import React from "react";
import { connect } from "react-redux";
import {Account} from "./account";
import {Init2FA} from "../login/init2FA";
import {UserNotifications} from "./user_notifications";
import Subscription from "../manage/subscription";
import Licenses from "../manage/licenses";
import CincLicenses from "../manage/cinc_licenses";
import { NavLink, Route, Redirect } from "react-router-dom";
import UserPModelManager from "../manage/user_pmodel_manager";
import PhenomId from "../../requests/phenom-id";
import SkaylManager from "./skayl_admin/skayl_manager";
import { SkaylRoute } from "../../routes";
import { routeUrlBuilder } from "../../routes";

class Settings extends React.Component {
    render() {
        const { isAdmin, skaylAdmin } = this.props;
        const phenomId = new PhenomId("settings",this.props.idCtx);

        return (<section id="phenom-content">
          <div className="phenom-content-wrapper">
            <nav className="sub-menu-pages tab-nav" aria-label='sub menu'>
                <NavLink activeClassName="active" to="/settings/account" id={phenomId.gen("","account-link")}>ACCOUNT</NavLink>
                {isAdmin &&
                  <NavLink activeClassName="active" to="/settings/subscription/users" id={phenomId.gen("","subscription-link")}>SUBSCRIPTION</NavLink> }
                <NavLink activeClassName="active" to="/settings/notifications" id={phenomId.gen("","notifications-link")}>NOTIFICATIONS</NavLink>
                {skaylAdmin &&
                  <NavLink activeClassName="active" to="/settings/skayl" id={phenomId.gen("","skayl-admin-link")}>Skayl Admin</NavLink>}
            </nav>
            <Route path={routeUrlBuilder("settings", "account")} render={() => <Account user={this.props.userIdentity}/>}/>
            {this.props.isAdmin && <>
              <Route path={routeUrlBuilder("settings", "subscription", "users")} 
                     component={Subscription}/>
              <Route path={routeUrlBuilder("settings", "subscription", "licenses")} 
                     component={Licenses}/>
              <Route path={routeUrlBuilder("settings", "subscription", "cinc-licenses")} 
                     component={CincLicenses}/>
            </>}
            <Route path={routeUrlBuilder("settings", "two_factor_auth")} 
                   component={Init2FA}/>
            <Route path={routeUrlBuilder("settings", "notifications")} 
                   component={UserNotifications}/>
            {this.props.isAdmin && <Route path={routeUrlBuilder("settings", "subscription", "user_model_manage", ":username")} 
                                          component={UserPModelManager}/>}
            <SkaylRoute path={routeUrlBuilder("settings", "skayl")} 
                        component={SkaylManager} />
            <Redirect to="/settings/account" />
          </div>
        </section>);
    }
}



const msp = (state) => ({
  userIdentity: state.user.userIdentity,
  isAdmin: state.user.isAdmin,
  skaylAdmin: state.user.skaylAdmin,
})


export default connect(msp)(Settings);