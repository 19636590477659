import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'


/**
 * Used within react-router's switch statement
 *    if not authenticated then render Public Component, otherwise render Redirect
 * 
 * @param {object} props
 * @returns route component
 */
export const PublicRoute = ({ component: Component, path, exact }) => {
  const authenticated = useSelector(state => state.user.authenticated);
  return <Route exact={exact} path={path}
                render={props => (
                  authenticated && props.location.prevPath ? <Redirect to={props.location.prevPath} />
                    : authenticated ? <Redirect to="/dashboard" /> : <Component {...props} />
                )} />
}


/**
 * Used within react-router's switch statement
 *    if authenticated then render Private Component, otherwise render Redirect
 * 
 * @param {object} props
 * @returns route component
 */
export const ProtectedRoute = ({ component: Component, path, exact }) => {
  const authenticated = useSelector(state => state.user.authenticated);
  return <Route exact={exact} path={path}
                render={props => (
                  authenticated ? <Component {...props} /> : <Redirect to={{ pathname: "/", prevPath: props.location.pathname, }} />
                )} />
}


/**
 * Standalone route component
 *    if authenticated then render Private Component, otherwise render null
 * 
 * @param {object} props
 * @returns route component
 */
export const AuthRoute = ({ component: Component, path, exact }) => {
  const authenticated = useSelector(state => state.user.authenticated);
  if (!authenticated) return null;
  return <Route exact={exact} path={path}
                component={Component} />
}


export const SkaylRoute = ({ component: Component, path, exact }) => {
  const skaylAdmin = useSelector(state => state.user.skaylAdmin);
  if (!skaylAdmin) return null;
  return <Route exact={exact} path={path}
                component={Component} />
}

/**
 * HELPER FUNCTIONS
 */

/**
 * Builds a URL path from provided segments.
 * - Index 0: `:mode(segment)` or `:mode` if falsy.
 * - Index 1: `:main_page(segment)` or `:main_page?` if null.
 * - Index 2: `:sub_page(segment)` or `:sub_page?` if null.
 * - Additional segments are appended as they are.
 *
 * @param {...string} pathArray - Segments of the URL path.
 * @returns {string} Constructed URL path.
 */
export const routeUrlBuilder = (...pathArray) => {
  if (!pathArray || !pathArray.length) return "/";

  let urlArray = [];

  // Mode - Index 0
  urlArray.push(pathArray[0] ? `:mode(${pathArray[0]})` : ":mode");

  // Main Page - Index 1
  pathArray[1] !== undefined && urlArray.push(pathArray[1] ? `:main_page(${pathArray[1]})` : ":main_page?")

  // Sub Page - Index 2
  pathArray[2] !== undefined && urlArray.push(pathArray[2] ? `:sub_page(${pathArray[2]})` : ":sub_page?")

  // Additional path segments - Indices 3+
  for (let i = 3; i < pathArray.length; i++) {
    urlArray.push(`${pathArray[i]}`);
  }

  return "/" + urlArray.join('/');
};