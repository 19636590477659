import React from 'react'
import { connect } from 'react-redux'
import { Button } from '@progress/kendo-react-buttons';
import PhenomId from "../../requests/phenom-id";
import { createNodeUrl } from "../../requests/type-to-path"
import { showEventLog, hideEventLog, clearNotifications } from "../../requests/actionCreators";
import { ResizeBarVertical } from '../util/stateless';



class EventLog extends React.Component {
    constructor(props) {
        super(props);
        EventLog.__singleton = this;
    }
    
    autoScroll = true;
    phenomId = new PhenomId("event-log",this?.props?.idCtx);
    state = {
        visible: false,
        sortByWarnings: false,
        sortByErrors: false,
    }

    componentDidUpdate(prevProps) {
      if (prevProps.notice !== this.props.notice) {
        this.appendNotice(this.props.notice);
      }

      if (prevProps.authenticated !== this.props.authenticated) {
        clearNotifications();
        
        const logArea = document.querySelector('#event-log-content');
        while (logArea.hasChildNodes()) {
          logArea.removeChild(logArea.lastChild);
        }
      }
    }

    static toggle = () => {
      const that = EventLog.__singleton;
      that.props.isEventLogVisible ? hideEventLog() : showEventLog();
    }

    appendNotice = (notice) => {
      const { timestamp, data, errors=[], logs=[], warnings=[] } = notice;
      const content = document.querySelector('#event-log-content');

      // check if user manually scrolled
      // the check needs to happen here before new lines are added
      const { scrollTop, clientHeight, scrollHeight } = content;
      this.autoScroll = scrollTop + clientHeight === scrollHeight;

      errors.forEach(text => {
        const note = this.createNote("error", timestamp, text, data);
        content.appendChild(note);
      })

      warnings.forEach(text => {
        const note = this.createNote("warning", timestamp, text, data);
        content.appendChild(note);
      })

      logs.forEach(text => {
        const note = this.createNote("log", timestamp, text, data);
        content.appendChild(note);
      })

      this.filterNotes();

      if (this.autoScroll) {
        this.scrollToBottom();
      }
    }

    createNote = (type, timestamp, text, data) => {
      let className;
      switch (type) {
        case "error":
            className = "event-error";
            break;
        case "warning":
            className = "event-warning";
            break;
        default:
            className = "event-log";
            break;
      }

      const note = document.createElement('div');
            note.classList.add(className);

      const time = document.createElement('time');
            time.innerHTML = this.convertTimeStamp(timestamp) + ": ";
            note.appendChild(time);

      const msg = document.createElement('span');
            msg.innerHTML = text + " ";
            note.appendChild(msg);

      if (data?.guid) {
        const link = document.createElement('a');
              link.href = createNodeUrl(data);
              link.innerHTML = `[${data.name || data.rolename || data.xmiType}]`
        note.appendChild(link);
      }

      return note;
    }

    filterNotes = () => {
      const { sortByErrors, sortByWarnings } = this.state;

      const content = document.querySelector('#event-log-content');
      let showTypes = [];

      if (sortByErrors ^ sortByWarnings) {
        sortByErrors && showTypes.push('event-error');
        sortByWarnings && showTypes.push('event-warning');
      } else {
        showTypes = ['event-log', 'event-error', 'event-warning'];
      }

      for (let child of content.children) {
        if (showTypes.some((type) => child.classList.contains(type))) {
          child.classList.remove("hide");
        } else {
          child.classList.add("hide");
        }
      }
    }

    convertTimeStamp = (timestamp) => {
        const now = new Date(timestamp);
        const date = now.toLocaleDateString();
        const time = now.toLocaleTimeString();
        return `${date} ${time}`;
    }

    scrollToBottom = () => {
        if(this.autoScroll) {
            const content = document.querySelector('#event-log-content');
            content.scrollTop = content.scrollHeight;
        }
    }

    render() {
        const { sortByErrors, sortByWarnings } = this.state;
        // if(!this.props.authenticated || !visible) return null;


        return <div id="event-log" className={this.props.authenticated && this.props.isEventLogVisible ? "visible" : null}>
                  <ResizeBarVertical onResize={(dMouse) => {
                    const eventLog = document.querySelector("#event-log");
                    const rect = eventLog.getBoundingClientRect();
                    eventLog.style.height = rect.height - dMouse + "px";
                  }} />
                  <header>
                    <ul className="actions">
                      <li>
                        <button onClick={() => this.setState({ sortByWarnings: false, sortByErrors: false },
                                                                this.filterNotes)}>
                            All</button>
                      </li>
                      <li>
                        <button className={"event-warning" + (sortByWarnings ? " active" : "")}
                                onClick={() => this.setState((prevState) => ({ sortByWarnings: !prevState.sortByWarnings }),
                                                                                this.filterNotes)}>
                            Warnings</button>
                      </li>
                      <li>
                        <button className={"event-error" + (sortByErrors ? " active" : "")}
                                onClick={() => this.setState((prevState) => ({ sortByErrors: !prevState.sortByErrors }),
                                                                                this.filterNotes)}>
                            Errors</button>
                      </li>
                    </ul>

                    <h1 className="title">
                        Event Log
                    </h1>

                    <ul>
                      <li>
                        <Button icon="close" look="bare" title="Close event log"
                                onClick={hideEventLog} />
                      </li>
                    </ul>
                  </header>

                  <div id="event-log-content" />
            </div>
    }
}


const msp = (state) => ({
  authenticated: state.user.authenticated,
  notice: state.notification.notice,
  isEventLogVisible: state.notification.isEventLogVisible,
});

export default connect(msp)(EventLog);