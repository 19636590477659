import React from "react";
import { nodeProps } from '../design/nodeDesign';
import { RootNodeModel } from './RootNode';
import _ from 'lodash';
import PhenomId from "../../../../requests/phenom-id";



export class DiagramShapeModel extends RootNodeModel {
    constructor(options = {}) {
        super({
            ...options,
        });
        if(!this.options.nodeData) this.options.nodeData = {};
    }

    serialize() {
      return {
          ...super.serialize(),
          nodeData: this.options.nodeData || {},
          width: this.width,
          height: this.height,
      };
    }

    deserialize(event) {
      super.deserialize(event);
      this.options.nodeData = event.data.nodeData || {};
      this.width = event.data.width;
      this.height = event.data.height;
    }
    
    updateProp = (k, v) => {
        this.options.nodeData[k] = v;
    }

    getNodeData = () => {
      return this.options.nodeData;
    };

    restoreNodeSize = () => {
        return;
    }

    rememberNodeSize = () => {
        return;
    }
}



export class DiagramShapeWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isEditing: false,
        }

        this.$app = this.props.engine.$app;
        this.nodeModel = this.props.node;
        this.nodeModel.$widget = this;
        this.phenomId = new PhenomId(this.nodeModel.getOptions().id);
    }

    startResize = (e, minHeight) => {
        e.stopPropagation();
        this.nodeModel.setLocked(true); 

        const classes = e.target.classList;
        const min_width = 20;
        const max_width = nodeProps.maxWidth;
        const min_height = 20;

        let original_x = this.nodeModel.getX();
        let original_y = this.nodeModel.getY();
        let original_mouse_x = e.pageX;
        let original_mouse_y = e.pageY;
        let original_width = this.nodeModel.width;
        let original_height = this.nodeModel.height;

        const resize = (e) => {
            if(classes.contains("resize-right")) { resizeRight(e); } 
            else if(classes.contains("resize-left")) { resizeLeft(e); } 
            else if(classes.contains("resize-bottom")) { resizeBottom(e); } 
            else if(classes.contains("resize-top")) { resizeTop(e); } 
            else if(classes.contains("resize-top-left")) { resizeTop(e); resizeLeft(e); } 
            else if(classes.contains("resize-top-right")) { resizeTop(e); resizeRight(e); } 
            else if(classes.contains("resize-bottom-left")) { resizeBottom(e); resizeLeft(e); } 
            else if(classes.contains("resize-bottom-right")) { resizeBottom(e); resizeRight(e); }
            this.forceUpdate();
        }

        const stopResize = () => {
            this.nodeModel.setLocked(false); 
            window.removeEventListener("mousemove", resize);
            window.removeEventListener("mouseup", stopResize);
        }

        const resizeTop = (e) => {
            let height = original_height - (e.pageY - original_mouse_y);
            let pos_y = original_y + (e.pageY - original_mouse_y);
            if(min_height <= height) {
                this.nodeModel.height = height;
                this.nodeModel.position.y = pos_y;
                this.props.engine.repaintCanvas();
            }
        }

        const resizeBottom = (e) => {
            let height = original_height + (e.pageY - original_mouse_y);
            if(min_height <= height) {
                this.nodeModel.height = height;
            }
        }

        const resizeRight = (e) => {
            let width = original_width + (e.pageX - original_mouse_x);
            if(min_width <= width) {
                this.nodeModel.width = width;
            }
        }

        const resizeLeft = (e) => {
            let width = original_width - (e.pageX - original_mouse_x);
            let pos_x = original_x + (e.pageX - original_mouse_x);
            if(min_width <= width) {
                this.nodeModel.width = width;
                this.nodeModel.position.x = pos_x;
                this.props.engine.repaintCanvas();
            }
        }

        window.addEventListener("mousemove", resize);
        window.addEventListener("mouseup", stopResize);
    }


    startRotate = (e) => {
        e.stopPropagation();
        this.nodeModel.setLocked(true);   

        const rotate = (e) => {
            // const angle = 90 + (Math.atan2(center_y - e.pageY, center_x - e.pageX) * 180) / Math.PI;
            const mouse = this.$app.engine.getRelativeMousePoint(e);

            let center_x = this.nodeModel.position.x + (this.nodeModel.width / 2);
            let center_y = this.nodeModel.position.y + (this.nodeModel.height / 2);
            let radians = Math.atan2(mouse.x - center_x, mouse.y - center_y);
            let degrees = (radians * (180 / Math.PI) * -1) + 90;
    
            this.nodeData.rotation = degrees;
            this.forceUpdate();
        }


        const stopRotate = () => {
            this.nodeModel.setLocked(false); 
            window.removeEventListener("mousemove", rotate);
            window.removeEventListener("mouseup", stopRotate);
        }


        window.addEventListener("mousemove", rotate);
        window.addEventListener("mouseup", stopRotate);
    }


    render() {
        return (
            <div>
                Please don't render this.
            </div>
        )
    }
}