import React from "react";
import { _ajax } from "../../requests/sml-requests";
import { cloneDeep } from "lodash"
import { CadetInput, LineLabel, CadetTextArea, SingleSelect, CommaLinks, Card, DropdownSelect2, ConstraintTable } from "./stateless";
import {elementDetail, getNodesOfType, getNodeWithAddenda} from "../../requests/sml-requests";
import {VtuPicker2} from "./vtu_picker";
import {deGuidify, isPhenomGuid, prepareNodesForSmmSave} from "./util";
import {BasicConfirm} from "../dialog/BasicConfirm";
import {Button} from "@progress/kendo-react-buttons";
import {NodeHistory2} from "../edit/node-history";
import { Notifications2 } from "../edit/notifications";
import PhenomId from "../../requests/phenom-id";

import NodeLayout, { withPageLayout, StyledGrid, StyledInput, StyledSidebar, StyledContent, StyledCardGroup } from '../edit/node-layout'
import { createNodeUrl } from "../../requests/type-to-path";
import { getActiveChangeSetId } from "../../requests/actionCreators";
import ChangeSetPicker from "../widget/ChangeSetPicker";
import {createPhenomGuid} from "../util/util"
import NavTree from "../tree/NavTree";
import DeletionConfirm2 from "../dialog/DeletionConfirm2";

const labelStyle = {margin:"0 0 7px", lineHeight:"normal"};
const inputStyle = {margin:"0 0 15px"}

export class MeasurementSystemAxisManager extends React.Component {
    original = {};
    vtuRef = {};
    inputRef = React.createRef();
    noticeRef = React.createRef();
    csaRef = React.createRef();
    parentRef = React.createRef();

    defaultMSA = {
            guid: null,
            name: "",
            description: "",
            axis:"",
            defaultValueTypeUnit: [],
            vtus: [],
            vtuGuids: [],
            vtuAddedData: {},
            newConstraint: undefined,
            newConstraints: [],
            coordinateSystemAxes: {},
            parent: "",
            parents: {},
            related_measurement_axis: [],
            related_measurement_systems: [],
            editable: true,
            dummyVtuGuid: createPhenomGuid(),
            dummyConstraintGuid: 1,
            look:"",
            isLiteralEnum: false
    }

    state = {
        ...cloneDeep(this.defaultMSA),
    };

    async componentDidMount() {
        if (this.props.look === "component") {
            this.setupMsaComponent();
        } else if (this.props.match.params.guid === "new") {
            this.setupNewMsa();
        } else {
            this.setupExistingMsa();
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.guid !== this.props.guid ||
            prevProps.match.params.guid !== this.props.match.params.guid) {
                if (this.props.look === "component") {
                    this.setupMsaComponent();
                } else if (this.props.match.params.guid === "new") {
                    this.setupNewMsa();
                } else {
                    this.setupExistingMsa();
                }
            }

        if(prevProps.dummyConstraintGuid !== this.props.dummyConstraintGuid) {
            this.setState({ dummyConstraintGuid: this.props.dummyConstraintGuid });
        }

        if(prevProps.newConstraints !== this.props.newConstraints) {
            this.setState({ newConstraints: this.props.newConstraints });
        }

        if(prevProps.coordinateSystemAxes !== this.props.coordinateSystemAxes) {
            this.setState({ coordinateSystemAxes: this.props.coordinateSystemAxes });
        }

        if (prevState.subModelId !== this.state.subModelId ||
            prevProps.subModels !== this.props.subModels) {
                this.setEditingStatus();
        }
    }

    componentWillUnmount() {
        if(this.props.saveState) {
            const state = {
                ...this.state,
            }

            this.props.saveState(state);
        }
    }

    setupNewMsa = async () => {
        const res = await Promise.all([
            getNodesOfType("logical:CoordinateSystemAxis"),
            getNodesOfType("face:LogicalDataModel"),
        ]);

        const coordinateSystemAxes = deGuidify(res[0].data.nodes);
        const parents = deGuidify(res[1].data.nodes);

        this.setState({
            ...cloneDeep(this.defaultMSA),
            look: "new",
            coordinateSystemAxes,
            parents
        });
    }

    setupExistingMsa = async () => {
        const res = await Promise.all([
            getNodesOfType("logical:CoordinateSystemAxis"),
            this.fetchMSA(),
            elementDetail(this.props.match.params.guid),
        ]);

        const coordinateSystemAxes = deGuidify(res[0].data.nodes);
        const msa = res[1];
        const details = res[2].data;

        const isLiteralEnum = msa.defaultValueTypeUnit.some(vtu => vtu.valueType.xmiType === "logical:Enumerated");
        const vtuGuids = msa.defaultValueTypeUnit.map(vtu => vtu.guid);
        const vtuAddedData = deGuidify(msa.defaultValueTypeUnit);

        if (this.props.updateTemplateNode) {
            this.props.updateTemplateNode(msa);
        }

        this.original = {...this.original, ...msa, ...details };
        this.setState({
            ...cloneDeep(this.defaultMSA),
            look: "normal",
            ...msa,
            ...details,
            coordinateSystemAxes,
            isLiteralEnum,
            vtuGuids,
            vtuAddedData
        }, () => {
            this.setEditingStatus();
        });
    }

    setupMsaComponent = async () => {
        this.original = {...this.original, ...this.props.node};
        this.setState({
            ...this.props.node,
            look: "component",
            parent: this.props.parent,
            dummyConstraintGuid: this.props.dummyConstraintGuid,
            newConstraints: this.props.newConstraints,
            coordinateSystemAxes: this.props.coordinateSystemAxes,
        });
    }

    fetchMSA = () => {
        return getNodeWithAddenda(this.props.match.params.guid, {
            coreAddenda: ["defaultValueTypeUnitMULTI"],
            defaultValueTypeUnitAddenda: ["valueType", "unit", "childrenMULTI"]
        })
    }

    handleSave = async () => {
        let requestData = this.generateNode();

        if (requestData.errors) {
            return Notifications2.parseErrors([...requestData.errors]);
        }

        // flatten the nodes
        // filters out published nodes
        // removes undefined and null
        const nodes = prepareNodesForSmmSave([requestData]);

        const res = await _ajax({
            url: "/index.php?r=/node/smm-save-nodes",
            method: "post",
            data: {
                nodes,
                returnTypes: [requestData.xmiType],
                returnAddenda: {
                    [requestData.xmiType]: {
                        coreAddenda: ["defaultValueTypeUnitMULTI"],
                        defaultValueTypeUnitAddenda: ["valueType", "unit", "childrenMULTI"]
                    }
                }
            }
        });

        const response = res.data;
        Notifications2.parseResponse(response);

        if (response.nodes?.length) {
            NavTree.addNodes(response.nodes);
            const node = response.nodes[0];
            window["resetCache"]();

            if (this.props.match.params.guid === "new") {
                return this.props.history.push( createNodeUrl(node) );
            }
            this.setupExistingMsa();
        }
    }

    resetMeasurementSystemAxis = async () => {
        this.inputRef.current.resetData();
        this.csaRef.current.resetData();

        if(this.props.match.params.guid === "new") {
            this.parentRef.current.resetData();
        }

        const vtus = cloneDeep(this.original.vtus);
        this.state.vtus = [];
        await this.forceUpdate();

        this.setState({vtus});
    }

    createNewConstraint = (constraint) => {
        if(this.props.createNewConstraint) {
            this.props.createNewConstraint(constraint);

        } else if (constraint) {
            const newConstraints = cloneDeep(this.state.newConstraints);
            newConstraints.push(constraint);
            this.setState((prevState) => ({
                newConstraint: constraint,
                newConstraints,
                dummyConstraintGuid: prevState.dummyConstraintGuid + 1
            }));
        }
    }

    addNewVtu = () => {
        const newVTU = {
            guid: this.state.dummyVtuGuid.toString(),
            xmiType: "logical:ValueTypeUnit",
            name: "",
            unit: {},
            valueType: {},
            children: [],
            collapse: false
        }

        this.state.vtuAddedData[newVTU.guid] = newVTU;
        const vtuGuids = [...this.state.vtuGuids, newVTU.guid];

        this.setState((prevState) => ({vtuGuids, dummyVtuGuid: createPhenomGuid()}));
    }

    removeVtu = async (guid) => {
        BasicConfirm.show("Are you sure you want to remove this Value Type Unit?\n(note: click SAVE to commit the change)", async () => {
            // force unmount and save data of each VTU
            let vtuGuids = [...this.state.vtuGuids];
            this.state.vtuGuids = [];
            await this.forceUpdate();

            vtuGuids = vtuGuids.filter(id => id !== guid);
            this.setState({vtuGuids});
        })
    }

    saveVtuState = (vtuState) => {
        if (vtuState.guid in this.state.vtuAddedData) {
            this.state.vtuAddedData[vtuState.guid] = vtuState;
            this.forceUpdate();
        }
    }

    setEditingStatus = () => {
        const { subModels={}, setParentEditingStatus } = this.props;
        const { subModelId } = this.state;
        const currSubModel = subModels[subModelId];
        this.setState({ editable: !currSubModel?.created }, () => {
            setParentEditingStatus && setParentEditingStatus(!currSubModel?.created)
        });
    };

    generateNode = () => {
        let vtu_nodes = [];
        let errors = new Set();

        this.state.vtuGuids.forEach(guid => {
            let vtu = this.vtuRef[guid].current.generateNode();
            if (vtu.errors) return vtu.errors.forEach(err => errors.add(err));
            if (vtu.data) vtu_nodes.push(vtu.data);
        })

        if (errors.size > 0) {
            return { errors }
        }

        let nodeData = {
            guid: this.state.guid || createPhenomGuid(),
            name: this.state.name,
            description: this.state.description,
            xmiType: "logical:MeasurementSystemAxis",
            axis: this.state.axis,
            defaultValueTypeUnit: vtu_nodes,
            parent: this.state.parent,
        }
        return nodeData;
    }

    handleDelete = () => {
        DeletionConfirm2.show(this.state.guid, this.state.name);
    }

    render() {
        const { guid, name, editable, deprecated } = this.state;
        const isComponent = this.props.look === "component";
        const phenomId = new PhenomId("measurement-system-axis",this.props.idCtx);

        return(
          <NodeLayout guid={guid}
                      name={name}
                      editable={editable}
                      deprecated={deprecated}
                      isComponent={isComponent}
                      idCtx={phenomId.genPageId()}
                      onSave={this.handleSave}>

              <StyledGrid isComponent={isComponent} id={phenomId.gen("","")}>
                  <StyledInput>
                      <LineLabel text="MEASUREMENT SYSTEM AXIS" style={labelStyle} idCtx={phenomId.gen("","name")} />
                      <CadetInput text={this.state.name} style={inputStyle}
                          disabled={!this.state.editable}
                          onChange={(e) => this.setState({name: e.target.value})}
                          idCtx={phenomId.gen("","name")} />

                      <LineLabel text="DESCRIPTION" style={labelStyle} idCtx={phenomId.gen("","description")} />
                      <CadetTextArea text={this.state.description} style={inputStyle}
                          disabled={!this.state.editable}
                          onChange={(e) => this.setState({description: e.target.value})}
                          idCtx={phenomId.gen("","description")} /></StyledInput>

                  {this.props.look !== "component" &&
                    <StyledSidebar id={phenomId.gen("","")}>
                        <NodeHistory2 guid={this.props.match.params.guid} idCtx={phenomId.gen("","")}/>
                        {/* <ChangeSetPicker id={phenomId.genPageId()}
                                         disabled={!this.state.editable}
                                         label="Change Set" /> */}
                  </StyledSidebar>}

                  <StyledContent id={phenomId.gen("details","")}>
                      {this.props.match.params.guid === "new" &&
                      <div>
                          <LineLabel text="Parent Package" style={labelStyle} idCtx={phenomId.gen("new","pacakge")} />
                          <div style={{marginBottom:10}}>
                              <Button icon="select-box" onClick={() => this.parentRef.current.toggleCollapse()}
                                      idCtx={phenomId.gen("","select-parent-toggle")}>Select Parent</Button>
                          </div>

                          <SingleSelect data={this.state.parents}
                                      selectedGuid={this.state.parent || ""}
                                      required={false}
                                      optionNoneText="No Package Selected / Use Default"
                                      collapse={this.props.match.params.guid !== "new"}
                                      onChange={(e) => this.setState({parent: e.target.value})}
                                      editable={this.state.editable}
                                      ref={this.parentRef}
                                      idCtx={phenomId.gen("parents")} />

                          {this.state.parents &&
                              <StyledCardGroup columns={3} id={phenomId.gen("","")}>
                                  <Card node={this.state.parents[this.state.parent]}
                                        idCtx={phenomId.gen("","")} /></StyledCardGroup>} </div>}

                      <div>
                          <LineLabel text="Coordinate System Axis" style={labelStyle} idCtx={phenomId.gen(["details","coordinate-system-axis"],"")}/>

                          {this.props.look === "component" ?
                              <DropdownSelect2 data={this.state.coordinateSystemAxes}
                                                  selectedGuid={this.state.axis}
                                                  onChange={(e) => this.setState({axis: e.target.value})}
                                                  editable={this.state.editable}
                                                  idCtx={phenomId.gen("","")} />
                              : <>
                              <div style={{marginBottom:10}}>
                                  <Button icon="select-box" onClick={() => this.csaRef.current.toggleCollapse()}
                                          idCtx={phenomId.gen("","select")}>Select Coordinate System Axis</Button></div>

                              <SingleSelect data={this.state.coordinateSystemAxes}
                                              selectedGuid={this.state.axis}
                                              required={true}
                                              collapse={this.props.match.params.guid !== "new"}
                                              onChange={(e) => this.setState({axis: e.target.value})}
                                              editable={this.state.editable}
                                              ref={this.csaRef}
                                              idCtx={phenomId.gen("","")} />
                              </>
                          }

                          {this.state.axis && this.props.look !== "component" &&
                              <StyledCardGroup columns={3} id={phenomId.gen("","")}>
                                  <Card node={this.state.coordinateSystemAxes[this.state.axis]} idCtx={phenomId.gen("","")}/></StyledCardGroup>} </div>

                      <div>
                          <LineLabel text="Value Type Units" style={labelStyle} idCtx={phenomId.gen(["details","vtus"],"")}/>
                          {this.state.vtuGuids.map((guid,gIdx) => {
                              const vtu = this.state.vtuAddedData[guid];
                              this.vtuRef[guid] = React.createRef();
                              return <VtuPicker2 valueTypeUnit={vtu}
                                              look={this.props.look}
                                              editable={this.state.editable}
                                              newConstraints={this.state.newConstraints}
                                              dummyConstraintGuid={this.state.dummyConstraintGuid}
                                              createNewConstraint={this.createNewConstraint}
                                              saveState={this.saveVtuState}
                                              removeNode={this.removeVtu}
                                              ref={this.vtuRef[guid]}
                                              idCtx={phenomId.gen(["vtus",gIdx],"")} /> })}

                          {!this.state.isLiteralEnum &&
                          <Button icon="plus"
                                  disabled={!this.state.editable}
                                  onClick={this.addNewVtu}
                                  id={phenomId.gen("","add")}>Add Value Type Unit</Button> } </div>

                      {this.state.newConstraint && this.props.look !== "component" &&
                          <div style={{marginTop:20}}>
                              <LineLabel text="Added Constraint" style={labelStyle} idCtx={phenomId.gen("details","constraint")} />
                              <ConstraintTable constraint={this.state.newConstraint} idCtx={phenomId.gen("","")}/></div>}

                      {this.props.match.params.guid !== "new" && this.props.look !== "component" && <>
                          <div>
                              <LineLabel text="Measurement Axis" style={labelStyle} idCtx={phenomId.gen("","measurement-axis")} />
                              <CommaLinks data={this.state.related_measurement_axis || []} idCtx={phenomId.gen("","measurement-axis")} /> </div>

                          <div>
                              <LineLabel text="Measurement System" style={labelStyle} idCtx={phenomId.gen("","measurement-system")} />
                              <CommaLinks data={this.state.related_measurement_systems || []} idCtx={phenomId.gen("","measurement-system")} /> </div>
                      </>}
                  </StyledContent>
              </StyledGrid>
          </NodeLayout>
        )
    }
}



export const EditMeasurementSystemAxisManager = withPageLayout(MeasurementSystemAxisManager, { renderResetBtn: false });
