// import PhenomId from "../../requests/phenom-id"
// const phenomId = new PhenomId(pagename);
// this.props.idCtx

// instructions :
// concept is simple the PhenomId object simply keeps place and order in a list
// of ids,
// instantiate with const phenomId = new PhenomId(pagename)
// pagename = is the page name OR if it is a nested page
//   this.props.idCtx + pagename
// most everything you need to do should be doable with the .gen() function
//   .gen(ctx, suffix)
//   ctx = name of location in list, either adds a new id to the current list
//     or rolls the list back to a previous id. in the case of looping it is
//     useful to instead of a sinlge string give it a list of the form
//     [backto,newid]
//   suffix = a string attached to end of the gen argument but not added to the
//     stack structure
// .genPageId() if you just need to give the initial page id without clearing
//   your tree
// ** reserved word/s **
//   "init" is reserved for the branch or backTo variable. automatically points to
//    the begining of and clears the stack
export default class PhenomdId{
  // originally thought i'd need a tree structure but that's overkill and once
  // someone climbs backs out of the stack it's not likely used again
  idBodies = [];
  idBody;
  pageId;
  currBody;
  constructor(pageId = "",idCtx = "") {
    if (idCtx) {
      this.pageId = idCtx + "-" + pageId;
    } else {
      this.pageId = pageId;
    }
    this.idBodies.push(this.pageId);
    this.currBody = this.pageId;
  }


    gen(ctx="",suffix="",postArgs=""){
      if (Array.isArray(ctx)) {
        const backTo = String(ctx[0])
        const ctxRest = ctx.slice(1,ctx.length);
        this.setBody(backTo);
        ctx.forEach((newId, i) => this.addBody(String(newId)));
      } else {
        ctx = String(ctx)
        if (this.has(ctx)) {
          this.setBody(ctx);
        } else if(ctx != ""){
          this.addBody(ctx);
        }
      }
      this.postGen(postArgs,ctx);
      return this.genId(suffix);
    }


    genId(suffix = ""){
      suffix = suffix ? ("-" + suffix) : "";
      let idBody = this.idBodies.join("-");
      return idBody + suffix;
    }

    genPageId(...suffix){
      if(suffix.length) return this.pageId + "-" + suffix.join("-");
      return this.pageId;
    }

    postGen(postArgs,ctx){
      switch (postArgs) {
        case "loop":
          this.setBody(ctx[0]);
          break;
        default:
      }
    }

    setBody(currBody){
      if (currBody) {
        if (currBody == "init") {
          this.currBody = this.pageId;
        } else if (this.has(currBody)) {
          this.currBody = currBody;
        }
        this.clearRest();
        return this;
      } else {
        return false;
      }
    }

    addBody(newBody){
      if (newBody != "") {
        this.idBodies.push(newBody);
        this.setBody(newBody);

        return this;
      }
      return false;
    }

    currBody(){
      return this.currBody;
    }

    setOrAddBody(body){
      return this.has(body) ? this.setBody(body) : this.addBody(body);
    }

    toEnd(){
      const lastBodyId = this.idBodies[this.idBodies.length - 1];
      this.setBody(lastBodyId);
      return this;
    }

    clear(){
      this.idBodies = [];
      this.idBodies.push(this.pageId);
      return this;
    }

    clearRest(){
      const index = this.idBodies.indexOf(this.currBody);
      const keys = this.idBodies.slice(0,index + 1);
      this.idBodies = this.bodiesFromKeys(keys);
      return this;
    }

    deleteBody(idBody){
      const index = this.idBodies.indexOf(idBody);
      this.idBodies.splice(index, 1);
      return this;
    }

    has(ctx){
      if (ctx == "init") {
        return true;
      }
      return this.idBodies.includes(ctx) ? true : false;
    }

    up(){
      let index = this.idBodies.length - 1;
      let keys = this.idBodies.slice(0,index);
      this.idBodies = this.bodiesFromKeys(keys);
      this.toEnd();
      return this;
    }

    bodiesFromKeys(keys){
      let idBodies = [];
      keys.forEach(key => idBodies.push(key))
      return idBodies;
    }


}
