import React from 'react'
import $ from 'jquery'
import { cloneDeep, debounce, invert } from 'lodash';
import { SkaylSubMenu } from './skayl_manager';
import { receiveErrors, receiveLogs, receiveResponse, receiveWarnings } from '../../../requests/actionCreators';
import { Grid, GridNoRecords, GridColumn } from '@progress/kendo-react-grid';
import { PhenomModal } from '../../util/Modal';
import { accountLicenses, accountRoles, cincVersions } from '../../../global-constants';
import { getAccountInfo } from "../../../requests/sml-requests";
import { Checkbox } from '@progress/kendo-react-inputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import styled from '@emotion/styled';
import { PhenomCollapsable, PhenomComboBox, PhenomInput, PhenomLabel, PhenomSelect, ProjectSelection } from '../../util/stateless';
import { SkaylAccountInfo, SkaylPhenomLicenses, SkaylCincLicenses, UserNameCell, UserEmailCell, UserActiveCell, UserAdminCell, UserAssignedPhenomLicenseCell, FormattedDateCell, UserPasswordCell, UserProjectsCell, UserDeleteCell, UserCurrentProjectCell, SkaylCincVersions, UserExpireDateCell, UserUnassignedPhenomLicenseCell } from '../util';
import { Button, Toolbar, ToolbarItem } from '@progress/kendo-react-buttons';
import { ChangeSubUserPassword } from '../change_password';
import { BasicAlert } from '../../dialog/BasicAlert';
import { DeleteUserPrompt } from '../../manage/subscription';
import { fetchModelTree } from '../../manage/branch';
import { createPhenomGuid } from '../../util/util';
import { BasicConfirm } from '../../dialog/BasicConfirm';
import { _ajax } from '../../../requests/sml-requests';


class SkaylAccounts extends React.Component {
  changePasswordRef = React.createRef();
  newAccountRef = React.createRef();
  newUsersRef = React.createRef();
  usersRef = React.createRef();
  cincVersionsRef = React.createRef();

  defaultState = {
    account_info: {},
    all_projects: {},
    users: {},
    cinc_licenses: {},
    cinc_versions: {},
    phenom_licenses: {},

    delete_user_id: null,
    phenom_licenses_new_date: {},
    cinc_licenses_change_type: {},
    phenom_licenses_revoke: new Set(),
    invoke_phenom_licenses_to_users: {},   // <license_id>: <user_id>
    invoke_users_to_phenom_licenses: {},   // <user_id>: <license_id>
    associate_cinc_versions: {}
  }

  state = {
    selectedAccountName: "",
    createNewAccount: false,
    createNewUsers: false,
    account_names: [],
    changePasswordFor: null,
    available_cinc_versions: [],
    viewLogs: false,
    logs: [],
    ...cloneDeep(this.defaultState),
  }

  componentDidMount() {
    this.fetchAccountList();
    this.fetchCincLicenses();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedAccountName !== this.state.selectedAccountName) {
      this.changeAccountInfo();
    }
  }

  fetchAccountList = () => {
    return _ajax({
      url: "/index.php?r=/skayl/accounts",
      method: "post",
    }).then(response => {
      
      if (Array.isArray(response.data.accounts)) {
        return this.setState({ account_names: response.data.accounts.sort() });
      }

      return receiveResponse(response);
    })
  }

  fetchAccountInfo = (account_name) => {
    return _ajax({
      url: "/index.php?r=/skayl/account-info",
      method: "post",
      data: {
        account_name,
      }
    }).then(response => {
      const { account_info={} } = response.data;
      let users = {}, all_projects = {}, pl_hash = {}, cv_hash = {}, cl_hash = {};

      Array.isArray(account_info.usersTable)    && account_info.usersTable.forEach(u => users[u.id] = u);
      Array.isArray(account_info.projectsTable) && account_info.projectsTable.forEach(proj => all_projects[proj.id] = proj);
      Array.isArray(response.data.phenom_licenses)   && response.data.phenom_licenses.forEach(lic => pl_hash[lic.license_id] = lic);
      Array.isArray(response.data.cinc_licenses)     && response.data.cinc_licenses.forEach(lic => cl_hash[lic.license_id] = lic);
      Array.isArray(response.data.cinc_versions)     && response.data.cinc_versions.forEach(ver => cv_hash[ver.id] = ver);
      
      // reset state
      this.setState(cloneDeep(this.defaultState), () => {
        this.setState({
          account_info,
          users,
          all_projects,
          phenom_licenses: pl_hash,
          cinc_licenses: cl_hash,
          cinc_versions: cv_hash,
        })
      });
    })
  }

  fetchCincLicenses = () => {
    return _ajax({
      url: "/index.php?r=/skayl/cinc-versions",
      method: "post",
    }).then(response => {
      if ("error" in response) return receiveErrors(response["error"]);

      const versions = {};
      Array.isArray(response.data.versions) && response.data.versions.forEach(ver => versions[ver.id] = ver);
      
      this.setState({ available_cinc_versions: versions });
    })
  }

  closeNewAccountModal = () => {
    this.setState({ createNewAccount: false })
  }

  closeNewUsersModal = () => {
    this.setState({ createNewUsers: false })
  }

  changeAccountInfo = () => {
    const { selectedAccountName } = this.state;

    if (!selectedAccountName) {
      return;
    }

    this.fetchAccountInfo(selectedAccountName);
  }

  handleCreateNewAccount = () => {
    if (!this.newAccountRef.current) {
      return;
    }

    const data = this.newAccountRef.current.generateAccountData();
    return _ajax({
      url: "/index.php?r=/skayl/create-account",
      method: "post",
      data
    }).then((response => {

      if ("error" in response || "errors" in response) {
        return receiveResponse(response);
      }

      receiveLogs(`Successfully created '${data.account_name}'`);
      this.closeNewAccountModal();
      this.fetchAccountList();
      this.setState({ selectedAccountName: data.account_name })
    }))
  }

  handleCreateNewUsers = () => {
    const data = this.newUsersRef.current.generateNewUsers();
    const { users, projectPerms, projectName } = data;

    // Skayl admin needs to confirm if sharing a project to another user
    if (data.users.some(user => !user?.personalWs)) {
      BasicConfirm.show("Are you sure you want to share the project " + projectName + " with " + projectPerms + " permissions to: " + 
      users.map(user => '\n• ' + user.name).join(''),
      () => {this.createNewUsers(data)});
    } else {
      this.createNewUsers(data);
    }    
  }

  createNewUsers = (data) => {
    receiveWarnings("User set up may take up to 10 minutes");

    return _ajax({
        url: "/index.php?r=/skayl/create-users",
        method: "post",
        data
    }).then(resp => {
      if (resp?.logs) {
        receiveLogs(resp.logs);
      }

      if (resp?.errors) {
        receiveErrors(resp.errors);
      }
      else {
        this.closeNewUsersModal();
        this.changeAccountInfo();
      }
    })
  }

  // toggle User's Phenom License
  toggleRevokePhenomLicense = (license_id) => {
    const phenom_licenses_revoke = new Set(this.state.phenom_licenses_revoke);

    if (phenom_licenses_revoke.has(license_id)) {
      phenom_licenses_revoke.delete(license_id);
    } else {
      phenom_licenses_revoke.add(license_id);
    }

    this.setState({ phenom_licenses_revoke });
  }

  // assign a new Phenom License to a User
  assignInvokePhenomLicense = (license_id, user_id) => {
    // used by PhenomLicense components
    // invoke_phenom_licenses_to_users = { <license_id>: <user_id> }
    let invoke_phenom_licenses_to_users = { ...this.state.invoke_phenom_licenses_to_users };
    let invoke_users_to_phenom_licenses = { ...this.state.invoke_users_to_phenom_licenses };

    let previous_license_id = invoke_users_to_phenom_licenses[user_id];
    let previous_user_id = invoke_phenom_licenses_to_users[license_id];

    if (!previous_license_id) {
      previous_license_id = invoke_users_to_phenom_licenses[previous_user_id];
    }

    // remove previously assigned user_id
    delete invoke_phenom_licenses_to_users[previous_license_id];
    
    // assign new user_id
    if (user_id) {
      invoke_phenom_licenses_to_users[license_id] = user_id;
    }
    
    // used by Users component 
    // invoke_users_to_phenom_licenses = { <user_id>: <license_id> }
    invoke_users_to_phenom_licenses = invert(invoke_phenom_licenses_to_users);

    this.setState({ invoke_phenom_licenses_to_users, invoke_users_to_phenom_licenses });
  }

  assignNewExpireDatePhenomLicense = (license_id, expire_date) => {
    if (!license_id || !expire_date) {
      return;
    }

    const new_date = expire_date.toISOString().replace(/\.\d+Z/, "");    // need to remove milliseconds (Z)
    const phenom_licenses_new_date = { ...this.state.phenom_licenses_new_date };
          phenom_licenses_new_date[license_id] = new_date;

    this.setState({ phenom_licenses_new_date });
  }

  clearInvokePhenomLicense = (license_id, user_id) => {
    if (!license_id) {
      license_id = this.state.invoke_users_to_phenom_licenses[user_id];
    }

    if (license_id) {
      return this.assignInvokePhenomLicense(license_id);
    }
  }

  changeUserPassword = (username=null) => {
    this.setState({ changePasswordFor: username });
  }

  handleSaveUserPassword = () => {
    const error = this.changePasswordRef.current.checkForErrors();

    if (error) {
      return receiveErrors(error);
    }

    const data = this.changePasswordRef.current.generateRequest();

    return _ajax({
      method: "post",
      url: "/index.php?r=/skayl/change-password",
      data
    }).then((response) => {
      receiveResponse(response);

      if (response?.status === "success") {
        this.changeUserPassword();
      }
    })
  }

  handleUpdateAccount = () => {
    const { selectedAccountName, phenom_licenses_new_date, cinc_licenses_change_type } = this.state;

    if (!selectedAccountName) {
      return receiveWarnings("Please select an Account.");
    }

    if (!this.usersRef.current) {
      return receiveWarnings("Users table did not finish loading.");
    }

    const users = this.usersRef.current.generateRequest();
    const newCincVersions = this.cincVersionsRef.current.generateRequest();
    
    const newCincLicenseTypes = Object.entries(cinc_licenses_change_type)
                                      .filter(([_, type]) => !!type)
                                      .map(([license_id, type]) => [license_id, type]);

    const newPhenomLicenseDates = Object.entries(phenom_licenses_new_date)
                                        .filter(([_, expire_date]) => !!expire_date)
                                        .map(([license_id, expire_date]) => [license_id, expire_date]);

    const userChanges = users.length;
    const versionChanges = newCincVersions.length;
    const cincChanges = newCincLicenseTypes.length;
    const phenomChanges = newPhenomLicenseDates.length;

    if (!userChanges && !versionChanges && !cincChanges && !phenomChanges) {
      return receiveWarnings("No changes detected.");
    }

    if (userChanges) {
      this.handleUpdateUsers(users);
    }

    if (versionChanges) {
      this.handleUpdateCincVersions(newCincVersions)
    }

    if (cincChanges) {
      this.handleUpdateCincLicenses(newCincLicenseTypes);
    }

    if (phenomChanges) {
      this.handleUpdatePhenomLicenses(newPhenomLicenseDates);
    }
  }

  handleUpdateUsers = (users) => {
    const { selectedAccountName, phenom_licenses_new_date, phenom_licenses_revoke, invoke_phenom_licenses_to_users } = this.state;

    const newLicenseDates = Object.entries(phenom_licenses_new_date)
                                  .filter(([_, expire_date]) => !!expire_date)
                                  .map(([license_id, expire_date]) => [license_id, expire_date]);

    const invokeLicenseIds = Object.entries(invoke_phenom_licenses_to_users)
                                   .filter(([_, user_id]) => !!user_id)
                                   .map(([license_id, user_id]) => [license_id, user_id]);

    const revokeLicenseIds = [...phenom_licenses_revoke];

    return _ajax({
      method: "post",
      url: "/index.php?r=/skayl/update-users",
      data: {
        account_name: selectedAccountName,
        users,
        newLicenseDates,
        invokeLicenseIds,
        revokeLicenseIds,
      }
    }).then((response) => {
      if (response.status === "success") {
        receiveLogs("Successfully updated users.");

        BasicAlert.show("Refreshing data", "One moment...", false);

        // reset state
        this.fetchAccountInfo(selectedAccountName).always(() => BasicAlert.hide());
      }

      receiveResponse(response);
    })
  }

  handleUpdatePhenomLicenses = (newLicenseDates) => {
    const { selectedAccountName } = this.state;
    
    return _ajax({
      method: "post",
      url: "/index.php?r=/skayl/update-phenom-licenses",
      data: {
        newLicenseDates        
      }
    }).then((response) => {
      if (response.status === "success") {
        receiveLogs("Successfully updated PHENOM License.");

        BasicAlert.show("Refreshing data", "One moment...", false);

        // reset state
        this.fetchAccountInfo(selectedAccountName).always(() => BasicAlert.hide());
      }

      receiveResponse(response);
    })
  }

  handleUpdateCincLicenses = (newLicenseTypes) => {
    const { selectedAccountName } = this.state;
    
    return _ajax({
      method: "post",
      url: "/index.php?r=/skayl/update-cinc-licenses",
      data: {
        newLicenseTypes,
      }
    }).then((response) => {
      if (response.status === "success") {
        receiveLogs("Successfully updated CinC License.");

        BasicAlert.show("Refreshing data", "One moment...", false);

        // reset state
        this.fetchAccountInfo(selectedAccountName).always(() => BasicAlert.hide());
      }

      receiveResponse(response);
    })
  }

  handleUpdateCincVersions = (newCincVersions) => {
    const { selectedAccountName } = this.state;

    return $.ajax({
      method: "post",
      url: "/index.php?r=/skayl/update-account-cinc-versions",
      data: {
        version_config_names: newCincVersions.map(ver => ver.config_name),
        account_name: selectedAccountName,
      }
    }).then((res) => {
      const response = JSON.parse(res);

      if (response.status === "success") {
        receiveLogs("Successfully updated CinC Versions.");

        BasicAlert.show("Refreshing data", "One moment...", false);

        // reset state
        this.fetchAccountInfo(selectedAccountName).always(() => BasicAlert.hide());
      }

      receiveResponse(response);
    });
  }

  // Copied from subscription page
  deleteUserFromDB = (username, user_id) => {
    const { selectedAccountName } = this.state;

    if (!selectedAccountName) {
      return receiveWarnings("Please select an Account.");
    }

    return _ajax({
        url: "/index.php?r=/skayl/delete-user",
        method: "post",
        data: {
          username,
          account_name: selectedAccountName,
        },
    }).then(response => {
        if (response.status !== "success") {
          return receiveResponse(response);
        }
        
        receiveLogs("Successfully deleted user.");
        this.cleanUpDeletedUser(user_id);
    });
  }

  cleanUpDeletedUser = (user_id) => {
    if (!user_id) {
      return;
    }

    const deleted_user = this.state.users[user_id];
    if (!deleted_user) {
      return;
    }

    const phenom_licenses_revoke = new Set(this.state.phenom_licenses_revoke);
    phenom_licenses_revoke.delete( deleted_user.license_id );

    this.setState({ phenom_licenses_revoke, delete_user_id: deleted_user.id }, () => {
      this.clearInvokePhenomLicense(deleted_user.license_id, deleted_user.id);
    })
  }

  handleCreatePhenomLicense = () => {
    const { selectedAccountName } = this.state;
    const aYearFromNow = new Date();
    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);
    
    return _ajax({
      url: "/index.php?r=/skayl/create-phenom-license",
      method: "post",
      data: {
        account_name: selectedAccountName,
        expire_date: aYearFromNow.toISOString().replace(/\.\d+Z/, ""),    // need to remove milliseconds (Z)
      }
    }).then(response => {
      if (response.status === "success") {
        receiveLogs("Successfully created PHENOM license.");

        BasicAlert.show("Refreshing data", "One moment...", false);

        // reset state
        this.fetchAccountInfo(selectedAccountName).always(() => BasicAlert.hide());
      }

      receiveResponse(response);
    })
  }

  handleCreateCincLicense = (type) => {
    const { selectedAccountName } = this.state;
    const aYearFromNow = new Date();
    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);
    
    return _ajax({
      method: "post",
      url: "/index.php?r=/skayl/create-cinc-license",
      data: {
        account_name: selectedAccountName,
        type: type || null,
      }
    }).then((response) => {

      if (response.status === "success") {
        receiveLogs("Successfully created CinC license.");

        BasicAlert.show("Refreshing data", "One moment...", false);

        // reset state
        this.fetchAccountInfo(selectedAccountName).always(() => BasicAlert.hide());
      }

      receiveResponse(response);
    })
  }

  handleShowLogs = (logs) => {
    this.setState({
      showLogs: true,
      logs: logs
    });
  }

  handleUpdatePhenomExpireDates = (currentExpireDate, newExpireDate) => {
    const { phenom_licenses, phenom_licenses_new_date } = this.state;

    if (!newExpireDate) {
      return
    }

    const new_date = newExpireDate.toISOString().replace(/\.\d+Z/, "");    // need to remove milliseconds (Z)
    const current_date = currentExpireDate === null ? null : currentExpireDate.toDateString();
    
    for (let license in phenom_licenses) {
      const id = phenom_licenses[license].license_id;
      var license_date = phenom_licenses_new_date[id] || phenom_licenses[license].expiration_date || null;
      if (license_date !== null) {
        license_date = new Date(license_date).toDateString();
      }

      if (license_date === current_date) {
        phenom_licenses_new_date[id] = new_date;
      }
    }

    this.setState({
      phenom_licenses_new_date
    });
  }

  handleChangeCincType = (license_id, new_type) => {
    
    if (!license_id || !new_type) {
      return;
    }

    const cinc_licenses_change_type = { ...this.state.cinc_licenses_change_type };
          cinc_licenses_change_type[license_id] = new_type;

    this.setState({ cinc_licenses_change_type });
  }

  render() {
    const { account_names, selectedAccountName, account_info, users, cinc_licenses, cinc_licenses_change_type, cinc_versions, available_cinc_versions, associate_cinc_versions, phenom_licenses, phenom_licenses_revoke, all_projects, createNewAccount, createNewUsers, phenom_licenses_new_date, invoke_phenom_licenses_to_users, invoke_users_to_phenom_licenses, delete_user_id } = this.state;

    return <div className='fill-vertical'>
      <SkaylSubMenu>
        <button className="fas fa-save"
                title="Save"
                onClick={this.handleUpdateAccount} />
      </SkaylSubMenu>

      <div className='phenom-content-scrollable'>
        <div className="edit-form-container">
          <PhenomComboBox label="Accounts"
                          data={account_names}
                          value={selectedAccountName}
                          placeholder="Select an account"
                          onChange={(selectedAccountName) => this.setState({ selectedAccountName })}
                          onClickPlusIcon={() => this.setState({ createNewAccount: true })} />

          <PhenomCollapsable label="Account Information" startCollapsed={false}>
            <SkaylAccountInfo account_info={account_info}
                              cinc_licenses={cinc_licenses}
                              phenom_licenses={phenom_licenses}
                              style={{ padding: 10 }} />
          </PhenomCollapsable>

          <PhenomCollapsable label="Users">
            <SkaylUsers users={users}
                        all_projects={all_projects}
                        phenom_licenses={phenom_licenses}
                        phenom_licenses_revoke={phenom_licenses_revoke}
                        phenom_licenses_new_date={phenom_licenses_new_date}
                        invoke_users_to_phenom_licenses={invoke_users_to_phenom_licenses}
                        delete_user_id={delete_user_id}
                        toggleRevokePhenomLicense={this.toggleRevokePhenomLicense}
                        assignInvokePhenomLicense={this.assignInvokePhenomLicense}
                        assignNewExpireDatePhenomLicense={this.assignNewExpireDatePhenomLicense}
                        clearInvokePhenomLicense={this.clearInvokePhenomLicense}
                        changeUserPassword={this.changeUserPassword}
                        ref={this.usersRef} 
                        onClickCreateNewUsers={() => this.setState({ createNewUsers: true })}
                        selectedAccountName={selectedAccountName} />
          </PhenomCollapsable>


          <PhenomCollapsable label="PHENOM Licenses">
            <SkaylPhenomLicenses users={users}
                                 licenses={phenom_licenses}
                                 phenom_licenses_revoke={phenom_licenses_revoke}
                                 phenom_licenses_new_date={phenom_licenses_new_date}
                                 invoke_phenom_licenses_to_users={invoke_phenom_licenses_to_users}
                                 toggleRevokePhenomLicense={this.toggleRevokePhenomLicense}
                                 assignInvokePhenomLicense={this.assignInvokePhenomLicense}
                                 assignNewExpireDatePhenomLicense={this.assignNewExpireDatePhenomLicense}
                                 clearInvokePhenomLicense={this.clearInvokePhenomLicense}
                                 selectedAccountName={selectedAccountName}
                                 handleCreatePhenomLicense={this.handleCreatePhenomLicense}
                                 handleUpdatePhenomExpireDates={this.handleUpdatePhenomExpireDates} />
          </PhenomCollapsable>

          <PhenomCollapsable label="CinC Licenses">
            <SkaylCincLicenses licenses={cinc_licenses}
                               cinc_licenses_change_type={cinc_licenses_change_type}
                               selectedAccountName={selectedAccountName}
                               handleChangeCincType={this.handleChangeCincType}
                               handleCreateCincLicense={this.handleCreateCincLicense}
                               showLogs={this.handleShowLogs} />
          </PhenomCollapsable>

          <PhenomCollapsable label="CinC Versions">
            <SkaylCincVersions versions={cinc_versions} 
                               available_cinc_versions={available_cinc_versions}
                               associate_cinc_versions={associate_cinc_versions}
                               accountName={selectedAccountName}
                               fetchAccountInfo={this.fetchAccountInfo}
                               ref={this.cincVersionsRef}
                               accountVersions />
          </PhenomCollapsable>

          <PhenomModal show={createNewAccount}
                       onSave={this.handleCreateNewAccount}
                       onClose={() => this.setState({ createNewAccount: false })}>
              <CreateNewAccount ref={this.newAccountRef} />

          </PhenomModal>
          <PhenomModal show={createNewUsers}
                       onSave={this.handleCreateNewUsers}
                       onClose={() => this.setState({ createNewUsers: false })}>
              <CreateNewUsers ref={this.newUsersRef} 
                              selectedAccountName={selectedAccountName} 
                              licenses={phenom_licenses}/>
          </PhenomModal>
          <PhenomModal show={!!this.state.changePasswordFor}
                       onSave={this.handleSaveUserPassword}
                       onClose={() => this.changeUserPassword()}>
            <ChangeSubUserPassword subUsername={this.state.changePasswordFor}
                                   ref={this.changePasswordRef} />
          </PhenomModal>
          <PhenomModal show={this.state.showLogs} 
                       onClose={()=> this.setState({ showLogs: false })}>
            {this.state.logs.map(x => {
              return(<div style={{margin: 10}}>
                {new Date(x.generation_time).toLocaleString()}: {x.user} generated {x.version} with {x.name}
              </div>)
            })}
          </PhenomModal>
        </div>
      </div>

      <DeleteUserPrompt deleteUserFromDB={this.deleteUserFromDB} />

    </div>
  }
}




class SkaylUsers extends React.Component {

  state = {
    users: [],
    reload_workspaces: new Set(),
  }

  componentDidMount() {
    this.initUserList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.users !== prevProps.users) {
      this.initUserList();
    }

    if (this.props.delete_user_id !== prevProps.delete_user_id) {
      this.removeDeletedUser();
    }

    if (prevState.reload_workspaces !== this.state.reload_workspaces) {
      this.debounceReloadWorkspaces();
    }
  }

  debounceReloadWorkspaces = debounce(() => {
    if (!this.state.reload_workspaces.size) {
      return;
    }

    _ajax({
      method: "post",
      url: "/index.php?r=/skayl/reload-workspaces",
      data: {
        model_ids: [...this.state.reload_workspaces],
      }
    }).then(response => {
      const users = [...this.state.users];
      const rw = new Set(this.state.reload_workspaces);

      if (Array.isArray(response.data?.loaded)) {
        users.forEach(user => {
          const project_id = user.current_project_id;
          if (response.data.loaded.includes(project_id)) {
            user.workspace_loaded = true;
          }
        })

        response.data.loaded.forEach(id => rw.delete(id));
      }

      this.setState({ users, reload_workspaces: rw });
    })
  }, 10000);

  initUserList = () => {
    const userList = Object.values(this.props.users);
    this.setState({ users: cloneDeep(userList) });
  }


  isUserEdited = (user) => {
    const { phenom_licenses_revoke, invoke_users_to_phenom_licenses, phenom_licenses_new_date } = this.props;
    const original = this.props.users[user?.id];

    if (!original) {
      return true;
    }

    // compare user attributes with original
    let isAttrEdited = ["name", "email", "active", "isAdmin", "new_license_id", "current_project_id"].some(key => user[key] !== original[key]);

    // check if user's license is to be revoked:
    let isRevokeLicense = phenom_licenses_revoke.has(user.license_id);

    // check if user is to receive a new license:
    let newLicenseId = invoke_users_to_phenom_licenses[user.id];

    return isAttrEdited || isRevokeLicense || newLicenseId;
  }

  removeDeletedUser = () => {
    const users = [ ...this.state.users ];
    const removeIdx = users.findIndex(u => u.id === this.props.delete_user_id);
    if (removeIdx > -1) users.splice(removeIdx, 1);
    this.setState({ users });
  }

  generateRequest = () => {
    const request = [];

    for (let user of this.state.users) {
      if (!this.isUserEdited(user)) {
        continue;
      }

      request.push({
        id: user.id,
        name: user.name,
        email: user.email,
        active: user.active,
        isAdmin: user.isAdmin,
        currentProjectId: user.current_project_id,
      })
    }

    return request;
  }

  // Copied from subscription page because I didn't want to refactor it
  checkUserPModelsAndDelete = (user) => {
    return _ajax({
        url: "/index.php?r=/referencing-model/users-index",
        data: {passedUser: user.name}
    }).then((res) => {
        const data = res.data.usersModels;
        const pTree = [];

        data.filter(el => !/^$|.*i.*/.test(el.permissions[user.name])).forEach(el => {
           const {id, name, description, permissions, subModels} = el;
           pTree.push({
                text: name,
                highlighted: false,
                additionalData: {
                    id,
                    description,
                    permissions
                },
                type: "project",
            });
        });

        return fetchModelTree(true, user.name).then((res) => {
            let mTree = [];
            let branches = Array.from(res);
            while (branches.length) {
                const branch = branches.shift();
                branch.children.forEach(branchRef => {
                    mTree.push(branchRef);
                    branches = branches.concat(branchRef.children);
                });
            }
            DeleteUserPrompt.show(user, pTree, mTree.filter(leaf => !/^$|.*i.*/.test(leaf.additionalData.permissions[user.name])), true);
        });
    });
  }

  handleChangeUser = (id, key, value) => {
    if (!id || !key) {
      return;
    }

    this.setState((prevState) => {
      const users = [...prevState.users];
      const idx = users.findIndex(u => u.id === id);

      if (idx > -1) {
        users[idx] = { 
          ...users[idx], 
          [key]: value 
        };
      }

      return { users }
    })
  }

  renderPhenomLicenseCell = (cellProps) => {
    const { phenom_licenses, invoke_users_to_phenom_licenses, phenom_licenses_revoke, toggleRevokePhenomLicense, assignInvokePhenomLicense, clearInvokePhenomLicense } = this.props;
    const user = cellProps.dataItem;
    
    if (user.license_id) {
      return <UserAssignedPhenomLicenseCell user_id={user.id}
                                            license_id={user.license_id}
                                            isRevoke={phenom_licenses_revoke.has(user.license_id)}
                                            onClick={() => toggleRevokePhenomLicense(user.license_id)} />
    }

    return <UserUnassignedPhenomLicenseCell user_id={user.id}
                                            phenom_licenses={phenom_licenses}
                                            invoke_users_to_phenom_licenses={invoke_users_to_phenom_licenses}
                                            onChange={(license) => assignInvokePhenomLicense(license.license_id, user.id)}
                                            onClickCancelIcon={() => clearInvokePhenomLicense(undefined, user.id)} />

  }

  renderRow = (_, cellProps) => {
    const { all_projects } = this.props;
    const { reload_workspaces } = this.state;
    const user = cellProps.dataItem;
    const isEdited = this.isUserEdited(user);
    const expire_date = user.expiration_date;
    const project_id = user.current_project_id;
    const workspace_loaded = user.workspace_loaded;

    return <tr style={{ background: isEdited ? "hsl(var(--skayl-sky-hs) 86%)" : null }}>
      <UserNameCell user_id={user.id}
                    username={user.name}
                    onChange={(e) => this.handleChangeUser(user.id, "name", e.target.value)} />

      <UserEmailCell user_id={user.id}
                     email={user.email}
                     onChange={(e) => this.handleChangeUser(user.id, "email", e.target.value)} />

      <UserActiveCell user_id={user.id}
                      active={user.active}
                      onChange={(e) => this.handleChangeUser(user.id, "active", e.target.value === "Enabled")} />

      <UserAdminCell user_id={user.id}
                     isAdmin={user.isAdmin}
                     onChange={(e) => this.handleChangeUser(user.id, "isAdmin", e.target.checked)} />

      { this.renderPhenomLicenseCell(cellProps) }

      <UserExpireDateCell dateRaw={expire_date} />

      <UserPasswordCell user_id={user.id}
                        onClick={() => this.props.changeUserPassword(user.name)} />

      <UserProjectsCell username={user.name} />

      <UserCurrentProjectCell all_projects={all_projects}
                              current_project_id={user.current_project_id}
                              available_projects={user.available_projects}
                              onChange={(proj) => this.handleChangeUser(user.id, "current_project_id", proj.id)} />

      <td style={{ display: "flex", alignItems: "center", gap: 5 }}>
        { workspace_loaded ? "Loaded" : "Not Loaded" }

        { reload_workspaces.has(project_id) 
          ? <Button iconClass="fa-fw fa-solid fa-circle-info"
                    title="Every 10 seconds the server is pinged to see if the model finished loading." />
          : !workspace_loaded &&
              <Button iconClass="fa-fw fa-solid fa-power-off"
                      onClick={() => {
                        this.setState(prevState => { 
                          const rw = new Set(prevState.reload_workspaces);
                          rw.add(user.current_project_id);
                          return { reload_workspaces: rw }
                        });
                      }} />
        }
      </td>

      <FormattedDateCell dateRaw={user.last_login} />

      <UserDeleteCell user_id={user.id}
                      onClick={() => this.checkUserPModelsAndDelete(user)} />
    </tr>
  }

  render() {
    const { users } = this.state; 

    return <div>
      <Grid data={users}
            rowRender={this.renderRow}>
        <GridNoRecords>
          No data available
        </GridNoRecords>

        <GridColumn title="Username" />
        <GridColumn title="Email" />
        <GridColumn title="Active" width="130px" />
        <GridColumn title="Admin" width="100px" />
        <GridColumn title="Phenom License" />
        <GridColumn title="Expiration Date" />
        <GridColumn title="Password" width="90px" />
        <GridColumn title="Projects" width="75px" />
        <GridColumn title="Current Project" />
        <GridColumn title="Workspace" width="100px" />
        <GridColumn title="Last login" width="200px" />
        <GridColumn title="Delete" width="70px" />
      </Grid>
      {this.props.selectedAccountName && <Toolbar>
        <ToolbarItem>
          <Button onClick={this.props.onClickCreateNewUsers} icon="plus">Add Users</Button>
        </ToolbarItem>
      </Toolbar>}
    </div>
  }
}








const StyledCincVersions = styled.div`
  .k-checkbox-label {
    padding-left: 24px;
  }
`

class CreateNewAccount extends React.Component {
  constructor(props) {
    super(props);

    const aYearFromNow = new Date();
    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);

    this.state = {
      name: "",
      role: "FULL",
      license: "COMMERCIAL",
      num_phenom_licenses: 0,
      num_cinc_licenses: 0,
      cinc_versions_list: cincVersions.map(version => ({ version, checked: false, })),
      expire_date: aYearFromNow,
      eval_license: true,
    }
  }

  generateAccountData = () => {
    return {
      account_name: this.state.name,
      role: this.state.role,
      license: this.state.license,
      num_phenom_licenses: this.state.num_phenom_licenses,
      num_cinc_licenses: this.state.num_cinc_licenses,
      expire_date: this.state.expire_date.toISOString().replace(/\.\d+Z/, ""),    // need to remove milliseconds (Z)
      cinc_versions: this.state.cinc_versions_list.filter(cinc => cinc.checked).map(cinc => cinc.version),
      eval_license: this.state.eval_license
    }
  }

  handleChangePhenomLicenses = (e) => {
    let num_phenom_licenses = parseInt(e.target.value);

    // invalid value
    if (isNaN(num_phenom_licenses) || num_phenom_licenses < 0) {
      num_phenom_licenses = 0;
    }

    this.setState({ num_phenom_licenses });
  }

  handleChangeCincLicenses = (e) => {
    let num_cinc_licenses = parseInt(e.target.value);

    // invalid value
    if (isNaN(num_cinc_licenses) || num_cinc_licenses < 0) {
      num_cinc_licenses = 0;
    }

    this.setState({ num_cinc_licenses });
  }

  handleChangePhenomLicenseExpireDate = (value, idx) => {
    this.setState((prevState) => {
      const phenom_licenses = [...prevState.phenom_licenses];
      phenom_licenses[idx] = { ...phenom_licenses[idx], expire_date: value };
      return { phenom_licenses };
    })
  }

  handleChangeCincVersionCheckbox = (checked, idx) => {
    this.setState((prevState) => {
      const cinc_versions_list = [...prevState.cinc_versions_list];
      cinc_versions_list[idx] = { ...cinc_versions_list[idx], checked };
      return { cinc_versions_list };
    })
  }

  handleChangeRole = (e) => {
    const role = e.target.value;
    this.setState({role: role});
    this.setState({eval_license: (role === "FULL" || role === "CINCWORKS")})
  }

  render() {
    const { name, license, role, num_phenom_licenses, num_cinc_licenses, cinc_versions_list, expire_date, eval_license } = this.state;

    return <div className="edit-form-container">
              <div className="edit-form">
                <PhenomInput label="Account Name"
                              value={name}
                              onChange={(e) => this.setState({ name: e.target.value })}
                              config={{
                                required: true,
                              }} />

                <div className="p-row">
                  <div className="p-col p-col-6">
                    <PhenomSelect label="License"
                                  data={accountLicenses}
                                  value={license}
                                  onChange={(e) => this.setState({ license: e.target.value })} />
                  </div>
                  <div className="p-col p-col-6">
                    <PhenomSelect label="Role"
                                  data={accountRoles}
                                  value={role}
                                  onChange={this.handleChangeRole} />
                  </div>
                </div>

                <div className="p-row">
                  <div className="p-col p-col-6">
                    <PhenomInput label="PHENOM Licenses"
                                  value={num_phenom_licenses}
                                  type="number"
                                  placeholder="Number of PHENOM Licenses"
                                  onChange={this.handleChangePhenomLicenses} />
                  </div>
                  <div className="p-col p-col-6">
                    <PhenomInput label="CinC Licenses"
                                  value={num_cinc_licenses}
                                  type="number"
                                  placeholder="Number of CinC Licenses"
                                  onChange={this.handleChangeCincLicenses} />
                  </div>
                </div>

                <div className="p-row">
                    <div className="p-col p-col-6"> 
                      {(!!num_phenom_licenses) && <div>
                      <PhenomLabel text="PHENOM License(s) Expiration Date" />
                      <DatePicker value={expire_date}
                                  onChange={(e) => {
                                    if (!e.target.value) {
                                      const aYearFromNow = new Date();
                                      aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);
                                      return this.setState({ expire_date: aYearFromNow });
                                    }

                                    this.setState({ expire_date: e.target.value })}
                                  } />
                      </div> }
                    </div>
                    <div className="p-col p-col-6" style={{gap: 0}}>
                      <div className="p-row" style={{gap: "5px"}}>
                        <input type="checkbox" 
                                checked={eval_license} 
                                onChange={(e) => this.setState({eval_license: e.target.checked})}/>
                        <label>Enable CinC EVAL License</label>
                      </div>
                    </div>
                </div>


                <StyledCincVersions>
                  <PhenomLabel text="CinC Versions" />
                  {cinc_versions_list.map((cinc, idx) => {
                    return <div>
                      <Checkbox label={cinc.version}
                                checked={cinc.checked}
                                onClick={(e) => this.handleChangeCincVersionCheckbox(e.target.checked, idx)} />
                      </div>
                  })}
                </StyledCincVersions>
              </div>
            </div>
  }
}

class CreateNewUsers extends React.Component {
  projectSelectRef = React.createRef();
  
  constructor(props) {
    super(props);

    this.state = {
      accountInfo: {},
      models: [],
      demo_account: false,
      create_licenses: false,
      expire_date: null,
      users: [],
      editID: null,
      licenses: { "NEW LICENSE": { license_id: "NEW LICENSE" }, ...cloneDeep(this.props.licenses) },
      invoke_users_to_phenom_licenses: {},   // <user_id>: <license_id>
    }

  }

  componentDidMount() {
    this.addUser();
    this.loadData();
  }

  loadData = () => {
      return Promise.all([ getAccountInfo() ]).then(res => {
          const resp1 = res[0];
    
          if ("error" in resp1 || "errors" in resp1) {
            return receiveErrors(resp1["error"] || resp1["errors"]);
          }

          this.setState({
              accountInfo: resp1.data.accountInfo,
              models: resp1.data.accountInfo.models,
          });
      })
  }

  generateNewUsers = () => {
    const { demo_account, users, licenses, invoke_users_to_phenom_licenses } = this.state;
    const { selectedAccountName } = this.props;

    const projectData = this.projectSelectRef.current.generateProjectData();

    const usersWithProject = users.map((user) => {
      const license_id = invoke_users_to_phenom_licenses[user.id];

      user.personalWs = projectData.personalWs;
      user.perms = projectData.perms;
      user.license_id = license_id;

      // lincese_id is either undefined or string
      if (license_id && license_id.startsWith("New License")) {
        user.license_id = "NEW LICENSE";              // backend is looking for this string
      }

      const license = licenses[license_id];
      if (license) {
        user.expire_date = license.expiration_date;   // backend is looking for this string
      }

      return user;
    });

    return {
      accountName: selectedAccountName,
      default_project: projectData?.selectedProject.id,
      demo_account, 
      users: usersWithProject,
      projectPerms: projectData?.perms,
      projectName: projectData?.selectedProject.name,
    }
  }

  addUser = () => {
    const { users } = this.state;
    const newUser = {
      id: createPhenomGuid(),
      name: "",
      email: "",
      isAdmin: false,
      license_id: null,
      expire_date: null
    }
    this.setState({
      users: [...users, newUser],
    })
  }

  handleChangeUser = (id, key, value) => {
    if (!id || !key) {
      return;
    }

    this.setState((prevState) => {
      const users = [...prevState.users];
      const idx = users.findIndex(u => u.id === id);

      if (idx > -1) {
        users[idx] = { 
          ...users[idx], 
          [key]: value 
        };
      }

      return { users }
    })
  }

  handleLicenseChange = (user, license) => {
    if (!user?.id) {
      return;
    }

    const licenses = { ...this.state.licenses };
    const invoke_users_to_phenom_licenses = { ...this.state.invoke_users_to_phenom_licenses };

    // remove previously assigned license
    delete invoke_users_to_phenom_licenses[user.id];

    // User clicked Clear License
    if (!license) {
      return this.setState({ licenses, invoke_users_to_phenom_licenses });
    }

    // User clicked New License
    if (license.license_id === "NEW LICENSE") {
      const newLicense = this.createNewLicense();
      licenses[newLicense.license_id] = newLicense;
      invoke_users_to_phenom_licenses[user.id] = newLicense.license_id;

    } else {
      // User clicked existing License
      invoke_users_to_phenom_licenses[user.id] = license.license_id;
    }

    return this.setState({ licenses, invoke_users_to_phenom_licenses });
  }

  handleExpireDateChange = (license_id, expire_date) => {
    if (!license_id || !expire_date) {
      return;
    }

    let license = this.state.licenses[license_id];
    if (!license) {
      return;
    }

    const new_date = expire_date.toISOString().replace(/\.\d+Z/, "");    // need to remove milliseconds (Z)
    
    license.expiration_date = new_date;
    this.forceUpdate();
  }

  createNewLicense = () => {
    const license_count = Object.keys(this.state.licenses).length;  // this is not off by one because "NEW LICENSE" was added in constructor

    return {
      license_id: `New License #${license_count}`,
      expiration_date: null
    }
  }

  renderRow = (_, cellProps) => {
    const user = cellProps.dataItem;
    const license_id = this.state.invoke_users_to_phenom_licenses[user.id];
    const license = this.state.licenses[license_id];

    return <tr>
      <UserNameCell username={user.name}
                    onChange={(e) => this.handleChangeUser(user.id, "name", e.target.value)} />

      <UserEmailCell username={user.name}
                    email={user.email}
                    onChange={(e) => this.handleChangeUser(user.id, "email", e.target.value)} />

      <UserAdminCell username={user.name}
                    isAdmin={user.isAdmin}
                    onChange={(e) => this.handleChangeUser(user.id, "isAdmin", e.target.checked)} />

      <UserUnassignedPhenomLicenseCell user_id={user.id}
                                       phenom_licenses={this.state.licenses}
                                       invoke_users_to_phenom_licenses={this.state.invoke_users_to_phenom_licenses}
                                       onChange={(license) => this.handleLicenseChange(user, license)}
                                       onClickCancelIcon={() => this.handleLicenseChange(user, undefined)} />

      {license 
        ? <UserExpireDateCell dateRaw={license.expiration_date} 
                              onChange={(e) => this.handleExpireDateChange(license_id, e.target.value)}/>
        : <td /> }
    </tr>
  }

  render() {
    const { expire_date, users, models, } = this.state;

    return <div className="edit-form-container">
              <div className="edit-form">
                <PhenomLabel text="Default Project" />
                <ProjectSelection models={models}
                                  ref={this.projectSelectRef}/>
                <Checkbox id={"chb2"}
                          onClick={(e) => this.setState({ demo_account: e.target.checked })} >
                  <label htmlFor="chb2"
                         className="k-checkbox-label" 
                         style={{ color: "#565656",
                                  fontWeight: "normal"
                         }}>Demo Account Password
                  </label>
                </Checkbox>
                <div>
                  <Grid data={users}
                        rowRender={this.renderRow}>
                    <GridColumn title="Username" />
                    <GridColumn title="Email" />
                    <GridColumn title="Admin" width="100px" />
                    <GridColumn title="Phenom License" />
                    <GridColumn title="Expiration Date" />
                  </Grid>
                  <Toolbar>
                    <ToolbarItem>
                      <Button onClick={this.addUser} icon="plus" >Add User</Button>
                    </ToolbarItem>
                  </Toolbar>
                </div>
                

                <PhenomLabel text="License(s) Expiration Date" />
                <DatePicker value={expire_date}
                            onChange={(e) => this.setState({ expire_date: e.value, create_licenses: true })} />
                
              </div>
           </div>
  }

}

export default SkaylAccounts;
