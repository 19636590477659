import React from 'react'
import { CSSTransition } from 'react-transition-group'
import { _ajax } from '../../requests/sml-requests'
import { cloneDeep } from 'lodash'
import { createPhenomGuid, stopBubbleUp } from '../util/util'
import { Notifications2 } from '../edit/notifications'
import { modelGetNode, smmSaveNodes } from '../../requests/sml-requests'
import { connect } from 'react-redux'
import { SubMenuRight } from '../edit/edit-top-buttons'
import NavTree from '../tree/NavTree'


class ModalNodeBuilder extends React.Component {
  constructor(props) {
    super(props);
    ModalNodeBuilder.__singleton = this;
  }

  // ------------------------------------------------------------
  // State
  // ------------------------------------------------------------
  defaultState = {
    activeIdx: 0,
    editors: [],
    nodesOfType: {},
    animateIn: true,
  }
  state = cloneDeep(this.defaultState);

  // ------------------------------------------------------------
  // Life Cycle Methods
  // ------------------------------------------------------------
  componentDidMount() {
    // close modal if user clicked back
    window.onpopstate = e => {
      if (this.state.editors.length) {
        this.close();
      }
    }
  }

  // ------------------------------------------------------------
  // Static Methods
  // ------------------------------------------------------------

  /**
   * 
   * @param {Component} component Base Component to be rendered
   * @param {node} node Javascript object formatted as a model node.  defaults to "new node" if not provided.
   * @param {function} [onSaveCallback] optional function to be triggered after save
   * @param {boolean} isFetchNodeData fetches the node if true, otherwise use the provide node.
   * @param {boolean} isLocalSaveOnly do not save to database if true.  
   * 
   */
  static show = (editor) => {
    let { component } = editor;

    // Base Component must be provided
    if (!component) return;

    // defaults to "new node"
    if (!editor.node) {
      editor.node = { guid: createPhenomGuid(), ...cloneDeep(component?.defaultProps?.newNode) }
      editor.isFetchNodeData = false;
    }

    const that = ModalNodeBuilder.__singleton;
    const activeIdx = that.state.editors.length;

    // set default values
    editor.editable = true;
    
    that.setState((prevState) => ({
      activeIdx,
      editors: [ ...prevState.editors, editor ]
    }), () => {
      if (editor.isFetchNodeData) that.getModelNode(activeIdx);
      that.modelGetNodesOfType(activeIdx);
    })
  }

  // ------------------------------------------------------------
  // Getters
  // ------------------------------------------------------------
  getChangeSetId = () => {
    return this.state.changeSetId;
  }

  // ------------------------------------------------------------
  // Setters
  // ------------------------------------------------------------
  setChangeSetId = (e) => {
    this.setState({ changeSetId: e.target.value });
  }

  close = () => {
    this.setState({ animateIn: false }, () => {
      setTimeout(() => this.setState(cloneDeep(this.defaultState)), 300)
    })
  }

  closeTab = (idx) => {
    if (!idx && idx !== 0) idx = this.state.activeIdx;
    const editors = [...this.state.editors];
    editors.splice(idx, 1);

    if (!editors.length) {
      return this.close();
    }

    this.setState({ editors });
  }

  disableEditing = (disabled) => {
    const editors = [...this.state.editors];
    const editor = editors[this.state.activeIdx];
    editor.editable = !disabled;
    this.setState({ editors });
  }

  // ------------------------------------------------------------
  // Fetch Data
  // ------------------------------------------------------------
  getModelNode = (idx) => {
    const editors = [...this.state.editors];
    const editor = editors[idx];
    const { component, node } = editor;

    modelGetNode(node.guid, component.defaultProps?.nodeAddenda)
      .then(res => {
        editor.node = res;
        this.setState({ editors });
      })
  }

  modelGetNodesOfType = (idx) => {
    const editors = [...this.state.editors];
    const editor = editors[idx];
    const nodesOfTypeAddenda = editor.component?.defaultProps?.nodesOfTypeAddenda || {};

    for (let listName in nodesOfTypeAddenda) {
      if (listName in this.state.nodesOfType) continue;

      _ajax({
        url: "/index.php?r=/node/model-nodes-of-type",
        method: "get",
        data: nodesOfTypeAddenda[listName],
      })
      .then(res => {
        const list = res.data.nodes;

        this.setState((prevState) => ({
          nodesOfType: {
            ...prevState.nodesOfType,
            [listName]: list,
          }
        }))
      })
    }
  }

  // ------------------------------------------------------------
  // Event Handlers
  // ------------------------------------------------------------
  /**
   * Defaults to smm-save-nodes
   *    provide handleSave method to page component, if a different save method is required
   */
  handleSave = (idx) => {
    if (!idx && idx !== 0) idx = this.state.activeIdx;
    const editors = [...this.state.editors];
    const editor = editors[idx];
    const node = editor.ref.generateNode();
    
    if (editor.isLocalSaveOnly) {
      editor.onSaveCallback && editor.onSaveCallback(node);
      return this.closeTab(idx);
    }

    smmSaveNodes({
      node,
      changeSetId: this.getChangeSetId(),
      returnTypes: [node.xmiType],
      returnAddenda: {
        [node.xmiType]: editor.component.defaultProps?.nodeAddenda,
      }
    }).then(res => {
      const response = res.data;
      Notifications2.parseResponse(response);

      if (response.nodes?.length) {
        NavTree.addNodes(response.nodes);
        const node = response.nodes[0];
        editor.onSaveCallback && editor.onSaveCallback(node);
        this.closeTab(idx);
      }
    })
  }

  render() {
    const { canEdit, expired } = this.props;
    const { activeIdx, editors, nodesOfType } = this.state;
    if (!editors.length) return null;

    const isEditable = canEdit && !expired;

    return <div tabIndex={0} className="modal-container" onKeyDown={stopBubbleUp}>
            <CSSTransition in={this.state.animateIn}
                          timeout={300}
                          appear={true}
                          mountOnEnter
                          unmountOnExit
                          classNames="fadeLeft">
              <div className="modal-form">
                <div className="modal-editors phenom-content-wrapper">
                  <nav className="sub-menu-actions" aria-label='form actions'>
                    <SubMenuRight>
                      <button id="form-action-save"
                              className="fas fa-save"
                              title="Save"
                              disabled={!isEditable}
                              onClick={this.handleSave} />
                      <button id="form-action-close"
                              className="fas fa-times"
                              title="Close"
                              onClick={this.closeTab} />
                    </SubMenuRight>
                  </nav> 

                  <div className="phenom-content-scrollable">
                    {editors.map((editor, idx) => {
                      const { component: EditorComponent, node, editable, additionalProps } = editor;
                      const isDeprecated = node.deprecated === "true";
                      const isEditable = canEdit && editable && !isDeprecated && !expired;
                      
                      return <div className="phenom-edit-layout edit-form-container" style={{ display: activeIdx !== idx ? "none" : null}} key={node.guid}>
                              <EditorComponent node={node}
                                              nodesOfType={nodesOfType}
                                              editable={isEditable}
                                              disableEditing={this.disableEditing}
                                              changeSetId={this.state.changeSetId}
                                              autoComplete="off"
                                              ref={el => editor.ref = el}
                                              {...additionalProps} />
                              </div>
                    })}
                  </div>
                </div>
              </div>
            </CSSTransition>
            <CSSTransition in={this.state.animateIn}
                           timeout={300}
                           appear={true}
                           mountOnEnter
                           unmountOnExit
                           classNames="fade">
              <div className="modal-bg" />
            </CSSTransition>
    </div>
  }
}



const msp = (state) => {
  return {
    canEdit: state.user.canEdit,
    model_uid: state.user.model_uid,
    expired: state.user.expired,
  }
}


export default connect(msp)(ModalNodeBuilder);