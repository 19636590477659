import React from "react";
import {LineLabel, PhenomLabel} from "../util/stateless";
import PhenomLoadButton from "../widget/LoaderButton";
import ChangeSetTable from "../widget/ChangeSetTable";
import {Notifications,Notifications2} from "../edit/notifications";
import {Grid, GridColumn as Column, GridNoRecords} from "@progress/kendo-react-grid";
import {orderBy} from "@progress/kendo-data-query";
import $ from "jquery";
import PhenomId from "../../requests/phenom-id"

export default class ChangeSetTree extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageName: "",
            changeSets: [],
            changeSetContent: false,
            selectedChangeSet: false,
        };
        this.phenomId = new PhenomId("change-set-tree",this.props.idCtx);
    }

    componentDidMount() {
        this.getChangeSets();
        // this.setState({pageName: this.props.pageName});
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if (prevProps.pageName !== this.props.pageName) {
    //         this.setState({pageName : this.props.pageName,});
    //     }
    //     if (this.state.pageName !==  prevState.pageName) {
    //         switch (this.state.pageName) {
    //             case "pull":
    //             case "push":
    //             case "approve":
    //                 this.getChangeSets(this.state.pageName);
    //                 break;
    //             default:
    //                 this.clearChangeSets();
    //         }
    //     }
    //     if(prevState.selectedChangeSet !== this.state.selectedChangeSet) {
    //         this.setState({changeSetContent: false}, () => this.refreshView(this.state.selectedChangeSet));
    //     }
    // }

    getChangeSets() {
        return $.ajax({
            method: "GET",
            url: "/index.php?r=/change-set/merge-index",
            data: { mergeMode: this.props.mergeMode },
        }).then(res => {
            const response = JSON.parse(res);
            Notifications2.parseResponse(response);
            if (response.status == "success") {
              this.setState({changeSets: response});
              this.getChangeSetContent();
            }
        });
    }

    clearChangeSets() {
        this.setState({changeSets: {}});
    }

    generateMainView(changeSetDatum) {
        const phenomId = this.phenomId;
        return <div className="branch-wrapper" id={phenomId.gen("generate-main-view","wrapper")}>
            <LineLabel text={changeSetDatum.Name} style={{marginBottom: 15}} idCtx={phenomId.gen("","name")}/>
            <Notifications ref={ele => this.noticeRef = ele}/>
            <p id={phenomId.gen("","description")}>{changeSetDatum.Description}</p>
            <div
            // id="pull-changes"
            id={phenomId.gen("pull-changes","wrapper")}
            style={{display:"flex"}}>
                <PhenomLoadButton
                    text={`${this.state.pageName.toUpperCase()} THIS CHANGE-SET`}
                    onClick={() => this.mergeChangeSet(changeSetDatum)}
                idCtx={phenomId.gen("pull-changes","merge-button")}/>
                {!!this.state.changeSetContent ||
                    <PhenomLoadButton
                        divStyle={{marginLeft: 15}}
                        text={"VIEW CHANGE-SET CONTENT"}
                        onClick={() => this.getChangeSetContent(changeSetDatum)}
                        idCtx={phenomId.gen("pull-changes","get-button")}/>}
            </div>
            {!this.state.changeSetContent ||
             <ChangeSetTable changeSetContent={this.state.changeSetContent} idCtx={phenomId.gen("generate-main-view")}/>}
        </div>
    }

    getChangeSetContent(changeSetDatum) {
        return $.ajax({
            method: "GET",
            url: "/index.php?r=/change-set/contents",
            data: {
                changeSetId: changeSetDatum.Change_Set_ID,
                srcModel: changeSetDatum.srcModel,
                mergeMode: this.state.pageName,
            },
        }).then(res => this.setState({changeSetContent: JSON.parse(res).contents}, () => this.refreshView(changeSetDatum)));
    }

    refreshView(changeSetDatum) {
        this.setState(
            {selectedChangeSet: changeSetDatum},
            () => this.props.setMainView(this.generateMainView(changeSetDatum))
        );
    }

    mergeChangeSet(changeSetDatum) {
        return $.ajax({
            method: "POST",
            url: "/index.php?r=/change-set/merge",
            data: {
                changeSetId: changeSetDatum.Change_Set_ID,
                srcModelId: changeSetDatum.srcModel,
                mode: this.state.pageName,
            },
        }).done(res => {
            if(res === "#true") {
                this.noticeRef.info("Merge of change-set content completed.");
            } else {
                this.noticeRef.parseErrors(res);
            }
        }).fail(_ => {
            this.noticeRef.error("The operation you requested could not be completed: the server responded in an unexpected fashion. Please try again.");
        });
    }

    render(){
      const phenomId = this.phenomId;

      return (
          <div className="tree-content" id={phenomId.gen("tree-container","wrapper")} ref={el => this.treeContainer = el} style={this.props.style}>
            <div className="tree-content-wrapper vertical">
                {this.state.changeSets.map((srcModel, srcIdx) => {
                    if (!srcModel.length) return null;
                    const project_name = srcModel[0]["project_name"];

                    return <>
                            {project_name &&
                            <PhenomLabel text={project_name} /> }

                            <ul>
                              {srcModel.map((changeSetDatum, datIdx) => {
                                let className = "tree-node";
                                if (this.props.selectedChangeSet === changeSetDatum) {
                                  className += " selected";
                                }

                                return <li key={changeSetDatum.Change_Set_ID} 
                                           className={className}
                                           onClick={() => this.props.onSelect(changeSetDatum)}>
                                            {changeSetDatum.Name}
                                       </li>
                              })}
                            </ul>
                    </>

                    // if (this.state.changeSets[srcModel].length){
                    //     return <React.Fragment>
                    //     <LineLabel text={this.state.changeSets[srcModel][0]["project_name"]} style={{marginBottom: 15, fontSize: 16}} idCtx={phenomId.gen(["tree-container","src-model",srcIdx,])}/>
                    //     {this.state.changeSets[srcModel].map((changeSetDatum,datIdx) => {
                    //         changeSetDatum.srcModel = srcModel;
                    //         return <div className="tree-node" style={{marginLeft: 15, cursor: "pointer"}} onClick={() => this.refreshView(changeSetDatum)} idCtx={phenomId.gen(["src-model",srcIdx,`sets-${datIdx}`],"refresh-view-div")}>
                    //         {changeSetDatum.Name}
                    //         </div>
                    //     })}
                    //     </React.Fragment>
                    // } else {
                    //     return null;
                    // }
                })}
            </div>
          </div>
        );}
}



