import React from 'react'
import $ from 'jquery'
import ChangeSetTree from '../../../tree/ChangeSetTree';
import ChangeSetTable from '../../../widget/ChangeSetTable';
import { capFirstChar } from '../../../util/util';
import PhenomLoadButton from '../../../widget/LoaderButton';
import { receiveErrors, receiveLogs } from '../../../../requests/actionCreators';


class ChangeSetManager extends React.Component {
  state = {
    selectedChangeSet: null,
    changeSetContent: false,
  }

  getChangeSetContent = () => {
    const { selectedChangeSet } = this.state;
    if (!selectedChangeSet) return;

    return $.ajax({
      method: "GET",
      url: "/index.php?r=/change-set/contents",
      data: {
          changeSetId: selectedChangeSet.Change_Set_ID,
          srcModel: selectedChangeSet.srcModelId,
          mergeMode: this.props.mergeMode,
      },
    }).then(res => {
      this.setState({ changeSetContent: JSON.parse(res).contents });
    })
  }

  mergeChangeSet = () => {
    const { selectedChangeSet } = this.state;
    if (!selectedChangeSet) return;

    return $.ajax({
        method: "POST",
        url: "/index.php?r=/change-set/merge",
        data: {
            changeSetId: selectedChangeSet.Change_Set_ID,
            srcModelId: selectedChangeSet.srcModelId,
            mode: this.props.mergeMode,
        },
    }).then(res => {
        if(res === "#true") {
            receiveLogs("Merge of change-set content completed");
        } else {
            receiveErrors(res);
        }
    })
  }

  selectChangeSet = (selectedChangeSet) => {
    if (selectedChangeSet === this.state.selectedChangeSet) return;
    this.setState({ selectedChangeSet, changeSetContent: false });
  }

  render() {
    return <div style={{ display: "flex", flex: 1, gap: "1em", overflow: "hidden" }}>
            <div style={{ flex: "1 1 30%" }}>
              <ChangeSetTree mergeMode={this.props.mergeMode}
                             selectedChangeSet={this.state.selectedChangeSet}
                             onSelect={this.selectChangeSet} />

            </div>
            <div style={{ flex: "1 1 70%", overflow: "auto" }}>
              {this.state.selectedChangeSet &&
              <div style={{ display: "flex", gap: 10 }}>
                <PhenomLoadButton text={`${capFirstChar(this.props.mergeMode)} Change Set`}
                                  onClick={this.mergeChangeSet} />
                <PhenomLoadButton text="View Change Set Content"
                                  onClick={this.getChangeSetContent} />
              </div> }
              
              {this.state.changeSetContent &&
              <ChangeSetTable changeSetContent={this.state.changeSetContent} /> }
            </div>

          </div>
  }
}

export default ChangeSetManager;