import React, { useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styled from "@emotion/styled";
import { jsx, css, keyframes } from '@emotion/core'
import PhenomId from "../../requests/phenom-id";
import { SubMenuRight } from "../edit/edit-top-buttons";


export class Modal extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const phenomId = new PhenomId("modal",this.props.idCtx);
        return (
            <div className="react-modal" style={this.props.style} id={phenomId.gen("","wrapper")}>
                <button className="bordered-button" onClick={() => this.props.closeModal()}
                        style={{float: "right"}}
                        id={phenomId.gen("","close-button")}>CLOSE
                </button>
                {this.props.componentToShow}
            </div>
        );
    }
}

const duration = 200;
export const Modal2 = ({show, children, onExitedCallback,idCtx}) => {
    return (
        <CSSTransition key="trans-1"
                        in={show}
                        timeout={duration}
                        appear={true}
                        mountOnEnter
                        unmountOnExit
                        classNames="fade">
            <Container>
                <Content>
                    {children}
                </Content>
                <Backdrop />
            </Container>
        </CSSTransition>
    )
}


export const PhenomModal = (props) => {
  const [ isMounted, setIsMounted ] = useState(false);
  const [ animateIn, setAnimateIn ] = useState(false);

  useEffect(() => {
    if (props.show) {
      setAnimateIn(true);
      setIsMounted(true);

    } else {
       // wait for animation to finish before closing modal
      setAnimateIn(false);
      setTimeout(() => {
        setIsMounted(false);
      }, 300);
    }
  }, [props.show])


    if (!isMounted) {
      return null;
    }

    return <div className="modal-container">
            <CSSTransition in={animateIn}
                        timeout={300}
                        appear={true}
                        mountOnEnter
                        unmountOnExit
                        classNames="fadeLeft">
              <div className="modal-form">
                <nav className="sub-menu-actions" aria-label='form actions'>
                  <SubMenuRight>
                    {props.onSave &&
                    <button id="form-action-save"
                            className="fas fa-save"
                            title="Save"
                            onClick={props.onSave} /> }
                    
                    {props.onClose &&
                    <button id="form-action-close"
                            className="fas fa-times"
                            title="Close"
                            onClick={props.onClose} /> }
                  </SubMenuRight>  
                </nav> 
                <div className="phenom-content-scrollable">
                {props.children}
                </div>
              </div>
            </CSSTransition>
            <CSSTransition in={animateIn}
                        timeout={300}
                        appear={true}
                        mountOnEnter
                        unmountOnExit
                        classNames="fade">
              <div className="modal-bg" />
            </CSSTransition>
          </div>
}





const Container = styled.div`
    position: fixed;
    top:0;
    right:0;
    bottom:0;
    left:0;
    z-index:15;
`

const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index:1;
`
//     animation-duration: ${duration}ms;
//     animation-timing-function: ease-in-out;
//     animation-name: ${(props) => props.state === "entering" ? props.animateIn || zoomIn :
//                                  props.state === "exiting" ? props.animateOut || fadeOut : null};
// `

const FlyoutContent = styled.div`
    position:absolute;
    right:0;
    width:900px;
    height:100%;
    background:#fff;
    z-index: 1;
    overflow-y: scroll;
    animation-duration: ${duration}ms;
    animation-name: ${({state}) => state === "entering" ? slideInRight :
                                   state === "exiting" ? slideOutRight : null};
`

const Backdrop = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.8);
    backdrop-filter: blur(3px);
`
//     animation-duration: ${duration}ms;
//     animation-timing-function: ease-out;
//     animation-name: ${({state}) => state === "entering" ? fadeIn :
//                                    state === "exiting" ? fadeOut : null};
// `



const sweetAnimation = keyframes`
    0% {
        transform: scale(0.7);
    }
    45% {
        transform: scale(1.05);
    }
    80% {
        transform: scale(0.95);
    }
    100% {
        transform: scale(1);
    }

`


const slideInRight = keyframes`
    from {
        transform: translate3d(100%, 0, 0);
        visibility: hidden;
    }
    to {
        transform: translate3d(0, 0, 0);
        visibility: visible;
    }
`

const slideOutRight = keyframes`
    from {
        transform: translate3d(0, 0, 0);
        visibility: visible;
    }
    to {
        transform: translate3d(100%, 0, 0);
        visibility: hidden;
    }
`

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

const fadeOut = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`

const zoomIn = keyframes`
    from {
      opacity: 0;
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
    }

    100% {
      opacity: 1;
    }

`

const bounceInDown = keyframes`
    0% {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }
    50% {
        opacity: 1;
        transform: translate3d(0, 10%, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
`


const bounceOutUp = keyframes`
    0% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
    50% {
        transform: translate3d(0, 10%, 0);
    }
    100% {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }
`
