"use strict";

import { cloneDeep } from 'lodash'
import { deGuidify } from '../components/util/util';
import * as actionTypes from '../requests/actionTypes'
import { updateState } from './rootReducer'

const initialState = {
  nodesOfType: {},
}


const navTreeReducer = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case actionTypes.ADD_NODES_TO_NODES_OF_TYPE:
      var nodesOfType = { ...state.nodesOfType }

      for (let xmiType in action.nodeHash) {
        let newHash = {};
        if (nodesOfType[xmiType]) newHash = deGuidify(nodesOfType[xmiType]);

        for (let node of action.nodeHash[xmiType]) {
          if (!node?.guid) continue;
          newHash[node.guid] = node;
        }

        nodesOfType[xmiType] = Object.values(newHash);
      }

      return updateState(state, { nodesOfType }); 


    case actionTypes.REMOVE_GUIDS_FROM_NODES_OF_TYPE:
      var nodesOfType = { ...state.nodesOfType };

      for (let xmiType in action.guidHash) {
        let newHash = {};
        if (nodesOfType[xmiType]) newHash = deGuidify(nodesOfType[xmiType]);

        for (let guid of action.guidHash[xmiType]) {
          delete newHash[guid];
        }

        nodesOfType[xmiType] = Object.values(newHash);
      }
      return updateState(state, { nodesOfType });

    case actionTypes.CLEAR_NODES_OF_TYPE:
      var nodesOfType = {};
      return updateState(state, { nodesOfType });

    case actionTypes.RESET_APP:
      return initialState;

    default:
      return state;
  }
}




export default navTreeReducer;