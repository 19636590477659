export const TREE_ICON_MAP = {
    "logical:Measurement": require("./../../images/tree icons/measurement.png"),
    "logical:MeasurementSystem": require("./../../images/tree icons/measurement_sys.png"),
    "logical:CoordinateSystem": require("./../../images/tree icons/coordinate_sys.png"),
    "face:ConceptualDataModel": require("./../../images/tree icons/conceptual_pkg.png"),
    "face:LogicalDataModel": require("./../../images/tree icons/logical_pkg.png"),
    "face:PlatformDataModel": require("./../../images/tree icons/platform_pkg.png"),
    "face:UoPModel": require("./../../images/tree icons/uop_pkg.png"),
    "skayl:MessageDataModel": require("./../../images/tree icons/message_pkg.png"),
    "skayl:DeploymentModel": require("./../../images/tree icons/deployment_pkg.png"),
    "skayl:DiagramModel": require("./../../images/tree icons/diagram_pkg.png"),
    "conceptual:Observable": require("./../../images/tree icons/observabledk_.png"),
    "conceptual:Entity": require("./../../images/tree icons/entitydk_.png"),
    "conceptual:Composition": require("./../../images/tree icons/attribute.png"),
    "conceptual:Association": require("./../../images/tree icons/assocdk_.png"),
    "platform:View": require("./../../images/tree icons/viewdk_.png"),
    "platform:CharacteristicProjection": require("./../../images/tree icons/proj_char.png"),
    "message:Type": require("./../../images/tree icons/message.png"),
    "platform:RegularExpressionConstraint": require("./../../images/tree icons/constraint.png"),
    "platform:RealRangeConstraint": require("./../../images/tree icons/constraint.png"),
    "platform:IntegerRangeConstraint": require("./../../images/tree icons/constraint.png"),
    "uop:PortableComponent": require("./../../images/tree icons/uopdk_.png"),
    "uop:PlatformSpecificComponent": require("./../../images/tree icons/uopdk_.png"),
    "skayl:IntegrationModel": require("./../../images/tree icons/integration_model_pkg.png"),
    "im:UoPInstance": require("./../../images/tree icons/uop_instance.png"),
    "im:IntegrationContext": require("./../../images/tree icons/integration_context.png"),
    "ddm:MainProgram": require("./../../images/tree icons/main_program.png"),
    "pedm:ProcessingElement": require("./../../images/tree icons/processing_element.png"),
    "im:TransportChannel": require("./../../images/tree icons/transport_channel.png"),
    "diagram:Package": require("./../../images/tree icons/diagram_pkg.png"),
    "logical:ValueTypeUnit": require("./../../images/tree icons/vtu.png"),
    "logical:RealRangeConstraint": require("./../../images/tree icons/constraint.png"),
    "logical:IntegerRangeConstraint": require("./../../images/tree icons/constraint.png"),
    "logical:EnumerationConstraint": require("./../../images/tree icons/constraint.png"),
    "logical:CoordinateSystemAxis": require("./../../images/tree icons/csa.png"),
    "logical:MeasurementAxis": require("./../../images/tree icons/ma.png"),
    "logical:MeasurementSystemAxis": require("./../../images/tree icons/msa.png"),
    "datamodel:DataModel": require("./../../images/tree icons/pkg_dm.png"),
    "platform:Char": require("./../../images/tree icons/pt_char.png"),
    "platform:Double": require("./../../images/tree icons/pt_double.png"),
    "platform:Float": require("./../../images/tree icons/pt_float.png"),
    "platform:Long": require("./../../images/tree icons/pt_long.png"),
    "platform:LongLong": require("./../../images/tree icons/pt_longlong.png"),
    "platform:Octet": require("./../../images/tree icons/pt_octet.png"),
    "platform:Short": require("./../../images/tree icons/pt_short.png"),
    "platform:String": require("./../../images/tree icons/pt_string.png"),
    "platform:ULong": require("./../../images/tree icons/pt_unsignedlong.png"),
    "platform:ULongLong": require("./../../images/tree icons/pt_unsignedlonglong.png"),
    "platform:UShort": require("./../../images/tree icons/pt_unsignedshort.png"),
    "logical:ReferencePoint": require("./../../images/tree icons/refpt.png"),
    "logical:Unit": require("./../../images/tree icons/unit.png"),
    "logical:Enumerated": require("./../../images/tree icons/vt.png"),
    "logical:Real": require("./../../images/tree icons/vt.png"),
    "logical:Integer": require("./../../images/tree icons/vt.png"),
    "logical:String": require("./../../images/tree icons/vt.png"),
    "logical:Boolean": require("./../../images/tree icons/vt.png"),
    "logical:Natural": require("./../../images/tree icons/vt.png"),
    "logical:NonNegativeReal": require("./../../images/tree icons/vt.png"),
    "logical:Character": require("./../../images/tree icons/vt.png"),
    "im:SourceNode": require("./../../images/tree icons/source.png"),
    "im:SinkNode": require("./../../images/tree icons/sink.png"),
    "im:FanIn": require("./../../images/tree icons/fan_i.png"),
    "im:TransformNode": require("./../../images/tree icons/transform_node.png"),
    "im:FilterNode": require("./../../images/tree icons/filter.png"),
    "im:ViewTransporterNode": require("./../../images/tree icons/view_transporter.png"),
    "im:SIMAdapter": require("./../../images/tree icons/sim_adapter.png"),
    "im:QueuingAdapter": require("./../../images/tree icons/queuing_adapter.png"),
    "im:DataPump": require("./../../images/tree icons/data_pump.png"),
    "im:NodeConnection": require("./../../images/tree icons/node_connection.png"),
    "im:ComposedBlock": require("./../../images/tree icons/composed_block.png"),
    "im:ComposedBlockInstance": require("./../../images/tree icons/composed_block_instance.png"),
    "platform:IDLStruct": require("./../../images/tree icons/platform_struct.png"),
    "platform:Enumeration": require("./../../images/tree icons/platform_enum.png"),
};

export const DEFAULT_ICON = require("./../../images/tree icons/defaultdk_.png");
export const noop = () => {};