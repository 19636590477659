import { 
  PointModel,
  LinkModel,
} from "@projectstorm/react-diagrams";

import {
  SelectionLayerModel,
  AbstractDisplacementState,
} from '@projectstorm/react-canvas-core';

import { Rectangle } from '@projectstorm/geometry';
import { ShapeLinkModel } from "../index"


export class CustomSelectionBoxState extends AbstractDisplacementState {

	async activated(previous) {
    super.activated(previous);
    this.engine.getModel().clearSelection();
		await this.engine.repaintCanvas(true);

    // we can block layer rendering because we are only targeting the transforms
		for (let layer of this.engine.getModel().getLayers()) {
			layer.allowRepaint(false);
		}

		this.initialCanvasX = this.engine.getModel().getOffsetX();
		this.initialCanvasY = this.engine.getModel().getOffsetY();

    this.layer = new SelectionLayerModel();
		this.engine.getModel().addLayer(this.layer);
	}

  deactivated(next) {
		super.deactivated(next);
		for (let layer of this.engine.getModel().getLayers()) {
			layer.allowRepaint(true);
		}

    this.layer.remove();
		this.engine.repaintCanvas();
	}

  getBoxDimensions(event) {
		const rel = this.engine.getRelativePoint(event.event.clientX, event.event.clientY);

		return {
			left: rel.x > this.initialXRelative ? this.initialXRelative : rel.x,
			top: rel.y > this.initialYRelative ? this.initialYRelative : rel.y,
			width: Math.abs(rel.x - this.initialXRelative),
			height: Math.abs(rel.y - this.initialYRelative),
			right: rel.x < this.initialXRelative ? this.initialXRelative : rel.x,
			bottom: rel.y < this.initialYRelative ? this.initialYRelative : rel.y
		};
	}

  fireMouseMoved(event) {
		this.layer.setBox(this.getBoxDimensions(event));

		const relative = this.engine.getRelativeMousePoint({
			clientX: this.initialX,
			clientY: this.initialY
		});
		if (event.virtualDisplacementX < 0) {
			relative.x -= Math.abs(event.virtualDisplacementX);
		}
		if (event.virtualDisplacementY < 0) {
			relative.y -= Math.abs(event.virtualDisplacementY);
		}
		const rect = new Rectangle(relative, Math.abs(event.virtualDisplacementX), Math.abs(event.virtualDisplacementY));

    // The elements in SelectionEntities are sorted -> [LinkModel, PointModel, NodeModel]
    // To select a PointModel, the parent needs to be selected first. Have to iterate backwards.
    const models = this.engine.getModel().getSelectionEntities();

    for(let i = models.length - 1; i >= 0; i--) {
      const model = models[i];
      if(model instanceof LinkModel) continue;

      if (model.getBoundingBox) {
        const bounds = model.getBoundingBox();

        if (rect.containsPoint(bounds.getTopLeft()) && rect.containsPoint(bounds.getBottomRight())) {
            if(model instanceof PointModel) {
              const linkModel = model.parent;
              const {sourcePort, targetPort} = linkModel;

              if(linkModel instanceof ShapeLinkModel && sourcePort.parent.isSelected() && targetPort.parent.isSelected()) {
                model.setSelected(true);
                linkModel.setSelected(true);
              } else if(sourcePort.parent.isSelected() || targetPort.parent.isSelected()) {
                model.setSelected(true);
                linkModel.setSelected(true);
              }
            } else {
              model.setSelected(true);
            }
        } else {
          model.setSelected(false);
        }
      }
    }
		this.engine.repaintCanvas();
	}
}