import React from 'react'
import { Link } from 'react-router-dom'

import dataNavIcon from "../../images/navigate.png";
import generateNavIcon from "../../images/generate.png";
import manageNavIcon from "../../images/manage2.png";
import LinkIcon from "../icons/LinkIcon";


const NavigateWidget = (props) => {
  return <div className='navigate-widget'>
    <h2 className="navigate-widget-title">Quick Links</h2>
    <div className="navigate-widget-sections">
      <WidgetData />
      <WidgetIntegration />
      <WidgetGenerate />
      <WidgetManage />
    </div>
  </div>
}



const WidgetData = (props) => {

  return <div className="navigate-widget-section">
    <header>
      <img className="navbar-icon" src={dataNavIcon} />
      <h2>Data Modeling</h2>
      {/* the button color matches the background to hide it until the play button is added */}
      <button className="navigate-play fas fa-play" style={{ color: "var(--border-color)", border: "var(--border-color)" }} />
    </header>

    <div className="navigate-widget-content">
      <Link to="/edit/details/entity/new">Create Entity</Link>
      <Link to="/edit/details/view/new">Document Interface (Create View)</Link>
      <Link to="/edit/scratchpad">Create or Edit Diagram</Link>
      <Link to="/edit/model_stats/semantic_equivalency">Find Semantic Matches</Link>
    </div>
  </div>
}


const WidgetIntegration = (props) => {

  return <div className="navigate-widget-section">
    <header>
      <LinkIcon className="navbar-icon" />
      <h2>Integration Modeling</h2>
      {/* the button color matches the background to hide it until the play button is added */}
      <button className="navigate-play fas fa-play" style={{ color: "var(--border-color)", border: "var(--border-color)" }} />
    </header>
    
    <div className="navigate-widget-content">
      <Link to="/integration/idm">Create or Edit Integration Diagram</Link>
      <Link to="/integration/details/portable_component/new">Create UoP</Link>
      <Link to="/integration/details/main_program/new">Create Main Program</Link>
    </div>
  </div>
}



const WidgetGenerate = (props) => {

  return <div className="navigate-widget-section">
    <header>
      <img className="navbar-icon" src={generateNavIcon} />
      <h2>Generate Products</h2>
      {/* the button color matches the background to hide it until the play button is added */}
      <button className="navigate-play fas fa-play" style={{ color: "var(--border-color)", border: "var(--border-color)" }} />
    </header>
    
    <div className="navigate-widget-content">
      <Link to="/generate/cinc_gen">Generate Integration (CinC)</Link>
      <Link to="/generate/model_gen">Export Model Content</Link>
      <Link to="/generate/templates">Export Templates</Link>
    </div>
  </div>
}



const WidgetManage = (props) => {

  return <div className="navigate-widget-section">
    <header>
      <img className="navbar-icon" src={manageNavIcon} />
      <h2>Manage Models</h2>
      {/* the button color matches the background to hide it until the play button is added */}
      <button className="navigate-play fas fa-play" style={{ color: "var(--border-color)", border: "var(--border-color)" }} />
    </header>
    
    <div className="navigate-widget-content">
      <Link to={{ pathname: "/manage/branch/model-manage", isCreateProject: true }}>Create Project</Link>
      <Link to={{ pathname: "/manage/branch/model-manage", isCreateModel: true }}>Create Model</Link>
      <Link to="/manage/branch/push">Push Changes</Link>
      <Link to="/manage/branch/pull">Pull Changes</Link>
    </div>
  </div>
}


export default NavigateWidget;