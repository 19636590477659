import React, {Fragment} from "react";
import { _ajax } from "../../requests/sml-requests";
import {Notifications} from "../edit/notifications";
import {CadetInput, LineLabel} from "../util/stateless";
import PhenomId from "../../requests/phenom-id";

export class AuthPass extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            isError: false
        };

        this.noteRef = undefined;
    }

    componentDidUpdate(prevProps, prevState) {
        const { page } = this.props;

        // reset error on page switch
        if (prevProps.page !== page) {
            this.setState({isError: false});
        }
    }

    handleChange = event => {
        this.setState({password: event.target.value});
    };

    handleKeyPress = event => {
        if (event.key === "Enter") {
            this.handleVerifyClick(event);
        }
    };

    handleVerifyClick = () => {
        if (this.state.password) {
            let passwordMsg = {
                username: this.props.username,
                password: this.state.password,
                requestType: this.props.page
            };
            let url = "/index.php?r=/site/auth-validation";
            _ajax({
                method: "POST",
                url: url,
                data: passwordMsg
            }).then(res => {
                let response = (this.props.page === "password" ? res : this.state.password);
                if (res["keyData"]) {
                    this.setState({password: ""}, this.props.onPageChange(response));
                } else {
                    this.noteRef.error(res["errors"]);
                    this.setState({isError: true});
                }
            });
        } else {
            this.noteRef.error(["Input required."]);
            this.setState({isError: true});
        }
    };

    render() {
        const { isError } = this.state;
        const {mode, page} = this.props;
        const phenomId = new PhenomId("auth-pass",this.props.idCtx);
        const pageTitle = (page === "password" ? "Password Verification" : "Authenticator Passcode Verification");
        const pHolder = (page === "password" ? "Please enter your current password" : "Please enter Authenticator passcode");
        const passLabel = (page === "password" ? "Current Password" : "Authenticator Passcode");
        const buttonText = (page === "password" ? "Verify Password" : "Verify Passcode");

        return (<div className="login-form form-horizontal" style={{padding: (mode === "init" && page === "authkey") ? 0 : ""}}>
            <Notifications ref={ele => this.noteRef = ele}/>

            {!(mode === "init" && page === "authkey") &&
                <div className="logintitle">
                    <h1>{pageTitle}</h1>
                </div>
            }
        
            <div className={"p-col-2 " + (isError ? "has-error" : "")} style={{margin: (mode === "init" && page === "authkey") ? 0 : "0 0 0 15px" }}>
                <div className="p-row">
                    <label htmlFor="loginform-password" className="login-label" id={phenomId.gen("","password-label")}>{passLabel}</label>
                </div>
                <div className="p-row">
                    <input value={this.state.password} style={{width: "25%"}}
                            onChange={this.handleChange}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                this.handleVerifyClick();
                                }
                            }}
                            type="password"
                            autoFocus="autofocus" aria-required="true" className="form-control"
                            autoComplete="off"
                            aria-invalid={isError}
                            placeholder={pHolder}
                            id={phenomId.gen("","auth-pc-input")}>
                    </input>
                </div>

                {isError &&
                    <div className="p-row" style={{width: "25%"}}>
                            <p className="help-block help-block-error" style={{marginLeft: "auto", marginRight: 0}}>Please try a valid input.</p>
                    </div>}

                <div className="p-row">
                    <button type="submit" name="login-button" className="btn btn-primary"
                                        style={{margin: "10px 0 0 0"}}
                                        onClick={this.handleVerifyClick}
                                        id={phenomId.gen("","verify-button")}>
                                        {buttonText}
                    </button>
                </div>
            </div>
        </div>);
    }
}