import React, {Component} from "react";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import PhenomId from "../../requests/phenom-id"

function noop() {
}

export class BasicConfirm extends Component {
    state = {
        visible: false,
        msg: "",
        confirmFunc: undefined,
        cancelFunc: undefined,
        title: "",
    };

    constructor(props) {
        super(props);
        BasicConfirm.__singleton = this;

        BasicConfirm.__eventBus = (keyEvent) => {
            if (keyEvent.keyCode === 78) {
                this.__hide();
                this.state.cancelFunc();
            }
            // key: Y
            if (keyEvent.keyCode === 89) {
                this.__hide();
                this.state.confirmFunc();
            }
        };
    }

    static show(msg, confirmFunc, cancelFunc, title = "Please Confirm") {
        if (!BasicConfirm.__singleton) new BasicConfirm();

        BasicConfirm.__singleton.setState({
            msg: msg,
            confirmFunc: confirmFunc || noop,
            cancelFunc: cancelFunc || noop,
            title: title,
        });

        BasicConfirm.__singleton.__show();
    }

    static hide() {
        BasicConfirm.__singleton.__hide();
    }

    __show() {
        window.addEventListener("keyup", BasicConfirm.__eventBus);
        this.setState({visible: true});
    }

    __hide() {
        window.removeEventListener("keyup", BasicConfirm.__eventBus);
        this.setState({visible: false});
    }

    render() {
        const phenomId = new PhenomId("basic-confirm",this.props.idCtx);
        return (<div>
            {this.state.visible && <Dialog title={this.state.title} className="dialog-no-exit" id={phenomId.gen("","wrapper")}>
                <div style={{margin: "5px"}}>
                    <div className="flex-v" style={{
                        whiteSpace: "pre-line",
                        width: "300px",
                        maxHeight: "calc(80vh - 340px)",
                        overflowY: "auto"
                    }}>
                        {this.state.msg}
                    </div>
                </div>
                <DialogActionsBar>
                    <button className="k-button"
                      id={phenomId.gen("","no-button")}
                      onClick={() => {
                        this.__hide();
                        this.state.cancelFunc();
                    }}>No
                    </button>
                    <button className="k-button k-primary" data-type="basic-confirm" autoFocus={true}
                      id={phenomId.gen("","yes-button")}
                      onClick={() => {
                        this.__hide();
                        this.state.confirmFunc();
                    }}>Yes
                    </button>
                </DialogActionsBar>
            </Dialog>}
        </div>);
    }
}
