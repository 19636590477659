import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import React from 'react'
import { getDiagramFiles, saveDiagramImage } from '../../requests/sml-requests';
import { BasicAlert } from '../dialog/BasicAlert';
import StormManager from '../navigate/diagrams/manager/StormManager';
import { Checkbox } from '@progress/kendo-react-inputs';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';



class ViewTraceExporter extends React.Component {
  managerRef = React.createRef();

  state = {
    nodes: [],
  }

  componentDidMount() {
    getDiagramFiles("t").then(res => {
      const nodes = JSON.parse(res).filter(node => !!node.Image);

      nodes.forEach(node => {
        node.isSelected = true;
      })

      this.setState({ nodes })
    })
  }

  // removes data:image/png;base64, from the dataURL string
  getBase64String(dataURL) {
    var idx = dataURL.indexOf('base64,') + 'base64,'.length;
    return dataURL.substring(idx);
  }

  downloadZipFile = () => {
    const zip = new JSZip();
    // const folder = zip.folder("View Traces");

    for (let node of this.state.nodes) {
      if (!node.isSelected) continue;
      zip.file(node.Name + ".png", this.getBase64String(node.Image), { base64: true });
    }

    zip.generateAsync({ type: "blob" })
       .then(blob => {
          saveAs(blob, "View_Traces.zip");
       })
  }

  generateAllImages = async () => {
    if (!this.managerRef.current) return;

    for (let node of this.state.nodes) {
      if (!node || !node.isSelected) continue;

      BasicAlert.show(`Generating ${node.Name}`, "One moment please", false);
      await this.managerRef.current.loadDiagram(node.Diagram_ID, node.Name);

      const imgData = await this.managerRef.current.createScreenshotWithoutCropping(0);
      await saveDiagramImage(node.Diagram_ID, imgData);
      node.Image = imgData;
      this.managerRef.current.deleteTab(0);
    }

    BasicAlert.hide();
    this.forceUpdate();
  }

  generateAndPreview = async (node) => {
    if (!node || !this.managerRef.current) return;

    BasicAlert.show(`Generating ${node.Name}. Preview will appear in a new browser tab.`, "One moment please", false);
    await this.managerRef.current.loadDiagram(node.Diagram_ID, node.Name);

    // note: window.open need to be triggered by the click action or else popup blockers can prevent it
    const win = window.open('', '_blank');
    if (win) {
      win.document.write('Loading image.  One moment please...')
      win.document.close();
    }

    this.managerRef.current.createScreenshotWithoutCropping(0).then((imgData) => {
      node.Image = imgData;

      this.managerRef.current.deleteTab(0);
      BasicAlert.hide();
      this.forceUpdate();

      saveDiagramImage(node.Diagram_ID, imgData)

      const image = new Image();
            image.src = imgData;
      
      if (win === null) {
        return BasicAlert.show("Failed to open a new browser tab. You might have a popup blocker enabled.", "Preview", true);
      }

      win.document.write(`<html><head><title>${node.Name}</title></head>
                                <body>${image.outerHTML}</body></html>`);
      win.document.close();
    })
  }

  previewImage = (node) => {
    if (!node) return;

    // note: window.open need to be triggered by the click action or else popup blockers can prevent it
    const win = window.open('', '_blank');
    if (win === null) {
      return BasicAlert.show("Failed to open a new browser tab. You might have a popup blocker enabled.", "Preview", true);
    }

    const image = new Image();
          image.src = node.Image;
    win.document.write(`<html><head><title>${node.Name}</title></head>
                              <body>${image.outerHTML}</body></html>`)
  }

  downloadSingleImage = (node) => {
    if (!node) return;
    saveAs(node.Image, `${node.Name || "my-diagram"}.png`);
  }

  headerCellCheckbox = (props) => {
    const { nodes } = this.state;

    return <a style={{ display: "block", textAlign: "center" }}>
      <Checkbox label=''
                checked={nodes.every(node => node.isSelected)} 
                onClick={(e) => {
                  nodes.forEach(node => node.isSelected = e.target.checked);
                  this.forceUpdate();
                }} />
    </a>
  }

  cellCheckbox = (props) => {
    const { dataItem } = props;

    return <td style={{ textAlign: "center" }}>
      <Checkbox label=''
                checked={dataItem.isSelected} 
                onClick={(e) => {
                  dataItem.isSelected = e.target.checked;
                  this.forceUpdate();
                }} />
    </td>
  }

  cellPreview = (props) => {
    const { dataItem } = props;

    return <td style={{ textAlign: "center" }}>
      <Button icon='preview' onClick={() => this.previewImage(dataItem)} />
    </td>
  }

  cellGenerate = (props) => {
    const { dataItem } = props;

    return <td style={{ textAlign: "center" }}>
      <Button icon='image-edit' onClick={() => this.generateAndPreview(dataItem)} />
    </td>
  }

  cellDownload = (props) => {
    const { dataItem } = props;

    return <td style={{ textAlign: "center" }}>
      <Button icon='download' onClick={() => this.downloadSingleImage(dataItem)} />
    </td>
  }

  render() {
    const { nodes } = this.state;

    return <div className='flex-container'>

            <div className='p-row'>
              <Button icon='image-edit' onClick={this.generateAllImages}>Regenerate selected</Button>
              <Button icon='download' onClick={this.downloadZipFile}>Download selected</Button>
            </div>

            <div style={{ overflow: "hidden" }}>
              <Grid data={nodes}>
                <GridColumn title="" width="60px" cell={this.cellCheckbox} headerCell={this.headerCellCheckbox} />
                <GridColumn title="NAME" field="Name" />
                <GridColumn title="PREVIEW" width="80px"  cell={this.cellPreview} />
                <GridColumn title="REGENERATE" width="105px" cell={this.cellGenerate} />
                <GridColumn title="DOWNLOAD" width="95px"  cell={this.cellDownload} />
              </Grid>

              <div style={{ position: "relative", flex: 1 }}>
                <StormManager ref={this.managerRef} stormType="view_trace" />
                <div style={{ position: "absolute", inset: "0 0 0 0", background: "white", zIndex: 5 }} />

              </div>
            </div>
    </div>
  }
}



export default ViewTraceExporter;