import React, { Component } from "react";
import Helmet from "react-helmet";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import { Details } from "../components/navigate/details";
import MeridianMap from "../components/navigate/meridian_map";
import SkratchpadManager from "../components/navigate/diagrams/manager/SkratchpadManager";
import StormManager from "../components/navigate/diagrams/manager/StormManager";
import HealthCheck from "../components/generate/health_check";
import ModelStats from "../components/generate/model_stats";
import Import from "../components/manage/import";
import { _ajax } from "../requests/sml-requests";
import * as actionThunks from "../requests/actionThunks"
import { routeUrlBuilder } from "../routes";



class DataModel extends Component {
    state = {
      meridianSelection: false,
    }

    constructor(props) {
      super(props);
      _ajax({
        url: "/index.php?r=/referencing-model/is-published",
        method: "get"
      }).then(response => {

        if (!response.data.published) {
          this.subMenu.push(
            {
              url: "/edit/import",
              text: "Import"
            });
          this.forceUpdate();
        }
      });
    }

    subMenu = [
      {
        url: "/edit/details",
        text: "Details"
      },
      {
        url: "/edit/scratchpad",
        text: "Diagram"
      },
      {
        url: "/edit/health_check",
        text: "HealthCheck"
      },
      {
        url: "/edit/model_stats",
        text: "Analytics"
      },
      {
        url: "/edit/meridian_map",
        text: "Meridian Map"
      },
      {
        url: "/edit/view_trace",
        text: "View Trace"
      }
    ]

    componentDidMount() {
      actionThunks.getActiveChangeSets();
    }

    setMeridianSelection = (selection) => {
        this.setState({meridianSelection: selection});
    };

    render() {
        return (<>
            <Helmet>
                <title>Navigate Project</title>
            </Helmet>
            
            <section id="phenom-content" aria-label='content'>
              <nav className="sub-menu-pages" aria-label='sub menu'>
                  {this.subMenu.map((sub, idx) => (
                    <NavLink to={sub.url} key={idx} activeClassName="active">
                      {sub.text}
                    </NavLink>
                  ))}
              </nav>
              <Switch>
                <Route path={routeUrlBuilder("edit", "details", null, ":guid?", ":parentGuid?")} 
                       component={Details}/>
                {/* <Route path="/:mode(edit)/scratchpad" render={(props) => <StormManager stormType="scratchpad" />}/> */}

                <Route path={routeUrlBuilder("edit", "scratchpad")} 
                       component={SkratchpadManager}/>
                <Route path={routeUrlBuilder("edit", "health_check")} 
                       component={HealthCheck} />
                <Route path={routeUrlBuilder("edit", "model_stats")} 
                       component={ModelStats} />
                <Route path={routeUrlBuilder("edit", "meridian_map")}
                      render={(props) => <MeridianMap selection={this.state.meridianSelection}
                                                  setMeridianSelection={this.setMeridianSelection} {...props}/>}/>
                <Route path={routeUrlBuilder("edit", "view_trace")} 
                       render={(props) => <StormManager stormType="view_trace" {...props}/>}/>
                <Route path={routeUrlBuilder("edit", "import", null)} 
                       component={Import}/>
                <Redirect to="/edit/details" />
              </Switch>
            </section>
        </>);
    }
}






export default DataModel