import React from 'react';
import { DefaultLinkFactory } from '@projectstorm/react-diagrams-defaults';
import { LinkWidget } from "@projectstorm/react-diagrams-core";
import { BaseLinkModel, BaseLinkWidget, Path } from "../../base/BaseLink";
import { ImLabelModel } from "./ImLabel";
import { nodeProps } from '../../util';



// ------------------------------------------------------------
// LINKS - REACT STORM
// ------------------------------------------------------------
export class ImLinkFactory extends DefaultLinkFactory {
	constructor(type = 'im-link') {
    super(type);
	}

	generateModel(event) {
		return new ImLinkModel();
	}

	generateReactWidget(event) {
		return <ImLinkWidget link={event.model} diagramEngine={this.engine} />;
  }

  generateLinkSegment(model, selected, path) {
		return <Path selected={selected}
                 stroke="var(--linkColor)"
                 strokeWidth={model.getOptions().width}
                 strokeSize={model.getDashSize()}
                 strokeOffset={model.getDashOffset()}
                 d={path} />
	}
}


export class ImLinkModel extends BaseLinkModel {
  constructor(options={}, settings={}) {
		super({
			type: 'im-link',
			width: options.width || 2,
      ...options,
    });

    this.settings = {
      ...this.settings,
      onDeleteRemoveOutPort: false,
      onDeleteRemoveInPort: false,
      ...settings,
    }

    this.registerListener({
      dataTypeChanged: (e) => {
        e.isIn ? this.setSourceDataType(e.view) : this.setDestinationDataType(e.view);
      },
      templateTypeChanged: (e) => {
        e.isIn ? this.setSourceTemplateType(e.text) : this.setDestinationTemplateType(e.text);
      },
      imLinkColorChanged: (e) => {
        // exit if the targetPort does not exist, most likely this was triggered by the "draw-tool"
        // exit if the new color matches current color to prevent the event listener from triggering again
        // change color only if the dataType matches
        if (!this.getTargetPort() || 
            this.getColor() === e.color || 
            this.getSourcePort().getDataType() !== e.dataType || 
            this.getTargetPort().getDataType() !== e.dataType) {
              return;
        }

        this.setLinkColor(e.color);
      },
    })
  }



  // ------------------------------------------------------------
  // Getters
  // ------------------------------------------------------------
  getLabel() {
    return this.getLabels()[0];
  }

  /**
   * Compares the outport's and inport's dataTypes
   *    The data type for UoPIEndpoints depend on the connected message port
   * 
   * @returns true if both dataTypes match, false otherwise
   */
  isError() {
    // using draw tool creates a new link and can trigger this method
    if (!this.getSourcePort() || !this.getTargetPort()) return false;
    return this.getSourcePort().getDataType() !== this.getTargetPort().getDataType();
  }

  // ------------------------------------------------------------
  // Setters
  // ------------------------------------------------------------
  createLabel() {
    const label = new ImLabelModel({
      label: "",
    });
    
    this.addLabel(label);
    return label;
  }

  findOrCreateLabel() {
    return this.getLabel() || this.createLabel();
  }

  updateAttrData(key, value) {
    this.getAttrData()[key] = value;
  }

  /**
   * 
   * @param {string} color 
   */
  setLinkColor(color) {
    super.setLinkColor(color);

    // trigger event listener if dataTypes match
    if (!this.isError()) {
      const { sourcePort, targetPort } = this;
      sourcePort.getNode().fireEvent({ color, dataType: sourcePort.getDataType() }, "imLinkColorChanged");
      targetPort.getNode().fireEvent({ color, dataType: targetPort.getDataType() }, "imLinkColorChanged");
    }
  }

  setLabelText(text) {
    const label = this.findOrCreateLabel();
    if (label.getLabel() === text) return;
    this.forceLinkToUpdate();
    label.fireEvent({ text: text || "" }, "textChanged");
  }

  setSourceDataType(view) {
    this.getSourcePort().setDataType(view);
    this.forceLinkToUpdate();
  }

  setDestinationDataType(view) {
    this.getTargetPort().setDataType(view);
    this.forceLinkToUpdate();
  }

  setSourceTemplateType(text) {
    this.getSourcePort().setTemplateType(text);
    this.forceLinkToUpdate();
  }

  setDestinationTemplateType(text) {
    this.getTargetPort().setTemplateType(text);
    this.forceLinkToUpdate();
  }
}



export class ImLinkWidget extends BaseLinkWidget {
  constructor(props) {
    super(props);

    this.props.link.registerListener({
      selectionChanged: (e) => {
        const { sourcePort, targetPort } = this.props.link;
        if (!sourcePort || sourcePort.getName() === "draw-tool") return;

        // display node's StormAnchor
        sourcePort.getNode().forceWidgetToUpdate();
        targetPort.getNode().forceWidgetToUpdate();

        // display link in SideBar
        e.isSelected && this.$app.setSidebarNodeModel && this.$app.setSidebarNodeModel(this.props.link);
      },
    })
  }

  componentDidUpdate() {
    // When the json data is loaded, for some reason refPath is never repopulated.
    // This is needed to calculate the position of the label
    if (!this.props.link.getRenderedPath().length) {
      this.props.link.setRenderedPaths(this.refPaths.map(rp => rp.current));
    }
  }

  getLinkText = () => {
    const { sourcePort, targetPort } = this.props.link;
    if (!sourcePort || !targetPort) return "";

    // First check the SourcePort
    switch (sourcePort.getType()) {
      case "Templated":
        if (sourcePort.getTemplateType()) {
          return sourcePort.getTemplateType();
        }
        break;
      
      default:
        if (sourcePort.getDataType()) {
          const view = this.$app.getViewList().find(view => view?.guid === sourcePort.getDataType());
          if (view) return view.name;
        }
    }

    // SourcePort should be enough but just in case it doesn't have the data we're looking for
    // Check the TargetPort
    switch (targetPort.getType()) {
      case "Templated":
        if (targetPort.getTemplateType()) {
          return targetPort.getTemplateType();
        }
        break;

      default:
        if (targetPort.getDataType()) {
          const view = this.$app.getViewList().find(view => view.guid === targetPort.getDataType());
          if (view) return view.name;
        }
    }

    return "";
  }

  // override
  handleMouseClick = (e) => {
    if (e.ctrlKey) {
      this.props.link.remove();
      this.props.diagramEngine.repaintCanvas();
    }
  }


  render() {
    const { sourcePort, targetPort } = this.props.link;
    if (!sourcePort) return null;
    
		//ensure id is present for all points on the path
    const settings = this.props.link.getSettings();
		var points = this.props.link.getPoints();
		var paths = [];
		this.refPaths = [];

    let classes = ["base-link"];

    //draw the multiple anchors and complex line instead
    for (let j = 0; j < points.length - 1; j++) {
      paths.push(
        this.generateLink(
          LinkWidget.generateLinePath(points[j], points[j + 1]),
          {
            'data-linkid': this.props.link.getID(),
            'data-point': j,
            onMouseEnter: this.handleMouseEnter,
            onMouseDown: (e) => this.handleMouseDown(e, j),
            onMouseUp: this.handleMouseUp,
          },
          j
        )
      );
    }

    // render ArrowHead when the link is not selected
    if (targetPort !== null && !this.props.link.isSelected()) {
      paths.push(this.generateArrow(points[points.length - 1], points[points.length - 2], this.props.link.getArrowHead()))
    }

    // render the circles
    for (let i = 1; i < points.length - 1; i++) {
      paths.push(this.generatePoint(points[i]));
    }

    let viewName = this.getLinkText();
    if(this.props.link.isError()){
      viewName = "Mismatched data types";
    }

    // calculate new center line for label if breakpoints are added
    let point = points[0];
    let previousPoint = points[1];
    let newPoint;

    if(points.length > 3){
      if(points.length % 2 === 0){
        newPoint = Math.floor(points.length/2)-1;
      }
      else{
        newPoint = Math.floor(points.length/2);
      }
      point = points[newPoint];
      previousPoint = points[newPoint+1];
    }

    // render center text only if target port is reached
    if(targetPort !== null && this.props.link.isLinesVisible() !== false){
      paths.push(<DataTypeWidget key={"src" + point.getID()} point={point} previousPoint={previousPoint} type="source" $app={this.$app} viewName={viewName} />);
    }

    

		return <g className={classes.join(" ")}
              data-default-link-test={this.props.link.getOptions().testName}
              data-tip={viewName}
              data-for="diagramTip"
              ref={el => this.linkRef = el}
              style={{
                display: settings.hide || this.isAppHidingLinkType() ? "none" : null,
                "--linkColor": this.props.link.isSelected() ? this.props.link.getSelectedColor() : 
                               this.props.link.isUncommitted() ? "var(--skayl-orange)" : this.props.link.getColor()
              }}>
                {paths}
           </g>
	}
}

const DataTypeWidget = (props) => {
  const { point, previousPoint, type, data, uncommitted, $app, viewName } = props;
  const angle = 90 +
      (Math.atan2(point.getPosition().y - previousPoint.getPosition().y, point.getPosition().x - previousPoint.getPosition().x) *
          180) /
      Math.PI;

  let textRotate, textPosX, textAnchor;
  let srcText, srcBound, tarText, tarBound;

  srcText = viewName || "";


  const textStyle = {
      fontSize:12, 
      fill: uncommitted ? nodeProps["uncommittedColor"] : null
  }

  const tarTextStyle = {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      whiteSpace: "nowrap",
      top: tarText ? "-35px" : "-20px", 
  }

  //label centerpoint distance calculation
  const x1 = previousPoint.getPosition().x;
  const x2 = point.getPosition().x;
  const y1 = previousPoint.getPosition().y;
  const y2 = point.getPosition().y;
  const centerX = (x1+x2)/2;
  const centerY = (y1+y2)/2;
  const distanceX = x1-centerX;
  const distanceY = y1-centerY;
  // bigger strings causes the centerpoint to appear off, subract it from distance to keep center
  const stringSize = srcText.length;
  const distance = Math.sqrt(distanceX ** 2 + distanceY **2) - (stringSize * 2.85);
  
  if (type === "source") {
      textRotate = 0 < angle && angle < 180 ? -90 : 90;
      textPosX = 0 < angle && angle < 180 ?  -distance : distance;
      textAnchor = 0 < angle && angle < 180 ? "end" : "start";

  } else {
      if(angle < 0 || 180 < angle) {
          textRotate = 90;
          textPosX = 30;
          textAnchor = "start";
          tarTextStyle.left = 30;
          tarTextStyle.alignItems = "flex-start";
      } else {
          textRotate = -90;
          textPosX = -30;
          textAnchor = "end";
          tarTextStyle.right = 30;
          tarTextStyle.alignItems = "flex-end";
      }
  }

return (
  <g transform={'translate(' + point.getPosition().x + ', ' + point.getPosition().y + ')'}>
          <g style={{transform: "rotate(" + (angle + textRotate) + "deg)"}}>
              
              <text x={textPosX} textAnchor={textAnchor} y="15" style={textStyle}>
                {srcText}</text>
          </g>
  </g>
);
};