import * as actionTypes from './actionTypes'
import store from '../store/store'
import NavTree from '../components/tree/NavTree';

//
// INDEX
// ------------------------------------------------------------
// # Window Events
// # Notifications
// # App
// # Nav Tree
// ------------------------------------------------------------
//



// ------------------------------------------------------------
// # Window Events
// ------------------------------------------------------------
export function removeNodes(guids) {
  if (!Array.isArray(guids)) {
    guids = [];
  }

  const event = new CustomEvent('DELETED_NODES', {detail: { guids }});
  window.dispatchEvent(event);
}



// ------------------------------------------------------------
// # Notifications
// ------------------------------------------------------------
export function receiveResponse(response) {
  store.dispatch({
    type: actionTypes.RECEIVE_RESPONSE,
    response,
  })
}

export function receiveLogs(logs, data) {
  store.dispatch({
    type: actionTypes.RECEIVE_LOGS,
    logs,
    data,
  })
}

export function receiveErrors(errors, data) {
  store.dispatch({
    type: actionTypes.RECEIVE_ERRORS,
    errors,
    data,
  })
}

export function receiveWarnings(warnings, data) {
  store.dispatch({
    type: actionTypes.RECEIVE_WARNINGS,
    warnings,
    data,
  })
}

export function clearNotifications() {
  store.dispatch({
    type: actionTypes.CLEAR_NOTIFICATIONS,
  })
}




// ------------------------------------------------------------
// # App
// ------------------------------------------------------------
export function sessionTokenExpired() {
  store.dispatch({ type: actionTypes.SESSION_TOKEN_EXPIRED });
  sessionStorage.clear();
}

export function updateUserSettings(settings={}) {
  store.dispatch({
    type: actionTypes.UPDATE_USER_SETTINGS,
    settings,
  })
}

export function updateBranchName(branchName="") {
  store.dispatch({
    type: actionTypes.UPDATE_BRANCH_NAME,
    branchName,
  })
}

export function showEventLog() {
  store.dispatch({
    type: actionTypes.SHOW_EVENT_LOG,
  })
}

export function hideEventLog() {
  store.dispatch({
    type: actionTypes.HIDE_EVENT_LOG,
  })
}

export function receiveChangeSetId(activeChangeSetId) {
  store.dispatch({
    type: actionTypes.RECEIVE_CHANGE_SET_ID,
    activeChangeSetId,
  })
}

export function getActiveChangeSetId() {
  return store.getState().app.activeChangeSetId;
}

export function setProjectSettings(projectSettings) {
  store.dispatch({
    type: actionTypes.RECEIVE_GENERATE_SETTINGS,
    project_settings: projectSettings,
  });
}


// ------------------------------------------------------------
// # Nav Tree
// ------------------------------------------------------------
export function addNodesToNodesOfType(nodeHash = {}) {
  store.dispatch({
    type: actionTypes.ADD_NODES_TO_NODES_OF_TYPE,
    nodeHash,
  })
}

export function removeGuidsFromNodesOfType(guidHash = {}) {
  store.dispatch({
    type: actionTypes.REMOVE_GUIDS_FROM_NODES_OF_TYPE,
    guidHash,
  })
}

export function clearNodesOfType() {
  store.dispatch({
    type: actionTypes.CLEAR_NODES_OF_TYPE,
  })
}


export function findGuidPublishedStatus(guid) {
  if (typeof guid !== 'string') {
    return false;
  }

  const node = NavTree.getNodeData(guid);
  return isModelPublished(node?.modelId);
}


export function isModelPublished(modelId) {
  const subModel = store.getState().app.subModels[modelId];
  if (!subModel) {
    return false;
  }

  return !!subModel.created;
}


/**
 *
 * @param {string} xmiType
 * @returns Set of Leaf Nodes
 */
export function getNodesOfType(xmiType) {
  const { nodesOfType } = store.getState().navTree;
  return nodesOfType[xmiType] || new Set();
}


// ------------------------------------------------------------
// # Health Check
// ------------------------------------------------------------
export function removeDeprecationIssues(guids=[]) {
  store.dispatch({
    type: actionTypes.REMOVE_DEPRECATION_ISSUES,
    guids
  })
}