import React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { PortWidget } from "@projectstorm/react-diagrams-core";
import styled from "@emotion/styled";

import { BaseNodeModel, BaseNodeWidget } from '../../base/BaseNode';
import { BasePortModel } from "../../base/BasePort";
import StormData from '../../base/StormData';
import { isStormData } from '../../util';

export const vertexProps = {
  size: 14,
}

const StyledVertex = styled.div`
  width: ${vertexProps.size}px;
  height: ${vertexProps.size}px;
  border-radius: 50%;
  background: ${p => p.color || "black"};
`

export const StyledHalo = styled.div`
  display: ${({selected}) => selected ? "block" : "none"};
  position: absolute;
  inset: -10px;
  border: 1px solid skyblue;
  border-radius: 50%;
  z-index:-1;
`;



export class VertexTraceNodeFactory extends AbstractReactFactory {
  constructor() {
    super("vertex-trace-node");
  }

  generateModel(event) {
    return new VertexTraceNodeModel();
  }

  generateReactWidget(event) {
    return <VertexTraceNodeWidget engine={this.engine} node={event.model}/>;
  }
}



export class VertexTraceNodeModel extends BaseNodeModel {
  constructor(options={}, $app, settings={}) {
    super(
      { color: "#000", ...options, type: "vertex-trace-node" }, 
      $app, 
      settings,
    );

    this.init();

    this.registerListener({
      entityRemoved: (e) => {
        this.$app.removeVertexNodeModel(this.getStormData().getAttr("tracing"));
      },
      removeNodeWithNoPorts: (e) => {
        this.removeNodeWithNoPorts();
      },
    })
  }



  deserialize(event) {
    this.$app = event.engine.$app;

    this.options.nodeData = event.data.nodeData;
    if (!isStormData(this.options.nodeData)) {
      this.options.nodeData = new StormData();
    }

    const tracingGuids = this.options.nodeData.getAttr("tracing");
    if (tracingGuids) {
      this.$app.setVertexNodeModel(tracingGuids, this);
    }

    super.deserialize(event);
  }

  init = () => {
    // when the line color changes, then the node color will change with it
    this.setSettings("matchLineColor", true);
  }

  // ------------------------------------------------------------
  // GETTERS
  // ------------------------------------------------------------


  // ------------------------------------------------------------
  // SETTERS
  // ------------------------------------------------------------
  setInPort = (attrData, options={}) => {
    try {
      if (!attrData) throw "invalid data, failed to create in port";

      const traceGuids = this.getStormData().getAttr("tracing");
      const pathGuids = attrData.getAttr("path");

      if (!pathGuids) throw "invalid observable, failed to create in port";
      
      // exit if path does not match
      if (traceGuids !== pathGuids) {
        return;
      }

      const port = new BasePortModel({
        name: options.portName || attrData.getGuid(),
        in: true,
        attrData,
      })

      this.addPort(port);
      port.reportPosition();
      return port;

    } catch (err) {
      console.error(err);
    }
  }

  centerNodePosition = (position={}) => {
    const zoomLevel = this.$app.model.getZoomLevel() / 100;
    const canvasRect = this.$app.getCanvasLayerDOM().getBoundingClientRect();
    const canvas_center = canvasRect.width / 2 / zoomLevel;
    const vertex_size = vertexProps.size / 2;
    const posX = canvas_center - vertex_size;

    if (posX !== this.getX()) {
      this.setPosition(posX, position.y || this.getY());
    }
  }

  // ------------------------------------------------------------
  // REMOVE
  // ------------------------------------------------------------
  removeNodeWithNoPorts = () => {
    !Object.keys(this.getPorts()).length && this.remove();
  }

  removeNodeWithLessThanTwoPorts = () => {
    Object.keys(this.getPorts()).length < 2 && this.remove();
  }
}

class VertexTraceNodeWidget extends BaseNodeWidget {
  constructor(props) {
    super(props);

    this.nodeModel.registerListener({
      selectionChanged: (e) => {
        if (this.widgetRef !== undefined) {
          if (e.isSelected) {
            this.widgetRef.parentElement.style.zIndex = "1";
          } else {
            this.widgetRef.parentElement.style.zIndex = "0";
          }
        }
      }
    });
  }

  render() {
    return <StyledVertex color={this.nodeModel.getNodeColor()}
                         ref={el => this.widgetRef = el}>

              <StyledHalo selected={this.nodeModel.options.selected} />

              <div className='anchor-point-container'>
                {Object.values(this.nodeModel.getPorts()).map(port => {
                  return <PortWidget key={port.options.id}
                                     engine={this.props.engine}
                                     port={port}
                                     style={{
                                        position: "absolute",
                                        left: vertexProps.size / 2,
                                        top: vertexProps.size / 2,
                                     }} />
                })}
              </div>
           </StyledVertex>
  }
}