import React, { Component } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import NavigateWidget from "./sections/NavigateWidget"
import ProjectChanges from "./sections/ProjectChanges"
import releaseNotes from "../static/updates"
import ProjectOverview from "../components/edit/widgets/ProjectOverview";

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stats: {
                entities: null,
                attributes: null,
                views: null,
                characteristics: null
            },
            updates: [],
            openedUpdateIdx: 0
        };

        this.changeOpenedUpdateIdx = this.changeOpenedUpdateIdx.bind(this);
    }

    // componentDidMount() {
    //   getModelStats().then(res => {
    //     this.setState({ stats: JSON.parse(res).stats });
    //   }).catch(err => {
    //     receiveErrors('Something went wrong. Failed to retrieve Model Stats');
    //   });
    // }

    changeOpenedUpdateIdx(idx) {
      this.setState({ openedUpdateIdx: idx });
    }

    render() {

        const { username, branchName } = this.props.userIdentity;
        return (<section id="phenom-content" aria-label='content'>
            <Helmet>
                <title>{username}'s Dashboard | {branchName} Project</title>
            </Helmet>

            <div className="phenom-content-scrollable" style={{ padding: "1em" }}>
              <div className="phenom-content-wrapper">
                <div style={{ display: "grid", gap: 20, height: 250, gridTemplateColumns: "1fr 1fr", marginBottom: "2em" }}>
                  <ProjectOverview />
                  <ProjectChanges />
                </div>
                <NavigateWidget />

                <div className="flex-container dashboard-notes">
                  <div className="p-row">
                    <div className="p-col p-col-6">
                      <div className="accordionWrapper">
                            {releaseNotes.updates.map((update, idx) => {
                                return (<div className={"accordionItem " + (this.state.openedUpdateIdx === idx ? "active": "")}
                                             key={idx} onClick={() => this.setState({ openedUpdateIdx: idx }) }>
                                          <h3 className="accordionItemHeading">{update.title}</h3>
                                          <div className="accordionItemContent"
                                              dangerouslySetInnerHTML={{__html: update.content}}/>
                                      </div>);
                            })}
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </section>);
    }
}


const msp = (state) => ({
  user: state.user,
  userIdentity: state.user.userIdentity,
})

export default connect(msp)(Dashboard);
