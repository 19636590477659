import React, {Component} from "react";
import {LineLabel, CadetInput, CadetTextArea} from "../util/stateless";
import {Notifications} from "../edit/notifications";
import PhenomLoadButton from "../widget/LoaderButton";
import ChangeSetTable from "../widget/ChangeSetTable";
import PhenomId from "../../requests/phenom-id";
import { _ajax } from "../../requests/sml-requests";


import $ from "jquery";
import { receiveLogs } from "../../requests/actionCreators";

export class ChangeSets extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: null,
            name: "",
            description: "",
            index: [],
            changeSetContent: false,
        };

        this.noticeRef = undefined;
        this.addChangeSet = this.addChangeSet.bind(this);
        this.editChangeSet = this.editChangeSet.bind(this);
        this.getChangeSetContent = this.getChangeSetContent.bind(this);
        this.reset = this.reset.bind(this);
    }

    componentDidMount() {
        this.getIndex();
    }

    getIndex() {
        return _ajax({
            url: "/index.php?r=/change-set/get-active-change-sets",
            method: "get"
        }).then(res => {
            this.setState({index: res.data.changeSets});
        });
    }

    addChangeSet() {
        return _ajax({
            url: "/index.php?r=/change-set/create-change-set",
            method: "post",
            headers: {"X-CSRF-TOKEN": window["_csrf"]},
            data: {
                name: this.state.name,
                description: this.state.description,
            },
        }).then(res => {
            receiveLogs(res.logs);
            this.reset();
            this.getIndex();
        });
    };

    editChangeSet() {
        return _ajax({
            url: "/index.php?r=/change-set/edit-change-set",
            method: "post",
            headers: {"X-CSRF-TOKEN": window["_csrf"]},
            data: {
                id: this.state.id,
                name: this.state.name,
                description: this.state.description,
            },
        }).then(res => {
            receiveLogs(res.logs);
            this.getIndex();
        });
    }

    reset () {
        this.setState({id: "", name: "", description: "", changeSetContent: false});
    }

    getChangeSetContent() {
        return $.ajax({
            method: "GET",
            url: "/index.php?r=/change-set/contents",
            data: {
                changeSetId: this.state.id,
                mergeMode: "push",
            },
        }).then(res => this.setState({changeSetContent: JSON.parse(res).contents}));
    }

    render() {
        const phenomId = new PhenomId("change-sets",this.props.idCtx);
        return (<div className="subview-wrapper phenom-content-scrollable">
            <Notifications ref={ele => this.noticeRef = ele} />
            <LineLabel text="Add Change Set" style={{marginBottom: 15}} idCtx={phenomId.gen("","label")}/>
            <p>Changesets are groups of project update actions like creating new nodes, editing existing nodes, deleting or deprecating nodes, importing model content, and bulk editing nodes to resolve issues after a health check.  The dropdown for selecting the changeset an update belongs to is next to the save button on the appropriate pages. A changeset can be merged down to inheriting projects or be part of a push request to a parent project, allowing all contained changes to be merged without needing to select nodes individually. Content changes from some Phenom actions, such as the Generalization-Specialization Migration, will populate automatically generated changesets.</p>
            <div style={{display: "flex"}}>
                <div style={{width: "20%", paddingRight: 30, paddingTop: 25}}>
                  {this.state.index.map((change_set,csIdx) => {
                      return <button className="btn btn-primary" style={{width: "100%", marginBottom:5}} onClick={() => this.setState({...change_set, changeSetContent: false})} id={phenomId.gen(["init",csIdx],"button")}>{change_set.name}</button>
                  })}
                  <button className="btn btn-primary" style={{width: "100%", marginTop: 10}} onClick={this.reset} id={phenomId.gen("init","reset-button")}>+</button>
                </div>
                <div style={{width: "80%"}}>
                    <LineLabel text="Name" style={{marginTop: 30}} idCtx={phenomId.gen("init","name")}/>
                    <CadetInput text={this.state.name} onChange={(e) => this.setState({name: e.target.value})} idCtx={phenomId.gen("init","name")}/>
                    <LineLabel text="Description" idCtx={phenomId.gen("init","description")}/>
                    <CadetTextArea text={this.state.description} onChange={(e) => this.setState({description: e.target.value})} idCtx={phenomId.gen("init","description")}/>
                    <div style={{display: "flex"}}>
                        <PhenomLoadButton text={this.state.id ? "Edit Change Set" : "ADD CHANGE SET"} onClick={this.state.id ? this.editChangeSet : this.addChangeSet} idCtx={phenomId.gen("init","edit-or-add")}/>
                        {!!this.state.changeSetContent || !this.state.id ||
                            <PhenomLoadButton
                                idCtx={phenomId.gen("","view-content")}
                                divStyle={{marginLeft: 15}}
                                text={"VIEW CHANGE-SET CONTENT"}
                                onClick={this.getChangeSetContent}
                        />}
                    </div>
                    {!this.state.changeSetContent ||
                     <ChangeSetTable changeSetContent={this.state.changeSetContent} idCtx={phenomId.gen("init")}/>}
                </div>
            </div>
        </div>);
    }
}
