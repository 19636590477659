import React from "react";
import { AbstractReactFactory } from "@projectstorm/react-canvas-core";
import { PortWidget } from "@projectstorm/react-diagrams";
import styled from "@emotion/styled";

import { 
    DiagramShapeModel, 
    DiagramShapeWidget,
} from "../index";




export class LineShapeFactory extends AbstractReactFactory {
    constructor() {
        super("line-shape");
    }

    generateModel(event) {
        return new LineShapeModel();
    }

    generateReactWidget(event) {
        return <LineShapeWidget engine={this.engine} node={event.model}/>;
    }
}



export class LineShapeModel extends DiagramShapeModel {
    constructor(options = {}) {
        super({
            ...options,
            type: "line-shape",
        });
    }
}


export const pointProps = {
    width: 10,
}

const Point = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${pointProps.width}px; 
    height: ${pointProps.width}px;
    border-radius: 30px;
`


class LineShapeWidget extends DiagramShapeWidget {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            Object.values(this.props.node.getPorts()).map(port => {
                return (
                    <Point id={this.phenomId.genPageId("-line-container")} 
                           key={port.options.id}>
                        <PortWidget engine={this.props.engine} port={port} />
                    </Point>
                )
            })
        );
    }
}