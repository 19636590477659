import React from 'react'
import $ from 'jquery'
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { receiveErrors, receiveResponse, receiveWarnings } from '../../requests/actionCreators';
import { connect } from 'react-redux';
import { Button } from '@progress/kendo-react-buttons';
import { PhenomComboBox, PhenomLabel } from '../util/stateless';
import { BasicAlert } from '../dialog/BasicAlert';
import { getAccountInfo, getAccountLicenses } from '../../requests/sml-requests';
import { SubMenuLeft, SubMenuRight } from '../edit/edit-top-buttons';
import { NavLink } from 'react-router-dom';
import PhenomId from '../../requests/phenom-id';
import { formatDate } from '../util/util';

class Licenses extends React.Component {
  licenseHash = {}
  state = {
    accountInfo: {},
    usersTable: [],

    unassigned_users: [],
    license_list: [],
    revoke_list: new Set(),
    invoke_hash: {},    // { <license_id>: <user> }
  }

  componentDidMount() {
    this.init();
  }

  init = () => {
    const { userIdentity } = this.props;

    return Promise.all([ getAccountLicenses(), getAccountInfo() ]).then(res => {
      const resp1 = res[0].data;
      const resp2 = res[1].data;

      const usersTable = Array.isArray(resp2.usersTable) ? resp2.usersTable : [];
      const license_list = Array.isArray(resp1.licenses) ? resp1.licenses : [];
      const unassigned_users = usersTable.filter(user => userIdentity.username !== user.name && !license_list.find(lic => lic.user_id === user.id));
      license_list.forEach(license => this.licenseHash[license.license_id] = license);

      this.setState({
        accountInfo: resp2.accountInfo,
        usersTable,
        license_list, 
        unassigned_users 
      });
    })
  }

  refresh = async () => {
    BasicAlert.show("Refreshing license list...", "Refreshing", false);
    await this.init();
    BasicAlert.hide();
  }

  isLicenseEdited = (license) => {
    const original = this.licenseHash[license?.license_id];
    if (!original) return true;

    // compare license attributes with original
    let isAttrEdited = ["user_id"].some(attr => license[attr] !== original[attr]);

    // check if license is to be revoked:
    let isRevokeLicense = this.state.revoke_list.has(license.license_id);

    // check if license is assigned a new user:
    let isNewUser = !!this.state.invoke_hash[license.license_id];

    return isAttrEdited || isRevokeLicense || isNewUser;
  }

  isDateExpired = (dateRaw) => {
    if (!dateRaw) return false;
    const expire_date = new Date(dateRaw);
    return Date.now() > expire_date.getTime();
  }

  willDateExpire = (dateRaw) => {
    if (!dateRaw) return false;
    const expire_date = new Date(dateRaw);
    const twoWeeks = 1209600000;
    return Date.now() > expire_date.getTime() - twoWeeks && Date.now() < expire_date.getTime();
  }

  toggleRevokeLicense = (license_id) => {
    const revoke_list = new Set(this.state.revoke_list);

    if (revoke_list.has(license_id)) {
      revoke_list.delete(license_id);
    } else {
      revoke_list.add(license_id);
    }

    this.setState({ revoke_list });
  }

  toggleInvokeLicense = (license_id, user) => {
    const invoke_hash = { ...this.state.invoke_hash };
    let unassigned_users = [...this.state.unassigned_users];

    const previouslySelectedUser = invoke_hash[license_id];
    previouslySelectedUser && unassigned_users.push(previouslySelectedUser);

    // toggle
    invoke_hash[license_id] = user;
    
    if (user) {
      unassigned_users = unassigned_users.filter(u => u.id !== user.id);
    }
    this.setState({ invoke_hash, unassigned_users });
  }

  handleSaveChanges = () => {
    const revoke_list = [...this.state.revoke_list];                                    // convert Set to Array
    const invoke_list = Object.entries(this.state.invoke_hash)
                              .filter(([_, user]) => !!user)                            // filter out empty entries
                              .map(([license_id, user]) => [license_id, user.id]);      // adjust the format

    if (!revoke_list.length && !invoke_list.length) {
      return receiveWarnings("No changes detected");
    }

    return $.ajax({
      url: "/index.php?r=/account/update-licenses",
      method: "post",
      data: {
        revoke_list,
        invoke_list,
      }
    }).then(res => {
      const response = JSON.parse(res);
      receiveResponse(response);
      this.refresh();
    })
  }

  renderExpireDateCell = (cellProps) => {
    const { expiration_date } = cellProps.dataItem;

    if (!expiration_date) {
      return <td />
    }

    const isExpired = this.isDateExpired(expiration_date);
    const willExpire = this.willDateExpire(expiration_date);

    let background;
    if (isExpired) background = "var(--bs-danger)";
    if (willExpire) background = "var(--bs-warning)";

    return <td style={{ background }}>
      { formatDate(expiration_date) }
    </td>
  }

  renderUserCell = (cellProps) => {
    const { userIdentity } = this.props;
    const { unassigned_users, revoke_list, invoke_hash } = this.state;
    const { dataItem } = cellProps;
    const { license_id, user_id, username } = cellProps.dataItem;

    const isCurrentUser = userIdentity.id === user_id;

    // license has a user
    if (username) {
      let revokeIconClass = revoke_list.has(license_id) ? "fas fa-link" : "fas fa-unlink";
      let revokeTitle = revoke_list.has(license_id) ? "Cancel revoke" : "Revoke";

      return <td>
              <div style={{ display: "flex", alignItems: "center", gap: 5, justifyContent: "space-between", whiteSpace: "nowrap"  }}>
                <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                  { username }
                </span>
                {!isCurrentUser &&
                  <Button iconClass={revokeIconClass}
                          title={revokeTitle}
                          onClick={() => this.toggleRevokeLicense(license_id)} /> }
              </div>
      </td>
    }

    if (isCurrentUser) {
      return <td />
    }

    const user = invoke_hash[dataItem.license_id];
    return <td>
      <PhenomComboBox data={unassigned_users}
                      value={user}
                      textField="name"
                      dataItemKey="id"
                      placeholder="Click to assign"
                      onChange={(user) => this.toggleInvokeLicense(dataItem.license_id, user)}
                      onClickCancelIcon={() => this.toggleInvokeLicense(dataItem.license_id)} />
    </td>
  }

  renderRow = (_, cellProps) => {
    const isEdited = this.isLicenseEdited(cellProps.dataItem);

    return <tr style={{ background: isEdited ? "hsl(var(--skayl-sky-hs) 86%)" : null }}>
      <td>{ cellProps.dataItem.license_id }</td>
      { this.renderExpireDateCell(cellProps) }
      { this.renderUserCell(cellProps) }
    </tr>
  }

  render() {
    const { accountInfo, usersTable, license_list } = this.state;
    const phenomId = new PhenomId("licenses",this.props.idCtx);

    const countUnassignedLicenses = license_list.reduce((total, currLicense) => {
      if (!currLicense?.user_id && !this.isDateExpired(currLicense?.expiration_date)) {
        return total + 1;
      }
      return total;
    }, 0);

    const countActiveLicenses = license_list.reduce((total, currLicense) => {
      if (!this.isDateExpired(currLicense?.expiration_date)) {
        return total + 1;
      }
      return total;
    }, 0);

    return <div className="phenom-content-wrapper">
      <nav className="sub-menu-actions" aria-label='form actions'>
        <SubMenuLeft>
          <NavLink id="subscription-link" activeClassName="active" to="/settings/subscription/users">Users</NavLink>
          <NavLink id="licenses-link" activeClassName="active" to="/settings/subscription/licenses">Phenom Licenses</NavLink>
          <NavLink id="cinc-licenses-link" activeClassName="active" to="/settings/subscription/cinc-licenses">CinC Licenses</NavLink>
        </SubMenuLeft>
        <SubMenuRight>
            <button id={phenomId.gen("","save")}
                    className="fas fa-save"
                    title="Save"
                    onClick={this.handleSaveChanges} />
        </SubMenuRight>
      </nav>
      <div className="phenom-content-scrollable subview-wrapper">
        <div>
          <PhenomLabel text="Subscription" />
          <p>Subscription name: { accountInfo.accountName || "..." }</p>
          <p>Active licenses: { countActiveLicenses } (unassigned: { countUnassignedLicenses })</p>
          <p>Subscription role: { accountInfo.subscriptionRole || "..." }</p>
          <p>Subscription license: { accountInfo.subscriptionLicense || "..." }</p>
        </div>

        <PhenomLabel text="Phenom Licenses" />
        <Grid data={license_list}
              className="without-box-shadow"
              rowRender={this.renderRow}>
          <GridNoRecords>No Data Is Available For This Table</GridNoRecords>

          <GridColumn title="License" />
          <GridColumn title="Expiration Date" />
          <GridColumn title="User" />
        </Grid>
      </div>
    </div>
  }
}



const msp = (state) => ({
  userIdentity: state.user.userIdentity,
})


export default connect(msp)(Licenses)
