import React from "react";
import { CadetInput } from "../util/stateless";
import {NavLink} from "react-router-dom";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import PhenomId from "../../requests/phenom-id";


export class IdlComposition extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            guid: "",
            parent: "",
            rolename: "",
            type: null,

            realizedGuid: null,
            realizationOptions: this.props.realizationOptions,
            deleted: false,
            primitive: false
        };

        this.originalRes = "";
    }

    componentDidMount() {
        this.originalRes = JSON.stringify(this.props.element);
        if (this.props.element) {
            this.setStateFromResponse(this.props.element);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.element !== this.props.element) {
          this.setStateFromResponse(this.props.element);
        }
    }

    setStateFromResponse(response) {
        this.setState({
            guid: response.guid,
            parent: response.parent,
            rolename: response.rolename,
            type: response.type ? response.type.guid : null,
            realizedGuid: response.realizes || response.type.realizes,
            deleted: false
        });
        this.originalRes = JSON.stringify(response);
    }

    getStateIfChanged = () => {
        const original = JSON.parse(this.originalRes);
        const realizedNode = this.props.realizationOptions.find(e => e.guid === this.state.realizedGuid);
        const realizesVal = realizedNode.xmiType === "logical:MeasurementAttribute" ? realizedNode.guid : undefined;
        let typeNode;

        if(typeof this.state.type !== "string") {
            typeNode = {...this.state.type};
            if(realizedNode.xmiType === "logical:MeasurementAttribute"){
                typeNode.realizes = realizedNode.type.guid;
            } else {
                typeNode.realizes = realizedNode.guid;
            }
        } else {
            typeNode = this.state.type;
        }

        if (!this.state.deleted && (this.props.new || original.rolename !== this.state.rolename || original.type.guid !== this.state.type)) {
            const childData = {
                guid: this.state.guid || undefined,
                rolename: this.state.rolename,
                xmiType: 'platform:IDLComposition',
                type: typeNode,
                realizes: realizesVal,
            };
            return childData;
        } else {
            return false;
        }
    };

    getName = () => {
        return this.state.rolename;
    }
    getRealizedGuid = () => {
        return this.state.realizedGuid;
    }
    getXmiType = () => {
        return "platform:IDLComposition";
    }
    getDeleted = () => {
        return this.state.deleted ? this.state.guid : false;
    }

    render() {
        const phenomId = new PhenomId("idl-composition",this.props.idCtx);

        const realizationDropdownValue = this.state.realizedGuid ? this.props.realizationOptions.find(e => e.guid === this.state.realizedGuid) : false;

        const typeDropdownSelection = [{guid: null, name: ""}];
        if(realizationDropdownValue) {
          if(realizationDropdownValue.xmiType === "logical:MeasurementAttribute") {
              typeDropdownSelection.push(...realizationDropdownValue.type.realizations);
          } else {
              typeDropdownSelection.push(...realizationDropdownValue.realizations);
          }
        }
        const typeDropdownValue = this.state.type ? typeDropdownSelection.find(e => e.guid === this.state.type) : false;

        const primValues = realizationDropdownValue && realizationDropdownValue.ambiguous ? [''] : ['', ...this.props.primitives];
        const primDropdownValue = typeDropdownValue ? typeDropdownValue.xmiType.replace('platform:', '') : realizationDropdownValue && realizationDropdownValue.ambiguous ? "IDLStruct": this.state.primitive;

        const emptyValue = {guid: undefined, name: ""};
        const isDestructured = this.props.destructureReference && this.props.destructureReference === this.state.guid;

        let rowColor = false;                    //no color by default

        if (this.props.destructureReference && this.props.destructureReference !== this.state.guid) {
            rowColor = "rgb(242, 242, 242)";     //gray for "unselected" members in a destructured view field
        } else if (this.state.deleted) {
            rowColor = "rgba(220,20,60,0.25)";   //red for a composition being deleted
        } else if (this.props.new) {
            rowColor = "rgba(152,251,152,0.25)"; //greend for a composition being created
        }

        return (
            <tr
                key={this.props.key}
                style={{height: 50, backgroundColor: rowColor}}
                className={this.props.isDraggable ? "draggable" : ""}
                onDragOver={this.props.onDragOver}
                onDragStart={this.props.onDragStart}
                draggable={this.props.isDraggable && this.props.canEdit}>
                <td>
                    <CadetInput
                        idCtx={phenomId.gen("init","rolename")}
                        // id={this.state.guid}
                        text={this.state.rolename}
                        disabled={!this.props.canEdit}
                        onChange={(e) => {
                            this.setState({rolename: e.target.value,
                                           type: !this.state.primitive ? this.state.type : 
                                                                         {...this.state.type, "name":`${e.target.value}_${realizationDropdownValue.name}_${this.state.primitive}`}});
                        }}
                        style={{margin: 0, padding: 0, height: 28, fontSize:14}}
                    />
                </td>
                <td>
                    <DropDownList
                        id={phenomId.gen("init","realizationOptions-dropdown")}
                        data={this.state.realizationOptions} textField="name"
                        dataItemKey="guid" style={{width: "100%"}}
                        value={realizationDropdownValue}
                        disabled={this.state.realizedGuid}
                        onOpen={(e) => {
                            const unusedrealizationOptions = realizationDropdownValue ? [emptyValue, realizationDropdownValue, ...this.props.getUnusedElements()] : this.props.getUnusedElements();
                            this.setState({realizationOptions: unusedrealizationOptions});
                        }}
                        onChange={(e) => {
                            this.setState({realizedGuid: e.value.guid});
                        }}/>
                </td>
                <td>
                    {(!this.props.new && typeDropdownValue) ?
                    <NavLink style={{marginTop: 10, marginBottom: 10}} className="cadet-anchor" to={`/edit/details/platform_type/${typeDropdownValue.guid}`} id={phenomId.gen("details","name-link")}>{typeDropdownValue.name}</NavLink>
                    :
                    this.state.primitive ?
                    <CadetInput
                        idCtx={phenomId.gen("init","primitive")}
                        text={this.state.type.name}
                        disabled={!this.props.canEdit}
                        onChange={(e) => {
                            this.setState({type: {...this.state.type, 'name':e.target.value}});
                        }}
                        style={{margin: 0, padding: 0, height: 28, fontSize:14}}
                    />
                    :
                    <DropDownList
                        id={phenomId.gen("init","type-dropdown")}
                        data={typeDropdownSelection} textField="name"
                        dataItemKey="guid" style={{width: "100%"}}
                        value={typeDropdownValue}
                        disabled={!this.props.canEdit || !this.state.realizedGuid || !this.props.new}
                        onChange={(e) => {
                            this.setState({type: e.value.guid});
                        }}/>}
                </td>
                <td>
                    <DropDownList
                        id={phenomId.gen("init","primitive-dropdown")}
                        data={primValues}
                        style={{width: "100%"}}
                        value={primDropdownValue}
                        disabled={!this.props.canEdit || typeDropdownValue || primValues.length < 2}
                        onChange={(e) => {
                            this.setState({primitive: e.value,
                                           type: e.value ? {"guid":undefined,
                                                  "name":`${this.state.rolename}_${realizationDropdownValue.name}_${e.value}`,
                                                  "xmiType":`platform:${e.value}`,
                                                  "children":[],
                                                  deconflictName: true,
                                                  "realizes":this.state.realizedGuid} : undefined});
                        }}/>
                </td>
            </tr>
        );
    }
}
