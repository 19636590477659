import React, {Component} from "react";
import {Dialog} from "@progress/kendo-react-dialogs";

class TitleBar extends Component {
    render() {
        return (<div className="custom-title" style={{fontSize: "18px", lineHeight: "1.3em"}}>
            <span className="k-icon k-i-info"/>
            <span style={{marginLeft: 5}}>{this.props.content}</span>
        </div>);
    }
}

export class BasicAlert extends Component {
    state = {
        visible: false,
        msg: "",
        title: "",
        allowClose: true
    };

    constructor(props) {
        super(props);
        BasicAlert.__singleton = this;
    }

    static show(msg, title, allowClose = true) {
        if (!BasicAlert.__singleton) new BasicAlert();

        BasicAlert.__singleton.setState({
            msg,
            title,
            allowClose
        }, () => {
            const bgEl = document.querySelector(".k-overlay");
            if (allowClose && bgEl) {
                bgEl.addEventListener("click", BasicAlert.hide);
            }
        });

        BasicAlert.__singleton.__show();
    }

    static hide() {
        BasicAlert.__singleton.__hide();
    }

    __show() {
        BasicAlert.__singleton.setState({visible: true});
    }

    __hide() {
        BasicAlert.__singleton.setState({
            visible: false,
            title: "",
            allowClose: true
        });
    }

    render() {
        return (<div>
            {this.state.visible && <Dialog title={<TitleBar content={this.state.title}/>} onClose={() => {
                this.__hide();
            }} className={this.state.allowClose ? "" : "dialog-no-exit"}>
                <div style={{margin: "5px"}}>
                    <div className="flex-v"
                         style={{whiteSpace: "pre-line", width: "300px", maxHeight: "80vh", overflowY: "auto"}}>
                        {this.state.msg}
                    </div>
                </div>
            </Dialog>}
        </div>);
    }
}