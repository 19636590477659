import {Notifications} from "../edit/notifications";
import {LineLabel} from "../util/stateless";
import React from "react";
import $ from "jquery";
import PhenomId from "../../requests/phenom-id"

export class UserNotifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notes: [{id: -1, Description: "loading..."}],
            userPrefs: [],
        };

        this.noteRef = undefined;
    }

    componentDidMount() {
        $.ajax({url: "/index.php?r=/notification/index"})
            .then(res => this.setState({notes: JSON.parse(res)}));
        this.loadPersonalPreferences();
    }

    loadPersonalPreferences() {
        $.ajax({url: "/index.php?r=/user/get-user-note-prefs"})
            .then(res => this.setState({userPrefs: JSON.parse(res)}));
    }

    changeNotePref = e => {
        const handleResponse = (res) => {
            const response = JSON.parse(res);
            if (response.errors) {
                this.noteRef.note(response.errors);
                this.loadPersonalPreferences();
            } else {
                this.noteRef.note("Changes saved successfully.", "info");
            }
        };
        if (e.target.checked) {
            $.ajax({
                url: "/index.php?r=/user/add-note-pref",
                method: "post",
                data: {noteId: parseInt(e.target.value)}
            }).then(handleResponse);
            this.state.userPrefs.push(parseInt(e.target.value));
        } else {
            $.ajax({
                url: "/index.php?r=/user/remove-pref",
                method: "post",
                data: {noteId: parseInt(e.target.value)}
            }).then(handleResponse);
            delete this.state.userPrefs[this.state.userPrefs.indexOf(parseInt(e.target.value))];
        }
        this.forceUpdate();
    };

    render() {
        const phenomId = new PhenomId("user-notifications",this.props.idCtx);
        return (
            <div className="subview-wrapper" id={phenomId.gen("","wrapper")}>
                <Notifications ref={ele => this.noteRef = ele}/>
                <LineLabel text="I would like to receive email notifications when:" idCtx={phenomId.gen()}/>
                <table className="default-table3" style={{marginTop: 15, border: "1px solid rgb(239, 240, 240)"}}>
                    {this.state.notes.map((note, idx) => {
                        const included = this.state.userPrefs.includes(parseInt(note.ID));
                        return (
                            <tr key={idx} id={phenomId.gen(["notes",idx],"wrapper")}>
                                <td style={{padding: "5px 5px 5px 15px", width: 38}}>
                                    <input
                                        id={phenomId.gen(idx,"-checbox")}
                                        type="checkbox"
                                        value={note.ID}
                                        title={(included ? "Cancel " : "Receive ") + note.Name + " Notifications"}
                                        checked={included}
                                        onChange={this.changeNotePref}
                                    />
                                </td>
                                <td id={phenomId.gen(idx,"-description")}>{note.Description}</td>
                            </tr>
                        );
                    })}
                </table>
            </div>
        );
    }
}
