export const RESET_APP = 'RESET_APP'

// User
export const RECEIVE_CRITICAL_DETAILS = 'RECEIVE_CRITICAL_DETAILS'
export const CLEAR_CRITICAL_DETAILS = 'CLEAR_CRITICAL_DETAILS'
export const SESSION_TOKEN_EXPIRED = 'SESSION_TOKEN_EXPIRED'
export const UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS'
export const UPDATE_BRANCH_NAME = 'UPDATE_BRANCH_NAME'

// Notifications
export const RECEIVE_RESPONSE = 'RECEIVE_RESPONSE'
export const RECEIVE_LOGS = 'RECEIVE_LOGS'
export const RECEIVE_WARNINGS = 'RECEIVE_WARNINGS'
export const RECEIVE_ERRORS = 'RECEIVE_ERRORS'
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS'

// App
export const RECEIVE_SAVED_NODE = 'RECEIVE_SAVED_NODE'
export const RECEIVE_SAVED_NODES = 'RECEIVE_SAVED_NODES'
export const SHOW_EVENT_LOG = 'SHOW_EVENT_LOG'
export const HIDE_EVENT_LOG = 'HIDE_EVENT_LOG'
export const RECEIVE_CHANGE_SETS = 'RECEIVE_CHANGE_SETS'
export const RECEIVE_CHANGE_SET_ID = 'RECEIVE_CHANGE_SET_ID'
export const RECEIVE_SUBMODELS = 'RECEIVE_SUBMODELS'

// Nav Tree
export const ADD_NODES_TO_NODES_OF_TYPE = 'ADD_NODES_TO_NODES_OF_TYPE'
export const REMOVE_GUIDS_FROM_NODES_OF_TYPE = 'REMOVE_GUIDS_FROM_NODES_OF_TYPE'
export const CLEAR_NODES_OF_TYPE = 'CLEAR_NODES_OF_TYPE'

// Health Check
export const RECEIVE_HEALTH_REPORT = 'RECEIVE_HEALTH_REPORT'
export const REMOVE_DEPRECATION_ISSUES = 'REMOVE_DEPRECATION_ISSUES'

// Generate
export const RECEIVE_GENERATE_SETTINGS = 'RECEIVE_GENERATE_SETTINGS'
