import Draggable from "react-draggable";
import {AnchoredPath, CadetInput, LineLabel} from "../util/stateless";
import React from "react";
import {getSemantics} from "../../requests/sml-requests";
import {NavLink} from "react-router-dom";
import PhenomId from "../../requests/phenom-id";

export class Semantics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            characteristic: "loading...",
            search_value: "",
            matches: [{
                view: "loading...",
                characteristic: "loading...",
                path_head: false,
                path_head_name: "loading...",
                path_pairs: "",
                level: 0,
                path_length: 1,
            }]
        };
    }

    componentDidMount() {
        getSemantics(this.props.guid).then((res) => {
            const response = res.data;
            if (response.matches === null) response.matches = [];
            response.matches = response.matches.sort((a, b) => (a.edits < b.edits) ? -1 : 1);
            this.setState(response);
        });
    }

    componentDidUpdate(prevProps) {
      if (prevProps.guid !== this.props.guid) {
        getSemantics(this.props.guid).then((res) => {
          const response = res.data;
          if (response.matches === null) response.matches = [];
          response.matches = response.matches.sort((a, b) => (a.edits < b.edits) ? -1 : 1);
          this.setState(response);
        });
      }
    }

    render() {
        const boxStyle = {top: window.innerHeight/2 - 50, left: window.innerWidth/3};
        const phenomId = new PhenomId("semantics",this.props.idCtx);
        return (
            <Draggable enableUserSelectHack={false} handle=".dialog-box-header" id={phenomId.gen("init","draggable")}>
                <div className="dialog-box" style={boxStyle}>
                    <span className="dialog-box-header">{this.state.characteristic}
                        <button onClick={this.props.close} id={phenomId.gen("init","close")}>X</button></span>
                    <div style={{padding: "0 15px"}}>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <LineLabel text="Semantic Matches" style={{width: "60%"}} idCtx={phenomId.gen("matches","")}/>
                            <CadetInput text={this.state.search_value}
                                        idCtx={phenomId.gen("matches","description")}
                                        placeholder="Type here to filter by View names. . ."
                                        // id="description"
                                        onChange={e => this.setState({search_value: e.target.value})}
                                        style={{width: "39%", height: "25px", marginBottom: 0}}/>
                        </div>
                        <table className="default-table2"
                               style={{maxHeight: 400, overflowY: "scroll", display: "block", marginTop: 5}}>
                            <thead>
                            <tr>
                                <td><b>{this.state.view_name}</b></td>
                                <td><b>{this.state.characteristic}</b></td>
                                <td><b>{this.state.path}</b></td>
                                <td></td>
                            </tr>
                            </thead>
                            <thead>
                            <tr>
                                <td>VIEW</td>
                                <td>CHARACTERISTIC</td>
                                <td>PATH</td>
                                <td>MATCH STRENGTH</td>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.matches.map((match, mIdx) => {
                                if (!this.state.search_value.length || new RegExp(this.state.search_value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&").replace(/\s/g, ".*"), "i").test(match.view)) {
                                    return (
                                        <tr id={phenomId.gen(`matches-${mIdx}`)}>
                                            <td><NavLink className='cadet-anchor'
                                                id={phenomId.gen(`matches-${mIdx}`,"view")}
                                                onClick={this.props.click ? (e) => {
                                                e.preventDefault();
                                                this.props.click(match.view_guid, this.props.guid);
                                            } : this.props.close}
                                                         to={`/edit/details/view/${match.view_guid}/`}>{match.view}</NavLink>
                                            </td>
                                            <td><NavLink className='cadet-anchor'
                                                         id={phenomId.gen(`matches-${mIdx}`,"characteristic")}
                                                         to={`/edit/details/characteristic/${match.characteristic_guid}/`}>{match.characteristic}</NavLink>
                                            </td>
                                            <td>{!match.path_pairs ||
                                            <AnchoredPath pathHead={{guid: match.path_head_guid, name: match.path_head}}
                                                          pathPairs={match.path_pairs}
                                                          idCtx={phenomId.gen(`matches-${mIdx}`,"path-pairs")}/>}</td>
                                            <td>{Math.round((1 - match.edits / (this.state.max_edits + 1)) * 100)}%</td>
                                        </tr>
                                    );
                                }
                            })}
                            {!!(this.state.matches.length) || <tr>
                                <td>No Matches</td>
                            </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Draggable>
        );
    }
}
