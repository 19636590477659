import React from "react";
import { AbstractReactFactory } from "@projectstorm/react-canvas-core";
import { DiagramShapeModel, DiagramShapeWidget, } from "../index";
import styled from "@emotion/styled";
import {BasicConfirm} from "../../../dialog/BasicConfirm";

import {
    NodeHalo,
    nodeProps,
} from "../design/nodeDesign";


const TextBoxStyled = styled.div`
    background-color: ${nodeProps["bgColor"]};
    border: 2px solid black;
    width: ${nodeProps["minWidth"]}px;
    height: 200px;
    position: relative;
    border: 2px solid var(--skayl-black);

    & textarea {
        width: 100%;
        height: 100%;
        resize: none;
    }
`


export class TextBoxShapeFactory extends AbstractReactFactory {
    constructor() {
        super("textbox-node");
    }

    generateModel(event) {
        return new TextBoxShapeModel();
    }

    generateReactWidget(event) {
        return <TextBoxShapeWidget engine={this.engine} node={event.model}/>;
    }
}

export class TextBoxShapeModel extends DiagramShapeModel {
    constructor(options = {}) {
        super({
            ...options,
            type: "textbox-node"
        });

        this.options.nodeData = {
          content: "",
        }
    }
}

class TextBoxShapeWidget extends DiagramShapeWidget {
    constructor(props) {
        super(props);

        this.nodeModel.registerListener({
            selectionChanged: (e) => {
                if (e.isSelected === false && this.toggleEditing) {
                    this.toggleEditing(false);
                }
            }
        });
    }

    toggleEditing = (newEditState) => {
        const engine = this.props.engine;
        engine.getModel().clearSelection();

        this.setState({
            isEditing: newEditState
        }, () => {
            if (newEditState) {
                this.nodeModel.setSelected(true);
            }
            this.nodeModel.setLocked(newEditState);
            engine.repaintCanvas();
        });
    };

    render() {
        return (
            <TextBoxStyled id={this.phenomId.genPageId("-textbox-container")} 
                            style={{
                                width: this.nodeModel.width || null, 
                                height: this.nodeModel.height || null
                            }}>

                <NodeHalo selected={this.nodeModel.options.selected}
                          onMouseDown={() => this.nodeModel.setLocked(false)} />

                <textarea id={this.phenomId.genPageId("-box-input")} 
                          cols="30" rows="10" value={this.nodeModel.getNodeData().content} 
                            onChange={(e) => {
                              this.nodeModel.updateProp("content", e.target.value);
                              this.forceUpdate();
                            }}
                            onMouseDown={() => {
                                const selected = this.$app.model.getSelectedEntities();
                                if(selected.length < 2) {
                                    this.nodeModel.setLocked(true)
                                }
                            }} />
                
                <div className="resize-left" onMouseDown={this.startResize} />
                <div className="resize-right" onMouseDown={this.startResize} />
                <div className="resize-top" onMouseDown={this.startResize} />
                <div className="resize-bottom" onMouseDown={this.startResize} />
                <div className="resize-top-left" onMouseDown={this.startResize} />
                <div className="resize-top-right" onMouseDown={this.startResize} />
                <div className="resize-bottom-left" onMouseDown={this.startResize} />
                <div className="resize-bottom-right" onMouseDown={this.startResize} />
            </TextBoxStyled>
        );
    }
}