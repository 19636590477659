// PROPS: pre-selected measurement guid - measGuid (string)
//        whether the measurement has been edited - edited (Boolean)
//        function for expanding system details on page - showSystemDetails (f)
//        filter for measurement guids by observable - filter (string)
//        measurement selection function - setMeas (f)

import {DetailBox, NodeComboBox} from "./stateless";
import React from "react";
import {deGuidify} from "./util";
import PhenomId from "../../requests/phenom-id";


export class Measurement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            measurements: {},
            measurement: {},
            defaultName: "",
            parent: "",
            edited: false,
            showSystems: false,
        };

        this.encodedMeasurementReferences = {};

        this.valueTypeUnits = undefined;
    }

    componentDidMount() {
        this.setupCachedData();
    }

    resetMeasurementCache() {
        const cachePromise = window["resetCache"]();
        return cachePromise.then(this.setupCachedData);
    }

    setupCachedData = () => {
        window["cachedRequest"]("measurementData").then((response) => {
            const measurements = deGuidify(response.data.nodes.sort((a, b) => a.name.localeCompare(b.name)));
            this.setState({
                measurements: measurements,
                measurement: measurements[this.props.measGuid] || {},
            });
        });
        window["cachedRequest"]("newVTUS").then(res => this.valueTypeUnits = res.value_type_units);
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.measGuid !== prevProps.measGuid && (!this.props.measGuid || !this.props.measGuid.includes('PHENOM-'))) {
            const measurement = this.state.measurements[this.props.measGuid] || {};

            this.setState({measurement: measurement,});
        }
        // the measurement selection is changed by the component
        if (this.state.measurement.guid !== prevState.measurement.guid) {
            this.props.setMeas(this.state.measurement);
        }
        // the filter has updated
        if (this.props.filter && this.props.filter !== prevProps.filter && this.state.measurement.realizes !== this.props.filter && this.state.measurement.guid) {
            const possibleMeas = Object.values(this.state.measurements).find(meas => meas.realizes === this.props.filter);
            this.setState({measurement: possibleMeas || {}});
        }
        // toggle the showing of systems
        if (this.state.showSystems !== prevState.showSystems) {
            this.props.setShowSystems(this.state.showSystems);
        }
        // the measurement was edited at one point, no longer exists, and is therefore no longer edited
        if (this.state.edited && !this.state.measurement.guid) {
            this.setState({edited: false});
        }
    }

    generateDefaultName() {
        const msName = this.state.measurement.measurementSystem.name.split("MeasurementSystem")[0];
        const axis = Object.values(this.props.axisRefs())[0];
        const valueType = axis.state.valueType;
        const unit = axis.state.unit;
        const vtuName = valueType.name + unit.name;

        return `${msName}_${vtuName}_Meas`;
    }


    reportState() {
        return this.state;
    }

    setCreatedMeasurement(measurement) {
        this.state.measurements[measurement.guid] = measurement;
        this.setState({
            measurement: measurement,
        });
    }

    reset(guid) {
        this.props.setObsGuid(this.state.measurements[guid].realizes);
        this.props.setMeas(this.state.measurements[guid]);
        this.setState({measurement: this.state.measurements[guid]});
    }

    findReplacement() {
        const comradeMeasures = Object.values(this.state.measurements).filter(measure => measure.realizes === this.state.measurement.realizes);
        const replacementMeasure = comradeMeasures.find(comradeMeasure => {
            const compadreAxes = comradeMeasure.measurementAxis;
            const myAxes = Object.values(this.props.axisRefs());

            if (comradeMeasure.guid === this.state.measurement.guid || compadreAxes.length !== myAxes.length) {
                return false;
            }
            const compadreAxesData = compadreAxes.map((axis) => {
                let vtuGuid = axis.valueTypeUnit || axis.measurementSystemAxis.defaultValueTypeUnit;
                vtuGuid = vtuGuid.split(" ")[0];
                const vtu = this.valueTypeUnits[vtuGuid];

                return {
                    vt: vtu.valueType.guid,
                    u: vtu.unit.guid,
                    p: axis.precision,
                    guid: axis.measurementSystemAxis.guid,
                };
            });
            return myAxes.every(axis => {
                const vt = axis.state.valueType.guid;
                const u = axis.state.unit.guid;
                const p = axis.state.precision;
                const guid = axis.state.axis.measurementSystemAxis.guid;

                return compadreAxesData.find(compadeAxis =>  compadeAxis.vt === vt && compadeAxis.u === u && compadeAxis.p === p && compadeAxis.guid === guid);
            });
        });
        return replacementMeasure;
    }

    detectAxisChange = () => {
        let replacementMeasure;
        const myAxisGuids = this.state.measurement.measurementAxis.map(axis => axis.guid);
        const axesEdited = Object.values(this.props.axisRefs())
            .filter(axis => myAxisGuids.includes(axis.state.axis.guid))
            .some(axis => axis.state.edited);
        if (axesEdited) {
            replacementMeasure = this.findReplacement();
        }

        if (replacementMeasure) {
            this.props.noteRef().note(`${this.state.measurement.name} has been replaced with ${replacementMeasure.name}`, "info");
            this.setState({measurement: replacementMeasure}, this.saveAxisChanges);
        } else {
            this.setState({
                edited: axesEdited,
                defaultName: this.generateDefaultName()
            }, this.saveAxisChanges);
        }
    };

    saveAxisChanges = () => {
        const parentGuid = this.state.parent;

        const measurement = !this.state.edited ? this.state.measurement : {
            guid: 'PHENOM-' + this.state.measurement.guid,
            parent: parentGuid || this.state.measurement.parent,
            xmiType: "logical:Measurement",
            name: this.state.defaultName,
            deconflictName: true,
            description: "",
            measurementSystem: this.state.measurement.measurementSystem.guid,
            realizes: this.state.measurement.realizes,
            measurementAxis: undefined
        };
        measurement.measurementAxis = Object.values(this.props.axisRefs()).map(axis => {
            if(axis.state.edited) {
                return ({
                    guid: 'PHENOM-' + axis.state.axis.guid,
                    parent: parentGuid || this.state.measurement.parent,
                    xmiType: "logical:MeasurementAxis",
                    name: axis.state.defaultAxisName,
                    deconflictName: true,
                    precision: axis.state.precision,
                    measurementSystemAxis: axis.state.axis.measurementSystemAxis,
                    realizes: this.state.measurement.observable,
                    realizations: [],
                    valueTypeUnit: {
                        guid: 'PHENOM-' + axis.state.vtunit.guid,
                        parent: parentGuid || this.state.measurement.parent,
                        xmiType: "logical:ValueTypeUnit",
                        name: axis.state.valueType.name + axis.state.unit.name,
                        valueType: axis.state.valueType.guid,
                        unit: axis.state.unit.guid,
                        deconflictName: true,
                    },
                });
            } else {
                return axis.state.axis;
            }
        });
        this.props.setMeas(measurement, true);
    }

    render() {
        const phenomId = new PhenomId("measurement",this.props.idCtx);
        let measurementSystem = false;
        let coordinateSystem = false;

        if (this.state.measurement.measurementSystem) {
            measurementSystem = this.state.measurement.measurementSystem;
            coordinateSystem = measurementSystem.coordinateSystem || {};
        }

        return (
            <div
              // id="measurement-wrapper"
              id={phenomId.gen("","wrapper")}>
                <div className="flex-h" style={{justifyContent: "space-between"}}>
                    <div className="detail-box">
            <span>
              Measurement
                {(!measurementSystem && !coordinateSystem) ||
                <button
                    className={`form-button ${this.state.showSystems ? "collapse-left" : "expand-right"}`}
                    title={this.state.showSystems ? "Hide System Details" : "Show System Details"}
                    onClick={() => this.setState({showSystems: !this.state.showSystems})}
                    id={phenomId.gen("","show-system-button")}>
                </button>}
            </span>
                        {this.state.edited ?
                            <input
                                className="cadet-text-input"
                                disabled={true}
                                type="text"
                                value={this.state.defaultName}
                                id={phenomId.gen("","name-input")}
                            /> :
                            <select
                                disabled={this.props.disabled}
                                className="cadet-select"
                                onChange={(e) => e.target.value ?
                                    this.setState({measurement: this.state.measurements[e.target.value]}) :
                                    this.setState({measurement: {}})}
                                value={this.state.measurement.guid}
                                id={phenomId.gen("","select")}
                            >
                                <option value="" id={phenomId.gen(["init","default"],"option")}>--- Select Measurement ---</option>
                                {Object.values(this.state.measurements).map((measurement, idx) => {
                                    if (!this.props.filter || measurement.realizes === this.props.filter) {
                                        return <option value={measurement.guid} key={idx} id={phenomId.gen(["init",idx],"option")}>{measurement.name}</option>;
                                    }
                                })}
                            </select>}
                        <textarea disabled={true} value={this.state.measurement.description || ""} id={phenomId.gen("init","description-text-area")}/>

                        {this.state.edited && <div style={{ padding: 5 }}>
                        
                        <NodeComboBox xmiType="face:LogicalDataModel"
                                      placeholder="Package"
                                      selectedGuid={this.state.parent}
                                      onChange={(parent) => this.setState({ parent: parent.guid })} />
                                      
                                      </div>}
                    </div>
                    <DetailBox show={this.state.showSystems} label="Measurement System" name={measurementSystem.name}
                               description={measurementSystem.description} id={phenomId.gen("init","measurement-system")}/>
                    <DetailBox show={this.state.showSystems} label="Coordinate System" name={coordinateSystem.name}
                               description={coordinateSystem.description} id={phenomId.gen("init","coordinate-system")}/>
                </div>
            </div>
        );
    }
}
