import React from 'react';
import ReactTooltip from "react-tooltip";
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { PortWidget } from "@projectstorm/react-diagrams-core";

import { isStormData, splitPortGuid } from "../../util";
import { BaseNodeModel, BaseNodeWidget } from '../../base/BaseNode';
import { BasePortModel } from "../../base/BasePort";
import StormData from '../../base/StormData';

import {
  nodeProps,
} from '../../index';





export class ViewTraceNodeFactory extends AbstractReactFactory {
  constructor() {
    super("view-trace-node");
  }

  generateModel(event) {
    return new ViewTraceNodeModel();
  }

  generateReactWidget(event) {
    return <ViewTraceNodeWidget engine={this.engine} node={event.model}/>;
  }
}


export class ViewTraceNodeModel extends BaseNodeModel {
  constructor(options={}, $app, settings={}, ) {
    super(
      {...options, type: "view-trace-node"},
      $app,
      settings,
    )

    this.settings = {
      ...this.settings,
      reverseAlignment: true,
      ...settings,
    };

    this.initializeDrawToolOutPort();
  }

  serialize() {
    return {
      ...super.serialize(),
      nodeData: {
        guid: this.getGuid(),
      }
    };
  }

  deserialize(event) {
    this.$app = event.engine.$app;

    this.options.nodeData = event.data.nodeData;
    if (!isStormData(this.options.nodeData)) {
      this.options.nodeData = new StormData();
    }
    
    super.deserialize(event);
    this.$app.setNodeModelFromCharGuids(this);
  }

  initializeDrawToolOutPort = () => {
    const portInfo = {
      name: "draw-tool",
      in: false,
      attrData: new StormData(),
    }

    const port = new BasePortModel(portInfo);
    this.addPort(port);
    port.reportPosition();
  }


  // ------------------------------------------------------------
  // Getters
  // ------------------------------------------------------------


  // ------------------------------------------------------------
  // Setters
  // ------------------------------------------------------------
  setNodeAlignment = () => {
    const zoomLevel = this.$app.model.getZoomLevel() / 100;
    const canvas_center = this.$app.getCanvasLayerDOM().clientWidth / zoomLevel / 2;
    const node_center = (this.width || nodeProps.minWidth) / 2;
    
    const rightHalfOfDiagram = this.getX() + node_center > canvas_center;
    
    this.setSettings("reverseAlignment", rightHalfOfDiagram);
    this.setPortAlignment();
  }

  setPortAlignment = () => {
    const zoomLevel = this.$app.model.getZoomLevel() / 100;

    Object.values(this.getPorts()).forEach(port => {
      if (port.getName() === 'draw-tool') return;

      const attrGuid = splitPortGuid(port.getName())[0];
      const attrRef = this.getWidgetChildRef(attrGuid);

      // if attribute is hidden set position to the top of node
      let posX = this.getX();
      let posY = this.getY();

      if (attrRef) {
        const attrRect = attrRef.getBoundingClientRect();
        const attrPoint = this.$app.engine.getRelativePoint(attrRect.x, attrRect.y);
        posY = (attrPoint.y + attrRect.height / 2) / zoomLevel;
      }

      if (this.getSettings().reverseAlignment) {
        port.setPosition({ x: posX, y: posY })
      } else {
        port.setPosition({ x: posX + (this.width || nodeProps.minWidth), y: posY })
      }

      port.reportPosition();
      this.setLinkDisplay(port);
    }) 
  }

  setLinkDisplay = (port) => {
    // Hide/Show link
    const link = Object.values(port.getLinks())[0];
    
    if (link?.targetPort) {
      const { sourcePort, targetPort } = link;
      let { reverseAlignment } = this.getSettings();
      let otherSettings = port.options.in ? sourcePort.parent.getSettings() : targetPort.parent.getSettings();

      if (reverseAlignment === otherSettings.reverseAlignment) {
        link.hideLink();
      } else {
        link.showLink();
      }
    }
  }

  // ------------------------------------------------------------
  // NODE
  // ------------------------------------------------------------
  updateNodePositionBasedOnZoom = (oldZoomLevel) => {
    const prevZoomLevel = oldZoomLevel / 100;
    const currZoomLevel = this.$app.model.getZoomLevel() / 100;
    const canvasRect = this.$app.getCanvasLayerDOM().getBoundingClientRect();

    const widthOld = canvasRect.width / 2 / prevZoomLevel;
    const widthCurr = canvasRect.width / 2 / currZoomLevel;
    const widthDiff = widthOld - widthCurr;

    this.setPosition(this.getX() - widthDiff, this.getY());
  }


  traceAllViewAttributes = async () => {
    let tracedAttrs = new Set();

    for (let childData of this.getChildrenData()) {
      if (tracedAttrs.has(childData.getGuid())) {
        continue;
      }

      const matchCreatedFor = await this.findAndTraceAttributeMatches(childData);
            matchCreatedFor.forEach(guid => tracedAttrs.add(guid));
    }
  }

  /**
   * 
   * @param {StormData} attrData 
   * @returns 
   */
   findAndTraceAttributeMatches = async (attrData) => {
    const matchCreatedFor = new Set();
    
    // invalid node
    if (!isStormData(attrData)) {
      return matchCreatedFor;
    }

    const tracingGuids = attrData.getAttr("path") || "";

    for (let viewNodeModel of this.$app.model.getNodes()) {
      for (let charData of viewNodeModel.getChildrenData()) {
        if (tracingGuids === charData.getAttr("path")) {
          await viewNodeModel.traceViewAttribute(charData);
          matchCreatedFor.add(charData.getGuid());
        }
      }
    }

    return matchCreatedFor;
  }

  /**
   * 
   * @param {StormData} attrData 
   */
  traceViewAttribute = async (attrData) => {
    // invalid node
    if (!isStormData(attrData)) {
      return;
    }

    const tracingGuids = attrData.getAttr("path");

    try {
      if (!tracingGuids) throw `path is empty for ${attrData.rolename}.`;
      
      let vertexNodeModel = this.$app.getVertexNodeModel(tracingGuids);

      // create new Vertex if it doesn't exist
      if (!vertexNodeModel) {
        const vertexData = this.$app.createVertexStormData(tracingGuids);

        const attrRef = this.getWidgetChildrenRef()[attrData.getGuid()];
        const rect = attrRef.getBoundingClientRect();

        const point = this.$app.engine.getRelativePoint(rect.x, rect.y);
        vertexNodeModel = await this.$app.createVertexNodeModel(vertexData, {x: point.x, y: point.y});
        vertexNodeModel.setNodeColor();
      }

      let srcPort = this.getPort(attrData.getGuid()) || this.setOutPort(attrData);
      let dstPort = vertexNodeModel.getPort(attrData.getGuid()) || vertexNodeModel.setInPort(attrData);

      if (srcPort && dstPort) {
        const link = this.$app.createLink(srcPort, dstPort);
              link.setLinkColor(vertexNodeModel.getNodeColor());
      }

    } catch (err) {
      console.error(err)
    }
  }

  traceViewAttributesToExistingVertices = () => {
    for (let attrData of this.getChildrenData()) {
      if (this.$app.hasVertexNodeModel(attrData.getGuid(" "))) {
        this.traceViewAttribute(attrData);
      }
    }
  }
}






class ViewTraceNodeWidget extends BaseNodeWidget {
  constructor(props) {
    super(props);

    this.nodeModel.registerListener({
      selectionChanged: (e) => {
        if (this.widgetRef !== undefined) {
          if (e.isSelected) {
            this.widgetRef.parentElement.style.zIndex = "1";
          } else {
            this.widgetRef.parentElement.style.zIndex = "0";
          }
        }
      }
    });
  }

  componentDidMount() {
    this.nodeModel.setNodeAlignment();
  }

  componentDidUpdate(_, prevState) {
    this.nodeModel.setNodeAlignment();

    if (prevState.isEditing !== this.state.isEditing) {
      ReactTooltip.hide();
    }
  }

  getObservableData = (charData) => {
    const measData = this.$app.getDiagramStormData(charData.getAttr("measurement"));
    if (!isStormData(measData)) {
      return;   // not found
    }

    const obsData = this.$app.getDiagramStormData(measData.getAttr("realizes"));
    if (!isStormData(obsData)) {
      return;   // not found
    }

    return obsData;
  }

  getPortWidgetCoord = (guid) => {
    const attrRef = this.childrenRef[guid];
    const rightSide = this.nodeModel.getSettings().reverseAlignment;
    
    if (!attrRef) {
      return {
        x: rightSide ? this.nodeModel.width : 0,
        y: 0,
      }
    }

    const nodeRect = this.widgetRef.getBoundingClientRect();
    const attrRect = attrRef.getBoundingClientRect();
    

    return {
      x: rightSide ? attrRect.width + attrRect.x : attrRect.x,
      y: attrRect.y - nodeRect.y + (attrRect.height / 2),
    }
  }


  renderResizeBars = () => {
    return <div>
              <div id={this.phenomId.genPageId("resize-left-bar")} className="resize-left" onMouseDown={this.startResize} />
              <div id={this.phenomId.genPageId("resize-right-bar")} className="resize-right" onMouseDown={this.startResize} />
           </div>
  }

  renderChildrenHeader = () => {
    const classes = ["attribute-row", "header"];
    if (this.nodeModel.getSettings().reverseAlignment) {
      classes.push("reverse");
    }

    return <div className={classes.join(" ")}>
              <div className="attribute-cell">Rolename</div>
              <div className="attribute-cell">Observable</div>
            </div>
  }

  renderChildren = () => {
    const stormData = this.nodeModel.getStormData();
    const settings = this.nodeModel.getSettings();

    return stormData.getChildren().map((childGuid, idx) => {
      const attrData = this.$app.getDiagramStormData(childGuid);
      
      if (!isStormData(attrData)) {
        return null;
      }

      const isOptional = attrData.getAttr("optional") === "true";
      const obsData = this.getObservableData(attrData);
      
      // NestedViews are not supported yet
      if (!isStormData(obsData)) {
        return null;
      }

      // ClassNames
      const classes = ["attribute-row"];
      if (settings.reverseAlignment) {
        classes.push("reverse");
      }

      const mappingFor = this.$app.getMappingFor();
      const isTargetable = mappingFor && mappingFor.viewNodeModel !== this.nodeModel && mappingFor.reverseAlignment !== settings.reverseAlignment;
      if (isTargetable) {
        classes.push("selectable-row");
      }

      return (
        <div key={`key-${attrData.getGuid()}`}
                      className={classes.join(" ")}
                      ref={el => this.childrenRef[attrData.getGuid()] = el}
                      onMouseUp={(e) => isTargetable && this.$app.endDragMapping(attrData)}>
          <div className="attribute-cell"
               data-tip={attrData.getAttr("descriptionExtension")}
               data-for="diagramTip">
            <a onClick={() => this.$app.props.$manager.showSemanticsFor(attrData.getGuid())}>
              {isOptional
                ? `[${attrData.getName()}]`
                : attrData.getName() }
            </a>
          </div>

          <div className="attribute-cell"
               data-tip={obsData.getAttr("description")}
               data-for="diagramTip">
            {obsData
              ? <a data-port-name="draw-tool"
                   onMouseDown={(e) => this.$app.startDragMapping(e, attrData)} 
                   onClick={() => this.nodeModel.findAndTraceAttributeMatches(attrData)}>
                  {obsData.getName()}</a>
              : <span /> }
          </div>
        </div>
      )
    })
  }

  render() {
    const stormData = this.nodeModel.getStormData();

    if (!isStormData(stormData)) {
      return null;
    }

    return (
      <div id={this.phenomId.genPageId("-view-container")}
           className="node-container node-font-color"
           style={{ "--nodeColor": this.nodeModel.getNodeColor(),
                    width: this.nodeModel.width || null }}
           ref={el => this.widgetRef = el}>

        { this.renderHalo() }
        { this.renderResizeBars() }

        <div id={this.phenomId.genPageId("header")}
             className="node-header node-color-background"
             data-tip={stormData.getAttr("description")} 
             data-for="diagramTip">
            <span>{stormData.getName()}</span>
        </div>

        <div className="attribute-table">
          { this.renderChildrenHeader() }
          { this.renderChildren() }
        </div>

        <div className="anchor-point-container behind">
          {Object.values(this.nodeModel.getPorts()).map(port => {
            if (port.getName() === 'draw-tool') {
              return <PortWidget key={port.options.id}
                                 engine={this.props.engine}
                                 port={port}
                                 style={{
                                  position: "absolute",
                                  left: "50%",
                                  top: "50%",
                                 }} />
            }

            const attrGuid = splitPortGuid(port.getName())[0];
            const left = this.nodeModel.getSettings().reverseAlignment ? 0 : "100%";
            const {x, y} = this.getPortWidgetCoord(attrGuid);

            return <PortWidget key={port.options.id}
                               engine={this.props.engine}
                               port={port}
                               style={{
                                position: "absolute",
                                left,
                                top: y,
                               }} />
          })}
        </div>
      </div>
    );
  }
}