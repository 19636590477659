import React, { forwardRef, useCallback, useState } from 'react'
import styled from '@emotion/styled'
import { SubMenuRight } from './edit-top-buttons'
import { ColorCollapsable, DeprecatedBanner, PackageComboBox, PhenomInput, PhenomLabel, PhenomTextArea, KbButton } from '../util/stateless'
import { Tags } from '../util/tags'
import { createPhenomGuid, isPhenomGuid, getShortenedStringRepresentationOfXmiType, retrieveNestedNodes, splitXmiType, prepareNodesForSmmSave } from '../util/util'
import { cloneDeep } from 'lodash'
import { createNodeUrl } from '../../requests/type-to-path'
import DeletionConfirm2 from '../dialog/DeletionConfirm2'
import { smmSaveNodes, modelGetNode, smmDeleteNodes, _ajax } from '../../requests/sml-requests'
import { connect } from 'react-redux'
import * as actionCreators from '../../requests/actionCreators'
import { useEffect } from 'react'
import { Button } from "@progress/kendo-react-buttons";
import { PhenomButtonLink, PhenomLink } from '../widget/PhenomLink'
import NavTree from "../tree/NavTree";
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid'
import { Dialog } from '@progress/kendo-react-dialogs'
import { orderBy } from '@progress/kendo-data-query'
import { PhenomModal } from '../util/Modal'
import LoadingPage from './loading-page'
import loadingIcon from "../../images/Palette Ring-1s-200px.gif";


function PageLayout(WrappedComponent, options={}) {
  const { renderSaveBtn=true, renderDeleteBtn=true, renderResetBtn=true, renderKbBtn=true } = options;

  return class extends React.Component {
    // ------------------------------------------------------------
    // State
    // ------------------------------------------------------------
    compRef = React.createRef();
    duplicateRef = React.createRef();
    state = {
      loading: true,
      node: {},
      nodesOfType: {},
      editable: true,
      pageNotFound: false,
      showImpactPopup: false,
      showDuplicatePopup: false,
    }

    // ------------------------------------------------------------
    // Life Cycle Methods
    // ------------------------------------------------------------
    componentDidMount() {
      this.getNode();
      this.getNodesOfTypeFromAddenda();
      window.addEventListener('MODEL_GET_NODE_404', this.receiveModelGetNode404);
      window.addEventListener('DELETED_NODES', this.removeNodesListener);
      window.addEventListener('MOVED_NODES', this.mutateOriginalParentListener);
      window.addEventListener('MOVED_NODES', this.refreshAfterNodeMoveListener);
    }

    componentWillUnmount() {
      window.removeEventListener('MODEL_GET_NODE_404', this.receiveModelGetNode404);
      window.removeEventListener('DELETED_NODES', this.removeNodesListener);
      window.removeEventListener('MOVED_NODES', this.mutateOriginalParentListener);
      window.removeEventListener('MOVED_NODES', this.refreshAfterNodeMoveListener);
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.match.params.guid !== this.props.match.params.guid) {
        this.resetLayout();
        this.getNode();
        this.getNodesOfTypeFromAddenda();
      } 
      
      if (prevState.node !== this.state.node ||
          prevProps.subModels !== this.props.subModels) {
            this.setEditingStatus();
      }
    }

    // ------------------------------------------------------------
    // Getters
    // ------------------------------------------------------------


    // ------------------------------------------------------------
    // Setters
    // ------------------------------------------------------------
    resetLayout = () => {
      this.setState({ 
        node: {}, 
        nodesOfType: {}, 
        editable: true, 
        pageNotFound: false, 
        loading: true 
      });
    }

    setStateNode = (node={}) => {
      this.setState({ node, pageNotFound: false, loading: false })
    }

    renderPageNotFound = (bool=true) => {
      this.setState({ pageNotFound: bool });
    }

    setEditingStatus = (bool) => {
      if (typeof bool === 'boolean') {
        return this.setState({ editable: bool });
      }

      const subModelId = this.state.node?.subModelId;
      const currSubModel = this.props.subModels[subModelId];
      this.setState({ editable: !currSubModel?.created });
    }

    // ------------------------------------------------------------
    // Fetch Data
    // ------------------------------------------------------------

    // TEMPORARY - this is for old detail pages
    updateTemplateNode = (node) => {
      this.setStateNode(cloneDeep(node));
    }

    getNode = () => {
      const { defaultProps } = WrappedComponent;
      // Temporary - exit if the component does not recognize the layout
      if (!defaultProps) {
        return this.setStateNode();
      }

      if (this.props.match.params.guid === "new") {
        return this.setStateNode({
          guid: createPhenomGuid(),
          ...cloneDeep(defaultProps.newNode),
        })
      }
      modelGetNode(this.props.match.params.guid, defaultProps.nodeAddenda)
        .then(res => this.setStateNode(res))
        .fail(err => err.status === 500 && this.renderPageNotFound())
    }

    getNodesOfTypeFromAddenda = () => {
      const { defaultProps } = WrappedComponent;

      // exit if the component does not need it
      if (!defaultProps?.nodesOfTypeAddenda) {
        return;
      }

      for (let listType in defaultProps.nodesOfTypeAddenda) {
        const data = defaultProps.nodesOfTypeAddenda[listType];

        _ajax({
          url: "/index.php?r=/node/model-nodes-of-type",
          method: "get",
          data,
        }).then(res => {
          const list = res.data.nodes;

          this.setState((prevState) => ({
            nodesOfType: {
              ...prevState.nodesOfType,
              [listType]: list,
            }
          }))
        })
      }
    }

    // ------------------------------------------------------------
    // Event Handlers
    // ------------------------------------------------------------
    handleDuplicateSave = () => {
      if (!this.duplicateRef.current) {
        return;
      }

      const { mainNode, additionalNodes=[] } = this.duplicateRef.current.generateNodes();
      
      // something went wrong or something was changed
      if (!mainNode?.guid) {
        return;
      }

      const nodes = [ mainNode, ...additionalNodes ];

      return smmSaveNodes({
        nodes,
        changeSetId: actionCreators.getActiveChangeSetId(),
        returnTypes: [mainNode.xmiType],
        returnAddenda: {
          [mainNode.xmiType]: WrappedComponent.defaultProps?.nodeAddenda,
        }
      }).then((res) => {
        const response = res.data;
        actionCreators.receiveResponse(response);

        if (!Array.isArray(response?.nodes)) {
          return;
        }

        const { nodes=[], referencees={} } = response;

        // mainNode has a phenom-guid
        // referencees is a hashmap to locate the real-guid
        const realGuid = referencees[mainNode.guid];

        // have to find the real node from nodes array
        const realNode = nodes.find((n) => n.guid === realGuid);

        NavTree.addNodes(response.nodes);
        window["resetCache"]();             // refresh VTUs
        this.setState({ showDuplicatePopup: false }, () => {
          realNode && this.props.history.push( createNodeUrl(realNode) );
        });
      })
    }

    /**
     * Defaults to smm-save-nodes
     *    provide handleSave method to page component, if a different save method is required
     */
    handleSave = async () => {
      if (this.compRef.current.handleSave) {
        return this.compRef.current.handleSave();
      }

      // need to check if the function exist because every detail page doesn't have it yet
      if (this.compRef.current.validateNode && !this.compRef.current.validateNode()) {
        return actionCreators.receiveWarnings("Please fill in the missing fields");
      }

      try {
        const requestData = this.compRef.current.generateNode();
        // cancel request - no node was returned because an "error" occured within the child
        if (!requestData?.guid) {
          return;
        }

        // Delete nodes
        //  -> children nodes need to be deleted separately - i.e. Characterisitc's UnionCase
        let guidsToBeDeleted = [];
        if (this.compRef.current.getGuidsToBeDeleted) {
          guidsToBeDeleted = this.compRef.current.getGuidsToBeDeleted();
        }

        if (guidsToBeDeleted.length) {
          const deletionStatus = await smmDeleteNodes(guidsToBeDeleted);

          if (deletionStatus.errors || deletionStatus.error) {
            return actionCreators.receiveErrors("Something went wrong.");
          }
        }

        // flatten the nodes
        // filters out published nodes
        // removes undefined and null
        const nodes = prepareNodesForSmmSave([requestData], this.props.subModels);

        // edge case:
        // all nodes are published and was filtered out
        // nodes is an empty array
        if (!nodes.length) {
          return actionCreators.receiveErrors("Something went wrong. Cancelled the save request.");
        }

        // Save nodes
        return smmSaveNodes({
          nodes,
          changeSetId: actionCreators.getActiveChangeSetId(),
          returnTypes: [requestData.xmiType],
          returnAddenda: {
            [requestData.xmiType]: WrappedComponent.defaultProps?.nodeAddenda,
          }
        }).then((res) => {
          const response = res.data;
          actionCreators.receiveResponse(response);
  
          if (response.nodes?.length) {
            NavTree.addNodes(response.nodes);
            window["resetCache"]();             // refresh VTUs
            const node = response.nodes[0];
  
            if (this.props.match.params.guid === "new") {
              this.props.history.push( createNodeUrl(node) );
            } else {
              this.setStateNode(node);
            }
          }
        })

      } catch (error) {
        return actionCreators.receiveErrors("An unexpected error occurred. Please try again or contact Skayl Support for help.");
      }
    }

    /**
     * Provide handleReset method to page component, if a different reset method is required
     */
    handleReset = () => {
      if (this.compRef.current.handleReset) {
        return this.compRef.current.handleReset()
      }

      this.setState({ node: cloneDeep(this.state.node) });
    }

    /**
     * Provide handleDelete method to page component, if a different delete method is required
     */
    handleDelete = () => {
      if (this.compRef.current.handleDelete) {
        return this.compRef.current.handleDelete()
      }

      const { node } = this.state;
      DeletionConfirm2.show(node.guid, node.name, this.getNode);
    }

    // triggered by event listeners
    receiveModelGetNode404 = (data) => {
      const { detail } = data;
      if(detail.guid === this.props.match.params.guid) {
        this.renderPageNotFound();
      }
    }

    removeNodesListener = (e) => {
      const { node } = this.state;
      let guidsRaw = e?.detail?.guids;

      // invalid
      if (!Array.isArray(guidsRaw)) {
        return;
      }

      const guids = new Set(guidsRaw);
      if (guids.has(this.props.match.params.guid)) {
        return this.renderPageNotFound();
      }

      if (!Array.isArray(node?.children)) {
        return;
      }

      // mutate children (remove deleted children)
      node.children = node.children.reduce((total, curr) => {
        if (!guids.has(curr.guid)) total.push(curr);
        return total;
      }, []);
    }

    mutateOriginalParentListener = (e) => {
      // invalid
      if (!this.state.node?.guid) {
        return;
      }

      const nodeList = retrieveNestedNodes([this.state.node]);
      for (let node of nodeList) {
        const leaf = NavTree.getLeafNode(node.guid);
        if (!leaf) continue;

        const currParentGuid = typeof node.parent === 'string' ? node.parent : node.parent?.guid;
        const newParentGuid = leaf.getParentGuid();
        if (currParentGuid !== newParentGuid) {
          node["parent"] = newParentGuid;
        }
      }

      this.forceUpdate();
    }

    refreshAfterNodeMoveListener = (e) => {
      const { defaultProps } = WrappedComponent;
      const { node } = this.state;
      let guidsRaw = e?.detail?.guids;

      // invalid format
      if (!Array.isArray(guidsRaw) || !node?.guid) {
        return;
      }

      // Some detail pages were not refactored yet
      if (!defaultProps) {
        return;
      }

      const movedGuids = new Set(guidsRaw);

      // this will find all nested nodes and flatten it into a single array
      const nodeList = retrieveNestedNodes([node]);
      const nodeWasMovedOut = nodeList.find(node => movedGuids.has(node.guid));

      // if any node was moved out then refresh the page
      if (nodeWasMovedOut) {
        actionCreators.receiveLogs("A node was moved out. Refreshing the page.");
        return this.getNode();
      }

      // special cases - children nodes were added to the detail page via drag-n-drop
      const movedLeaves = guidsRaw.map(guid => NavTree.getLeafNode(guid));
      const childWasAdded = movedLeaves.find(nodeLeaf => nodeLeaf && nodeLeaf.getParentGuid() === node.guid);  // nodeLeaf can be undefined

      // if a child node was added then refresh the page
      if (childWasAdded) {
        actionCreators.receiveLogs("A node was moved in. Refreshing the page.");
        return this.getNode();
      }
    }

    renderCloneButton = () => {
      const { node } = this.state;

      if (typeof node?.xmiType !== 'string' || node.xmiType.match(/^(face|datamodel|uop|skayl):.*Model$/)) {
        return null;
      }

      return <button id="form-action-duplicate"
                     className="fas fa-copy"
                     title="Copy Element"
                     onClick={() => this.setState({ showDuplicatePopup: true })} />
    }

    renderComponent = () => {
      const { node, nodesOfType, editable, loading } = this.state;
      const { canEdit, expired, model_uid, subModels } = this.props;
      const useGrid = !!WrappedComponent.defaultProps;
      const isDeprecated = node.deprecated === "true"
      const isEditable = canEdit && editable && !isDeprecated && !expired;

      // found in NavTree's Package context menu - "Create Package"
      // -- changes the url and comes with PrevState
      const parentNode = this.props.location.state?.parentNode;

      if (loading) {
        return <LoadingPage />
      }

      if (useGrid) {
        return <div className="phenom-content-scrollable">
                <div className="edit-form-container">
                  <DeprecatedBanner deprecated={node.deprecated} />
                  <WrappedComponent node={node}
                                    nodesOfType={nodesOfType}
                                    parentNode={parentNode}
                                    editable={isEditable}
                                    model_uid={model_uid}
                                    history={this.props.history}
                                    ref={this.compRef} />
                  {this.props.match.params.guid !== "new" &&
                  <Tags guid={this.props.match.params.guid} name={node?.name} disabled={!editable} /> }
                </div>
              </div>
      }

      return <div className="phenom-content-scrollable" >
              <WrappedComponent renderPageNotFound={this.renderPageNotFound}
                                editable={canEdit}
                                model_uid={model_uid}
                                subModels={subModels}
                                scrollToGuid={this.props.location.state?.scrollToGuid}
                                ref={this.compRef}
                                setParentEditingStatus={this.setEditingStatus}
                                updateTemplateNode={this.updateTemplateNode}
                                {...this.props} />
            </div>
    }

    render() {
      const { node, editable, pageNotFound, showImpactPopup, showDuplicatePopup } = this.state;
      const { canEdit, expired } = this.props;
      const { params } = this.props.match;
      const isNewPage = params.guid === "new";
      const isDeprecated = node.deprecated === "true"
      const isEditable = canEdit && editable && !isDeprecated && !expired;
      const isDeleteable = canEdit && editable && !expired;

      if (pageNotFound) {
        return <PageNotFound />
      }

      // NOTE: DO NOT CHANGE THE NAME OF phenom-content-scrollable
      // --> it is needed for PhenomCombobox

      return <>
        <nav className="sub-menu-actions" aria-label='form actions'>
          {canEdit &&
          <SubMenuRight>
            { renderKbBtn &&
              <KbButton />}

            {node?.guid && !isPhenomGuid(node.guid) &&
            <button id="form-action-impact-analysis"
                    className='fas fa-bezier-curve'
                    title="Show Impact Analysis"
                    onClick={() => this.setState({ showImpactPopup: true })} /> }

            { !isNewPage && this.renderCloneButton() }

            {renderSaveBtn &&
            <button id="form-action-save"
                    className="fas fa-save"
                    title="Save"
                    disabled={!isEditable}
                    onClick={this.handleSave} /> }
            {renderResetBtn &&
            <button id="form-action-reset"
                    className="fas fa-undo"
                    title="Reset"
                    disabled={!isEditable}
                    onClick={this.handleReset} /> }
            {renderDeleteBtn && !isNewPage &&
            <button id="form-action-delete"
                    className="fas fa-trash"
                    title="Delete"
                    disabled={!isDeleteable}
                    onClick={this.handleDelete} /> }
          </SubMenuRight> }
        </nav>

        <PhenomModal show={showDuplicatePopup}
                     onSave={this.handleDuplicateSave}
                     onClose={() => this.setState({ showDuplicatePopup: false })}>
          <DuplicateNode node={node}
                         ref={this.duplicateRef} />
        </PhenomModal>

        {showImpactPopup &&
          <ImpactAnalysis node={node} 
                          onClose={() => this.setState({ showImpactPopup: false })} /> }

        { this.renderComponent() }
      </>
    }
  };
}



export const withNestedLayout = (WrappedComponent) => {
  return forwardRef((props, ref) => {
    const { id, editable, autoExpand,
            onClickCancelIcon, onClickTrashIcon, ...restProps } = props;
    const [activeNode, setActiveNode] = useState({});
    const [collapsed, setCollapsed] = useState(true);
    const [title, setTitle] = useState("");
    const [published, setPublished] = useState(false);
    const isDeprecated = activeNode?.deprecated === "true"
    const isEditable = editable && !isDeprecated && !published;

    useEffect(() => {
      setActiveNode(props.node);
    }, [props.node])

    useEffect(() => {
      if (!activeNode?.guid) {
        setTitle("New Node");
        setPublished(false);
        if (autoExpand) setCollapsed(false);
        return;
      }

      const nodeGuid = activeNode.guid;
      const title = activeNode.name || activeNode.rolename || "";

      if (title) {
        setTitle(title);
      } else if (activeNode?.xmiType) {
        const type = getShortenedStringRepresentationOfXmiType(activeNode.xmiType);
        setTitle(`New ${type}`);
      } else {
        setTitle("New Node");
      }

      if (autoExpand || isPhenomGuid(nodeGuid)) {
        setCollapsed(false);
      }
      setPublished(actionCreators.isModelPublished(activeNode?.subModelId));
    }, [activeNode])

    const updateLayoutNode = useCallback((node) => {
      setActiveNode(node);
    }, []);

    // invalid node - return null
    if (!activeNode?.guid) {
      return null;
    }

    const caretClasses = ["fas"];
    if (collapsed) {
      caretClasses.push("fa-caret-right");
    } else {
      caretClasses.push("fa-caret-down");
    }

    const wrapperClasses = ["edit-form-container"];
    if (collapsed) {
      wrapperClasses.push("collapsed")
    }

    return <div className="edit-collapsable">
              <header>
                <div onClick={() => setCollapsed(prev => !prev)}>
                  <span id={id ? `${id}-collapse-toggle` : null}
                        className={caretClasses.join(" ")} />
                  <span>{ title }</span>
                </div>
                <div>
                  <PhenomButtonLink node={activeNode} newTab={true} />

                  {onClickCancelIcon &&
                  <Button icon="close"
                          look="bare"
                          onClick={onClickCancelIcon} /> }

                  {onClickTrashIcon &&
                  <Button icon="trash"
                          look="bare"
                          onClick={onClickTrashIcon} /> }
                </div>
              </header>

              <div className={wrapperClasses.join(" ")}>
                <WrappedComponent ref={ref}
                                  id={id}
                                  node={activeNode}
                                  editable={isEditable}
                                  updateLayoutNode={updateLayoutNode}
                                  {...restProps} />
              </div>
            </div>
  })
}

// ==================================================
// Duplicate 
// ==================================================
class DuplicateNode extends React.Component {
  references = {};
  state = {
    node: {},
    additionalNodes: [],
    loading: true,
    suffix: "",
  }

  componentDidMount() {
    this.fetchAdditionalNodes(this.props.node);
  }

  fetchAdditionalNodes = (node) => {
    return _ajax({
      url: "/index.php?r=/node/additional-nodes-for-copy",
      method: "get",
      data: {
        guid: node.guid,
      },
    }).then((response) => {

      if (!response?.data?.node?.guid || !Array.isArray(response?.data?.nodes)) {
        return;
      }

      const additionalNodes = [];
      response.data.nodes.forEach(node => {
        // copy and convert real guid into phenom guid
        const copy = this.copyAndFormatNode(node);

        // these nodes will be saved
        additionalNodes.push(copy);
      })

      this.setState({
        node: this.copyAndFormatNode(response.data.node),
        additionalNodes,
        loading: false });
    })
  }

  copyAndFormatNode = (node) => {
    const copy = cloneDeep(node);

    // convert guid to phenom-guid
    copy.guid = createPhenomGuid();

    // map the real guid to phenom guid for easy lookup
    this.references[node.guid] = copy.guid;

    // some nodes do not have a name and cannot be deconflicted
    if (copy.name || copy.rolename) {
      copy.deconflictName = true;
    }

    this.flattenNode(copy);

    // change guids to phenom-guid
    if (Array.isArray(copy.children) && copy.children.every(c => c?.guid)) {
      copy.children = copy.children.map(c => this.copyAndFormatNode(c));

    } else {
      // children cannot be an array of strings - smm-save-nodes will yell
      copy.children = [];
    }

    return copy;
  }

  flattenNode = (n) => {
    for (let key in n) {
      if (["name", "rolename", "guid", "xmiType", "children"].includes(key)) {
        continue;
      }

      const attr = n[key];

      // attr is a node - flatten it
      if (attr?.guid) {
        n[key] = attr.guid;

      // attr is an array of nodes - flatten it  
      } else if (Array.isArray(attr) && attr.every(ele => ele?.guid)) {
        n[key] = attr.map(ele => ele.guid);
      } 
    }
  }

  convertRealGuidsToPhenomGuids = (n) => {
    for (let key in n) {
      if (["name", "rolename", "guid", "xmiType", "children"].includes(key)) {
        continue;
      }

      const attr = n[key];

      if (typeof attr === 'string') {
        // guid was converted from real to phenom-guid, redirect pointer
        if (this.references[attr]) {
          n[key] = this.references[attr];

        } else {
          attr.split(" ").forEach((guid) => {
            if (!this.references[guid]) {
              return;
            }

            const re = new RegExp(guid, "g")
            n[key] = n[key].replace(re, this.references[guid]);
          })
        }

      // guids were converted from real to phenom-guid, redirect pointers
      } else if (Array.isArray(attr) && attr.some(ele => this.references[ele])) {
        n[key] = attr.map(ele => this.references[ele] || ele);
      }
    }
  }

  getParentXmiType = () => {
    const { node } = this.state;

    if (!node?.guid) {
      return;
    }

    // some nodes can be stored in multiple package types
    switch (node.xmiType) {
      case "platform:View":
        return ["face:PlatformDataModel", "face:UoPModel"];

      // do not have a Parent Package
      case "logical:ReferencePoint":
      case "logical:ReferencePointPart":
        return;

      default:
    }

    switch (splitXmiType(node.xmiType, 1)) {
      case "conceptual":
        return "face:ConceptualDataModel";

      case "logical":
        return "face:LogicalDataModel";

      case "platform":
        return "face:PlatformDataModel";

      case "uop":
        return "face:UoPModel";

      default:
    }
  }

  updateNodeAttr = (key, value) => {
    this.setState((prevState) => {
      return {
        node: {
          ...prevState.node,
          [key]: value
        }
      }
    })
  }

  generateNodes = () => {
    const { node, additionalNodes, suffix } = this.state;
    const parentGuid = node.parent;
    const copy = cloneDeep(node);

    // convert all real guids to phenom guids
    for (let n of retrieveNestedNodes([ copy ])) {
      this.convertRealGuidsToPhenomGuids(n);
    }

    // flatten additional nodes without mutating original array
    const additional = additionalNodes.map(ele => {
      const n = cloneDeep(ele);

      // add suffix
      // some nodes do not have a name/rolename
      if (n.name) n.name += suffix;
      if (n.rolename) n.rolename += suffix;

      // this node will share the package as main node's
      n.parent = parentGuid;

      // convert all real guids to phenom guids
      for (let c of retrieveNestedNodes([ n ])) {
        this.convertRealGuidsToPhenomGuids(c);
      }

      return n;
    })

    return {
      mainNode: copy,
      additionalNodes: additional,
    };
  }

  renderNameInput = () => {
    const { node } = this.state;

    if (node.name) {
      return <PhenomInput label="Name"
                          value={node.name}
                          onChange={(e) => this.updateNodeAttr("name", e.target.value)} />

    } else if (node.rolename) {
      return <PhenomInput label="Rolename"
                          value={node.rolename}
                          onChange={(e) => this.updateNodeAttr("rolename", e.target.value)} />
    }

    // some nodes don't have a name/rolename
    return <div />
  }

  renderDescriptionInput = () => {
    const { node } = this.state;

    // because characteristic wants a special description variable
    if (node.xmiType === "platform:CharacteristicProjection") {
      return <PhenomTextArea label="Description"
                             value={node.descriptionExtension}
                             onChange={(e) => this.updateNodeAttr("descriptionExtension", e.target.value)} />
    }

    return <PhenomTextArea label="Description"
                           value={node.description}
                           onChange={(e) => this.updateNodeAttr("description", e.target.value)} />
  }

  renderSuffixInput = () => {
    const { suffix, loading, additionalNodes } = this.state;

    if (!loading && !additionalNodes.length) {
      return null;
    }

    return <PhenomInput label="Suffix for all additional elements"
                        value={suffix}
                        onChange={(e) => this.setState({ suffix: e.target.value })} />
  }

  renderFirstGridColumn = () => {
    const { additionalNodes } = this.state;
    let title = "";

    if (!Array.isArray(additionalNodes)) {
      return null;
    }

    const node = additionalNodes[0];
    if (!node?.guid) {
      return null;
    }

    if (node.name) {
      title = "Name";
    } else if (node.rolename) {
      title = "Rolename";
    } else {
      // some child nodes do not have a name/rolename
      title = "xmi:type";
    }

    return <GridColumn title={title} cell={this.renderFirstGridColumnCell} />
  }

  renderFirstGridColumnCell = (cellProps) => {
    const node = cellProps.dataItem;
    const { suffix } = this.state;

    let text;
    if (node.name) {
      text = node.name;
    } else if (node.rolename) {
      text = node.rolename;
    }

    if (text) {
      return <td>{ `${text}${suffix}` }</td>
    }

    // some nodes do not have a name/rolename
    return <td>{ node.xmiType }</td>
  }

  renderXmiTypeCell = (cellProps) => {
    const node = cellProps.dataItem;
    return <td>{ getShortenedStringRepresentationOfXmiType(node.xmiType) }</td>
  }

  renderAdditionalNodes = () => {
    const { additionalNodes, loading } = this.state;

    if (!Array.isArray(additionalNodes)) {
      return null;
    }

    if (!loading && !additionalNodes.length) {
      return null;
    }

    return <div>
      <PhenomLabel text="Additional Elements" />
      
      <Grid data={additionalNodes}>
        <GridNoRecords>
          {loading ? "Searching for additional elements..." : "No Data Is Available For This Table"}
        </GridNoRecords>
        
        { this.renderFirstGridColumn() }
        <GridColumn title="xmi:type" field="xmiType" cell={this.renderXmiTypeCell} />
      </Grid>
    </div>
  }

  render() {
    const { node, suffix } = this.state;
    if (!node?.guid) {
      return null;
    }

    const parentXmiType = this.getParentXmiType();

    return <div className="edit-form-container">
            <div className="edit-form">
              <div className="p-row">
                <div className="p-col p-col-6">
                  { this.renderNameInput() }
                </div>

                {parentXmiType &&
                <div className="p-col p-col-6">
                  <PackageComboBox label="Package"
                                    xmiType={this.getParentXmiType()}
                                    placeholder="<Default>"
                                    nodeGuid={node.guid}
                                    selectedGuid={node.parent}
                                    onChange={(parent) => this.updateNodeAttr("parent", parent.guid)} /> 
                </div> }
              </div>

              { this.renderDescriptionInput() }
              { this.renderSuffixInput() }
              { this.renderAdditionalNodes() }
            </div>
    </div>
  }
}


// ==================================================
// Impact Analysis Popup
// ==================================================
const ImpactAnalysis = (props) => {
  const [loading, setLoading] = useState(true);
  const [referencers, setReferencers] = useState([]);
  const [referencees, setReferencees] = useState([]);
  const [sortConfig1, setSortConfig1] = useState([{field: 'name', dir: 'asc'}]);
  const [sortConfig2, setSortConfig2] = useState([{field: 'name', dir: 'asc'}]);
  const node_name = props.node?.name || props.node?.rolename || "Node";

  useEffect(() => {
    if (!props.node?.guid || isPhenomGuid(props.node.guid)) {
      return;
    }

    // Root does not have an xmiType and crashes when we do xmiType.split(":")
    const findAndFormatRootNode = (accum, curr) => {
      if (curr?.guid === "root") {
        accum.push({ guid: "root", xmiType:"skayl:Root", name: "Root" });
      } else {
        accum.push(curr);
      }

      return accum;
    }

    _ajax({
      url: "/index.php?r=/node/get-node-usage",
      method: "get",
      data: { guid: props.node.guid },
    }).then((response) => {
      let referencers = [], referencees = [];

      if (Array.isArray(response?.data?.referencers)) {
        referencers = response.data.referencers.reduce(findAndFormatRootNode, [])
                                               .filter(node => !!node.xmiType);
      }

      if (Array.isArray(response?.data?.referencees)) {
        referencees = response.data.referencees.reduce(findAndFormatRootNode, [])
                                               .filter(node => !!node.xmiType);
      }

      setReferencers(referencers);
      setReferencees(referencees);
      setLoading(false);
    }) 
  }, [props.show, props.node])

  const referencers_content = <div id="impact-analysis-referencers">
    <Grid data={sortConfig1.length ? orderBy(referencers, sortConfig1) : referencers}
          sortable
          sort={sortConfig1}
          onSortChange={(e) => setSortConfig1(e.sort)}>
      <GridNoRecords>
        { loading 
          ? <div>
                <img id="loading-spinner"
                      style={{ width: 30 }}
                      src={loadingIcon} />
                <span>Fetching data</span>
            </div>
          : <span>No data available</span> }
      </GridNoRecords>
      <GridColumn title="Name" field="name" cell={(props) => <td><PhenomLink node={props.dataItem} newTab={true} /></td>} />
      <GridColumn title="Type" field="xmiType" cell={(props) => <td>{ getShortenedStringRepresentationOfXmiType(props.dataItem.xmiType) }</td>} />
    </Grid>
  </div>

  const referencees_content = <div id="impact-analysis-referencees">
    <Grid data={sortConfig2.length ? orderBy(referencees, sortConfig2) : referencees}
          sortable
          sort={sortConfig2}
          onSortChange={(e) => setSortConfig2(e.sort)}>
      <GridNoRecords>
        { loading 
          ? <div>
                <img id="loading-spinner"
                      style={{ width: 30 }}
                      src={loadingIcon} />
                <span>Fetching data</span>
            </div>
          : <span>No data available</span> }
      </GridNoRecords>
      <GridColumn title="Name" field="name" cell={(props) => <td><PhenomLink node={props.dataItem} newTab={true} /></td>} />
      <GridColumn title="Type" field="xmiType" cell={(props) => <td>{ getShortenedStringRepresentationOfXmiType(props.dataItem.xmiType) }</td>} />
    </Grid>
  </div>

  return <Dialog title="Impact Analysis"
                 className="dialog-80vh dialog-80vw"
                 onClose={props.onClose}>

    <ColorCollapsable color="#8aa5b2"
                      heading={`${node_name} used in`}
                      content={referencers_content}
                      contentId="impact-analysis-referencers"
                      vMargin={5}
                      collapsableStyle={{ border: "1px solid #8aa5b2", paddingRight: 0, marginBottom: 15 }} />

    <ColorCollapsable color="#8aa5b2"
                      heading={`${node_name} uses`}
                      content={referencees_content}
                      contentId="impact-analysis-referencees"
                      vMargin={5}
                      collapsableStyle={{ border: "1px solid #8aa5b2", paddingRight: 0 }} />
  </Dialog>
}



// ==================================================
// PAGE NOT FOUND
// ==================================================
const PageNotFound = (props) => {
  const containerStyle = {
    display:"flex",
    position: "relative",
    justifyContent:"center",
    alignItems:"center",
    minHeight: 512,
    flexDirection:"column",
  }

  const messageStyle = {
    color: "var(--skayl-black)",
    fontWeight: 900,
    padding: 10,
    textAlign: "center",
    textTransform: "none",
    fontFamily: 'Source Sans Pro',
  }

  return (
    <div style={containerStyle}>
      <span className="k-icon k-i-close-circle" style={{fontSize:50, color: "var(--skayl-black)"}} />
      <h1 style={messageStyle}>The model node couldn't be found.</h1>
    </div>
  )
}







/**
 * DEPRECATED
 */
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px;
  min-height: ${({isComponent}) => isComponent ? null : "512px"};

  .edit-node-row {
    margin-bottom: 10px;
  }
`

const NodeLayout = (props) => {
  const { guid, name, editable, children, deprecated, isComponent, onSave, onReset, onDelete, topBtnRef, idCtx  } = props;

  return (
    <StyledContainer isComponent={isComponent}>
      <DeprecatedBanner deprecated={deprecated} />

      {children}

      {guid && !isComponent &&
        <Tags guid={guid}
              name={name}
              disabled={!editable}
              idCtx={(idCtx || "") + "-tags"} />}
    </StyledContainer>
  )
}


export const StyledGrid = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: ${({isComponent}) => isComponent? "1fr" : "1fr 23%"};
  grid-template-areas: ${({isComponent}) => isComponent?
      `"input"
        "content"`
  :
      `"input sidebar"
        "content content"`
  };
`

export const StyledInput = styled.div`
    grid-area: input;
`

export const StyledSidebar = styled.div`
    grid-area: sidebar;
    padding-left: 20px;
`

export const StyledContent = styled.div`
    grid-area: content;
    display: grid;
    gap: 20px;
    grid-template-columns: ${({columns}) => `repeat(${columns || 1}, 1fr)`};
`

export const StyledCardGroup = styled.div`
    display: grid;
    gap: 10px;
    grid-template-columns: ${({columns}) => `repeat(${columns || 1}, 1fr)`};
`



const msp = (state) => {
  return {
    canEdit: state.user.canEdit,
    model_uid: state.user.model_uid,
    expired: state.user.expired,
    subModels: state.app.subModels,
  }
}

// export const withNestedLayout = (WrappedComponent) => NestedLayout(WrappedComponent);
export const withPageLayout = (WrappedComponent, options) => connect(msp)( PageLayout(WrappedComponent, options) );
export default NodeLayout;
