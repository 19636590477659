import {runStatReport} from "../../requests/sml-requests";
import React from "react";
import Plotly from "plotly.js/lib/core";
import loadingIcon from "../../images/Palette Ring-1s-200px.gif";

export class AttributeUsageFrequency extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        Plotly.register([require("plotly.js/lib/bar")]);

        runStatReport({tests: "attribute_usage"}).then((res) => {
            const results = res.data;
            this.makeUsageChart(results, "attribute_usage", "attr_total", "Attributes", "attribute-usage-frequency");
            this.setState({loading: false});
        });
    }

    makeUsageChart(results, type, total_type, title, plotType) {
        let usage_data = [];
        let total = results["totals"][total_type];

        for (let guid in results[type]) {
            usage_data.push([guid, results[type][guid].usages, results[type][guid].name]);
        }

        usage_data.sort((a, b) => b[1] - a[1]);

        let data = {
            y: usage_data.map(x => x[1]),
            type: "bar",
            text: usage_data.map(x => x[2]),
            showlegend: false
        };

        let layout = {
            title: "Most Used " + title,
            xaxis: {
                showticklabels: false,
                title: usage_data.length + "/" + total + " used in semantic paths",
                fixedrange: true
            },
            yaxis: {showticklabels: false, type: "log", autorange: true, fixedrange: true}
        };

        Plotly.newPlot(plotType, [data], layout, {displayModeBar: false});

    }

    render() {
        return (
            <div className='phenom-content-scrollable subview-wrapper tree-height-w-buttons'>
                <img
                    style={{
                        display: this.state.loading ? "block" : "none",
                        width: 150,
                        left: "calc(50% - 150px)",
                        top: "calc(50% - 150px)",
                    }}
                    className="ring-o-loading"
                    src={loadingIcon}/>
                <div
                  id="attribute-usage-frequency"
                  className="flex-h" style={{justifyContent: "space-around"}}></div>
            </div>
        );
    }
}
