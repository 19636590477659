// PROPS: an axis object that contains the relevant data - axisObj (Object)
//        a pre-determined primitive selection - primitive (String)
//        an indicator of whether or not to display the primitive - showPrim (Boolean)
//        an indicator of whether or not to show system details - showSystems (Boolean)
//        an inidcator of whether  or not the axis can be edited - canEdit (Boolean)
//        a function to communicate change in axis properties - registerAxisChange (f)

import {DetailBox} from "./stateless";
import React from "react";
import {deGuidify} from "./util";
import PhenomId from "../../requests/phenom-id";


export class Axis extends React.Component {
    constructor(props) {
        super(props);
        const axis = this.props.axisObj;
        let vtunit = axis.valueTypeUnit || axis.measurementSystemAxis.defaultValueTypeUnit;
        vtunit = vtunit.split(" ")[0];

        this.state = {
            axis: axis,
            vtunit: vtunit,
            unit: {guid: null},
            valueType: {guid: null},
            precision: axis.precision,
            primitive: "unk",
//            boundPrimitiveValues: this.props.primitive.bound,
            canEdit: this.props.canEdit && this.props.primitive !== "Enumeration" && axis.guid !== "SML_F1157E3F_E0C5_465D_98F8_70BF2BA7644D" && !this.props.disabled,
            edited: false,
            defaultAxisName: "",
            units: {},
            valueTypes: {},
            showDetails: !this.props.showPrim, //FIXME: Moving forward with inline platform type editing, this will never be used - all primitive logic should be removed.
        };

        this.basicValueTypes = {};
//        this.boundedPrimitives = {
//          Fixed: {length: "digits", scale: "scale"},
//          BoundedString: {length: "maxLength"},
//          CharArray: {length: "length"},
//          IDLSequence: {length: "maxSize"},
//          IDLArray: {length: "size"}
//        };
        this.phenomId = new PhenomId("axis",this.props.idCtx);
    }

    componentDidMount() {
        window["cachedRequest"]("newVTUS").then(this.absorbVTUs);

//        if (this.props.registerPrimChange) {
//            this.props.registerPrimChange(this.state.primitive);
//        }
    }

    absorbVTUs = res => {
        const myVTU = res.value_type_units[this.state.vtunit];
        if (myVTU) {
            const unit = myVTU.unit;
            const valueType = myVTU.valueType;
            this.basicValueTypes = deGuidify(Object.values(res.value_types).filter(node => node.xmiType !== "logical:Enumerated"));
            this.setState({
                vtunit: myVTU,
                unit: unit,
                valueType: valueType,
                units: res.units,
                valueTypes: valueType.xmiType === "logical:Enumerated" ? {[myVTU.guid]: myVTU} : this.basicValueTypes,
                vtunits: res.value_type_units,
            }, () => {
                if (this.state.valueType.xmiType === "logical:Enumerated") {
                    this.setState({primitive: "Enumeration", canEdit: false});
                }
            });
        } else {
            this.state.vtunits = res.value_type_units;
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.axisObj.guid !== prevProps.axisObj.guid && this.props.axisObj.guid === "SML_F1157E3F_E0C5_465D_98F8_70BF2BA7644D") {
            this.setState({canEdit: false});
        } else if (this.props.primitive !== prevProps.primitive) {
            if (this.props.primitive === "Enumeration") {
                this.setState({canEdit: false});
            } else {
                this.setState({canEdit: true});
            }
        }

        if (this.props.axisObj.guid !== prevProps.axisObj.guid) {
            const axis = this.props.axisObj;
            let vtunit;

            if ((typeof axis.valueTypeUnit === "string") || (typeof axis.measurementSystemAxis.defaultValueTypeUnit === "string")) {
                if (!axis.valueTypeUnit) {
                    axis.valueTypeUnit = axis.measurementSystemAxis.defaultValueTypeUnit;
                }
                vtunit = axis.valueTypeUnit.guid ? axis.valueTypeUnit.guid : axis.valueTypeUnit.split(" ")[0];
                vtunit = this.state.vtunits[vtunit];
            } else {
                if (!!axis.valueTypeUnit[0]) {
                    vtunit = axis.valueTypeUnit[0];
                } else {
                    vtunit = axis.measurementSystemAxis.defaultValueTypeUnit[0];
                }
            }
            try {
                this.setState({
                    axis: axis,
                    vtunit: vtunit,
                    unit: vtunit.unit,
                    valueType: vtunit.valueType,
                    precision: axis.precision,
                    primitive: vtunit.valueType.xmiType === "logical:Enumerated" ? "Enumeration" : "unk",
//                    boundPrimitiveValues: this.props.primitive.bound,
                    edited: false,
                    defaultName: "",
                    canEdit: vtunit.valueType.xmiType !== "logical:Enumerated",
                    valueTypes: vtunit.valueType.xmiType === "logical:Enumerated" ? {[vtunit.guid]: vtunit} : this.basicValueTypes,
                }, this.props.measurementRef().detectAxisChange);
            } catch (e) {
                // debugger;
            }
        }

//        if (this.state.primitive !== prevState.primitive && this.props.registerPrimChange) {
//            this.props.registerPrimChange(this.state.primitive);
//        }

//        if (this.props.primitive !== "unk" && this.state.primitive === "unk") {
//            this.setState({primitive: this.props.primitive});
//        }
//
//        if (this.state.boundPrimitiveValues !== prevState.boundPrimitiveValues && this.props.registerPrimChange) {
//            this.props.registerBoundPrimValChange(this.state.boundPrimitiveValues);
//        }

    }


    editVtu(attr, guid) {
        this.setState({[attr]: this.state[attr + "s"][guid]}, this.determineEdit);
    }

    editAxis(attr, value) {
        this.setState({[attr]: value,
//                       boundPrimitiveValues: null
                      }, () => this.determineEdit());
    }

    determineEdit() {
        if (this.state.unit.guid !== this.state.vtunit.unit.guid ||
            this.state.valueType.guid !== this.state.vtunit.valueType.guid ||
            this.state.precision !== this.state.axis.precision) {
            this.setState({edited: true, defaultAxisName: this.generateDefaultAxisName()},
                this.props.measurementRef().detectAxisChange
            );
        } else {
            this.setState({edited: false},
                this.props.measurementRef().detectAxisChange
            );
        }
    }

    generateDefaultAxisName() {
        const msaName = this.state.axis.measurementSystemAxis.name.split("MeasurementSystemAxis")[0];
        const vtuName = this.state.valueType.name + this.state.unit.name;
        return `${msaName}_${vtuName}_MeasAxis`;
    }

//    renderBoundPrimitiveField() {
//      const phenomId = this.phenomId;
//      let bp = this.boundedPrimitives[this.state.primitive];
//        return (
//            Object.keys(bp).map((key,i) => {
//              return <td key={i} id={phenomId.gen(["render-bound-primitive-field",i],"wrapper")}>
//                <LineLabel text={//bp[key] || ""} style={{fontSize:'10px'}} id={phenomId.gen(i)}/>
//                <CadetInput text={//this.state.boundPrimitiveValues ? this.state.boundPrimitiveValues[this.boundedPrimitives[this.state.primitive][key]] : ""}
//                             id={key}
//                            id={//phenomId.gen(i)}
//                            onChange={//(key,e) => this.changeboundPrimitiveValues(key,e.target.value)}
//                            disabled={//!this.state.canEdit}/></td>})
//        );
//    }

//    changeboundPrimitiveValues = (key,value) =>{
//      this.setState({boundPrimitiveValues: {...this.state.boundPrimitiveValues, [this.boundedPrimitives[this.state.primitive][key]]: value}});
//    }

    resetChanges = () => {
        this.setState({
            unit: this.state.units[this.state.vtunit.unit.guid],
            valueType: this.state.valueTypes[this.state.vtunit.valueType.guid],
            precision: this.state.axis.precision,
//            primitive: this.props.primitive,
            edited: false,
        }, this.props.measurementRef().detectAxisChange);
    };

    reportState = () => {
        return this.state;
    };

    //sorts array
    getLiteralList() {
        let literalList = [];
        if (this.state.vtunit && this.state.vtunit.children && this.state.vtunit.children[0] && this.state.vtunit.children[0].allowedValue) {
            literalList = this.state.vtunit.children[0].allowedValue.map((literal) => literal.name);
        } else if (this.state.valueType && this.state.valueType.children && this.state.valueType.children.length) {
            literalList = this.state.valueType.children.map((child) => child.name);
        } else {
            literalList.push("No Allowed Literals");
        }
        //first sort by name (before regex for number at end of string)
        //then sort by trailing number (regex for number at end of string)
        const myRE = /\d+/;

        literalList.sort((a, b) =>
            a.split(myRE)[0].localeCompare(b.split(myRE)[0]) || parseInt(myRE.exec(a)) - parseInt(myRE.exec(b))
        );


        literalList = literalList.map((literal, idx) => <li key={idx}>{literal}</li>);

        return literalList;
    }


    render() {
        const phenomId = this.phenomId;
        let measurementSystem = {};
        let coordinateSystem = {};

        // sorting array of enums, done in render since state constantly resets in the component
        let literalList = this.getLiteralList();

        if (this.state.axis.measurementSystemAxis) {
            measurementSystem = this.state.axis.measurementSystemAxis;
            coordinateSystem = measurementSystem.axis || {};
        }

        return (
            <div className="flex-h" style={{justifyContent: "space-between"}} id={phenomId.gen("","wrapper")}>
                <div className="detail-box">
          <span>
            Measurement Axis
            <button
                className={`form-button ${this.state.showDetails ? "collapse-up" : "expand-down"}`}
                title={this.state.showDetails ? "Collapse Axis" : "Expand Axis"}
                onClick={() => this.setState({showDetails: !this.state.showDetails})}
                id={phenomId.gen("","expand-axis-toggle-button")}>
            </button>
          </span>
                    <input
                        className="cadet-text-input"
                        disabled={true}
                        type="text"
                        value={this.state.edited ? this.state.defaultAxisName : this.state.axis.name}
                        id={phenomId.gen("","axis-name-input")}
                    />
                    <textarea disabled={true} value={this.state.axis.description || "- No Description Available -"} id={phenomId.gen("","description-input")}/>
                    <table className="input-table">
                        <tbody>
                        {!this.state.showDetails || <tr id={phenomId.gen("logical-value-type","wrapper")}>
                            <td>Logical Value Type</td>
                            <td>
                                <select disabled={!this.state.canEdit} className="cadet-select"
                                        value={this.state.valueType.guid || ""}
                                        onChange={(e) => this.editVtu("valueType", e.target.value)}
                                        id={phenomId.gen("logical-value-type","select")}>
                                    {Object.values(this.state.valueTypes).sort((a, b) => a.name.localeCompare(b.name)).map((valueType, idx) => {
                                        return <option value={valueType.guid} key={idx} id={phenomId.gen(["logical-value-type",idx],"option")}>{valueType.name}</option>;
                                    })}
                                </select>
                            </td>
                        </tr>}
                        {(this.state.primitive !== "Enumeration" || !this.state.showDetails) ||
                        <tr>
                            <td>Literals</td>
                            <td>
                                <ul
                                  className="literal-list"
                                  id={phenomId.gen("init","literal-list")}>
                                    {literalList}
                                </ul>
                            </td>
                        </tr>}
                        {!this.state.showDetails || <tr id={phenomId.gen(["init","unit"],"wrapper")}>
                            <td>Unit</td>
                            <td>
                                <select disabled={!this.state.canEdit} className="cadet-select"
                                        value={this.state.unit.guid || ""}
                                        onChange={(e) => this.editVtu("unit", e.target.value)}
                                        id={phenomId.gen("unit","select")}>
                                    {Object.values(this.state.units).sort((a, b) => a.name.localeCompare(b.name)).map((unit, idx) => {
                                        return <option value={unit.guid} key={idx} id={phenomId.gen(["unit",idx],"option")}>{unit.name}</option>;
                                    })}
                                </select>
                            </td>
                        </tr>}
                        {!this.state.showDetails || <tr id={phenomId.gen(["init","precision"],"wrapper")}>
                            <td>Precision</td>
                            <td><input
                                className="cadet-text-input"
                                style={{marginBottom: (this.state.showDetails && !this.state.edited) ? 4 : 0}}
                                disabled={!this.state.canEdit}
                                type="number"
                                step="0.00000001"
                                value={this.state.edited ? this.state.precision : this.state.axis.precision}
                                onChange={(e) => this.editAxis("precision", e.target.value)}
                                id={phenomId.gen("precision","input")}
                            />
                            </td>
                        </tr>}
                        </tbody>
                    </table>
                    {(!this.state.showDetails || !this.state.showEdit) || <textarea disabled={true}
                                                                                    id={phenomId.gen("init","no-edit-text-area")}
                                                                                    value="NOTE: Editing Enumeration typed measurements is not supported at this time."
                                                                                    style={{
                                                                                        resize: "none",
                                                                                        margin: "5px 0px"
                                                                                    }}/>}
                    {!this.state.edited ? false :
                        <button
                            className="form-button"
                            disabled={!this.state.edited}
                            onClick={this.resetChanges}
                            id={phenomId.gen("init","reset-changes-button")}>
                            Reset Changes
                        </button>
                    }
                </div>
                <DetailBox show={this.props.showSystems} label="Measurement System Axis" name={measurementSystem.name}
                           description={measurementSystem.description} idCtx={phenomId.gen("init")}/>
                <DetailBox show={this.props.showSystems} label="Coordinate System Axis" name={coordinateSystem.name}
                           description={coordinateSystem.description} idCtx={phenomId.gen("init")}/>
            </div>
        );
    }
}
