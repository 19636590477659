import {AuthPass} from "./authPass";
import React from "react";
import qrcode from "qrcode-generator";
import {CadetInput} from "../util/stateless";
import {Notifications} from "../edit/notifications";
import PhenomId from "../../requests/phenom-id";

export class Init2FA extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: "password",
            authPw: ""
        };

        this.noteRef = undefined;
    }

    handlePageReset = () => {
        this.setState({page: "password"});
    };

    handleNewPage = newPageData => {
        if (this.state.page === "password") {
            this.setState({page: "authkey", authPw: newPageData["keyData"]});
            this.noteRef.error(newPageData["errors"]);
            let qr = qrcode(0, "Q");
            qr.addData("otpauth://totp/PHENOM?secret=" + this.state.authPw);
            qr.make();
            document.getElementById("qr-holder").innerHTML = qr.createImgTag(4);
        } else {
            this.setState({page: "success", authPw: ""});
        }
    };

    render() {
        const phenomId = new PhenomId("init-2fa",this.props.idCtx);
        return (
            <main className="subview-wrapper" id={phenomId.gen("","wrapper")}>
                <Notifications ref={ele => this.noteRef = ele}/>
                {this.state.page === "authkey" && <div>
                    <label htmlFor="auth-pw" id={phenomId.gen("","auth-pw-label")}>Authenticator password:</label>
                    <CadetInput type="text"
                      // id="auth-pw"
                      idCtx={phenomId.gen("","auth-pw")}
                      text={this.state.authPw} readOnly/>
                    <br/>
                    <label htmlFor="qr-holder" id={phenomId.gen("","auth-pw-input")}>Authenticator QR code:</label>
                    <div id="qr-holder"/>
                <p style={{paddingBottom: "1.25em"}}>Scan the QR code above or type in the password to generate a 2 factor authentication code, then enter that code in the box below and hit the "verify passcode" button.</p>
                </div>}
                {this.state.page !== "success" && <AuthPass
                    idCtx={phenomId.gen("")}
                    page={this.state.page}
                    onPageChange={this.handleNewPage}
                    mode={"init"}
                />}
                {this.state.page === "success" && <div>You have successfully set up two factor authentication.</div>}
            </main>
        );
    }
}
