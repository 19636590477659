import React from "react";
import styled from "@emotion/styled";
import {
    DefaultLinkFactory,
    DefaultLinkModel,
} from "@projectstorm/react-diagrams-defaults";
import { LinkWidget } from "@projectstorm/react-diagrams-core";

import { DiagramLinkWidget } from './DiagramLink';
import { cloneDeep } from "lodash";
import { createPathPortGuid } from "../util";
import { BasicAlert } from "../../../dialog/BasicAlert";



const Path = styled.path`
  fill: none;
  ${p => !p.selected ? `stroke-dasharray: ${p.strokeSize} ${p.strokeOffset}` : null};
  pointer-events: all;
`

export class PathLinkFactory extends DefaultLinkFactory {
	constructor(type = 'path-link') {
		super(type);
	}

	generateModel() {
		return new PathLinkModel();
	}

	generateReactWidget(event) {
    if(event.model.sourcePort) {
      let pathData = event.model.sourcePort.options.pathData || {};
      if(pathData.strokeData) event.model.setColor(pathData.strokeData.strokeColor || "gray");
    }

		return <PathLinkWidget link={event.model} diagramEngine={this.engine} />;
  }

  generateLinkSegment(model, selected, path) {
    let strokeData = {};
    if(model.sourcePort) {
      let pathData = model.sourcePort.options.pathData || {};
      if(pathData.strokeData) strokeData = pathData.strokeData;
    }

    return <Path selected={selected}
                 strokeSize={strokeData.strokeDashSize || 10}
                 strokeOffset={strokeData.strokeDashOffset || 2}
                 stroke={selected ? model.getOptions().selectedColor : model.getOptions().color}
                 strokeWidth={model.getOptions().width}
                 d={path} />
  }
}


export class PathLinkModel extends DefaultLinkModel {
	constructor() {
		super({
			type: 'path-link',
			width: 2
    });

    this.registerListener({
      eventDidFire: e => {
        if(e.function === "entityRemoved") {
          const {sourcePort, targetPort} = e.entity;
          if(!sourcePort) return;

          const srcData = sourcePort.getAttrData();
          const pathData = sourcePort.options.pathData;

          let portGuid = createPathPortGuid(srcData, pathData);

          sourcePort.parent.removeOutPort(portGuid);
          targetPort.parent.removeInPort(portGuid);
        }
      }
    })
  }

  forceLinkToUpdate = () => {
    if(this.$widget) this.$widget.forceUpdate();
  }
}



export class PathLinkWidget extends DiagramLinkWidget {
  constructor(props) {
      super(props);
  }

  // removes the port and connector
  removeLink = () => {
    const { sourcePort } = this.props.link;
    const attrData = sourcePort.getAttrData();
    const { pathData } = sourcePort.options;
    
    const portGuid = createPathPortGuid(attrData, pathData);
    sourcePort.parent.removePathLinks(portGuid);
    this.props.diagramEngine.repaintCanvas();
  }

	render() {
    const sourcePort = this.props.link.sourcePort;
    const targetPort = this.props.link.targetPort;
    if (!sourcePort) return null;

		//ensure id is present for all points on the path
		var points = this.props.link.getPoints();
		var paths = [];
    this.refPaths = [];

    let linkClassName = ["projection-link"];
    const srcData = sourcePort.getAttrData();
    const pathData = sourcePort.options.pathData;
    
    if(pathData && pathData.viewNodeData) {
      const selectedViewNode = this.$app.state.pathBuilder.viewData.nodeData;
      const selectionMode = this.$app.state.modeAction === "select-a-projChar"
      var myViewNodeGuid = pathData.viewNodeData.guid;
      var isSelectable = selectionMode && selectedViewNode && myViewNodeGuid === selectedViewNode.guid && srcData.xmiType !== "platform:CharacteristicProjection";
      
      if (pathData.viewChild) {
        var domId = `${pathData.viewChild.guid}-${srcData.guid}-pos${pathData.pathPos}`;
      }    
    }

		//draw the multiple anchors and complex line instead
		for (let j = 0; j < points.length - 1; j++) {
			paths.push( this.generateLink(
					LinkWidget.generateLinePath(points[j], points[j + 1]),
					{
						'data-linkid': this.props.link.getID(),
            'data-point': j,
            'data-for': myViewNodeGuid || "",
            'style': isSelectable ? {strokeWidth:15, strokeOpacity:0.1} : null,
            onMouseDown: (e) => {
              // Left Click
              if(e.button === 0){
                if(isSelectable) {
                  e.stopPropagation();

                  if(pathData && (pathData.pathPos || pathData.pathPos === 0)) {
                    let pathPairs = cloneDeep(pathData.viewChild.pathPairs);
                        pathPairs.length = pathData.pathPos + 1;
                    this.$app.buildExistingPath(pathData.viewChild, pathPairs);
                  }
                // Adds a Point
                } else if(e.ctrlKey) {
                  this.mouseEvent = e;
                  this.createPointAtIdx = j + 1;
                  window.addEventListener("mousemove", this.moveMouseToAddPoint);
                  window.addEventListener("mouseup", this.cleanUpAfterMouseMove);
                  // this.addPointToLink(e, j + 1);
                }
              }
            },
            onClick: (e) => {
              // removes the PathLinks
              if(e.ctrlKey) {
                const { modeAction } = this.$app.state;

                if(modeAction === "select-a-projChar" || modeAction === "select-a-hop") {
                  BasicAlert.show("You are trying to remove the connectors. Please finish building the path and try again later.", "Path Builder", true);
                } else {
                  e.stopPropagation();
                  this.removeLink();
                }
              }
            },
					},
					j
      ));
    }


    // render Arrow or Point
    if(this.linkModel.isSelected() && targetPort) {
      linkClassName.push("link-selected");
      paths.push(this.generateHandle(points[points.length - 1], targetPort));
    } else if (targetPort !== null) {
      paths.push(this.generateArrow(points[points.length - 1], points[points.length - 2], "thinArrow"));
    }
        
    // render the circles
    for (let i = 1; i < points.length - 1; i++) {
        paths.push(this.generatePoint(points[i]));
    }

    return <g className={linkClassName.join(" ")}
              id={domId && `${this.$app.getPhenomDomId()}-${domId}-pathlink-container`}
              data-default-link-test={this.props.link.getOptions().testName} 
              ref={el => this.linkRef = el}>
                {paths}
           </g>
	}
}
