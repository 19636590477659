import React, {Component, Fragment} from "react";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import Helmet from "react-helmet";

import ModelGen from "../components/generate/model_gen";
import CincGen from "../components/generate/cinc_gen";
import Templates from "../components/generate/templates";
import {ViewMediation} from "../components/generate/view_mediation";
import * as actionThunks from "../requests/actionThunks"
import { routeUrlBuilder } from "../routes";


class Generate extends Component {
    state = {}

    componentDidMount() {
      actionThunks.getActiveChangeSets();
    }

    render() {
        const { expired, modeler_only } = this.props;

        return (<Fragment>
            <Helmet>
                <title>Generate | {this.props.userIdentity.branchName} Project</title>
            </Helmet>

            <section id="phenom-content" aria-label='content'>
                <nav className="sub-menu-pages" aria-label='sub menu'>
                    <NavLink to="/generate/model_gen" activeClassName="active">DATA MODEL</NavLink>
                    <NavLink to="/generate/cinc_gen" activeClassName="active">CINC</NavLink>
                    <NavLink to="/generate/templates"
                              className={expired || modeler_only ? "disabled" : null}
                              tabIndex={expired || modeler_only ? -1 : null}
                              activeClassName="active">TEMPLATES</NavLink>
                </nav>
                <Switch>
                  <Route path={routeUrlBuilder("generate", "model_gen")} 
                         component={ModelGen}/>
                  <Route path={routeUrlBuilder("generate", "cinc_gen")} 
                         component={CincGen}/>
                  <Route path={routeUrlBuilder("generate", "templates")} 
                         component={Templates}/>
                  <Route path={routeUrlBuilder("generate", "view_mediation")} 
                         component={ViewMediation} />
                  <Redirect to="/generate/model_gen" />
                </Switch>
            </section>
        </Fragment>);
    }
}


const msp = (state) => ({
  userIdentity: state.user.userIdentity,
  expired: state.user.expired,
  modeler_only: state.user.modeler_only,
  userRole: state.user.userRole,
})

export default connect(msp)(Generate);
