import React from 'react';
import { isStormData } from '../../../util';
import { PhenomComboBox, PhenomInput, PhenomSelect } from '../../../../../util/stateless';
import { BaseNodeModel } from '../../../base/BaseNode';


class SidePortDetail extends React.Component {

  updateDataType = (view) => {
    const { parentNodeModel, attrData, port, fetchPreviewCode } = this.props;

    // if port exist then trigger event listeners
    if (port) {
      port.setDataType(view);
    } else {
      attrData.setAttr("dataType", view?.guid || "");
    }

    // fetch preview code for Manual Transforms
    if (parentNodeModel.getXmiType() === "im:TransformNode") {
      fetchPreviewCode && fetchPreviewCode();
    }

    parentNodeModel.fireEvent({}, 'nodeDataChanged');
  }

  updateTemplateType = (text) => {
    const { parentNodeModel, attrData, port } = this.props;

    // if port exist then trigger event listeners
    if (port) {
      port.setTemplateType(text);
    } else {
      attrData.setAttr("templateType", text || "");
    }

    parentNodeModel.fireEvent({}, 'nodeDataChanged');
  }

  updatePortType = (e) => {
    const { parentNodeModel, attrData, port } = this.props;

    const currDataType = attrData.getAttr("dataType") || "";
    const currTemplateType = attrData.getAttr("templateType") || "";

    if (!port) {
      attrData.setAttr("type", e.target.value);
      return parentNodeModel.fireEvent({}, 'nodeDataChanged');
    }

    // If Port exist then trigger event listeners
    if (e.target.value === "Templated") {
      this.updateTemplateType(currTemplateType);
    } else {
      // updateDataType is expecting a View. it was used to populate the StormLabel with the View's name but that was removed.
      // now it only uses the guid.
      this.updateDataType({ guid: currDataType });
    }

    parentNodeModel.fireEvent({}, 'nodeDataChanged');
  }

  renderDataTypeCell = () => {
    const { attrData, port, viewList, phenomId, idx } = this.props;
    // const selectedView = viewList.find(v => v.guid === port.getDataType()) || {};

    // true for input / false for output
    const isInput = ["im:InPort", "im:ComposedInPort", "im:ComposedInputEndpoint", "im:UoPInputEndPoint"].includes(attrData.getXmiType());
    const label = isInput ? "Data Type (In)" : "Data Type (Out)";
    let selectedView = viewList.find(v => v.guid === attrData.getAttr("dataType")) || {};

    return <div>
      <label>{ label }</label>
      <PhenomComboBox value={selectedView}
                      data={viewList}
                      dataItemKey="guid"
                      id={isInput ? phenomId.genPageId("dataType-input-" + idx) : phenomId.genPageId("dataType-output-" + idx)}
                      autoComplete="off"
                      onChange={this.updateDataType}
                      onClickCancelIcon={() => this.updateDataType()} />
    </div>
  }

  renderTemplateTypeCell = () => {
    const { attrData, phenomId, idx } = this.props;

    // true for input / false for output
    const isInput = ["im:InPort", "im:ComposedInPort", "im:ComposedInputEndpoint", "im:UoPInputEndPoint"].includes(attrData.getXmiType());
    const label = isInput ? "Template Type (In)" : "Template Type (Out)";

    return <div>
      <label>{ label }</label>
      <PhenomInput value={attrData.getAttr("templateType") || ""} 
                   id={isInput ? phenomId.genPageId("templateType-input-" + idx) : phenomId.genPageId("templateType-output-" + idx)}
                   onChange={(e) => this.updateTemplateType(e.target.value)} />
    </div>
  }

  renderComposedDataTypeCell = () => {
    const { $app, attrData, viewList, templateTypeMap } = this.props;

    let label;
    if (["im:InPort", "im:ComposedInPort", "im:ComposedInputEndpoint", "im:UoPInputEndPoint"].includes(attrData.getXmiType())) {
      label = "Data Type (In)";
    } else {
      label = "Data Type (Out)";
    }

    const type = attrData.getAttr("realized_type");
    const realized_templateType = attrData.getAttr("realized_templateType");

    if (type === "Templated") {
      const realized_view = viewList.find(v => v.guid === attrData.getAttr("dataType")) || {};
      const sibling_dataTypes = templateTypeMap[realized_templateType];
      
      let dataType_warning;
      if (sibling_dataTypes instanceof Set && sibling_dataTypes.size > 1) {
        dataType_warning = "Sibling Templated Ports have mismatched Data Types";
      }

      return <div>
        <label>{ label }</label>
        <div>{ `${realized_view?.name || ""} as ${realized_templateType}` }</div>
        { dataType_warning &&
          <div style={{ fontSize: 12, color: "red" }}>{ dataType_warning }</div> }
      </div>
    }

    const view = viewList.find(v => v.guid === attrData.getAttr("dataType")) || {};
    return <div>
        <label>{ label }</label>
        <div>{ `${view?.name || ""}` }</div>
      </div>
  }

  renderDataTypeSelectionCell = () => {
    const { parentNodeModel, attrData } = this.props;
    const type = attrData.getAttr("type");

    if (parentNodeModel.getXmiType() === "im:ComposedBlockInstance") {
      return this.renderComposedDataTypeCell();

    } else if (type === "Templated") {
      return this.renderTemplateTypeCell();

    } else {
      return this.renderDataTypeCell();
    }
  }

  renderRoleNameCell = () => {
    const { parentNodeModel, attrData, phenomId, idx } = this.props;
    const isInput = ["im:InPort", "im:ComposedInPort", "im:ComposedInputEndpoint", "im:UoPInputEndPoint"].includes(attrData.getXmiType());
    const isInstanceEndpoint = parentNodeModel.getXmiType() === "im:ComposedBlockInstance";

    return <div style={{ paddingLeft: 50 }}>
        <label>Rolename</label>
        <PhenomInput value={attrData.getAttr("rolename")}
                    id={isInput ? phenomId.genPageId("rolename-input-" + idx) : phenomId.genPageId("rolename-output-" + idx)}
                    disabled={isInstanceEndpoint}
                    onChange={(e) => {
                        attrData.setAttr("rolename", e.target.value);
                        parentNodeModel.fireEvent({}, 'nodeDataChanged');
                    }} />
    </div>
  }

  renderFlowTriggerCell = () => {
    const { parentNodeModel, attrData, phenomId, idx } = this.props;
    const isInput = ["im:InPort", "im:ComposedInPort", "im:ComposedInputEndpoint", "im:UoPInputEndPoint"].includes(attrData.getXmiType());
    const isInstanceEndpoint = parentNodeModel.getXmiType() === "im:ComposedBlockInstance";

    return <div style={{ paddingLeft: 50 }}>
        <label>Flow Trigger</label>
        <PhenomSelect value={attrData.getAttr("flowTrigger") || "NONE"}
                      data={["NONE", "PUSH", "PULL"]}
                      id={isInput ? phenomId.genPageId("flowTrigger-input-" + idx) : phenomId.genPageId("flowTrigger-output-" + idx)}
                      autoComplete="off"
                      disabled={isInstanceEndpoint}
                      onChange={(e) => {
                        attrData.setAttr("flowTrigger", e.target.value);
                        parentNodeModel.fireEvent({}, 'nodeDataChanged');
                      }} />
      </div>
  }

  renderTemplateCell = () => {
    const { parentNodeModel, attrData, phenomId, idx } = this.props;
    const isInput = ["im:InPort", "im:ComposedInPort", "im:ComposedInputEndpoint", "im:UoPInputEndPoint"].includes(attrData.getXmiType());
    const type = attrData.getAttr("type");
    const isInstanceEndpoint = parentNodeModel.getXmiType() === "im:ComposedBlockInstance";

    return <div style={{ paddingLeft: 50 }}>
        <label>Type</label>
        <PhenomSelect value={type || "Default"}
                      data={["Default", "Templated"]}
                      id={isInput ? phenomId.genPageId("type-input-" + idx) : phenomId.genPageId("type-output-" + idx)}
                      autoComplete="off"
                      disabled={isInstanceEndpoint}
                      onChange={this.updatePortType} />
      </div>
  }

  render() {
    const { $app, parentNodeModel, attrData, port, viewList, phenomId } = this.props;
 
    if (parentNodeModel instanceof BaseNodeModel === false || !isStormData(attrData)) {
      return null;
    }

    return <div className="storm-side-port-detail">
      { this.renderDataTypeSelectionCell() }
      { this.renderRoleNameCell() }
      { this.renderFlowTriggerCell() }
      { this.renderTemplateCell() }
    </div>
  }
}

export default SidePortDetail;