import React from 'react'
import $ from 'jquery'
import { Button, ToolbarItem } from '@progress/kendo-react-buttons';
import { PhenomLabel } from '../../util/stateless';
import { GridToolbar } from '@progress/kendo-react-grid';
import { SkaylSubMenu } from './skayl_manager';
import { receiveErrors, receiveLogs, receiveResponse, receiveWarnings } from '../../../requests/actionCreators';
import { SkaylCincVersions } from '../util';
import { BasicAlert } from '../../dialog/BasicAlert';
import { _ajax } from '../../../requests/sml-requests';


class SkaylAdmin extends React.Component {
  verRef = React.createRef()
  state = {
    cinc_versions: {},
  }

  componentDidMount() {
    this.fetchCincLicenses();
  }

  fetchCincLicenses = () => {
    return _ajax({
      url: "/index.php?r=/skayl/cinc-versions",
      method: "post",
    }).then(response => {
      if ("error" in response) return receiveErrors(response["error"]);

      const versions = {};
      Array.isArray(response.data.versions) && response.data.versions.forEach(ver => versions[ver.id] = ver);
      
      this.setState({ cinc_versions: versions });
    })
  }

  handleSaveVersions = () => {
    const versions = this.verRef.current.generateRequest();

    if (!versions.length) {
      return receiveWarnings("No changes detected.");
    }

    return $.ajax({
      method: "post",
      url: "/index.php?r=/skayl/update-cinc-versions",
      data: {
        cincVersions: versions,
      }
    }).then((res) => {
      const response = JSON.parse(res);

      if (response.status === "success") {
        receiveLogs("Successfully updated CinC Versions.");
        BasicAlert.show("Refreshing data", "One moment...", false);

        // reset state
        return this.fetchCincLicenses().always(() => BasicAlert.hide());
      }

      receiveResponse(response);
    })
  }

  addNewCincVersion = () => {
    this.verRef.current.addNewVersion();
  }

  handleSendReleaseNotes = () => {
    return _ajax({
      method: "post",
      url: "/index.php?r=/skayl/get-release-notes-emails",
    }).then((response) => {
      receiveResponse(response);
      
      window.location.href = "mailto:?bcc=" + response.data?.emails;
    })
  }

  render() {
    const { cinc_versions } = this.state;

    return <div className='fill-vertical'>
      <SkaylSubMenu>
        <button className="fas fa-save"
                title="Save"
                onClick={this.handleSaveVersions}
                 />
      </SkaylSubMenu>

      <div className='phenom-content-scrollable'>
        <div className="edit-form-container">
          <div>
            <Button icon='email'
                    onClick={this.handleSendReleaseNotes}>Send Release Notes</Button>
          </div>

          <div>
            <PhenomLabel text="CinC Versions" />
            <SkaylCincVersions versions={cinc_versions}
                               ref={this.verRef} />
            <GridToolbar>
              <ToolbarItem>
                <Button icon='add'
                        onClick={this.addNewCincVersion}>Add new Version</Button>
              </ToolbarItem>
            </GridToolbar>
          </div>

        </div>
      </div>
    </div>
  }
}


export default SkaylAdmin;