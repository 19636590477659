import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'



function handleOutsideClick(e) {
  const createMenu = document.querySelector('#tree-create-dropdown');
  if (!createMenu) return;

  const clickedOutside = !createMenu.contains(e.target);
  if (clickedOutside) {
    createMenu.classList.remove("visible");
    window.removeEventListener("click", handleOutsideClick);
  }
}

function toggleMenu(e) {
  const createMenu = document.querySelector('#tree-create-dropdown');
  if (!createMenu) return;

  if (createMenu.classList.contains("visible")) {
    createMenu.classList.remove("visible");
    window.removeEventListener("click", handleOutsideClick);
  } else {
    createMenu.classList.add("visible");
    setTimeout(() => window.addEventListener("click", handleOutsideClick), 0);
  }
}

// click commands will bubble up
function handleClick(e) {
  const createMenu = document.querySelector('#tree-create-dropdown');
  if (!createMenu) return;

  if (e.target?.tagName === 'A') {
    createMenu.classList.remove("visible");
    window.removeEventListener("click", handleOutsideClick);
  }
}

export const CreateOptions = (props) => {
  const expertMode = useSelector((state) => state.user.expertMode);
  const isPublished = useSelector((state) => state.user.isProjectPublished);

  if (isPublished) {
    return null;
  }
  
  return <div id="tree-create-menu">
          <button onClick={toggleMenu}>
            <i className="fas fa-plus" />
            <span>Create</span>
            <i className="fas fa-caret-down" />
          </button>

          <ul id="tree-create-dropdown" className="tree-create-options" onClick={handleClick}>
            <li><Link id="create-new-package" to="/edit/details/package/new">PACKAGE</Link></li>
            <li>
              <span id="create-new-conceptual" className="tree-create-options-dropdown">CONCEPTUAL &nbsp; &nbsp;<i className="fa fa-caret-right"></i></span>
              <ul>
                <li>
                  <Link id="create-new-entity" to="/edit/details/entity/new">ENTITY</Link>
                </li>
                <li>
                  <Link id="create-new-basisentity" to="/edit/details/basis_entity/new">BASIS ENTITY</Link>
                </li>
                <li>
                  <Link id="create-new-domain" to="/edit/details/domain/new">DOMAIN</Link>
                </li>
                {expertMode && <li>
                  <Link id="create-new-observable" to="/edit/details/observable/new">OBSERVABLE</Link>
                </li>}
              </ul>
            </li>
            <li>
              <span id="create-new-logical" className="tree-create-options-dropdown">LOGICAL<i className="fa fa-caret-right"></i></span>
              <ul>
                <li>
                  <Link id="create-new-measurement" to="/edit/details/measurement/new">MEASUREMENT</Link>
                </li>
                <li>
                  <Link id="create-new-measurement-system" to="/edit/details/measurement_system/new">MEASUREMENT SYSTEM</Link>
                </li>
                <li>
                  <Link id="create-new-measurement-system-axis" to="/edit/details/measurement_system_axis/new">MEASUREMENT SYSTEM AXIS</Link>
                </li>
                <li>
                  <Link id="create-new-coordinate-system" to="/edit/details/coordinate_system/new">COORDINATE SYSTEM</Link>
                </li>
                <li>
                  <Link id="create-new-coordinate-system-axis" to="/edit/details/coordinate_system_axis/new">COORDINATE SYSTEM AXIS</Link>
                </li>
                <li>
                  <Link id="create-new-conversion" to="/edit/details/conversion/new">CONVERSION</Link>
                </li>
                <li>
                  <Link id="create-new-constraint" to="/edit/details/value_type_unit/new">VALUE TYPE UNIT</Link>
                </li>
                {expertMode && <li>
                  <Link id="create-new-unit" to="/edit/details/unit/new">UNIT</Link>
                </li>}
              </ul>
            </li>
            <li>
              <span className="tree-create-options-dropdown">PLATFORM<i className="fa fa-caret-right"></i></span>
              <ul>
                <li>
                  <Link id="create-new-view" to="/edit/details/view/new">VIEW</Link>
                </li>
                <li>
                  <Link id="create-new-platform-type" to="/edit/details/platform_type/new">PLATFORM TYPE</Link>
                </li>
                <li>
                  <Link id="create-new-constraint" to="/edit/details/constraint/new">CONSTRAINT</Link>
                </li>
              </ul>
            </li>
            <li>
              <span className="tree-create-options-dropdown">INTEGRATION<i className="fa fa-caret-right"></i></span>
              <ul>
                <li>
                  <Link id="create-new-context" to="/integration/idm">CONTEXT</Link>
                </li>
                <li>
                  <Link id="create-new-portable-component" to="/integration/details/portable_component/new">UOP</Link>
                </li>
                <li>
                  <Link id="create-new-transport-channel" to="/integration/details/transport_channel/new">TRANSPORT CHANNEL</Link>
                </li>
                <li>
                  <Link id="create-new-uop-instance" to="/integration/details/uop_instance/new">UOP INSTANCE</Link>
                </li>
              </ul>
            </li>
            <li>
              <span className="tree-create-options-dropdown">DEPLOYMENT<i className="fa fa-caret-right"></i></span>
              <ul>
                <li>
                  <Link id="create-new-main-program" to="/integration/details/main_program/new">MAIN PROGRAM</Link>
                </li>
                <li>
                  <Link id="create-new-processing-element" to="/integration/details/processing_element/new">PROCESSING ELEMENT</Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
}