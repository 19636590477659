import React from 'react';
import styled from "@emotion/styled";
import { endsWith } from 'lodash';


export const spProps = {
    tabHeight: 35,
    toolHeight: 36,
    stencilWidth: 110
}

export const nodeProps = {
    fontScalar: 12,
    defaultWidth: 300,
    entWidth: 200,
    viewWidth: 320,
    minWidth: 200,
    minHeight: 18,
    widthHexagon: 5,
    bgColor: "whitesmoke",
    uncommittedColor: "var(--skayl-orange)",
    deleteColor: "#fce0e5",
}

export const attrProps = {
    widthCheckbox: 23,
    widthOutPort: 6,
    widthOutPortCell: 10,
    heightOutPort: 12,
}

export const managerColors = {
    modelling: "#8aa3b2",
    projection: "var(--skayl-mint)",
}

// TODO: Change AE AND GENSPEC COLOR NAME
export const nodeColors = {
    "default": "var(--color-sp-row)",
    "conceptual:Entity": "var(--skayl-violet)",
    "conceptual:Association": "var(--skayl-purple)",
    "conceptual:Observable": "var(--skayl-teal)",
    "conceptual:Composition": "var(--color-sp-row)",
    "conceptual:AssociatedEntity": "var(--color-ae)",
    "conceptual:GenSpec": "var(--color-specializes)",
    "platform:View": "var(--skayl-grey)",
    "uop:PortableComponent": "var(--skayl-blue)",
}


export const NodeContainer = styled.div`
    position: relative;
    width: ${nodeProps.width}px;
    min-width: ${nodeProps.minWidth}px;
    // max-width: ${nodeProps.maxWidth}px;
    min-height: ${nodeProps.minHeight}px;
    background-color: ${(p) => nodeColors["default"]};

    li:hover {
        margin: 0 !important;
    }
`

export const NodeHalo = styled.div`
    display: ${({selected}) => selected ? "block" : "none"};
    position: absolute;
    top: -14px;
    right: -14px;
    bottom: -14px;
    left: -14px;
    border: 1px solid skyblue;
    border-radius: 3px;
    z-index:-1;
`;

export const NodeOuterPorts = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index:-1;
`

export const NodeHeader = styled.div`
    font-size: 16px;
    min-height: ${nodeProps.minHeight}px;
    text-align: center;
    color: ${(props) => props.uncommitted ? nodeProps["uncommittedColor"] : "#f2f2f2"};
    background: ${(p) => p.bgColor ? p.bgColor : nodeColors[p.xmiType] || "black"};
`


export const AttributeTable = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 12px;
    border-width: 2px;
    border-style: solid;
    border-color: ${(p) => p.color ? p.color : nodeColors[p.xmiType]};
    border-top: ${(props) => props.uncommitted ? `2px solid ${nodeProps["uncommittedColor"]}` : null};
    min-height: 18px;
`

export const AttributeRow = styled.div`
    display: flex;
    flex-direction: ${p => p.reverse ? "row-reverse" : null};
    position: relative;
    background: ${({node, isNestedChar}) => isNestedChar ? "#e6e6e6" :
                              node.xmiType === "conceptual:AssociatedEntity" ? nodeColors["conceptual:AssociatedEntity"] :
                              node.specializes ? nodeColors["conceptual:GenSpec"] :
                              nodeColors[node.xmiType] || nodeColors["default"]};
`

export const AttributeRowWrapper = styled.div`
    display: ${({hide}) => hide ? "none" : "flex"};
    width: 100%;

    & div:last-child {
        margin-right: 0;
    }
`

export const AttributeHeaderRow = styled.div`
    display: ${({hide}) => hide ? "none" : "flex"};
    flex-direction: ${p => p.reverse ? "row-reverse" : null};
    border-bottom: 1px solid gray;
    align-items: center;
    background: ${({highlight}) => highlight ? "#ffdea0" : nodeColors["default"]};

    & > div {
      border-right: 1px solid gray;
    }

    & > div:${p => p.reverse ? "first-of-type" : "last-of-type"} {
      border-right: none;
      margin-right: 0;
    }
`

export const AttributeCell = styled.div`
    display: ${(p) => p.hide ? "none" : "flex"};
    position: relative;
    flex: 1;
    align-items: center;
    flex-wrap: nowrap;
    // margin-right: 7px;
    color: ${(p) => p.isUncommitted ? nodeProps["uncommittedColor"] : 
                    p.hide ? "gray" : null};
    // border-right: ${(p) => p.header ? "1px solid gray" : null};
    padding: ${(p) => p.header ? "0 5px" : null};
    box-sizing: border-box;
    overflow: hidden;
`

export const AttributeCellFixedSize = styled.div`
    display: ${(p) => p.hide ? "none" : "flex"};
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    border-right: ${(p) => p.header ? "1px solid gray" : null};
    width: ${(p) => p.width ? p.width :
                    p.checkbox ? "26" :
                    p.char ? "38" : "50"}px;
`

export const NodeInput = styled.input`
    background-color: transparent;
    outline: none;
    border: none;
    padding: 0;
    width:100%;
`

export const TopToolBar = styled.div`
    display: flex;
    visibility: ${({visible}) => visible ? "visible" : "hidden"};
    position: absolute;
    top: -40px;
    right: 0;

    .port {
        margin-left: 10px;
    }

    .k-icon {
        color: #717171;
        font-size: 24px;
        background: #f6f6f6;
        border-radius: 50px;
    }
`

export const BottomToolBar = styled.div`
    display: ${(p) => p.show ? "block" : "none"};
    position: absolute;
    bottom: -25px;
    right: 0;
    z-index: -1;

    span {
        background-color: #c2c3c4;
        margin-right: 5px;
        color: white;
        font-weight: bold;
        font-size: 80%;
        padding: 3px;
        text-transform: uppercase;
        cursor: pointer;
    }
`

export const AssociationError = styled.span`
    visibility: ${(props) => props.visible ? "visible" : "hidden"};
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
    position: absolute;
    color: red;
    top: 0;
    right: 0;
    text-shadow: 1px 1px #000;

    &:before {
        content: "\f12a";
    }
`

export const UndoDelete = styled.span`
    font-family: "WebComponentsIcons";
    padding: 1px;
    cursor: auto;

    &:before {
        content: "\\e100";
    }

    &:hover {
        color: blue;
    }
`

// Kendo React Icons
export const StyledIcon = styled.span`
    font-family: "WebComponentsIcons";
    font-size: 16px;
    cursor: pointer;
    &:hover {
        color: #8aa3b2;
    }

    &[disabled] {
        pointer-events: none;
        color: #c4c4c4;
    }

    &:before {
        content: ${(p) => p.eye ? '"\\e13d"' :
                              p.undo ? '"\\e100"' :
                              p.cancel ? '"\\e115"' :
                              p.trash ? '"\\e10c"' :
                              p.plus ? '"\\e11e"' :
                              p.plusCircle ? '"\\e120"' :
                              p.edit ? '"\\e10b"' :
                              p.info ? '"\\e401"' :
                              p.caretup ? '"\\e004"' :
                              p.caretdown ? '"\\e006"' :
                              p.cog ? '"\\e13a"' : null};
    }
`

// Font Awesome Icons
export const StyledFAIcon = styled.span`
    font-family: 'Font Awesome 6 Pro';
    font-weight: 600;
    cursor: pointer;

    &:hover {
        color: #8aa3b2;
    }

    &[disabled] {
        pointer-events: none;
        color: #c4c4c4;
    }

    &:before {
        content: ${(p) => p.eyeSlash ? '"\\f070"' :
                        p.eye ? '"\\f06e"' :
                        p.plusCircle ? '"\\f055"' :
                        p.edit ? '"\\f303"' : null};
    }
`

export const Hexagon = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: -${nodeProps["widthHexagon"]}px;
    left: -${nodeProps["widthHexagon"]}px;
    z-index: -1;
`

const HexagonSide = styled.div`
    position: absolute;
    width: 0; 
    height: 0; 
    border-top: ${p => `${p.height / 2}px`} solid transparent;
    border-bottom: ${p => `${p.height / 2}px`} solid transparent;
`

export const HexagonRight = styled(HexagonSide)`
    top: 0;
    right: 0;
    border-left: ${p => `${nodeProps["widthHexagon"]}px solid ${nodeColors["conceptual:Association"]}`};
`
export const HexagonLeft = styled(HexagonSide)`
    top: 0;
    left: 0;
    border-right: ${p => `${nodeProps["widthHexagon"]}px solid ${nodeColors["conceptual:Association"]}`};
`



// const portPlaceSize = 16;
// const portPlacement = `-${portPlaceSize / 2}px`;
// const portPlacePercent = (placement) => `calc(${placement} - ${portPlaceSize / 2}px)`;
// export const PortPlacement = styled.div`
//     display: none;
//     flex-wrap: wrap;
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     font-size: 12px;
//     justify-content: space-between;
//     background: rgba(0,0,0,0.3);
//     z-index: 1;

//     .anchor-placement {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         position: absolute;
//         width: ${portPlaceSize}px;
//         height: ${portPlaceSize}px;
//         border-radius: 30px;
//         background: var(--skayl-yellow);
//         border: 1px solid black;
//     }

//     .top-left { 
//         top:${portPlacement}; 
//         left:${portPlacement}; 
//     }

//     .top { 
//         top:${portPlacement}; 
//         left: ${portPlacePercent("50%")}; 
//     }

//     .top-right { 
//         top:${portPlacement}; 
//         right:${portPlacement}; 
//     }

//     .left { 
//         top:${portPlacePercent("50%")}; 
//         left:${portPlacement}; 
//     }

//     .right { 
//         top:${portPlacePercent("50%")}; 
//         right:${portPlacement}; 
//     }

//     .bottom-left { 
//         bottom:${portPlacement}; 
//         left: ${portPlacement}; 
//     }

//     .bottom { 
//         bottom:${portPlacement}; 
//         left:${portPlacePercent("50%")}; 
//     }

//     .bottom-right { 
//         bottom:${portPlacement}; 
//         right:${portPlacement}; 
//     }

//     .top-0 { top:${portPlacement}; left: ${portPlacePercent("0%")}; }
//     .top-1 { top:${portPlacement}; left: ${portPlacePercent("10%")}; }
//     .top-2 { top:${portPlacement}; left: ${portPlacePercent("20%")}; }
//     .top-3 { top:${portPlacement}; left: ${portPlacePercent("30%")}; }
//     .top-4 { top:${portPlacement}; left: ${portPlacePercent("40%")}; }
//     .top-5 { top:${portPlacement}; left: ${portPlacePercent("50%")}; }
//     .top-6 { top:${portPlacement}; left: ${portPlacePercent("60%")}; }
//     .top-7 { top:${portPlacement}; left: ${portPlacePercent("70%")}; }
//     .top-8 { top:${portPlacement}; left: ${portPlacePercent("80%")}; }
//     .top-9 { top:${portPlacement}; left: ${portPlacePercent("90%")}; }
//     .top-10 { top:${portPlacement}; left: ${portPlacePercent("100%")}; }
// `




export const SelectionOverlay = styled.div`
    display: ${(p) => p.show ? "flex" : "none"};
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    color: #f2f2f2;
    font-size: 30px;
    border-radius: 3px;
    background-color: ${(p) => p.selectable ? "var(--item-select)" : "var(--item-prevent)"};
    opacity: 0.5;
    cursor: ${(p) => p.selectable ? "pointer" : null};
    z-index: 1;
`


export const SelectionOverlayRow = styled.div`
    display: ${(p) => p.show ? "block" : "none"};
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background: var(--item-select);
    opacity: 0.25;
    cursor: pointer;
    z-index: 2;

    &:hover + div {
        font-weight: 600;
    }
`



 