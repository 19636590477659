import React from "react";
import { CadetInput, NodeComboBox } from "../util/stateless";
import DeletionConfirm2 from "../dialog/DeletionConfirm2";
import {Button} from "@progress/kendo-react-buttons";
import PhenomId from "../../requests/phenom-id";


export class MeasurementAttribute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            guid: "",
            parent: "",
            rolename: "",
            type: null
        };

        this.originalRes = "";
    }

    componentDidMount() {
        this.originalRes = JSON.stringify(this.props.element);
        if (this.props.element) {
            this.setStateFromResponse(this.props.element);
        }
    }

    componentDidUpdate(previousProps) {
        if (previousProps.element !== this.props.element) {
            this.setStateFromResponse(this.props.element);
        }
    }

    setStateFromResponse(response) {
        this.setState({
            guid: response.guid,
            parent: response.parent,
            rolename: response.rolename,
            type: response.type
        });
        this.originalRes = JSON.stringify(response);
    }

    getStateIfChanged = () => {
        const original = JSON.parse(this.originalRes);
        if (this.props.new || original.name !== this.state.rolename || original.type !== this.state.type) {
            const childData = {
                guid: this.state.guid || undefined,
                rolename: this.state.rolename,
                xmiType: 'logical:MeasurementAttribute',
                type: this.state.type
            };
            return childData;
        } else {
            return false;
        }
    };

    getRolename = () => {
        return this.state.rolename;
    }
    getType = () => {
        return this.state.type;
    }
    getXmiType = () => {
        return "logical:MeasurementAttribute";
    }
    getGuid = () => {
        return this.state.guid;
    }
    setType = (newType) => {
        this.setState({type: newType});
    }

    generateNode = () => {
        return {
          guid: this.state.guid,
          rolename: this.state.rolename,
          xmiType:"logical:MeasurementAttribute",
          type: this.state.type,
          parent: this.state.parent || undefined,
        }
      }

    render() {
        const rowColor = this.props.new ? "rgba(152,251,152,0.25)" : false;
        const phenomId = new PhenomId("measurement-attribute",this.props.idCtx);
        return (
            <tr
                key={this.props.element.guid}
                style={{height: 50, backgroundColor: rowColor}} >
                <td>
                    <CadetInput
                        text={this.state.rolename}
                        disabled={!this.props.canEdit}
                        idCtx={phenomId.gen("","rolename")}
                        onChange={(e) => {
                            this.setState({rolename: e.target.value});
                        }}
                        style={{margin: 0, padding: 0, height: 28, fontSize:14}}
                    />
                </td>
                <td>
                    <NodeComboBox xmiType="logical:Measurement"
                                  selectedGuid={this.state.type}
                                  disabled={!this.props.canEdit}
                                  id={phenomId.gen("", "measurement-attr-type")}
                                  onChange={(measurement) => this.setState({ type: measurement.guid })}
                                  onClickResetIcon={() => this.setState({ type: null })} />
                </td>
                <td>
                    <Button
                        id={phenomId.gen("","delete-button")}
                        icon="close"
                        look="bare"
                        disabled={!this.props.canEdit}
                        style={{width: "100%"}}
                        onClick={(e) => {
                            this.props.onDelete(this.state.guid);
                        }} />
                </td>
            </tr>
        );
    }
}
