import React from 'react'
import { connect } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { Button } from '@progress/kendo-react-buttons'
import PhenomId from "../../requests/phenom-id";
import { receiveResponse, receiveErrors, receiveLogs, receiveWarnings } from "../../requests/actionCreators"


// Please use Notifications2.  It will not unmount when navigating to other pages.
        //      parseResponse   -> handles all scenarios
        //      parseLogs       -> success messages
        //      parseWarnings   -> warning messages
        //      parseErrors     -> error messages
export class Notifications extends React.Component {
    info = (input, data) => {
        receiveLogs(input, data);
    }

    error = (input, data) => {
        receiveErrors(input, data);
    }

    parseErrors (res) {
        try {
            const response = JSON.parse(res);
            return receiveResponse(response);
        } catch (_) {
            return receiveErrors("The server responded with an unexpected message. Plese contact Skayl support for more help.")
        }
    }

    note = (input, type = "error", data) => { //creates a new set of notes from the input
        switch(type) {
            case "error":
                receiveErrors(input, data);
                break;
            case "warning":
                receiveWarnings(input, data);
                break;
            case "info":
                receiveLogs(input, data);
                break;
        }
    };

    addNote = (input, type = "error") => { //adds to the input to the old set of notes (untested!!!)
        let newNotes = Array.from(this.state.content);
        if (typeof input === "string") {
            newNotes.push({content: [{type: type, text: input}]});
        } else if (Array.isArray(input)) {
            newNotes = newNotes.concat(input.map(err => {
                return {type: type, text: err};
            }));
        }
        this.setState({content: newNotes});
    };

    render() {
        // Please use Notifications2.  It will not unmount when navigating to other pages.
        //      parseResponse   -> handles all scenarios
        //      parseLogs       -> success messages
        //      parseWarnings   -> warning messages
        //      parseErrors     -> error messages
        return null;
    }
}











const timeoutDuration = 10000;

const closeStyle = {
    position: "absolute",
    top: 0,
    right: 0,
}


export class NotificationManager extends React.Component {
    constructor(props) {
        super(props);

        this.phenomId = new PhenomId("notifications");
        this.timeout = setTimeout(this.__hide, timeoutDuration);
        this.hideTimeout = this.hideDebounce(timeoutDuration)
    }

    animationDuration = 300;
    uniqueKey = 1;      // used for React's <key> attribute so React can better identify changes
    state = {
        notifications: [],
    }

    componentDidMount() {
      this.appendNotice(this.props.notice);
    }

    componentDidUpdate(prevProps) {
      if (prevProps.notice !== this.props.notice) {
        this.appendNotice(this.props.notice);
      }
    }

    static parseResponse = (response) => {
      receiveResponse(response);
    }

    static parseLogs = (logs, data) => {
      receiveLogs(logs, data);
    }

    static parseErrors = (errors, data) => {
      receiveErrors(errors, data);
    }

    static parseWarnings = (warnings, data) => {
      receiveWarnings(warnings, data);
    }

    // the timeout delay is to allow the fade out animation to finish
    __hide = () => {
        const notifications = [...this.state.notifications];
        notifications.forEach(data => {
            data.animateIn = false;
        });

        this.setState({ notifications },
            () => {
                clearTimeout(this.timeout);
                setTimeout(() => this.setState({ notifications: [] }), this.animationDuration)
            })
    }

    // individual notice block animates out and then removed after timer
    hideSingle = (notice) => {
      notice.animateIn = false;
      this.forceUpdate(() => {
          setTimeout(() => {
            const notifications = [...this.state.notifications];
            const idx = notifications.findIndex(n => n.key === notice.key);
            if(idx > -1) notifications.splice(idx, 1);
            this.setState({ notifications });
          }, this.animationDuration)
      })
    }

    // number of notification blocks are limited to 4
    appendNotice = (notice) => {
        if (!notice) {
            return;
        }

        const notifications = [...this.state.notifications];
        const { errors=[], logs=[], warnings=[], data } = notice;

        if (errors.length&& errors.every(ele => typeof ele === "string")) {
            // enqueue to the front of array
            notifications.unshift({
                key: this.uniqueKey++,
                errors,
                animateIn: true,
            })
        }

        if (logs.length && logs.every(ele => typeof ele === "string")) {
            notifications.unshift({
                key: this.uniqueKey++,
                logs,
                animateIn: true,
            })
        }

        if (warnings.length && warnings.every(ele => typeof ele === "string")) {
            notifications.unshift({
                key: this.uniqueKey++,
                warnings,
                animateIn: true,
            })
        }

        this.setState({
            notifications: notifications.slice(0, 4),
        }, this.hideTimeout);
    }

    // myDebounce function
    hideDebounce = (interval = timeoutDuration) => {
        return () => {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.__hide, interval);
        }
    }

    render() {
        const { notifications } = this.state;

        if(!notifications.length) {
            return null;
        }

        return (
            <div id={this.phenomId.genPageId()} className="notifications-wrapper" role="notifications">
                {notifications.map((notice) => {
                    const { errors, logs=[], warnings, animateIn } = notice;
                    const classes = ["alert", "alert-item"];
                    let category = "", messages = [];

                    if (errors) {
                        messages = errors;              // array of text
                        category = "errors";            // used by robotframework
                        classes.push("alert-danger");   // changes text color

                    } else if (warnings) {
                        messages = warnings;
                        category = "warnings";
                        classes.push("alert-warning");

                    } else {
                        messages = logs;
                        category = "logs";
                        classes.push("alert-success");
                    }

                    return <CSSTransition key={`notification-${category}-${notice.key}`}
                                          in={animateIn}
                                          appear={true}
                                          timeout={this.animationDuration}
                                          unmountOnExit
                                          classNames="fade">
                            <div className={classes.join(" ")}>
                                  {messages.slice(0, 4).map((msg, j) => (
                                  <div key={`notitymessage-${category}-${j}`}
                                        id={this.phenomId.gen(["init",j],"div")}>
                                          {msg}</div>))}
        
                                  {messages.length > 4 &&
                                  <div key={`notitymessage-moreinfo`} style={{textAlign:"right", fontSize:12}}>...See Event Log for more information</div>}
        
                                  <Button className={this.phenomId.genPageId("hide-btn")}
                                          icon="close"
                                          look="bare"
                                          style={closeStyle}
                                          onClick={() => this.hideSingle(notice)} />
                            </div>
                        </CSSTransition>
                })}
            </div>
        )
    }
}



const msp = (state) => ({
  notice: state.notification?.notice,
})

export const Notifications2 = connect(msp)(NotificationManager)