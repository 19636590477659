import React from 'react';
import { AbstractModelFactory } from '@projectstorm/react-canvas-core';

import { CompositionLinkModel } from '../link/CompositionLink';
import { DiagramPortModel } from "./DiagramPort";


export class CompositionPortFactory extends AbstractModelFactory {
	constructor() {
		super('comp-port');
	}

	generateModel() {
		return new CompositionPortModel();
	}
}

export class CompositionPortModel extends DiagramPortModel {
	constructor(options={}) {
		super({
			...options,
			type: 'comp-port'
		});
	}

	createLinkModel() {
		return new CompositionLinkModel();
	}
}