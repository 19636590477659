import { PhenomLabel, PhenomSelect, PhenomInput, KbButton } from "../util/stateless";
import { scrubBooleanStrings } from "../util/util"
import React from "react";
import { connect } from "react-redux"
import { TreeView } from "@progress/kendo-react-treeview";
import { Checkbox } from "@progress/kendo-react-inputs";
import LoaderButton from "../widget/LoaderButton";
import PhenomId from "../../requests/phenom-id";
import {TREE_ICON_MAP} from "../tree/tree_constants";
import { receiveResponse, receiveLogs, receiveErrors, setProjectSettings } from "../../requests/actionCreators";
import { PhenomLink } from "../widget/PhenomLink";
import {getDateTimeForFilename} from "../util/util";
import ReactTooltip from 'react-tooltip'
import { _ajax } from "../../requests/sml-requests";
import { SubMenuRight } from "../edit/edit-top-buttons";

class CincGen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileType: "face3_cinc_code",
            reportBy: "Main_Program",
            mainProgramOptions:[],
            uopOptions:[],
            loading: false,
            include_skeleton: true,
            include_model: true,
            selected_cinc_version: null,
            namespace: "DSDM",
            system_name: "Test_System",
            targetArchitecture: null,
            cinc_versions_are_available: null,
            page_loaded: null,
        };

        this.artifact_options = (this.props.expired || this.props.modeler_only) ? [] :
            [
                {label: "CinC", value: "face3_cinc_code"},
                {label: "Mock UoP", value: "cinc_im_from_uop"},
                {label: "PinC", value: "pinc"}];

        //TODO: Remove once the options come back with correct granularity
        if (this.props.pinc) {
            this.artifact_options = [{label: "PinC", value: "pinc"}]
        }

        this.architecture_options = [
          {label: "Debian 9", value: "debian_9"},
        ];
        this.descriptions = {
            "cinc_im_from_uop": "Generate CinC content based on UoP",
            "face3_cinc_code": "FACE 3.0 CinC. Select your Main Program(s) and UoPs.",
            "pinc": "FACE 3 Passthrough Infrastructure Capability. Generate based on UoPs."};
        this.treeConcernaMap = {
            "View": "views",
            "Portable Component (UoP)": "uops",
            "Main_Program": "mps"
        };
        this.licenses = {}

        this.phenomId = new PhenomId("cinc-gen",this.props.idCtx);
    }


    componentDidMount() {
      this.setState({fileType: "face3_cinc_code"}, this.getVersionOptions);
      this.populateCincNodes();
    }

    componentDidUpdate(prevProps, prevState) {
      const { fileType } = this.state;

      // init config and node selection based on projest settings
      if (prevState.fileType !== fileType && this.props.project_settings?.generate) {
        this.initGenerateSettings(this.props.project_settings.generate, fileType);
      }
    }

    /**
     * Initializes the cinc gen config and node selections based on project settings.
     *
     * @param {Array} selection - An array of objects representing the main programs/uops and licenses to be selected.
     */
    initGenerateSettings = (generateSettings, fileType) => {
      if (!generateSettings || !generateSettings[fileType]) return;
      const data = scrubBooleanStrings(generateSettings[fileType]);
      const { selection, generateConfig } = data;
      const mainProgramOptions = [...this.state.mainProgramOptions];
      const uopOptions = [...this.state.uopOptions];

      switch (fileType) {
        case "face3_cinc_code":
          selection.forEach(mainProgram => {
            const newMp = mainProgramOptions.find(mp => mp.guid === mainProgram.guid);
            if (!newMp) return;

            newMp.checked = true;
            newMp.expanded = mainProgram.expanded === true;
            if (mainProgram.selected_license) {
              newMp.selectedLicense = mainProgram.selected_license;
            }
        
            mainProgram.include_uops.forEach(uop => {
              const newUop = newMp.items.find(mpUop => mpUop.guid === uop);
              if (newUop) newUop.checked = true;
            });
          });
          this.setState({ 
            mainProgramOptions,
            system_name: generateConfig.system_name,
            include_skeleton: generateConfig.include_tss_skeleton ? "true" : "false",
            namespaceOverride: generateConfig.namespace ? true : false,
            namespace: generateConfig.namespace ? generateConfig.namespace : "DSDM", 
            include_model: generateConfig.include_model,
          });
          break

        case "cinc_im_from_uop":
          selection.forEach(uop => {
            const newUop = uopOptions.find(uopOption => uopOption.guid === uop.guid);
            if (!newUop) return;

            newUop.checked = true;
            newUop.expanded = uop.expanded === true;
            
            if (uop.include_main === true) {
              newUop.items[0].checked = true;
            }
          })
          this.setState({ 
            uopOptions,
            system_name: generateConfig.system_name,
            include_skeleton: generateConfig.include_tss_skeleton ? "true" : "false",
            namespaceOverride: generateConfig.namespace ? true : false,
            namespace: generateConfig.namespace ? generateConfig.namespace : "DSDM", 
            include_model: generateConfig.include_model,
          });
          break
          
        case "pinc":
          selection.forEach(uop => {
            const newUop = uopOptions.find(uopOption => uopOption.guid === uop.guid);
            if (!newUop) return;

            newUop.checked = true;
            newUop.expanded = uop.expanded === true;
            
            if (uop.include_main === true) {
              newUop.items[0].checked = true;
            }
          })
          this.setState({ 
            uopOptions,
            system_name: generateConfig.system_name,
            namespaceOverride: generateConfig.namespace ? true : false,
            namespace: generateConfig.namespace ? generateConfig.namespace : "DSDM", 
            include_model: generateConfig.include_model,
          });
          break
      }
    };

    getVersionOptions () {
        _ajax({
            url: "/index.php?r=/generate/get-cinc-options",
            method: "post", 
            data: {
                "artifact": this.state.fileType
            }
        }).then(res => {
            let options = res.data.options;
            let versionsAreAvailable = (options.length > 0);
            let defaultSelection = versionsAreAvailable ? options[0]['config-name'] : false
            let descriptions = {}
            let face_versions = {}
            let languages = {}
            options.forEach((x) => {
                let key = x['config-name']
                descriptions[key] = x['description']
                face_versions[key] = x['face-versions']
                languages[key] = x['languages']
            })
            this.setState({cinc_versions_are_available: versionsAreAvailable, 
                            cinc_version_options: options, 
                            selected_cinc_version: (this.props.project_settings?.generate && this.props.project_settings.generate[this.state.fileType]?.generateConfig?.cinc_version)  || defaultSelection, 
                            version_descriptions:descriptions, 
                            face_versions, 
                            selected_face_version: (this.props.project_settings?.generate && this.props.project_settings.generate[this.state.fileType]?.generateConfig?.face_version) || (defaultSelection && face_versions[defaultSelection][0]),
                            languages,
                            selected_language: (this.props.project_settings?.generate && this.props.project_settings.generate[this.state.fileType]?.generateConfig?.language) || (defaultSelection && languages[defaultSelection][0])})
        });
    }

    populateCincNodes() {
        let nodes = {
          "uop:PortableComponent": [],
          "uop:PlatformSpecificComponent": [],
          "ddm:MainProgram": [],
          "im:UoPInstanceToMainProgram": [],
          "im:UoPInstance": []
        };
        _ajax({
            url: "/index.php?r=/generate/get-main-program-options",
            method: "post"
        }).then(res => {
            nodes["ddm:MainProgram"] = res.data.options || [];
            this.licenses = (res.data.licenses || {});

            _ajax({
                url: "/index.php?r=/node/model-nodes-of-type",
                method:"get",
                data: {
                    type:["uop:PortableComponent", "uop:PlatformSpecificComponent", "im:UoPInstanceToMainProgram", "im:UoPInstance"]
                }
            }).then(res => {
                res.data.nodes.forEach(node => nodes[node.xmiType].push(node));
                let uops = nodes["uop:PortableComponent"].concat(nodes["uop:PlatformSpecificComponent"]);

                uops && uops.forEach(n => {
                  n["text"] = n["name"];
                  n["items"] = [
                    {
                      text: "Include main"
                    }
                  ];
                });

                let mains = nodes["ddm:MainProgram"]
                mains && mains.forEach(n => {
                  n["text"] = n["name"];
                  n["items"] = []
                  n["selectedLicense"] = n["default_license"]
                })

                // Resolve associations
                nodes["im:UoPInstanceToMainProgram"] && nodes["im:UoPInstanceToMainProgram"].forEach(associationNode => {
                    let instanceNode = nodes["im:UoPInstance"].find(n => n.guid === associationNode.UoP_Instance_Guid);
                    let uopNode = uops.find(n => n.guid === instanceNode.realizes);
                    let mpNode = mains.find(n => n.guid === associationNode.Main_Program_Guid);

                    if (mpNode["items"].findIndex(uop => uop.guid === uopNode.guid) === -1) {
                      const copy = {...uopNode};
                      delete copy["items"]          // remove "Include main"
                      mpNode["items"].push(copy);
                    }
                });
                this.setState({mainProgramOptions:mains, uopOptions:uops, page_loaded: true}, () => {
                  // init config and node selection based on projest settings
                  if (this.props.project_settings?.generate) {
                    this.initGenerateSettings(this.props.project_settings.generate, "face3_cinc_code")
                  }
                });
            })
        });
    }


    changeState = e => {
        const target = e.target.id.match("fileType")[0]
        this.setState({[target]: e.target.value});
        if (target === "fileType") {
            if (["cinc_im_from_uop","pinc"].includes(e.target.value)) {
                this.state.uopOptions.forEach(n => {
                    n["checked"] = false;
                    n["items"] = [{"text": n["name"] + "_main"}]});
                this.state.mainProgramOptions.forEach(n => {
                    n["checked"] = false;
                })
            } else if (e.target.value === "face3_cinc_code") {
                this.state.uopOptions.forEach(n => {
                    n["checked"] = false;
                    n["items"] = []});
            }
            this.getVersionOptions();
        }
    };

    requestGeneratedFile = () => {
        this.setState({loading: true});
        const { fileType, reportBy } = this.state;
        let selectedNodes = [];
        if (["cinc_im_from_uop", "pinc"].includes(fileType)) {
            this.state.uopOptions.forEach(n => {
                if (n.checked && n.items) {
                    let item = {guid: n.guid, include_main:n.items[0].checked, expanded:n.expanded};
                    selectedNodes.push(item);
                }
            });
        } else if (fileType === "face3_cinc_code") {
            this.state.mainProgramOptions.forEach(n => {
                if (n.checked && n.items) {
                    let item = {guid: n.guid,
                                expanded: n.expanded,
                                include_uops:n.items.filter(c => c.checked)
                                                    .map(c => c.guid),
                                selected_license: n.selectedLicense};
                    selectedNodes.push(item);
                }
            });
        }
        const downloadFileType = fileType;
        let skeleton = this.state.fileType == "pinc" || this.state.include_skeleton;
        let generationConfig = {include_tss_skeleton:skeleton,
            cinc_version:this.state.selected_cinc_version,
            face_version:this.state.selected_face_version,
            language:this.state.selected_language,
            namespace:this.state.namespaceOverride ? this.state.namespace : false,
            system_name:this.state.system_name !== "" ? this.state.system_name : "Test_System",
            include_model: this.state.include_model,
        };
        return _ajax({
            url: "/index.php?r=/generate/generate-model-file",
            method: "post",
            data: {
                fileType,
                reportBy,
                template: [],
                selectedNodes,
                generationConfig,
                includeTags: [],
                excludeTags: [],
            }
        }).then(response => {
            this.setState({loading: false});

            receiveResponse(response);

            if (response.data.filename) {
              receiveLogs((this.artifact_options.find(op => op.value === fileType)["label"]) + " generated.");
              const newName = `${this.props.userIdentity.branchName}_${downloadFileType}${getDateTimeForFilename()}.${response.data.filename.match(/(?!\.)\w+$/)}`;
              window.location.href = `${process.env.NODE_ENV === "development" ? "http://localhost" : ""}/index.php?r=/generate/download-file&file=${response.data.filename}&newName=${newName}`;

              //store mains/uops and licenses in 'generate' react redux
              setProjectSettings({
                ...this.props.project_settings,
                generate: {
                  ...this.props.project_settings.generate,
                  [fileType]: {
                    selection: selectedNodes,
                    generateConfig: generationConfig,
                  }
                }

              })
            }
        })
    };

    selectTemplates = e => {
        const selectedTemplates = [];
        for (const template of e.currentTarget.children) {
            if (template.selected) {
                selectedTemplates.push(template.value);
            }
        }
        this.setState({template: selectedTemplates});
    }

    render() {
        const phenomId = this.phenomId;
        const { params } = this.props.match;

        const TreeItem = (props) => {
          // render "Include Main" checkbox
          if (!props.item.xmiType) {
            return <span>{ props.item.text }</span>
          }

          return <>
                  <img src={TREE_ICON_MAP[props.item.xmiType]} style={{paddingRight:"5px"}} />
                  <PhenomLink node={props.item} />
                 </>
        };
        const isMockUoP = ["cinc_im_from_uop","pinc"].includes(this.state.fileType);
        return (
            <div className="phenom-content-wrapper">
                <nav className="sub-menu-actions flex-end" aria-label='form actions' > 
                  <SubMenuRight> 
                    <KbButton /> 
                  </SubMenuRight> 
                </nav>
                <div className="flex-container phenom-content-scrollable subview-wrapper">
                  <div className="p-row">
                    <div className="p-col p-col-4">
                      <PhenomSelect label="CinC Artifact"
                                    data={this.artifact_options}
                                    value={this.state.fileType}
                                    id={phenomId.gen("init","artifact")}
                                    textField="label"
                                    dataItemKey="value"
                                    onChange={(e) => {
                                      this.setState({ fileType: e.target.value }, this.getVersionOptions)
                                    }}
                                    wrapperProps={{
                                      style:{ maxWidth: 300 },
                                    }} />
                    </div>

                    <div className="p-col p-col-8">
                      <div>
                        <PhenomLabel text="Description"/>
                        <p>{this.descriptions[this.state.fileType]}</p>
                      </div>
                    </div>
                  </div>

                  <div style={{ display: "flex", flexDirection: "column", gap: "0.75em", padding: "1em", borderTop: "2px solid #eaeaea" }}>
                    {this.state.cinc_versions_are_available ?
                    <>
                        <>
                            <div className={"flex-h"}>
                                <label htmlFor="cinc_version" style={{marginRight: 10, "width": "200px", display: "flex", alignItems: "center"}}>CinC Version</label>
                                <PhenomSelect data={this.state.cinc_version_options}
                                              value={this.state.selected_cinc_version}
                                              textField="name"
                                              id={phenomId.gen("init","version")}
                                              dataItemKey="config-name"
                                              onChange={(e) => {
                                                let v = e.target.value
                                                let face_versions = this.state.face_versions[v]
                                                let languages = this.state.languages[v]
                                                this.setState({ selected_cinc_version: v,
                                                                selected_face_version: face_versions[face_versions.length - 1],
                                                                selected_language: languages[languages.length - 1] })
                                              }}
                                              wrapperProps={{
                                                style:{ maxWidth: 100 },
                                              }} />
                            </div> 
                            <div className={"flex-h"} style={{alignItems: "center", paddingTop:"0.35em", paddingBottom: "0.35em"}}>
                                <label htmlFor="version_description" style={{marginRight: 10, "width": "200px"} }>Description</label>
                                {this.state.version_descriptions[this.state.selected_cinc_version]}
                            </div>
                        </> 
                        <div className={"flex-h"}>
                            <label htmlFor="face_version" style={{marginRight: 10, "width": "200px", display: "flex", alignItems: "center"}}>FACE Version</label>
                            <PhenomSelect data={this.state.face_versions[this.state.selected_cinc_version]}
                                          value={this.state.selected_face_version}
                                          onChange={(e) => {
                                            this.setState({ selected_face_version: e.target.value })
                                          }}
                                          wrapperProps={{
                                            style:{ maxWidth: 100 },
                                          }} />
                        </div>
                        <div className={"flex-h"}>
                            <label htmlFor="p_language" style={{marginRight: 10, "width": "200px", display: "flex", alignItems: "center"}}>Language</label>
                            <PhenomSelect data={this.state.languages[this.state.selected_cinc_version]}
                                          value={this.state.selected_language}
                                          id={phenomId.gen("init","language")}
                                          onChange={(e) => {
                                            this.setState({ selected_language: e.target.value })
                                          }}
                                          wrapperProps={{
                                            style:{ maxWidth: 100, display: "flex", alignItems: "center"},
                                          }} />
                        </div> 
                    </>: 
                    <div>
                        {this.state.cinc_versions_are_available === null ? "Cinc versions are loading..." : this.state.cinc_versions_are_available ? null : "No Cinc versions are available!"}
                    </div>}

                    <div className={"flex-h"}>
                      <label htmlFor="namespace" style={{marginRight: 10, "width": "200px"}}>Override namespaces?</label>
                        <span className="fas fa-info-circle"
                              style={{margin: "2px 5px 0 0"}}
                              data-tip
                              data-for="namespaceInfo"
                              data-place="right"/>
                        <ReactTooltip id='namespaceInfo'>
                                    <span>Forces a global namespace overriding top-level model names.<br/><br/>                      
                                    </span>
                          </ReactTooltip>
                          <Checkbox checked={this.state.namespaceOverride}
                                    label=""
                                    onChange={(prevState) => this.setState({ namespaceOverride: !prevState.namespaceOverride})} />
                    </div>


                    {this.state.namespaceOverride && 
                    <div className={"flex-h"}>
                      <label htmlFor="namespace" style={{marginRight: 10, "width": "200px", display: "flex", alignItems: "center"}}>Namespace:</label>
                      <PhenomInput  value={this.state.namespace}
                                    disabled={false}
                                    style={{width: "20px", marginBottom: 0}}
                                    autoFocus={false}
                                    id={phenomId.gen("", "name")}
                                    onChange={(e) => { e.stopPropagation();
                                      let val = e.target.value.match(/[0-9A-Za-z_]*/)[0];
                                      this.setState({namespace: val});}} 
                                    containerProps={{
                                        style:{ width: 200 },
                                    }}/>
                    </div>}

                    <div className={"flex-h"}>
                      <label htmlFor="system-name" style={{marginRight: 10, "width": "200px", display: "flex", alignItems: "center"}}>System Directory:</label>
                      <PhenomInput  value={this.state.system_name}
                                    disabled={false}
                                    style={{width: "20px", marginBottom: 0}}
                                    autoFocus={false}
                                    id={phenomId.gen("", "name")}
                                    onChange={(e) => { e.stopPropagation();
                                      let val = e.target.value.match(/[0-9A-Za-z_]*/)[0];
                                      this.setState({system_name: val});}} 
                                    containerProps={{
                                        style:{ width: 200 },
                                    }}/>
                    </div>
                    {this.props.pinc && <div className={"flex-h"}>
                      <label htmlFor="architecture" style={{marginRight: 10, "width": "200px"}}>Target Architecture:</label>
                      <PhenomSelect data={this.architecture_options}
                                    value={this.state.targetArchitecture}
                                    textField="label"
                                    dataItemKey="value"
                                    onChange={(e) => {
                                      this.setState({ targetArchitecture: e.target.value })
                                    }}
                                    wrapperProps={{
                                      style:{ maxWidth: 300 },
                                    }} />
                    </div>}
                    {!(this.state.fileType == "pinc") && <div className={"flex-h"}>
                      <label htmlFor="include_tss_skeleton" style={{marginRight: 10, "width": "200px", display: "flex", alignItems: "center"}}>Include CinC Source?</label>
                      <PhenomSelect data={[{label: "No", value: false},
                                           {label: "Yes", value: true}]}
                                    value={this.state.include_skeleton}
                                    textField="label"
                                    dataItemKey="value"
                                    id={phenomId.gen("init","source")}
                                    onChange={(e) => {
                                      this.setState({ include_skeleton: e.target.value })
                                    }}
                                    wrapperProps={{
                                      style:{ maxWidth: 100 },
                                    }} />
                    </div>}
                    <div className={"flex-h"}>
                      <label htmlFor="namespace" style={{marginRight: 10, "width": "200px"}}>Include Model files?</label>
                      <div id={phenomId.gen("init","model-file-checkbox")}>
                        <Checkbox checked={this.state.include_model}
                                  label=""
                                  onChange={() => this.setState(prevState => ({include_model: !prevState.include_model}))} />
                      </div>
                    </div>
                    
                    <div style={{paddingTop: "0.35em"}}>
                      {isMockUoP &&
                        <label style={{marginRight: 10, minHeight: "30px"}}>Select your Mocked UoPs and Mains:</label>}
                      {this.state.fileType === "face3_cinc_code" &&
                        <label style={{marginRight: 10}}>Select your Mains and UoPs:</label>}
                    </div>
                    <TreeView
                        data={isMockUoP ? this.state.uopOptions : this.state.mainProgramOptions}
                        item={TreeItem}
                        checkboxes={true}
                        expandIcons={true}
                        onExpandChange={(event) => {
                            event.item.expanded = !event.item.expanded;
                          }}
                        onCheckChange={(event) => {let item = event.item;
                                                    item.checked = !item.checked
                                                    item.expanded = item.checked
                                                    const data = isMockUoP ? this.state.uopOptions : this.state.mainProgramOptions;
                                                    const parent = data.find(x => x.items.includes(item));
                                                    if (item.checked && parent) {
                                                        parent.checked = true;
                                                    }
                                                    if (event.item.items)
                                                        event.item.items.forEach(c => c.checked = event.item.checked)
                                                    this.forceUpdate()}
                                                    }/>
                    If nothing is selected then we will generate Data Types and TS Interfaces for your entire model.

                      {((this.state.mainProgramOptions || []).length > 0 && !isMockUoP) && 

                      <div>
                      <label htmlFor="cinc_version" style={{marginRight: 10, marginBottom:20, "width": "200px"}}>Select License(s):</label>
                          {this.state.mainProgramOptions.filter(x => x.checked).
                          map(x => {
                            return (
                                <div className={"flex-h"}>
                                <div style={{marginRight: 10, marginBottom:20, "width": "200px"}}>{x.name}</div>
                                    <PhenomSelect data={Object.keys(this.licenses).map(l => {
                                            return({label: (this.licenses[l].type == "EVAL") ? "Evaluation" : l, value: l})}
                                        )}
                                                  value={x.selectedLicense}
                                                  textField="label"
                                                  dataItemKey="value"
                                                  onChange={(e) => {
                                                    x.selectedLicense = e.target.value
                                                    this.forceUpdate()
                                                  }}
                                                  wrapperProps={{
                                                    style:{ maxWidth: 350 },
                                                  }} />
                                </div>
                                )
                          })}
                      </div>}

                  </div>

                  <div className="p-row">
                    <div>
                      {this.state.page_loaded === true ? 
                      <LoaderButton
                          id={phenomId.gen("init","generate-button")}
                          className='filled-button2'
                          text="GENERATE"
                          onClick={this.requestGeneratedFile}
                          disabled={this.cinc_versions_are_available}
                      />
                      :
                      null
                        }
                    </div>
                  </div>
                </div>
            </div>
        );
    }
}



const msp = (state) => {
  const { userRole="" } = state.user;

  return {
    project_settings: state.app.project_settings,
    userIdentity: state.user.userIdentity,
    expired: state.user.expired,
    modeler_only: state.user.modeler_only,
    expert_only: state.user.expertMode,
    cinc_works_only: userRole.indexOf('c') !== -1,
    pinc: userRole.includes('p'),
  }
}

export default connect (msp)(CincGen);
