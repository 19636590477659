import React from 'react';
import { AbstractModelFactory } from '@projectstorm/react-canvas-core';
import { PortWidget } from "@projectstorm/react-diagrams-core";
import { DefaultPortModel } from '@projectstorm/react-diagrams-defaults';
import { BaseLinkModel } from './BaseLink';
import { convertPortPositionToPercentage, isStormData } from '../util';
import StormData from './StormData';


export const anchorProps = {
  width: 9
}



// ------------------------------------------------------------
// PORTS - REACT STORM
// ------------------------------------------------------------
export class BasePortFactory extends AbstractModelFactory {
	constructor() {
		super('base-port');
	}

	generateModel() {
		return new BasePortModel();
	}
}

export class BasePortModel extends DefaultPortModel {
	constructor(options = {}) {
		super({
      type: 'base-port',
			...options,
		});

    if (!isStormData(this.options.attrData)) {
      this.options.attrData = new StormData();
    }

		if (!this.options.widgetPos) {
      this.setWidgetPosition();
    }

    this.registerListener({
      entityRemoved: (e) => {
        // remove all links connected to this port
        Object.values(this.getLinks()).forEach(link => link.remove());

        // this.remove() only fires an event listener.
        // it does not actually remove the port, therefore the port is removed through the parent.
        const parentNode = this.getNode();
        parentNode && parentNode.removePort(this);

        // a slight delay is needed or else the link/port removal will crash
        switch(parentNode?.getOptions().type) {
          case "vertex-trace-node":
            // remove Vertex Node if is has no ports
            return setTimeout(() => parentNode.fireEvent({}, "removeNodeWithNoPorts"), 0);
          case "line-shape":
            // line/arrow shapes are drawn with two separate NodeModels. If a point is deleted, then the NodeModels are deleted as well
            return setTimeout(() => parentNode.remove(), 0);
          case "block-node":
            const $app = parentNode.$app;
            const parentData = parentNode.getStormData();
            const attrData = this.getAttrData();

            parentData && parentData.removeChild(attrData.getGuid());

            $app?.markNodeForDeletion && $app.markNodeForDeletion({
              data: attrData.serializeData(),
              position: [0, 0],
            });
            break;
        }
      },
    })
	}

  createLinkModel() {
		return new BaseLinkModel();
	}

  serialize() {
    return {
      ...super.serialize(),
      widgetPos: this.getWidgetPosition(),
      portData: {
        guid: this.getAttrGuid(),
      }
    }
  }

  deserialize(event) {
    super.deserialize(event);

    this.options.attrData = event.data.portData;
    if (!isStormData(this.options.attrData)) {
      this.options.attrData = new StormData();
    }

    this.options.widgetPos = event.data.widgetPos;
  }




  // ------------------------------------------------------------
  // Getters
  // ------------------------------------------------------------
  /**
   * Javascript is a dynamically typed language.
   *  -> If IDE doesn't show suggested methods, then look up the StormData Class to see the methods
   * 
   * @returns StormData
   */
  getAttrData() {
    return this.options.attrData;
  }

  /**
   * 
   * @returns guid
   */
  getAttrGuid() {
    return this.getAttrData().getGuid();
  }


  /**
   * 
   * @returns xmiType
   */
  getAttrXmiType() {
    return this.getAttrData().getXmiType();
  }

  getPortPosition() {
    return {
      x: this.getX(),
      y: this.getY(),
    }
  }

  getWidgetPosition() {
    return this.getOptions().widgetPos;
  }

  getFirstLink() {
    return Object.values(this.getLinks())[0];
  }

  getLastLink() {
    const links = Object.values(this.getLinks());
    return links[links.length - 1];
  }

  isEmpty() {
    return !Object.keys(this.getLinks()).length;
  }

  isComposedPort() {
    return this.getAttrXmiType() === "im:ComposedInPort" || this.getAttrXmiType() === "im:ComposedOutPort";
  }

  countLinks() {
    return Object.keys(this.getLinks()).length;
  }

  // ------------------------------------------------------------
  // Setters
  // ------------------------------------------------------------
  /**
   * Set the name of the Port
   *    This name is used as a unique identifier (specific to React Storm)
   * 
   * @param {string} name 
   */
  setName(name) {
		this.options.name = name;
	}

  setAttrData(attrData) {
    if (!isStormData(attrData)) return;
    this.options.attrData = attrData;
  }

  setDefaultPosition() {
    const box = this.getNode().getBoundingBox().points;

    if (this.getOptions().in) {
      this.setPosition(box[0].x, box[0].y);
      this.setWidgetPosition(convertPortPositionToPercentage(box[0], this.getNode()));
    } else {
      this.setPosition(box[1].x, box[1].y);
      this.setWidgetPosition(convertPortPositionToPercentage(box[1], this.getNode()));
    }

    this.reportPosition();
  }

  setDefaultPositionCenter() {
    const center = this.getNode().getCenterPoint();
    this.setPosition(center.x, center.y);
  }

  setWidgetPosition(pos={ top:0, left:0 }) {
    return this.getOptions().widgetPos = pos;
  }

  setWidgetPosLeft(left) {
    const pos = this.getWidgetPosition();
    this.setWidgetPosition({ top: pos.top, left });
  }

  setWidgetPosTop(top) {
    const pos = this.getWidgetPosition();
    this.setWidgetPosition({ top, left: pos.left });
  }

  isLinkSelected() {
    return Object.values(this.getLinks()).some(link => link.isSelected());
  }
}





// ------------------------------------------------------------
// Port Anchor - used in render
// ------------------------------------------------------------
export class StormAnchor extends React.Component {
  componentDidUpdate() {
    this.updatePortPosition();
  }

  /**
   * Update's the port's svg layer position
   */
  updatePortPosition = () => {
    const { port, engine, $app } = this.props;
    if (!this.portRef) return;

    const zoomLevel = $app.model.getZoomLevel() / 100;
    const portRect = this.portRef.getBoundingClientRect();
    const point = engine.getRelativePoint(portRect.x + anchorProps.width / 2, portRect.y + anchorProps.width / 2);
    const posX = point.x / zoomLevel;
    const posY = point.y / zoomLevel;

    if (posX !== port.getX() || posY !== port.getY()) {
      port.setPosition(posX, posY);
    }
  }

  /**
   * Moves the anchor but keeps it along the edge of the node.
   *
   * @param {mouse event} e
   */
  handleDragAnchor = (e) => {
    const { port, engine } = this.props;
    const nodeModel = port.parent;
    let prevMousePoint = engine.getRelativeMousePoint(e);

    // prevent the entire node from moving
    nodeModel.setLocked(true);

    // move anchor only
    const move = (e) => {
      const currMousePoint = engine.getRelativeMousePoint(e);
      this.moveAnchor(prevMousePoint, currMousePoint)
      prevMousePoint = currMousePoint;
    }

    const cleanUp = () => {
      nodeModel.setLocked(false);
      window.removeEventListener("mousemove", move);
      window.removeEventListener("mouseup", cleanUp);
    }

    window.addEventListener("mousemove", move);
    window.addEventListener("mouseup", cleanUp);
  }

  /**
   * When moving the anchor, two values need to be updated
   *    1. anchor's div layer -> this is an absolute value and uses a percentage for top and left
   *    2. port's svg layer -> this uses x and y value relative to the diagram (set by updatePortPosition method)
   *
   * @param {mouse event} prevMousePoint
   * @param {mouse event} currMousePoint
   */
  moveAnchor = (prevMousePoint, currMousePoint) => {
    const { port } = this.props;
    const nodeModel = port.parent;
    const box = nodeModel.getBoundingBox().points;

    let { left, top } = port.getWidgetPosition();
    let moveX = left < 1 && left > 0;
    let moveY = top < 1 && top > 0;

    // horizontal movement
    // (vertical movement is locked to 0 or 1)
    if (moveX && !moveY) {
      left = (currMousePoint.x - box[0].x) / nodeModel.width;

      // mouse moved outside the box
      // change Y position to top of box
      if (currMousePoint.y < box[0].y) {
        top = 0;

      // change Y position to bottom of box
      } else if (currMousePoint.y > box[2].y) {
        top = 1;
      }

    // vertical movement
    // (horizontal movement is locked to 0 or 1)
    } else if (moveY && !moveX) {
      top = (currMousePoint.y - box[0].y) / nodeModel.height;

      // mouse moved outside the box
      // change X position to left side of the box
      if (currMousePoint.x < box[0].x) {
        left = 0;

      // change X position to right side of the box
      } else if (currMousePoint.x > box[2].x) {
        left = 1;
      }

    // both moveX and moveY have the same value (it is in a corner), determine new direction based on mouse's position
    } else {
      const dMouseX = Math.abs(prevMousePoint.x - currMousePoint.x);
      const dMouseY = Math.abs(prevMousePoint.y - currMousePoint.y);

      if (dMouseX > dMouseY) {
        left = (currMousePoint.x - box[0].x) / nodeModel.width;
      } else {
        top = (currMousePoint.y - box[0].y) / nodeModel.height;
      }
    }

    // limit the number between 0 and 1
    top = Math.max(top, 0);
    top = Math.min(top, 1);
    left = Math.max(left, 0);
    left = Math.min(left, 1);

    port.setWidgetPosition({ top, left });
    this.forceUpdate();
  }

  getAnchorSize = () => {
    return anchorProps.width;
  }

  getTopPosition = () => {
    return `calc(${this.props.port.getWidgetPosition().top * 100}% - ${this.getAnchorSize()}px / 2)`;
  }

  getLeftPosition = () => {
    return `calc(${this.props.port.getWidgetPosition().left * 100}% - ${this.getAnchorSize()}px / 2)`;
  }


  render() {
    const { port, engine, visible } = this.props;
    const isSelected = port.isLinkSelected();
    const flowTrigger = (port.getAttrData().getAttr("flowTrigger") || "NONE");
    const rolename = (port.getAttrData().getAttr("rolename") || "");


    return <span className="anchor-point"
                 onMouseUp={this.props.onMouseUp}
                 onMouseDown={this.handleDragAnchor}
                 ref={el => this.portRef = el}
                 style={{ "--anchor-size": this.getAnchorSize() + "px",
                          top: this.getTopPosition(),
                          left: this.getLeftPosition(), }}>
              <PortWidget key={port.options.id}
                          engine={engine}
                          port={port} />

              <span className="anchor-point-bullet"
                    style={{ visibility: visible || (isSelected || flowTrigger.match("PULL|PUSH")) ? "visible" : "hidden",
                             borderRadius: (flowTrigger.match("PULL|PUSH") ? "0px" : "30px"),
                             transform: (flowTrigger == "PUSH" ? "skew(-20deg)" : ""),
                             background: isSelected ? "rgb(0,192,255)": null, }} />
            </span>
  }
}
