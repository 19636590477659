import React from "react";
import { connect } from "react-redux";
import {Button} from "@progress/kendo-react-buttons";
import TagsEditor from "../dialog/TagsEditor";
import { getTags } from "../../requests/sml-requests";
import PhenomId from "../../requests/phenom-id";
import { PhenomLabel } from "../util/stateless"
import { isPhenomGuid } from "./util";


class TagManager extends React.Component {
    phenomId = new PhenomId("tags", this.props.idCtx);
    state = {
        tags: [],
    }

    componentDidMount() {
        this.fetchTags();
        window.addEventListener('UPDATE_NODE_TAGS', this.updateTags);
    }

    componentDidUpdate(prevProps) {
        if (this.props.guid !== prevProps.guid) {
            this.fetchTags();
        }
    }

    componentWillUnmount() {
      window.removeEventListener('UPDATE_NODE_TAGS', this.updateTags);
    }

    fetchTags = () => {
        const { guid } = this.props;
        if (!guid || isPhenomGuid(guid)) {
          return this.setState({ tags: [] });
        }

        getTags(guid).then(response => {
          const rawTags = response.data?.tags;
          const tags = typeof rawTags === 'string' && rawTags.length ? rawTags.split(",") : [];
          this.setState({ tags });
        })
    };

    updateTags = (e) => {
      if (!Array.isArray(e.detail)) return;

      const node = e.detail.find(ele => ele?.guid === this.props.guid);
      if (node && Array.isArray(node.tags)) {
        this.setState({ tags: node.tags });
      }
    }

    editTags = () => {
        TagsEditor.show([{
            name: this.props.name,
            guid: this.props.guid,
            tags: this.state.tags
        }]);
    };

    render() {
        const { canEdit, disabled } = this.props;
        const { tags } = this.state;

        if (!canEdit) {
          return null;
        }

        return <div id={this.phenomId.gen("","wrapper")} className="edit-tag-manager">
            <PhenomLabel text="Tags" id={this.phenomId.gen("","")} />
            <div style={{ marginBottom: "0.5rem" }}>
              {tags.map((tag, idx) => {
                return <Button className="tag-editor-tag"
                              key={idx}
                              id={this.phenomId.gen(["init",idx],"tag-button")}>{tag}</Button>
              })}
            </div>

            {!disabled && <div>
            <Button look="outline" 
                    iconClass="fa fa-tag" 
                    className="icon-text-btn"
                    onClick={this.editTags} 
                    id={this.phenomId.gen("init","edit-button")}>EDIT TAGS</Button>
            </div> }
        </div>
    }
}


const msp = (state) => ({
  canEdit: state.user.canEdit,
})

export const Tags = connect(msp)(TagManager)