import {Grid, GridColumn as Column, GridNoRecords} from "@progress/kendo-react-grid";
import {CadetInput, CadetTextArea, FormatMenuHeaderCell, LineLabel, PackageComboBox} from "./stateless";
import React from "react";
import {orderBy} from "@progress/kendo-data-query";
import {Tags} from "./tags";
import {NodeHistory2} from "../edit/node-history";
import {NavLink} from "react-router-dom";
import {Notifications, Notifications2} from '../edit/notifications';
import DeletionConfirm2 from "../../components/dialog/DeletionConfirm2";
import { getNodeWithAddenda } from "../../requests/sml-requests";
import { _ajax } from "../../requests/sml-requests";
import PhenomId from "../../requests/phenom-id";
import { withPageLayout } from "../edit/node-layout";
import { createNodeUrl } from "../../requests/type-to-path";
import { getActiveChangeSetId } from "../../requests/actionCreators";
import ChangeSetPicker from "../widget/ChangeSetPicker";
import {createPhenomGuid} from "./util";
import NavTree from "../tree/NavTree";
import { PhenomLink } from "../widget/PhenomLink";

export class ObservableManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            realizations: [],
            rlzSort: [],
            editable: true,
            deprecated: false,
            changeSetId: "0",
            parent: "",
        };

        this.original = {};
        this.noticeRef = undefined;
        this.loadView = this.loadView.bind(this);
    }

    componentDidMount() {
        this.loadView();
        window.addEventListener('MOVED_NODES', this.mutateOriginalParentListener);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.match.params.guid !== prevProps.match.params.guid) {
            this.loadView();
        }

        if (prevState.subModelId !== this.state.subModelId ||
            prevProps.subModels !== this.props.subModels) {
                this.setEditingStatus();
        }
    }

    componentWillUnmount() {
      window.removeEventListener('MOVED_NODES', this.mutateOriginalParentListener);
    }

    loadView() {
        if (this.props.match.params.guid === "new") {
            this.original = {};
            this.setState({
                guid: null,
                name: "",
                description: "",
                realizations: [],
                deprecated: false,
            });
        } else {
            getNodeWithAddenda(this.props.match.params.guid, {
                coreAddenda: ["realizations"],
                realizationsFilter: ["xmi:type", "logical:Measurement"],
                realizationsAddenda: ["measurementAxisMULTI", "measurementSystem"],
                measurementAxisAddenda: ["measurementSystemAxis", "valueTypeUnitMULTI"],
                valueTypeUnitAddenda: ["unit", "valueType"],
                defaultValueTypeUnitAddenda: ["unit", "valueType"],
                measurementSystemAxisAddenda: ["defaultValueTypeUnitMULTI"],
            }).then(response => {
                this.setStateFromResponse(response);
                if (this.state.deprecated === "true") {
                    this.setState({editable: false})
                }
            });
        }
    }

    isEdited = () => {
        return this.state.name !== this.original.name ||
            this.state.description !== this.original.description ||
            this.state.parent !== this.original.parent;
    }

    setEditingStatus = () => {
        const { subModels={}, setParentEditingStatus } = this.props;
        const { subModelId } = this.state;
        const currSubModel = subModels[subModelId];
        this.setState({ editable: !currSubModel?.created }, () => {
            setParentEditingStatus && setParentEditingStatus(!currSubModel?.created)
        });
    };

    setStateFromResponse = (response) => {
        this.original = response;

        if (this.props.updateTemplateNode) {
            this.props.updateTemplateNode(response);
        }

        this.setState({...response}, () => {
            this.setEditingStatus();
        });
    }


    handleSave = () => {
        if (!this.isEdited()) {
            return this.noticeRef.error("No changes detected.");
        }

        const data = {
            name: this.state.name,
            guid: this.state.guid || createPhenomGuid(),
            xmiType: "conceptual:Observable",
            description: this.state.description,
            changeSetId: getActiveChangeSetId(),
            parent: this.state.parent || null,
        }

        return _ajax({
            url: "/index.php?r=/node/smm-save-nodes",
            method: "post",
            data
        }).then(res => {
            const response = res.data;
            Notifications2.parseResponse(response);

            if (response.guid) {
                if(this.props.match.params.guid === "new") {
                    this.props.history.push( createNodeUrl(response) );
                } else {
                    this.setStateFromResponse(response);
                }
                NavTree.addNodes([ response ]);
            }
        })
    }

    handleReset = () => {
        this.setState({...this.original});
    }

    handleDelete() {
        DeletionConfirm2.show(this.state.guid, this.state.name, this.loadView);
        return null;
    };

    mutateOriginalParentListener = (e) => {
      // invalid
      if (!this.state.guid) {
        return;
      }

      const leaf = NavTree.getLeafNode(this.state.guid);
      if (!leaf) {
        return;
      }

      const newParentGuid = leaf.getParentGuid();
      if (this.state.parent !== newParentGuid) {
        this.original["parent"] = newParentGuid;
      }
    }

    render() {
        const phenomId = new PhenomId("observable",this.props.idCtx);
        const deprecated = this.state.deprecated === "true";
        let deprecatedClassName = "subview-wrapper flex-v"
        deprecatedClassName += deprecated ? " deprecated" : "";
        return (
            <div className={deprecatedClassName} style={{minHeight: 512}} id={phenomId.gen("","wrapper")}>
                {deprecated ? <strong><div className="deprecated-banner">WARNING: This node has been DEPRECATED</div></strong> : null}
                <Notifications ref={el => this.noticeRef = el} />
                <div className="flex-h">
                    <div className="flex-v" style={{flexGrow: 1}}>
                        <LineLabel text="OBSERVABLE" idCtx={phenomId.gen("","name")} />
                        <CadetInput text={this.state.name}
                            disabled={!this.state.editable}
                            onChange={(e) => this.setState({name: e.target.value})}
                            idCtx={phenomId.gen("","name")} />

                        <LineLabel text="Description" idCtx={phenomId.gen("","description")} />
                        <CadetTextArea text={this.state.description}
                            disabled={!this.state.editable}
                            onChange={(e) => this.setState({description: e.target.value})}
                            idCtx={phenomId.gen("","description")} />
                        <PackageComboBox id={phenomId.genPageId("parent")}
                                          label="Package"
                                          xmiType="face:ConceptualDataModel"
                                          placeholder="<Default>"
                                          nodeGuid={this.state.guid}
                                          selectedGuid={this.state.parent}
                                          disabled={!this.state.editable}
                                          onChange={(parent) => this.setState({ parent: parent.guid })}
                                          onClickCancelIcon={() => this.setState({ parent: undefined })} />
                    </div>
                    <div className="edit-side-bar">
                        <NodeHistory2 ref={ele => this.historyRef = ele} guid={this.props.match.params.guid} idCtx={phenomId.gen("init")} />
                        {/* <ChangeSetPicker id={phenomId.genPageId()}
                                         disabled={!this.state.editable}
                                         label="Change Set" /> */}
                    </div>
                </div>

                <LineLabel text="Measurements" style={{marginBottom: 15, marginTop: 15}} idCtx={phenomId.gen("measurements","")} />
                <Grid
                    id={phenomId.gen("","grid")}
                    data={orderBy(this.state.realizations, this.state.rlzSort)}
                    sortable
                    sort={this.state.rlzSort}
                    onSortChange={(e) => {
                        this.setState({
                            rlzSort: e.sort
                        });
                    }}
                    className="editorTable"
                    resizable>
                    <GridNoRecords>
                        No Data Is Available For This Table
                    </GridNoRecords>
                    <Column title="MEASUREMENTS" field="name"
                        headerCell={(props) => {
                            return <FormatMenuHeaderCell text={props.title} idCtx={phenomId.gen("","measurements")}
                                useTruncation={(val) => this.setState({truncateMeas: val})} />;
                        }}
                        cell={(props) => {
                            const {guid, name} = props.dataItem;
                            return (<td style={{wordBreak: this.state.truncateMeas ? "unset" : "break-word"}}>
                                <NavLink className="cadet-anchor normal-anchor"
                                    to={`/edit/details/measurement/${guid}/`}
                                    id={phenomId.gen("","measurements-link")}>{name}</NavLink>
                            </td>);
                        }} />
                    <Column title="SYSTEM" field="measurementSystem.name"
                        headerCell={(props) => {
                            return <FormatMenuHeaderCell text={props.title}
                                useTruncation={(val) => this.setState({truncateSystems: val})} idCtx={phenomId.gen("","system")} />;
                        }}
                        cell={(props) => {
                            const {measurementSystem} = props.dataItem;
                            return (
                                <td style={{wordBreak: this.state.truncateSystems ? "unset" : "break-word"}}>
                                    <PhenomLink node = {measurementSystem} />
                                </td>
                            )
                        }} />
                    <Column title="AXIS"
                        headerCell={(props) => {
                            return <FormatMenuHeaderCell text={props.title} idCtx={phenomId.gen("","axis")}
                                useTruncation={(val) => this.setState({truncateAxis: val})} />;
                        }}
                        cell={(props) => {
                            const {measurementAxis} = props.dataItem;
                            return (<td style={{wordBreak: this.state.truncateAxis ? "unset" : "break-word"}} id={phenomId.gen(["measurements","axis"],"wrapper")}>
                                {measurementAxis.map((axis, idx) => <div key={idx} id={phenomId.gen(["axis",idx],"div")}>{axis.name}</div>)}
                            </td>);
                        }} />
                    <Column title="LOGICAL TYPE" cell={(props) => {
                        const {measurementAxis} = props.dataItem;
                        return (<td id={phenomId.gen(["measurements","logical"],"wrapper")}>
                            {measurementAxis.map((axis, idx) => {
                                const vtus = axis.valueTypeUnit.length ? axis.valueTypeUnit : axis.measurementSystemAxis.defaultValueTypeUnit;
                                const vtsString = vtus.map(vtu => vtu.valueType.name).join("-");
                                return <div key={idx} id={phenomId.gen(["logical",idx],"vtus-div")}>{vtsString}</div>;
                            })}
                        </td>);
                    }} />
                    <Column title="UNIT" cell={(props) => {
                        const {measurementAxis} = props.dataItem;
                        return (<td id={phenomId.gen(["measurements","unit"],"wrapper")}>
                            {measurementAxis.map((axis, idx) => {
                                const vtus = axis.valueTypeUnit.length ? axis.valueTypeUnit : axis.measurementSystemAxis.defaultValueTypeUnit;
                                const unitsString = vtus.map(vtu => vtu.unit.name).join("-");
                                return <div key={idx} id={phenomId.gen(["unit",idx],"vtus-div")}>{unitsString}</div>;
                            })}
                        </td>);
                    }} />
                </Grid>

                {this.state.guid &&
                    <Tags guid={this.state.guid}
                        name={this.state.name}
                        disabled={!this.state.editable}
                        idCtx={phenomId.gen("init")} />
                }
            </div>);
    }
}



export const EditObservableManager = withPageLayout(ObservableManager);
