function RootLeaf() {
  this.data = {}
  this.config = {
    filteredOut: false,         // is node filtered out?
    expanded: false,            // show/hide children
    selected: false,
    autoSelected: false,        // used to apply .tree-leaf:hover class to autoselected leaves
    checked: false,
    highlighted: false,
    selectable: true,
  }

  this.parentLeaf = null;
  this.childrenLeaves = [];
  

  //--------------------
  // GETTERS
  //--------------------
  this.getData = () => {
    return this.data;
  }

  this.getConfig = () => {
    return this.config;
  }

  this.getParentLeaf = () => {
    //console.log(this.getName() + " -> " +this.parentLeaf?.getName())
    return this.parentLeaf;
  }

  this.getAllParentLeaves = (leaves = []) => {
    if (this.getParentLeaf()) {
      leaves.push( this.getParentLeaf() );
      this.getParentLeaf().getAllParentLeaves(leaves);
    }

    return leaves;
  }

  this.getChildrenLeaves = () => {
    return this.childrenLeaves;
  }

  this.getAllChildrenLeaves = (leaves = []) => {
    for (let leaf of this.getChildrenLeaves()) {
      leaves.push(leaf);
      leaf.getAllChildrenLeaves(leaves);
    }

    return leaves;
  }

  this.getSortedChildrenLeaves = () => {
    // sort will mutate the original array - need to slice it
    return this.childrenLeaves.slice().sort((a, b) => a.getName().localeCompare(b.getName()))
  }

  this.isExpanded = () => {
    return this.config.expanded;
  }

  this.isFilteredOut = () => {
    return this.config.filteredOut;
  }

  this.isSelected = () => {
    return this.config.selected;
  }

  this.isSelectable = () => {
    return this.config.selectable;
  }

  this.isChecked = () => {
    return this.config.checked;
  }

  this.isHighlighted = () => {
    return this.config.highlighted;
  }

  this.isAutoSelected = () => {
    return this.config.autoSelected;
  }

  //--------------------
  // SETTERS
  //--------------------
  this.forceUpdate = () => {
    this.config = { ...this.config };
    this.forceParentUpdate();       // will trigger React.memo's rerender method
  }

  // it walks up the parent and changes an attribute to trigger the rerender method used by React.memo
  this.forceParentUpdate = () => {
    this.getParentLeaf() && this.getParentLeaf().forceUpdate();
  }

  this.updateData = (data) => {
    this.data = { ...this.data, ...data }
    this.forceUpdate();             // will trigger React.memo's rerender method
  }

  this.setParentLeaf = (leaf) => {
    //if (leaf instanceof RootLeaf === false) throw "TypeError. Cannot set a non-RootLeaf parent";
    this.parentLeaf = leaf;
  }

  this.setChildLeaf = (leaf, forceUpdate=true) => {
    if (leaf instanceof RootLeaf === false) throw "TypeError. Cannot set a non-RootLeaf child";
    if (this.childrenLeaves.find(ele => leaf === ele)) {
      leaf.data.childIndex !== null && this.childrenLeaves.sort((a, b) => a.data.childIndex - b.data.childIndex);
      return;
    }

    if (leaf.data.childIndex !== null) {
      this.childrenLeaves.splice(this.childrenLeaves.findIndex(child => child.data.childIndex < leaf.data.childIndex), 0, leaf);
      this.childrenLeaves.sort((a, b) => a.data.childIndex - b.data.childIndex);
    } else {
      this.childrenLeaves.push(leaf);
    }
    if (forceUpdate) this.forceUpdate();             // will trigger React.memo's rerender method
  }

  this.clearChildrenLeaves = () => {
    this.childrenLeaves = [];
    this.forceUpdate();             // will trigger React.memo's rerender method
  }

  this.setConfig = (config={}) => {
    this.config = { ...this.config, ...config };
  }

  this.toggleExpanded = () => {
    this.setExpanded( !this.isExpanded() );
  }

  this.toggleChecked = () => {
    this.setChecked( !this.isChecked() );

    if (this.getParentLeaf()) {
      this.getParentLeaf().updateCheckedStatus();
    }
  }

  this.toggleCheckedWithChildren = () => {
    const checked = !this.isChecked();
    const allParents = this.getAllParentLeaves();
    const allChildren = this.getAllChildrenLeaves().filter(leaf => !leaf.isFilteredOut());

    // Set checked status for current leaf and children
    this.setChecked(checked);
    for (let leaf of allChildren) {
      leaf.setChecked(checked);
    }

    // Update parent's checked status
    for (let parent of allParents) {
      parent.updateCheckedStatus();
    }
  }

  this.setSelectable = (bool) => {
    this.config = {
      ...this.config,
      selectable: bool,
    }
    this.forceParentUpdate();       // will trigger React.memo's rerender method
  }

  this.setSelected = (bool) => {
    this.config = {
      ...this.config,
      selected: bool,
    }
    this.forceParentUpdate();       // will trigger React.memo's rerender method
  }

  this.setAutoSelected = (bool) => {
    this.config = {
      ...this.config,
      autoSelected: bool,
    }
    this.forceParentUpdate();       // will trigger React.memo's rerender method
  }

  this.setChecked = (bool) => {
    this.config = {
      ...this.config,
      checked: bool,
    }
  }

  this.updateCheckedStatus = () => {
    if (this.getChildrenLeaves().length && this.getChildrenLeaves().every(leaf => leaf.isChecked())) {
      // checks if children are checked. note: doing .every on an empty list is true
      this.setChecked(true);
    } else if (this.getChildrenLeaves().every(leaf => leaf.isChecked() === false)) {
      // we specifically check for false because null is falsy
      this.setChecked(false);
    } else {
      // neither true or false, the checkbox will show a dash
      this.setChecked(null);
    }
  }

  this.setHighlighted = (bool) => {
    this.config = {
      ...this.config,
      highlighted: bool,
    }

    // this is only needed because of React.memo
    // it walks up the parent and changes an attribute to trigger the rerender method used by React.memo
    if (this.getParentLeaf()) {
      bool ? this.setParentExpanded(bool) : this.forceParentUpdate();
    }
  }

  /**
   * Toggles the expanded attribute when user clicks the caret
   *    - note: It creates a new "config" to trigger a rerender with React.memo
   */
  this.setExpanded = (bool) => {
    this.config = {
      ...this.config,
      expanded: bool,
    }

    // this is only needed because of React.memo
    // it walks up the parent and changes an attribute to trigger the rerender method used by React.memo
    if (this.getParentLeaf()) {
      bool ? this.setParentExpanded(bool) : this.forceParentUpdate();
    }
  }

  this.setParentExpanded = (bool) => {
    this.getParentLeaf() && this.getParentLeaf().setExpanded(bool);
  }

  /**
   * Activates the "filteredOut" flag so the node does not render
   *    - This should trigger when the node is filtered out
   *    - note: It creates a new "config" to trigger a rerender with React.memo
   */
   this.hide = () => {
    this.config = {
      ...this.config,
      filteredOut: true,
    }
    this.forceParentUpdate();       // will trigger React.memo's rerender method
  }

  /**
   * Removes the "filteredOut" flag so the node will be rendered
   *    - Walks up the parent and removes the parent's "filteredOut" flag
   *    - note: It creates a new "config" to trigger a rerender with React.memo
   */
  this.show = () => {
    this.config = {
      ...this.config,
      filteredOut: false,
    }
    this.getParentLeaf() && this.getParentLeaf().show();
  }

  this.remove = () => {
    const parentLeaf = this.getParentLeaf();
    parentLeaf && parentLeaf.removeChild(this);
  }

  this.removeChild = (leaf, forceUpdate=true) => {
    const children = [...this.getChildrenLeaves()];
    const idx = children.findIndex(ele => ele === leaf);
    idx > -1 && children.splice(idx, 1);
    this.childrenLeaves = children;
    if (forceUpdate) this.forceUpdate();             // will trigger React.memo's rerender method
  }
}


export default RootLeaf;