import React, {useState, useEffect} from "react";
import Draggable from "react-draggable";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import blankDiagram from "../../../../../images/blank_diagram.png";





export default function LoadPrompt({ selectedFile={}, data=[], dataGuidKey, dataNameKey, selectedBranch={}, reachableBranches=[], shareIds=new Set(), stormType, disabled, phenomId, onSelect, onLoad, onDelete, onShare, onShareChange }) {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (typeof selectedFile[dataNameKey] === "string") {
      setInputValue(selectedFile[dataNameKey]);
    }    
  }, [selectedFile])

  const handleInputChange = (e) => {
    const ele = data.find(item => item[dataNameKey] === e.target.value);
    
    if (ele) {
      onSelect(ele);
    } else if (selectedFile[dataGuidKey]) {
      onSelect({
        fileId:  null,
        fileName: e.target.value,
        Image: blankDiagram,
      })
    }

    setInputValue(e.target.value);
  }

  const showShareButton = stormType !== "idm_editor";

  return <div className="storm-prompt-content" id={phenomId.gen("load-prompt")}>
            <div className="p-row row-no-gutter-row">
              <div className="p-col p-col-12">Select a diagram:</div>
              
              <ul className="p-col p-col-6 p-no-gutter storm-prompt-box-200 storm-prompt-selection" id={phenomId.genPageId("selection-list")} style={{ maxWidth: 280 }}>
                {data.map((item) => {
                  return <li key={item[dataGuidKey]}
                            className="storm-prompt-selection-item"
                            id={phenomId.genPageId("selection-item", item[dataGuidKey])}
                            style={{ backgroundColor: item[dataGuidKey] === selectedFile[dataGuidKey] ? "rgba(3, 15, 222, 0.5)" : 
                                      shareIds.has(selectedFile[dataGuidKey]) ? "#feffd3" : null }}
                            onClick={() => onSelect(item)}>
                          {item[dataNameKey]}
                        </li>
                })}
              </ul>

              <div className="p-col p-col-6 p-col-fixed storm-prompt-box-200">
                <img className="storm-prompt-image"
                      src={selectedFile.Image || blankDiagram} />
              </div>
            </div>

            <div className="p-row">
              <div className="p-col p-col-6 p-col-fixed">
                <div>
                  <label className="storm-prompt-label" htmlFor="diagram-name">Diagram name:</label>
                  <Input className="storm-prompt-input" 
                        id="diagram-name" 
                        type="text" 
                        value={inputValue}
                        disabled={disabled}
                        onChange={handleInputChange} />
                </div>
                <div className="storm-prompt-buttons">
                    {onLoad &&
                    <Button iconClass="fas fa-file-import fa-fw"
                            disabled={disabled}
                            id={phenomId.genPageId("load-btn")}
                            onClick={onLoad}>Load</Button>}

                    {onDelete &&
                    <Button iconClass="fas fa-trash fa-fw"
                            disabled={disabled}
                            id={phenomId.genPageId("delete-btn")}
                            onClick={onDelete}>Delete</Button>}
                </div>
              </div>

              {showShareButton &&
              <div className="p-col p-col-6 p-col-fixed">
                <div>
                  <label className="storm-prompt-label" htmlFor="diagram-name">Share name:</label>
                  <DropDownList data={reachableBranches} 
                                textField="name" 
                                dataItemKey="id"
                                value={selectedBranch}
                                disabled={disabled}
                                style={{
                                  marginBottom: 2, 
                                  width: "100%"
                                }}
                                onChange={onShareChange} />
                </div>
                <div className="storm-prompt-buttons">
                    <Button iconClass="fas fa-share fa-fw"
                            id={phenomId.genPageId("share-btn")}
                            onClick={onShare}
                            disabled={disabled || !selectedBranch?.id}>Share</Button>
                </div>
              </div> }
            </div>
  </div>
}

