import {CadetInput, LineLabel} from "../util/stateless";
import React from "react";
import {NavLink} from "react-router-dom";
import PhenomId from "../../requests/phenom-id"
import { PhenomModal } from "../util/Modal";
import { ChangeMyPassword } from "./change_password";
import { Button } from "@progress/kendo-react-buttons";

export class Account extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            branchRoles: {"loading...": ["loading..."]},
            showChangePasswordModal: false,
        };
        this.permissions = {
            awr: "admin",
            wr: "write",
            r: "read",
            "loading...": "loading...",
        };
    }

    saveMyPassword = () => {
        this.changePasswordRef.handleSave()
                              .then(() => {
                                this.setState({ showChangePasswordModal: false });
                              })
    }

    render() {
        const phenomId = new PhenomId("account",this.props.idCtx);
        return (
            <main id={phenomId.gen("","wrapper")}>
                <section className="account-section" id={phenomId.gen(["init","profile"],"wrapper")}>
                    <h4>Profile</h4>
                    <LineLabel text="Name" style={{fontSize: "90%"}} idCtx={phenomId.gen("profile","name")}/>
                    <CadetInput text={this.props.user.username} style={{marginBottom: 0, marginTop: 0}} idCtx={phenomId.gen("profile","name")}/>
                    <LineLabel text="Password" style={{fontSize: "90%"}} idCtx={phenomId.gen("profile","password")}/>
                    <CadetInput text="*******" style={{marginBottom: 0, marginTop: 0}} idCtx={phenomId.gen("profile","password")}/>
                </section>
                <section className="account-section" id={phenomId.gen(["init","contact"],"wrapper")}>
                    <h4>Contact Information</h4>
                    <LineLabel text="Email" style={{fontSize: "90%"}} idCtx={phenomId.gen("contact","email")}/>
                    <CadetInput text={this.props.user.email} style={{marginBottom: 0, marginTop: 0}} idCtx={phenomId.gen("contact","email")}/>
                    <LineLabel text="Phone" style={{fontSize: "90%"}} idCtx={phenomId.gen("contact","phone")}/>
                    <CadetInput text="(***) ***-****" style={{marginBottom: 0, marginTop: 0}} idCtx={phenomId.gen("contact","phone")}/>
                </section>
                <section className="account-section flex-v" id={phenomId.gen(["init","security"],"wrapper")}>
                    <h4>Security</h4>
                    <div style={{ marginBottom: 10 }}>
                        <NavLink style={{marginTop: 5}} className="cadet-anchor" to="two_factor_auth" id={phenomId.gen("security","two-factor-authentication-link")}>setup two factor
                            authentication</NavLink>
                    </div>
                    <div>
                        <Button 
                        id={phenomId.gen("change","password")}
                        onClick={() => this.setState({ showChangePasswordModal: true })}>Change password</Button>
                    </div>
                </section>

                <PhenomModal show={this.state.showChangePasswordModal}
                             onSave={this.saveMyPassword}
                             onClose={() => this.setState({ showChangePasswordModal: false })}>
                    <ChangeMyPassword ref={el => this.changePasswordRef = el} />
                </PhenomModal>
            </main>
        );
    }
}
