import React from "react";
import { CadetInput, CadetTextArea, TypeAutoFill, BoundsPicker } from "../../util/stateless";
import AEPathBuilder from "./ae-path-builder";
import SpecializationBuilder from "./specialization-builder";
import {NavLink} from "react-router-dom";
import { deGuidify, isPhenomGuid} from "../../util/util";
import deprecated from "../../../images/deprecated_hover.png";
import AttrMove from "../../dialog/attr_move";
import moveIcon from "../../../images/tree icons/move_ltgray.png";
import PhenomId from "../../../requests/phenom-id";
import commitIcon from "../../../images/edit_icons/commit.png";
import editIcon from "../../../images/edit.png";
import { Button } from "@progress/kendo-react-buttons";


export class EntityChild extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            guid: "",
            parent_id: "",
            xmiType: "",
            rolename: "",
            description: null,
            type: {guid: "", name: "", xmiType: ""},
            pathPairs: [],
            projectors: [],
            showViews: false,
            specializes: "",
            modalShow: false,
            editType: false
        };
        this.domRef = React.createRef();
        this.originalRes = "";
        this.poppedPathHops = {};
    }

    componentDidMount() {
        this.originalRes = JSON.stringify(this.props.element);
        if (this.props.element) {
            this.setStateFromResponse(this.props.element);
        }
        this.scrollToAttribute();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.element) {
            if (this.props.element && this.props.new) {
                this.setStateFromResponse(this.props.element);
            }
        }
        if (!this.props.new) {
            if (this.props.element.guid !== prevProps.element.guid ||
                this.props.element.name !== prevProps.element.name ||
                this.props.element.description !== prevProps.element.description ||
                this.props.element.type.guid !== prevProps.element.type.guid) {
                this.setStateFromResponse(this.props.element);
            }
        }
        if (this.state.showViews !== prevState.showViews) {
            this.props.getSpecTrace(undefined, undefined, false);
        }
        if (this.state.specializes && this.state.specializes !== prevState.specializes) {
            if (this.props.curSpecSrc === this.state.guid) {
                this.props.getSpecTrace(this.state.guid, this.state.specializes.guid, false);
            } else {
                this.props.getSpecTrace(undefined, undefined, false);
            }
        }
        if (prevProps.scrollToGuid !== this.props.scrollToGuid){
            this.scrollToAttribute();
        }
    }

    scrollToAttribute = () => {
        if(!this.domRef.current || !this.props.element?.guid || !this.props.scrollToGuid) return;
        if(this.props.element.guid === this.props.scrollToGuid){
            setTimeout( () => {
                this.domRef.current.scrollIntoView();
            }, 0);
        }
    }

    setStateFromResponse(response) {
        const isAssoEnt = response.xmiType === "conceptual:AssociatedEntity";
        this.setState({
            guid: response.guid,
            parent_id: response.parent_id,
            rolename: response.rolename,
            xmiType: response.xmiType,
            description: (response.description || null),
            type: response.type,
            path: response.path || "",
            pathPairs: isAssoEnt && !isPhenomGuid(response.guid) && !this.props.readonly ? this.connectifyPathPairs(response.pathPairs, response.type.guid) : [],
            options: [],
            projectors: response.projectors,
            showViews: false,
            specializes: response.specializes,
            lowerBound: response.lowerBound || null,
            upperBound: response.upperBound || null,
            sourceLowerBound: response.sourceLowerBound ? response.sourceLowerBound : isAssoEnt ? null : null,
            sourceUpperBound: response.sourceUpperBound ? response.sourceUpperBound : isAssoEnt ? null : null,
            editType: false
        });
        response.description = (response.description || "");
        this.originalRes = {...this.props.originalChild};
    }

    connectifyPathPairs(pathPairs, parent_type_guid) {
        pathPairs.forEach((path_pair, idx) => {
            const prev_pair = idx === 0 ? null : pathPairs[idx - 1];
            const local_parent_type_guid = idx === 0 ? parent_type_guid : prev_pair.connector === "." ? prev_pair.type.guid : prev_pair.parent.guid;

            if (path_pair.parent.guid === local_parent_type_guid) {
                path_pair.connector = ".";
            } else {
                path_pair.connector = "->";
            }
        });
        return pathPairs;
    }


    getStateIfChanged = () => {
        if ((isPhenomGuid(this.state.guid) ||
             this.isChanged())) {
            const childData = {
                guid: this.state.guid,
                rolename: this.state.rolename,
                description: this.state.description,
                xmiType: this.state.xmiType,
                type: this.state.type.guid,
                specializes: this.state.specializes ? this.state.specializes.guid : "",
                lowerBound: this.state.lowerBound,
                upperBound: this.state.upperBound,
                sourceLowerBound: this.state.sourceLowerBound,
                sourceUpperBound: this.state.sourceUpperBound,
            };

            if (this.state.xmiType === "conceptual:AssociatedEntity") {
                childData["path"] = this.state.pathPairs.map(pathPair => pathPair.guid).join(" ");
            }
            return {data: childData};
        } else {
            return false;
        }
    };

    getState = () => {
        const childData = {
            guid: this.state.guid,
            rolename: this.state.rolename,
            description: this.state.description,
            xmiType: this.state.xmiType,
            type: this.state.type.guid,
            specializes: this.state.specializes ? this.state.specializes.guid : "",
            lowerBound: this.state.lowerBound,
            upperBound: this.state.upperBound,
            sourceLowerBound: this.state.sourceLowerBound,
            sourceUpperBound: this.state.sourceUpperBound,
        };

        if (this.state.xmiType === "conceptual:AssociatedEntity") {
            childData["path"] = this.state.pathPairs.map(pathPair => pathPair.guid).join(" ");
        }

        return childData;
    };

    isChanged = () => {
        const original = this.originalRes;

        return original.rolename !== this.state.rolename ||
               original.description !== this.state.description ||
               (this.state.specializes && (!original.specializes || original.specializes.guid !== this.state.specializes.guid)) ||
               (this.state.xmiType === "conceptual:AssociatedEntity" && this.state.path.trim() !== this.state.pathPairs.map(path_pair => path_pair.guid).join(" ")) ||
               original?.lowerBound !== this.state?.lowerBound ||
               original?.upperBound !== this.state?.upperBound ||
               original?.sourceLowerBound !== this.state?.sourceLowerBound ||
               original?.sourceUpperBound !== this.state?.sourceUpperBound ||
               original?.type.guid !== this.state.type.guid;
    }

    typeChanged = () => {
        const original = this.originalRes ? this.originalRes : {};

        return !this.props.new && original?.type?.guid !== this.state.type.guid;
    }

    showTypeEditBtn = () => {
        const original = this.originalRes.length ? this.originalRes : {};
        return !this.props.new && this.props.canEdit && !this.state.projectors?.length &&
               !original.specializedBy?.length && !original.specializes && !this.state.pathPairs.length;
    }

    clearIncompletePaths() {
        const pprs = this.state.pathPairs;

        if (this.state.xmiType === "conceptual:AssociatedEntity" &&
            pprs.length &&
            pprs[pprs.length - 1].type.xmiType !== "conceptual:Observable") {
            this.state.pathPairs = [];
            this.setState({pathPairs: []});
        }
    }

    popPathHop = () => {
        const poppedHop = this.state.pathPairs.pop();

        this.poppedPathHops[poppedHop.guid] = poppedHop;
        this.forceUpdate();
    };

    pushPathHop = hop => {
        this.state.pathPairs.push(hop);
        this.forceUpdate();
    };

    resetPath = () => {
        if (!this.state.path) {
            this.setState({pathPairs: []});
            return;
        }

        const pathGuids = this.state.path.trim().split(" ");
        const hopsMap = {...this.poppedPathHops, ...deGuidify(this.state.pathPairs)};
        const newPathPairs = [];

        pathGuids.forEach(pathGuid => newPathPairs.push(hopsMap[pathGuid]));
        this.setState({pathPairs: newPathPairs});
    };

    changeState = e => {
        const key = e.target.id.match("rolename|description")[0];
        const value = e.target.value;
        this.setState({[key]: value});
        this.props.element[key] = value;
    };

    reverseBound = (value) => {
        if(value === "*") return "-1";
        return value;
    }

    convertBound = (value) => {
        if(value === "-1") return "*";
        return value;
    }

    createBoundText(lower, upper) {
        return <span>{lower} .. {this.convertBound(upper)}</span>;
    }

    setBound = (val1,val2,selector1,selector2) => {
        this.setState({[selector1]: val1, [selector2]: val2});
        this.props.element[selector1] = val1;
        this.props.element[selector2] = val2;
    }

    onDragStart = (e, attr) => {
        e.dataTransfer.setDragImage(e.target.parentElement, 0, 35);
        this.props.onDragStart(attr);
      }

    render() {
        const { draggable, hideDrag, isSorting, canEdit } = this.props;
        const type_type = this.state.type.xmiType.substr(this.state.type.xmiType.indexOf(":") + 1).replace("Association", "Entity");
        const DescriptionComponentTag = this.props.truncations && this.props.truncations.descriptions ? CadetInput : CadetTextArea;
        const phenomId = new PhenomId(this.props.idCtx);
        const typeChanged = this.typeChanged();

        return (
            <React.Fragment>
                {canEdit && !hideDrag && 
                    <td ref={this.domRef}
                        style={{textAlign:"center", cursor: (draggable ? "grab" : "")}} 
                        draggable={draggable} 
                        onDragStart={(e) => {
                            if (!draggable) {
                                e.preventDefault();
                                return;
                            }
                            this.onDragStart(e, this.props.element);
                        }}>
                        {!isSorting && <span className="fas fa-grip-dots-vertical" style={{fontSize: "15px"}}/>}
                    </td>}
                <td >
                <div style={{"display" : "flex"}}>
                  {this.props.element.deprecated === "true" ? <img src={deprecated} style={{"height" : "20px"}}/> : null}
                    <CadetInput
                        disabled={!this.props.canEdit}
                        text={this.state.rolename}
                        placeholder="enter new rolename"
                        idCtx={phenomId.genPageId("rolename")}
                        onChange={this.props.readonly ? null : this.changeState}
                    />
                </div>
                </td>

                <td>
                    <DescriptionComponentTag
                        disabled={!this.props.canEdit}
                        text={this.state.description}
                        title={this.state.description}
                        id={phenomId.genPageId("description")}
                        onChange={this.props.readonly ? null : this.changeState}
                    />
                </td>

                {this.props.isGenViewChild === true &&
                    <td>
                        <div style={{"display" : "flex"}}>
                            <CadetInput
                                disabled={!this.props.canEdit}
                                text={this.state.xmiType === "conceptual:Composition" ? "Attribute" : "Paricipant"}
                                idCtx={phenomId.genPageId("category")}
                            />
                        </div>
                    </td>}

                {(this.state.xmiType !== "conceptual:AssociatedEntity" || this.props.isGenViewChild !== undefined) || <td>
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center", padding:2}}>
                        <BoundsPicker ele={this.state}
                                      lowerSelector="sourceLowerBound"
                                      upperSelector="sourceUpperBound"
                                      idCtx={phenomId.gen("","source")}
                                      setBound={this.setBound}
                                      disabled={!this.props.canEdit}
                                      editableBounds={true}/>
                    </div></td> || <td/>}
                {this.props.isGenViewChild !== undefined ||
                    <td>
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center", padding:"2px 5px"}}>
                        <BoundsPicker ele={this.state}
                                      lowerSelector="lowerBound"
                                      upperSelector="upperBound"
                                      idCtx={phenomId.gen("","target")}
                                      setBound={this.setBound}
                                      disabled={!this.props.canEdit}
                                      editableBounds={true}/>
                    </div></td>}
                    
                <td><div style={{"display" : "flex"}}>{this.state.type.deprecated === "true" ? <img src={deprecated} style={{"height" : "20px"}}/> : null}

                {(!this.props.new && !this.state.editType) ?
                <NavLink className="cadet-anchor" 
                         id={phenomId.genPageId("type")}
                         style={{maxWidth: "85%", textOverflow: "ellipsis", overflow: "hidden"}}
                         to={`/edit/details/${type_type}/${this.state.type.guid}`}>
                            {this.state.type.name}
                </NavLink>
                :
                <TypeAutoFill
                    style={{width: "100%", fontSize: "12px"}}
                    data={this.props.typeOptions}
                    textField="name"
                    value={this.state.type}
                    idCtx={phenomId.genPageId()}
                    showAll={false}
                    addTypeIdent={true}
                    onChange={(evt) => {
                        const selectMatch = this.props.typeOptions.find(e => e === evt.target.value);
                        if (selectMatch) {
                            this.setState({
                                type: selectMatch
                            });
                            this.props.element.type = selectMatch;
                        }  else { 
                            this.setState({
                                type: {
                                    guid: "",
                                    name: evt.target.value,
                                    xmiType: "",
                                }
                            });
                    }
                    }}/>}

                {this.showTypeEditBtn() && <button
                    className="edit-specialization"
                    id={phenomId.gen("edit-type","btn")}
                    style={{
                        backgroundImage: this.state.editType ? `url(${commitIcon})` : `url(${editIcon})`,
                        position: 'relative',
                        marginLeft: 'auto'
                    }}
                    onClick={() => this.setState({editType: !this.state.editType})}/>}
                </div></td>

                {this.state.xmiType !== "conceptual:Composition" && !this.props.readonly && (!isPhenomGuid(this.state.guid) &&
                    <td className="flex-v" style={{position: "relative", minHeight: 30}} id={phenomId.gen("", "path")}>
                        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <AEPathBuilder
                        canEdit={!typeChanged && this.props.canEdit}
                        pathPairs={this.state.pathPairs.slice()}
                        parentType={this.state.type}
                        popPathHop={this.popPathHop}
                        pushPathHop={this.pushPathHop}
                        resetPath={this.resetPath}
                        canEditPath={!this.state.projectors.length}
                        parentAEGuid={this.state.guid}
                        idCtx={phenomId.genPageId()}
                    /></div></td> || <td/>)}

                {this.props.new || typeChanged ? <td/> : <td>
                    {!this.state.projectors.length || <div className="flex-v" style={{alignItems: "center"}}>
                        <a className="cadet-anchor"
                           id={phenomId.gen("projectors","show")}
                           onClick={() => this.setState(prevState => ({showViews: !prevState.showViews}))}>{(!this.state.showViews ? "Show Projector List" : "Hide Projector List")}</a>
                        <div className="view-list-container" id={phenomId.gen("view-list","wrapper")}>
                            <ul className={(this.state.showViews ? "view-list-down" : "view-list")}>
                                {Object.keys(this.state.projectors).map((guid, idx) => {
                                    const projection = this.state.projectors[guid];
                                    const pageType = projection.xmiType === "platform:View" ? "view" : "entity";
                                    return (
                                        <li key={idx} id={phenomId.gen("view-list",`${idx}`)}>
                                            <NavLink className="cadet-anchor"
                                                     to={`/edit/details/${pageType}/${projection.guid}`}>
                                                {projection.name}
                                            </NavLink>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>}
                </td>}

                {(this.props.isGenViewChild === undefined) && (this.state.specializes !== undefined ?
                    <SpecializationBuilder getSpecializationTrace={this.props.getSpecTrace}
                                           typeGuid={this.state.type.guid}
                                           parentGuid={this.props.genParentGuid}
                                           guid={this.state.guid}
                                           idCtx={phenomId.gen("")}
                                           allowEdit={(!typeChanged && this.props.allowSpecializationEdit && this.props.canEdit) || false}
                                           specializes={this.state.specializes || {
                                               rolename: "",
                                               type: "",
                                               specializes: null,
                                               specializedBy: null,
                                               guid: "",
                                               xmiType: "conceptual:Composition",
                                               parent: ""
                                           }}
                                           possibleSpecializations={this.props.possibleSpecializations}
                                           updateSpecialization={(update) => {
                                               this.setState({
                                                   specializes: update
                                               });
                                           }}/> : <td/>)}

                {!typeChanged && this.state.xmiType === "conceptual:Composition" && this.props.canEdit &&
                    <td style={{textAlign:"center"}} id={phenomId.gen("move")}>
                        <img src={moveIcon}
                             style={{cursor:"pointer"}}
                             onClick={() => AttrMove.show({
                                guid: this.state.guid,
                                name: this.state.rolename,
                                parentGuid: this.state.parent_id
                            })} /></td>}

                {this.props.canEdit &&
                    <td style={{textAlign:"center"}} id={phenomId.gen("move")}>
                        <Button icon="trash"
                                title="Deprecate"
                                onClick={this.props.deleteChild} />
                    </td>}


            </React.Fragment>
        );
    }
}
