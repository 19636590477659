import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { PHENOM_URL } from '../../global-constants'
import EventLog from '../../components/widget/EventLog'

import dashboardNavIcon from "../../images/dashboard.png";
import navigateNavIcon from "../../images/navigate.png";
import generateNavIcon from "../../images/generate.png";
import manageNavIcon from "../../images/manage2.png";
import eventLogNavIcon from "../../images/eventLog.png";
import LinkIcon from "../icons/LinkIcon";


const Navbar = (props) => {
  const authenticated = useSelector((state) => state.user.authenticated);
  const notice = useSelector((state) => state.notification.notice);
  const isEventLogVisible = useSelector((state) => state.notification.isEventLogVisible);
  const [countErrors, setCountErrors] = useState(0);

  useEffect(() => {
    if (!authenticated || isEventLogVisible) {
      countErrors && setCountErrors(0);

    } else if (notice.errors) {
      let numErrors = Math.min(countErrors + notice.errors.length, 999);
      setCountErrors(numErrors);
    }
  }, [isEventLogVisible, notice, authenticated]);


  if(!authenticated) {
    return null;
  }

  return <nav id="phenom-modes" className="navigation-container" aria-label='modes'>
          <ul className='primary-modes' role="primary modes"  >
            <li>
              <NavLink to={PHENOM_URL["dashboard"]} className="mode-link" activeClassName="active">
                <img className="navbar-icon" src={dashboardNavIcon} alt="" />
                <span>Dashboard</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={PHENOM_URL["edit"]} className="mode-link" activeClassName="active">
                <img className="navbar-icon" src={navigateNavIcon} alt="" />
                <span>Data Model</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={PHENOM_URL["integration"]} className="mode-link" activeClassName="active">
                <LinkIcon className="navbar-icon" />
                <span>Integration Model</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={PHENOM_URL["generate"]} className="mode-link" activeClassName="active">
                <img className="navbar-icon" src={generateNavIcon} alt="" />
                <span>Generate</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={PHENOM_URL["manage"]} className="mode-link" activeClassName="active">
                <img src={manageNavIcon} alt="" />
                <span>Manage Models</span>
              </NavLink>
            </li>
          </ul>

          <ul className="secondary-modes" role="secondary modes">
            <li className='mode-event-log-container'>
              <button className="mode-link"
                      onClick={EventLog.toggle}>
                <img src={eventLogNavIcon} alt="" />
                <span>Event Log</span>
              </button>
              {countErrors > 0 &&
                <span className="perfect-circle event-log-dot" role='number of errors'>{countErrors}</span>}
            </li>
          </ul>
        </nav>
}




export default Navbar;