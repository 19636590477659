import ImportCSV from "./import/import_csv";
import AddPlaceholders from "./import/add_placeholders";
import React from "react";
import {NavLink, Redirect, Route, Switch} from "react-router-dom";
import Merge from "../manage/branch/merge";
import { connect } from "react-redux";
import NavTree from "../tree/NavTree";
import { routeUrlBuilder } from "../../routes";
import { SubMenuRight } from "../edit/edit-top-buttons";
import { KbButton } from "../util/stateless";

class Import extends React.Component {
    componentDidMount() {
        NavTree.collapseNavTree(true);
        NavTree.assignPresetPageFilters("data");
    }

    componentWillUnmount() {
      NavTree.clearPageFilters();
    }

    render() {
        return (
            <div className="phenom-content-wrapper">  
                  <nav className="sub-menu-actions">
                  {this.props.canEdit && !this.props.expired &&
                  <>
                    <NavLink
                        title="Import CSV"
                        to="/edit/import/import_csv"
                        activeClassName="active">
                        CSV
                    </NavLink>
                    <NavLink
                        title="Add Placeholders"
                        to="/edit/import/add_placeholders"
                        activeClassName="active">
                        PLACEHOLDERS
                    </NavLink>
                    <NavLink
                        title="Merge External Model"
                        to="/edit/import/merge"
                        activeClassName="active">
                        MERGE EXTERNAL MODEL
                    </NavLink>
                  </>}
                    <SubMenuRight> 
                      <KbButton /> 
                    </SubMenuRight> 
                  </nav> 
                <Switch>
                  <Route path={routeUrlBuilder("edit", "import", "import_csv")}  
                         component={ImportCSV}/>
                  <Route path={routeUrlBuilder("edit", "import", "add_placeholders")} 
                         component={AddPlaceholders}/>
                  <Route path={routeUrlBuilder("edit", "import", "merge")} 
                         component={Merge} />
                  <Redirect to="/edit/import/import_csv" />
                </Switch>
                <div id="success-status"/>
            </div>
        );
    }
}


const msp = (state) => ({
  canEdit: state.user.canEdit,
  expired: state.user.expired,
})

export default connect(msp)(Import);
