import React from 'react'
import { NodeComboBox, PackageComboBox, PhenomComboBox, PhenomInput, PhenomTextArea } from '../util/stateless';
import { VtuManager } from '../util/vtu_picker';
import ChangeSetPicker from '../widget/ChangeSetPicker';
import { NodeHistory2 } from './node-history';
import { withNestedLayout, withPageLayout } from "./node-layout";
import { isPhenomGuid, validateNodeFields } from '../util/util';
import { cloneDeep } from 'lodash';
import PhenomId from "../../requests/phenom-id";



export class MeasurementAxisManager extends React.Component {
  static defaultProps = {
    nodeAddenda: {
      coreAddenda: ["measurementSystemAxis", "valueTypeUnitMULTI"],
      valueTypeUnitAddenda: ["childrenMULTI"],
    },
    newNode: {
      name: "",
      xmiType: "logical:MeasurementAxis",
      description: "",
      precision: "",
      realizes: "",
      measurementSystemAxis: {},
      valueTypeUnit: [],
      parent: "",
      subModelId: undefined,
    },
    nodesOfTypeAddenda: {
      "logical:MeasurementSystem": {
        type: ["logical:MeasurementSystem", "logical:StandardMeasurementSystem"],
        coreAddenda: ["measurementSystemAxisMULTI"],
      }
    },
  }

  constructor(props) {
    super(props);
    this.phenomId = new PhenomId("measurement-axis", this.props.id);
  }

  // ------------------------------------
  // State
  // ------------------------------------
  vtuRef = React.createRef();
  defaultState = {
    isEnumerated: false,

    measurementSystemAxisList: [],
    errorMsgMSA: "",
  }
  state = cloneDeep(this.defaultState);

  // "required" checks for empty field as the input changes (user is typing)
  // "errorRef" checks for empty field after pressing the save button
  requiredFields = {
    name: {
      required: true,
      checkFirstChar: true,
      checkAllChars: true,
      errorRef: React.createRef(),
    },
    realizes: {
      required: true,
      errorRef: React.createRef(),
    },
    parent: {
      required: true,
      errorRef: React.createRef(),
    },
    measurementSystemAxis: {
      required: true,
      errorRef: React.createRef(),
    },
  }

  // ------------------------------------
  // Life Cycle Methods
  // ------------------------------------
  componentDidMount() {
    this.initNodeState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.node !== this.props.node) {
      this.initNodeState();

    } else if (prevProps.nodesOfType !== this.props.nodesOfType ||
               prevProps.parentMeasurementSystem !== this.props.parentMeasurementSystem) {
      // This component is nested by the Measurement and receives a list of MSAs
      //    - Also receives the MS guid from measurement to down select the list of MSAs
      this.initMeasurementSystemAxisList();
    }

    if (prevProps.parentObservable !== this.props.parentObservable) {
      this.setState({ realizes: this.props.parentObservable })
    }

    if (prevProps.parentPackage !== this.props.parentPackage && isPhenomGuid(this.state.guid)) {
      this.setState({ parent: this.props.parentPackage })
    }

    if (prevProps.parentEnumerated !== this.props.parentEnumerated) {
      this.setState({ isEnumerated: this.props.parentEnumerated })
    }
  }

  // ------------------------------------
  // Initial Setup
  // ------------------------------------
  initNodeState = () => {
    const { node, parentPackage, parentEnumerated } = this.props;
    const parent = isPhenomGuid(node.guid) && parentPackage ? parentPackage : node.parent;
    
    this.setState({
      ...cloneDeep(this.defaultState),
      ...node,
      parent,
      isEnumerated: !!parentEnumerated,
    }, () => {
      this.initMeasurementSystemAxisList();
    })
  }

  // fetch Measurement System to limit the MSA list
  initMeasurementSystemAxisList = () => {
    const currMSAGuid = this.state.measurementSystemAxis?.guid;
    let measurementSystems = this.props.nodesOfType["logical:MeasurementSystem"] || [];
    let measurementSystemAxisList = [];
    let errorMsgMSA = "";

    // 1) Nested by Measurement Page
    if (typeof this.props.parentMeasurementSystem === "string") {
      let measurementSystem = measurementSystems.find(ms => ms.guid === this.props.parentMeasurementSystem);

      // get MSA list from MS. a StandardMS will have empty MSA list
      if (Array.isArray(measurementSystem?.measurementSystemAxis)) {
        measurementSystemAxisList = measurementSystem.measurementSystemAxis;
      }

      // the measurement system changed, check for mismatch
      if (currMSAGuid) {
        const searchMSA = measurementSystemAxisList.find(msa => msa.guid === currMSAGuid);
        if (!searchMSA) errorMsgMSA = "the selected measurement system does not reference this measurement system axis";

      } else {
        // if selected MSA is empty then select first element from list
        if (measurementSystemAxisList.length) {
          return this.setState({ measurementSystemAxis: measurementSystemAxisList[0], measurementSystemAxisList, errorMsgMSA });
        }
      }

      return this.setState({ measurementSystemAxisList, errorMsgMSA });
    }

    // 2) Standalone MA page
    // concat all MSAs
    for (let ms of measurementSystems) {
      if (!ms.measurementSystemAxis) continue;

      for (let msa of ms.measurementSystemAxis) {
        // prevent duplicates
        if ((currMSAGuid && currMSAGuid !== msa.guid) || measurementSystemAxisList.find(ele => ele.guid === msa.guid)) {
              continue;
        }
        measurementSystemAxisList.push(msa);
      }
    }

    this.setState({ measurementSystemAxisList });
  }

  // ------------------------------------
  // Getters
  // ------------------------------------


  // ------------------------------------
  // Setters
  // ------------------------------------
  setEnumerated = (bool) => {
    if (typeof bool !== 'boolean') return;
    this.setState({ isEnumerated: bool }, () => {
      this.props.setEnumerated && this.props.setEnumerated(bool);
    });
  }


  // ------------------------------------
  // Called by parent component
  // ------------------------------------
  handleReset = () => {
    this.initNodeState();
    this.vtuRef.current?.handleReset && this.vtuRef.current.handleReset();
  }

  validateNode = () => {
    let valid = validateNodeFields(this.requiredFields, this.state);

    if (this.vtuRef.current?.validateNode && !this.vtuRef.current.validateNode()) {
      valid = false;
    }

    return valid;
  }

  generateNode = () => {
    let vtu_nodes = [];

    if (this.vtuRef.current) {
      vtu_nodes = this.vtuRef.current.generateNode().map((node) => {
        return isPhenomGuid(node.guid) ? node : node.guid;
      });
    }

    return {
      guid: this.state.guid,
      name: this.state.name,
      description: this.state.description,
      precision: this.state.precision,
      xmiType:"logical:MeasurementAxis",
      measurementSystemAxis: this.state.measurementSystemAxis?.guid,
      realizes: this.state.realizes,
      valueTypeUnit: vtu_nodes,
      parent: this.state.parent || undefined,
      subModelId: this.state.subModelId,
    }
  }


  // ------------------------------------
  // Render Methods
  // ------------------------------------
  render() {
    const { editable } = this.props;
    const { valueTypeUnit, isEnumerated } = this.state;
    const phenomId = this.phenomId;
    const original = this.props.node;

    const title = isEnumerated ? "Enumerated Measurement Axis" : "Measurement Axis";

    return <div className="edit-form">
            <div className="p-row">
              <div className="p-col">
                <PhenomInput label={title}
                            value={this.state.name}
                            originalValue={original["name"]}
                            disabled={!editable}
                            autoFocus={true}
                            id={phenomId.gen("", "name")}
                            onChange={(e) => this.setState({ name: e.target.value })}
                            onClickResetIcon={() => this.setState({ name: original["name"] })}
                            config={this.requiredFields["name"]} />

                <PhenomTextArea label="Description"
                            value={this.state.description}
                            originalValue={original["description"]}
                            disabled={!editable}
                            id={phenomId.gen("", "description")}
                            onChange={(e) => this.setState({ description: e.target.value })}
                            onClickResetIcon={() => this.setState({ description: original["description"] })} />
              </div>

              <div className="edit-side">
                <NodeHistory2 guid={this.state.guid} 
                              ref={ele => this.historyRef = ele} />
                {/* <ChangeSetPicker label="Change Set" /> */}
              </div>
            </div>

            <div className="p-row">
              {!isEnumerated &&
              <div className="p-col p-col-4">
                <NodeComboBox label="Observable"
                              xmiType="conceptual:Observable"
                              selectedGuid={this.state.realizes}
                              originalGuid={original["realizes"]}
                              disabled={!editable}
                              id={phenomId.gen("", "realizes")}
                              onChange={(observable) => this.setState({ realizes: observable.guid })}
                              onClickResetIcon={() => this.setState({ realizes: original["realizes"] })}
                              config={this.requiredFields["realizes"]} />
              </div> }

              <div className="p-col p-col-4">
                <PhenomInput label="Precision"
                              value={this.state.precision}
                              originalValue={original["precision"]}
                              disabled={!editable}
                              id={phenomId.gen("", "precision")}
                              onChange={(e) => this.setState({ precision: e.target.value })}
                              onClickResetIcon={() => this.setState({ precision: original["precision"] })} />
              </div>

              <div className="p-col p-col-4">
                <PackageComboBox label="Parent Package"
                                  xmiType="face:LogicalDataModel"
                                  nodeGuid={this.state.guid}
                                  selectedGuid={this.state.parent}
                                  originalGuid={original["parent"]}
                                  disabled={!editable}
                                  id={phenomId.gen("", "parent")}
                                  onChange={(parent) => this.setState({ parent: parent.guid })}
                                  onClickResetIcon={() => this.setState({ parent: original["parent"] })}
                                  config={this.requiredFields["parent"]} />
              </div>
            </div>
            
            {!isEnumerated &&
            <PhenomComboBox label="Measurement System Axis"
                            value={this.state.measurementSystemAxis}
                            originalValue={original["measurementSystemAxis"]}
                            data={this.state.measurementSystemAxisList}
                            dataItemKey="guid"
                            disabled={!editable}
                            id={phenomId.gen("", "measurementSystemAxis")}
                            customErrorMsg={this.state.errorMsgMSA}
                            onChange={(measurementSystemAxis) => this.setState({ measurementSystemAxis })}
                            onClickResetIcon={() => this.setState({ measurementSystemAxis: original["measurementSystemAxis"] })}
                            config={this.requiredFields["measurementSystemAxis"]} /> }

            <div>
              <VtuManager label="Value Type Unit"
                          vtus={valueTypeUnit}
                          editable={editable}
                          id={phenomId.gen("")}
                          parentNewPage={this.props.parentNewPage ?? isPhenomGuid(this.state.guid)}
                          parentName={this.props.parentName || this.state.name}
                          parentEnumerated={isEnumerated}
                          parentMeasurementSystemAxis={this.state.measurementSystemAxis?.guid}
                          parentPackage={this.state.parent}
                          setEnumerated={this.setEnumerated}
                          ref={this.vtuRef} />
            </div>
          </div>
  }
}




export const EditMeasurementAxisManager = withPageLayout(MeasurementAxisManager);
export const NestedMeasurementAxis = withNestedLayout(MeasurementAxisManager);