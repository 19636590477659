// PROPS: pre-selected observable guid - obsGuid (string)
//        observable selection function - setObs (f)

import React from "react";
import {getNodesOfType} from "../../requests/sml-requests";
import {deGuidify, sortNodesByName} from "./util";
import PhenomId from "../../requests/phenom-id";
import deprecatedSrc from "../../images/deprecated_hover.png";
import {TypeAutoFill} from "./stateless"

export class Observables extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            observables: [],
            observable: {},
        };
    }

    componentDidMount() {
        getNodesOfType(["conceptual:Observable"]).then((list) => {
            const resp = list.data;
            this.setState({observables: resp.nodes.sort(sortNodesByName)});
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.obsGuid !== prevProps.obsGuid || this.state.observables.length !== prevState.observables.length) {
            this.setState({observable: this.state.observables.find(obs => obs.guid == this.props.obsGuid) || {}});
        }
    }

    render() {
        const phenomId = new PhenomId("observables",this.props.idCtx);
        return (
            <div
              id={phenomId.gen("","wrapper")}
              style={{padding: "5px 0px 5px 0px"}}>
                {!!this.state.observables.length &&
                  <TypeAutoFill
                    style={{width: "150px", fontSize: "12px"}}
                    data={this.state.observables}
                    textField="name"
                    value={this.state.observable}
                    idCtx={phenomId.gen("")}
                    showAll={false}
                    disabled={this.props.disabled}
                    onChange={(evt) => {
                        const selectMatch = this.state.observables.find(e => e === evt.target.value);
                        this.setState({ observable: selectMatch || {}});
                        this.props.setObs(selectMatch?.guid);
                    }}
                    addTypeIdent={false}/>}
            </div>
        );
    }
}


// <select className="cadet-select"
//         onChange={e => this.setState({observable: this.state.observables[e.target.value] || {}})}
//         value={this.state.observable.guid}
//         disabled={this.props.disabled}
//         id={phenomId.gen("","select")}>
//     <option value="" id={phenomId.gen(["init","default"],"option")}>No Observable</option>
//     {Object.values(this.state.observables).sort((a, b) => a.name.localeCompare(b.name)).map((observable, idx) => {
//         return <option value={observable.guid} key={idx} id={phenomId.gen(["init",idx],"option")}>{observable.name}</option>;
//     })}
// </select>
