import {CadetListSelect, LineLabel, PhenomLabel, PhenomSelect, KbButton} from "../util/stateless";
import React from "react";
import { connect } from "react-redux"
import { Checkbox } from '@progress/kendo-react-inputs';
import LoaderButton from "../widget/LoaderButton";
import PhenomId from "../../requests/phenom-id";
import { receiveErrors, receiveLogs, receiveResponse } from "../../requests/actionCreators"
import NavTree from "../tree/NavTree";
import ScratchpadExporter from "./diagrams_scratchpad";
import ViewTraceExporter from "./diagrams_viewtrace";
import {getDateTimeForFilename} from "../util/util";
import { _ajax } from "../../requests/sml-requests";
import { SubMenuRight } from "../edit/edit-top-buttons";




class ModelGen extends React.Component {
  containerRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            fileType: "face",
            faceType: "face3",
            generationArtifact: "model",
            reportBy: "Entire Model",
            templateOptions: ["loading..."],
            template: [],
            loading: false,
            include_tss_skeleton: false,
            config_only: false,
            namespace: "DSDM",
            system_name: "Test_System",

            scratchPadFiles: [],
            viewTraceFiles: [],
            published: false,

            process_cdm_queries: false,
            process_ldm_queries: false,
            recoverRetentionArtifacts: false,
        };

        this.uopOptions = null;

        this.available_options = this.getAvailableOptions();
        this.descriptions = {
            "cinc_im_from_uop": "Generate CinC content based on UoP",
            "diagrams": "All diagram files in PNG form.",
            "docx": "Microsoft Word ICD",
            "face": "A FACE conformant XMI model.",
            "face3": "A FACE 3.0-conformant XMI model. In order to ensure conformance, PHENOM will remove any integration model elements present in the model.",
            "face3.1": "A FACE 3.1 / 3.2-conformant XMI model. In order to ensure conformance, PHENOM will remove any integration model elements present in the model.",
            "face3_cinc_code": "FACE 3.0 CinC. Select your Main Program(s) and UoPs.",
            "face_obs": "An obfuscated FACE 2.1-conformant XMI model.",
            "idl": "Interface Documentation Language",
            "skayl": "A Skayl XMI model. For debug purposes only. Format is subject to change without notice.",
            "template": "Custom or built-in template files. Visit the 'Templates' tab to edit."};
        this.treeConcernaMap = {
            "View": "views",
            "Portable Component (UoP)": "uops",
            "Main_Program": "mps"
        };
        this.phenomId = new PhenomId("model-gen",this.props.idCtx);
    }

    REPORT_BY_LIST = [
      {
        reportBy: "Entire Model",
        text: "None",
      },
      {
        reportBy: "Portable Component (UoP)",
        text: "UoPs",
      },
      {
        reportBy: "View",
        text: "Views",
      },
    ]

    FACE_OPTIONS = [
      {
        value: "face3.1",
        text: "3.1 / 3.2",
      },
      {
        value: "face3",
        text: "3.0",
      },
    ]

    componentDidMount() {
        _ajax({
            url: "/index.php?r=/template/index",
            method: "get"
        }).then(res => this.setState({
            templateOptions: res.data.valid_templates,
            template: [res.data.valid_templates[0]]
        }));
        _ajax({
          url: "/index.php?r=/referencing-model/is-published",
          method: "get"
        }).then(res => this.setState({
            published: res.data.published
        }));
    }

    componentDidUpdate(_, prevState) {
        if (this.state.reportBy !== prevState.reportBy) {
            switch (this.state.reportBy) {
              case "Portable Component (UoP)":
                NavTree.assignPresetPageFilters("uops");
                break;
              case "View":
                NavTree.assignPresetPageFilters("views");
                break;
              case "Main_Program":
                NavTree.assignPresetPageFilters("mps");
                break;
              case "Entire Model":
              case "Tag_Filter":
                NavTree.clearPageFilters();
                break;

              default:
            }
        }
    }

    componentWillUnmount() {
      NavTree.clearPageFilters();
    }

    getAvailableOptions = () => {
        const expert_options = [
            {label: "FACE (.face)", value: "face"},
            // {label: "Diagrams (.zip)", value: "diagrams"},
            {label: "IDL (.idl)", value: "idl"},
            // {label: "FACE 3 CinC", value: "face3_cinc_code"},
            // {label: "Face3 CinC - Mock UoP", value: "cinc_im_from_uop"},
            {label: "Skayl (.skayl)", value: "skayl"},
            {label: "User Template", value: "template"},
            {label: "Diagrams", value: "sp-diagram"},
            {label: "View Traces", value: "vt-diagram"},
          ];

        // const cinc_works_options = expert_options.filter(op => 
        //     ["face3_cinc_code","cinc_im_from_uop"].includes(op.value));
        // const expired_options = expert_options.filter(op => 
        //     ["face","face3","face3.1"].includes(op.value));
        // const modeler_options = expert_options.filter(op => 
        //     ["idl","diagrams","face_obs","face3.1","face3","face"].includes(op.value));

        // if (this.props.expired) return expired_options;
        // else if (this.props.modeler_only) return modeler_options;
        // else if (this.props.cinc_works_only) return cinc_works_options;
        // else return expert_options;

        if (this.props.expired) {
          return expert_options.filter(op => ["face","face3","face3.1"].includes(op.value));
        } else if (this.props.modeler_only) {
          return expert_options.filter(op => ["idl","diagrams","face"].includes(op.value));
        } else {
          return expert_options;
        }
    }

    generationOptions = () => {
        if (this.state.generationArtifact === "model") {
            return this.available_options.filter(op => !(["face3_cinc_code","cinc_im_from_uop"].includes(op.value)));
        }
        if (this.state.generationArtifact === "cinc") {
            return this.available_options.filter(op => (["face3_cinc_code","cinc_im_from_uop"].includes(op.value)));
        }
    }

    requestGeneratedFile = () => {
        this.setState({loading: true});
        const { fileType, faceType, reportBy } = this.state;

        let template = [];
        switch (fileType) {
          case "sp-diagram":
          case "vt-diagram":
            return;
          
          case "template":
            template = this.selectionRef.getSelections();
            break;

          default:
        }

        const { useAndTags, includeTags, excludeTags } = NavTree.getTagFilters();
        const selectedForModelGen = NavTree.getSelectedForModelGen();
        const downloadFileType = fileType;
        let generationConfig = {include_tss_skeleton:this.state.include_tss_skeleton, 
            config_only:this.state.config_only,
            namespace:this.state.namespace !== "" ? this.state.namespace : "DSDM",
            system_name:this.state.system_name !== "" ? this.state.system_name : "Test_System"};

        const include_process = this.state.fileType === "face" && ["face3", "face3.1"].includes(this.state.faceType);
        return _ajax({
            url: "/index.php?r=/generate/generate-model-file",
            method: "post",
            data: {
                fileType: fileType === "face" ? faceType : fileType,
                reportBy,
                template,
                selectedNodes: selectedForModelGen,
                useAndTags: useAndTags,
                includeTags: reportBy === "Tag_Filter" ? [...includeTags].join(",") : "",
                excludeTags: reportBy === "Tag_Filter" ? [...excludeTags].join(",") : "",
                process_CDM_queries: include_process && this.state.process_cdm_queries,
                process_LDM_queries: include_process && this.state.process_ldm_queries,
                recoverRetentionArtifacts: this.state.recoverRetentionArtifacts,
                // config vars never change?
                generationConfig,
                regenerate: this.state.regenerate
            }
        }).then(response => {
            this.setState({loading: false});
            receiveResponse(response);

            if (response.data.filename) {
              receiveLogs("Model file generated.");
              const newName = `${this.props.userIdentity.branchName}_${downloadFileType}${getDateTimeForFilename()}.${response.data.filename.match(/(?!\.)\w+$/)}`;
              window.location.href = `${process.env.NODE_ENV === "development" ? "http://localhost" : ""}/index.php?r=/generate/download-file&file=${response.data.filename}&newName=${newName}`;
            }
        }).fail(err => {
          receiveErrors("An error occurred while trying to generate your model. Please contact Skayl support for more help.");
        })
    };

    // removes data:image/png;base64, from the dataURL string
    getBase64String(dataURL) {
      var idx = dataURL.indexOf('base64,') + 'base64,'.length;
      return dataURL.substring(idx);
    }

    changeFileType = (e) => {
      this.setState({ fileType: e.target.value }, () => {
        if (["sp-diagram", "vt-diagram"].includes(this.state.fileType)) {
          this.setState({ reportBy: "Entire Model" })
        }
      })
    }

    selectTemplates = e => {
        const selectedTemplates = [];
        for (const template of e.currentTarget.children) {
            if (template.selected) {
                selectedTemplates.push(template.value);
            }
        }
        this.setState({template: selectedTemplates});
    }

    renderGenerateButton = () => {
      const { fileType, published } = this.state;
      const phenomId = this.phenomId;

      if (["sp-diagram", "vt-diagram"].includes(fileType)) {
        return null;
      }

      return <div className="p-row">
              <div>
              {published &&
                <div> 
                  <Checkbox label="Force Regeneration"
                            checked={this.state.regenerate}
                            onChange={(event) => this.setState({regenerate: event.value})} />
                </div>}
                <LoaderButton 
                    id={phenomId.gen("init","generate-button")}
                    className='filled-button2'
                    text="GENERATE"
                    onClick={this.requestGeneratedFile}
                />
              </div>
            </div>
    }

    renderDescription = () => {
      const { fileType } = this.state;
      
      switch (fileType) {
        case "face":
          return <p>{ this.descriptions[this.state.faceType] }</p>
        case "sp-diagram":
        case "vt-diagram":
        return <ul>
                <li>Preview - open a single image in a new browser tab for preview</li>
                <li>Regenerate - rebuild a single image, save it, and then display it in a new browser tab for preview</li>
                <li>Download - download a single image</li>
              </ul>
        default:
          return <p>{ this.descriptions[fileType] }</p>
      }
    }

    render() {
        const phenomId = this.phenomId;
        const disableFilterBy = ["sp-diagram", "vt-diagram"].includes(this.state.fileType);
        const { params } = this.props.match;

        return (
            <div className="phenom-content-wrapper">
                <nav className="sub-menu-actions flex-end" aria-label='form actions' >
                  <SubMenuRight> 
                    <KbButton /> 
                  </SubMenuRight> 
                </nav>

                <div className="flex-container phenom-content-scrollable subview-wrapper">
                  <div className="p-row">
                    <div className="p-col">
                      <PhenomSelect label="Filter By"
                                    id={phenomId.gen(["init","reportBy"])}
                                    data={this.REPORT_BY_LIST}
                                    value={this.state.reportBy}
                                    dataItemKey="reportBy"
                                    textField="text"
                                    disabled={disableFilterBy}
                                    onChange={(e) => {
                                      this.setState({ reportBy: e.target.value })
                                    }}
                                    wrapperProps={{
                                      style:{ maxWidth: 300 },
                                    }} />
                    </div>
                  </div>

                  <div className="p-row">
                    <LoaderButton 
                        id={phenomId.gen("init","select-all-button")}
                        className='filled-button2'
                        text="SELECT ALL"
                        onClick={NavTree.selectAllForModelGen} />
                    <LoaderButton 
                        id={phenomId.gen("init","deselect-all-button")}
                        className='filled-button2'
                        text="DESELECT ALL"
                        onClick={NavTree.deselectAllForModelGen} />
                  </div>

                  <div className="p-row">
                    <div className="p-col p-col-4">
                      <PhenomSelect label="Export Type"
                                    data={this.generationOptions()}
                                    value={this.state.fileType}
                                    textField="label"
                                    dataItemKey="value"
                                    onChange={this.changeFileType}
                                    wrapperProps={{
                                      style:{ maxWidth: 300 },
                                    }} />

                      {this.state.fileType === "face" && ["face3", "face3.1"].includes(this.state.faceType) &&
                        <div className="p-col">
                          <Checkbox label="Export CDM Queries"
                                    checked={this.state.process_cdm_queries}
                                    onChange={() => this.setState((prevState) => ({ process_cdm_queries: !prevState.process_cdm_queries }))} />
                          <Checkbox label="Export LDM Queries"
                                    checked={this.state.process_ldm_queries}
                                    onChange={() => this.setState((prevState) => ({ process_ldm_queries: !prevState.process_ldm_queries }))} />
                          <Checkbox label="Recover Retention Artifacts"
                                    checked={this.state.recoverRetentionArtifacts}
                                    onChange={() => this.setState((prevState) => ({ recoverRetentionArtifacts: !prevState.recoverRetentionArtifacts }))} />
                        </div> }
                    </div>

                    {this.state.fileType === "face" &&
                      <div className="p-col p-col-4">
                        <PhenomSelect label="FACE Version"
                                      data={this.FACE_OPTIONS}
                                      value={this.state.faceType}pe
                                      textField="text"
                                      dataItemKey="value"
                                      onChange={(e) => {
                                        this.setState({ faceType: e.target.value })
                                      }}
                                      wrapperProps={{
                                        style:{ maxWidth: 300 },
                                      }} /> 
                      </div> }

                    <div className="p-col p-col-4">
                      <div>
                        <PhenomLabel text="Description" />
                        { this.renderDescription() }
                      </div>
                    </div>
                  </div>

                  { this.renderGenerateButton() }

                  {this.state.fileType === "template" && <div>
                      <LineLabel id={phenomId.gen(["fileType","template"],"")} text="Select your template(s)" />
                      <CadetListSelect data={this.state.templateOptions}
                                      style={{margin:"15px 0"}}
                                      width={500}
                                      title="Templates"
                                      ref={el => this.selectionRef = el} />
                  </div>}

                  {this.state.fileType === "sp-diagram" &&                     
                    <ScratchpadExporter /> }

                  {this.state.fileType === "vt-diagram" &&
                    <ViewTraceExporter /> }
                </div>
            </div>
        );
    }
}



const msp = (state) => {
  const { userRole="" } = state.user;

  return {
    userIdentity: state.user.userIdentity,
    expired: state.user.expired,
    modeler_only: state.user.modeler_only,
    expert_only: state.user.expertMode,
    cinc_works_only: userRole.indexOf('c') !== -1,
    includeTags: state.navTree.includeTags,
    excludeTags: state.navTree.excludeTags,
  }
}

export default connect(msp)(ModelGen);
