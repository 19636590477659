import React, { useState } from 'react'




const TreeSearch = (props) => {
  const [text, setText] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    props.onSubmit(text);
  }

  const handleToggle = (e) => {
    e.preventDefault();
    props.onResultToggle();
  }

  return <form className="tree-search" onSubmit={handleSubmit}>
            <input id={props.id ? `${props.id}-search-input` : null}
                   value={text}
                   autoComplete="off"
                   onChange={(e) => setText(e.target.value)}
                   placeholder="Search..." />
            <i id={props.id ? `${props.id}-search-submit-btn` : null} 
               className="fas fa-search"
               onClick={handleSubmit} />
            {!!props.searchText.length && <>
              <i className={"fas fa-stream" + (props.showSearchResultOnly ? " active" : "")}
                 onClick={handleToggle} />
              <span className='search-result'>{props.countMatches} found</span>
            </>}
         </form>

  // return <div className="phenom-search"
  //             onKeyPress={handleKeyPress}>
  //         <input className="phenom-search" 
  //                placeholder="Search..."
  //                onChange={(e) => setValue(e.target.value)} />
  //         <i className="fas fa-search"
  //            onClick={handleSubmit} />
  //       </div>
}


export default TreeSearch;