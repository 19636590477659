import { Upload } from '@progress/kendo-react-upload';
import React from 'react'
import { PhenomInput, PhenomLabel, PhenomTextArea } from '../../../util/stateless'
import PhenomId from "../../../../requests/phenom-id";
import { cloneDeep } from 'lodash';
import { ImportCheckList, QueryCheckList } from './ManageDetail';


export class ModelDetail extends React.Component {
  constructor(props) {
    super(props);
    this.phenomId = new PhenomId("model-detail", this.props.idCtx);
    this.state = cloneDeep(this.defaultState);
  }

  importCheckRef = React.createRef();
  queryCheckRef = React.createRef();

  defaultState = {
    name: "",
    description: "",
    files: [],
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    const {leaf, createPublishModel} = this.props;

    if (prevProps.leaf !== this.props.leaf) {
      this.init();
    }

    // append published to leaf name on publish ctx btn click
    if (prevProps.createPublishModel !== createPublishModel && createPublishModel === true) {
      this.setState({
        name: leaf.getName() + "_published",
      })
    }
  }

  init() {
    const { leaf } = this.props;
    if (!leaf) {
      return this.setState( cloneDeep(this.defaultState) );
    };

    this.setState({
      ...cloneDeep(this.defaultState),
      name: leaf.getName(),
      description: leaf.getDescription(),
    })
  }

  isEdited = () => {
    const { leaf } = this.props;

    return leaf.getName() !== this.state.name ||
           leaf.getDescription() !== this.state.description;
  }

  // serializes data from import and query checklist
  serialize = () => {
    const importData = this.importCheckRef.current?.state;
    const queryData = this.queryCheckRef.current?.getSerializeData();
    const checks = JSON.stringify({...importData, ...queryData[0]});
    const retainedTypes = JSON.stringify([...queryData[1]]);

    return {
      name: this.state.name,
      description: this.state.description,
      files: this.state.files,
      checks: checks,
      retained_types: retainedTypes,
    }
  }

  // serializes data from just import checklist
  serializeImport = () => {
    const importData = this.importCheckRef.current?.state;
    const checks = JSON.stringify({ ...importData });

    return {
      name: this.state.name,
      description: this.state.description,
      files: this.state.files,
      checks: checks,
    }
  }


  handleFileChange = (e) => {
    const modifiedState = e.newState;
    modifiedState[0].status = 1;

    this.setState({
        files: modifiedState
    });
  }

  handleCheckAllBox = (e) => {
    const checks = {}
    Object.keys(this.importCheckList).forEach(key => checks[key] = e.target.checked);
    this.setState({ ...checks });
  }

  renderDependencies() {
    const { leaf, modelIndex } = this.props;

    const dependencies = [];
    leaf.getDependencies().forEach(id => modelIndex[id] && dependencies.push(modelIndex[id]));

    return <ul style={{ padding: 0, margin: 0, listStyle: "none" }}>
              {dependencies.length > 0
                ? dependencies.map(dLeaf => <li key={dLeaf.getId()}>{ dLeaf.getName() }</li>)
                : <li>None</li>
              }
           </ul>
  }

  render() {
    const { leaf, createPublishModel } = this.props;
    if (!leaf) return null;

    return <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
              <PhenomInput label={createPublishModel ? "New Published Model" : "Model Name"}
                           value={this.state.name}
                           onChange={(e) => this.setState({ name: e.target.value })} />
              <PhenomTextArea label="Description"
                              value={this.state.description}
                              onChange={(e) => this.setState({ description: e.target.value })} />

              {leaf.isNewLeaf() ? 
                  <div className="p-row p-row-6">
                    <div className="p-col">
                      <Upload id="model-upload"
                                  onStatusChange={this.handleFileChange}
                                  multiple={false}
                                  files={this.state.files}
                                  defaultFiles={[]}
                                  restrictions={{
                                    allowedExtensions: [".face", ".skayl"],
                                    maxFileSize: 209715200
                                  }} />
                    </div>
                    <div className="p-col">
                      <div className="p-row manage-collasable">
                        <ImportCheckList ref={this.importCheckRef} />
                      </div>
                      <div className="p-row manage-collasable">
                        <QueryCheckList ref={this.queryCheckRef} />
                      </div>
                    </div>
                  </div>
                : <>
                    {leaf.isPublished() &&
                    <div>
                      <PhenomLabel text="Publication Date" />
                      <p>{ leaf.getPublishedDate() }</p>
                    </div> }

                    <div>
                      <PhenomLabel text="Dependencies" />
                      { this.renderDependencies() }
                    </div>
                </>
              }
          </div>
  }
}