import React, { Component } from "react";
import Helmet from "react-helmet";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import { Details } from "../components/navigate/details";
import StormManager from "../components/navigate/diagrams/manager/StormManager";
import * as actionThunks from "../requests/actionThunks"
import { routeUrlBuilder } from "../routes";


const subMenu = [
  {
    url: "/integration/details",
    text: "Details"
  },
  {
    url: "/integration/idm",
    text: "Diagram"
  },
]

class IntegrationModel extends Component {

    componentDidMount() {
      actionThunks.getActiveChangeSets();
    }

    render() {
        return (<>
            <Helmet>
                <title>Navigate Project</title>
            </Helmet>

            <section id="phenom-content" aria-label='content'>
              <nav className="sub-menu-pages" aria-label='sub menu'>
                  {subMenu.map((sub, idx) => (
                    <NavLink to={sub.url} key={idx} activeClassName="active">
                      {sub.text}
                    </NavLink>
                  ))}
              </nav>
              <Switch>
                <Route path={routeUrlBuilder("integration", "details", null, ":guid?", ":parentGuid?")}
                       component={Details}/>
                <Route path={routeUrlBuilder("integration", "idm")}
                       render={(props) => <StormManager stormType="idm_editor" 
                                                        {...props} />}/>
                <Redirect to="/integration/details" />
              </Switch>
            </section>
        </>);
    }
}





export default IntegrationModel;