import Draggable from "react-draggable";
import React from "react";
import {Grid, GridNoRecords} from "@progress/kendo-react-grid";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import $ from "jquery";
import PhenomId from "../../../requests/phenom-id"

export default class SpecRollupPopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isVisible: false,
            children: [],
            selected: []
        };
    }

    componentDidMount() {
        this.setupData();
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.setupData();
        }
    }

    setupData = () => {
        const newChildren = this.props.children;
        newChildren.forEach(el => {
            const specialization = this.props.possibleSpecializations.find(e => e.guid === el.specializes);
            if (!specialization) return;
            if (specialization && specialization.rolename === el.rolename) el.selected = true;
            el.specializationPath = `${specialization.parentName}.${specialization.rolename}`;
        });
        this.setState({
            children: newChildren
        }, this.gatherSelected);
    };

    openModal = () => {
        this.setState({
            isVisible: true
        });
    };

    closeModal = () => {
        this.setState({
            isVisible: false
        });
    };

    selectionChange = event => {
        event.dataItem.selected = !event.dataItem.selected;
        this.forceUpdate();
        this.gatherSelected();
    };

    headerSelectionChange = event => {
        const checked = event.syntheticEvent.target.checked;
        this.state.children.forEach(item => item.selected = checked);
        this.forceUpdate();
        this.gatherSelected();
    };

    rowClick = event => {
        let last = this.lastSelectedIndex;
        const current = this.state.children.findIndex(dataItem => dataItem === event.dataItem);

        if (!event.nativeEvent.shiftKey) {
            this.lastSelectedIndex = last = current;
        }

        if (!event.nativeEvent.ctrlKey) {
            this.state.children.forEach(item => item.selected = false);
        }
        const select = !event.dataItem.selected;
        for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
            this.state.children[i].selected = select;
        }
        this.forceUpdate();
        this.gatherSelected();
    };

    gatherSelected = () => {
        const selectedPorts = this.state.children.filter(e => e.selected);
        this.setState({selected: selectedPorts});
    };

    submit = () => {
        $.ajax({
            url: "/index.php?r=/edit/perform-rollup",
            method: "POST",
            data: {
                guids: this.state.selected.map(e => e.guid)
            },
            success: () => {
                this.props.refreshData();
            }
        });
    };

    render() {
        const phenomId = new PhenomId("spec-rollup",this.props.idCtx);
        return (<Draggable enableUserSelectHack={false} handle=".dialog-box-header" id={phenomId.gen("init","draggable")}>
            <div className="dialog-box"
                 style={{
                     zIndex: "105",
                     display: this.state.isVisible ? "block" : "none",
                     width: 450
                 }}>
                    <span className="dialog-box-header">Rollup Attributes
                        <button onClick={() => {
                            this.closeModal();
                        }}>X</button></span>
                <div style={{padding: "0px 15px"}}>
                    <Grid
                        id={phenomId.gen("init","grid")}
                        data={this.state.children}
                        className="editorTable default-table2 cb-table"
                        style={{maxHeight: "300px", marginBottom: 15}}
                        selectedField="selected"
                        onSelectionChange={this.selectionChange}
                        onHeaderSelectionChange={this.headerSelectionChange}
                        onRowClick={this.rowClick}>
                        <GridNoRecords>
                            No Attributes Could Be Found To Rollup
                        </GridNoRecords>
                        <Column
                            field="selected"
                            width="20px"
                            headerSelectionValue={
                                this.state.children.findIndex(e => e.selected === false) === -1
                            }/>
                        <Column title="Name" field="rolename"/>
                        <Column title="Specializes" field="specializationPath"/>
                    </Grid>
                    <button
                            id={phenomId.gen("init","rollup")}
                            className="bordered-button no-left-margin"
                            onClick={this.submit}>Rollup
                    </button>
                </div>
            </div>
        </Draggable>);
    }
}
