import React, {Component} from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import {Grid, GridColumn as Column} from "@progress/kendo-react-grid";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Checkbox } from '@progress/kendo-react-inputs';
import {fetchModelTree} from "./branch";
import { getAccountInfo, getAccountLicenses } from "../../requests/sml-requests";
import { KbButton, PhenomComboBox, PhenomInput, PhenomLabel, PhenomSelect, PhenomToggle, ProjectSelection } from "../util/stateless";
import { receiveErrors, receiveLogs, receiveResponse, receiveWarnings } from "../../requests/actionCreators";
import { ChangeSubUserPassword } from "../settings/change_password";
import { PhenomModal } from "../util/Modal";
import { SubMenuLeft, SubMenuRight } from "../edit/edit-top-buttons";
import { BasicAlert } from "../dialog/BasicAlert";
import PhenomId from "../../requests/phenom-id";
import { formatDate } from "../util/util";
import { _ajax } from "../../requests/sml-requests";



class Subscription extends Component {
    changePasswordRef = React.createRef();
    newUserRef = React.createRef();

    licenseHash = {};
    userHash = {};
    state = {
      accountInfo: {},
      models: [],
      usersTable: [],

      unassigned_licenses: [],
      revoke_list: new Set(),
      invoke_hash: {},          // { <user_id>: <license_id> }

      addNewUser: false,
      changePasswordFor: null,
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        return Promise.all([ getAccountInfo(), getAccountLicenses() ]).then(res => {
            const resp1 = res[0].data.accountInfo;
            const resp2 = res[1].data;

            const usersTable = resp1.usersTable.sort((user1, user2) => user1.name.localeCompare(user2.name));
            usersTable.forEach(user => this.userHash[user.id] = {...user});

            const unassigned_licenses = resp2.licenses.filter(license => !license.user_id && !this.isDateExpired(license.expiration_date)).map(license => license.license_id);
            resp2.licenses.forEach(license => this.licenseHash[license.license_id] = license);
            
            this.setState({
                usersTable,
                unassigned_licenses,
                accountInfo: resp1,
                models: resp1.models,
                revoke_list: new Set(),
                invoke_hash: {},
            });
        })
    }

    deleteUserFromDB = (userName) => {
        return _ajax({
            url: "/index.php?r=/account/delete-user",
            method: "post",
            data: {
                userName: userName,
                accountName: this.state.accountInfo.accountName,
            },
        }).then(response => {
            receiveLogs(response.logs);
            this.setState({usersTable: this.state.usersTable.filter((user) => user.name != userName)});
        });
    }

  checkUserPModelsAndDelete = (user) => {
      return _ajax({
          url: "/index.php?r=/referencing-model/users-index",
          data: {passedUser: user.name}
      }).then((res) => {
          const data = res.data.usersModels;
          const pTree = [];

          data.filter(el => !/^$|.*i.*/.test(el.permissions[user.name])).forEach(el => {
             const {id, name, description, permissions, subModels} = el;
             pTree.push({
                  text: name,
                  highlighted: false,
                  additionalData: {
                      id,
                      description,
                      permissions
                  },
                  type: "project",
              });
          });
          return fetchModelTree(true, user.name).then((res) => {
              let mTree = [];
              let branches = Array.from(res);
              while (branches.length) {
                  const branch = branches.shift();
                  branch.children.forEach(branchRef => {
                      mTree.push(branchRef);
                      branches = branches.concat(branchRef.children);
                  });
              }
              DeleteUserPrompt.show(user, pTree, mTree.filter(leaf => !/^$|.*i.*/.test(leaf.additionalData.permissions[user.name])), true);
          });
      });
  };

    isUserEdited = (user) => {
      const original = this.userHash[user?.id];
      if (!original) return true;

      // compare user attributes with original
      let isAttrEdited = ["active", "license_id", "isAdmin", "email"].some(attr => user[attr] !== original[attr]);

      // check if user's license is to be revoked:
      let isRevokeLicense = this.state.revoke_list.has(user.license_id);

      // check if user is assigned a new license:
      let isNewLicense = !!this.state.invoke_hash[user.id];

      return isAttrEdited || isRevokeLicense || isNewLicense;
    }

    isDateExpired = (dateRaw) => {
      if (!dateRaw) return false;
      const expire_date = new Date(dateRaw);
      return Date.now() > expire_date.getTime();
    }

    willDateExpire = (dateRaw) => {
      if (!dateRaw) return false;
      const expire_date = new Date(dateRaw);
      const twoWeeks = 1209600000;
      return Date.now() > expire_date.getTime() - twoWeeks && Date.now() < expire_date.getTime();
    }

    toggleRevokeLicense = (license_id) => {
      const revoke_list = new Set(this.state.revoke_list);

      if (revoke_list.has(license_id)) {
        revoke_list.delete(license_id);
      } else {
        revoke_list.add(license_id);
      }

      this.setState({ revoke_list });
    }

    toggleInvokeLicense = (user_id, license_id) => {
      const invoke_hash = { ...this.state.invoke_hash };
      let unassigned_licenses = [...this.state.unassigned_licenses];      

      const previouslySelectedLicenseId = invoke_hash[user_id];
      previouslySelectedLicenseId && unassigned_licenses.push(previouslySelectedLicenseId);

      // toggle
      invoke_hash[user_id] = license_id;
      
      if (license_id) {
        unassigned_licenses = unassigned_licenses.filter(lic_id => lic_id !== license_id);
      }
      this.setState({ invoke_hash, unassigned_licenses });
    }

    toggleActive = (user, bool) => {
      const usersTable = [...this.state.usersTable];
      const clone = { ...user, active: bool }

      const idx = usersTable.findIndex(u => u.id === clone.id);
      if (idx > -1) {
        usersTable[idx] = clone;
      }

      this.setState({ usersTable });
    }

    toggleAdminStatus = (user, bool) => {
      const usersTable = [...this.state.usersTable];
      const clone = { ...user, isAdmin: bool }

      const idx = usersTable.findIndex(u => u.id === clone.id);
      if (idx > -1) {
        usersTable[idx] = clone;
      }

      this.setState({ usersTable });
    }

    handleSaveChanges = () => {
      const { accountInfo, usersTable } = this.state;

      const requestUsers = usersTable.filter(user => this.isUserEdited(user));
      const revoke_list = [...this.state.revoke_list];                                        // convert Set to Array
      const invoke_list = Object.entries(this.state.invoke_hash)
                                .filter(([_, license_id]) => !!license_id)                    // filter out empty entries
                                .map(([user_id, license_id]) => [license_id, user_id]);       // adjust the format - this matches the license table/page

      if (!requestUsers.length) {
        return receiveWarnings("No changes detected");
      }

      return _ajax({
          url: "/index.php?r=/account/update-users",
          method: "post",
          data: {
              accountName: accountInfo.accountName,
              usersTable: requestUsers,
              revoke_list,
              invoke_list,
          },
      }).then(res => {
          receiveLogs(res.logs);

          return this.setState({ addNewUser: null }, () => {
            BasicAlert.show("Refreshing users", "One moment please", false);
            this.loadData().then(() => BasicAlert.hide());
          });
      });
    };

    handleSaveUserPassword = () => {
      this.changePasswordRef.current
                            .handleSave()
                            .then(() => {
                              this.setState({ changePasswordFor: null });
                            })
                            .catch((err) => {
                              receiveErrors(err);
                            })
    }

    handeSaveNewUser = () => {
      const { accountInfo, usersTable } = this.state;
      const numActiveUsers = usersTable.filter(user => user.active === true).length;
      const newUser = this.newUserRef.current.generateNewUser();    // <-- active is set to true.

      if (accountInfo.maxUsers !== -1 && numActiveUsers + 1 > accountInfo.maxUsers) {
        return receiveErrors("Account quota has been met. Please ensure active users do not surpass the maximum users allowed in account.");
      }

      if (newUser.name === "" || newUser.email === "" || !newUser.initialModelId) {
        return receiveErrors("Please fill all fields");
      }

      if (newUser.personalWs) {
        receiveWarnings("User set up may take up to 10 minutes");
      }

      return _ajax({
          url: "/index.php?r=/account/create-user",
          method: "post",
          data: {
              accountName: accountInfo.accountName,
              newUser: newUser,
          },
      }).then(res => {
          receiveLogs(res.logs);
          
          return this.setState({ addNewUser: null }, () => {
            BasicAlert.show("Refreshing users", "One moment please", false);
            this.loadData().then(() => BasicAlert.hide());
          });
      })
    }

    renderPasswordCell = (cellProps) => {
      const { userIdentity } = this.props;

      // current user
      if (userIdentity?.username === cellProps.dataItem.name) {
        return <td />
      }

      return <td style={{ textAlign: "center" }}>
        <Button iconClass="fas fa-edit"
                onClick={() => this.setState({ changePasswordFor: cellProps.dataItem.name })} />
      </td>
    }

    renderActiveCell = (cellProps) => {
      const { userIdentity } = this.props;

      // current user
      if (userIdentity?.username === cellProps.dataItem.name) {
        return <td />
      }

      const data = ["Disabled", "Enabled"];
      const value = cellProps.dataItem.active ? data[1] : data[0];

      return <td><PhenomSelect value={value}
                               data={data}
                               onChange={(e) => this.toggleActive(cellProps.dataItem, e.target.value === "Enabled")} /></td>
    }

    renderEmailCell = (cellProps) => {
      const { usersTable } = this.state;
      const idx = usersTable.findIndex(u => u.id === cellProps.dataItem.id);
      const phenomId = new PhenomId("email-cell", this.props.idCtx);

      return <td><PhenomInput value={usersTable[idx].email}
                          id={phenomId.gen("", idx)}
                          onChange={(e) => {
                            usersTable[idx].email = e.target.value;
                            this.setState({ usersTable });
                          }} /></td>
    }

    renderAdminCell = (cellProps) => {
      const { userIdentity } = this.props;
      const isCurrentUser = userIdentity?.username === cellProps.dataItem.name;
      const isUserAdmin = cellProps.dataItem.isAdmin;

      return <td style={{ textAlign: "center" }}>
        <PhenomToggle
          checked={isUserAdmin}
          data={["No", "Yes"]}
          disabled={isCurrentUser}
          onChange={(e) => this.toggleAdminStatus(cellProps.dataItem, !isUserAdmin)} />
      </td>
    }

    renderUserProjectsCell = (cellProps) => {
      return <td style={{ textAlign: "center" }}>
        <Button iconClass="fas fa-tasks"
                title="Manage user's projects"
                onClick={() => this.props.history.push(`/settings/subscription/user_model_manage/${cellProps.dataItem.name}`)} />
      </td>
    }


    renderLicenseCell = (cellProps) => {
      const { unassigned_licenses, revoke_list, invoke_hash } = this.state;
      const { userIdentity } = this.props;
      const { id, name, license_id } = cellProps.dataItem;
      const isCurrentUser = userIdentity?.username === name;

      // user has a license
      if (license_id) {
        let revokeIconClass = revoke_list.has(license_id) ? "fas fa-link" : "fas fa-unlink";
        let revokeTitle = revoke_list.has(license_id) ? "Cancel revoke" : "Revoke";

        return <td>
                <div style={{ display: "flex", alignItems: "center", gap: 5, justifyContent: "space-between", whiteSpace: "nowrap"  }}>
                  <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                    { license_id }
                  </span>
                  {!isCurrentUser &&
                    <Button iconClass={revokeIconClass}
                            title={revokeTitle}
                            onClick={() => this.toggleRevokeLicense(license_id)} /> }
                </div>
        </td>
      }

      if (isCurrentUser) {
        return <td />
      }

      const newLicenseId = invoke_hash[id];
      return <td><PhenomComboBox data={unassigned_licenses}
                                value={newLicenseId}
                                placeholder="Click to assign"
                                onChange={(license_id) => this.toggleInvokeLicense(cellProps.dataItem.id, license_id)}
                                onClickCancelIcon={() => this.toggleInvokeLicense(cellProps.dataItem.id)} /></td>
    }


    renderExpireDateCell = (cellProps) => {
      const { invoke_hash } = this.state;
      const license_id = invoke_hash[cellProps.dataItem?.id];
      const newLicense = this.licenseHash[license_id];
      const dateRaw = cellProps.dataItem.expiration_date || newLicense?.expiration_date;

      if (!dateRaw) {
        return <td />
      }

      const isExpired = this.isDateExpired(dateRaw);
      const willExpire = this.willDateExpire(dateRaw);

      let background;
      if (isExpired) background = "var(--bs-danger)";
      if (willExpire) background = "var(--bs-warning)";

      return <td style={{ background }}>
        { formatDate(dateRaw) }
      </td>
    }

    renderDeleteCell = (cellProps) => {
      const { userIdentity } = this.props;

      // current user
      if (userIdentity?.username === cellProps.dataItem.name) {
        return <td />
      }

      return <td style={{ textAlign: "center" }} >
        
        <Button id={`delete-${cellProps.dataItem.name}`}
                iconClass="fas fa-trash"
                onClick={() => this.checkUserPModelsAndDelete(cellProps.dataItem)} />
      </td>
    }

    renderRow = (_, cellProps) => {
      const isEdited = this.isUserEdited(cellProps.dataItem);

      return <tr style={{ background: isEdited ? "hsl(var(--skayl-sky-hs) 86%)" : null }}>
        <td>{ cellProps.dataItem.name }</td>
        { this.renderEmailCell(cellProps) }
        { this.renderActiveCell(cellProps) }
        { this.renderAdminCell(cellProps) }
        { this.renderLicenseCell(cellProps) }
        { this.renderExpireDateCell(cellProps) }
        { this.renderPasswordCell(cellProps) }
        { this.renderUserProjectsCell(cellProps) }
        { this.renderDeleteCell(cellProps) }
      </tr>
    }

    render() {
        const phenomId = new PhenomId("subscription",this.props.idCtx);
        const { accountInfo, usersTable, unassigned_licenses } = this.state;

        const countActiveUsers = usersTable.reduce((total, currUser) => currUser.active ? total + 1 : total, 0);
        const countUnassignedLicenses = unassigned_licenses.length;
        const countActiveLicenses = Object.values(this.licenseHash).reduce((total, currLicense) => {
          if (!this.isDateExpired(currLicense?.expiration_date)) {
            return total + 1;
          }
          return total;
        }, 0);

        return (<div className="phenom-content-wrapper">
            <nav className="sub-menu-actions" aria-label='form actions'>
                <SubMenuLeft>
                  <NavLink id="subscription-link" activeClassName="active" to="/settings/subscription/users">Users</NavLink>
                  <NavLink id="licenses-link" activeClassName="active" to="/settings/subscription/licenses">Phenom Licenses</NavLink>
                  <NavLink id="cinc-licenses-link" activeClassName="active" to="/settings/subscription/cinc-licenses">CinC Licenses</NavLink>
                </SubMenuLeft>
                <SubMenuRight>
                    <KbButton />
                    <button id={phenomId.gen("","add-user-button")}
                            className="fas fa-user-plus"
                            title="Add New User"
                            onClick={() => this.setState({ addNewUser: true })} />
                    <button id={phenomId.gen("","save")}
                            className="fas fa-save"
                            title="Save"
                            onClick={this.handleSaveChanges} />
                </SubMenuRight>
              </nav>

            <div className="phenom-content-scrollable subview-wrapper">
                <DeleteUserPrompt deleteUserFromDB={this.deleteUserFromDB} idCtx={phenomId.gen("","label")}/>

                <div>
                  <PhenomLabel text="Subscription" />
                  <p>Subscription name: { accountInfo.accountName || "..." }</p>
                  <p>Active licenses: { countActiveLicenses } (unassigned: { countUnassignedLicenses })</p>
                  <p>Subscription role: { accountInfo.subscriptionRole || "..." }</p>
                  <p>Subscription license: { accountInfo.subscriptionLicense || "..." }</p>
                </div>

                <PhenomLabel text="Users" />
                <Grid id={phenomId.gen("","grid")}
                      data={this.state.usersTable || []}
                      className="subscription-users-table without-box-shadow"
                      rowRender={this.renderRow}>
                        <Column title="Username" />
                        <Column title="Email" />
                        <Column title="Active" width="130px" />
                        <Column title="Admin" width="100px" />
                        <Column title="Phenom License" />
                        <Column title="Phenom License Expiration Date" width="205px" />
                        <Column title="Change Password" width="130px" />
                        <Column title="User Projects" width="110px" />
                        <Column title="Delete User" width="100px" />
                </Grid>

                <PhenomModal show={!!this.state.changePasswordFor}
                            onSave={this.handleSaveUserPassword}
                            onClose={() => this.setState({ changePasswordFor: null })}>
                    <ChangeSubUserPassword subUsername={this.state.changePasswordFor}
                                            ref={this.changePasswordRef} />
                </PhenomModal>

                <PhenomModal show={this.state.addNewUser}
                             onSave={this.handeSaveNewUser}
                             onClose={() => this.setState({ addNewUser: false })}>
                                <AddNewUser models={this.state.models}
                                            unassigned_licenses={this.state.unassigned_licenses} 
                                            licenseHash={this.licenseHash}
                                            ref={this.newUserRef}/>
                </PhenomModal>
            </div>
        </div>)
    }
}

export class DeleteUserPrompt extends Component{
  state = {
     visible: false,
     name: "",
     allowClose: true,
     userHasContent: false,
 };

   constructor(props) {
       super(props);
       DeleteUserPrompt.__singleton = this;
   }

   static show(user, projects, models, allowClose = true) {
       if (!DeleteUserPrompt.__singleton) new DeleteUserPrompt();

       DeleteUserPrompt.__singleton.setState({
           user,
           projects,
           models,
           allowClose,
           userHasContent: !projects.length && !models.length,
       });

       DeleteUserPrompt.__singleton.__show();
   }

   static hide() {
       DeleteUserPrompt.__singleton.__hide();
   }

   __show() {
       DeleteUserPrompt.__singleton.setState({visible: true});
   }

   __hide() {
       DeleteUserPrompt.__singleton.setState({
           visible: false,
           name: "",
           allowClose: true
       });
   }

   render() {
       const phenomId = new PhenomId("delete-user-prompt",this.props.idCtx);
       return (<div>
           {this.state.visible && <Dialog title={`Delete user ${this.state.user.name}?`} onClose={() => {
               this.__hide();
           }} className={this.state.allowClose ? "" : "dialog-no-exit"} id={phenomId.gen("init","dialog")}>
               <div style={{margin: "5px"}} id={phenomId.gen("init","wrapper")}>
                   <div className="flex-v"
                        style={{whiteSpace: "pre-line", width: "300px", maxHeight: "80vh", overflowY: "auto"}}>
                       {this.state.userHasContent ? <div>Warning you are about to delete {this.state.user.name}, continue?</div> :
                         <div>User '{this.state.user.name}' has permissions to the content listed below.
                              <br/>If no other user has access to this content, it may become inaccesable.
                              <br/>You may use the 'Manage' button next to the username to delete this content or extend permissions to it to other users.
                           {!this.state.projects.length || <div><br/>Projects:{this.state.projects.map(project => {return(<div>&nbsp;&nbsp; {" - " + project.text}</div>)})}</div>}
                           {!this.state.models.length || <div><br/>Models:{this.state.models.map(model => {return(<div>&nbsp;&nbsp;{" - " + model.text}</div>)})}</div>}
                        </div>}
                   </div>
               </div>
               <DialogActionsBar>
                   <button className="k-button" onClick={() => {
                       this.__hide();
                   }}
                   id={phenomId.gen("init","cancel-button")}>Cancel
                   </button>

                   <button className="k-button k-primary" onClick={() => {
                       this.props.deleteUserFromDB(this.state.user.name, this.state.user?.id);
                       this.__hide();
                   }}
                   id={phenomId.gen("init","delete-button")}>Delete
                   </button>
               </DialogActionsBar>
           </Dialog>}
       </div>);
   }
}



class AddNewUser extends React.Component {
  projectSelectRef = React.createRef();

  state = {
    isNew: true,
    name: "",
    email: "",
    newLicenseId: null,
  }

  generateNewUser = () => {
    const { name, email, newLicenseId, } = this.state;
    const projectData = this.projectSelectRef.current.generateProjectData();

    return {
      name,
      email,
      active: true,
      initialModelId: projectData.selectedProject?.id,
      newLicenseId: newLicenseId || undefined,
      perms: projectData.perms,
      personalWs: projectData.personalWs,
    }
  }

  render() {
    const { models, unassigned_licenses, licenseHash } = this.props;
    const { name, email, newLicenseId } = this.state;
    const phenomId = new PhenomId("add-new-user",this.props.idCtx);
    const selectedLicense = licenseHash[newLicenseId];

    return <div className="edit-form-container">
              <div className="edit-form">
                <label>New User</label>

                <div className="p-row">
                  <div className="p-col p-col-6">
                    <PhenomInput label="Username"
                                  value={name}
                                  id={phenomId.gen("","username")}
                                  onChange={(e) => this.setState({ name: e.target.value })}
                                  config={{
                                      required: true,
                                  }} />
                  </div>
                  <div className="p-col p-col-6">
                    <PhenomInput label="Email"
                                  value={email}
                                  id={phenomId.gen("","email")}
                                  onChange={(e) => this.setState({ email: e.target.value })}
                                  config={{
                                      required: true,
                                  }} />
                  </div>
                </div>
                <div className="p-row">
                  <div className="p-col p-col-6">
                    <PhenomLabel text="Initial Project Content" />
                  </div>
                </div>
                <ProjectSelection models={models}
                                  ref={this.projectSelectRef}
                                  id={phenomId.gen("","project-content")}/>
                <div className="p-row">
                  <div className="p-col p-col-6">
                    <PhenomComboBox label="License (optional)"
                                    value={newLicenseId}
                                    id={phenomId.gen("","license")}
                                    data={unassigned_licenses}
                                    onChange={(license_id) => this.setState({ newLicenseId: license_id })}
                                    onClickCancelIcon={() => this.setState({ newLicenseId: null })} />
                  </div>
                  <div className="p-col p-col-6">
                    <div>
                      <PhenomLabel text="Expiration Date" />
                      <div>{ formatDate(selectedLicense?.expiration_date) }</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  }
}



const msp = (state) => ({
  userIdentity: state.user.userIdentity,
})


export default connect(msp)(Subscription)