import React from "react";
import styled from "@emotion/styled";
import ReactTooltip from "react-tooltip";
import {
    DefaultLinkFactory,
    DefaultLinkModel,
} from "@projectstorm/react-diagrams-defaults";
import { LinkWidget } from "@projectstorm/react-diagrams-core";
import { DiagramLinkWidget } from './DiagramLink';

import { nodeProps } from '../design/nodeDesign';
import { generateTextPath, createBoundText, createSourceBoundText, createTargetBoundText } from '../util'
import { defaultBounds } from '../../../util/util'


const Path = styled.path`
    fill: none;
    pointer-events: all;
`;

export class CompositionLinkFactory extends DefaultLinkFactory {
	constructor(type = 'comp-link') {
        super(type);
	}

	generateModel() {
		return new CompositionLinkModel();
	}

	generateReactWidget(event) {
		return <CompositionLinkWidget link={event.model} diagramEngine={this.engine} />;
    }

    generateLinkSegment(model, selected, path) {
      const { $widget, sourcePort } = model;
      let uncommitted = false;

      // changed line color when uncommitted
      if ($widget && sourcePort) {
        const { $app } = $widget;
        const isAttrUncommitted = sourcePort.getAttrData().isUncommitted;
        uncommitted = $app.state.showUncommitted && isAttrUncommitted;
      }

		return (
			<Path stroke={selected ? model.getOptions().selectedColor : 
            uncommitted ? nodeProps["uncommittedColor"] : model.getOptions().color}
				    strokeWidth={model.getOptions().width}
				    d={path} />
		);
	}
}


export class CompositionLinkModel extends DefaultLinkModel {
	constructor() {
		super({
			type: 'comp-link',
			width: 2
        });

        this.registerListener({
            eventDidFire: e => {
                if(e.function === "entityRemoved") {
                    const {sourcePort, targetPort} = e.entity;
                    if(!sourcePort) return;

                    const srcData = sourcePort.getAttrData();

                    if(srcData) sourcePort.parent.removeOutPort(srcData.guid);
                    if(targetPort) targetPort.parent.removeInPort(srcData.guid);
                }
            },
        })
    }

    forceLinkToUpdate = () => {
        if(this.$widget) this.$widget.forceUpdate();
    }
}



export class CompositionLinkWidget extends DiagramLinkWidget {
    constructor(props) {
        super(props);

         /*
         * Used for ContextMenu
         */
        this.menuItems = {
            Edit: () => {
                const sourcePort = this.props.link.sourcePort;
                const srcData = sourcePort.getAttrData();

                if(srcData.xmiType === "conceptual:AssociatedEntity") {
                    sourcePort.parent.$widget.openAssociationModal(srcData);
                }
            },
            Remove: () => {
                this.deleteLink();
            },
            Delete: () => { 
                // removes the attribute, port and connector
                const sourcePort = this.props.link.sourcePort;
                const srcData = sourcePort.getAttrData();

                this.$app.deleteNodeChild(srcData, sourcePort.parent);
            },
        }
    }

    // removes the port and connector
    deleteLink = () => {
        const sourcePort = this.props.link.sourcePort;
        const srcData = sourcePort.getAttrData();

        sourcePort.parent.removeLink(srcData.guid);
        this.props.diagramEngine.repaintCanvas();
        ReactTooltip.hide();
    }


	render() {
    const sourcePort = this.props.link.sourcePort;
    const targetPort = this.props.link.targetPort;
    if (!sourcePort) return null;

		//ensure id is present for all points on the path
		var points = this.props.link.getPoints();
		var paths = [];
        this.refPaths = [];

        const srcData = sourcePort.getAttrData();
        const uncommitted = this.$app.state.showUncommitted && srcData.isUncommitted;
        
        let linkClassName = [];
        let srcName = srcData.rolename;

        //draw the multiple anchors and complex line instead
        for (let j = 0; j < points.length - 1; j++) {
          paths.push(
            this.generateLink(
              LinkWidget.generateLinePath(points[j], points[j + 1]),
              {
                'data-linkid': this.props.link.getID(),
                'data-point': j,
                onMouseEnter: () => {
                    // SVG elements do not work with z-index
                    // SVG elements are "painted" on to the dom, so the latest element takes precedence
                    // to bring a SVG element forward, it needs to be reordered and become the last child
                    if(this.linkRef) {
                        const svgLayer = this.$app.getSvgLayerDOM();
                        svgLayer.appendChild(this.linkRef.parentElement)
                        // this.props.diagramEngine.repaintCanvas();
                    }
                },
                onMouseDown: (e) => {
                    if(e.button === 0) {
                        // // Adds a Point
                        if(e.ctrlKey) {
                            this.mouseEvent = e;
                            this.createPointAtIdx = j + 1;
                            window.addEventListener("mousemove", this.moveMouseToAddPoint);
                            window.addEventListener("mouseup", this.cleanUpAfterMouseMove);
                            // this.addPointToLink(e, j + 1);
                        }
                    }
                },
                onMouseUp: (e) => {
                    this.props.diagramEngine.model.clearSelection();
                    this.linkModel.setSelected(true);
                },
                onDoubleClick: (e) => {
                    e.preventDefault();
                    if(srcData.xmiType === "conceptual:AssociatedEntity") {
                        sourcePort.parent.$widget.openAssociationModal(srcData);
                    }
                },
              },
              j
            )
          );
        }

        // Determine if the link should show on Projection mode or Modelling Mode
        srcData.viewType ? linkClassName.push("projection-link") : linkClassName.push("modelling-link");

        // render Arrow or Point
        if(this.linkModel.isSelected() && targetPort) {
            linkClassName.push("link-selected");
            paths.push(this.generateHandle(points[0], sourcePort));
            paths.push(this.generateHandle(points[points.length - 1], targetPort));
        } else if (targetPort !== null) {
            paths.push(this.generateArrow(points[points.length - 1], points[points.length - 2], sourcePort.options.arrowHead, uncommitted));
            paths.push(this.generateArrow(points[0], points[1], sourcePort.options.arrowTail, uncommitted));
        } else {
            linkClassName.push("tool-link");
            paths.push(this.generatePoint(points[points.length - 1]));
        }
        
        // render the circles
        for (let i = 1; i < points.length - 1; i++) {
            paths.push(this.generatePoint(points[i]));
        }
        
        // adds source rolename to line if it exist
        if(sourcePort !== null && srcName) {
            paths.push(<MultiplicityWidget key={"src" + points[0].getID()} point={points[0]} previousPoint={points[1]} uncommitted={uncommitted} type="source" data={srcData} $app={this.$app} />);
        }

        // render target labels if target === src's type
        if(targetPort !== null) {
            paths.push(<MultiplicityWidget key={"tar" + points[points.length - 1].getID()} point={points[points.length - 1]} previousPoint={points[points.length - 2]} uncommitted={uncommitted} type="target" data={srcData} $app={this.$app} />);
        }

        return <g className={linkClassName.join(" ")}
                    id={`${this.$app.getPhenomDomId()}-${srcData.guid}-link-container`}
                    data-default-link-test={this.props.link.getOptions().testName} 
                    data-tip={srcData.description} 
                    data-for="diagramTip"
                    ref={el => this.linkRef = el}>
            {paths}
        </g>;
	}
}







const MultiplicityWidget = (props) => {
    const { point, previousPoint, type, data, uncommitted, $app  } = props;
    const { xmiType, lowerBound, upperBound, sourceLowerBound, sourceUpperBound } = data;
    const angle = 90 +
        (Math.atan2(point.getPosition().y - previousPoint.getPosition().y, point.getPosition().x - previousPoint.getPosition().x) *
            180) /
        Math.PI;

    let textRotate, textPosX, textAnchor;
    let srcText, srcBound, tarText, tarBound;

    srcText = data.rolename || "";

    switch(xmiType) {
      case "conceptual:Composition":
        if((lowerBound && lowerBound !== defaultBounds["lowerBound"]) ||
           (upperBound && upperBound !== defaultBounds["upperBound"])) {
              srcBound = createTargetBoundText(lowerBound, upperBound);
        }
        break;
      case "conceptual:AssociatedEntity":
        srcBound = createSourceBoundText(sourceLowerBound, sourceUpperBound);
        tarBound = createTargetBoundText(lowerBound, upperBound);

        if(type === "target" && data.pathPairs) {
          const typeGuid = data.type;
          tarText = generateTextPath({ pathPairs: data.pathPairs, pathHeadGuid: typeGuid }, $app);
        }
        break;

      case "platform:CharacteristicProjection":
        if(type === "target" && data.pathPairs) {
          const typeGuid = data.projectedCharacteristic;
          tarText = generateTextPath({ pathPairs: data.pathPairs, pathHeadGuid: typeGuid, showPathHead: true}, $app);
        }
      break;
    }

    const textStyle = {
        fontSize:12, 
        fill: uncommitted ? nodeProps["uncommittedColor"] : null
    }

    const tarTextStyle = {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        whiteSpace: "nowrap",
        top: tarText ? "-35px" : "-20px", 
    }
    
    if (type === "source") {
        textRotate = 0 < angle && angle < 180 ? -90 : 90;
        textPosX = 0 < angle && angle < 180 ?  -20 : 20;
        textAnchor = 0 < angle && angle < 180 ? "end" : "start";

    } else {
        if(angle < 0 || 180 < angle) {
            textRotate = 90;
            textPosX = 30;
            textAnchor = "start";
            tarTextStyle.left = 30;
            tarTextStyle.alignItems = "flex-start";
        } else {
            textRotate = -90;
            textPosX = -30;
            textAnchor = "end";
            tarTextStyle.right = 30;
            tarTextStyle.alignItems = "flex-end";
        }
    }

	return (
		<g transform={'translate(' + point.getPosition().x + ', ' + point.getPosition().y + ')'}>
            <g style={{transform: "rotate(" + (angle + textRotate) + "deg)"}}>
                
                {/* note: width:1 and height:1 because it doesn't display correctly in firefox without it */}
                {type === "target" &&
                <foreignObject style={{
                    display: "flex",
                    fontSize:12, 
                    position:"relative", 
                    overflow:"visible", 
                    width:1, 
                    height:1,}}>
                        <div style={tarTextStyle}>
                            <div>
                                <div>{srcText}</div>
                                {tarText &&
                                    <div>{tarText}</div>}
                                
                            </div>
                        </div>
                </foreignObject>}
                
                <text x={textPosX} textAnchor={textAnchor} y="15" style={textStyle}>
                  {type === "source" ? srcBound : tarBound}</text>
            </g>
		</g>
	);
};


