import React from "react";
import {SpecializationAutoFill} from "../../util/stateless";
import commitIcon from "../../../images/edit_icons/commit.png";
import editIcon from "../../../images/edit.png";
import PhenomId from "../../../requests/phenom-id"

export default class SpecializationBuilder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editing: false,
            specializes: props.specializes,
            autofillOptions: []
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.specializes && (!prevState.specializes || prevState.specializes.guid !== this.state.specializes.guid)) {
            if (this.props.updateSpecialization) {
                this.props.updateSpecialization(this.state.specializes);
            }
        }
        if (this.props !== prevProps) {
            this.setState({
                autofillOptions: this.props.possibleSpecializations.filter(e => e.type.guid === this.props.typeGuid && e.parent === this.props.parentGuid),
                specializes: this.props.specializes
            });
        }
    }

    render() {
        const phenomId = new PhenomId("specialization-builder",this.props.idCtx);
        let specializationPath = "";
        if (this.state.specializes && this.props.possibleSpecializations) {
            if (this.state.specializes.guid && this.state.specializes.guid !== "") {
                const matchingSpecialization = this.props.possibleSpecializations.find(e => e.guid === this.state.specializes.guid);
                if (matchingSpecialization) {
                    const parent = matchingSpecialization.parentName || "";
                    const rolename = this.state.specializes.rolename;
                    specializationPath = `${parent}.${rolename}`;
                }
            }
        }

        return (<td style={{position: "relative"}}>
                <div style={{display:"flex"}}>
            {this.props.allowEdit && <button
                // id="edit-specialization"
                className="edit-specialization"
                id={phenomId.gen("init","edit-specialization")}
                style={{
                    backgroundImage: this.state.editing ? `url(${commitIcon})` : `url(${editIcon})`,
                    marginTop: 5
                }}
                onClick={() => this.setState({editing: !this.state.editing})}/>}
            {this.state.editing ?
                <SpecializationAutoFill
                    id={phenomId.gen("init","specialization-auto-fill")}
                    data={this.state.autofillOptions}
                    textField="fullPath"
                    value={this.state.specializes || {fullPath: ""}}
                    onChange={(evt) => {
                        const selectMatch = this.state.autofillOptions.find(e => e.guid === (evt.target.value ? evt.target.value.guid : ""));

                        selectMatch ? this.setState({
                            specializes: selectMatch
                        }) : this.setState({
                            specializes: evt.target.value ? {
                                ...this.state.specializes,
                                fullPath: evt.target.value ? evt.target.value.name : ""
                            } : {}
                        });
                    }}/> :
                <a className="cadet-anchor"
                   id={phenomId.gen("init","get-specialization-trace")}
                   style={this.props.allowEdit ? {width: "calc(100% - 25px)", overflow: "hidden"} : {}}
                   onClick={() => this.props.getSpecializationTrace(this.props.guid, this.state.specializes.guid)}>{specializationPath}</a>}
        </div></td>);
    }
}
