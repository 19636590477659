import React from "react";
import { AbstractReactFactory } from "@projectstorm/react-canvas-core";
import styled from "@emotion/styled";

import { DiagramShapeModel, DiagramShapeWidget } from "../node/DiagramShape";

import {
    NodeHalo,
} from "../design/nodeDesign";


const Box = styled.div`
    border: 1px solid black;
    border-radius: 2px;
    width: 200px;
    height: 200px;
`


export class BoxShapeFactory extends AbstractReactFactory {
    constructor() {
        super("box-shape");
    }

    generateModel(event) {
        return new BoxShapeModel();
    }

    generateReactWidget(event) {
        return <BoxShapeWidget engine={this.engine} node={event.model}/>;
    }
}



export class BoxShapeModel extends DiagramShapeModel {
    constructor(options = {}) {
        super({
            ...options,
            type: "box-shape"
        });
    }
}



class BoxShapeWidget extends DiagramShapeWidget {

    componentDidMount() {
        this.boxRef.parentElement.style.zIndex = -1;
    }

    render() {
        return (
            <Box id={this.phenomId.genPageId("-box-container")} 
                 ref={el => this.boxRef = el}
                 style={{width: this.nodeModel.width || null, height: this.nodeModel.height || null}}>
                    
                <NodeHalo selected={this.nodeModel.options.selected} />

                <div className="resize-left" onMouseDown={this.startResize} />
                <div className="resize-right" onMouseDown={this.startResize} />
                <div className="resize-top" onMouseDown={this.startResize} />
                <div className="resize-bottom" onMouseDown={this.startResize} />
                <div className="resize-top-left" onMouseDown={this.startResize} />
                <div className="resize-top-right" onMouseDown={this.startResize} />
                <div className="resize-bottom-left" onMouseDown={this.startResize} />
                <div className="resize-bottom-right" onMouseDown={this.startResize} />
            </Box>
        );
    }
}