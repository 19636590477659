import { 
  // DragNewLinkState,
  // DragDiagramItemsState,
  PortModel,
  PointModel,
} from "@projectstorm/react-diagrams";

import {
	State,
	Action,
	InputType,
	DragCanvasState,
  AbstractDisplacementState,
  BasePositionModel,
} from '@projectstorm/react-canvas-core';

import * as _ from 'lodash';

import { CustomSelectionBoxState } from './CustomSelectionBoxState'




export class StormState extends State {
  constructor() {
    super({
      name: 'sp-diagrams'
    });

    this.childStates = [];
		this.dragCanvas = new DragCanvasState({allowDrag: false});
		this.dragNewLink = new CustomDragNewLinkState({allowLooseLinks: false});
		this.dragItems = new CustomMoveItemsState();
    
    // determine what was clicked on
		this.registerAction(
			new Action({
				type: InputType.MOUSE_DOWN,
				fire: async (event) => {
          switch(event.event.nativeEvent.button) {
            case 0:
              const element = this.engine.getActionEventBus().getModelForEvent(event);
              const hasPortNameData = event.event.target?.dataset?.portName;

              // the canvas was clicked on, transition to the selection box state
              if (!element) {
                this.transitionWithEvent(new CustomSelectionBoxState(), event);
              }
              // initiate dragging a new link
              else if (element instanceof PortModel || hasPortNameData) {
                this.transitionWithEvent(this.dragNewLink, event);
              }
              // move the items (and potentially link points)
              else {
                this.transitionWithEvent(this.dragItems, event);
              }
          }
				}
			})
		);
  }
}






// ------------------------------------------------------------
// Override DragNewLinkState
// ------------------------------------------------------------
export class CustomDragNewLinkState extends AbstractDisplacementState {

	constructor(options = {}) {
		super({ name: 'drag-new-link' });

		this.config = {
			allowLooseLinks: true,
			allowLinksFromLockedPorts: false,
			...options
		};

		this.registerAction(
			new Action({
				type: InputType.MOUSE_DOWN,
				fire: (event) => {
          const element = this.engine.getMouseElement(event.event);
          const portNameData = event.event.target?.dataset?.portName;

          if (element instanceof PortModel) {
            this.port = element;
          } else if (portNameData) {
            this.port = element.getPort(portNameData);
          }

          // exit if port doesn't exist
          if (!this.port) {
            return;
          }
          
					if (!this.config.allowLinksFromLockedPorts && this.port.isLocked()) {
						this.eject();
						return;
					}
					this.link = this.port.createLinkModel();

					// if no link is given, just eject the state
					if (!this.link) {
						this.eject();
						return;
					}
					this.link.setSelected(true);
					this.link.setSourcePort(this.port);
					this.engine.getModel().addLink(this.link);
					this.port.reportPosition();
				}
			})
		);

		this.registerAction(
			new Action({
				type: InputType.MOUSE_UP,
				fire: (event) => {
					const model = this.engine.getMouseElement(event.event);
					// check to see if we connected to a new port
					if (model instanceof PortModel) {
						if (this.port.canLinkToPort(model)) {
							this.link.setTargetPort(model);
							model.reportPosition();
							this.engine.repaintCanvas();
							return;
						} else {
							this.link.remove();
							this.engine.repaintCanvas();
							return;
						}
					}

					if (!this.config.allowLooseLinks) {
						this.link.remove();
						this.engine.repaintCanvas();
					}
				}
			})
		);
	}

	/**
	 * Calculates the link's far-end point position on mouse move.
	 * In order to be as precise as possible the mouse initialXRelative & initialYRelative are taken into account as well
	 * as the possible engine offset
	 */
	fireMouseMoved(event) {
		const portPos = this.port.getPosition();
		const zoomLevelPercentage = this.engine.getModel().getZoomLevel() / 100;
		const engineOffsetX = this.engine.getModel().getOffsetX() / zoomLevelPercentage;
		const engineOffsetY = this.engine.getModel().getOffsetY() / zoomLevelPercentage;
		const initialXRelative = this.initialXRelative / zoomLevelPercentage;
		const initialYRelative = this.initialYRelative / zoomLevelPercentage;
		const linkNextX = portPos.x - engineOffsetX + (initialXRelative - portPos.x) + event.virtualDisplacementX;
		const linkNextY = portPos.y - engineOffsetY + (initialYRelative - portPos.y) + event.virtualDisplacementY;

		this.link.getLastPoint().setPosition(linkNextX, linkNextY);
		this.engine.repaintCanvas();
	}
}







// ------------------------------------------------------------
// Override DragDiagramItemsState
// ------------------------------------------------------------
export class CustomMoveItemsState extends AbstractDisplacementState {
	constructor() {
		super({
			name: 'move-items'
		});
    this.registerAction(
      new Action({
        type: InputType.MOUSE_UP,
        fire: (event) => {
          const item = this.engine.getMouseElement(event.event);
          if (item instanceof PortModel) {
            _.forEach(this.initialPositions, (position) => {
              if (position.item instanceof PointModel) {
                const link = position.item.getParent();

                // only care about the last links
                if (link.getLastPoint() !== position.item) {
                  return;
                }
                if (link.getSourcePort().canLinkToPort(item)) {
                  link.setTargetPort(item);
                  item.reportPosition();
                  this.engine.repaintCanvas();
                }
              }
            });
          }
        }
      })
    );

		this.registerAction(
			new Action({
				type: InputType.MOUSE_DOWN,
				fire: (event) => {
					const element = this.engine.getActionEventBus().getModelForEvent(event);
					if (!element) {
						return;
					}
					if (!element.isSelected()) {
						this.engine.getModel().clearSelection();
					}
					element.setSelected(true);
					this.engine.repaintCanvas();
				}
			})
		);
	}

	activated(previous) {
		super.activated(previous);
		this.initialPositions = {};
	}

	fireMouseMoved(event) {
		const items = this.engine.getModel().getSelectedEntities();
		const model = this.engine.getModel();
		for (let item of items) {
			if (item instanceof BasePositionModel) {
				if (item.isLocked()) {
					continue;
				}
				if (!this.initialPositions[item.getID()]) {
					this.initialPositions[item.getID()] = {
						point: item.getPosition(),
						item: item
					};
				}

				const pos = this.initialPositions[item.getID()].point;
        const settings = item.getSettings && item.getSettings();

        let posX = pos.x + event.virtualDisplacementX;
        if (settings?.lockHorizontalMovement) {
          posX = pos.x;
        }

        let posY = pos.y + event.virtualDisplacementY;
        if (settings?.loclVerticalMovement) {
          posY = pos.y;
        }

				item.setPosition(
					model.getGridPosition(posX),
					model.getGridPosition(posY)
				);
			}
		}
		this.engine.repaintCanvas();
	}
}
