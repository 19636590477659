import {Notifications, Notifications2} from "./notifications";
import {CadetInput, CadetTextArea, LineLabel, Toggle} from "../util/stateless";
import React from "react";
import {NodeHistory2} from "./node-history";
import $ from "jquery";
import LoaderButton from "../widget/LoaderButton";
import PhenomId from "../../requests/phenom-id";
import { getNodeWithAddenda } from "../../requests/sml-requests";
import { withPageLayout } from "./node-layout";
import {constraintsToPrimitives} from "../util/util";
import { createNodeUrl } from "../../requests/type-to-path";
import ChangeSetPicker from "../widget/ChangeSetPicker";
import { getActiveChangeSetId } from "../../requests/actionCreators";
import { _ajax } from "../../requests/sml-requests";


export class ConstraintManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            guid: null,
            parent: null,
            name: "",
            description: "",
            xmiType: "platform:RegularExpressionConstraint",
            expression: "",
            upperBound: "",
            lowerBound: "",
            upperBoundInclusive: true,
            lowerBoundInclusive: true,
            possibleParents: [],
            constraintType: "",
            changeSetId: "",
        };

        this.historyRef = undefined;
        this.noticeRef = undefined;
        this.xmiTypeMap = {
            "platform:RegularExpressionConstraint": "Regular Expression Constraint",
            "platform:RealRangeConstraint": "Real Range Constraint",
            "platform:IntegerRangeConstraint": "Integer Range Constraint",
            "logical:RegularExpressionConstraint": "Regular Expression Constraint",
            "logical:RealRangeConstraint": "Real Range Constraint",
            "logical:IntegerRangeConstraint": "Integer Range Constraint",
        };
        this.phenomId = new PhenomId("edit-constraint",this.props.idCtx);

        if (this.props.primitive) {
            const xmiType = 'platform:' + Object.keys(constraintsToPrimitives).find(constraint => constraintsToPrimitives[constraint].includes(this.props.primitive));
            this.state.xmiType = xmiType;
        }
        this.resetConstraintType();
    }

    componentDidMount() {
        let {constraintType} = this.props.match.params;

        if (this.props.match.params.guid !== "new") {
            getNodeWithAddenda(this.props.match.params.guid).then(newState => {
                this.setState({
                    ...newState,
                    lowerBoundInclusive: newState.lowerBoundInclusive === "true",
                    upperBoundInclusive: newState.upperBoundInclusive === "true"
                })
            });
        }

        // If logical:Constraint then change xmiType
        if (constraintType === "logical") {
            this.setState({
                xmiType: "logical:RegularExpressionConstraint",
                constraintType: "logical"
            });

            // If platform:Constraint fetch possible Parents and set xmiType
        } else {
            _ajax({
                url: "/index.php?r=/node/model-nodes-of-type",
                method: "get",
                data: {type: "face:PlatformDataModel"}
            }).then(res => {
                this.setState({
                    possibleParents: res.data.nodes,
                    constraintType: "platform"
                })
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.match.params.guid !== prevProps.match.params.guid) {
            const {constraintType} = this.props.match.params;
            const xmiType = constraintType == "logical" ? "logical:RegularExpressionConstraint" : "platform:RegularExpressionConstraint";

            if (this.props.match.params.guid !== "new") {
                $.ajax({
                    url: "/index.php?r=/node/get-details",
                    method: "get",
                    data: {
                        guid: this.props.match.params.guid
                    }
                }).then(res => {
                    this.setState(JSON.parse(res))
                });
            } else {
                this.setState({
                    guid: null,
                    parent: "",
                    name: "",
                    description: "",
                    xmiType,
                    constraintType: constraintType || "platform"
                }, this.resetConstraintType);
            }
        }
    }

    changeState = e => {
      const target = e.target.id.match("name|parent|description|expression|lowerBound|upperBound|xmiType")[0];
        this.setState({[target]: e.target.value});
    };

    handleSave = () => {
        const newState = $.extend(true, {}, this.state);
        delete newState["possibleParents"];
        delete newState["constraintType"];

        const convertBound = (value) => {
            value = value.replace(/\s+/g, "");
            const calculation = value.match(/([+-]?\d+(e\d+)?[+\-*/^%])*([+-]?\d+(e\d+)?)/);
            if (calculation && calculation[0] === value) {
                return new Function(`return ${calculation[0]};`)();
            }
            return Number(value);
        };

        if (this.state.xmiType === "platform:RealRangeConstraint" ||
            this.state.xmiType === "logical:RealRangeConstraint") {
            newState.lowerBound = convertBound(newState.lowerBound);
            newState.upperBound = convertBound(newState.upperBound);
        }

        if (this.state.xmiType === "platform:IntegerRangeConstraint" ||
            this.state.xmiType === "logical:IntegerRangeConstraint") {
            newState.lowerBound = Number(newState.lowerBound);
            newState.upperBound = Number(newState.upperBound);
        }

        newState.changeSetId = getActiveChangeSetId();

        let errors = this.validateData(newState);
        if (errors.length > 0) {
            return this.noticeRef.note(errors);
        }

        // if logical:Constraint send State to parent
        if (this.state.constraintType === "logical" && this.props.closeModal) {
            this.props.closeModal(newState);

            // if platform:Constraint create a new Constraint and send the new GUID to parent
        } else {
            return _ajax({
                url: "/index.php?r=/node/smm-save-nodes",
                method: "post",
                data: newState,
            }).then((res) => {
                const response = res.data;
                if (response.errors) {
                  Notifications2.parseErrors(response);
                } else {
                    if (this.props.closeModal) {
                        this.props.closeModal(response.guid);
                    } else {
                        this.setState(response);
                        Notifications2.parseResponse(response);
                        this.props.history.push( createNodeUrl(response) );
                        window["treeRef"].fetchData();
                        this.historyRef.updateHisotry();
                    }
                }
            });
        }
    };


    validateData = (newState) => {
        let errors = [];

        if (newState.name.length < 1) {
            errors.push("Name cannot be blank.");
        }

        switch (newState.xmiType) {
            case "logical:RegularExpressionConstraint":
            case "platform:RegularExpressionConstraint":
                if (newState.expression.length < 1) {
                    errors.push("Expression cannot be blank.");
                }
                break;
            case "logical:IntegerRangeConstraint":
            case "platform:IntegerRangeConstraint":
                let lower = parseInt(newState.lowerBound);
                let upper = parseInt(newState.upperBound);

                if (isNaN(lower) && this.state.lowerBound) errors.push("Lower Bound must be an integer.");
                if (isNaN(upper) && this.state.upperBound) errors.push("Upper Bound must be an integer.");
                if ((lower || lower === 0) && (upper || upper === 0) && upper <= lower) errors.push("Lower Bound must be less than Upper Bound");
                if (newState.xmiType === "logical:IntegerRangeConstraint" && ((!lower && lower !== 0) || (!upper && upper !== 0))) {
                    errors.push("Both Upper and Lower Bounds must be set.");
                }
                if (newState.xmiType === "platform:IntegerRangeConstraint" && ((!lower && lower !== 0) && (!upper && upper !== 0))) {
                    errors.push("At least one bound must be set.");
                }
                break;
            case "logical:RealRangeConstraint":
            case "platform:RealRangeConstraint":
                if ((newState.lowerBound || newState.lowerBound === 0) && (newState.upperBound || newState.upperBound === 0) && newState.upperBound <= newState.lowerBound) {
                    errors.push(`Lower Bound must be less than Upper Bound`);
                }
                if (newState.xmiType === "logical:RealRangeConstraint" && ((!this.state.lowerBound && this.state.lowerBound !== 0) || (!this.state.upperBound && this.state.upperBound !== 0))) {
                    errors.push("Both Upper and Lower Bounds must be set.");
                }
                if (newState.xmiType === "platform:RealRangeConstraint" && !(this.state.lowerBound && this.state.lowerBound !== 0) && (!this.state.upperBound && this.state.upperBound !== 0)) {
                    errors.push("At least one bound must be set.");
                }
                // if (newState.upperBound < newState.lowerBound) {
                //     errors.push("Lower Bound must be less than Upper Bound");
                // }

                break;
        }

        return errors;
    }

    renderBoundsSelection() {
      const phenomId = this.phenomId;
        switch (this.state.xmiType) {
            case undefined:
                return (null);
            case "logical:RegularExpressionConstraint":
            case "platform:RegularExpressionConstraint":
                return (<div className="flex-v">
                    <LineLabel text="Expression" idCtx={phenomId.gen(["details","expression"],"")}/>
                    <CadetInput text={this.state.expression} idCtx={phenomId.gen("","")} onChange={this.changeState}
                        disabled={!!this.state.guid} />
                </div>);
            default:
                const isIntegerRangeConstraint = this.state.xmiType === "logical:IntegerRangeConstraint" ||
                    this.state.xmiType === "platform:IntegerRangeConstraint";
                const cadetInputType = isIntegerRangeConstraint ? "number" : "text";

                return (<div className="flex-v" id={phenomId.gen(["details","bounds-selection"],"wrapper")}>
                    <div className="flex-h">
                        <div className="flex-v" style={{flexGrow: 10, marginRight: 7.5}}>
                            <LineLabel text="Lower Bound" idCtx={phenomId.gen(["bounds-selection","lowerBound"],"")}/>
                            <div className="flex-h">
                                <CadetInput
                                    type={cadetInputType}
                                    text={this.state.lowerBound}
                                    idCtx={phenomId.gen("lowerBound","")}
                                    onChange={this.changeState}
                                    disabled={!!this.state.guid}
                                />
                                {isIntegerRangeConstraint ||
                                    <Toggle
                                        options={["Inclusive", "Exclusive"]}
                                        startingPosition={this.state.lowerBoundInclusive ? 0 : 1}
                                        toggleFunction={() => this.setState({lowerBoundInclusive: !this.state.lowerBoundInclusive})}
                                        style={{width: 105, marginTop: 10, marginRight: 0, backgroundColor: "#fff"}}
                                        idCtx={phenomId.gen("","inclusive-exclusive")}
                                        disabled={!!this.state.guid}
                                    />}
                            </div>
                        </div>
                        <div className="flex-v" style={{flexGrow: 10, margin: "0 7.5px 0 15px"}}>
                            <LineLabel text="Upper Bound" idCtx={phenomId.gen(["bounds-selection","upperBound"],"")}/>
                            <div className="flex-h">
                                <CadetInput
                                    type={cadetInputType}
                                    text={this.state.upperBound}
                                    idCtx={phenomId.gen("upperBound","")}
                                    onChange={this.changeState}
                                    disabled={!!this.state.guid}
                                />
                                {isIntegerRangeConstraint ||
                                    <Toggle
                                        idCtx={phenomId.gen("","inclusive-exclusive")}
                                        options={["Inclusive", "Exclusive"]}
                                        startingPosition={this.state.upperBoundInclusive ? 0 : 1}
                                        toggleFunction={() => this.setState({upperBoundInclusive: !this.state.upperBoundInclusive})}
                                        style={{width: 105, marginTop: 10, marginRight: 0, backgroundColor: "#fff"}}
                                        disabled={!!this.state.guid}
                                    />}
                            </div>
                        </div>
                    </div>
                </div>);
        }
    }

    resetConstraintType = () => {
        const resetState = {
            expression: null,
            upperBound: null,
            lowerBound: null,
            upperBoundInclusive: null,
            lowerBoundInclusive: null
        };

        switch (this.state.xmiType) {
            case "platform:RegularExpressionConstraint":
            case "logical:RegularExpressionConstraint":
                resetState.expression = "";
                break;

            case "platform:RealRangeConstraint":
            case "logical:RealRangeConstraint":
                resetState.upperBoundInclusive = true;
                resetState.lowerBoundInclusive = true;
            case "platform:IntegerRangeConstraint":
            case "logical:IntegerRangeConstraint":
                resetState.upperBound = "";
                resetState.lowerBound = "";
                break;
        }

        this.setState({...resetState});
    }


    handleTypeChange = async (e) => {
      const target = e.target.id.match("xmiType")[0]
      await this.setState({[target]: e.target.value});
      this.resetConstraintType();
    }

    render() {
        const phenomId = this.phenomId;
        return (
            <div className="subview-wrapper flex-v" style={{minHeight: 512}}>
                <Notifications ref={ele => this.noticeRef = ele} />
                <div className="flex-h">
                    <div className="flex-v" style={{flexGrow: 1}}>
                        <LineLabel text="CONSTRAINT" idCtx={phenomId.gen("details","name")}/>
                        <CadetInput text={this.state.name} idCtx={phenomId.gen("details","name")} onChange={this.changeState}
                            disabled={!!this.state.guid} />

                        {this.state.constraintType !== "logical" &&
                            <><LineLabel text="Parent Package" />
                                <select
                                    className="cadet-select"
                                    value={this.state.parent}
                                    disabled={!!this.state.guid}
                                    id={phenomId.gen("parent-package","select")}
                                    onChange={this.changeState}>
                                    <option id={phenomId.gen(["parent-package",`default`],"option")} value="">No Package Selected / Use Default</option>
                                    {this.state.possibleParents.map((pack,pIdx) => {
                                        return (<option id={phenomId.gen(["parent-package",`${pIdx}`],"option")} value={pack.guid}>{pack.name}</option>);
                                    })}
                                </select></>
                        }
                    </div>
                    <div className="edit-side-bar">
                        <NodeHistory2 ref={ele => this.historyRef = ele} guid={this.state.guid} idCtx={phenomId.genPageId()}/>
                        {/* <ChangeSetPicker id={phenomId.genPageId()} label="Change Set" /> */}
                    </div>
                </div>
                <LineLabel text="Description" idCtx={phenomId.gen("details","description")}/>
                <CadetTextArea text={this.state.description} idCtx={phenomId.gen("details","description")} onChange={this.changeState}
                    disabled={!!this.state.guid} />
                <LineLabel text="Constraint Type" idCtx={phenomId.gen(["details","constraint-xmiType"],"")}/>
                {!!this.state.guid || !!this.props.primitive ||
                    <select className="cadet-select"
                        id={phenomId.gen("constraint-xmiType","select")}
                        onChange={this.handleTypeChange}
                        value={this.state.xmiType}
                        disabled={!!this.state.guid || !!this.props.primitive}>
                        {Object.entries(this.xmiTypeMap).filter(([k, v]) => k.split(":")[0] == this.state.constraintType).map(([type, value],tIdx) => {
                            return <option value={type} id={phenomId.gen(["constraint-xmiType",`${tIdx}`],"option")}>{value}</option>
                        })}
                        {/* <option value="platform:RegularExpressionConstraint">Regular Expression Constraint</option>
                    <option value="platform:RealRangeConstraint">Real Range Constraint</option>
                    <option value="platform:IntegerRangeConstraint">Integer Range Constraint</option> */}
                    </select>}
                {(!this.state.guid && !this.props.primitive) ||
                    <CadetInput text={this.xmiTypeMap[this.state.xmiType]} idCtx={phenomId.gen("details","type-map")}/>}
                {this.renderBoundsSelection()}
                {!!this.state.guid ||
                    <LoaderButton className="filled-button"
                        idCtx={phenomId.genPageId()}
                        text="SAVE"
                        onClick={this.handleSave}
                    />}
            </div>
        );
    }
}


export const EditConstraintManager = withPageLayout(ConstraintManager, { renderDeleteBtn: false, renderResetBtn: false });
