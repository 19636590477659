import $ from "jquery";
import { cloneDeep } from "lodash";
import { receiveErrors, receiveResponse, receiveWarnings, removeNodes } from "./actionCreators";
import { convertNullToUndefined } from "../components/util/util";
import { ModelLeaf, BranchLeaf, ProjectLeaf, FeauxLeaf } from "../components/tree/leaf/ModelLeaf";


// INDEX
// ------------------------------------------------------------
// # User
// # Settings
// # Data Model
// # Manage
// ## Detail Page
// # Uncategorized
// ------------------------------------------------------------
export const ajaxSuccess = (res) => {
  try {
    const response = JSON.parse(res);

            if (typeof response?.error === "string") {
              return Promise.reject({
                status: 422,
                localError: response.error,
              })
            }

            // push/pull/approve page can an array of objects
            // only process array of string, otherwise assume something else will handle it
            if (Array.isArray(response?.errors) && response.errors.some(ele => typeof ele === "string")) {
              return Promise.reject({
                status: 422,
                localErrors: response.errors,
              })
            }

            return response;
  } catch (error) {
    return Promise.reject({
      status: 422,
      localError: 'Server read invalid input',
    })
  }
}

export function _ajax(settings={}, customErrors={}) {
  return $.ajax(settings)
          .then((res) => {
            const response = JSON.parse(res);

            if (typeof response?.error === 'string') {
              return Promise.reject({
                status: 422,
                localError: response.error,
                localWarnings: response.warnings
              })
            }

            // Targets SMLCurlable generic error message (yii)
            if (typeof response.errors === 'string') {
              return Promise.reject({
                status: 422,
                localError: response.errors,
                localWarnings: response.warnings
              })
            }

            // push/pull/approve page can an array of objects
            // only process array of string, otherwise assume something else will handle it
            if (Array.isArray(response?.errors) && response.errors.some(ele => typeof ele === "string")) {
              return Promise.reject({
                status: 422,
                localErrors: response.errors,
                localWarnings: response.warnings
              })
            }

            return response;

          }).catch((jqXHR) => {
            const resFailed = Promise.reject({
              status: jqXHR?.status || 500,
            });

            if (customErrors[jqXHR?.status]) {
              receiveErrors(customErrors[jqXHR?.status]);
              receiveWarnings(jqXHR.localWarnings);
              return resFailed;
            }

            // reject cases from above
            if (typeof jqXHR?.localError === 'string' || Array.isArray(jqXHR?.localErrors)) {
              receiveErrors(jqXHR.localError || jqXHR.localErrors);
              receiveWarnings(jqXHR.localWarnings);
              return resFailed;
            }

            // default error messages:
            switch (jqXHR?.status) {
              case 0:
                // receiveErrors("Previous request cancelled");
                break;
                
              case 408:   // Request Timeout
                receiveErrors("The request unexpectedly timed out. Please contact Skayl support for more help.");
                break;
              
              case 422:   // Unprocessable Content
              case 500:   // Internal Server Error
              default:
                receiveErrors("The server responded with an unexpected status code. Please contact Skayl support for more help.")
            }

            return resFailed;
          })
}

// ------------------------------------------------------------
// # User
// ------------------------------------------------------------
export function getCurrentModelRefIndex() {
  return _ajax({
    url: "/index.php?r=/sub-model/current-model-ref-index",
    method: "get",
  })
}

export function wsLoaded() {
  return $.ajax({
    method: "get",
    url: "/index.php?r=/site/ws-loaded",
  })
}



// ------------------------------------------------------------
// # Settings
// ------------------------------------------------------------
export function getAccountInfo() {
  return _ajax({
      url: "/index.php?r=/account/info",
      method: "get",  
  })
}

export function getAccountLicenses() {
  return _ajax({
    url: "/index.php?r=/account/get-licenses",
    method: "get",    
  })
}

// ------------------------------------------------------------
// # Manage
// ------------------------------------------------------------

/**
 * 
 * @param {string} passedUser when desired user is different from logged in user
 * @returns 
 */
const fetchProjects = (passedUser) => {
  return _ajax({
    url: "/index.php?r=/referencing-model/user-projects",
    passedUser,
  })
}

const fetchModels = () => {
  return _ajax({
    url: "/index.php?r=/sub-model/users-index-tree",
    method: "post",
    // data: {passedUser: passedUser}
  })
}

/**
 * 
 * @param {string} activeUser current user's username
 * @param {number} activeProjectId current active Project - hint: this.props.userIdentity.branchId (because the names got shifted?)
 * @returns 
 */
export const fetchProjectsAndModels = (activeUser, activeProjectId) => {
    // const activeUser = this.props.userIdentity.username;
    // const activeProjectId = parseInt(this.props.userIdentity.branchId);
    const projectIndex = {};
    const branchIndex = {};
    const modelIndex = {};

    return Promise.all([fetchModels(), fetchProjects()]).then(res => {
      const traverse = (data) => {
        // A) Find or create Branch Leaf
        const branch = { id: parseInt(data.branchId), name: data.branchName, branchCreated: data.branchCreated}
        let branchLeaf = branchIndex[branch.id];
        if (!branchLeaf) {
          branchLeaf = new BranchLeaf(branch);
          branchIndex[branch.id] = branchLeaf;
        }

        // B) Clean up some data
        data.dependencies = JSON.parse(data.dependencies);
        data.family = data.family.filter(fam => fam.created || !fam.model).map(fam => parseInt(fam.id));

        // C) Assign Sub Model to Branch
        const modelLeaf = new ModelLeaf(data);
        modelIndex[data.id] = modelLeaf;
        if (!/.*i.*/.test(modelLeaf.getUserPermission(activeUser))) {
          modelLeaf.setBranchLeaf(branchLeaf);
          branchLeaf.setModelLeaf(modelLeaf);
        }

        // note: children can be null/undefined, an empty array or a hash table of arrays
        if (data.children) {
          Object.values(data.children).forEach(array => {
            array.forEach(child => {
              const childBranchLeaf = traverse(child);
              childBranchLeaf.setParentLeaf(branchLeaf);
              branchLeaf.setChildLeaf(childBranchLeaf);
            })
          })
        }

        return branchLeaf;
      }

      // 1) Populate Model and Branch Index
      // note: res is a hash table of arrays of objects
      Object.values(res[0].data).forEach((models) => {
        for (let data of models) {
          traverse(data);
        }
      })

      // 2) Populate Project Index
      const projects = res[1].data.usersModels || [];  // usersModels is from handler
      for (let project of projects) {
        const projectLeaf = new ProjectLeaf(project);
        projectIndex[project.id] = projectLeaf;
        projectLeaf.parent_project = project.parent_project;

        if (projectLeaf.getId() === activeProjectId) {
          projectLeaf.setExpanded(true);
        }

        for (let model_id of project["subModelIds"]) {
          const modelLeaf = modelIndex[model_id];

          if (!modelLeaf) {
            continue;
          }

          if (/.*i.*/.test(modelLeaf.getUserPermission(activeUser))) continue;

          projectLeaf.setModelLeaf(modelLeaf);
          modelLeaf.setProjectLeaf(projectLeaf);
        }
      }

      // Assign Project's parent-child relationship
      for (let projectId in projectIndex) {
        const projectLeaf = projectIndex[projectId];
        const parentId = projectLeaf.getProjectParentId();
        const parentLeaf = projectIndex[parentId];

        if (parentLeaf) {
          const feauxLeaf = new FeauxLeaf({});

          parentLeaf.setChildLeaf(feauxLeaf);
          feauxLeaf.setParentLeaf(parentLeaf);

          projectLeaf.setParentLeaf(feauxLeaf);
          feauxLeaf.setChildLeaf(projectLeaf);
        }
      }
      
      return { branchIndex, modelIndex, projectIndex };
    })
  }

// ------------------------------------------------------------
// ## Detail Page
// ------------------------------------------------------------
/**
 * Get node with addenda
 *
 * @param {string} guid
 * @param {object} addenda
 * @returns Promise
 */
export function modelGetNode(guid, addenda = {}) {
  return _ajax({
    url: "/index.php?r=/node/model-get-node",
    method: "get",
    data: { guid, ...addenda },
  }).fail(err => {
    receiveErrors(`We were unable to retrieve the node with xmi:id '${guid}'.`)
  })
}

/**
 * Get node with addenda
 *
 * @param {*} type a xmi:type or an array of xmi:type strings
 * @param {object} addenda
 * @returns Promise
 */
export function modelNodesOfType(type, addenda = {}) {
  return _ajax({
      url: "/index.php?r=/node/model-nodes-of-type",
      method: "get",
      data: { type, ...addenda },
  });
}

/**
 * Checks the node(s) against the meta model before saving
 *
 * @param {object} requestData formatted as {
 *                                node: { guid, xmiType}
 *                                nodes: [node, node],
 *                                returnTypes: [xmiTypes],
 *                                returnAddenda: { xmiType: {addenda} },
 *                              }
 * @returns Promise
 */
export function smmSaveNodes(requestData) {
  const { node, nodes } = requestData;

  return _ajax({
    method: "post",
    url: "/index.php?r=/node/smm-save-nodes",
    data: {
      ...requestData,
      node: convertNullToUndefined(cloneDeep(node)),
      nodes: convertNullToUndefined(cloneDeep(nodes)),
    }
  })
}

export function getNodeEffectiveType(guid) {
    return $.ajax({
        url: "/index.php?r=/node/get-node-effective-type",
        data: { 
            guid
        }
    })
}


export function getNodeProjectors(guid) {
    return _ajax({
        url: "/index.php?r=/entity/get-projectors",
        data: { 
            guid
        }
    })
}




// ------------------------------------------------------------
// # Uncategorized
// ------------------------------------------------------------
export function modelTypesOfNode(guid_path) {
    return _ajax({
        method: "get",
        url: "/index.php?r=/sml/model-types-of-node",
        data: {guid_path: guid_path},
    });
}

export function modelDeprecateNode(guid, changeSetId) {
    return _ajax({
        method: "post",
        url: "/index.php?r=/edit/deprecate",
        data: {
            element: guid,
            changeSetId: changeSetId
        }
    });
}

export function modelDeprecateNodeMulti(guids, changeSetId) {
    return _ajax({
        method: "post",
        url: "/index.php?r=/edit/deprecate-multi",
        data: {
            elements: guids,
            changeSetId: changeSetId
        }
    });
}

export function smmCanDelete(guids=[], severances=[]) {
  return _ajax({
    url: "/index.php?r=/node/smm-can-delete-node",
    method: "POST",
    data: {
      guids,
      severances,
    }
  })
}

export function smmDeleteNodes(deleted_guids=[], severances=[], changeSetId) {
  return _ajax({
    url: "/index.php?r=/node/smm-delete-node",
    method: "POST",
    data: {
        guids: deleted_guids,
        severances,
        changeSetId,
    },
  })
}

export function modelRemoveNode(guid, changeSetId) {
    return _ajax({
        method: "post",
        url: "/index.php?r=/node/smm-delete-node",
        data: {
            guid,
            changeSetId
        }
    })
    .then((response) => {
        receiveResponse(response);

        if (response.status === "success") {
          removeNodes([ guid ]);
          return true;    // old code expects boolean
        }

        return false;
    })
    .catch(() => {
      receiveErrors("The server responded with an unexpected status. Please try again.")
      return false;
    })
}

export function nodeHistory(guid) {
    return _ajax({
        url: "/index.php?r=/sml/node-history",
        method: "get",
        data: {guid: guid}
    });
}

export function elementDetail(guid) {
    return _ajax({
        method: "get",
        url: "/index.php?r=/detail/element-detail/",
        data: {element: guid},
    });
}

export function getTags(guid) {
    return _ajax({
        method: "get",
        url: "/index.php?r=/detail/get-tags",
        data: {guid: guid},
    });
}

export function getMoveImpact(data) {
    return _ajax({
        url: "/index.php?r=/detail/get-move-impact/",
        method: "post",
        data: data,
    });
}

export function moveNode(data) {
    return _ajax({
        url: "/index.php?r=/edit/move-node/",
        method: "post",
        data: data,
    });
}

// used by View Trace
export function getDiagramFiles(category) {
    return $.ajax({
        method: "get",
        url: "/index.php?r=/diagram/_diagram-files-data",
        data: {category: category}
    });
}

// used by View Trace
export function saveDiagramImage(diagramId, image) {
    return $.ajax({
        method: "post",
        url: "/index.php?r=/diagram/save-diagram-image",
        data: { diagramId, image }
    });
}

export function loadDiagrams(data) {
    return $.ajax({
        url: "/index.php?r=/diagram/_load-diagrams",
        method: "post",
        data: data,
    });
}

export function runHealthReport(data) {
    return _ajax({
        url: "/index.php?r=/detail/run-report",
        method: "post",
        data: data,
    });
}

export function runStatReport(data) {
    return _ajax({
        url: "/index.php?r=/detail/stat-report",
        method: "post",
        data: data,
    });
}

export function getThingsOfType(type) {
    return $.ajax({
        url: "/index.php?r=/detail/get-things-of-type",
        method: "get",
        data: {type: type},
    });
}

export function getNodeWithAddenda(guid = null, addenda = {}, customErrors) {
    const requestConfig = {
      url: "/index.php?r=/node/model-get-node",
      method: "get",
      data: { guid, ...addenda },
      error: (err) => {
        if(err.status === 500) {            
        window.dispatchEvent(new CustomEvent('MODEL_GET_NODE_404', { detail: { guid }}));
      }},
    };

    return _ajax(requestConfig, customErrors);
}

export function getNodesOfType(type, addenda = {}) {
    return _ajax({
        url: "/index.php?r=/node/model-nodes-of-type",
        method: "get",
        data: { type: type, ...addenda },
    });
}

export function getDeletableStatus(guid) {
    return _ajax({
        url: "/index.php?r=/node/can-delete-node",
        method: "get",
        data: {guid: guid},
    });
}

export function getSemantics(guid) {
    return _ajax({
        url: "/index.php?r=/characteristic/get-semantics",
        method: "get",
        data: {guid: guid},
    });
}

export function getSemanticsMulti(guids) {
    return _ajax({
        url: "/index.php?r=/characteristic/get-semantics-multi",
        method: "post",
        data: {guids: guids},
    });
}

export function fetchAvailableTags() {
    return _ajax({
        url: "/index.php?r=/detail/available-tags",
    });
}

export function getSemanticEquivalencyByTag(tag1, tag2) {
    return _ajax({
        url: "/index.php?r=/detail/get-group-view-equivalency",
        method: "get",
        data: {tag1: tag1, tag2: tag2},
    });
}

export function runEntityUniquenessReport() {
    return _ajax({
        url: "/index.php?r=/detail/entity-uniqueness-report",
        method: "get",
    });
}

export function getModelStats() {
    return _ajax({
        url: "/index.php?r=/detail/model-stats",
        method: "get"
    });
}

export function getCSRFToken() {
    return $.ajax({
        url: "/index.php?r=/site/get-token",
        method: "get",
    });
}



export function getActiveChangeSets() {
    return _ajax({
        url: "/index.php?r=change-set/get-active-change-sets",
        method: "get"
    })
}


export function getChangeSetContent(changeSetId) {
    return $.ajax({
        method: "GET",
        url: "/index.php?r=/change-set/contents",
        data: {
            changeSetId,
            mergeMode: "push",
        },
    })
}

export function setEquality(srcGuid, dstGuid) {
    return _ajax({
        url: "/index.php?r=/edit/set-equality",
        method: "post",
        data: {src: srcGuid, dst: dstGuid}
    })
}

export function verifyEquation(equation, srcGuid, dstGuid) {
  return _ajax({
    url: "/index.php?r=/conversion/verify-equation",
    method: "post",
    data: {equation: equation, srcGuid: srcGuid, dstGuid: dstGuid}
  })
}

/**
 *
 * @param {guid} viewIn
 * @param {guid} viewOut
 * @param {array} manualTransforms
 */
export function getTransformCode(viewIn="", viewOut="", manualTransforms=[]) {
  // leave as standard $.ajax for less error handling
  return $.ajax({
    url: "/index.php?r=/integration-manual-transform/get-transform-code",
    method: "get",
    data: {
        viewIn: viewIn,
        viewOut: viewOut,
        manualTransforms: manualTransforms,
      },
  })
}

export function getLicense() {
  return _ajax({
    url: "/index.php?r=/site/license",
    method: "get"
  })
}

export function acceptLicense(license_version) {
  return _ajax({
    url: "/index.php?r=/site/accept-license",
    method: "post",
    data: {
        license_version: license_version,
      }
  })
}

export function getActiveProjectChanges(recordLimit) {
    return _ajax({
        url: "/index.php?r=/node/historical-data",
        method: "get",
        data: { recordLimit },
    });
}

export function getUsernameFromResetCode(resetCode) {
    return _ajax({
        url: "/index.php?r=/site/get-username-from-reset-code",
        method: "get",
        data: { resetCode },
    });
}
