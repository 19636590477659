import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import SkaylLogo from "../../images/skayl_logo.jpg";


const Footer = (props) => {
  const [copyright, setCopyright] = useState("");

  useEffect(() => {
    const curr = new Date(Date.now());
    setCopyright( `©${curr.getFullYear()}` );
  }, []);

  const doNotShowFooter = ["edit", "integration", "generate", "manage"].some((mode) => {
    const regex = new RegExp(`^\/${mode}\/(.*)`);
    return props.location.pathname.match(regex);
  })

  if (doNotShowFooter) {
    return null;
  }

  return <footer className="footer-container">
            <div id="u12656-15">
                <span id="u12656-8">PHENOM™</span>
                <span id="u12656-10"> is a product of </span>
                <span>
                    <a className="nonblock" href="https://www.skayl.com" target="_blank"
                        rel="noopener noreferrer">Skayl, LLC</a>
                    &nbsp;
                    { copyright }
                </span>
            </div>

            <div>
                <img src={SkaylLogo} />
            </div>

            <div style={{ display: "flex", gap: 10, textTransform: "uppercase" }}>
              <a href="https://www.skayl.com/terms-of-use" target="_blank">Terms of Use</a> 
              <a href="https://www.skayl.com/privacy-policy-1" target="_blank">Privacy Policy</a>
            </div>
          </footer>
}


export default withRouter(Footer);