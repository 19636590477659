import React, {useState, useEffect} from "react";
import Draggable from "react-draggable";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import blankDiagram from "../../../../../images/blank_diagram.png";





export default function UnsavedPrompt({ selectedTab={}, data=[], dataGuidKey, dataNameKey, stormType, phenomId, onSelect, onSaveDiagram, onCommitDiagram, onContinue, onCancel }) {
  const showCommitButton = stormType !== "view_trace";
  const isSaved = selectedTab.ref?.getDiagramSaveStatus && selectedTab.ref.getDiagramSaveStatus();


  return <div className="storm-prompt-content" id={phenomId.genPageId("load-prompt")}>
            <div className="p-row row-no-gutter-row">
              <div className="p-col p-col-12">Select a diagram:</div>

              <ul className="p-col p-col-6 p-no-gutter storm-prompt-box storm-prompt-selection" id={phenomId.genPageId("selection-list")}>
                {data.map((item) => {
                  const isTabSaved = item.ref?.getDiagramSaveStatus && item.ref.getDiagramSaveStatus();

                  return <li key={item[dataGuidKey]}
                            className="storm-prompt-selection-item"
                            id={phenomId.genPageId("selection-item", item[dataGuidKey])}
                            style={{ backgroundColor: item[dataGuidKey] === selectedTab[dataGuidKey] ? "rgba(3, 15, 222, 0.5)" : null }}
                            onClick={() => onSelect(item)}>
                          {item[dataNameKey]} {isTabSaved ? "" : "(unsaved)"}
                        </li>
                })}
              </ul>

              <div className="p-col p-col-6" style={{ display: "flex", flexDirection: "column", gap: 6 }}>
                <button className="storm-prompt-unsaved-button"
                        onClick={onSaveDiagram}
                        id={phenomId.genPageId("save-btn")}
                        disabled={isSaved}>
                  <i className="fas fa-save" />
                  <div>
                    <h3>Save Diagram</h3>
                    <p>Save your diagram.</p>
                  </div>
                </button>

                {showCommitButton &&
                <button className="storm-prompt-unsaved-button"
                        id={phenomId.genPageId("commit-btn")}
                        onClick={onCommitDiagram}>
                  <i className="fas fa-project-diagram" />
                  <div>
                    <h3>Commit</h3>
                    <p>Save uncommitted changes</p>
                  </div>
                </button> }

                <button className="storm-prompt-unsaved-button"
                        id={phenomId.genPageId("continue-btn")}
                        onClick={onContinue}>
                  <i className="fas fa-door-open" />
                  <div>
                    <h3>Continue</h3>
                    <p>Discard unsaved changes and then exit</p>
                  </div>
                </button>

                <button className="storm-prompt-unsaved-button"
                        id={phenomId.genPageId("cancel-btn")}
                        onClick={onCancel}>
                  <i className="fas fa-arrow-alt-circle-left" />
                  <div>
                    <h3>Cancel</h3>
                    <p>Go back</p>
                  </div>
                </button>
              </div>
            </div>
  </div>
}

