import React from "react";
import {
    DefaultLinkFactory,
    DefaultLinkModel,
} from "@projectstorm/react-diagrams-defaults";
import {LinkWidget} from "@projectstorm/react-diagrams-core";
import styled from "@emotion/styled";
import { pointProps } from "../node/LineShape";


import { DiagramLinkWidget, CustomLinkArrowWidget } from './DiagramLink';


const Path = styled.path`
  fill: none;
  pointer-events: all;
`;


export class ShapeLinkFactory extends DefaultLinkFactory {
	constructor(type = 'shape-link') {
    super(type);
	}

	generateModel() {
		return new ShapeLinkModel();
	}

	generateReactWidget(event) {
		return <ShapeLinkWidget link={event.model} diagramEngine={this.engine} />;
  }

  generateLinkSegment(model, selected, path) {
		return <Path stroke={selected ? model.getOptions().selectedColor : model.getOptions().color}
                 strokeWidth={model.getOptions().width}
                 d={path} />
	}
}


export class ShapeLinkModel extends DefaultLinkModel {
	constructor() {
		super({
			type: 'shape-link',
			width: 3
        });
  }

  forceLinkToUpdate = () => {
  }
}



export class ShapeLinkWidget extends DiagramLinkWidget {
  createPointAtIdx = -1;
  pointCreated = false;
  mouseEvent = null;

  removeLink = () => {
    const {sourcePort, targetPort} = this.props.link;
    
    const srcNodeModel = sourcePort.getParent();
    const tarNodeModel = targetPort.getParent();

    this.$app.model.removeLink(this.linkModel);
    this.$app.model.removeNode(srcNodeModel);
    this.$app.model.removeNode(tarNodeModel);
    
    this.props.diagramEngine.repaintCanvas();
  }

  moveMouseToAddPoint = (e) => {
    e.preventDefault();
    e.persist = this.mouseEvent.persist

    let points = this.props.link.getPoints();

    if(!this.pointCreated) {
      this.addPointToLink(e, this.createPointAtIdx);
      this.pointCreated = true;
    };

    let point = points[this.createPointAtIdx];
    point.extras = {isBendPoint: true}
    let coord = this.$app.engine.getRelativeMousePoint(e);
    point.setPosition(coord);
  }

  cleanUpAfterMouseMove = (e) => {
    window.removeEventListener("mousemove", this.moveMouseToAddPoint);
    this.createPointAtIdx = -1;
    this.pointCreated = false;
    this.mouseEvent = null;
  }

  // override
  generateArrow(point, previousPoint, arrowHead) {
      return <CustomLinkArrowWidget key={point.getID()} 
                                    arrowHead={arrowHead} 
                                    point={point} 
                                    previousPoint={previousPoint}
                                    colorSelected={this.props.link.getOptions().selectedColor}
                                    color={this.props.link.getOptions().color}/>
  }

  // override
  generateHandle(point, port) {
      return <ShapeHandle key={point.getID()} 
                          point={point}
                          port={port} link={this} 
                          engine={this.props.diagramEngine}
                          color={this.props.link.getOptions().selectedColor} />
  }

	render() {
    const {sourcePort, targetPort} = this.props.link;

		//ensure id is present for all points on the path
		var points = this.props.link.getPoints();
		var paths = [];
    this.refPaths = [];
		
    //draw the multiple anchors and complex line instead
    for (let j = 0; j < points.length - 1; j++) {
      paths.push( this.generateLink(
          LinkWidget.generateLinePath(points[j], points[j + 1]),
          {
            'data-linkid': this.props.link.getID(),
            'data-point': j,
            onMouseDown: (e) => {
                if(e.ctrlKey) {
                  this.mouseEvent = e;
                  this.createPointAtIdx = j + 1;
                  window.addEventListener("mousemove", this.moveMouseToAddPoint);
                  window.addEventListener("mouseup", this.cleanUpAfterMouseMove);
                } else {
                  // Selects the entire line so it can be dragged and moved
                  this.props.link.setSelected(true);
                  this.props.link.points.forEach(point => point.setSelected(true));
                  [sourcePort.parent, targetPort.parent].forEach(p => p.setSelected(true));
                }
            },
            onMouseUp: (e) => {
                this.props.diagramEngine.model.clearSelection();
                this.linkModel.setSelected(true);
            },
          },
          j
      ))
    }

    // render the circles
    for (let i = 1; i < points.length - 1; i++) {
        paths.push(this.generatePoint(points[i]));
    }

    if(this.linkModel.isSelected()) {
      var linkClassName = "link-selected";
      paths.push(this.generateHandle(points[0], sourcePort));
      paths.push(this.generateHandle(points[points.length - 1], targetPort));
    
    // render Arrow
    } else if(targetPort !== null && targetPort.options.arrowHead) {
      paths.push(this.generateArrow(points[points.length - 1], points[points.length - 2], targetPort.options.arrowHead));
    }

		return <g className={linkClassName} 
              data-default-link-test={this.props.link.getOptions().testName}>
                {paths}
           </g>
	}
}





const PointTop = styled.circle`
  pointer-events: all;
  cursor: pointer;
`;

const ShapeHandle = props => {
  const {point, port, link, engine} = props;

  return <g transform={"translate(" + point.getPosition().x + ", " + point.getPosition().y + ")"} style={{zIndex:5}}>
            <circle r="5" fill={props.color} />
            <PointTop r={15}
                      opacity={0}
                      onMouseDown={(e) => {
                        e.stopPropagation();
                        startMoveArrow(point, port, engine, link)
                      }} />
      </g>
}


export const startMoveArrow = (movingPoint, port, engine, link) => {
  // engine.$app.domModelLayerOnTop();
  let nodeModel = port.parent;    
  let original_pos = port.getPosition();

  const moveArrow = (e) => {
    const mouse_pos = engine.getRelativeMousePoint(e);
    movingPoint.setPosition(mouse_pos.x, mouse_pos.y);
    link.forceUpdate();
  }

  const repositionNodeModel = (e) => {
    const mouse_pos = engine.getRelativeMousePoint(e);

    // reattach the point to the port
    movingPoint.setPosition(original_pos);
    nodeModel.setPosition(mouse_pos.x - (pointProps.width / 2), mouse_pos.y - (pointProps.width / 2));

    cleanUp();
    link.forceUpdate();
    engine.repaintCanvas();
  }

    const cleanUp = () => {
      // engine.$app.domSvgLayerOnTop();

      window.removeEventListener("mousemove", moveArrow);
      window.removeEventListener("mouseup", repositionNodeModel);
    }

  window.addEventListener("mousemove", moveArrow);
  window.addEventListener("mouseup", repositionNodeModel);
}