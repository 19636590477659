import React, { useRef, useState, useEffect, useImperativeHandle } from 'react'

function isStringEmpty(str) {
  return !str;
}

function isValidAllChars(str) {
  return /^[a-zA-Z0-9_]+$/.test(str);
}

function isValidFirstChar(str) {
  return /^[a-zA-Z]/.test(str);
}

function createErrorMessage(config={}, text="") {
  const { required, checkFirstChar, checkAllChars } = config;

  if (required && isStringEmpty(text)) {
    return "This field is required.";

  } else if (checkFirstChar && !isValidFirstChar(text)) {
    return "Must begin with a letter.";

  } else if (checkAllChars && !isValidAllChars(text)) {
    return "Must consist of letters, numbers, and underscores.";

  } else {
    return "";
  }
}


export const useInputErrorMsg = (text="", config={}, originalText) => {
  let isMounted = useRef(false);
  let [errorMsg, setErrorMsg] = useState(""); 

  // exposes methods to the parent component
  // normally you're supposed to use a normal ref but it overrides the default use case.  so this attaches the method to the config.errorRef
  useImperativeHandle(config?.errorRef, () => ({
    checkForErrors: () => {
      isMounted.current = true;   // I noticed a mounting issue for new detail pages, need to revisit this
      setErrorMsg(createErrorMessage(config, text));
    }
  }), [text])

  useEffect(() => {
      isMounted.current = false;
      setErrorMsg("");
  }, [originalText])

  useEffect(() => {
    // this triggers only on update and not on mount
    if (isMounted.current) {
      setErrorMsg(createErrorMessage(config, text));

    } else {
      isMounted.current = true
    }
  }, [text])

  return errorMsg;
}
