import React from 'react'
import ReactDOM from "react-dom";

class Portal extends React.Component {
  constructor(props) {
    super(props);
    this.parent = document.querySelector("#root");
    this.portal = document.createElement('div');
    this.portal.classList.add("portal");
  }

  componentDidMount() {
    this.parent.appendChild(this.portal);
  }

  componentWillUnmount() {
    this.parent.removeChild(this.portal);
  }

  render() {
    return ReactDOM.createPortal(
      this.props.children,
      this.portal
    )
  }
}

export default Portal;

