import React from "react";
import {Grid, GridColumn as Column, GridNoRecords} from "@progress/kendo-react-grid";
import {orderBy} from "@progress/kendo-data-query";
import PhenomId from "../../requests/phenom-id";

export default class ChangeSetTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sort: [],
        }

        this.colorGuidCell = this.colorGuidCell.bind(this);
        this.colorMap = {
            Create: "green",
            Edit: "blue",
            Delete: "crimson",
            Deprecate: "orange",
        };
        this.phenomId = new PhenomId("change-set-table",this.props.idCtx);
    }

    colorGuidCell(props) {
        const csMemberDatum = props.dataItem

        return (
            <td
                style={{color: this.colorMap[csMemberDatum.action], width: "fit-content", width: "-moz-fit-content"}}>
                {csMemberDatum.guid}
            </td>
        );
    }

    render() {
        const phenomId = this.phenomId;
        return (
        <Grid
            id={this.phenomId.gen("","wrapper")}
            data={orderBy(this.props.changeSetContent, this.state.sort)}
            style={{marginTop: 15}}
            sort={this.state.sort}
            sortable={{
                allowUnsort: true,
                mode: 'multiple'
            }}
            onSortChange={(e) => this.setState({sort: e.sort})}
            >
            <GridNoRecords>
                No Data Is Available For This Table <br/> This change set is either empty or the current branch is up to date on all of this change set's contents.
            </GridNoRecords>
            <Column title="GUID" cell={this.colorGuidCell}/>
            <Column title="Name" field="name"/>
            <Column title="Type" field="xmiType"/>
            <Column title="Created" field="created"/>
        </Grid>
    );}
}
