import React from "react";
import {LineLabel} from "../../util/stateless";
import {Grid, GridColumn as Column, GridNoRecords} from "@progress/kendo-react-grid";
import {orderBy} from "@progress/kendo-data-query";
import {NavLink} from "react-router-dom";
import PhenomId from "../../../requests/phenom-id"

class TyperViews extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            composedIn: this.props.typers.filter(typer => typer.tag === "composition"),
            associatedIn: this.props.typers.filter(typer => typer.tag === "associatedEntity"),
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.typers !== this.props.typers) {
            this.setState({
                composedIn: this.props.typers.filter(typer => typer.tag === "composition"),
                associatedIn: this.props.typers.filter(typer => typer.tag === "associatedEntity"),
            });
        }
    }

    render() {
      const phenomId = new PhenomId("typer-view",this.props.idCtx);
      let rowRenderIdx = 0;

        return (
            <React.Fragment>
                {["composedIn", "associatedIn"].map((parentType, idx) => {
                    return (
                        <React.Fragment key={idx}>
                            <LineLabel
                                text={parentType === "composedIn" ? "Composed In" : "Associated In"}
                                style={{margin: "15px 0"}}
                                idCtx={phenomId.gen(["parent-type",`${idx}`],"")}
                            />
                            <Grid
                                id={phenomId.gen([`${idx}`,"grid"])}
                                data={orderBy(this.state[parentType], this.state[parentType + "Sort"] || [])}
                                sortable
                                sort={this.state[parentType + "Sort"]}
                                onSortChange={(e) => {
                                    this.setState({
                                        [parentType + "Sort"]: e.sort
                                    });
                                }}
                                className="editorTable"
                                resizable
                            >
                                <GridNoRecords>
                                    No Data Is Available For This Table
                                </GridNoRecords>
                                <Column
                                    title="NAME"
                                    field="parent_name"
                                    width={parentType === "composedIn" ? "200px" : "300px"}
                                    cell={(props) => {
                                        const {parent_id, parent_name, rolename} = props.dataItem;
                                        const childIdx = rowRenderIdx++;

                                        return (
                                            <td><NavLink
                                                         id={phenomId.gen("grid", `entity-${childIdx}-link`)}
                                                         className="cadet-anchor normal-anchor"
                                                         to={`/edit/details/entity/${parent_id}`}>{parent_name}{parentType !== "composedIn" && `.${rolename}`}</NavLink>
                                            </td>
                                        );
                                    }}
                                />
                                <Column title="DESCRIPTION" field="parent_description"/>
                            </Grid>
                        </React.Fragment>
                    );
                })}
            </React.Fragment>
        );
    }
}

export default TyperViews;
