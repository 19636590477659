import React from 'react';
import { AbstractModelFactory } from '@projectstorm/react-canvas-core';

import { PathLinkModel } from '../link/PathLink';
import { DiagramPortModel } from "./DiagramPort";



export class PathPortFactory extends AbstractModelFactory {
	constructor() {
		super('path-port');
	}

	generateModel() {
		return new PathPortModel();
	}
}

export class PathPortModel extends DiagramPortModel {
	constructor(options={}) {
		super({
			...options,
			type: 'path-port'
		});
	}

	createLinkModel() {
		return new PathLinkModel();
	}

	addTargetAttrGuid = (guid) => {
		this.options.targetAttrGuid = guid;
	}

  removeTargetAttrGuid = () => {
    delete this.options.targetAttrGuid;
  }
}