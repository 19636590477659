import {CadetInput} from "../stateless";
import {MsgField} from "./MsgField";
import React from "react";
import PhenomId from "../../../requests/phenom-id";

export class MsgConditionalContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            collapsed: !this.props.open,
            name: this.props.fields[0].name,
            depends: this.findDiscriminator("depends"),
            count: this.findDiscriminator("count"),
            maxH: 79 + 30 * (this.props.fields.filter(field => !!field.rolename)).length + 130 * (this.props.fields.filter(field => !field.rolename)).length,
            msgFields: this.props.msgFields,
        };
        this.fieldRefs = {};
    }

    componentDidUpdate(prevProps) {
        const delta = this.props.fields.length - prevProps.fields.length;
        if (delta) {
            const last = this.props.fields[this.props.fields.length - 1];
            this.setState({maxH: this.state.maxH + (last.rolename ? 30 : 135) * delta});
        }
        if (this.props.msgFields.length !== prevProps.msgFields.length) {
            this.setState({msgFields: this.props.msgFields}, () => {
                const names = this.state.msgFields;

                if (!names.includes(this.state.depends)) {
                    this.setState({depends: false});
                }
                if (!names.includes(this.state.count)) {
                    this.setState({count: false});
                }
            });
        }
        for (let id in this.fieldRefs) {
            if (!this.fieldRefs[id]) {
                delete this.fieldRefs[id];
            }
        }
    }

    createFieldRef = (guid, ele) => {
        this.fieldRefs[guid] = ele;
    };

    upStyle() {
        return {
            padding: 0,
            maxHeight: 0,
            opacity: 0,
            transition: "all 300ms linear, opacity 200ms linear",
            overflowY: "hidden",
        };
    }

    downStyle() {
        return {
            padding: "4px 0 0 4px",
            maxHeight: this.state.maxH,
            opacity: 1,
            transition: `all 300ms linear, opacity 150ms linear`,
            overflowY: "visible",
        };
    }

    changeMaxH = delta => {
        this.setState({maxH: this.state.maxH + delta});
    };

    findDiscriminator(key) {
        const filledField = this.props.fields.find(field => field[key]);

        if (filledField && this.props.msgFields.find(rolename => rolename === filledField[key])) {
            return filledField[key];
        } else {
            return false;
        }
    }

    changeDiscriminator = e => {
        const rolename = e.target.value;
        this.setState({depends: rolename, count: rolename});
    };

    reportState() {
        const localReports = Object.values(this.fieldRefs).filter(field => !!field).map(field => field.reportState());
        localReports.forEach((report) => {
            report["name"] = report.presence === "conditional" ? this.state.name : false;
            report["depends"] = this.state.depends;
            report["count"] = this.state.count;
        });
        return localReports;
    }

    collapse = () => {
        this.setState({collapsed: !this.state.collapsed});
    };

    forceCollapsedState(state) {
        Object.values(this.fieldRefs).forEach(field => field.forceCollapsedState(state));
        this.setState({collapsed: state});
        if (!state) {
            this.setState({maxH: 79 + 135 * this.props.fields.length});
        } else {
            this.setState({maxH: 79 + 30 * this.props.fields.length});
        }
    }

    refreshMsgFields = () => {
        this.setState({msgFields: this.props.gatherDiscriminatorNames()});
    };

    render() {
        const phenomId = new PhenomId("msg-conditional-container",this.props.idCtx);
        return (
            <div style={{border: "2px solid " + (this.props.idx % 2 ? "#cfd1d2" : "#e4e4e4")}} id={phenomId.gen("","wrapper")}>
                <header
                    className="conditional-message-header"
                    style={{width: "100%", backgroundColor: "#fff"}}
                    onDoubleClick={this.collapse}
                id={phenomId.gen("","header")}>
                    <CadetInput style={{fontStyle: "italic"}} text={this.state.name}
                                idCtx={phenomId.gen("","name")} onChange={(e) => this.setState({name: e.target.value})}/>
                    <button
                        onClick={this.collapse} id={phenomId.gen("","collapse-button")}
                        className={"form-button " + (this.state.collapsed ? "expand-down" : "collapse-up") + "-lg"}>
                    </button>
                </header>
                <div className="message-field-contents"
                     style={this.state.collapsed ? this.upStyle() : this.downStyle()}>
                    <section style={{width: "50%"}}>
                        <label style={{margin: "10px 0", justifyContent: "space-between", paddingRight: "3.7%"}}>
                            <div>Discriminant Field:</div>
                            <select
                                id={phenomId.gen("discriminator","select")}
                                className="cadet-select"
                                style={{width: "calc(100% - 135px)"}}
                                value={this.state.depends || this.state.count || ""}
                                onChange={this.changeDiscriminator}
                                onMouseEnter={this.refreshMsgFields}
                                defaultValue=''
                            >
                                <option value='' id={phenomId.gen(["discriminator","default"],"option")}>--- Select a Discriminator ---</option>
                                {this.state.msgFields.map((rolename, idx) => {
                                    return <option id={phenomId.gen(["discriminator",idx],"option")} value={rolename} key={idx}>{rolename}</option>;
                                })}
                            </select>
                        </label>
                    </section>
                    {this.props.fields.map((field, idx) => {
                        return <MsgField
                            idCtx={phenomId.gen(["init","fields"],idx)}
                            ref={(ele) => this.createFieldRef(field.guid, ele)}
                            idx={idx}
                            discriminator={this.state.depends || this.state.count}
                            effectChange={this.props.effectChange}
                            views={this.props.views}
                            field={field}
                            changeMaxH={this.changeMaxH}
                        />;
                    })}
                    <button
                        style={{marginLeft: 30, width: "calc(100% - 30px)"}}
                        id={phenomId.gen("init","new-mdm-field-button")}//"new-mdm-field"
                        onClick={() => this.props.addMsgField(this.state.name)}
                        title="Click to Add New Conditional Field"
                    >+
                    </button>
                </div>
            </div>
        );
    }
}
