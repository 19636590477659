import {AuthPass} from "./authPass";
import React, {Component} from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { getCSRFToken, wsLoaded } from "../../requests/sml-requests";
import { getCriticalDetails } from "../../requests/actionThunks";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import PhenomId from "../../requests/phenom-id";
import { receiveErrors } from "../../requests/actionCreators";

class Verify2FA extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: "authkey",
            twoFactorKey: "default",
            showVerifyAuth: false,
            login: "",
            password: "",
            remember_me: 0,
            login_error: "",
            password_error: ""
        };
    }

    handleSubmit = event => {
        event.preventDefault();
        let login_status = (this.state.login.length ? "" : "Username cannot be blank.");
        let password_status = (this.state.password.length ? "" : "Password cannot be blank.");
        this.setState({login_error: login_status, password_error: password_status}, () => {
            if (!this.state.password_error.length && !this.state.login_error.length) {
                this.attemptLogin();
            }
        });
    };

    handleNewAuthCode = authCode => {
        this.setState({twoFactorKey: authCode});
        this.attemptLogin();
    };

    attemptLogin = async () => {
      // need to grab a new token when user is auto logged out (session token expired)
      if(!this.props.csrf) {
        await this.refreshCSRFToken();
      }

      $.ajax({
          method: "post",
          url: "/index.php?r=/api/login",
          data: {
            username: this.state.login,
            password: this.state.password,
            rememberMe: this.state.remember_me,
            twoFactorKey: this.state.twoFactorKey
          },
          error: (jqXHR, textStatus, errorThrown ) => {
            const { responseJSON, status } = jqXHR;

            if (status === 406 && responseJSON && responseJSON.message) {
                return receiveErrors([responseJSON.message]);
            }

            // note: if the user enters an incorrect username & password, server responds with a 500 status
            // this is a fallback message.
            this.setState({password_error: "Incorrect username or password"});
          },
      }).then(response => {

          if(response.result === "success") {
            this.signIn();
          } else if (response.messages.password || response.messages.username) {
            if (response.messages.password) {
                this.setState({password_error: response.messages.password});
            }
            if (response.messages.username) {
              this.setState({login_error: response.messages.username});
            }
         } else if (response.messages.twoFactorKey != null) {
              this.setState({showVerifyAuth: true});

         } else {
            this.setState({login_error: response.messages});
           }
      });
    };

    refreshCSRFToken = () => {
        return getCSRFToken().then((data) => {
            $.ajaxSetup({headers: {"x-csrf-token": data}});
        });
    };

    signIn = () => {
        wsLoaded().then(res => {
            const response = JSON.parse(res);

            if (response?.status === "success") {
                getCriticalDetails();
            } else {
                this.props.history.push({ pathname: "/login-error", state: {errors: response.errors }});
            }
        })
    }

    render() {
        const phenomId = new PhenomId("verify-2fa",this.props.idCtx);
        const page = this.state.page;
        const showVerifyAuth = this.state.showVerifyAuth;
        const un = this.state.login;
        const loginErrorClass = (this.state.login_error.length !== 0 ? "has-error" : "");
        const loginError = this.state.login_error;
        const passwordErrorClass = (this.state.password_error.length !== 0 ? "has-error" : "");
        const passwordError = this.state.password_error;
        const rememberSwitch = (this.state.remember_me === 0 ? 1 : 0);
        const displayInput = (showVerifyAuth ? "none" : "block");
        return (<section id="phenom-content" className="login-page">
            <Helmet>
                <title>Login</title>
            </Helmet>

            <div className="phenom-content-wrapper">
              <div className="phenom-content-scrollable">
                {showVerifyAuth && <AuthPass
                    idCtx={phenomId.gen("")}
                    username={un}
                    page={page}
                    onPageChange={this.handleNewAuthCode}
                    mode={"login"}
                /> }
                <form className="login-form form-horizontal" style={{ display: displayInput }}
                      onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="logintitle">
                        <h1>Login</h1>
                    </div>

                    <div className={"form-group field-loginform-username required " + loginErrorClass}>
                        <label htmlFor="loginform-username" className="col-lg-1 login-label" id={phenomId.gen("","username-label")}>Username</label>
                        <div className="col-lg-3">
                            <input type="text" value={this.state.login}
                                  onChange={(e) => this.setState({login: e.target.value})}
                                  autoFocus="autofocus" aria-required="true" className="form-control"
                                  aria-invalid={loginError.length !== 0 || this.state.login.length === 0}
                                  id={phenomId.gen("","username-input")}>
                            </input>
                        </div>
                        {loginError.length !== 0 &&
                        <div className="col-lg-3">
                            <p className="help-block help-block-error">{loginError}</p>
                        </div>
                        }
                    </div>

                    <div className={"form-group field-loginform-password required " + passwordErrorClass}>
                        <label htmlFor="loginform-password" className="col-lg-1 login-label" id={phenomId.gen("","password-label")}>Password</label>
                        <div className="col-lg-3">
                            <input type="password" value={this.state.password}
                                  onChange={(e) => this.setState({password: e.target.value})}
                                  aria-required="true" className="form-control"
                                  aria-invalid={passwordError.length !== 0 || this.state.password.length === 0}
                                  id={phenomId.gen("","password-input")}>
                            </input>
                        </div>
                        {passwordError.length !== 0 &&
                        <div className="col-lg-3">
                            <p className="help-block help-block-error">{passwordError}</p>
                        </div>
                        }
                    </div>
                    <div className="form-group field-loginform-rememberme">
                        <div className="col-lg-3">
                            <input type="checkbox"
                                  onClick={(e) => this.setState({remember_me: rememberSwitch})}
                                  id={phenomId.gen("","rememberme-input")}></input>
                            <label htmlFor="loginform-rememberme" id={phenomId.gen("","rememberme-label")}>Remember Me</label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-lg-3">
                            <button type="submit" name="login-button" className="btn btn-primary"
                                    onClick={this.handleSubmit}
                                    id={phenomId.gen("","login-button")}>Login
                            </button>
                        </div>
                    </div>
                  <p className="just-text" style={{marginLeft: 10}}>
                      If you have forgotten your password, <Link className="cadet-anchor" 
                                                                 to="/request_password_reset" 
                                                                 id={phenomId.gen("","request-new-password-link")}>click here</Link> to request a new password.
                  </p>
                  <p className="just-text" style={{marginLeft: 10}}>
                      If you have any other issues with your account, please email <a className="cadet-anchor"
                                                                                      href="mailto:support@skayl.com">support@skayl.com</a>
                  </p>
                </form>
              </div>
            </div>
        </section>);
    }
}



const msp = (state) => ({
  csrf: state.user._csrf,
});

export default connect(msp)(Verify2FA);