import React from "react";
import { NodeModel } from "@projectstorm/react-diagrams";
import { isStormData, nodeProps } from "../util";
import { BasePortModel } from "./BasePort";
import PhenomId from '../../../../requests/phenom-id';
import ReactTooltip from "react-tooltip";
import StormData from "./StormData";




export class BaseNodeModel extends NodeModel {
	constructor(options={}, $app, settings={}) {
      super(options);

      // Two ways to load a Node - drag & drop and load diagram
      //      --> on drag and drop, we call the constructor and can pass in extra variables
      //      --> on diagram load, React Storm calls the constructor on its own (while doing other stuff), at this point nodeData is undefined but will be repopulated during deserialize
      if (!isStormData(this.options.nodeData)) {
        this.options.nodeData = new StormData();
      }

      // the data is a waterfall and flows down one direction - starts with $app > (react storm's factory & model) > NodeModel > NodeWidget
      //    --> We needed a way to communicate with the $app level
      //    --> On diagram load, the $app variable will start off as undefined and then repopulate during deserialize
      this.$app = $app;

      // hiddenAttributeGuids - stores individual guids to be hidden
      // showHiddenAttributes - forces the hidden attributes to show (sorry, the name is a little confusing)
      this.settings = {
        hiddenAttributeGuids: new Set(),
        showHiddenAttributes: false,
        matchLineColor: false,
        _width: 0,      // stores the width. when switching tabs, the width resets to zero and we need a way to restore the size.
        _height: 0,     // stores the height. when switching tabs, the height resets to zero and we need a way to restore the size.
        ...settings,
      };

      // set initial edited status
      this.markUncommittedStatus();

      this.registerListener({
        refreshData: () => {
          this.refresh();
        },
        nodeDataChanged: (e) => {
          this.refresh();

          // set saved status to false
          this.$app.setDiagramUnsavedStatus && this.$app.setDiagramUnsavedStatus();

          // update the Sidebar
          this.$app.forceSidebarUpdate && this.$app.forceSidebarUpdate();

          // update all connected links
          for (let portId in this.getPorts()) {
            const port = this.getPort(portId);

            for (let linkId in port.getLinks()) {
              const link = port.getLinks()[linkId];
              link.fireEvent({}, 'nodeDataChanged');
            }
          }
        },
        selectionChanged: (e) => {
          // load the node into Sidebar
          e.isSelected && this.$app.setSidebarNodeModel && this.$app.setSidebarNodeModel(this);
        },
        positionChanged: (e) => {
          // resize canvas if node moves past canvas size
					this.$app.setDiagramUnsavedStatus && this.$app.setDiagramUnsavedStatus();
          this.$app.resizeCanvas && this.$app.resizeCanvas();
        },
        entityRemoved: (e) => {
          this.$app.setSidebarNodeModel && this.$app.setSidebarNodeModel(null);
        },
        colorChanged: (e) => {
          // update the Sidebar
          this.$app.forceSidebarUpdate && this.$app.forceSidebarUpdate();

          // Node color changed. If true, change the link color as well
          // currently used by ViewTrace
          if (this.isMatchLineColor()) {
            Object.values(this.getPorts()).forEach(port => {
              const link = Object.values(port.getLinks())[0];
                    link.setLinkColor(e.color);
            })
          }
        },
      })
  }

  /**
   * Create a general purpose port - used for drag and drop
   *  -> This uses BasePortModel
   */
  init() {
    const port = new BasePortModel({
      name: "draw-tool",
      in: false,
      attrData: new StormData(),
    });
    this.addPort(port);
    port.reportPosition();
  }

  serialize() {
    return {
      ...super.serialize(),
      settings: this.getSettings(),
      width: this.width,
      height: this.height,
      nodeColor: this.getNodeColor(),
      nodeData: {
        guid: this.getGuid(),
      }
    };
  }

  deserialize(event) {
    this.$app = event.engine.$app;

    this.options.nodeData = event.data.nodeData;
    if (!isStormData(this.options.nodeData)) {
      this.options.nodeData = new StormData();
    }

    this.settings = event.data.settings;
    this.width = event.data.width;
    this.height = event.data.height;
    this.options.color = event.data.nodeColor;
    this.rememberNodeSize();
    super.deserialize(event);
  }

  reassignStormData(hashPhenomToReal={}) {
    const currGuid = this.getGuid();
    const newGuid = hashPhenomToReal[currGuid];

    if (newGuid) {
      const data = this.$app.getDiagramStormData(newGuid);
      this.setStormData(data);
    }

    const currStormData = this.getStormData();
    const currData = currStormData.getData();

    // convert node's attributes
    for (let key in currData) {
      if (key === 'guid' || key === 'xmiType') {
        continue;
      }
      
      let attr = currData[key];
      
      // convert phenom_guid to real_guid
      if (typeof attr === 'string' && hashPhenomToReal[attr]) {
        currStormData.setAttr(key, hashPhenomToReal[attr]);

      // convert array of phenom_guids to real_guids
      } else if (Array.isArray(attr) && attr.some(ele => !!hashPhenomToReal[ele])) {
        const newArr = attr.map((guid) => {
          if (hashPhenomToReal[guid]) {
            return hashPhenomToReal[guid];
          }

          return guid;
        })

        currStormData.setAttr(key, newArr);
      }
    }
  }

  reassignPorts(hashPhenomToReal={}) {
    Object.values(this.getPorts()).forEach(port => {
      if (port.getName() === "draw-tool") return;
      const newGuid = hashPhenomToReal[port.getName()];

      if (newGuid) {
        this.getPorts()[newGuid] = port;
        delete this.getPorts()[port.getName()]
        port.setName(newGuid);
        port.setAttrData(this.$app.getDiagramStormData(newGuid));
      }
    })
  }


  // ------------------------------------------------------------
  // Getters
  // ------------------------------------------------------------
  // deprecated
  getNodeData() {
    return this.getStormData();
  }

  getStormData() {
    return this.options.nodeData;
  }

  getSettings() {
    return this.settings;
  }

  getGuid() {
    return this.getStormData().getGuid();
  }

  getXmiType() {
    return this.getStormData().getXmiType();
  }

  getChildrenData() {
    return this.getStormData()
               .getChildren()
               .map(childGuid => this.$app.getDiagramStormData(childGuid))
               .filter(childData => isStormData(childData));
  }

  getNodeColor() {
    return this.options.color;
  }

  getOutPorts() {
    return Object.values(this.getPorts()).filter(p => !p.getOptions().in && p.getName() !== "draw-tool");
  }

  getInPorts() {
    return Object.values(this.getPorts()).filter(p => p.getOptions().in);
  }

  getWidget() {
    return this.$widget;
  }

  // TODO: REMOVE?
  getWidgetRef() {
    return this.$widget?.widgetRef;
  }

  getWidgetChildrenRef() {
    return this.getWidget()?.childrenRef || {};
  }

  getWidgetChildRef(guid) {
    const childrenRef = this.getWidgetChildrenRef();
    return childrenRef[guid];
  }

  getHiddenAttributeGuids() {
    return this.getSettings().hiddenAttributeGuids;
  }

  isAttributeGuidHidden(guid) {
    return this.getHiddenAttributeGuids().has(guid);
  }

  isShowHiddenAttributes() {
    return this.getSettings().showHiddenAttributes;
  }

  isMatchLineColor() {
    return this.getSettings().matchLineColor;
  }

  getPortCoordinates() {
    const positions = [];

    Object.values(this.getPorts()).forEach(port => {
      if (port.getName() === "draw-tool") return;
      positions.push({
        widgetPos: port.getWidgetPosition(),
        portPos: port.getPortPosition()
      })
    })

    return positions;
  }

  getCenterPoint() {
    const box = this.getBoundingBox().points;
    const offsetX = (box[1].x - box[0].x) / 2;
    const offsetY = (box[3].y - box[0].y) / 2;
    return {
      x: box[0].x + offsetX,
      y: box[0].y + offsetY,
    }
  }

  isValidDropTarget() {
    return false;
  }


  // ------------------------------------------------------------
  // Setters
  // ------------------------------------------------------------

  refresh() {
    this.markUncommittedStatus();
    this.forceWidgetToUpdate();
  }

  // deprecated
  setNodeData(nodeData) {
    this.setStormData(nodeData);
  }

  setStormData(stormData) {
    try {
      if (!isStormData(stormData)) throw Error("cannot assign a non-StormData object");
      this.options.nodeData = stormData;
    } catch (err) {
      console.error(err);
    }
  }

  setNodeProp(key, value) {
    this.getStormData().setAttr(key, value);
  }

  setSettings(key, value) {
    this.settings[key] = value;
  }

  /**
   * Update the Node Color
   *   Fires event listener to update link colors
   *
   * @param {string} color
   */
  setNodeColor(color) {
    if (this.getNodeColor() === color) return;
    this.getOptions().color = color;
    this.fireEvent({ color }, 'colorChanged');
    this.forceWidgetToUpdate();
  }

  setInPort(attrData, options={}) {
    try {
      if (!isStormData(attrData)) throw "invalid data, failed to create in port";
      const port = new BasePortModel({
        name: options.portName || attrData.getGuid(),
        in: true,
        attrData,
      })

      this.addPort(port);
      port.reportPosition();
      return port;

    } catch(err) {
      console.error(err);
    }
  }

  setOutPort(attrData, options={}) {
    try {
      if (!isStormData(attrData)) throw "invalid data, failed to create out port";
      const port = new BasePortModel({
        name: options.portName || attrData.getGuid(),
        in: false,
        attrData,
      })

      this.addPort(port);
      port.reportPosition();
      return port;

    } catch(err) {
      console.error(err);
    }
  }

  restoreNodeSize() {
    // After switching tabs, the width/height resets to zero.
    // the width/height is stored when the node is resized. the stored values are reapplied when switching tabs.
    //    weird bug on prod only -> width/height was still resetting to 0.  attempt to restore width/height if it is 0 and if a number was previously stored.
    if (!this.width && this.getSettings()._width) {
      this.width = this.getSettings()._width;
    }

    if (!this.height && this.getSettings()._height) {
      this.height = this.getSettings()._height;
    }
  }

  rememberNodeSize() {
    this.setSettings("_width", this.width);
    this.setSettings("_height", this.height);
  }

  markUncommittedStatus() {
    this.markUncommittedStormData(this.getStormData());
  }

  /**
   * Recursively loops through all nested node and marks if it is edited or not
   * 
   * @param {StormData} stormData 
   * @returns boolean
   */
  markUncommittedStormData = (stormData, memo = new Set()) => {
    if (!isStormData(stormData)) {
      return false;
    }

    const data = stormData.getData();
    let edited = stormData.checkEditedStatus();

    for (let key in data) {
      let attr = data[key];

      if (["guid", "parent"].includes(key) || memo.has(attr)) {
        continue;
      }

      // deref and check array of nested nodes
      if (Array.isArray(attr) && attr.every(ele => typeof ele === 'string')) {
        attr.forEach(guid => {
          const nestedStormData = this.$app.getDiagramStormData(guid);

          if (isStormData(nestedStormData)) {
            memo.add(guid);
            if (this.markUncommittedStormData(nestedStormData, memo)) {
              edited = true;
            }
          }
        })

      // deref and check a single node
      } else if (typeof attr === 'string') {
        const nestedStormData = this.$app.getDiagramStormData(attr);

        if (isStormData(nestedStormData)) {
          memo.add(attr);
          if (this.markUncommittedStormData(nestedStormData, memo)) {
            edited = true;
          }
        }
      }
    }

    // if any nested node is edited, then mark the main node as edited.
    stormData.setEditedStatus(edited);
    return edited;
  }


  /**
   * Add or remove guid from the set of hiddenGuids
   *
   * @param {string} guid
   */
  toggleHiddenAttributeGuid(guid) {
    const hiddenAttributeGuids = this.getHiddenAttributeGuids();
    this.isAttributeGuidHidden(guid) ? hiddenAttributeGuids.delete(guid) : hiddenAttributeGuids.add(guid);
    this.forceWidgetToUpdate();
  }

  toggleAllHiddenAttributeGuids(bool) {
    const showAll = typeof bool === "boolean" ? bool : this.getHiddenAttributeGuids().size !== 0;
    const hiddenGuids = new Set();

    if (!showAll) this.getStormData().getChildren().forEach(childGuid => hiddenGuids.add(childGuid));
    this.setSettings("hiddenAttributeGuids", hiddenGuids);
    this.forceWidgetToUpdate();
  }

  // showHiddenAttributes - forces the hidden attributes to show (sorry, the name is a little confusing)
  toggleShowHiddenAttributes(bool) {
    this.setSettings("showHiddenAttributes", typeof bool === "boolean" ? bool : !this.isShowHiddenAttributes());
    this.forceWidgetToUpdate();
  }


  // ------------------------------------------------------------
  // Remove Items
  // ------------------------------------------------------------
  removePortByGuid(portGuid) {
    let port = this.getPort(portGuid);
    port && port.remove();
  }



  // ------------------------------------------------------------
  // Node
  // ------------------------------------------------------------

  /**
   * Update a node's attribute without changing the reference pointer
   * Re-renders the react component
   * Fires event listener that the node was changed (triggers a forceUpdate for sidebar)
   *
   * @param {string} key
   * @param {*} value
   */
  updateProp(key, value) {
    this.setNodeProp(key, value);
    this.fireEvent({}, 'nodeDataChanged');
  }

  updateChildProp(childData, key, value) {
    // invalid
    if (!isStormData(childData)) {
      return;
    }

    childData.setAttr(key, value);
    this.fireEvent({}, 'nodeDataChanged');
  }

  /**
   * Checks to see if widget exist before calling forceUpdate
   *  - this is to ensure the Widget is fully rendered before the method is called.
   */
  forceWidgetToUpdate() {
    this.getWidget() && this.getWidget().forceUpdate();
  }
}







export class BaseNodeWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
    };

    this.$app = this.props.engine.$app;
    this.nodeModel = this.props.node;
    this.nodeModel.$widget = this;
    this.childrenRef = {};
    this.phenomId = new PhenomId(this.$app.getPhenomDomId() + "-" + this.nodeModel.getGuid());
  }

  componentDidMount() {
    ReactTooltip.rebuild();
  }

  // ------------------------------------------------------------
  // Getters
  // ------------------------------------------------------------
  getPhenomDomId = () => {
    return this.phenomId.genPageId();
  }

  isEditing = () => {
    return this.state.isEditing;
  }

  // ------------------------------------------------------------
  // Setters
  // ------------------------------------------------------------
  toggleEditing = (bool) => {
    this.setState({ isEditing: typeof bool === "boolean" ?  bool : !this.state.isEditing });
  }

  // ------------------------------------------------------------
  // Helper
  // ------------------------------------------------------------
  /**
   * Repositions the "draw-tool" port position to mouse's location.
   *
   * @param {mouse event} e
   */
  repositionDrawTool = (e) => {
    const zoomLevel = this.$app.model.getZoomLevel() / 100;
    const point = this.props.engine.getRelativePoint(e.clientX, e.clientY);
    const posX = point.x / zoomLevel;
    const posY = point.y / zoomLevel;

    const port = this.nodeModel.getPort("draw-tool");
          port.setPosition(posX, posY);

    // manually set the link's endpoint to match the mouse's current position
    // because $app setState is triggered, the endpoint of newDragLink jumps to 0,0 of the graph.
    this.props.engine.repaintCanvas(true).then((res) => {
      const link = Object.values(port.getLinks())[0];
      link && link.getLastPoint().setPosition(posX, posY);

      port.reportPosition();
    })
  }

  // ------------------------------------------------------------
  // Resize
  // ------------------------------------------------------------
  calculateMinHeight = () => {
    let headerHeight = 0, attributeHeight = 0;
    const borderPx = getComputedStyle(this.widgetRef,null).getPropertyValue('border-top-width');
    const border = parseInt(borderPx, 10) * 2;

    const headerDom = this.widgetRef.querySelector(".node-header");
    if (headerDom) headerHeight = headerDom.getBoundingClientRect().height;

    const attrDom = this.widgetRef.querySelector(".attribute-container");
    if (attrDom) attributeHeight = attrDom.getBoundingClientRect().height;

    return headerHeight + attributeHeight + border;
  }

  startResize = (e) => {
    e.stopPropagation();
    this.nodeModel.setLocked(true);

    const classes = e.target.classList;
    const min_height = this.calculateMinHeight();

    let original_x = this.nodeModel.getX();
    let original_y = this.nodeModel.getY();
    let original_mouse_x = e.pageX;
    let original_mouse_y = e.pageY;
    let original_width = this.nodeModel.width;
    let original_height = this.nodeModel.height;

    const resize = (e) => {
        if (classes.contains("resize-right")) { resizeRight(e); }
        else if (classes.contains("resize-left")) { resizeLeft(e); }
        else if (classes.contains("resize-bottom")) { resizeBottom(e); }
        else if (classes.contains("resize-top")) { resizeTop(e); }
        else if (classes.contains("resize-top-left")) { resizeTop(e); resizeLeft(e); }
        else if (classes.contains("resize-top-right")) { resizeTop(e); resizeRight(e); }
        else if (classes.contains("resize-bottom-left")) { resizeBottom(e); resizeLeft(e); }
        else if (classes.contains("resize-bottom-right")) { resizeBottom(e); resizeRight(e); }

        this.nodeModel.rememberNodeSize();
        this.forceUpdate();
    }

    const stopResize = () => {
        this.nodeModel.setLocked(false);
        window.removeEventListener("mousemove", resize);
        window.removeEventListener("mouseup", stopResize);
    }

    const resizeTop = (e) => {
        let height = original_height - (e.pageY - original_mouse_y);
        let pos_y = original_y + (e.pageY - original_mouse_y);
        if (height >= min_height) {
            this.nodeModel.height = height;
            this.nodeModel.position.y = pos_y;
            this.props.engine.repaintCanvas();
        }
    }

    const resizeBottom = (e) => {
        let height = original_height + (e.pageY - original_mouse_y);
        if (height >= min_height) {
            this.nodeModel.height = height;
        }
    }

    const resizeRight = (e) => {
        let width = original_width + (e.pageX - original_mouse_x);
        if (width >= nodeProps.minWidth) {
            this.nodeModel.width = width;
        }
    }

    const resizeLeft = (e) => {
        let width = original_width - (e.pageX - original_mouse_x);
        let pos_x = original_x + (e.pageX - original_mouse_x);
        if (width >= nodeProps.minWidth) {
            this.nodeModel.width = width;
            this.nodeModel.position.x = pos_x;
            this.props.engine.repaintCanvas();
        }
    }

    window.addEventListener("mousemove", resize);
    window.addEventListener("mouseup", stopResize);
  }

  // ------------------------------------------------------------
  // Render
  // ------------------------------------------------------------
  renderHalo = () => {
    if (!this.nodeModel.isSelected()) return null;
    return <div className="node-halo"
                onMouseDown={() => this.nodeModel.setLocked(false)} />
  }

  render() {
    return <div>Please don't render this</div>
  }
}
