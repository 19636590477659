import React, { Component } from "react";
import Helmet from "react-helmet";
import styled from "@emotion/styled";
import { getLicense } from "../requests/sml-requests";


export const StyledLicenseContent = styled.div`
padding: 1rem 1.5rem;

  header {
    text-align: center;
  }

  h1, h2 {
    font-weight: 600;
    color: #656565;
  }

  ul {
    margin-bottom: 1rem;
  }

  li::marker {
    font-weight: 600;
  }

  ol {
    padding-left: 0;

    li > ol {
      list-style-type: lower-alpha;
    }
  }

  header {
    h1 {
      font-size: 1.15rem;
      margin: 0;
      text-decoration: underline;
    }

    h2 {
      margin: 0;
    }
  }
`

export default class License extends Component {
    state = {
      license_text: "",
    }

    componentDidMount() {
      getLicense().then(response => {

        if (typeof response?.data?.license_text === 'string') {
          this.setState({ license_text: response.data.license_text });
        }
        });
    }

    render() {
        return <section id="phenom-content" aria-label='content'>
          <Helmet>
              <title>User Agreement</title>
          </Helmet>
          <div className="phenom-content-scrollable">
            <StyledLicenseContent style={{ padding: "2rem 5rem" }}>
              <div dangerouslySetInnerHTML={{ __html: this.state.license_text }}/>
            </StyledLicenseContent>
          </div>
        </section>
    }
}