import React, {useState} from "react";
import $ from "jquery";
import styled from "@emotion/styled";
import { nodeColors } from "../design/nodeDesign";
import { CadetInput, CadetTextArea, NodeComboBox } from "../../../util/stateless";
import { clone, cloneDeep } from "lodash";
import { isDiagramNode } from "../util";
import PhenomId from '../../../../requests/phenom-id';
import { _ajax } from "../../../../requests/sml-requests";

import {
    StyledIcon
} from "../index";
import { stopBubbleUp } from "../../../util/util";


const inputStyle = { margin:"0 0 10px", background:"#fff" }
const minWidth = 250;
const maxWidth = 500;
const activeStyle = {color: "#e1a600", fontStyle:"italic"}

const StyledContainer = styled.div`
    display: grid;
    position: relative;
    height: 100%;
    grid-template-columns: 1fr 20px;
    grid-template-areas:
        "content controls";

    label {
        font-size: 14px;
    }
`
const StyledContent = styled.div`
    display: ${p => p.show ? "flex" : "none"};
    grid-area: content;
    flex-direction: column;
    background: hsl(var(--skayl-zero-hs) 95%);
    overflow: hidden;
`
const StyledControls = styled.div`
    grid-area: controls;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const StyledResize = styled.div`
    display: ${p => p.show ? "block" : "none"};
    position: absolute;
    width: 3px;
    top: 0;
    bottom: 0;
    cursor: ew-resize;
    z-index: 1;
    &:hover{
      background: #2684ff;
    }
`
const StyledUL = styled.ul`
    font-size: 14px;    
    list-style: none;
    padding-left: 30px;
    position: relative;

    li {
        position: relative;
    }

    ul {
        font-size: 12px;
        list-style: circle;
        padding-left: 30px;
    }
`

const ControlButton = styled.span`
    display: inline;
    font-size: 12px;
    padding: 10px 0;
    writing-mode: vertical-lr;
    line-height: 1.7;
    background: ${p => p.active ? "#d2dbf7" : null};
    cursor: pointer;

    &:hover {
        background: #d2dbf7;
    }
`
const ContentContext = styled.div`
    display: ${p => p.show ? "flex" : "none"};
    flex-direction: column;
    flex: 1;
    width: ${p => p.width ? p.width : minWidth}px;
    position: relative;
    overflow: hidden;
`

const ContentDetail = styled.div`
    display: ${p => p.show ? "flex" : "none"};
    flex-direction: column;
    flex: 1;
    width: ${p => p.width ? p.width : minWidth}px;
    position: relative;
    overflow: hidden;
`
const ContentUsage = styled.div`
    display: ${p => p.show ? "flex" : "none"};
    flex-direction: column;
    flex: 1;
    width: ${p => p.width ? p.width : minWidth}px;
    position: relative;
    overflow: hidden;
`
const ContentHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    padding: 10px;
    background: ${p => nodeColors[p.xmiType] || "black" };
`
const EditedIcon = styled(StyledIcon)`
    position: absolute;
    left: -20px;
`


const Toggle = (props) => {
    const [show, setShow] = useState(props.header);

    const textStyle = {
        fontWeight: props.header ? "bold" : null,
        order: props.left ? 2 : null,
    }

    return <>
        <div style={{
            display:"flex",
            alignItems:"center",
        }}>
            <span id={(props.id || "") + "-title"} style={props.isActive ? activeStyle : textStyle}>{props.text}</span>
            <StyledIcon id={(props.id || "") + "-toggle"} caretup={show} caretdown={!show} onClick={() => setShow(!show)} />
        </div>

        {show && props.children}
    </>
}


const Info = (props) => {
    const [show, setShow] = useState(false);

    const infoStyle = {
        fontSize:14,
        width: 200,
        position: "absolute",
        top: "-10px",
        right: "150%",
        padding:10,
        background: "var(--skayl-darkgrey)",
    }

    return <div id={props.id} style={{position:"relative"}}>
        <StyledIcon info style={{fontSize:18}}
                    onMouseEnter={() => setShow(true)}
                    onMouseLeave={() => setShow(false)} />
        {show && <div style={infoStyle}>
            Nodes colored in <span style={activeStyle}>orange</span> are contained in the diagram.
        </div>}
    </div>
}


const defaultNode = {
    nodeModel: null,
    editMode: false,
    guid: null,
    xmiType: "",
    name: "",
    description: "",
    loading: false,
}

const defaultEntityUsageData = {
    typers: [],
    children: [],
    specializedBy: [],
    specializes: "",
}

const defaultViewUsageData = {
    nestedWithin: [],
    nestingThis: [],
}


class SidePanel extends React.Component {

    phenomId = new PhenomId(this.props.idCtx || "sp-side-panel");
    state = {
        show: "context",
        width: minWidth,
        ...defaultNode,
        usageData: {},
    }


    toggleUsage = () => {
        this.setState({showUsage: !this.state.showUsage});
    }

    load = (nodeModel) => {
        this.setState({ nodeModel });

        if(nodeModel !== this.state.nodeModel) {
            if(nodeModel) {
                const nodeData = nodeModel.getNodeData();
                const usageData = nodeData.xmiType === "platform:View" ? cloneDeep(defaultViewUsageData) : cloneDeep(defaultEntityUsageData);
                this.setState({
                    ...cloneDeep(defaultNode),
                    nodeModel,
                    guid: nodeData.guid,
                    xmiType: nodeData.xmiType,
                    name: nodeData.name,
                    description: nodeData.description,
                    usageData: usageData,
                })
            } else {
                this.setState({
                    ...cloneDeep(defaultNode),
                })
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.nodeModel !== this.state.nodeModel && this.state.guid) {
            if(!isDiagramNode(this.state.guid)) {
                this.setState({loading: true}, () => {
                    const { xmiType, guid } = this.state;

                    if (xmiType === "platform:View") {
                        this.fetchView(guid);
                    } else {
                        this.fetchEntity(guid);
                    }
                })
            }
        }
    }

    save = () => {
        this.state.nodeModel.updateProp("name", this.state.name);
        this.state.nodeModel.updateProp("description", this.state.description);
        this.state.nodeModel.forceWidgetToUpdate();
        this.setState({ editMode: false });
    }

    fetchEntity = (guid) => {
        return $.ajax({
            url: "/index.php?r=/entity/page-data",
            method: "get",
            data: {guid},
        }).then(res => {
            const usageData = JSON.parse(res);
            this.setState({
                loading: false,
                usageData,
            })
        })
    }

    fetchView = (guid) => {
        return _ajax({
            url: "/index.php?r=/node/model-get-node",
            method: "get",
            data: { 
                guid, 
                coreAddenda: ["childrenMULTI", "typers"],
                coreAddendaChildren: ["viewType"],
                typersAddenda: ["parent"],
            }
        }).then(node => {
            let nestedWithin = [];
            let nestingThis = [];

            // Gather views nested within view
            if (node.children) {
                nestedWithin = node.children.filter(child => child?.viewType).map(child => child.viewType);
            }

            // Gather views nesting this view
            if (node.typers) {
                nestingThis = node.typers.map(typer => typer.parent);
            }

            this.setState({
                loading: false,
                usageData: {
                    nestedWithin,
                    nestingThis,
                },
            })
        })
    }

    addDiagramUsage = (composedIn, associatedIn, projectorMap) => {
        // Loop through Active Nodes and add to UsageData
        [...this.props.$app.tabData.activeIds].forEach(guid => {
            const node = this.props.$app.getOptionNode(guid);
            if(!node) return;
            
            switch(node.xmiType) {
                case "conceptual:Association":
                case "conceptual:Entity":
                    // Type
                    node.children.forEach((child) => {
                        // Type can be null
                        if(child.guid && child.type &&
                           child.guid.startsWith("DIAGRAM_") &&
                           child.type.guid === this.state.guid) {
                                if(child.xmiType === "conceptual:Composition") {
                                    composedIn[node.guid] = node;
                                } else if(child.xmiType === "conceptual:AssociatedEntity") {
                                    associatedIn[node.guid] = node;
                                }
                           }
                    })
                    break;
                case "platform:View":
                    // Path Pairs
                    node.children.forEach((child) => {
                        if(Array.isArray(child.pathPairs)) {
                                child.pathPairs.forEach(pair => {
                                    if(pair.parent.guid === this.state.guid) {
                                        if(!projectorMap[node.guid]) {
                                            projectorMap[node.guid] = {
                                                view: node,
                                                comps: []
                                            }
                                        }
                                        const {comps} = projectorMap[node.guid];

                                        if(!comps.find(c => c.guid === pair.guid)) {
                                            const copy = cloneDeep(pair);
                                            projectorMap[node.guid].comps.push(copy)
                                        }
                                    }
                                })
                           }
                    })
                    break;
            }
        })
    }

    renderEntityUsageData = () => {
        const { nodeModel } = this.state;
        const nodePos = nodeModel.position;
        const {typers=[], children=[], specializedBy=[], specializes} = this.state.usageData;

        const { activeIds } = this.props.$app.tabData;
        const viewMatches = children.filter(child => child.projectors && child.projectors.length);
        let projectorMap = {};
        let composedIn = {};
        let associatedIn = {};

        this.addDiagramUsage(composedIn, associatedIn, projectorMap);

        typers.forEach(typer => {
            if(typer.tag === "composition") {
                composedIn[typer.parent.guid] = typer.parent;
            } else if(typer.tag === "associatedEntity") {
                associatedIn[typer.parent.guid] = typer.parent;
            }
        })

        composedIn = Object.fromEntries(Object.entries(composedIn).sort(([,a], [,b]) => a?.name.localeCompare(b?.name)));
        associatedIn = Object.fromEntries(Object.entries(associatedIn).sort(([,a], [,b]) => b?.name.localeCompare(a?.name)));;

        viewMatches.forEach(comp => {
            comp.projectors.forEach(view => {
                if(!projectorMap[view.guid]) {
                    projectorMap[view.guid] = {
                        view: view,
                        comps: []
                    }
                }
                if(!projectorMap[view.guid].comps.includes(comp)) {
                    projectorMap[view.guid].comps.push(comp);
                }
            })
        })

        projectorMap = Object.fromEntries(Object.entries(projectorMap).sort((a, b) => a[1]?.view?.name.localeCompare(b[1]?.view?.name)));
        for (const guid in projectorMap) {
            if (Object.prototype.hasOwnProperty.call(projectorMap, guid)) {
                projectorMap[guid].comps.sort((a, b) => a?.rolename.localeCompare(b?.rolename));
            }
        }

        if(!Object.keys(projectorMap).length && !Object.keys(composedIn).length && !Object.keys(associatedIn).length && !specializedBy.length && !specializes) {            
            return <div style={{padding:10}}>
                No usage found
            </div>
        }

        return (<div>
            {/* VIEWS */}
            {Object.keys(projectorMap).length > 0 &&
                <Toggle id={this.phenomId.genPageId("node-usage-views")}
                        header 
                        text="Views">
                    <StyledUL id={this.phenomId.genPageId("node-usage-views-list")}>
                        {Object.entries(projectorMap).map(([guid, projData], idx) => {
                          const domId = "node-usage-views-list-item";
                          
                          return (
                            <li id={this.phenomId.genPageId(domId, idx)}
                                key={guid}>
                                {!activeIds.has(projData.view.guid) &&
                                    <EditedIcon id={this.phenomId.genPageId(domId, idx + "-add-btn")} plus onClick={() => {
                                        this.props.$app.addNodes(projData.view, [nodePos.x + nodeModel.width + 50, nodePos.y, nodeModel.getBoundingBox()], true, true, false, true)
                                    }}/>}

                                <Toggle id={this.phenomId.genPageId(domId, idx)}
                                        text={projData.view.name}
                                        isActive={activeIds.has(projData.view.guid)}><ul>
                                        {projData.comps.map((comp, jdx) => <li key={comp.guid}id={this.phenomId.genPageId(domId, idx + "-comp-" + jdx)}>{comp.rolename}</li>)}
                                    </ul></Toggle>
                            </li>)})}
                    </StyledUL>
                </Toggle>}

                {/* Entity/Association */}
                {[composedIn, associatedIn].map((typerMap, idx) => {
                    const title = idx === 0 ? "Composed In: " : "Associated In: ";
                    const domId = idx ? "node-usage-assoc-in" : "node-usage-composed-in";

                    // const typerMap = parentType === "composedIn" ? composedIn : associatedIn;

                    if(!Object.keys(typerMap).length) return null;

                    return (
                        <Toggle id={this.phenomId.genPageId(domId)} header text={title}>
                            <StyledUL id={this.phenomId.genPageId(domId, "list")}>{Object.values(typerMap).map((typer, jdx) => {
                              const innerId = domId + "-list-item";
                              return (
                                <li id={this.phenomId.genPageId(innerId, jdx)} key={typer.guid}>
                                    {!activeIds.has(typer.guid) &&
                                        <EditedIcon id={this.phenomId.genPageId(innerId, jdx + "-add-btn")} plus onClick={() => {
                                            this.props.$app.addNodes(typer, [nodePos.x + nodeModel.width + 50, nodePos.y, nodeModel.getBoundingBox()], true, true, false, true);
                                        }}/>}

                                    <span id={this.phenomId.genPageId(innerId, jdx + "-name")}
                                          style={activeIds.has(typer.guid) ? activeStyle : null}>
                                        {typer.name}</span>
                                </li>)})}</StyledUL>
                        </Toggle>)})}

                {/* SPECIALIZES */}
                {specializes &&
                <Toggle id={this.phenomId.genPageId("node-usage-specializes")}
                        header 
                        text="Specializes">
                    <div id={this.phenomId.genPageId("node-usage-specializes-list")} style={{position:"relative", fontSize:14, paddingLeft:30}}>
                        {!activeIds.has(specializes.guid) &&
                            <EditedIcon id={this.phenomId.genPageId("node-usage-specializes-add-btn")} plus style={{left:10}}
                                onClick={() => {
                                    this.props.$app.addNodes(specializes, [nodePos.x + nodeModel.width + 50, nodePos.y, nodeModel.getBoundingBox()], true, true, false, true)
                            }} />}

                        <span id={this.phenomId.genPageId("node-usage-specializes-name")} style={activeIds.has(specializes.guid) ? activeStyle : null}>
                            {specializes.name}</span>
                    </div>
                </Toggle>}

                {/* SPECIALIZED BY */}
                {specializedBy.length > 0 &&
                    <Toggle id={this.phenomId.genPageId("node-usage-specialized-by")}
                            header
                            text="Specialized by">

                        <StyledUL id={this.phenomId.genPageId("node-usage-specialized-by-list")}>
                            {specializedBy.map((sp, idx) => {
                              const domId = "node-usage-specialized-by-list-item";

                              return (
                                <li id={this.phenomId.genPageId(domId, idx)}
                                    key={sp.guid}>
                                    {!activeIds.has(sp.guid) &&
                                        <EditedIcon id={this.phenomId.genPageId(domId, idx + "-add-btn")} plus onClick={() => {
                                            this.props.$app.addNodes(sp, [nodePos.x + nodeModel.width + 50, nodePos.y, nodeModel.getBoundingBox()], true, true, false, true)
                                        }}/>}
                                    <span id={this.phenomId.genPageId(domId, idx + "-name")} style={activeIds.has(sp.guid) ? activeStyle : null}>
                                            {sp.name}</span>
                                </li>
                            )})}
                        </StyledUL>
                    </Toggle>
                    }
        </div>)
    }

    renderViewUsageData = () => {
        const { usageData, nodeModel } = this.state;
        const { nestedWithin, nestingThis } = usageData;
        const { activeIds } = this.props.$app.tabData;
        const nodePos = nodeModel.position;

        if(!usageData?.nestedWithin?.length && !usageData?.nestingThis?.length ) {            
            return <div style={{padding:10}}>
                No usage found
            </div>
        }
        
        return (<div>
        {/* VIEWS NESTED WITHIN THIS VIEW*/}
        {nestedWithin.length > 0 &&
            <Toggle id={this.phenomId.genPageId("node-usage-views-nested-within")}
                    header 
                    text="Nesting">
                <StyledUL id={this.phenomId.genPageId("node-usage-views-list")}>
                    {nestedWithin.map((view, idx) => {
                      const domId = "node-usage-views-nested-within-list-item";
                      
                      return (
                        <li id={this.phenomId.genPageId(domId, idx)}
                            key={view.guid}>
                            {!activeIds.has(view.guid) &&
                                <EditedIcon id={this.phenomId.genPageId(domId, idx + "-add-btn")} plus onClick={() => {
                                    this.props.$app.addNodes(view, [nodePos.x + nodeModel.width + 50, nodePos.y, nodeModel.getBoundingBox()], true, true, false, true)
                                }}/>}
                            <span id={this.phenomId.genPageId(domId, idx + "-name")} style={activeIds.has(view.guid) ? activeStyle : null}>
                                            {view.name}</span>
                        </li>)})}
                </StyledUL>
            </Toggle>}

        {/* VIEWS NESTING THIS VIEW*/}
        {nestingThis.length > 0 &&
            <Toggle id={this.phenomId.genPageId("node-usage-views-nesting-this")}
                    header 
                    text="Nested in">
                <StyledUL id={this.phenomId.genPageId("node-usage-views-list")}>
                    {nestingThis.map((view, idx) => {
                      const domId = "node-usage-views-nesting-this-list-item";
                      
                      return (
                        <li id={this.phenomId.genPageId(domId, idx)}
                            key={view.guid}>
                            {!activeIds.has(view.guid) &&
                                <EditedIcon id={this.phenomId.genPageId(domId, idx + "-add-btn")} plus onClick={() => {
                                    this.props.$app.addNodes(view, [nodePos.x + nodeModel.width + 50, nodePos.y, nodeModel.getBoundingBox()], true, true, false, true)
                                }}/>}
                            <span id={this.phenomId.genPageId(domId, idx + "-name")} style={activeIds.has(view.guid) ? activeStyle : null}>
                                            {view.name}</span>
                        </li>)})}
                </StyledUL>
            </Toggle>}
        </div>)
    }

    renderUsageData = () => {
        const { show, nodeModel, xmiType } = this.state;
        if(show !== "usage" || !nodeModel) return null;
        

        if(this.state.loading) {
            return <div style={{padding:10}}>
                Loading
            </div>
        }

        return (
            <div className="phenom-content-scrollable"
                style={{
                    padding:10, 
                    overflow:"auto",
                }}>
                {xmiType === "platform:View" ?
                    this.renderViewUsageData()
                    :
                    this.renderEntityUsageData()
                }
                
            </div>
        )
    }

    startResize = (e) => {
        e.stopPropagation();
        let prev_mouse_x = e.pageX;
        let original_width = this.state.width;

        const resize = (e) => {
            let newWidth = original_width - (e.pageX - prev_mouse_x);
            if (newWidth < minWidth) newWidth = minWidth;
            this.setState({ width: newWidth });
        }

        const stopResize = () => {
            window.removeEventListener("mousemove", resize);
            window.removeEventListener("mouseup", stopResize);
        }

        window.addEventListener("mousemove", resize);
        window.addEventListener("mouseup", stopResize);
    }


    render() {
        const { $app, contextState } = this.props;
        const {show, nodeModel} = this.state;
        // const show = (showDetail || showUsage) && nodeModel;

        const showContext = show === "context";
        const showDetail = show === "detail";
        const showUsage = show === "usage";

        return (<StyledContainer tabIndex={0} onKeyDown={stopBubbleUp}>
            <StyledResize id={this.phenomId.genPageId("horizontal-resize-bar")}
                          show={show}
                          onMouseDown={this.startResize} />

            <StyledControls id={this.phenomId.genPageId("controls")} className="side-panel-controls">
                <ControlButton id={this.phenomId.genPageId("toggle-context-btn")}
                               active={showContext}
                               onClick={() => {
                                    this.setState({ show: showContext ? "" : "context" })
                               }}>Context</ControlButton>
                <ControlButton id={this.phenomId.genPageId("toggle-detail-btn")}
                               active={showDetail}
                               onClick={() => {
                                    this.setState({ show: showDetail ? "" : "detail" })
                               }}>Details</ControlButton>
                <ControlButton id={this.phenomId.genPageId("toggle-usage-btn")}
                               active={showUsage}
                               onClick={() => {
                                    this.setState({ show: showUsage ? "" : "usage" })
                               }}>Usage</ControlButton>
            </StyledControls>

            
            <StyledContent id={this.phenomId.genPageId("content")} show={show}>
                <ContentContext show={showContext}
                                width={this.state.width}>
                    <ContentHeader>
                        <span>Context</span>
                    </ContentHeader>

                    <div style={{ padding:10 }} className="phenom-content-scrollable">
                        <label id={this.phenomId.genPageId("node-context-name-label")}>Name</label>
                        <CadetInput id={this.phenomId.genPageId("node-context-name-input")}
                                    style={inputStyle}
                                    text={contextState.name}
                                    onChange={(e) => $app.setContextState({ name: e.target.value })} />

                        <label id={this.phenomId.genPageId("node-context-description-label")}>Description</label>
                        <CadetTextArea id={this.phenomId.genPageId("node-context-description-input")}
                                    style={inputStyle}
                                    text={contextState.description}
                                    autoComplete="off"
                                    onChange={(e) => $app.setContextState({ description: e.target.value })} />

                        <NodeComboBox id={this.phenomId.genPageId("node-context-parent")}
                                      label="Package"
                                      xmiType="skayl:DiagramModel"
                                      placeholder="<Default>"
                                      selectedGuid={contextState.parent}
                                      autoComplete="off"
                                      onChange={(parent) => $app.setContextState({ parent: parent.guid })}
                                      onClickCancelIcon={() => $app.setContextState({ parent: undefined })} />
                    </div>
                </ContentContext>


                <ContentDetail id={this.phenomId.genPageId("node-detail-wrapper")}
                                show={showDetail}
                                width={this.state.width}>
                    <ContentHeader xmiType={this.state.xmiType}>
                        <span>Details</span>
                        <StyledIcon id={this.phenomId.genPageId("node-detail-edit-btn")}
                                    edit 
                                    onClick={() => this.setState({editMode: !this.state.editMode})} />
                    </ContentHeader>

                    <div style={{padding:10}} className="phenom-content-scrollable">
                        <label id={this.phenomId.genPageId("node-detail-name-label")}>Name</label>
                        <CadetInput id={this.phenomId.genPageId("node-detail-name-input")}
                                    style={inputStyle}
                                    text={this.state.name}
                                    disabled={!this.state.editMode}
                                    onChange={(e) => this.setState({name: e.target.value})} />

                        <label id={this.phenomId.genPageId("node-detail-description-label")}>Description</label>
                        <CadetTextArea id={this.phenomId.genPageId("node-detail-description-input")}
                                    style={inputStyle}
                                    text={this.state.description}
                                    disabled={!this.state.editMode}
                                    autoComplete="false"
                                    onChange={(e) => this.setState({description: e.target.value})} />

                        {this.state.editMode &&
                            <div style={{textAlign: "right"}}>
                                <button id={this.phenomId.genPageId("node-detail-save-btn")} onClick={this.save}>Save</button>
                            </div>}
                    </div>
                </ContentDetail>
                
                <ContentUsage id={this.phenomId.genPageId("node-usage-wrapper")}
                              show={showUsage}
                              width={this.state.width}>
                    <ContentHeader xmiType={this.state.xmiType}>
                        <span id={this.phenomId.genPageId("node-usage-title")}>Usage - {this.state.name}</span>
                        <Info id={this.phenomId.genPageId("node-usage-info-btn")}/>
                    </ContentHeader>

                    {this.renderUsageData()}
                    
                </ContentUsage>
            </StyledContent>
        </StyledContainer>)
    }
}

export default React.memo(SidePanel);


