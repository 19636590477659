import {LineLabel} from "../util/stateless";
import React, {Component, Fragment} from "react";
import $ from "jquery";
import PhenomId from "../../requests/phenom-id";
import generatedGitInfo from './generatedGitInfo.json';

export class Commit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            smlCommit: "",
        };
    }

    launchCommitRequest = () => {
        $.ajax({
            url: "/index.php?r=/site/commit-info",
            method: "post",
        }).then((res) => {
            this.setState({ smlCommit: res })
        });
        return false;
    };
    
    componentDidMount() {
        this.launchCommitRequest();
    }

    render() {
        const phenomId = new PhenomId("commit-info",this.props.idCtx);
        return (<Fragment style={{backgroundColor: "#fff"}} id={phenomId.gen("","wrapper")}>
            <nav className="tab-nav" style={{height: 30}}></nav>
            <main style={{padding: 20, backgroundColor: "#fff"}}>
                <LineLabel text={"Skayl_Model_Library: " + this.state.smlCommit} idCtx={phenomId.gen("","sml-commit")}/>
                <br/>
                <LineLabel text={"phenom: \"" + generatedGitInfo.gitCommitHash + "\""} idCtx={phenomId.gen("","sml-commit")}/>
            </main>
        </Fragment>);
    }
}
