import React from "react";
import { BaseLinkModel } from "../../base/BaseLink";
import { BaseNodeModel } from "../../base/BaseNode";
import { getShortenedStringRepresentationOfXmiType, isPhenomGuid, stopBubbleUp } from "../../../../util/util"
import { Button } from "@progress/kendo-react-buttons";

import ModalNodeBuilder from "../../../../dialog/ModalNodeBuilder";
import { TransportChannelManager } from "../../../../edit/integration/EditTransportChannel";

import {
  PhenomInput,
  PhenomLabel,
  PhenomSelect,
  PhenomTextArea,
  PhenomRadioButtons,
  PhenomComboBox,
  NodeComboBox,
  PhenomToggle,
} from "../../../../util/stateless";
import { getTransformCode } from "../../../../../requests/sml-requests";
import PhenomId from "../../../../../requests/phenom-id";
import { isStormData, StormSideTabs } from "../../util";
import { ImComposedPortBlockModel } from "../node/ImComposedPortBlock";
import SidePortDetail from "./sidepanel/SidePortDetail";
import SideNodeDetail from "./sidepanel/SideNodeDetail";
import SideMessagePortDetail from "./sidepanel/SideMessagePortDetail";


export class ImSidePanel extends React.Component {
  constructor(props) {
    super(props);
    this.phenomId = new PhenomId(`${this.props.id}-side`);
  }

  state = {
    show: "context",
    edit_width: 250,
    transformCode: "",
    srcTemplateTypeMap: {},
    dstTemplateTypeMap: {},
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedNode !== this.props.selectedNode) {
      this.setState({ show: "detail" }, () => {
        this.initTemplateTypeMaps();
      });
    }
  }

  initTemplateTypeMaps = () => {
    const { selectedNode } = this.props;

    if (selectedNode instanceof BaseLinkModel) {
      const srcNodeModel = selectedNode.getSourcePort().getNode();
      const dstNodeModel = selectedNode.getTargetPort().getNode();
      let srcTemplateTypeMap = {}, dstTemplateTypeMap = {};

      if (srcNodeModel.getXmiType() === "im:ComposedInstanceBlock") {
        srcTemplateTypeMap = this.createTemplateTypeMap(srcNodeModel);
      }

      if (dstNodeModel.getXmiType() === "im:ComposedBlockInstance") {
        dstTemplateTypeMap = this.createTemplateTypeMap(dstNodeModel);
      }

      this.setState({ srcTemplateTypeMap, dstTemplateTypeMap });

    } else if (selectedNode instanceof BaseNodeModel) {
      if (selectedNode.getXmiType() === "im:ComposedBlockInstance") {
        this.setState({ 
          srcTemplateTypeMap: this.createTemplateTypeMap(selectedNode), 
          dstTemplateTypeMap: {},
        });
      }
    }
  }

  getRealizedTemplateType = (childData) => {
    return childData.getAttr("realized_templateType");
  }

  isRealizing_a_TemplatedPort = (childData) => {
    return childData.getAttr("realized_type") === "Templated";
  }

  // this function is used for ComposedBlockInstance's In/Out ports
  createTemplateTypeMap = (nodeModel) => {
    const { $app } = this.props;
    const data = nodeModel.getStormData();
    const templateTypeMap = {};

    for (let childGuid of data.getChildren()) {
      const childData = $app.getDiagramStormData(childGuid);

      if (!isStormData(childData) || !this.isRealizing_a_TemplatedPort(childData)) {
        continue;
      }

      const realized_templateType = this.getRealizedTemplateType(childData)
      if (!templateTypeMap[realized_templateType]) {
        templateTypeMap[realized_templateType] = new Set();
      }

      templateTypeMap[realized_templateType].add(childData.getAttr("dataType"));
    }

    return templateTypeMap;
  }

  fetchPreviewCode = () => {
    const { $app, selectedNode } = this.props;
    const stormData = selectedNode.getStormData();

    if (!isStormData(stormData) || stormData.getXmiType() !== "im:TransformNode") {
      return;
    }

    const viewIn = selectedNode.getImInPort()?.getDataType() ?? "";
    const viewOut = selectedNode.getImOutPort()?.getDataType() ?? "";
    if (!viewIn || !viewOut) {
      return this.setState({ transformCode: "" });
    }

    let manualTransforms = [];
    if (stormData.getAttr("transformType") === "manual") {
      // dereference -> ManualTransform (children) -> Equation nodes -> Equation node text
      manualTransforms = stormData.getChildren()
                                  .map(childGuid => $app.getDiagramStormData(childGuid))
                                  .filter(mtData => isStormData(mtData) && mtData.getXmiType() === "im:ManualTransform")
                                  .map(mtData => $app.getDiagramStormData(mtData.getAttr("update")))
                                  .filter(eqData => isStormData(eqData) && eqData.getXmiType() === "im:Equation" && !!eqData.getAttr("equation"))
                                  .map(eqData => eqData.getAttr("equation"));
    }

    getTransformCode(viewIn, viewOut, manualTransforms).then(res => {
      const response = JSON.parse(res);
      if (response?.data) {
        this.setState({ transformCode: response.data });
      } else {
        this.setState({ transformCode: "Transform could not be generated. \nPlease check the formatting of manual transforms to be '$dst.<field name> = <value>;' (e.g. '$dst.foobar = 0;')" });
      }
    })
  }

  resizeWidth = (e) => {
    e.stopPropagation();
    let prev_mouse_x = e.pageX;
    let min_width = 100;
    let original_width = this.state.edit_width;

    const resize = (e) => {
      let newWidth = original_width - (e.pageX - prev_mouse_x);
      if (newWidth < min_width) newWidth = min_width;
      this.setState({ edit_width: newWidth });
    }

    const stopResize = () => {
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", stopResize);
    }

    window.addEventListener("mousemove", resize);
    window.addEventListener("mouseup", stopResize);
  }

  /**
   * CONTEXT CONTENT
   * 
   */
  renderContextDetail = () => {
    const { $app, contextData } = this.props;
    const { show } = this.state;

    if (show !== "context" || !isStormData(contextData)) {
      return null;
    }

    const guid = contextData.getGuid();
    const isComposed = contextData.getXmiType() === "im:ComposedBlock";

    return <section>
              <div className="storm-side-header">{isComposed ? "Composed Context" : "Context"}</div>
              <div className="storm-side-content phenom-content-scrollable">
                <PhenomInput label="Name"
                            id={this.phenomId.genPageId("context-name")}
                            value={contextData.getName()}
                            autoComplete="off"
                            onChange={(e) => $app.updateContextName( e.target.value )} />

                <PhenomTextArea label="Description"
                                id={this.phenomId.genPageId("context-description")}
                                value={contextData.getAttr("description")}
                                autoComplete="off"
                                onChange={(e) => {
                                  contextData.setAttr("description", e.target.value);
                                  this.forceUpdate();
                                }} />

                <div>
                  <PhenomLabel text="Context Type" />
                  <PhenomToggle checked={isComposed}
                                data={["Normal", "Composed"]}
                                disabled={!isPhenomGuid(guid)}
                                id={this.phenomId.genPageId("context-type")}
                                onChange={() => $app.updateContextType(!isComposed)}
                  />
                </div>

                <NodeComboBox label="Package"
                              xmiType="skayl:IntegrationModel"
                              placeholder="<Default>"
                              selectedGuid={contextData.getParentGuid()}
                              id={this.phenomId.genPageId("context-package")}
                              autoComplete="off"
                              onChange={(parent) => {
                                contextData.setAttr("parent", parent.guid);
                                this.forceUpdate();
                              }} />
              </div>
            </section>
  }

  /**
   * DETAIL CONTENT
   * 
   */
  renderDetail = () => {
    const { $app, $manager, selectedNode, viewList } = this.props;
    const { show, transformCode, srcTemplateTypeMap } = this.state;
    if (show !== "detail") return null;

    if (selectedNode instanceof BaseLinkModel) {
      return <section>
              { this.renderLinkDetail() }
             </section>

    } else if (selectedNode instanceof ImComposedPortBlockModel) {
      return this.renderComposedPortDetail()

    } else if (selectedNode instanceof BaseNodeModel) {
      return <SideNodeDetail $app={$app}
                             $manager={$manager}
                             selectedNodeModel={selectedNode}
                             viewList={viewList}
                             transformCode={transformCode}
                             phenomId={this.phenomId}
                             templateTypeMap={srcTemplateTypeMap}
                             fetchPreviewCode={this.fetchPreviewCode} />

    } else {
      return <section>
              <div className="storm-side-header">
                  Node Detail
              </div>
             </section>
    }
  }

  /**
   * DELETION CONTENT
   * 
   */
  renderDeletion = () => {
    const { $app } = this.props;
    const { show } = this.state;
    if (show !== "deletion") return null;

    return <section>
              <div className="storm-side-header">Marked for deletion</div>
              <p style={{ fontSize: 14, padding: "1em" }}>The following nodes exist in the model but were removed from the current workspace. When committing your changes, these nodes will be deleted from the model.</p>
              <div className="storm-side-content phenom-content-scrollable">
                <div>
                  {$app.getNodesToBeDeleted().map(node => {
                    switch (node?.xmiType) {
                      case "im:SourceNode":
                      case "im:SinkNode":
                      case "im:FanIn":
                      case "im:TransformNode":
                      case "im:FilterNode":
                      case "im:ViewTransporterNode":
                      case "im:SIMAdapter":
                      case "im:QueuingAdapter":
                      case "im:DataPump":
                      case "im:ComposedInPort":
                      case "im:ComposedOutPort":
                        return <div className="storm-side-delete-detail" key={node.guid}>
                                {node.name && <>
                                  <label>Name</label>
                                  <span id={this.phenomId.genPageId(node.guid, "deleted-name")}>{node.name}</span>
                                </> }

                                <label>xmi:type</label>
                                <span id={this.phenomId.genPageId(node.guid, "deleted-xmiType")}>{node.xmiType}</span>

                                <label>xmi:id</label>
                                <span id={this.phenomId.genPageId(node.guid, "deleted-guid")}>{node.guid}</span>

                                <Button className="storm-side-delete-readd"
                                        id={this.phenomId.genPageId(node.guid, "readd-btn")}
                                        onClick={() => $app.readdDeletedNode(node.guid)}>READD</Button>
                              </div>

                      default:
                        return null;
                    }
                  })}
                </div>
              </div>
          </section>
  }

  /**
   * LINK CONTENT
   * 
   */
  renderLinkDetail = () => {
    const { $app, selectedNode, viewList } = this.props;
    const { srcTemplateTypeMap, dstTemplateTypeMap } = this.state;

    if (selectedNode instanceof BaseLinkModel === false) {
      return null;
    }

    const srcPort = selectedNode.getSourcePort();
    const dstPort = selectedNode.getTargetPort();
  
    return <>
        <div className="storm-side-header">Node Connection</div>
        <div className="storm-side-content phenom-content-scrollable">
          {[srcPort, dstPort].map((port) => {
            if (!port) return null;
            const attrData = port.getAttrData();

            return <div key={port.getOptions().id}>
                    <PhenomLabel text={port.getOptions().in ? "In Ports" : "Out Ports"} />
                    {attrData.getXmiType() === "im:UoPOutputEndPoint" || attrData.getXmiType() === "im:UoPInputEndPoint"
                      ? this.renderEndPointDetail(port) 
                      : <SidePortDetail key={port.getOptions().id}
                                        $app={$app}
                                        parentNodeModel={port.getNode()}
                                        attrData={port.getAttrData()}
                                        port={port}
                                        viewList={viewList}
                                        phenomId={this.phenomId}
                                        templateTypeMap={port.getOptions().in ? dstTemplateTypeMap : srcTemplateTypeMap}
                                        fetchPreviewCode={this.fetchPreviewCode} /> }
                  </div>
                })}
        </div>
    </>
  }

  renderComposedPortDetail = () => {
    const { $app, selectedNode, viewList } = this.props;

    const stormData = selectedNode?.getStormData();
    if (selectedNode instanceof ImComposedPortBlockModel === false || !isStormData(stormData)) {
      return null;
    }

    const background = selectedNode.getNodeColor();
    const xmiType = stormData.getXmiType();
    const header = getShortenedStringRepresentationOfXmiType(xmiType);

    let label, port;
    if (xmiType === "im:ComposedInPort") {
      label = "Data Type (In)";
      port = selectedNode.getImOutPort();   // confusing? yes.
    } else {
      label = "Data Type (Out)";
      port = selectedNode.getImInPort();    // confusing? yes.
    }

    return <section>
      <div className="storm-side-header" style={{ background }}>{ header }</div>
      <div className="storm-side-content phenom-content-scrollable">
        <SidePortDetail $app={$app}
                        idx={0}
                        parentNodeModel={selectedNode}
                        attrData={selectedNode.getStormData()}
                        port={port}
                        viewList={viewList}
                        phenomId={this.phenomId} />
      </div>
    </section>
  }

  renderEndPointDetail = (port) => {
    const { $app } = this.props;
    if (!port) {
      return null;
    }

    const uopiEndPointData = port.getAttrData();
    if (!isStormData(uopiEndPointData)) {
      return null;
    }

    const msgPortData = $app.getDiagramStormData(uopiEndPointData.getAttr("connection"));
    if (!isStormData(msgPortData)) {
      return null;
    }

    return <SideMessagePortDetail msgPortData={msgPortData}
                                  $app={$app}
                                  phenomId={this.phenomId} />
  }



  render() {
    const { show, edit_width } = this.state;

    return (
      <div className="storm-side-panel"
           tabIndex={0}
           onKeyDown={stopBubbleUp}
           style={{ "--resize-width": show ? "3px" : "0px",
                    "--edit-width":   show ? `${edit_width}px` : "0px" }}>
        <div className="storm-side-resize"
             onMouseDown={this.resizeWidth} />

        <StormSideTabs phenomId={this.phenomId}
                       activeTab={show}
                       tabs={["context", "detail", "deletion"]}
                       onClick={(tab) => this.setState({ show: tab })} />

        <div className="storm-side-edit"
             style={{ display: show ? "flex" : "none" }}>
              { this.renderContextDetail() }
              { this.renderDetail() }
              { this.renderDeletion() }
        </div>
      </div>
    )
  }
}
