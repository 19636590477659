import React, { Component } from "react";
import { connect } from "react-redux";
import { Prompt } from "react-router-dom";
import { cloneDeep, invert } from "lodash";
import domToImg from "dom-to-image";

import Skratchpad from "../../skratchpad/Skratchpad";
import { createPhenomGuid, deGuidify, isPhenomGuid, randomChars } from "../../../util/util";

import { BasicAlert } from "../../../dialog/BasicAlert";
import { LoadSavePrompt } from "../modal/LoadSavePrompt";
import { isDiagramNode, formatComposition, formatCharacteristic, createNewNodeData, serializeViewData, serializeCharData, serializeEntityData, convertNullToUndefined } from "../util";
import { withRouterAndRef, KbButton } from "../../../util/stateless"
import PhenomId from "../../../../requests/phenom-id";
import {
    managerColors,

    AssociationModal,
    CharacteristicModal,
} from "../index";

import { Notifications2 } from "../../../edit/notifications"
import NavTree from "../../../tree/NavTree";
import DeletionConfirm2 from "../../../dialog/DeletionConfirm2";
import imageCompression from 'browser-image-compression';
import { _ajax, getNodeWithAddenda } from "../../../../requests/sml-requests";
import { saveAs } from "file-saver";
import { SubMenuRight } from "../../../edit/edit-top-buttons";


// ========================

const whitelist = new Set(["conceptual:Entity", "conceptual:Association", "platform:View"]);

const assoEntiyAddenda = {
    coreAddenda: ["childrenMULTI", "specializes", "specializedBy"],
    coreAddendaChildren: ["pathPairsMULTI", "add_effectiveType"],
}

const viewAddenda = {
    coreAddenda: ["childrenMULTI", "typers"],
    coreAddendaChildren: ["pathPairsMULTI", "viewType"],
}

// used for creating Stencil Node
// used for creating an id tag
const nodeCounter = {
    "conceptual:Entity": 1,
    "conceptual:Association": 1,
    "conceptual:Observable": 1,
    "platform:View": 1,
    "uop:PortableComponent": 1,
};

function extractDiagramID(id) {
    if(!id) return null;
    return id.startsWith("PHENOM-") ? id.substr(7) : null;
}

// ========================


class SkratchpadManager extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navColor: managerColors["modelling"],
            tabIds: [],
            activeTabId: "",
            activeTabIndex: 0,
            dialog: null,
            requestInProgress: [],
            settings: {
                showRemovalWarning: true,
            },

            promptLocation: null,
            promptAnswer: false,
        };

        this.phenomId = new PhenomId("sp");
    }

    /*
     * DATA
     */
    // Tab Managers
    tabData = {};
    placeholderIds = new Set(); // prevent diagram guid collision

    // Nodes
    typeMap = {};
    viewMap = {};
    uopMap = {};
    platformTypeMap = {};
    measurementMap = {};

    // injest into NavTree
    navTreeNodes = {
        "diagramPackage": { p: "root", n:"UncommittedEntities", t:"diagram:Package", x:[], children: {} }
    }

    // Refs
    dialogRef = React.createRef();

    componentDidMount() {
        NavTree.collapseNavTree(false);
        NavTree.assignPresetPageFilters("sp");

        window.addEventListener('DELETED_NODES', this.removeNodesListener);
        window.addEventListener('LOAD_CONTEXT', this.loadContextFromNavTree);
        window["treeRef"].insertDiagramIndex(this.navTreeNodes);
        window["treeRef"].forceUpdate();

        this.fetchData();

        const settings = sessionStorage.getItem('spSettings');
        if (settings) this.setState({ settings: JSON.parse(settings) })

        window["getTreeGuids"] = (guids, pos) => {
            this.tabData[this.state.activeTabId].ref.getGuidsFromTree(guids, pos);
        }

        window["diagramResetNode"] = (guids=[]) => {
          this.resetDiagramNodesFromAllTabs(guids);
        }

        window["deleteDiagramNode"] = (guids=[]) => {
          this.deleteDiagramNodesFromAllTabs(guids);
        }

        // Recover files or create new tab
        const recover = sessionStorage.getItem('spRecover');
        if (recover) {
          BasicAlert.show("Recovering file(s)", "Recovering...", true);
          JSON.parse(recover).forEach((recoverFile) => {
            this.recoverDiagram(recoverFile);
          });
          sessionStorage.removeItem('spRecover');
          BasicAlert.hide();

        } else if (this.props.location.state?.guid) {
          this.loadContextWithAlert(this.props.location.state.guid);

        } else {
          this.setNewTab();
        }
    }

    componentDidUpdate(prevProps) {
      if (this.props.location.state !== prevProps.location.state) {
        if (!this.props.location.state?.guid) return;
        this.loadContextWithAlert(this.props.location.state.guid);
      }
    }

    componentWillUnmount() {
        NavTree.clearPageFilters();

        if(window["sessionExpired"]) {
          const recoverFiles = this.state.tabIds.map((tabId) => this.serializeRecoverFile(tabId));
          sessionStorage.setItem('spRecover', JSON.stringify(recoverFiles));
        }
        sessionStorage.setItem('spSettings', JSON.stringify(this.state.settings));
        window["treeRef"].removeDiagramLeaf("diagramPackage");
        delete window["getTreeGuids"];
        delete window["diagramResetNode"];
        delete window["deleteDiagramNode"];
        window["treeRef"].forceUpdate();
        window.removeEventListener('DELETED_NODES', this.removeNodesListener);
        window.removeEventListener('LOAD_CONTEXT', this.loadContextFromNavTree);
    }

    removeNodesListener = (e) => {
      let guids = e?.detail?.guids;

      // invalid
      if (!Array.isArray(guids)) {
        return;
      }

      for (let contextGuid of guids) {
        const contextFound = Object.values(this.tabData).find(tab => tab.contextGuid === contextGuid);

        if (contextFound) {
          this.removeTab(contextFound.tabId);
        }
      }
    }



    /*
     * Manage Tabs
     */
    getActiveTabId = () => {
      return this.state.activeTabId;
    }

    setNewTab = (fileId=null, fileName, tabId) => {
      const tabIndex = Object.keys(this.tabData).length + 1;
      const newId = tabId || this.createManagerId();

      this.tabData[newId] = {
          tabId: newId,
          saved: true,
          fileName: fileName || `New_${tabIndex}`,
          fileId: fileId,     // used to be Diagram_ID - no longer used
          contextGuid: "",
          activeIds: new Set(),       // [guids]  --> used to prevent duplicate nodes
          uncommitted: new Set(),     // [guids]  --> used with commit nodes dialog box
          diagramGuids: new Set(),    // [guids]  --> used to save hidden diagram node data
          ref: null,
      }

      this.setState((prevState) => ({
          tabIds: [...prevState.tabIds, newId],
          activeTabId: newId,
          activeTabIndex: tabIndex,
      }))
    }

    removeTab = (selectedTabId) => {
      const { activeTabId, tabIds } = this.state;
      // if(tabIds.length < 2) return;

      const data = this.tabData[selectedTabId];
      const closeCurrent = activeTabId === selectedTabId;

      // maybe remove this in the future
      // remove nodes from NavTree
      if (data) {
        [...data.uncommitted].forEach(guid => {
            this.removeUncommittedNode(guid, selectedTabId);
        })
      }

      const newTabIds = tabIds.filter(id => id !== selectedTabId);
      const newActiveTabIndex = closeCurrent ? newTabIds.length - 1 : newTabIds.findIndex(id => id === activeTabId);
      const newActiveTabId = closeCurrent ? newTabIds[newActiveTabIndex] : activeTabId;

      this.setState({
          tabIds: newTabIds,
          activeTabId: newActiveTabId,
          activeTabIndex: newActiveTabIndex,
      }, () => {
        if (!this.state.tabIds.length) {
          this.setNewTab();
        }
      })
    }

    // similar to removeTab(), uses context guid instead of tabID
    removeTabContextGuid = (contextGuid) => {
      const deleteTab = Object.values(this.tabData).find(tab => tab.contextGuid === contextGuid);

      if (!deleteTab) return;

      const { activeTabId, tabIds, activeTab } = this.state;
      const { tabData } = this;
      const newTabIds = tabIds.filter(id => id !== deleteTab.tabId);
      const newActiveTabIndex = activeTab === deleteTab.tabId ? newTabIds.length - 1 : activeTabId;
      const newActiveTabId = activeTab === deleteTab.tabId ? newTabIds[newActiveTabIndex] : activeTabId;

      [...deleteTab.uncommitted].forEach(guid => {
          this.removeUncommittedNode(guid, deleteTab.tabId);
      })

      if (tabData.hasOwnProperty(deleteTab.tabId)) {
        this.setState({
          tabIds: newTabIds,
          activeTabId: newActiveTabId,
          activeTabIndex: newActiveTabIndex,
        }, () => {
          if (!this.state.tabIds.length) {
            this.setNewTab();
          }
          delete tabData[deleteTab.tabId];
          this.forceUpdate();
        })}
    }

    clearTabCanvas = (tabId) => {
      if (!tabId) return;
      const tab = this.tabData[tabId];

      if (tab.ref) {
        tab.ref.forceClearCanvas();
      }
    }

    switchTab = async (tabId) => {
        const tabIndex = this.state.tabIds.findIndex(id => id === tabId);
        this.tabData[tabId].ref.forceNodesToUpdate();
        this.tabData[tabId].ref.engine.repaintCanvas();

        await this.setState({
            activeTabId: tabId,
            activeTabIndex: tabIndex,
        })
    };

    updateTabProps = (key, value, tabId) => {
      const tab = this.tabData[tabId || this.state.activeTabId];
      tab[key] = value;
      this.forceUpdate();
    }

    

    /*
     * Manage New/Edited Nodes
     */
    createManagerId = () => this.createId("MANAGER_");
    createDiagramId = () => this.createId("DIAGRAM_");
    createPortId = () => this.createId("PORT_");
    createId = (text) => {
        const id = text + randomChars(4);

        if(this.placeholderIds.has(id)) {
            return this.createId(text);
        } else {
            this.setPlaceholderId(id);
            return id;
        }
    }

    createNewStencilNode = (xmiType) => {
      let nodeName = "";
      switch(xmiType) {
          case "conceptual:Entity":
              nodeName = "Ent";
              break;
          case "conceptual:Association":
              nodeName = "Assoc";
              break;
          case "platform:View":
              nodeName = "View";
              break;
      }
      const nodeData = createNewNodeData({
        guid: this.createDiagramId(),
        name: nodeName + nodeCounter[xmiType]++,
        xmiType,
        isStencil: true,
      })

      this.setOptionNode(nodeData);
      // set diagram unsaved
      const selectedTab = this.tabData[this.state.activeTabId];
      selectedTab.saved = false;
      this.forceUpdate();
      return nodeData;
    }

    setPlaceholderId = (id) => {
      if(typeof id === "string") this.placeholderIds.add(id);
    }

    setNodeGuid = (category, guid, tabId) => {
      try {
        if(!guid) throw "cannot set empty guid";
        const activeTab = this.tabData[tabId || this.state.activeTabId];

        switch(category) {
          case "active":
            activeTab.activeIds.add(guid);
            break;
          case "diagram":
            activeTab.diagramGuids.add(guid);
            break;
          case "uncommitted":
            activeTab.uncommitted.add(guid);
            break;
          default:
            return;
        }
      } catch (err) {
        console.error(err);
      }
    }

    setActiveGuid = (guid, tabId) => {
      this.setNodeGuid("active", guid, tabId);
    }

    setDiagramGuid = (guid, tabId) => {
      this.setNodeGuid("diagram", guid, tabId);
    }

    setUncommittedGuid = (guid, tabId) => {
      this.setNodeGuid("uncommitted", guid, tabId);
    }

    removeNodeGuid = (category, guid, tabId) => {
      try {
        if(!guid) throw "cannot remove empty guid";
        const activeTab = this.tabData[tabId || this.state.activeTabId];

        switch(category) {
          case "active":
            activeTab.activeIds.delete(guid);
            break;
          case "diagram":
            activeTab.diagramGuids.delete(guid);
            break;
          case "uncommitted":
            activeTab.uncommitted.delete(guid);
            break;
          default:
            return;
        }
      } catch (err) {
        console.error(err);
      }
    }

    removeActiveGuid = (guid, tabId) => {
      this.removeNodeGuid("active", guid, tabId);
    }

    removeDiagramGuid = (guid, tabId) => {
      this.removeNodeGuid("diagram", guid, tabId);
    }

    removeUncommittedGuid = (guid, tabId) => {
      this.removeNodeGuid("uncommitted", guid, tabId);
    }

    removeUncommittedGuidFromAllTabs = (guid) => {
      if(!guid) return;
      this.state.tabIds.forEach(tabId => {
        this.removeUncommittedGuid(guid, tabId);
      })
      this.removeFromNavTree(guid);
    }

    removeGuidFromAllTabs = (guid) => {
      if(!guid) return;
      this.state.tabIds.forEach(tabId => {
          this.removeDiagramGuid(guid, tabId);
          this.removeActiveGuid(guid, tabId);
          this.removeUncommittedGuid(guid, tabId);
      })
      this.removeFromNavTree(guid);
    }

    addToNavTree = (node) => {
      if(!node || !node.guid) return;
      const treeNode = {p: "diagramPackage", n:node.name, t:node.xmiType, x:[], isDiagramNode: true}
      this.navTreeNodes[node.guid] = treeNode;

      window["treeRef"].insertDiagramIndex({[node.guid]: treeNode});
      window["treeRef"].applyFilter();
    }

    removeFromNavTree = (guid) => {
      if(!guid) return;
      window["treeRef"].removeDiagramLeaf(guid);
    }

    addUncommittedNode = (node, tabId) => {
      if(!node || !node.guid) return;
      if(!tabId) tabId = this.state.activeTabId;
      const tab = this.tabData[tabId];
      isDiagramNode(node.guid) && this.setPlaceholderId(node.guid);

      if(whitelist.has(node.xmiType)) {
          this.addToNavTree(node);
          this.setActiveGuid(node.guid, tabId);
          this.setUncommittedGuid(node.guid, tabId);
          isDiagramNode(node.guid) && this.setDiagramGuid(node.guid, tabId);
          tab.saved = false;
          this.forceUpdate();
      }
    }

    removeUncommittedNode = (guid, tabId) => {
      this.removeUncommittedGuid(guid, tabId);

      // Diagram Nodes stay in NavTree
      if(!isDiagramNode(guid)) {
        let keepInNavTree = this.state.tabIds.some(tabId => this.tabData[tabId].uncommitted.has(guid));
        if(!keepInNavTree) this.removeFromNavTree(guid);
      }
    }

    resetNavTreeNodes = () => {
        this.navTreeNodes["diagramPackage"].children = {};
        for(let key in this.navTreeNodes) {
            if(key === "diagramPackage") continue;
            delete this.navTreeNodes[key];
        }
        window["treeRef"].resetTreeData();
        window["treeRef"].insertDiagramIndex(this.navTreeNodes);
        window["treeRef"].forceUpdate();
    }

    rebuildNodeForAllTabs = () => {
        this.state.tabIds.forEach(tabId => {
            this.tabData[tabId].ref.rebuildNodes();
        })
    }

    // Reset button from NavTree Ctx menu
    resetDiagramNodesFromAllTabs = async (guids=[]) => {
      for(let tabId of this.state.tabIds) {
        const sp = this.tabData[tabId].ref;
        if(sp) await sp.resetNodes(guids)
      }

      for(let guid of guids) {
        const node = this.getOptionNode(guid);

        if(node && !isDiagramNode(node.guid) && node.diagramNodeLoaded) {
          await this.getNode(node, true);
        }
      }

      this.resetNavTreeNodes();
      this.rebuildNodeForAllTabs();
      this.forceUpdate();
    }

    // Delete button from NavTree Ctx menu
    deleteDiagramNodesFromAllTabs = async (guids=[]) => {
      guids = guids.filter(g => isDiagramNode(g));

      for(let tabId of this.state.tabIds) {
        const tab = this.tabData[tabId];
        const nodeModels = [];
        let nodeModels2 = [];

        const sp = tab.ref;
        guids.forEach((g) => {
          const nm = sp.findNodeModel(g);
          const nm2 = sp.findNodeModelWithChildren(g);
          if(nm) nodeModels.push(nm);
          if(nm2.length) nodeModels2 = [...nodeModels2, ...nm2];
        });

        sp.deleteNodes(nodeModels, false);
        sp.deleteAssociationChildren(nodeModels2, guids);
      }

      guids.forEach(guid => {
        this.removeGuidFromAllTabs(guid);
        this.removeFromNavTree(guid);
        window["treeRef"].forceUpdate();
      })
      this.resetNavTreeNodes();
      this.rebuildNodeForAllTabs();
      this.forceUpdate();
    }

    // reset => NavTree context menu allows the user to reset the node
    // diagramNodeLoaded => prevents refetching the same data again (overriding changes)
    //    cases for diagramNodeLoaded:
    //      -> if user drags in the same node but into multiple tabs
    //      -> if user drags in a node, edits it, removes it, and then readds it
    getNode = async (node, reset=false) => {
      let res;
      let response = this.getOptionNode(node.guid);

      if(!response || reset || !response.diagramNodeLoaded) {
          try {
              switch(node.xmiType) {
                  case "conceptual:Entity":
                  case "conceptual:Association":
                      response = await this.getAssoEntity(node.guid);

                      response.children.forEach(child => {
                          formatComposition(child);
                      })
                      break;

                  case "conceptual:Observable":
                      res = await this.getObservable(node.guid);
                      response = JSON.parse(res);
                      break;

                  case "platform:View":
                      response = await this.getView(node.guid);

                      response.children.forEach(child => {
                          formatCharacteristic(child, {getOptionNode: this.getOptionNode});
                      })
                      break;

                  case "uop:PortableComponent":
                      res = await this.getUoP(node.guid);
                      response = JSON.parse(res);
                      break;
              }

              if(reset) {
                this.updateOrSetOptionNode(response);
              } else {
                this.setOptionNode(response)
              }
          } catch (error) {
              return false;
          }
      }
      return response;
    }



    /*
     * Manage Option Lists
     */
    setOptionNode = (node, specificGuid) => {
      if(!node || !node.guid) return;
      let guid = specificGuid ? specificGuid : node.guid;

      switch(node.xmiType) {
          case "conceptual:Entity":
          case "conceptual:Association":
              this.typeMap[guid] = node;
              break;
          case "platform:View":
              this.viewMap[guid] = node;
              break;
          case "logical:Measurement":
              this.measurementMap[guid] = node;
              break;
          case "uop:PortableComponent":
              this.uopMap[guid] = node;
              break;
      }
    }

    getOptionNode = (guid) => {
      let node;
      [
          this.typeMap,
          this.viewMap,
          this.measurementMap,
          this.uopMap,
      ]
      .forEach(list => {
          if(!node) node = list[guid];
      });
      return node;
    }

    // Update Node without replacing the reference pointers!
    updateOrSetOptionNode = (newNode, diagramId) => {
        if(!newNode || !newNode.guid) return;
        let existingNode = this.getOptionNode(diagramId || newNode.guid);

        // If diagramId is provided then the diagramNode is being converted into a real node
        //    the diagramId and real guid will point to the same memory location
        if(diagramId && existingNode) {
            this.setOptionNode(existingNode, newNode.guid);
        }

        // update the node without replacing the reference pointer
        if (existingNode) {
            for(let key in newNode) {
                if(key === "children") continue;
                existingNode[key] = newNode[key];
            }

            for(let i = 0; i < newNode.children.length; i++) {
                let newChild = newNode.children[i];
                let existingChild = existingNode.children.find(c => c.guid === newChild.diagramId || c.guid === newChild.guid);

                if(existingChild) {
                    for(let key in newChild) {
                        existingChild[key] = newChild[key];
                    }
                } else {
                    existingNode.children.push(newChild);
                }
            }
        } else {
            this.setOptionNode(newNode);
        }
    }



    /*
     * Get Data
     */
    fetchData = () => {
        this.getTypeOptions()
            .then(() => this.getMeasurements());
    }

    getTypeOptions = () => {
        return _ajax({
            url: "/index.php?r=/node/model-nodes-of-type",
            method: "get",
            data: {
                type: ["conceptual:Entity", "conceptual:Association", "conceptual:Observable"],
                coreAddenda: ["childrenMULTI", "specializes", "specializedBy"],
            },
        }).then(res => {
          this.typeMap = {...this.typeMap, ...deGuidify(res.data.nodes)};
          this.forceUpdate();
        })
    }

    getAssoEntity = (guid) => {
        return _ajax({
            url: "/index.php?r=/node/model-get-node",
            method: "get",
            data: {
                guid,
                ...assoEntiyAddenda
            },
        })
    }

    getView = (guid) => {
        return _ajax({
            url: "/index.php?r=/view/model-get-node",
            method: "get",
            data: {
                guid,
                ...viewAddenda
            }
        })
    }

    getMeasurements = () => {
      _ajax({
        url: "/index.php?r=/node/model-nodes-of-type",
        method: "get",
        data: {
          type: ["logical:Measurement"],
          coreAddenda: ["realizationsMULTI", "measurementAxisMULTI", "measurementSystem"],
          measurementAxisAddenda: ["valueTypeUnitMULTI"],
          valueTypeUnitAddenda: ["valueTypeMULTI", "childrenMULTI"],
        },
      }).then(res => {
        const measurementMap = {};
        const platformTypeMap = {};
        const measurements = res.data.nodes;

        measurements.forEach(measurement => {
          const observable = this.getOptionNode(measurement.realizes);
          if(observable) {
            const realizations = observable.realizations ? observable.realizations.split(" ") : [];
            if (!realizations.includes(measurement.guid)) {
                 realizations.push(measurement.guid);
            }
            observable.realizations = realizations.join(" ");
          }

          measurementMap[measurement.guid] = measurement;

          measurement.realizations.forEach(pt => {
            platformTypeMap[pt.guid] = pt;
          })
        })

        this.measurementMap = measurementMap;
        this.platformTypeMap = platformTypeMap;
        this.forceUpdate();
      })
    }

    getRealizedPlatformTypeList = (measurementGuid) => {
      return Object.values(this.platformTypeMap).filter(pt => pt.realizes === measurementGuid);
    }

    addPlatformTypeMap = (platformType) => {
      if (!platformType?.guid) return;
      this.platformTypeMap[platformType.guid] = platformType;
    }

    /*
     * Used by Scratchpad
     */

    // when dragging a node(s) from the NavTree and if there was a delay, the user was able to create duplicate nodes.
    // the nodes are stored in an array and checked against
    disableDragFromNavTree = (nodes=[]) => {
      this.setState((prevState) => {
        return ({
          requestInProgress: [...prevState.requestInProgress, ...nodes]
        })
      });
    }

    // the node(s) are filtered out of the array, allowing the user to drag the node into Scratchpad (and multiple tabs)
    enableDragFromNavTree = (nodes=[]) => {
      this.setState((prevState) => {
        const requestInProgress = prevState.requestInProgress.filter((inProgressNode) => {
          return !nodes.some((node) => node.guid === inProgressNode.guid);
        })

        return ({
          requestInProgress
        })
      })
    }

    // if the request is still in progress then the returns a list of names
    isPrevRequestStillInProgress = (nodes=[]) => {
      const nodesFromPrevRequest = nodes.filter((node) => {
        return this.state.requestInProgress.some((inProgressNode) => inProgressNode.guid === node.guid)
      });

      return nodesFromPrevRequest.map(n => n.name);
    }

    createScreenshotWithMode = (tabId, mode="m") => {
      try {
        const activeTab = this.tabData[tabId || this.state.activeTabId];
        if (mode === "m") {
          activeTab.ref.switchToModellingMode();
        } else {
          activeTab.ref.switchToProjectionMode();
        }

        return this.createScreenshot(tabId);

      } catch (error) {
        console.error(error);
      }
    }

    createScreenshot = async (tabId) => {
      try {
        const activeTab = this.tabData[tabId || this.state.activeTabId];
        const { engine, model } = activeTab.ref;
        const { min_x, min_y, max_x, max_y } = this.getCroppedCanvasCoords(tabId);
        const prevZoom = model.getZoomLevel();
        const canvas = engine.getCanvas();
        const buffer = 50;

        // DomToImg does not have a way to crop
        // have to manipulate the current canvas and then reset it afterwards
        model.setZoomLevel(100);
        model.setOffsetX(Math.round(min_x) * -1 + buffer);
        model.setOffsetY(Math.round(min_y) * -1 + buffer);
        engine.repaintCanvas();

        const rawImage = await domToImg.toBlob(canvas, { 
            bgcolor: "transparent", 
            height: max_y - min_y + (buffer * 2), 
            width: max_x - min_x + (buffer * 2), 
        })

        const compressedFile = await imageCompression(rawImage);
        const compressedBase64 = await imageCompression.getDataUrlFromFile(compressedFile);

        model.setZoomLevel(prevZoom);
        model.setOffsetX(0);
        model.setOffsetY(0);
        engine.repaintCanvas();
        return compressedBase64;

      } catch (error) {
        console.error(error);
      }
    }

    getCroppedCanvasCoords = (tabId) => {
      try {
          const activeTab = this.tabData[tabId || this.state.activeTabId];
          const { model } = activeTab.ref;

          if (!model.getNodes().length) {
              return { min_x: 0, min_y: 0, max_x: 0, max_y: 0 };
          }
  
          let min_x = Infinity;
          let min_y = Infinity; 
          let max_x = 0;
          let max_y = 0;
  
          model.getNodes().forEach((nodeModel) => {
              const { points } = nodeModel.getBoundingBox();
              min_x = Math.min(min_x, points[0].x);
              min_y = Math.min(min_y, points[0].y);
              max_x = Math.max(max_x, points[2].x);
              max_y = Math.max(max_y, points[2].y);
          })
          
          return { min_x, min_y, max_x, max_y }

      } catch (err) {
          console.error(err);
      }
    }

    screenshotDiagram = () => {
      const activeTab = this.tabData[this.state.activeTabId];

      BasicAlert.show(`Generating ${activeTab.fileName}`, "One moment please", true);

      this.createScreenshot(this.state.activeTabId)
          .then((imgData) => {
            saveAs(imgData, `${activeTab.fileName || "my-diagram"}.png`);
            BasicAlert.hide();
          })
    };

    showDialog = (dialogType=null) => {
        this.dialogRef.current.show(dialogType)
    }

    showDialogSingle = (guid, confirmFunc, cancelFunc) => {
        this.dialogRef.current.showCommitSingleNode(guid, confirmFunc, cancelFunc);
    }



    /*
     * ACTIONS
     */
    recoverDiagram = async (recover) => {
      await this.setNewTab(recover.fileId, recover.fileName, recover.tabId);

      const activeTab = this.tabData[recover.tabId];
      activeTab.activeIds = new Set(recover.activeIds);
      activeTab.uncommitted = new Set(recover.uncommitted);
      activeTab.diagramGuids = new Set(recover.diagramGuids);

      const diagramInstance = activeTab.ref;
      await diagramInstance.loadDiagram(recover.content);

      Notifications2.parseLogs(`Recovered '${recover.fileName}' diagram.`);
    }

    serializeRecoverFile = (tabId) => {
      const selectedTab = this.tabData[tabId];
      const recoverFile = {...selectedTab};
      recoverFile.content = this.serializeDiagram(tabId);

      // convert Set into Array (so it can be stringified)
      recoverFile.activeIds = [...recoverFile.activeIds];
      recoverFile.uncommitted = [...recoverFile.uncommitted];
      recoverFile.diagramGuids = [...recoverFile.diagramGuids];

      // remove ref (so it doesn't get stringified)
      delete recoverFile.ref;

      return recoverFile;
    }

    serializeDiagram = (tabId) => {
      const selectedTab = this.tabData[tabId || this.state.activeTabId];
      const { model } = selectedTab.ref;
      const diagramNodes = [];

      // diagram nodes exist in NavTree but not on the canvas
      [...selectedTab.diagramGuids].forEach(guid => {
        if(!selectedTab.activeIds.has(guid)) {
            const node = this.getOptionNode(guid);
            if(node) diagramNodes.push(node);
        }
      });

      let serializedData = model.serialize();
          serializedData.diagramNodes = diagramNodes;

      return serializedData;
    }


    loadContextWithAlert = async (contextGuid) => {
      if (!contextGuid) return;
      BasicAlert.show("Loading Context...", "Loading", true);
      await this.loadContext(contextGuid);
      BasicAlert.hide();
    }

    loadContextFromNavTree = (e) => {
      const { stormType } = this.props;
      const { detail } = e;
  
      // invalid
      if (!detail) {
        return;
      }

      BasicAlert.show("Loading Context...", "Loading", true);
      this.loadContext(detail.guid);
      BasicAlert.hide();
    }

    /**
     * Fetches Context Node and Presentational data
     */
    loadContext = async (contextGuid) => {
      if (!contextGuid) return;

      // Remove state from window history
      this.props.location.state?.guid && window.history.replaceState(null, document.title);

      // Switch tab if diagram was previously loaded
      const alreadyLoaded = Object.values(this.tabData).find(tab => tab.contextGuid === contextGuid);

      let activeTab = this.tabData[this.state.activeTabId];
      
      // Create new tab if no diagram exist yet (i.e. user double clicked nav tree)
      // Create new tab if current diagram is being used
      if (!activeTab?.ref || activeTab.activeIds.size) {
        await this.setNewTab();

        const newId = this.state.tabIds[this.state.tabIds.length - 1];
        activeTab = this.tabData[newId];
      }

      let diagramInstance = activeTab.ref;
      const response = await getNodeWithAddenda(contextGuid);

      if ("error" in response || "errors" in response) {
        return;
      }

      await diagramInstance.loadDiagram(JSON.parse(response.content));
      activeTab.saved = true;
      activeTab.fileName = response.name;
      activeTab.contextGuid = response.guid;
      diagramInstance.setContextState(response);

      if (response.mode === "m") {
        diagramInstance.switchToModellingMode();
      } else {
        diagramInstance.switchToProjectionMode();
      }

      // if already loaded, delete that node and clean data
      if (alreadyLoaded) {
        this.removeTabContextGuid(contextGuid);
      } else {
        this.forceUpdate();
      }
    }

    saveDiagram = async (tabId) => {
        try {
          const selectedTab = this.tabData[tabId || this.state.activeTabId];

          BasicAlert.show("Saving diagram...", "Saving");

          let content = JSON.stringify(this.serializeDiagram(tabId));
          const image = await this.createScreenshot(tabId);

          let contextNode = {
            ...selectedTab.ref.getContextState(),
            mode: selectedTab.ref.getMode(),
            content,
            image,
          };
          contextNode.deconflictName = isPhenomGuid(contextNode.guid);

          const res = await _ajax({
            method: "post",
            url: "/index.php?r=/node/smm-save-nodes",
            data: {
                node : contextNode,
            },
          });

          const response = res.data;

          if (!response.nodes) {
            return false;
          }

          NavTree.addNodes(response.nodes);
          const context = response.nodes[0];

          selectedTab.saved = true;
          selectedTab.fileName = context.name;
          selectedTab.contextGuid = context.guid;
          selectedTab.ref.setContextState(context);

          this.forceUpdate();
          Notifications2.parseLogs(`${context.name} successfully saved.`);
          BasicAlert.hide();
          return true;

        } catch (err) {
            Notifications2.parseErrors("Unable to save diagram. Server responded with an unexpected status code.");
            BasicAlert.hide();
            return false;
        }
    };

    saveDiagramAs = async (tabId, saveAs, parentPackage) => {
      try {
        let selectedTab = this.tabData[tabId || this.state.activeTabId];
        const saveAsData = saveAs || null;
        const parent = parentPackage || null;

        BasicAlert.show("Saving diagram...", "Saving");
        let content = JSON.stringify(this.serializeDiagram(tabId));
        const image = await this.createScreenshot(tabId);

        let contextNode = {
          ...selectedTab.ref.getContextState(),
          mode: selectedTab.ref.getMode(),
          content,
          image,
        };

        // if saveAs object has "name" key it is matched otherwise "fileName" means it is saving as a new diagram
        if(saveAsData && saveAsData.hasOwnProperty("name") || saveAsData.hasOwnProperty("fileName")) {
          contextNode.name = saveAsData.name || saveAsData.fileName;
        } 

        if(saveAsData && saveAsData.hasOwnProperty("guid")) {
          contextNode.guid = saveAsData.guid;
        } else {
          contextNode.guid = createPhenomGuid();
        }

        // check for saveAs package selection
        if (parent) {
          contextNode.parent = parent;
        }

        contextNode.deconflictName = isPhenomGuid(contextNode.guid);

        const res = await _ajax({
          method: "post",
          url: "/index.php?r=/node/smm-save-nodes",
          data: {
              node : contextNode,
          },
        });

        const response = res.data;

        if (!response.nodes) {
          return false;
        }

        NavTree.addNodes(response.nodes);
        const context = response.nodes[0];

        if(saveAsData !== null && (saveAsData?.name || saveAsData?.fileName)) {
          await this.loadContext(context.guid);
          selectedTab.saved = true;
        } else {
          selectedTab.saved = true;
          selectedTab.fileName = context.name;
          selectedTab.contextGuid = context.guid;
          selectedTab.ref.setContextState(context);
        }        

        this.forceUpdate();
        Notifications2.parseLogs(`${context.name} successfully saved.`);
        BasicAlert.hide();
        return true;

      } catch (err) {
          Notifications2.parseErrors("Unable to save diagram. Server responded with an unexpected status code.");
          BasicAlert.hide();
          return false;
      }
  };

    deleteContext = (context, callback) => {
      if (!context?.guid) return;
      DeletionConfirm2.show(context.guid, context.name, (status) => {
        if (!status.deleted) return;
        callback && callback();

        const activeTab = Object.values(this.tabData).find(tab => tab.contextGuid === context.guid);
        if (!activeTab) return;
        this.removeTab(activeTab.tabId);
      }, true);
    }





    /*
     * HELPER
     */
    promptContinue = async () => {
        await this.setState({promptAnswer: true});
    }

    // used by util > CogOptions
    toggleRemovalWarning = () => {
      const settings = {
          ...this.state.settings,
          showRemovalWarning: !this.state.settings.showRemovalWarning,
      }
      this.setState({ settings });
    }

    // used by LoadSavePrompt
    getMissingDependencies = (guid, missingGuids) => {
        const node = this.getOptionNode(guid);
        if(!missingGuids) missingGuids = new Set();
        if(!node) return missingGuids;

        if(Array.isArray(node.children)) {
            for(let child of node.children) {
                switch(child.xmiType) {
                    case "conceptual:Composition":
                    case "conceptual:AssociatedEntity":
                        if(isDiagramNode(child.type)) {
                            var depNode = this.getOptionNode(child.type);
                        }
                        break;

                    case "platform:CharacteristicProjection":
                        if(child.viewType && isDiagramNode(child.viewType.guid)) {
                            var depNode = this.getOptionNode(child.viewType.guid);
                        }
                        break;
                }

                if(depNode && !missingGuids.has(depNode.guid)) {
                    missingGuids.add(depNode.guid);
                    this.getMissingDependencies(depNode.guid, missingGuids);
                }
            }
        }
        return missingGuids;
    }

    // used by Scratchpad
    changeNavColor = (mode) => {
        this.setState({navColor: managerColors[mode]})
    }



    /*
     * SAVE NODES TO DATABASE
     */
    // Order of deletion: projection, view, associated entities, compositions, entities/associations
    // This b/c projections depend on compositions/ae's, ae's (may) depend on compositions, and ae's and compositions depend on entities.
    commitChanges = async (requestedGuids=[], selectedTabId) => {
        const currentTab = this.tabData[selectedTabId];
        if (!requestedGuids.length || !currentTab) return;

        BasicAlert.show("Committing nodes...", "Saving");
        let { errors=[], saveNodes=[] } = this.prepareRequestData(requestedGuids);

        if(errors.length) {
            BasicAlert.hide();
            Notifications2.parseErrors(errors);
            return Promise.reject("errors");
        }

        return _ajax({
          method: "post",
          url: "/index.php?r=/node/smm-save-nodes",
          data: {
            nodes: saveNodes,
            returnTypes: [...whitelist],
            returnAddenda: {
              "conceptual:Entity": assoEntiyAddenda,
              "conceptual:Association": assoEntiyAddenda,
              "platform:View": viewAddenda,
            }
          }
        }).then(async (res) => {
          const response = res.data;

          if (!Array.isArray(response?.nodes)) {
            BasicAlert.hide();
            Notifications2.parseErrors("Server responded with an unexpected status code.");
            return;
          }

          // swap key-value pairs to change format into guid->diagramId
          const referencees = invert(response.referencees);
  
          for (let node of response.nodes) {
              // assign diagramId for easy reference
              node.diagramId = extractDiagramID(referencees[node.guid]);

              switch(node.xmiType) {
                  case "conceptual:Entity":
                  case "conceptual:Association":
                      node.children.forEach(c => {
                          c.diagramId = extractDiagramID(referencees[c.guid]);
                          formatComposition(c);
                      });
                  case "platform:View":
                      node.children.forEach(c => {
                          c.diagramId = extractDiagramID(referencees[c.guid]);
                          formatCharacteristic(c, {getOptionNode: this.getOptionNode});
                      });
              }

              this.updateOrSetOptionNode(node, node.diagramId);

              if(node.diagramId) {
                  this.removeGuidFromAllTabs(node.diagramId);
              } else {
                  this.removeUncommittedGuidFromAllTabs(node.guid);
              }

              this.setActiveGuid(node.guid, selectedTabId);
          }

          NavTree.addNodes(response.nodes);
  
          this.getMeasurements();           // refresh the measurement and platform type list
          this.resetNavTreeNodes();
          this.rebuildNodeForAllTabs();
  
          if(currentTab.tabId) {
              await this.saveDiagram(currentTab.tabId);
          }

          Notifications2.parseResponse(response);
          BasicAlert.hide();
          this.forceUpdate();
        }).fail(() => {
          BasicAlert.hide();
        })
    }

    prepareRequestData = (requestedGuids=[]) => {
        let errors = [];
        let saveNodes = [];

        let goodNodes = new Set();  // nodes with resolved dependencies
        let stack = [];

        let list = requestedGuids.map(guid => {
            const n = cloneDeep(this.getOptionNode(guid));
                // edge case: the node can be undefined
                if(!n) errors.push(`A dependency with guid: ${guid}, doesn't exist in the diagram anymore.`);
                return n;
        });

        requestLoop:
        for(let n of list) {
            stack.push(n);

            stackLoop:
            while(stack.length && !errors.length) {
                let pushToStack = new Set();        // gather guids to be pushed on to stack
                let allGood = true;
                let node = stack[stack.length - 1];
                let aeCount = 0;

                // Imma good node :)
                if(goodNodes.has(node.guid)) {
                    stack.pop();
                    continue;
                }

                if(node.children) {
                    for(let child of node.children) {
                        if(child.xmiType === "conceptual:AssociatedEntity") {
                            aeCount++;
                        }

                        if(child.type) {
                            const typeGuid = typeof child.type === 'string' ? child.type : child.type.guid;
                            if(isDiagramNode(typeGuid) && !goodNodes.has(typeGuid)) {
                                pushToStack.add(typeGuid);
                                allGood = false;
                            }
                        }

                        if(child.projectedCharacteristic) {
                            const pcGuid = typeof child.projectedCharacteristic === 'string' ? child.projectedCharacteristic : child.projectedCharacteristic.guid;
                            if(isDiagramNode(pcGuid) && !goodNodes.has(pcGuid)) {
                                pushToStack.add(pcGuid);
                                allGood = false;
                            }
                        }

                        if(Array.isArray(child.pathPairs)) {
                            child.pathPairs.forEach(pair => {
                                const parentGuid = typeof pair.parent === 'string' ? pair.parent : pair.parent.guid;
                                const typeGuid = typeof pair.type === 'string' ? pair.type : pair.type.guid;

                                // 5a) Path parent is missing
                                if(isDiagramNode(parentGuid) && !goodNodes.has(parentGuid)) {
                                    pushToStack.add(parentGuid);
                                    allGood = false;
                                }

                                // 5b) Type is missing
                                if(isDiagramNode(typeGuid) && !goodNodes.has(typeGuid)) {
                                    pushToStack.add(typeGuid);
                                    allGood = false;
                                }
                            })
                        }
                    }
                } // if

                if(aeCount === 1) {
                    allGood = false;
                    errors.push(`The Association ${node.name}, needs at least 2 participants.`);
                    break requestLoop;
                }

                // success!
                if(allGood) {
                    goodNodes.add(node.guid);

                    if(node.xmiType === "platform:View") {
                        saveNodes.push(this.generateViewNode(node));
                    } else {
                        saveNodes.push(this.generateEntityNode(node));
                    }
                    stack.pop();

                // need dependencies:
                } else {
                    [...pushToStack].forEach(id => {
                        let newNode = cloneDeep(this.getOptionNode(id));
                        stack.push(newNode);
                    })
                }
            }
        }

        return {
            errors,
            saveNodes,
        }
    }

    generateEntityNode = (node) => {
      const data = serializeEntityData(node);

      // isPlaceholder
      if (isDiagramNode(node.guid)) {
          data.guid = `PHENOM-${node.guid}`;
      }

      // convert specializes to guid
      if(data.specializes?.guid) data.specializes = data.specializes.guid;

      // reduce request size
      data.children = node.children.filter(comp => comp.isUncommitted);
      data.children.forEach(comp => {
          // isPlaceholder
          if (isDiagramNode(comp.guid)) {
            comp.guid = `PHENOM-${comp.guid}`;
          }

          // convert type to guid
          if (comp.type?.guid) comp.type = comp.type.guid;
          if (isDiagramNode(comp.type)) comp.type = `PHENOM-${comp.type}`;

          // convert specializes to guid
          if (comp.specializes?.guid) comp.specializes = comp.specializes.guid;

          comp.parent = data.guid;
      })

      return convertNullToUndefined(data);
    }

    generateViewNode = (node) => {
        let isComposite = node.structureKind === "composite";
        const data = serializeViewData(node);

        // isPlaceholder
        if (isDiagramNode(node.guid)) {
            data.guid = `PHENOM-${node.guid}`;
        }

        // reduce request size
        data.children = node.children.filter(char => char.isUncommitted).map(c => {
          const char = serializeCharData(c, data);
                char.parent = data.guid;

          // isPlaceholder
          if (isDiagramNode(char.guid)) {
            char.guid = `PHENOM-${char.guid}`;
          }

          if (char.projectedCharacteristic?.guid) {
            char.projectedCharacteristic = char.projectedCharacteristic.guid;
          }

          if (isComposite) {
            char.path = "";
            char.attributeKind = "none";
          }
          return char;
        })
        return convertNullToUndefined(data);
    }

    render() {
        const { doNotRenderPrompt } = this.props;
        const { params } = this.props.match;

        return <div id={this.phenomId.genPageId("manager")} 
                    className="fill-vertical"
                    style={{ overflow: "hidden" }}>
            {/* 
              This is a hacky way of doing this (this will go away after scratchpad is refactored)
                  -> originally Prompt is supposed to show like the window alert - but people wanted something special
                  -> this was created before redux.  redux can solve this issue as well.

              promptAnswer        -> this is what allows the user to navigate away or force them to stay on this page
              location.state.guid -> when the user double click the diagram nodes in NavTree, the react router history changes and Prompt will trigger because it thinks you're navigating away. This is another check to prevent it from occurring
              tabIds              -> looks for a diagram that is not saved
            */}
            {!doNotRenderPrompt && 
            <Prompt when={!this.props.location?.state?.guid && !this.state.promptAnswer && this.state.tabIds.some(tabId => !this.tabData[tabId].saved)}
                    message={(location) => {
                        this.showDialog("savePrompt");
                        this.setState({
                            promptLocation: location,
                        })
                        return "";
                    }} /> }

            <div id={this.phenomId.genPageId("navbar")} className="diagram-nav" style={{background: "hsl(var(--skayl-purple-hs) 65%)"}}>
                {this.state.tabIds.map((tabId, idx, array) => {
                    const domId = "tab" + idx;
                    const data = this.tabData[tabId];
                    return (
                        <span id={this.phenomId.genPageId(domId)} style={{display:"flex", alignItems:"center", padding:"5px 7px"}}
                                className={this.state.activeTabId === tabId ? "active" : ""}
                                onClick={() => this.switchTab(tabId)}>
                                    {data.fileName}

                            {array.length > 1 &&
                                <button id={this.phenomId.genPageId(domId, "close-btn")}
                                        className="k-icon k-i-close"
                                        style={{margin: "0 0 0 5px", padding: 0}}
                                        onClick={(e) => {
                                            if(this.state.diableTabs) return;
                                            e.stopPropagation();
                                            this.removeTab(tabId);
                                        }} />}
                        </span>)})}
                <button id={this.phenomId.genPageId("add-new-tab")} onClick={() => this.setNewTab()}><span className="k-icon k-i-plus"/></button>
                <SubMenuRight style={{paddingRight: 8}}> 
                    <KbButton /> 
                </SubMenuRight>
            </div> 
            {this.state.tabIds.map((tabId, idx, array) => {
                if(!this.tabData[tabId]) return null;

                const data = this.tabData[tabId];

                return (
                    <div key={tabId}
                         className="fill-vertical"
                         style={{
                          display: this.state.activeTabId === tabId ? "flex" : "none",
                          width:"100%",
                          overflow: "hidden" }}>
                        <Skratchpad
                            ref={el => {
                                return this.tabData[tabId].ref = el
                            }}
                            idCtx={this.phenomId.genPageId("diagram", idx)}
                            tabId={tabId}
                            tabData={data}
                            fileName={data.fileName}
                            manager={this}
                            typeOptions={this.typeMap}
                            canEdit={this.props.canEdit}
                            params={params}
                        />
                    </div>
                )
            })}
            <LoadSavePrompt category="s"
                            tabData={this.tabData}
                            activeTabId={this.state.activeTabId}
                            manager={this}
                            commitChanges={this.commitChanges}
                            getOptionNode={this.getOptionNode}
                            promptHistory={this.props.history}
                            promptLocation={this.state.promptLocation}
                            promptContinue={this.promptContinue}
                            canEdit={this.props.canEdit}
                            ref={this.dialogRef} />

            <AssociationModal ref={el => this.assoModalRef = el} />
            <CharacteristicModal ref={el => this.charModalRef = el}
                                 measurementMap={this.measurementMap}
                                 platformTypeMap={this.platformTypeMap}
                                 getOptionNode={this.getOptionNode}
                                 getRealizedPlatformTypeList={this.getRealizedPlatformTypeList}
                                 addPlatformTypeMap={this.addPlatformTypeMap} />

        </div>;
    }
}



const msp = (state) => ({
  canEdit: state.user.canEdit,
})

export default connect(msp, null, null, { forwardRef: true })(withRouterAndRef(SkratchpadManager));
