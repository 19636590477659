import React from 'react'
import $ from 'jquery'
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { receiveErrors, receiveResponse, receiveWarnings } from '../../requests/actionCreators';
import { connect } from 'react-redux';
import { Button } from '@progress/kendo-react-buttons';
import { PhenomComboBox, PhenomLabel } from '../util/stateless';
import { BasicAlert } from '../dialog/BasicAlert';
import { getAccountInfo, getAccountLicenses, getAccountCincLicenses } from '../../requests/sml-requests';
import { SubMenuLeft, SubMenuRight } from '../edit/edit-top-buttons';
import { NavLink } from 'react-router-dom';
import PhenomId from '../../requests/phenom-id';
import { PhenomModal } from "../util/Modal";
import { Link } from 'react-router-dom'
import { formatDate } from '../util/util';
import { _ajax } from '../../requests/sml-requests';

class CincLicenses extends React.Component {
  licenseHash = {}
  state = {
    license_list: [],
    logView: false
  }

  componentDidMount() {
    this.init();
  }

  init = () => {
    const { userIdentity } = this.props;

    _ajax({
        url: "/index.php?r=/generate/get-main-program-options",
        method: "post"
    }).then(res => {
        let license_list = Object.keys(res.data.licenses).map(x => {
          return ({...res.data.licenses[x], guid:x})
        })

        this.setState({license_list})
    }).catch(err => {
      
    });

    return;
  }

  refresh = async () => {
    BasicAlert.show("Refreshing license list...", "Refreshing", false);
    await this.init();
    BasicAlert.hide();
  }


  isDateExpired = (dateRaw) => {
    if (!dateRaw) return false;
    const expire_date = new Date(dateRaw);
    return Date.now() > expire_date.getTime();
  }

  willDateExpire = (dateRaw) => {
    if (!dateRaw) return false;
    const expire_date = new Date(dateRaw);
    const twoWeeks = 1209600000;
    return Date.now() > expire_date.getTime() - twoWeeks && Date.now() < expire_date.getTime();
  }

  renderExpireDateCell = (cellProps) => {
    const { license_id, user_id, username, expiration_date } = cellProps.dataItem;

    if (!expiration_date) {
      return <td>None</td>
    }

    const expire_date = new Date(expiration_date);
    const isExpired = this.isDateExpired(expiration_date);
    const willExpire = this.willDateExpire(expiration_date);

    let background;
    if (isExpired) background = "var(--bs-danger)";
    if (willExpire) background = "var(--bs-warning)";

    return <td style={{ background }}>
      {`${expire_date.getMonth() + 1}/${expire_date.getDate()}/${expire_date.getFullYear()} - ${expire_date.getHours()}:${expire_date.getMinutes()}:${expire_date.getSeconds()}`}
    </td>
  }

  showLogs = (dataItem) => {

      _ajax({
          url: "/index.php?r=/generate/get-generation-logs",
          method: "post",
          data:{license_id: dataItem.guid}
      }).then(res => {
          this.setState({logView:res.data.logs})
      });
  }

  renderLastUsageCell = (dataItem) => {
      return  <td> 
          {dataItem.present ? <Link to={`/integration/details/main_program/${dataItem.last_usage_guid}`}>{dataItem.last_usage_name}</Link> : dataItem.last_usage_name} at {formatDate(dataItem.last_usage_time)}
          </td>
  }

  renderRow = (_, cellProps) => {

    return <tr>
      <td>{ cellProps.dataItem.guid }</td>
      <td>{ cellProps.dataItem.type }</td>
      { (cellProps.dataItem.last_usage_guid) ? 
        this.renderLastUsageCell(cellProps.dataItem) :
        <td> (Unused) </td>
      }
      <td><Button onClick = {()=>this.showLogs(cellProps.dataItem)}>Show logs</Button></td>
    </tr>
  }

  render() {
    const { accountInfo, usersTable, license_list } = this.state;
    const phenomId = new PhenomId("licenses",this.props.idCtx);

    const countActiveLicenses = (license_list || []).reduce((total, currLicense) => {
      if (!this.isDateExpired(currLicense?.expiration_date) && !(currLicense.type == "EVAL")) {
        return total + 1;
      }
      return total;
    }, 0);

    return <div className="phenom-content-wrapper">
      <nav className="sub-menu-actions" aria-label='form actions'>
        <SubMenuLeft>
        <NavLink id="subscription-link" activeClassName="active" to="/settings/subscription/users">Users</NavLink>
        <NavLink id="licenses-link" activeClassName="active" to="/settings/subscription/licenses">Phenom Licenses</NavLink>
        <NavLink id="cinc-licenses-link" activeClassName="active" to="/settings/subscription/cinc-licenses">CinC Licenses</NavLink>
        </SubMenuLeft>
        <SubMenuRight>
            <button id={phenomId.gen("","save")}
                    className="fas fa-save"
                    title="Save"
                    onClick={this.handleSaveChanges} />
        </SubMenuRight>
      </nav>
      <div className="phenom-content-scrollable subview-wrapper">
        <div>
          <PhenomLabel text="Subscription" />
          <p>CinC production licenses: { countActiveLicenses } </p>

        </div>

        <PhenomLabel text="Licenses" />
        <Grid data={license_list}
              className="without-box-shadow"
              rowRender={this.renderRow}>
          <GridNoRecords>No Data Is Available For This Table</GridNoRecords>

          <GridColumn title="License" />
          <GridColumn width="80px" title="Type" />
          <GridColumn title="Last Main Generated" />
          <GridColumn title="Logs" />
        </Grid>

        {this.state.logView && <PhenomModal show = {true} onClose={()=> this.setState({logView:false})}
        children={this.state.logView.map(x => {
          return(<div style={{margin: 10}}>
            {new Date(x.generation_time).toLocaleString()}: {x.user} generated {x.version} with {x.name}
            </div>)
        })}/>}
      </div>
    </div>
  }
}



const msp = (state) => ({
  userIdentity: state.user.userIdentity,
})


export default connect(msp)(CincLicenses)
