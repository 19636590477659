import { createNodeUrl } from "../../requests/type-to-path.js";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import { isPhenomGuid } from "../util/util.js";

export const PhenomButtonLink = withRouter(({ node, history, newTab, id, className }) => {
  const classes = ["k-icon", "k-i-hyperlink-open"];
  classes.push(className);
  const url = createNodeUrl(node);

  if (!url || url === "#" || isPhenomGuid(node?.guid)) {
    return null;
  }

  return <Link to={url} className={classes.join(' ')} target={newTab ? "_blank" : null}/>
})

export const PhenomLink = ({node={}, newTab, idCtx = "", showParent, stagger = false, onClick}) => {
    const displayName = node.name || node.rolename || node.xmiType;
    const url = createNodeUrl(node);
    idCtx += "-phenom-link"

    // XmiType does not have a page
    if (url === "#") {
      return <span>{ displayName }</span>
    }

    if (typeof showParent !== 'boolean') {
      showParent = !node.name && !!node.parent?.guid && !!node.parent?.xmiType && !!node.parent?.name;
    }

    return <span>
        {showParent && <>
            <PhenomLink node={node.parent} newTab={newTab} idCtx={idCtx} />
            {stagger && <br />}
            .
        </>}
        <Link id={idCtx}
              className="cadet-anchor"
              to={{
                pathname: url,
                state: { 
                  scrollToGuid: node.guid,
                  guid: node.guid, 
                  xmiType: node.xmiType
                }}}
              target={newTab ? "_blank" : null}
              onClick={onClick}
              disabled={(!node.xmiType && !node["xmi:type"]) || (!node.guid && !node["xmi:id"]) || node.in_model === false}>
            {displayName}
        </Link>
    </span>
}
