import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { SkoogleBar } from '../../components/widget/SkoogleBar'
 
import { ReactComponent as Logo } from "../../images/svg/phenom-with-tagline-gradient.svg";
import { ReactComponent as USOnly } from "../../images/svg/us-gov-con-only.svg";
import { logout } from "../../requests/actionThunks"


export const Header = (props) => {
  const user = useSelector((state) => state.user);
  const userIdentity = user.userIdentity;
  const authenticated = user.authenticated;
  const distroD = user.distroD;
  const branchInfo = userIdentity?.username && userIdentity.branchName && `${userIdentity.username} | ${userIdentity.branchName}`;
  const activeProjectId = userIdentity?.activeProjectId;

  return <section id="phenom-header">
          <div className="header-container">
            <div className="header-logo">
              <Link to={authenticated ? "/dashboard" : "/"}>
                <Logo />
                {distroD && <USOnly /> }
              </Link>
            </div>

            {authenticated && branchInfo &&
            <div className="header-info">
              <h1>{branchInfo}</h1>
            </div>}

            {authenticated &&
            <div className="header-tools">
              <SkoogleBar disabled={!authenticated}
                          activeProjectId={activeProjectId}/>

              <div className="header-user">
                <MoreInfo />
                {authenticated &&
                <UserSettings authenticated={authenticated}
                              userIdentity={userIdentity} /> }
              </div>
            </div>}
          </div>
        </section>
}




const MoreInfo = (props) => {
  const hideDropdown = (e) => {
    // const container = document.querySelector("#more-info");
    const dropdown = document.querySelector('#more-info-dropdown');
    // if (!dropdown || container.contains(e.target)) return;
    dropdown.classList.remove("show");
    window.removeEventListener("click", hideDropdown);
  }

  const showDropdown = (e) => {
    e.preventDefault();
    const dropdown = document.querySelector('#more-info-dropdown');
    if (!dropdown) return;

    if (dropdown.classList.contains("show")) {
      dropdown.classList.remove("show");
      window.removeEventListener("click", hideDropdown);

    } else {
      dropdown.classList.add("show");
      setTimeout(() => {
        window.addEventListener("click", hideDropdown);
      }, 0);
    }
  }

  return <div id="more-info" className="dropdown">
      <button id="more-info-btn" className="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
              onClick={showDropdown}>
        <span className="far fa-question-circle" />
        <span className="fas fa-caret-down" />
      </button>

      <ul id="more-info-dropdown" className="dropdown-menu dropdown-menu-right" aria-labelledby="more-info-btn">
        <li>
          <a href="http://kb.phenomportal.com" target="_blank" rel="noopener noreferrer">Knowledgebase</a>
        </li>
        <li>
          <a href="https://www.skayl.com/tutorials" target="_blank" rel="noopener noreferrer">Tutorials</a>
        </li>
        <li>
          <a href="mailto:support@skayl.com">Contact</a>
        </li>
        <li>
          <Link to="/about" rel="noopener noreferrer">About</Link>
        </li>
      </ul>
  </div>
}



const UserSettings = (props) => {
  const hideDropdown = (e) => {
    // const container = document.querySelector("#more-settings");
    const dropdown = document.querySelector('#more-settings-dropdown');
    // if (!dropdown || container.contains(e.target)) return;
    dropdown.classList.remove("show");
    window.removeEventListener("click", hideDropdown);
  }

  const showDropdown = (e) => {
    e.preventDefault();
    const dropdown = document.querySelector('#more-settings-dropdown');
    if (!dropdown) return;

    if (dropdown.classList.contains("show")) {
      dropdown.classList.remove("show");
      window.removeEventListener("click", hideDropdown);

    } else {
      dropdown.classList.add("show");
      setTimeout(() => {
        window.addEventListener("click", hideDropdown);
      }, 0);
    }
  }

  let initials = "?";
  if (props.userIdentity?.username) {
    const names = props.userIdentity.username.split(" ");
    initials = names[0].charAt(0).toUpperCase();

    if (names.length > 1) {
      initials +=  names[names.length - 1]?.charAt(0).toUpperCase();
    }
  }

  return <div id="more-settings" className="dropdown">
      <button id="more-settings-btn" className="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
              onClick={showDropdown}>
        <span className="perfect-circle more-settings-initials">{initials}</span>
        <span className="fas fa-caret-down" />
      </button>

      <ul id="more-settings-dropdown" className="dropdown-menu dropdown-menu-right" aria-labelledby="more-settings-btn">
        <li>
          <span>{props.userIdentity.username}</span>
        </li>
        <li>
          <Link to="/settings/account">Settings</Link>
        </li>
        <li>
          <button onClick={logout}>Logout</button>
        </li>
      </ul>
  </div>
}


export default Header;