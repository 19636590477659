import React from "react";
import {InlinePathEditor, DeprecatedIcon, CadetLink} from "../../util/stateless";
import $ from "jquery";
import {NavLink} from "react-router-dom";
import commitIcon from "../../../images/edit_icons/commit.png"
import resetIcon from "../../../images/edit_icons/reset.png"
import editIcon from "../../../images/edit.png";
import deprecated from "../../../images/deprecated_hover.png"
import PhenomId from "../../../requests/phenom-id"
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { filterDataList } from "../../util/util"
import { _ajax } from "../../../requests/sml-requests";


class AEPathBuilder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            hopOptions: [],
            loadingHops: false,
            filter: "",
        };
    this.phenomId = new PhenomId("path-builder", this.props.idCtx)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.pathPairs.length !== this.props.pathPairs.length || (!prevState.editing && this.state.editing)) {
            this.fetchHopOptions();
        }
    }

    renderWithConnector(ae) {
        return ae.connector + ae.rolename + (ae.connector === "." ? "" : "[" + ae.parent.name + "]");
    }

    filterHops = filter => {
        this.setState({filter: filter.filter.value});
    };

    renderHopPicker(pathPairsArr) {
        if (this.state.editing && (pathPairsArr.length === 0 || pathPairsArr[pathPairsArr.length - 1].type.xmiType !== "conceptual:Observable")) {
            return <InlinePathEditor
                idCtx={this.phenomId.genPageId()}
                placeholder={this.state.loadingHops ? "loading..." : ""}
                data={this.state.hopOptions.filter(hopOption => hopOption.pathedName.toLowerCase().includes(this.state.filter.toLowerCase()))}
                textField={"pathedName"}
                dataItemKey={"guid"}
                onFilterChange={this.filterHops}
                selectHop={this.selectHop}
                style={pathPairsArr.length === 0 ? {width: "calc(100% - 30px)"} : {}}
            />;
        } else {
            return null;
        }
    }

    selectHop = e => {
        this.setState({filter: ""});
        const newValue = e.target.value;
        if (newValue) {
            this.props.pushPathHop(newValue);
        }
    };

    resetPath = () => {
        this.setState({editing: false}, this.props.resetPath);
    };

    fetchHopOptions() {
        this.setState({hopOptions: [], loadingHops: true});
        const lastHop = this.props.pathPairs[this.props.pathPairs.length - 1];

        if (lastHop && lastHop.type.xmiType === "conceptual:Observable") {
            this.setState({loadingHops: false});
            return;
        }

        const assocGuid = this.props.parentType.guid;
        const pathGuids = this.props.pathPairs.map(pathPair => pathPair.guid);
        _ajax({
            url: "/index.php?r=/compassoc/get-assoc-ent-path-options/",
            method: "get",
            data: {assocGuid: assocGuid, pathGuids: pathGuids, pathedAE: this.props.parentAEGuid},
        }).then(response => {
            response.data.hopOptions.forEach(hopOption => {
                if (hopOption.connector === ".") {
                    hopOption.pathedName = "." + hopOption.rolename;
                } else if (hopOption.connector === "->") {
                    hopOption.pathedName = "->" + hopOption.rolename + "[" + hopOption.parent.name + "]";
                } else {
                    throw "One of the hop options connectors is corrupted.";
                }
            });
            this.setState({hopOptions: response.data.hopOptions, loadingHops: false});
        });
    }

    render() {
        const pathPairsArr = this.props.pathPairs;
        const validPath = pathPairsArr.length === 0 || pathPairsArr[pathPairsArr.length - 1].type.xmiType === "conceptual:Observable";
        const phenomId = new PhenomId("path-builder", this.props.idCtx)
        return (
            <>
                {!this.props.canEditPath || !this.props.canEdit || <button
                    className="edit-assoc-path"
                    id={this.state.editing ? (validPath ? this.phenomId.genPageId("commit-btn") : this.phenomId.genPageId("reset-btn")) : this.phenomId.genPageId("edit-btn")}
                    style={{
                        backgroundImage: this.state.editing ? (validPath ? `url(${commitIcon})` : `url(${resetIcon})`) : `url(${editIcon})`,
                    }}
                    onClick={() => {
                        // Pencil Icon
                        if(!this.state.editing) return this.setState({editing: true});

                        // Commit Icon
                        if(validPath) {
                            this.setState({editing: false});

                        // Reset Icon
                        } else {
                            this.resetPath();
                        }
                    }}>
                </button>}
                {pathPairsArr.map((ae, idx) => {
                    const maxIdx = pathPairsArr.length - 1;
                    if (!this.state.editing) {
                        return <div>{ae.deprecated === "true" ? <img src={deprecated} style={{"height" : "20px"}}/> : null}<NavLink
                            key={idx}
                            title={this.renderWithConnector(ae)}
                            style={{whiteSpace: "nowrap", maxWidth: "calc(100% - 30px)", overflowX: "hidden"}}
                            className="cadet-anchor"
                            to={`/edit/details/entity/${ae.parent.guid}/`}
                            id={this.phenomId.genPageId(`hop-${idx}`)}>
                            {this.renderWithConnector(ae)}
                        </NavLink></div>;
                    } else {
                        return <span
                            key={idx}
                            id={this.phenomId.genPageId(`hop-${idx}`)}
                            className="phenom-span-text flex-h"
                            style={{fontSize: "100%", height: 18, width: "100%"}}
                            title={this.renderWithConnector(ae)}>
                                <div
                                    style={{whiteSpace: "nowrap", maxWidth: "calc(100% - 40px)", overflowX: "hidden"}}>
                                    {this.renderWithConnector(ae)}
                                </div>
                            {idx !== maxIdx || <button
                                id={this.phenomId.genPageId(`hop-${idx}`,"remove-btn")}
                                className="delete-assoc-path-hop"
                                onClick={this.props.popPathHop}>
                                ×
                            </button>}
                               </span>;
                    }
                })}
                {this.renderHopPicker(pathPairsArr)}
            </>
        );
    }
}

export default AEPathBuilder;








/*
    PropTypes: {
        pathPairs: Object,
        projectedEntity: Object,
        mainCompGuid: String,
        editable: Boolean
    }
 */
export class InlinePathBuilderForward extends React.Component {
    phenomId = new PhenomId("path-builder-forward",this.props.idCtx);
    poppedPathHops = {}
    state = {
        editing: false,
        hopOptions: [],
        loadingHops: false,
        filter: "",
        pathPairs: [],
        projectedEntity: {},
        entities: [],
    }

    componentDidMount() {
        const projectedEntity = this.props.projectedEntity;
        const pathPairs = projectedEntity ? this.connectifyPathPairs(this.props.pathPairs.slice(), projectedEntity.guid) : [];

        this.setState({
            projectedEntity,
            pathPairs,
            mainCompGuid: this.props.mainCompGuid,
            editing: !!this.props.parentIsEditing,
            entities: (this.props.entities || []).sort((node1, node2) => filterDataList(node1, node2)),
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.pathPairs !== this.props.pathPairs) {
            const projectedEntity = this.props.projectedEntity;
            const pathPairs = projectedEntity ? this.connectifyPathPairs(this.props.pathPairs.slice(), projectedEntity.guid) : [];

            this.setState({
                projectedEntity,
                pathPairs,
                mainCompGuid: this.props.mainCompGuid,
            }, () => this.fetchHopOptions);
        }

        if (prevState.pathPairs.length !== this.state.pathPairs.length ||
            prevState.projectedEntity !== this.state.projectedEntity ||
            prevState.editing !== this.state.editing) {
              this.fetchHopOptions();
        }

        if (prevProps.parentIsEditing !== this.props.parentIsEditing) {
            this.setState({
                editing: this.props.parentIsEditing
            })
        }

        if (prevProps.entities !== this.props.entities) {
          this.setState({
              entities: (this.props.entities || []).sort((node1, node2) => filterDataList(node1, node2))
          })
      }
    }

    getProjectedEntityGuid = () => {
      return this.state.projectedEntity?.guid || "";
    }

    connectifyPathPairs(pathPairs, parent_type_guid) {
        pathPairs.forEach((path_pair, idx) => {
            const prev_pair = idx === 0 ? null : pathPairs[idx - 1];
            const local_parent_type_guid = idx === 0 ? parent_type_guid : prev_pair.connector === "." ? prev_pair.type.guid : prev_pair.parent.guid;

            if (path_pair.parent.guid === local_parent_type_guid) {
                path_pair.connector = ".";
            } else {
                path_pair.connector = "->";
            }
        });
        return pathPairs;
    }

    filterHops = filter => {
        this.setState({filter: filter.filter.value});
    };


    fetchHopOptions() {
        const { parent, setEditableBounds, guid, attributeKind, viewType } = this.props;

        this.setState({
            hopOptions: [],
            loadingHops: true
        });

        if (!this.state.projectedEntity) {
          return this.setState({ hopOptions: [], loadingHops: false });
        }

        const lastHop = this.state.pathPairs[this.state.pathPairs.length - 1];

        if (lastHop && lastHop.type.xmiType === "conceptual:Observable") {
            this.setState({loadingHops: false});
            return;
        }

        const projGuid = this.state.projectedEntity.guid;
        const pathGuids = this.state.pathPairs.map(pathPair => pathPair.guid);

        const data = {
            assocGuid: this.state.projectedEntity.guid,
            pathGuids: this.state.pathPairs.map(pathPair => pathPair.guid),
        }

        if (guid && parent && attributeKind && viewType) {
            data.charProj = {
                path: pathGuids.join(" "),
                projectedCharacteristic: projGuid || null,
                ["xmi:id"]: guid, 
                parent: parent,
                attributeKind: attributeKind,
                viewType: !!viewType
            }
        }

        _ajax({
            url: "/index.php?r=/compassoc/get-assoc-ent-path-options/",
            method: "get",
            data,
        }).then(response => {

            // only for NestedChars: filter out Observable Hops
            if(this.props.buildViewPath) {
                response.data.hopOptions = response.data.hopOptions.filter(hopOption => {
                    return hopOption.type.xmiType !== "conceptual:Observable";
                })
            }

            response.data.hopOptions.forEach(hopOption => {
                if (hopOption.connector === ".") {
                    hopOption.pathedName = "." + hopOption.rolename;
                } else if (hopOption.connector === "->") {
                    hopOption.pathedName = "->" + hopOption.rolename + "[" + hopOption.parent.name + "]";
                } else {
                    throw "One of the hop options connectors is corrupted.";
                }
            });

            if (setEditableBounds) {
                setEditableBounds(!response.data.invalidBounds);
            }

            this.setState({
                hopOptions: response.data.hopOptions,
                loadingHops: false
            });
        });
    }


    selectHop = (e) => {
        this.setState({filter: ""});
        const newValue = e.target.value;
        if (newValue) {
            this.pushPathHop(newValue);
        }
    };

    popPathHop = () => {
        const lastHop = this.state.pathPairs[this.state.pathPairs.length - 1];

        if(lastHop) {
            const pathPairs = this.state.pathPairs.slice(0, -1);
            this.poppedPathHops[lastHop.guid] = lastHop;

            this.setState({ pathPairs });
            this.props.onPathSet && this.props.onPathSet(this.state.projectedEntity, pathPairs);
            // this.forceUpdate();
        }
    };

    pushPathHop = hop => {
        const pathPairs = [...this.state.pathPairs, hop];
        this.setState({pathPairs}, () => {
        this.props.onPathSet && this.props.onPathSet(this.state.projectedEntity, pathPairs);
        });
    };

    changeProjectedCharacteristic = (e) => {
        const projectedEntity = e.value;

        this.setState({ projectedEntity, pathPairs: [], hopOptions: [], }, () => {
            this.props.onPathSet && this.props.onPathSet(projectedEntity, []);
        })
    }

    resetPath = () => {
        const projectedEntity = this.state.projectedEntity;
        const pathPairs = this.connectifyPathPairs(this.props.pathPairs.slice(), projectedEntity.guid);

        this.setState({pathPairs});
        this.props.onPathSet && this.props.onPathSet(this.state.projectedEntity, pathPairs);
    };

    filterProjectedEntities = (e) => {
      const { value } = e.filter;
      this.setState({
          entities: Object.values(this.props.entities).sort((node1, node2) => filterDataList(node1, node2, value))
      })
    }


    renderHopPicker(pathPairsArr) {
        // Only for NestedChars.  Need to be an incomplete path (cannot end on Observable)
        if(this.state.editing && this.props.buildViewPath && pathPairsArr.length && !this.state.hopOptions.length) {
            return null;
        }

        if (this.state.editing && (pathPairsArr.length === 0 || pathPairsArr[pathPairsArr.length - 1].type.xmiType !== "conceptual:Observable")) {
            return <InlinePathEditor
                idCtx={this.phenomId.gen(this.props.index)}
                placeholder={this.state.loadingHops ? "loading..." : "Select Hop"}
                data={this.state.hopOptions.filter(hopOption => hopOption.pathedName.toLowerCase().includes(this.state.filter.toLowerCase()))}
                textField={"pathedName"}
                dataItemKey={"guid"}
                onFilterChange={this.filterHops}
                selectHop={this.selectHop}
                style={{width: "calc(100% - 30px)"}}
            />;
        } else {
            return null;
        }
    }

    renderWithConnector(ae) {
        return ae.connector + ae.rolename + (ae.connector === "." ? "" : "[" + ae.parent.name + "]");
    }

    render() {
        const pathPairsArr = this.state.pathPairs;

        const displayDirection = this.state.editing ? "flex-v" : this.props.flexHorizontal ? "flex-h" : "flex-v";
        const classes = [displayDirection]

        const lastHop = pathPairsArr[pathPairsArr.length - 1];
        const validPath = !lastHop || this.props.buildViewPath || lastHop.type.xmiType === "conceptual:Observable";


        return (
            <div className="flex-h"
                 style={{justifyContent:"space-between", alignItems:"center"}}>

                <div className={classes.join(" ")}
                     style={{flex:1,
                            flexWrap: this.props.textWrap ? "wrap" : "nowrap",
                            overflow:"hidden",
                            width: 0,  // needed to fix a overflow problem
                            textOverflow:"ellipsis"}}>

                    {/* Allow use to select Project Characteristic or show as link */}
                    {this.state.editing && !!this.state.entities?.length
                      ? <ComboBox className="phenom-combo-box"
                                  opened={this.state.expanded}
                                  data={this.state.entities}
                                  value={this.state.projectedEntity}
                                  dataItemKey="guid"
                                  textField="name"
                                  allowCustom={true}
                                  clearButton={true}
                                  filterable={true}
                                  id={this.phenomId.genPageId(this.props.index ,"edit-char-input")}
                                  placeholder="Select Projected Characteristic"
                                  onChange={this.changeProjectedCharacteristic}
                                  onFilterChange={this.filterProjectedEntities}
                                  onFocus={() => this.setState({ expanded: true })}
                                  onClose={() => this.setState({ expanded: false })}
                                  onBlur={() => this.setState({ expanded: false })} />
                      : !!this.state.projectedEntity ?
                        <CadetLink node={this.state.projectedEntity}
                                    id={this.phenomId.gen("init","projected-entity")}
                                    look="normal"
                                    newPage={true} />
                      : null
                    }

                    {pathPairsArr.map((pair, idx) => {
                        const maxIdx = pathPairsArr.length - 1;
                        const text = this.renderWithConnector(pair)

                        if(this.state.editing) {
                            return <span key={idx}
                                        id={this.phenomId.gen(["init","path-pair"],"editing-text")}
                                        className="flex-h"
                                        style={{whiteSpace: "nowrap", fontSize:"100%", }}
                                        title={text}>
                                            <span style={{ overflow: "hidden" }}>{text}</span>

                                    {idx !== maxIdx || <button
                                        id={this.phenomId.gen("path-pair","delete-assoc-path-hop")}
                                        className="delete-assoc-path-hop"
                                        onClick={this.popPathHop}>
                                        ×
                                    </button>}
                            </span>
                        } else {
                            return <span style={{textOverflow:"ellipsis", display:"inline"}}>
                                    <DeprecatedIcon deprecated={pair.deprecated} id={this.phenomId.gen("path-pair","deprecated")}/>
                                    <NavLink key={idx}
                                                id={this.phenomId.gen("path-pair","non-editing-text")}
                                                title={text}
                                                target="_blank"
                                                style={{whiteSpace: "nowrap", display:"inline"}}
                                                className="cadet-anchor normal-anchor"
                                                to={`/edit/details/entity/${pair.parent.guid}/`}>
                                        {text}
                                    </NavLink></span>;
                        }
                    })}
                        {this.renderHopPicker(pathPairsArr)}
                </div>
                {this.props.editable && (!this.props.hidePencilIcon || this.state.editing) &&
                    <button style={{ width:20,
                                        height:20,
                                        border: "none",
                                        backgroundSize: "contain",
                                        backgroundColor:"transparent",
                                        backgroundImage: this.state.editing ? (validPath ? `url(${commitIcon})` : `url(${resetIcon})`) : `url(${editIcon})`,
                                    }}
                            id={this.phenomId.genPageId(this.props.index ,"edit-perspective-btn")}
                            onClick={() => {
                                // Pencil Icon
                                if(!this.state.editing) return this.setState({editing: true});

                                // Commit Icon
                                if(validPath) {
                                    this.setState({editing: false});

                                // Reset Icon
                                } else {
                                    this.resetPath();
                                }
                            }} />
                }
            </div>
        );
    }
}
