import { cloneDeep } from "lodash";
import { isPhenomGuid } from "../../../util/util";

// old code that needs to be removed
// I needed to change the Addedum, and now this attr is triggered isEdited
// diagramNodeLoaded -> is in Yii
const oldCode = ["diagramNodeLoaded"];

function flattenNode(node={}) {
  const res = {};

  for (let key in node) {

    // old code that needs to be removed
    // I needed to change the Addedum, and now this attr is triggered isEdited
    if (oldCode.includes(key)) {
      continue;
    }

    const attr = node[key];
    res[key] = attr;

    // convert nested nodes into guids
    if (Array.isArray(attr)) {
      res[key] = attr.map(el => el?.guid ? el.guid : el)

    } else if (attr?.guid) {
      res[key] = attr?.guid;
    }
  }

  return res;
}

function StormData(data={}) {
  this.original = data ? flattenNode(data) : { guid: "", xmiType: "", children: [] }
  this.data = cloneDeep(this.original)
  this.config = {
    edited: false,
  }


  //--------------------
  // GETTERS
  //--------------------
  this.getData = () => {
    return this.data;
  }

  this.getOriginalData = () => {
    return this.original;
  }

  this.getAttr = (attr="") => {
    return this.data[attr];
  }

  this.getAttributeKeys = () => {
    return Object.keys(this.data);
  }

  this.getGuid = () => {
    return this.getAttr("guid");
  }

  this.getParentGuid = () => {
    return this.getAttr("parent");
  }

  this.getName = () => {
    return this.getAttr("name") || this.getAttr("rolename");
  }

  this.getXmiType = () => {
    return this.getAttr("xmiType");
  }

  this.getDescription = () => {
    return this.getAttr("description");
  }

  this.getChildren = () => {
    return this.data.children || [];
  }

  this.isEdited = () => {
    return this.config.edited;
  }

  /**
   * Serialize the node for save
   * 
   * @returns serialized node
   */
  this.serializeData = () => {
    const data = {};

    for (let key in this.getData()) {
      const attr = this.getAttr(key);
      if (attr === undefined || attr === null) {
        continue;
      }

      data[key] = attr;
    }

    return data;
  }


  //--------------------
  // SETTERS
  //--------------------
  this.setAttr = (key, value) => {
    this.data[key] = value;

    // convert nested nodes into guids
    if (Array.isArray(value)) {
      this.data[key] = value.map(el => el?.guid ? el.guid : el)

    } else if (value?.guid) {
      this.data[key] = value?.guid;
    }
  }

  this.setXmiType = (xmiType) => {
    this.setAttr("xmiType", xmiType);
  }

  this.setEditedStatus = (bool) => {
    this.config.edited = bool;
  }

  /**
   * Loops through the node's key-value pairs and assign each attribute. This will override pre-existing attributes
   * 
   * @param {node} data 
   * @returns void
   */
  this.updateStormData = (data) => {
    if (!data?.guid) return;

    // convert phenom guid to real guid
    const newGuid = data.guid;
    if (typeof newGuid === 'string' && !isPhenomGuid(newGuid) && isPhenomGuid(this.getGuid())) {
      this.setAttr("guid", newGuid);
    }

    const mostData = (Object.keys(this.original).length > Object.keys(data).length) ? this.original : data;

    for (let key in mostData) {
      if (key === "guid") continue;

      // old code that needs to be removed
      // I needed to change the Addedum, and now this attr is triggered isEdited
      if (oldCode.includes(key)) {
        continue;
      }

      const attr = data[key];
      this.setAttr(key, attr);
    }
  }


  /**
   * The node from the model can be out of sync with the node from json.
   * This loops through the json data and readds the phenom attributes/guids. 
   * 
   * @param {node} jsonData 
   * @returns void
   */
  this.readdPhenomAttrs = (jsonData) => {
    if (!jsonData?.guid) return;

    for (let key in jsonData) {
      if (key === "guid") continue;
      const currAttr = this.getAttr(key);
      const newAttr = jsonData[key];

      // search through new attr and add phenom guids to current attr
      if (Array.isArray(currAttr) && Array.isArray(newAttr)) {
        let attrGuids = new Set(currAttr);

        if (newAttr.every(ele => typeof ele === 'string')) {
          newAttr.forEach(guid => isPhenomGuid(guid) && attrGuids.add(guid));
        
        } else if (newAttr.every(ele => !!ele?.guid)) {
          newAttr.map(ele => ele.guid).forEach(guid => isPhenomGuid(guid) && attrGuids.add(guid));
        }

        if (attrGuids.size) {
          this.setAttr(key, [...attrGuids]);
        }
      }
    }
  }

  this.setOriginalAttr = (key, value) => {
    this.original[key] = value;

    // convert nested nodes into guids
    if (Array.isArray(value)) {
      this.original[key] = value.map(el => el?.guid ? el.guid : el)

    } else if (value?.guid) {
      this.original[key] = value?.guid;
    }
  }

  this.updateOriginalStormData = (data) => {
    if (!data?.guid) return;

    const mostData = (Object.keys(this.original).length > Object.keys(data).length) ? this.original : data;

    for (let key in mostData) {
      if (key === "guid") continue;

      // old code that needs to be removed.
      // I needed to change the Addedum, and now this attr is triggered isEdited
      if (oldCode.includes(key)) {
        continue;
      }

      const attr = data[key];
      this.setOriginalAttr(key, attr);
    }
  }

  this.addChild = (childGuid) => {
    const newChildren = [...this.getChildren()];
    newChildren.push(childGuid);
    this.setAttr("children", newChildren);
  }

  this.removeChild = (childGuid) => {
    const newChildren = [...this.getChildren()];
    const removeIdx = newChildren.findIndex(ele => ele === childGuid);
    removeIdx > -1 && newChildren.splice(removeIdx, 1);
    this.setAttr("children", newChildren);
  }

  this.checkEditedStatus = () => {
    if (isPhenomGuid(this.getGuid())) {
      this.setEditedStatus(true);
      return true;
    }

    const edited = Object.keys(this.getData()).some(key => {
      const currentAttr = this.getAttr(key);
      const originalAttr = this.original[key];

      if (Array.isArray(currentAttr) && Array.isArray(originalAttr)) {
        const diff1 = currentAttr.filter(ele => !originalAttr.includes(ele));
        const diff2 = originalAttr.filter(ele => !currentAttr.includes(ele));
        return Math.abs(diff1.length - diff2.length) > 0;
      }

      return this.data[key] !== this.original[key];
    });

    this.setEditedStatus(edited);
    return edited;
  }
}




export default StormData;
