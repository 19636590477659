import React from 'react'
import $ from 'jquery'
import NodeLeaf from '../../../tree/leaf/NodeLeaf'
import SmallNavTree from '../../../tree/SmallNavTree';
import DependencyMapper from './DependencyMapper';
import { Button } from '@progress/kendo-react-buttons';
import { PhenomInput, PhenomLabel, PhenomTextArea, PhenomToggle } from '../../../util/stateless';
import { BasicAlert } from '../../../dialog/BasicAlert';
import { _ajax } from '../../../../requests/sml-requests';

class ProjectFilter extends React.Component {

  state = {
    filteredId: null,
    name: "",
    description: "",
    subModelIds: [],
    srcProjectId: "",
    allTags: [],
    
    filterAll: true,
  }
  
  relationsMap = {};
  leftTreeRef = React.createRef();
  rightTreeRef = React.createRef();

  leftIndex = {
    "root": new NodeLeaf({ guid: "root", name: "<Root>", xmiType: "#" })
  };

  rightIndex = {
    "root": new NodeLeaf({ guid: "root", name: "<Root>", xmiType: "#" })
  };



  componentDidMount() {
    this.initData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.createFilteredProject !== this.props.createFilteredProject) {
      this.initData();
    }
  }

  initData = () => {
    const { projectLeaf, createFilteredProject } = this.props;
    BasicAlert.show("One moment please. Retrieving the needed data.", "Loading...", false);

    if (createFilteredProject === "new") {
      this.setState({
        filteredId: null,
        name: projectLeaf.getName() + "_filtered",
        description: projectLeaf.getData().description,
        subModelIds: projectLeaf.getModelLeaves().map(leaf => leaf.getId()),
        srcProjectId: projectLeaf.getId(),
      }, 
      async () => {
        this.fetchAvailableTags();
        await this.fetchRelationsMap();

        // create both trees
        this.fetchTreeData().then(response => {
          console.log(response)
          if (!response.data?.baseNodes) return;
          this.clearRightTree();
          this.insertNodeIndex(this.leftIndex, JSON.parse(response.data.baseNodes));
          this.insertNodeIndex(this.rightIndex, JSON.parse(response.data.baseNodes));
          BasicAlert.hide();
          this.forceUpdate();
        })
      });
    }

    if (createFilteredProject === "edit") {
      // editing an existing filtered project - need source project
      this.fetchSourceProjectId().then(response => {
        const srcParentId = response.data.toString();
  
        this.setState({
          filteredId: projectLeaf.getId(),
          name: projectLeaf.getName(),
          description: projectLeaf.getData().description,
          subModelIds: projectLeaf.getModelLeaves().map(leaf => leaf.getId()),
          srcProjectId: srcParentId,
        }, 
        () => {
          this.fetchAvailableTags();
          
          Promise.all([ this.fetchRelationsMap(), this.fetchTreeData() ])
                 .then((res) => {
                    // create left tree
                    const respTree = res[1];
                    if (!respTree.data?.baseNodes) return;
                    this.clearRightTree();
                    this.insertNodeIndex(this.leftIndex, respTree.data.baseNodes);
  
                    // create right tree
                    this.fetchFilteredGuids();
                    BasicAlert.hide();
                    this.forceUpdate();
                 });
        });
      })
    }
  }


  serialize = () => {
    return {
      modelId: this.state.srcProjectId,
      name: this.state.name,
      description: this.state.description,
      subModels: this.state.subModelIds,
      guids: Object.keys(this.rightIndex),
      deletableId: this.state.filteredId,
    }
  }

  // ===============================================
  // TREES
  // ===============================================
  insertNodeIndex = (nodeIndex, nodeMap) => {      
    for (let guid in nodeMap) {
      if (guid === "root") continue;
  
      const node = nodeMap[guid];
      // initially the node does not contain the guid attribute
      node.guid = guid;
  
      if (nodeIndex[guid]) {
        nodeIndex[guid].updateData(node);
      } else {
        const leaf = new NodeLeaf(node);
        nodeIndex[guid] = leaf;
      }
    }
  
    for (let guid in nodeIndex) {
      if (guid === "root") continue;

      const childLeaf = nodeIndex[guid];
      const parentLeaf = nodeIndex[childLeaf.getParentGuid()];
      parentLeaf && childLeaf.setParentLeaf(parentLeaf);
      parentLeaf && parentLeaf.setChildLeaf(childLeaf);
    }
  }


  // add downs
  addToRightTree = (leafNodes=[]) => {
    const nodeMap = {};

    for (let leaf of leafNodes) {
      const relation = this.relationsMap.findLeaf([leaf.getGuid()]);
      if (!relation) continue;

      // reset checked status
      leaf.setChecked(false);

      // add the selected node
      nodeMap[leaf.getGuid()] = leaf.getData();

      // add the selected node's dependencies
      relation.getDependencies(this.leftIndex).forEach(guid => {
        nodeMap[guid] = this.leftIndex[guid].getData()
      })
    }

    this.insertNodeIndex(this.rightIndex, nodeMap);
    this.forceUpdate();
  }

  // remove ups
  removeFromRightTree = (leafNodes=[]) => {
    const nodeSet = new Set();

    for (let leaf of leafNodes) {
      // add the selected node
      nodeSet.add(leaf);

      // reset checked status
      leaf.setChecked(false);

      // add the selected node's dependencies
      const relation = this.relationsMap.findLeaf([leaf.getGuid()]);
      relation && relation.getDependents().forEach(guid => {
        this.rightIndex[guid] && nodeSet.add(this.rightIndex[guid]);
      })
    }

    // remove nodes from right tree
    nodeSet.forEach(leafNode => {
      leafNode.remove();                            // removes the parent-child reference pointers
      delete this.rightIndex[leafNode.getGuid()]    // removes the node from index
    })

    this.forceUpdate();
  } 


  
  // ===============================================
  // FETCH
  // ===============================================
  fetchSourceProjectId = () => {
    const { projectLeaf } = this.props;

    return _ajax({
      url:"/index.php?r=/referencing-model/model-filtered-parent/",
      method: "post",
      data: {
        modelId: projectLeaf.getId(),
      }
    })
  }

  fetchRelationsMap = async () => {
    //return new Promise(async (resolve, _) => {
      this.relationsMap = await DependencyMapper.createMapper(this.state.srcProjectId);
      //resolve();
    //});
  }

  fetchTreeData = () => {
    const { srcProjectId } = this.state;

    return _ajax({
      url: "/index.php?r=/tree/load-target-model/",
      data: { 
        modelId: srcProjectId,
      }
    })
  }

  fetchAvailableTags = () => {
    const { srcProjectId } = this.state;

    return _ajax({
      url: "/index.php?r=/detail/available-model-tags/",
      data: { 
        projectId: srcProjectId,
      }
    }).then(response => {
      Array.isArray(response.data.tags) && this.setState({ allTags: response.data.tags })
    })
  }

  fetchFilteredGuids = () => {
    const { filteredId } = this.state;

    _ajax({
      url:"/index.php?r=/referencing-model/model-target-guids/",
      method: "post",
      data: {
        modelId: filteredId,
      }
    }).then(res => {
      const guids = res.data.guids;

      const nodeMap = {};
      for (let guid of guids) {
        if (guid === "root") continue;
        const node = this.leftIndex[guid].getData();
        nodeMap[guid] = node;
      }

      this.insertNodeIndex(this.rightIndex, nodeMap);
      this.forceUpdate();
    })
  }


  // ===============================================
  // RIGHT SIDE
  // ===============================================
  resetRightTree = () => {
    this.clearRightTree();
    const nodeMap = {};

    for (let guid in this.leftIndex) {
      if (guid === "root") continue;
      const leaf = this.leftIndex[guid];
      nodeMap[guid] = leaf.getData();
    }

    this.insertNodeIndex(this.rightIndex, nodeMap);
    this.forceUpdate();
  }

  clearRightTree = () => {
    this.rightIndex = {
      "root": new NodeLeaf({ guid: "root", name: "<Root>", xmiType: "#" })
    };
    this.forceUpdate();
  }


  // ===============================================
  // EVENT HANDLERS
  // ===============================================
  handleAddNodes = (addLeafNodes=[]) => {
    const leftLeaves = this.leftTreeRef.current.getCheckedLeaves();

    BasicAlert.show("Determining dependencies...", "Processing...", false);
    setTimeout(() => {
      leftLeaves.length && this.addToRightTree(leftLeaves);
      BasicAlert.hide();
    }, 10);
  }

  handleRemoveNodes = () => {
    const rightLeaves = this.rightTreeRef.current.getCheckedLeaves();

    BasicAlert.show("Determining dependencies...", "Processing...", false);
    setTimeout(() => {
      rightLeaves.length && this.removeFromRightTree(rightLeaves);
      BasicAlert.hide();
    }, 10);
  }

  toggleFilterAll = () => {
    const filterAll = !this.state.filterAll;

    if (filterAll) {
      this.resetRightTree();
    } else {
      this.clearRightTree();
    }

    this.setState({ filterAll });
  }

  render() {
    const isNewProject = this.props.createFilteredProject === "new";
    const newText = isNewProject ? "New " : "";


    return <div className='project-detail'>
              <div className='p-row'>
                <div className='p-col p-col-9'>
                  <PhenomInput label={newText + "Project Name"}
                              value={this.state.name}
                              onChange={(e) => this.setState({ name: e.target.value })} />
                </div>

                <div className='p-col p-col-3'>
                  <div>
                    <PhenomLabel text={newText + "Project Starting State"}>
                      {!isNewProject &&
                      <i className="fas fa-exclamation-triangle" style={{ color: "orange", fontSize: 16 }} /> }
                    </PhenomLabel>
                      <PhenomToggle checked={this.state.filterAll}
                                    data={["Empty", "All"]}
                                    onChange={this.toggleFilterAll} />
       
                  </div>
                </div>

              </div>

            {isNewProject &&
            <PhenomTextArea label="Description"
                            value={this.state.description}
                            onChange={(e) => this.setState({ description: e.target.value })} /> }

            <div className='p-row' style={{ flex: 1, gap: "2em", alignItems: "center", overflow: "hidden" }}>
                <div className="navtree" style={{ height: "100%" }}>
                  <h3 style={{ margin: "0 0 5px" }}>Source Project</h3>
                  <SmallNavTree id="source-tree"
                                mode="manage"
                                nodeIndex={this.leftIndex}
                                useCheckbox={true}
                                onCheckIncludeChildren={true}
                                allTags={this.state.allTags}
                                ref={this.leftTreeRef}
                                />
                </div>

                <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
                  <Button onClick={this.handleAddNodes}>
                    Add
                    <i className="fas fa-chevron-right" style={{ marginLeft: 5 }} />
                  </Button>
                  <Button onClick={this.handleRemoveNodes}>
                    <i className="fas fa-chevron-left" style={{ marginRight: 5 }} />
                    Remove
                  </Button>
                </div>

                <div className='navtree' style={{ height: "100%" }}>
                    <h3 style={{ margin: "0 0 5px" }}>Filtered Project</h3>
                    <SmallNavTree id="target-tree"
                                  mode="manage"
                                  nodeIndex={this.rightIndex}
                                  useCheckbox={true}
                                  onCheckIncludeChildren={true}
                                  allTags={this.state.allTags}
                                  ref={this.rightTreeRef} />
              </div>
            </div>

           </div>
  }
}


export default ProjectFilter;
