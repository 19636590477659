import React from "react";
import { NodeModel } from "@projectstorm/react-diagrams";
import { cloneDeep } from "lodash";
import ReactTooltip from "react-tooltip";
import { CompositionPortModel } from '../port/CompositionPort';
import { PathPortModel } from '../port/PathPort';
import { nodeProps } from '../design/nodeDesign';
import { RootNodeModel } from './RootNode';
import PhenomId from '../../../../requests/phenom-id';
import { isNestingChar } from "../../../util/util";

import {
    isDiagramNode,
    isDifferent,
    createPathPortGuid,
    getPositionOfTarget,
    getClosestAnchorPoints,
    getLatestPortPosition,
    sortNodesByType,
} from "../util";

export const ignorePorts = new Set(["create-asso","create-comp"]);
export const modellingPortPosition = [[0, 0], [0.5, 0], [1, 0], [1, 0.5], [1, 1], [0.5, 1], [0, 1], [0, 0.5]];
export const projectionPortPosition = [[0, 0], [0.1, 0], [0.2, 0], [0.3, 0], [0.4, 0], [0.5, 0], [0.6, 0], [0.7, 0], [0.8, 0], [0.9, 0], [1, 0]];

export class DiagramNodeModel extends RootNodeModel {
	constructor(options = {}, $app) {
        super(options);

        this.$app = $app;

        // when loading the diagram:
        // constructor runs first - nodeData is undefined
        // afterwards it runs deserialize on the JSON data - this is where nodeData will be populated
        if(!this.options.nodeData) this.options.nodeData = {};
        if(!this.options.nodeData.children) this.options.nodeData.children = [];
        this.options.nodeData.isUncommitted = !!this.options.nodeData.guid && isDiagramNode(this.options.nodeData.guid);
        this.originalData = cloneDeep(this.options.nodeData);

        this.outPortMap = {};
        this.inPortMap = {};
        this.settings = {
          hiddenAttributeGuids: new Set(),
          showHiddenAttributes: false,
          showCompositionType: true,
        };

        this.registerListener({
            eventDidFire: e => {
                if (e.isSelected === false) {
                    this.$widget.state.isEditing && this.$widget.toggleEditMode(false);
                    this.setLocked(false);
                }

                if(e.function === "positionChanged") {
                    const selectedTab = this.$app.tabData;
                    selectedTab.saved = false;
                    this.$app.props.manager.forceUpdate();
                    this.$app.resizeCanvas();
                }

                if(e.function === "entityRemoved") {
                    const nodeData = e.entity.getNodeData();
                    if(!nodeData && !nodeData.guid) return;

                    this.$app.props.manager.removeUncommittedNode(nodeData.guid);
                    this.$app.props.manager.removeActiveGuid(nodeData.guid);
                    this.$app.sidePanelRef.current.load(null);

                    [this.getOutPorts(), this.getInPorts()].forEach(hash => {
                        Object.keys(hash).forEach(portGuid => {
                            const charGuid = portGuid.split("--")[1];

                            if(charGuid) {
                                this.removePathLinks(portGuid);
                            } else {
                                this.removeLink(portGuid);
                            }
                        })
                    })
                }
            },
        })
    }

    /*
     * Saving and Loading Data
     */
	  serialize() {
      const settings = {...this.getSettings()};
            settings.hiddenAttributeGuids = [...settings.hiddenAttributeGuids];

      return {
        ...super.serialize(),
        nodeData: this.getNodeData(),
        settings,
        inPortMap: this.inPortMap,
        outPortMap: this.outPortMap,
      };
    }

    deserialize(event) {
      this.$app = event.engine.$app;
      let guid = event.data.nodeData.guid;
      let nodeData = this.$app.getOptionNode(guid);
      let settings = event.data.settings;
          settings.hiddenAttributeGuids = new Set(settings.hiddenAttributeGuids);

      this.options.nodeData = nodeData || event.data.nodeData;
      this.originalData = cloneDeep(nodeData || event.data.nodeData);
      this.settings = settings;
      this.width = event.data.width;
      this.height = event.data.height;
      this.inPortMap = event.data.inPortMap;
      this.outPortMap = event.data.outPortMap;
      this.rememberNodeSize();
      super.deserialize(event);
    }

    getNodeData = () => {
      return this.options.nodeData;
    }

    getOriginalData = () => {
      return this.originalData;
    }

    getSettings = () => {
      return this.settings;
    }

    getOutPorts = () => {
      return this.outPortMap;
    }

    getInPorts = () => {
      return this.inPortMap;
    }

    setNodeData = (data) => {
      this.options.nodeData = data;
    }

    setSettings = (key, value) => {
      this.settings[key] = value;
    }

    updateProp = (k, v) => {
      this.options.nodeData[k] = v;
    }

    getInPort = (guid) => {
      if(this.inPortMap[guid]) {
        const port = this.getPort(this.inPortMap[guid]);
        return port;
      }
    }

    getOutPort = (guid) => {
      if(this.outPortMap[guid]) {
        const port = this.getPort(this.outPortMap[guid]);
        return port;
      }
    }

    setInPort = (attrData, options = {}) => {
      try {
          if(!attrData) throw "invalid data, failed to create port";
          const portName = this.$app.props.manager.createPortId();
          this.inPortMap[attrData.guid] = portName;

          const portInfo = {
            name: portName,
            in: true,
            attrData,
            position: options.position || 0,
          }

          if(options.specializedBy) {
            portInfo.specializedBy = options.specializedBy;
          }

          const port = new CompositionPortModel(portInfo);
          this.addPort(port);
          port.reportPosition();
          return port;

      } catch(e) {
          console.error(e);
      }
    }

    setOutPort = (attrData, options = {}, arrowHead) => {
      const portName = this.$app.props.manager.createPortId();
      this.outPortMap[attrData.guid] = portName;

      const portInfo = {
        name: portName,
        in: false,
        attrData,
        arrowHead: options.arrowHead,
        arrowTail: options.arrowTail,
        position: options.position || options.position === 0 ? options.position : 2,
      }

      if(!portInfo.arrowHead &&
        attrData.xmiType === "conceptual:AssociatedEntity") {
          portInfo.arrowHead = "thinArrow";
      }

      if(!portInfo.arrowTail &&
        (attrData.xmiType === "conceptual:Composition" ||
          attrData.xmiType === "platform:CharacteristicProjection")) {
            portInfo.arrowTail = "diamondFilledArrow";
      }

      if(options.specializes) {
        portInfo.arrowHead = "thinEmptyArrow";
        portInfo.arrowTail = undefined;
        portInfo.specializes = options.specializes;
      }

      const port = new CompositionPortModel(portInfo);
      this.addPort(port);
      port.reportPosition();
      return port;
    };

    removeInPort = (portGuid) => {
      try {
          if(!portGuid) return;
          let port = this.getInPort(portGuid);

          if(port) this.removePort(port);
          delete this.inPortMap[portGuid];
      } catch (err) {
          console.error(err);
      }
    }

    removeOutPort = (portGuid) => {
      try {
          if(!portGuid) return;
          let port = this.getOutPort(portGuid);

          if(port) this.removePort(port);
          delete this.outPortMap[portGuid];
      } catch (err) {
          console.error(err);
      }
    }

    restoreNodeSize() {
      // After switching tabs, the width/height resets to zero.
      // the width/height is stored when the node is resized. the stored values are reapplied when switching tabs.
      //    weird bug on prod only -> width/height was still resetting to 0.  attempt to restore width/height if it is 0 and if a number was previously stored.
      if (!this.width && this.getSettings()._width) {
        this.width = this.getSettings()._width;
      }
  
      if (!this.height && this.getSettings()._height) {
        this.height = this.getSettings()._height;
      }
    }
  
    rememberNodeSize() {
      this.setSettings("_width", this.width);
      this.setSettings("_height", this.height);
    }



    /*
     * Used by EntityNode and ViewNode
     */
    rebuild = (onDiagramLoad = false) => {
        const newNodeData = this.$app.getOptionNode(this.getNodeData().guid);

        // Ensures the reference pointer is pointing to the node inside the data-bank (on diagram load)
        if(newNodeData) this.setNodeData(newNodeData);
        this.$app.props.manager.setActiveGuid(this.getNodeData().guid);
        this.originalData = cloneDeep(this.getNodeData());

        /*
         * HELPER
         */
        const findChildNode = (data, guidType, compareGuid) => {
            if(!data.parent) return;
            let parentGuid = typeof data.parent === "string" ? data.parent : data.parent.guid;
            let parentData = this.$app.getOptionNode(parentGuid);
            if (parentData?.children) return parentData.children.find(c => c[guidType] === compareGuid);
        }

        /*
         * UPDATE PORTS
         */
        [this.getOutPorts(), this.getInPorts()].forEach(hashList => {
          for (let [portGuid, portName] of Object.entries(hashList)) {
            const port = this.getPort(portName);

            // clean up empty port from portMap
            if(!port) {
              delete hashList[portGuid];
              continue;
            }

            // clean up empty links/ports (mainly used to remove PathLink on diagram load)
            if(onDiagramLoad && port.options.type === "path-port") {
              this.removeLink(portGuid);
              continue;
            }

            // NORMAL PORT NAME: "comp.guid"
            // PATH PORT NAME: "comp.guid -- viewChild.guid -- int"
            const portGuids = portGuid.split("--");

            let childGuid = portGuids[0];         // <-- comp.guid or char.guid or specialize guid (ent/asso guid)
            let viewChildGuid = portGuids[1];     // <-- char.guid
            let pathPos = portGuids[2];

            // ----------------------------------------------------
            // 1) check if child was deleted (on diagram load or reset node)
            // ----------------------------------------------------
            if(!isDiagramNode(childGuid)) {
              // 1a) check if SPECIALIZED relationship still exist (on diagram load)
              if(port.options.specializes) {
                const specialNode = this.$app.getOptionNode(port.options.specializes);
                if(!specialNode || !specialNode.specializedBy.some(sp => sp.guid === portGuids[0])) {
                    this.removeLink(portGuid);
                }
                continue;
              }

              // 1b) check if SPECIALIZED relationship still exist (on diagram load)
              if(port.options.specializedBy) {
                const specialNode = this.$app.getOptionNode(port.options.specializedBy);
                if(!specialNode || !specialNode.specializes || !specialNode.specializes.guid === portGuids[0]) {
                    this.removeLink(portGuid);
                }
                continue;
              }

              // 1c) check if REAL child was deleted (on diagram load)
              // -> remove port/link, otherwise move on
              if(port.options.in) {
                var attr = findChildNode(port.getAttrData(), "guid", childGuid);
              } else {
                var attr = this.getNodeData().children.find(child => child.guid === childGuid);
              }

              if(!attr) {
                this.removeLink(portGuid);
                continue;
              }
            }

            // ---------------------------------------
            // 2) NORMAL PORT (Comp, AE, Nested Char):
            // ---------------------------------------
            if(!viewChildGuid) {
              // 2a) Check if NEW child was created (on node commit)
              // -> swap out the key in outPortMap/inPortMap with real guid
              if(isDiagramNode(childGuid)) {
                if(port.options.in) {
                  var realChild = findChildNode(port.getAttrData(), "diagramId", childGuid);
                } else {
                  var realChild = this.getNodeData().children.find(child => child.diagramId === childGuid);
                }

                if(realChild) {
                  delete hashList[realChild.diagramId];
                  hashList[realChild.guid] = portName;
                  port.setAttrData(realChild);    // ensures the reference pointer wasn't changed accidentally
                }
              }

            // ---------------------------------------
            // 3) PATH PORT:
            // ---------------------------------------
            } else {
              // props: pathData = {
              //     viewNodeData,
              //     viewChild,
              //     strokeData,
              //     goingUp: false,
              //     pathPos,
              // }
              let pathData = port.options.pathData || {};
              let makeNewPathGuid = false;

              if(pathData.viewNodeData) {
                var viewNode = this.$app.getOptionNode(pathData.viewNodeData.guid);
              }

              // 3a) check if VIEW was deleted (on diagram load)
              if(!viewNode) {
                this.removeLink(portGuid);
                continue;
              }

              // 3b) check if NEW characteristic was created (on node commit)
              if(isDiagramNode(viewChildGuid)) {
                let realChar = viewNode.children.find(child => child.diagramId === viewChildGuid);

                if(realChar) {
                  viewChildGuid = realChar.guid;
                  pathData.viewNodeData = viewNode;   // ensures the reference pointer wasn't changed accidentally
                  pathData.viewChild = realChar;      // ensures the reference pointer wasn't changed accidentally
                  makeNewPathGuid = true;
                }
              }

              // 3c) check if NEW composition was created (on node commit)
              if(isDiagramNode(childGuid)) {
                if(port.options.in) {
                  var realChild = findChildNode(port.getAttrData(), "diagramId", childGuid);
                } else {
                  var realChild = this.getNodeData().children.find(child => child.diagramId === childGuid);
                }

                if(realChild) {
                  childGuid = realChild.guid;
                  makeNewPathGuid = true;
                }
              }

              // 3d) replace old port with new port
              if(makeNewPathGuid) {
                delete hashList[portGuid];
                const newPathGuid = childGuid + "--" + viewChildGuid + "--" + pathPos;
                hashList[newPathGuid] = portName;

                if(realChild) {
                  port.setAttrData(realChild);    // ensures the reference pointer wasn't changed accidentally
                  // port.options.pathData = pathData;
                }
              }
            }
          }
        })

        // Update children TYPE attribute
        // -> if user commits a few nodes (not all) then some nodes may be pointing to an old diagramId
        this.getNodeData().children.forEach(child => {
          if(!child.type) return;

          if(isDiagramNode(child.type)) {
            const typeNode = this.$app.getOptionNode(child.type);

            // Diagram Nodes can be "deleted" now.
            // it is deleted by removing the guid from diagramGuids list (the data still exist)
            if(!typeNode || !this.$app.tabData.diagramGuids.has(child.type)) {
              child.type = "";
            } else {
              // if typeNode was converted from DiagramNode to RealNode
              // then diagramId and guid are both pointing to the same memory location
              // and child.type needs an updated string
              child.type = typeNode.guid;
            }
          }
        })
    }







    /*
     * UPDATE NODE
     */
    forceNodeToUpdateByMoving = () => {
        this.setPosition(this.position.x + 0.0000000000001, this.position.y);
    }

    // force Widget to update
    forceWidgetToUpdate = (refreshOnly=true) => {
        if(refreshOnly) {
            this.$widget.forceUpdate();
        } else {
            this.sortChildrenByType();
            this.markUncommittedStatus();
            this.$widget.forceUpdate();
        }
    }

    sortChildrenByType = () => {
      this.getNodeData().children.sort((node1, node2) => {
        if (node1.xmiType < node2.xmiType) return -1;
        if (node1.xmiType > node2.xmiType) return 1;
        return 0;
      });
    }





    link = (srcPort, dstPort) => {
        const link = Object.values(srcPort.links)[0];
        const engine = this.$app.engine;

        // establish a link if and only if no connection exist
        if(!link || !link.targetPort) {
            const newLink = engine.model.addLink( srcPort.link(dstPort) );
            srcPort.reportPosition();
            dstPort.reportPosition();
            engine.repaintCanvas();
            return newLink;
        }
    };

    establishLink = (attr, dstNodeModel, dstPosition, srcPortOptions={}, dstPortOptions={}) => {
        try {
            if(!attr) throw "attribute is invalid, cannot establish link.";
            if(!dstNodeModel) throw "target node is invalid, cannot establish link.";

            const rotatePos = {
                top : [0, 1, 2],
                right : [2, 4],
                bottom : [4, 5, 6],
                left : [6, 0],
            }

            const targetPosition = getPositionOfTarget(this, dstNodeModel);
            const closestPoints = getClosestAnchorPoints(this, dstNodeModel);
            const latestPortPosition = !isNestingChar(attr) ? getLatestPortPosition(this, attr.type) : false;

            if(!dstPosition) dstPosition = closestPoints.target;
            let dstPort = dstNodeModel.getInPort(attr.guid);
            if(!dstPort) dstPort = dstNodeModel.setInPort(attr, {position: dstPosition, ...dstPortOptions});

            if(targetPosition && (latestPortPosition || latestPortPosition === 0)) {
                const positions = rotatePos[targetPosition];
                const idx = positions.findIndex(pos => pos === latestPortPosition);
                if(idx > -1) var srcPosition = positions[(idx + 1) % positions.length];
            }

            if(!srcPosition && srcPosition !== 0) var srcPosition = closestPoints.source;
            let srcPort = this.getOutPort(attr.guid);
            if(!srcPort) srcPort = this.setOutPort(attr, {position: srcPosition, ...srcPortOptions});

            this.link(srcPort, dstPort);
        } catch (err) {
            console.error(err);
        }
    }

    removeLink = (portGuid) => {
        try {
            if(!portGuid) throw "invalid port, failed to remove link.";
            const port = this.getOutPort(portGuid) || this.getInPort(portGuid);

            if(port) {
                const link = Object.values(port.links)[0];

                if(link) {
                    let srcNode = link.sourcePort.parent;
                    let dstNode = link.targetPort.parent;

                    srcNode.removeOutPort(portGuid);
                    dstNode.removeInPort(portGuid);
                    link.remove();
                }
            }
        } catch (err) {
            console.error(err);
        }
    }

    removeDiagramLink = (portGuid) => {
        try {
            if(!portGuid) throw "invalid port, failed to remove link.";

            const portGuids = portGuid.split("--");

            let compGuid = portGuids[0];
            let charGuid = portGuids[1];
            let direction = portGuids[2];

            if(charGuid) {
                this.removePathLinks(portGuid);
            } else {
                this.removeLink(portGuid);
            }
        } catch (err) {
            console.error(err);
        }
    }

    removePathLinks = (portGuid) => {
        const port = this.getOutPort(portGuid) || this.getInPort(portGuid);
        try{
            if(!port) throw "invalid port, failed to remove link.";

            const {pathData} = port.options;
            const viewGuid = pathData.viewNodeData.guid;
            const viewChild = pathData.viewChild;

            const viewNodeModel = this.$app.findNodeModel(viewGuid);
            if(viewNodeModel) {
                viewNodeModel.removePathLinks(viewChild);
            }

        } catch(e) {
            console.error(e);
        }
    }

    setPortPosition = (port, pos) => {
        try {
            if(!port) throw "invalid port, cannot set port position.";
            if(!pos) throw "invalid port position, cannot set port position.";
            port.options.position = pos;

            if(port.options.type === "path-port") {
                var outerPositions = projectionPortPosition;
            } else {
                var outerPositions = modellingPortPosition;
            }

            let newX = this.position.x + this.width * outerPositions[pos][0];
            let newY = this.position.y + this.height * outerPositions[pos][1];

            port.setPosition(newX, newY);
            port.reportPosition();
        } catch (err) {
            console.error(err);
        }
    }



    /*
     * PATH BUILDER
     */
    setPathInPort = (attrData, outPortGuid, pathData={}, position) => {
        // need portMap to keep track of ports
        const portName = this.$app.props.manager.createPortId();
        this.inPortMap[outPortGuid] = portName;

        const port = this.addPort(new PathPortModel({
            name: portName,
            in: true,
            attrData,
            pathData,
            position: pathData.goingUp ? 10 : 0,
        }));

        this.addPort(port);
        port.reportPosition();
        return port;
    }

    setPathOutPort = (attrData, portGuid, pathData={}) => {
        // need portMap to keep track of ports
        const portName = this.$app.props.manager.createPortId();
        this.outPortMap[portGuid] = portName;

       const port = new PathPortModel({
           name: portName,
           in: false,
           attrData,
           pathData,
       });

       this.addPort(port);
       port.reportPosition();
       return port;
   }

    establishPathLink = (attr, pathData={}, dstNodeModel) => {
        if(!attr || !dstNodeModel) return;

        let portGuid = createPathPortGuid(attr, pathData);

        let dstPort = dstNodeModel.getInPort(portGuid);
        if(!dstPort) dstPort = dstNodeModel.setPathInPort(attr, portGuid, pathData);

        let srcPort = this.getOutPort(portGuid);
        if(!srcPort) srcPort = this.setPathOutPort(attr, portGuid, pathData);

        this.link(srcPort, dstPort);

        return {
            targetPort: dstPort,
        }
    }



    /*
     * Helpers
     */
    markUncommittedStatus = () => {
        const nodeData = this.getNodeData();
        const originalData = this.getOriginalData();
        let nodeUncommitted = false;

        if (isDiagramNode(nodeData.guid) ||
            isDifferent(nodeData, originalData)){
                nodeUncommitted = true;
        }

        nodeData.children.forEach(child => {
            let originalChild = originalData.children.find(c => c.guid === child.guid);
            child.isUncommitted = isDiagramNode(child.guid) || child.deleteMe || child.deprecateMe || isDifferent(child, originalChild);
            if(child.isUncommitted) {
              nodeUncommitted = true;
            }
        })

        if (nodeUncommitted) {
            this.$app.addUncommittedNode(nodeData);
        } else {
            this.$app.removeUncommittedNode(nodeData.guid);
        }

        this.updateProp("isUncommitted", nodeUncommitted);
    }

    toggleHiddenAttributeGuid = (guid) => {
      const { hiddenAttributeGuids } = this.getSettings();
      hiddenAttributeGuids.has(guid) ? hiddenAttributeGuids.delete(guid) : hiddenAttributeGuids.add(guid);
      this.forceWidgetToUpdate();
    }

    toggleHiddenAttributeGuids = (bool) => {
      const showAll = bool || !(this.getSettings().hiddenAttributeGuids.size === 0);
      const hiddenGuids = new Set();

      if(!showAll) this.getNodeData().children.forEach(child => hiddenGuids.add(child.guid));
      this.setSettings("hiddenAttributeGuids", hiddenGuids);
      this.forceWidgetToUpdate();
    }

    toggleShowHiddenAttributes = (bool) => {
      const showHiddenAttributes = bool || !this.getSettings().showHiddenAttributes;
      this.setSettings("showHiddenAttributes", showHiddenAttributes);
      this.forceWidgetToUpdate();
    }

    toggleShowCompositionType = (bool) => {
      const showCompositionType = bool || !this.getSettings().showCompositionType;
      this.setSettings("showCompositionType", showCompositionType);
      this.forceWidgetToUpdate();
    }

    showOnlyAttributesWithActivePorts = () => {
      const { hiddenAttributeGuids } = this.getSettings();

      this.getNodeData().children.forEach(child => {
          const hasOutPorts = Object.keys(this.getOutPorts()).some((portGuid) => {
              return child.guid === portGuid.split("--")[0];
          });

          const hasInPorts = Object.keys(this.getInPorts()).some(portGuid => {
              const port = this.getInPort(portGuid);
              return port && child.guid === port.options.targetAttrGuid;
          })

          if(hasOutPorts || hasInPorts) {
              hiddenAttributeGuids.delete(child.guid);
          } else {
              hiddenAttributeGuids.add(child.guid);
          }
      })
      this.forceWidgetToUpdate();
    };
}










export class DiagramNodeWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isEditing: false,
            hideAttr: new Set(),
            showAllAttrs: true,
            showHiddenAttrs: false,
        };

        this.$app = this.props.engine.$app;
        this.nodeModel = this.props.node;
        this.nodeModel.$widget = this;
        this.childrenRef = {};
        this.phenomId = new PhenomId(this.$app.getPhenomDomId() + "-" + this.nodeModel.getNodeData().guid);
        this.$app.props.manager.setActiveGuid(this.nodeModel.getNodeData().guid);
    }

    componentDidMount() {
        ReactTooltip.rebuild();

        if (!this.$app.state.loadingDiagram && isDiagramNode(this.nodeModel.getNodeData().guid)) {
          this.toggleEditMode(false);
        }

        if(this.widgetRef) {
          this.domHeight = this.widgetRef.offsetHeight; // used for Association Hexagon
          this.forceUpdate();
        }
    }

    getPhenomDomId = () => {
      return this.phenomId.genPageId();
    }

    updatePhenomDomId = () => {
      this.phenomId = new PhenomId(this.$app.getPhenomDomId() + "-" + this.nodeModel.getNodeData().guid);
    }


    /*
     * Node Data
     */
    // updateProp = (prop, val) => {
    //     this.nodeModel.updateProp(prop, val);
    //     this.forceUpdate();
    // };

    updateAttr = (attr, prop, val) => {
        if(attr.isPlaceholder) {
            attr[prop] = val;
            attr.isPlaceholder = false;

            this.nodeModel.getNodeData().children.push(attr);
            this.nodeModel.sortChildrenByType();

            this.forceUpdate(() => {
                const attrRef = this.childrenRef[attr.guid].ref;
                const input = attrRef.querySelector(`input[type="text"]`);
                if(input) input.focus();
            })

            if(this.createAttrNode) {
                this.placeholder = this.createAttrNode("", true);
            }

        } else {
            attr[prop] = val;
            this.forceUpdate();
        }
    };


    toggleEditMode = (bool) => {
      const isEditing = bool || !this.state.isEditing;

      this.setState({ isEditing }, () => {
        if(!isEditing) {
          this.nodeModel.sortChildrenByType();
          this.nodeModel.markUncommittedStatus();
          this.nodeModel.setLocked(false);
          this.$app.forceNodesToUpdate();
        }
      });
    }

    revealInTree = () => {
        const guid = this.nodeModel.getNodeData().guid;
        window["treeRef"].searchLeafByGuid(guid);
    };


    

    /*
     * Actions
     */
    handleDoubleClick = () => {
        this.toggleEditMode(true);
    }

    expandTypeNode = async (child, type) => {
        if(!child || !type) return;
        return await this.$app.addNodes(type, [this.nodeModel.getX() + this.nodeModel.width, this.nodeModel.getY(), this.nodeModel.getBoundingBox()], true, true, false, true);
    };

    getNewPortPosition = (port, {searchChildGuid="", displayInChildRow=false, displayOnLeftSide=false}) => {
        if(!port) return;

        const pos = port.options.position;
        const isPathPort = port.options.type === 'path-port';

        if(isPathPort) {
            var portPosition = projectionPortPosition;
        } else {
            var portPosition = modellingPortPosition;
        }

        let width = this.nodeModel.width;
        let height = this.nodeModel.height;
        let percentX = portPosition[pos][0];
        let percentY = portPosition[pos][1];

        let pathX = this.nodeModel.getX();
        let pathY = this.nodeModel.getY();
        let absX = percentX * 100 + "%";
        let absY = percentY * 100 + "%";

        if(isPathPort || displayInChildRow) {
            const zoom = this.$app.model.getZoomLevel() / 100;

            // Can be undefined for Path InPort
            const childRow = this.childrenRef[searchChildGuid]?.ref;
            // const childRow = searchChildGuid ? this.widgetRef && this.widgetRef.querySelector(`div[data-id="${searchChildGuid}"]`) : null;

            // Default Y position
            absY = "0px";

            if(!port.options.in) {
                // InPort = Left side
                // OutPort = Right side
                absX = displayOnLeftSide ? "0%" : "100%";
                pathX = displayOnLeftSide ? pathX : pathX + width;
            }

            if(childRow) {
                const nodeRect = this.widgetRef.getBoundingClientRect();
                const childRect = childRow.getBoundingClientRect();

                const newHeight = childRect.y + (childRect.height / 2);
                const point = this.$app.engine.getRelativePoint(childRect.x, newHeight);
                
                absY = (newHeight - nodeRect.y) / zoom + "px";
                pathY = point.y / zoom;

            } else {

                // Shifts inPort to the right, if there is no childRow
                if(port.options.in) {
                    pathX = pathX + (percentX * width);
                }
            }

            return {
                absX,
                absY,
                pathX,
                pathY,
            }
        }

        // otherwise return abs position only
        return {
            absX,
            absY,
        }
    }

    startResize = (e) => {
        e.stopPropagation();
        this.nodeModel.setLocked(true);

        const classes = e.target.classList;
        const min_width = nodeProps.minWidth;
        const min_height = 100;

        let original_x = this.nodeModel.getX();
        let original_y = this.nodeModel.getY();
        let original_mouse_x = e.pageX;
        let original_mouse_y = e.pageY;
        let original_width = this.nodeModel.width;
        let original_height = this.nodeModel.height;

        const resize = (e) => {
            if(classes.contains("resize-right")) { resizeRight(e); }
            else if(classes.contains("resize-left")) { resizeLeft(e); }
            else if(classes.contains("resize-bottom")) { resizeBottom(e); }
            else if(classes.contains("resize-top")) { resizeTop(e); }
            else if(classes.contains("resize-top-left")) { resizeTop(e); resizeLeft(e); }
            else if(classes.contains("resize-top-right")) { resizeTop(e); resizeRight(e); }
            else if(classes.contains("resize-bottom-left")) { resizeBottom(e); resizeLeft(e); }
            else if(classes.contains("resize-bottom-right")) { resizeBottom(e); resizeRight(e); }

            this.nodeModel.rememberNodeSize();
            this.forceUpdate();
        }

        const stopResize = () => {
            this.nodeModel.setLocked(false);
            window.removeEventListener("mousemove", resize);
            window.removeEventListener("mouseup", stopResize);
        }

        const resizeTop = (e) => {
            let height = original_height - (e.pageY - original_mouse_y);
            let pos_y = original_y + (e.pageY - original_mouse_y);
            if(min_height <= height) {
                this.nodeModel.height = height;
                this.nodeModel.position.y = pos_y;
                this.props.engine.repaintCanvas();
            }
        }

        const resizeBottom = (e) => {
            let height = original_height + (e.pageY - original_mouse_y);
            if(min_height <= height) {
                this.nodeModel.height = height;
            }
        }

        const resizeRight = (e) => {
            let width = original_width + (e.pageX - original_mouse_x);
            if(width >= min_width) {
                this.nodeModel.width = width;
            }
        }

        const resizeLeft = (e) => {
            let width = original_width - (e.pageX - original_mouse_x);
            let pos_x = original_x + (e.pageX - original_mouse_x);
            if(width >= min_width) {
                this.nodeModel.width = width;
                this.nodeModel.position.x = pos_x;
                this.props.engine.repaintCanvas();
            }
        }

        window.addEventListener("mousemove", resize);
        window.addEventListener("mouseup", stopResize);
    }

    render() {
        return (
            <div>
                Please don't render this.
            </div>
        )
    }
}
