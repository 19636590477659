import {CadetInput, CadetTextArea} from "../stateless";
import React from "react";
import PhenomId from "../../../requests/phenom-id";

export class MsgFieldHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: !!this.props.field.rolename,
            presence: this.props.field.presence,
            name: this.props.field.name,
            value: this.props.field.value,
            rolename: this.props.field.rolename,
            description: this.props.field.description,
            type: this.props.field.scope ? "view" : "protocol",
            initializePresenceChange: false,
        };

        if (this.props.changeMaxH && !this.props.field.rolename) {
            this.props.changeMaxH(135);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.field !== prevProps.field) {
            const field = this.props.field;
            this.setState({
                rolename: field.rolename,
                description: field.description,
                type: field.scope ? "view" : "protocol",
                presence: field.presence,
            });
        }
    }

    changeState = e => {
        const key = e.target.id.match("rolename|conditionals")[0];
        const value = e.target.value;
        this.setState({[key]: value});
    };

    toggleCollapse = position => {
        if (!(position === false || position === true)) {
            position = !this.state.collapsed;
        }
        this.setState({collapsed: position});
        this.props.toggleContents(position);
        if (this.props.changeMaxH) {
            this.props.changeMaxH(105 * (!position ? 1 : -1));
        }
    };

    toggleType = () => {
        const newType = this.state.type === "view" ? "protocol" : "view";
        this.setState({type: newType});
        if (this.state.collapsed) {
            this.toggleCollapse();
        }
        this.props.toggleType(newType);
    };

    togglePresence = () => {
        if (this.props.field.presence === "always") {
            if (!this.state.name) {
                this.setState({initializePresenceChange: true, presence: "conditional"});
                if (this.state.collapsed) {
                    this.toggleCollapse();
                }
            } else {
                this.props.setValue(true);
                this.props.effectChange(this.state.name);
            }
        } else {
            this.setState({presence: "always", name: ""}, this.props.effectChange);
        }
    };

    reportState = () => {
        return {
            rolename: this.state.rolename,
            description: this.state.description,
            name: this.state.name,
            presence: this.state.presence,
        };
    };

    render() {
        const phenomId = new PhenomId("header",this.props.idCtx);
        return (
            <header
                className="message-field-header"
                style={{width: "100%", backgroundColor: (this.props.idx % 2 ? "#cfd1d2" : "#e4e4e4")}}
                onDoubleClick={this.toggleCollapse}
            >
                {(this.props.field.presence === "always" || this.props.value === false) ||
                <CadetInput text={this.props.value || ""} style={{width: "5%"}} onChange={this.props.setValue} id={phenomId.gen("","value-input")}/>
                }
                <CadetInput text={this.state.rolename || ""} onChange={this.changeState} id={phenomId.gen("","rolename-input")}
                            placeholder="enter field name"/>
                <CadetTextArea
                    idCtx={phenomId.gen("","description")}
                    style={{height: 22, paddingTop: 2}}
                    onChange={(e) => this.setState({description: e.target.value})}
                    text={this.state.description || ""}
                    placeholder='enter description'
                    narrow={true}
                />
                <button className="toggle" onClick={this.toggleType} style={{width: "10%"}} id={phenomId.gen("","toggle-type-button")}>
                    <div id="first" className={this.state.type === "view" ? "tglRight" : "tglLeft"}/>
                    <div id="second" style={{
                        left: this.state.type === "view" ? 0 : 22,
                        textAlign: this.state.type === "view" ? "start" : "end"
                    }}>{this.state.type}</div>
                </button>
                <button className="toggle" style={{width: "12%"}}
                        onClick={this.state.initializePresenceChange ? null : this.togglePresence} id={phenomId.gen("","toggle-presence-button")}>
                    <div id="first" className={this.state.presence === "always" ? "tglRight" : "tglLeft"}/>
                    <div id="second" style={{
                        left: this.state.presence === "always" ? 0 : 22,
                        textAlign: this.state.presence === "always" ? "start" : "end"
                    }}>{this.state.presence}</div>
                    {!this.state.initializePresenceChange ||
                    <span className='conditional-name-entry'>
                    <input
                        id={phenomId.gen("","name-input")}
                        className="cadet-text-input" value={this.state.name || ""}
                        placeholder='Enter Conditional Name'
                        list="conditionals"
                        onChange={(e) => this.setState({name: e.target.value})}
                    />
                    <datalist id={phenomId.gen("conditionals","datalist")}>
                        {this.props.conditionalFields.map((field,idx) => <option id={phenomId.gen(["conditionals",idx],"option")}value={field}/>)}
                    </datalist>
                    <div className="flex-h">
                        <button
                            id={phenomId.gen("init","ok-button")}
                            className="bordered-button"
                            onClick={this.togglePresence}>
                            OK
                        </button>
                        <button
                            id={phenomId.gen("init","cancel-button")}
                            onClick={() => this.setState({
                                initializePresenceChange: false,
                                name: this.props.field.name,
                                presence: "always"
                            })}
                            className="bordered-button">
                            Cancel
                        </button>
                    </div>
                </span>}
                </button>
                <button
                    id={phenomId.gen("init","form-button")}
                    onClick={this.toggleCollapse}
                    className={"form-button " + (this.state.collapsed ? "expand-down" : "collapse-up") + "-lg"}>
                </button>
            </header>
        );
    }
}
