import {MsgFieldHeader} from "./MsgFieldHeader";
import {MsgFieldContents} from "./MsgFieldContents";
import React from "react";
import PhenomId from "../../../requests/phenom-id";

export class MsgField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contents: {},
            header: {},
            value: this.props.field.value,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.field.guid !== prevProps.field.guid) {
            this.setState({value: this.props.field.value});
        }
    }

    contentRef = element => {
        this.setState({contents: element});
    };

    headerRef = element => {
        this.setState({header: element});
    };

    setValue = e => {
        if (e.target) {
            this.setState({value: e.target.value});
        } else {
            this.state.value = e;
            this.setState({value: e});
        }
    };

    reportState = () => {
        return {
            ...(this.state.contents.reportState()),
            ...(this.state.header.reportState()),
            ...{guid: this.props.field.guid, value: this.state.value}
        };
    };

    forceCollapsedState(state) {
        this.state.header.toggleCollapse(state);
    }

    render() {
        const phenomId = new PhenomId("msg-field",this.props.idCtx);
        return (
            <div
                id={phenomId.gen("","drag-div")}
                style={{marginLeft: this.props.field.presence === "conditional" ? 30 : 0}}
                draggable={true}
                onDragStart={(e) => {
                    e.dataTransfer.items.add(this.props.field.guid, "text/plain");
                }}
            >
                <MsgFieldHeader
                    idCtx={phenomId.gen()}
                    ref={this.headerRef}
                    idx={this.props.idx}
                    effectChange={this.props.effectChange}
                    value={this.state.value}
                    setValue={this.setValue}
                    field={this.props.field}
                    toggleContents={this.state.contents.toggleCollapse}
                    toggleType={this.state.contents.toggleType}
                    changeMaxH={this.props.changeMaxH}
                    conditionalFields={this.props.conditionalFields}
                />
                <MsgFieldContents
                    idCtx={phenomId.gen()}
                    ref={this.contentRef}
                    value={this.state.value}
                    discriminator={this.props.discriminator}
                    setValue={this.setValue}
                    field={this.props.field}
                    views={this.props.views}
                />
            </div>
        );
    }
}
