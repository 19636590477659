import React from 'react'
import $ from 'jquery'
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid'
import { PhenomComboBox, PhenomInput, PhenomLabel } from '../util/stateless';
import { getNodesOfType, getNodeWithAddenda, getTransformCode, smmDeleteNodes, smmSaveNodes } from '../../requests/sml-requests';
import { createPhenomGuid, deGuidify, isPhenomGuid, retrieveNestedNodes, splitXmiType } from '../util/util';
import { cloneDeep } from 'lodash';
import { Button } from '@progress/kendo-react-buttons';
import styled from '@emotion/styled';
import { primitiveTypeList } from '../../global-constants';
import { BasicAlert } from '../dialog/BasicAlert';
import { receiveErrors, receiveResponse, receiveWarnings } from '../../requests/actionCreators';
import NavTree from '../tree/NavTree';
import { _ajax } from '../../requests/sml-requests';


// removed for the demo
// "platform:IDLArray", "platform:IDLSequence", "platform:BoundedString", "platform:BoundedWString", "platform:CharArray", "platform:Fixed", "platform:WCharArray"
const platformXmiTypes = ["platform:Boolean", "platform:Octet", "platform:Char", "platform:WChar", "platform:String", "platform:WString", "platform:Short", "platform:UShort",
"platform:Long", "platform:ULong", "platform:LongLong", "platform:ULongLong", "platform:Float", "platform:Double", "platform:LongDouble", "platform:Enumeration", "platform:IDLStruct"];


const ViewModGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 20px;
`



class ViewModification extends React.Component {
  view1Ref = React.createRef()
  view2Ref = React.createRef()

  viewAddenda = {
    coreAddenda: ["childrenMULTI"],
    coreAddendaChildren: ["pathPairsMULTI"],
    // coreAddendaChildren: ["measurement", "platformType"],
    // platformTypeAddenda: ["realizes"],
    // realizesAddenda: ["measurementAxisMULTI", "realizes"],
    // measurementAxisAddenda: ["valueTypeUnitMULTI", "measurementSystemAxis"],
    // measurementSystemAxisAddenda: ["defaultValueTypeUnitMULTI"],
  }

  defaultState = {
    view1: {},
    view2: {},
    chars1: [],
    chars2: [],
    originals: {},

    transformCodeLeftToRight: "",
    transformCodeRightToLeft: "",
    selectedSemantic: "",

    all_measurements: {},
    all_platformTypes: {},
    all_measSys: {},
    all_coordSys: {},
    all_valuetypeunits: {},
    list_measSysAxis: [],
    unit_conversions:  {},
    msa_conversions: {},
    primitiveTypeList: primitiveTypeList.map(xmiType => splitXmiType(xmiType)),
  }
  state = cloneDeep(this.defaultState)

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.viewGuid1 !== prevProps.match.params.viewGuid1 ||
        this.props.match.params.viewGuid2 !== prevProps.match.params.viewGuid2) {
          this.init();
    }
  }

  init = async () => {
    await this.gatherData();
    this.fetchViews();
  }

  gatherData = async () => {
    BasicAlert.show("Gathering needed data", "One moment", false);
    
    const res = await Promise.all([
      this.fetchMeasurements(),
      this.fetchMeasurementSystems(),
      this.fetchCoordinateSystems(),
      this.fetchPlatformTypes(),
      this.fetchValueTypeUnits(),
      this.fetchConversions(),
    ]);

    BasicAlert.hide();
    return res;
  }

  fetchViews = () => {
    BasicAlert.show("Retrieving Views", "One moment", false);

    Promise.all([
      getNodeWithAddenda(this.props.match.params.viewGuid1, this.viewAddenda),
      getNodeWithAddenda(this.props.match.params.viewGuid2, this.viewAddenda)
    ]).then(res => {
      const view1 = res[0];
      const view2 = res[1];
      
      this.setViewState(view1, view2);
    }).finally(() => {
      BasicAlert.hide();
    })
  }

  setViewState = (view1, view2) => {
    const originals = {};

    // on save - check if char was edited
    for (let char of view1.children) {
      originals[char.guid] = cloneDeep(char);
    }

    for (let char of view2.children) {
      originals[char.guid] = cloneDeep(char);
    }

    this.setState({ 
      view1,
      view2,
      chars1: [...view1.children],
      chars2: [...view2.children],
      originals,
    });
  }

  fetchConversions = () => {
    return _ajax({
      url: "/index.php?r=/node/get-obs-to-unit-list",
      method: "get",
    }).then(response => {

      this.setState({
        unit_conversions: response.data.unit_conversions || {},
        msa_conversions: response.data.msa_conversions || {},
      })
    })
  }

  fetchMeasurements = () => {
    return getNodesOfType("logical:Measurement", {
      coreAddenda: ["measurementAxisMULTI"],
      measurementAxisAddenda: ["valueTypeUnitMULTI", "measurementSystemAxis"],
      measurementSystemAxisAddenda: ["defaultValueTypeUnitMULTI"],
      valueTypeUnitAddenda: ["valueType", "unit"],
      defaultValueTypeUnitAddenda: ["valueType", "unit"],
    }).then(res => {
      this.setState({
        all_measurements: deGuidify(res.data.nodes),
      })
    })
  }

  fetchMeasurementSystems = () => {
    return getNodesOfType("logical:MeasurementSystem", {
      coreAddenda: ["measurementSystemAxisMULTI"],
      measurementSystemAxisAddenda: ["defaultValueTypeUnitMULTI"],
      defaultValueTypeUnitAddenda: ["valueType", "unit"],
    }).then(res => {
      const response = res.data;
      const all_msas = {}

      for (let ms of response.nodes) {
        // skip - if number of MSAs is not one
        if (!Array.isArray(ms.measurementSystemAxis) || ms.measurementSystemAxis.length !== 1) {
          continue;
        }

        const msa = ms.measurementSystemAxis[0];
        all_msas[msa.guid] = msa;
      }

      this.setState({
        all_measSys: deGuidify(response.nodes),
        list_measSysAxis: Object.values(all_msas).sort((msa1, msa2) => msa1.name.localeCompare(msa2.name)),
      })
    })
  }

  fetchCoordinateSystems = () => {
    return getNodesOfType("logical:CoordinateSystem").then(res => {
      this.setState({
        all_coordSys: deGuidify(res.data.nodes),
      })
    })
  }

  fetchPlatformTypes = () => {
    return getNodesOfType(platformXmiTypes).then(res => {
      this.setState({
        all_platformTypes: deGuidify(res.data.nodes),
      })
    })
  }

  fetchValueTypeUnits = () => {
    return getNodesOfType("logical:ValueTypeUnit", {
      coreAddenda: ["valueType", "unit"],
    }).then(res => {
      this.setState({
        all_valuetypeunits: deGuidify(res.data.nodes),
      })
    })
  }

  setActiveSemantic = (path) => {
    if (path === this.state.selectedSemantic) return;
    this.setState({ selectedSemantic: path || "" });
  }

  onCloneChar = () => {
    const char = this.view1Ref.current.getSelectedChar();
    if (!char?.guid) {
      return receiveWarnings('Please select a field and try again.');
    }

    const copy = cloneDeep(char);
          copy.guid = createPhenomGuid();
          copy.deconflictName = true;
          delete copy["parent"];    // this is pointing to original parent
    this.view2Ref.current.addNewChar( copy );
  }

  onAddChild = () => {
    this.view2Ref.current.addNewChar();
  }

  addNewMeasurement = (measurement) => {
    if (!measurement?.guid) {
      return;
    }

    this.setState(prevState => {
      return { 
        all_measurements: {
          ...prevState.all_measurements,
          [measurement.guid]: measurement,
        }
      }
    })
  }

  addNewMeasurementSystem = (measSys) => {
    if (!measSys?.guid) {
      return;
    }

    this.setState(prevState => {
      return { 
        all_measSys: {
          ...prevState.all_measSys,
          [measSys.guid]: measSys,
        }
      }
    })
  }

  addNewPlatformType = (pt) => {
    if (!pt?.guid) {
      return;
    }

    this.setState(prevState => {
      return { 
        all_platformTypes: {
          ...prevState.all_platformTypes,
          [pt.guid]: pt,
        }
      }
    })
  }

  addNewValueTypeUnit = (vtu) => {
    if (!vtu?.guid) {
      return;
    }

    this.setState(prevState => {
      return { 
        all_valuetypeunits: {
          ...prevState.all_valuetypeunits,
          [vtu.guid]: vtu,
        }
      }
    })
  }

  onReset = () => {
    let view1Chars = this.view1Ref.current.getCharacteristics();
    let chars = view1Chars.map(char => {
                            const cloned = cloneDeep(char);
                            cloned.guid = createPhenomGuid();
                            return cloned;
                          });

    this.view2Ref.current.replaceAllChars(chars);
  }

  saveAndPreview = async () => {
    const success = await this.saveViews();

    if (success) {
      await this.gatherData();
      await this.previewTransformCode();
    }    
  }

  savePreviewAndGenerate = async () => {
    const success = await this.saveViews();

    if (success) {
      await this.gatherData();
      await this.previewTransformCode();
      await this.generateCincFile();
    }    
  }


  saveViews = async () => {
    BasicAlert.show("Saving Views", "One moment", false);

    let view1 = { ...this.state.view1 }
    let view2 = { ...this.state.view2 }
    const deleteCharGuids = this.view2Ref.current.getDeleteCharGuids();
    const view1Chars = this.view1Ref.current.getCharacteristics();
    const view2Chars = this.view2Ref.current.getCharacteristics();

    view2.childOrder = view2Chars.map(c => c.guid);
    view1.children = this.formatCharsForSave( view1.guid, view1Chars );
    view2.children = this.formatCharsForSave( view2.guid, view2Chars );

    if (deleteCharGuids.size) {
      const respDelete = await smmDeleteNodes([...deleteCharGuids]);
      if (respDelete.error || respDelete.errors) {
        BasicAlert.hide();
        receiveErrors(respDelete.error || respDelete.errors);
        return false;
      }

      // deletion successful - clear guids
      this.view2Ref.current.clearDeleteCharGuids();
    }

    const view1Response = await smmSaveNodes({
      node: view1,
      returnTypes: ["platform:View"],
      returnAddenda: {
        "platform:View": this.viewAddenda,
      }
    })

    if (view1Response.error || view1Response.errors) {
      BasicAlert.hide();
      receiveErrors(view1Response.error || view1Response.errors);
      return false;
    }

    const view2Response = await smmSaveNodes({
      node: view2,
      returnTypes: ["platform:View"],
      returnAddenda: {
        "platform:View": this.viewAddenda,
      }
    })

    BasicAlert.hide();
    if (view2Response.error || view2Response.errors) {
      receiveErrors(view2Response.error || view2Response.errors);
      return false;
    }

    const newView1 = view1Response.data.nodes[0];
    const newView2 = view2Response.data.nodes[0];
    receiveResponse({ nodes: [newView1, newView2] });

    this.setViewState(newView1, newView2);
    return true;
  }

  isCharEdited = (char) => {
    const { originals } = this.state;

    if (!char?.guid) {
      return false;
    }

    if (isPhenomGuid(char.guid)) {
      return true;
    }

    const attributes = ["rolename", "descriptionExtension", "measurement", "platformType", "path", "projectedCharacteristic"]
    const original = originals[char.guid];

    return attributes.some(key => original[key] !== char[key]);
  }

  formatCharsForSave = (parentGuid, children=[]) => {
    const { all_measurements, all_platformTypes } = this.state;
    const standardChildren = [];

    children.forEach(char => {
      // skip nested views or chars that reference IDLStructs
      if (char.error || !this.isCharEdited(char)) {
        return;
      }

      const copy = cloneDeep(char);
            copy.parent = parentGuid;

      const measGuid = copy.measurement;
      const ptGuid = copy.platformType;
      const measurement = all_measurements[measGuid];
      const platformType = all_platformTypes[ptGuid];
      const lastHop = copy.pathPairs[copy.pathPairs.length - 1];

      const obsGuid = measurement.realizes;
      const lastHopTypeGuid = typeof lastHop.type === 'string' ? lastHop.type : lastHop.type?.guid;

      // OBSERVABLE WAS CHANGED - create new path
      if (obsGuid !== lastHopTypeGuid) {
        const { projChar, composition } = this.view2Ref.current.createNewPathAttributes(measurement?.guid);
        copy.projectedCharacteristic = projChar;
        copy.path = [ composition ];    //pathPairs

      } else if (isPhenomGuid(lastHop?.guid)) {
        copy.path = copy.pathPairs;
      }

      // replace phenom-guid with object
      if (isPhenomGuid(measGuid)) {
        copy.measurement = measurement;
      }

      if (isPhenomGuid(ptGuid)) {
        copy.platformType = platformType;
      }

      // clean up children - backend throws a temper tantrum when it sees a children array of strings/guids
      const queue = retrieveNestedNodes([ copy ]);
      for (let node of queue) {
        if (!Array.isArray(node.children)) {
          continue;
        }

        if (node.children.some(child => typeof child === 'string')) {
          node.children = [];
        }
      }

      standardChildren.push(copy);
    })

    return standardChildren;
  }

  previewTransformCode = async () => {
    BasicAlert.show("Generating Preview", "One moment", false);
    this.setState({
      transformCodeLeftToRight: ["loading..."],
      transformCodeRightToLeft: ["loading..."],
    })

    const view1Guid = this.props.match.params.viewGuid1;
    const view2Guid = this.props.match.params.viewGuid2;
    const mtLeftToRight = this.view2Ref.current.generateTransformCodes(false);
    const mtRightToLeft = this.view1Ref.current.generateTransformCodes(false);

    const tcRes = await Promise.all([
      getTransformCode(view1Guid, view2Guid, mtLeftToRight),
      getTransformCode(view2Guid, view1Guid, mtRightToLeft),
    ])

    BasicAlert.hide();

    const tcResponse1 = JSON.parse(tcRes[0])
    const tcResponse2 = JSON.parse(tcRes[1])

    this.setState({
      transformCodeLeftToRight: tcResponse1.data.split("\n").map(str => str.trim()),
      transformCodeRightToLeft: tcResponse2.data.split("\n").map(str => str.trim()),
    })
  }

  generateCincFile = async () => {
    BasicAlert.show("Generating Cinc", "One moment", false);
    const view1MTs = this.view1Ref.current.generateTransformCodes(false);
    const view2MTs = this.view2Ref.current.generateTransformCodes(false);
    const searchParams = new URLSearchParams(this.props.location.search);

    $.ajax({
      url: "/index.php?r=/node/generate-ime-demo-code",
      data: {
        context_guid: searchParams.get("forward_transform"),            // "SML_F544D5F1_AA8C_9B02_33C7_53CD1ED3FD95"
        forward_transform_guid: searchParams.get("forward_transform"),  // "SML_58E0B01C_2D7C_BAC1_B449_57C2F1FFA1DE"
        reverse_transform_guid: searchParams.get("reverse_transform"),  // "SML_0B6F5A78_FC2D_4536_B955_26F0989ADD95"
        main_guid: searchParams.get("main_program"),                    // "SML_9F781971_0D7C_87A1_AAD9_D8A05590CCE9"
        uop_guid: searchParams.get("uop"),                              // "SML_5457CC2D_11AE_E887_1E6A_07486B57A5E9"
        msg1_manual_transforms: view1MTs,
        msg2_manual_transforms: view2MTs,
      }
    }).then(res => {
      const response = JSON.parse(res);
      if (response.error || response.errors.length) {
        return receiveErrors(response.error || response.errors);
      }

      window.location.href = `${process.env.NODE_ENV === "development" ? "http://localhost" : ""}/index.php?r=/generate/download-file&file=${response.filename}`;
    }).always(() => {
      BasicAlert.hide();
    })
  }

  render() {
    const { view1, view2, chars1, chars2, all_measurements, all_platformTypes, all_measSys, all_coordSys, all_valuetypeunits, list_measSysAxis, msa_conversions, unit_conversions, primitiveTypeList, selectedSemantic, transformCodeLeftToRight, transformCodeRightToLeft } = this.state;

    return <ViewModGrid className='phenom-content-scrollable'>
      <div style={{ display: "flex", flexDirection: "column", gap: "1em"}}>
        <div style={{ display: "flex", gap: "1em" }}>
          <span className='cadet-input' style={{ flex: 1, border: "1px solid rgba(0, 0, 0, 0.08)" }}>{view1.name || ""}</span>
          <Button icon="add"
                    onClick={this.onCloneChar}>Copy field to right side</Button>
        </div>
        <CharGrid 
            viewGuid={view1.guid}
            chars={chars1}
            msa_conversions={msa_conversions}
            unit_conversions={unit_conversions}
            all_measurements={all_measurements}
            all_platformTypes={all_platformTypes}
            all_measSys={all_measSys}
            all_coordSys={all_coordSys}
            all_valuetypeunits={all_valuetypeunits}
            list_measSysAxis={list_measSysAxis}
            primitiveTypeList={primitiveTypeList}
            selectedSemantic={selectedSemantic}
            setActiveSemantic={this.setActiveSemantic}
            addNewMeasurement={this.addNewMeasurement}
            addNewMeasurementSystem={this.addNewMeasurementSystem}
            addNewPlatformType={this.addNewPlatformType}
            addNewValueTypeUnit={this.addNewValueTypeUnit}
            ref={this.view1Ref} />
      </div>

      <div style={{ display: "flex", flexDirection: "column", gap: "1em"}}>
        <div style={{ display: "flex", gap: "1em" }}>
          <span className='cadet-input' style={{ flex: 1, border: "1px solid rgba(0, 0, 0, 0.08)" }}>{view2.name || ""}</span>
          <Button icon="add"
                  onClick={this.onAddChild}>Add new field</Button>
          <Button icon="reset"
                  onClick={this.onReset}>Reset</Button>
        </div>

        <CharGrid 
            isRightSide
            viewGuid={view2.guid}
            chars={chars2}
            msa_conversions={msa_conversions}
            unit_conversions={unit_conversions}
            all_measurements={all_measurements}
            all_platformTypes={all_platformTypes}
            all_measSys={all_measSys}
            all_coordSys={all_coordSys}
            all_valuetypeunits={all_valuetypeunits}
            list_measSysAxis={list_measSysAxis}
            primitiveTypeList={primitiveTypeList}
            selectedSemantic={selectedSemantic}
            setActiveSemantic={this.setActiveSemantic}
            addNewMeasurement={this.addNewMeasurement}
            addNewMeasurementSystem={this.addNewMeasurementSystem}
            addNewPlatformType={this.addNewPlatformType}
            addNewValueTypeUnit={this.addNewValueTypeUnit}
            ref={this.view2Ref} />
      </div>

      {(!!transformCodeLeftToRight.length || !!transformCodeRightToLeft.length) && <>
        <div>
          <PhenomLabel text={`${view1.name} from ${view2.name}`} />
          <div className='view-mod-textarea'>
            {transformCodeRightToLeft.map(text => {
              const htmlString = text.replace(/(dst|src)\.[a-zA-Z_0-9]+/g, (match) => `<b>${match}</b>`);
              return <p className={(text.startsWith("//") ? "error" : null)} dangerouslySetInnerHTML={{ __html: htmlString }} />
            })}
          </div>
        </div>
        <div>
          <PhenomLabel text={`${view2.name} from ${view1.name}`} />
          <div className='view-mod-textarea'>
            {transformCodeLeftToRight.map(text => {
              const htmlString = text.replace(/(dst|src)\.[a-zA-Z_0-9]+/g, (match) => `<b>${match}</b>`);
              return <p className={(text.startsWith("//") ? "error" : null)} dangerouslySetInnerHTML={{ __html: htmlString }} />
            })}
          </div>
        </div>
      </>}

      <div style={{ display: "flex", justifyContent: "flex-end", gap: 10, gridColumn: "span 2", maxHeight: 30 }}>
        <Button onClick={this.saveAndPreview}>Preview</Button>
        <Button onClick={this.savePreviewAndGenerate}>Generate</Button>
      </div>
    </ViewModGrid>

  }
}


class CharGrid extends React.Component {
  state = {
    chars: [],
    selectedChar: {},
    deleteCharGuids: new Set(),
    draggingChar: null,
  }

  componentDidMount() {
    this.initChars();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.chars !== this.props.chars) {
      this.initChars();
    }
  }

  initChars = () => {
    const chars = cloneDeep(this.props.chars);
          chars.forEach(char => this.checkCharStatus(char));
    this.setState({ chars });
  }

  getSelectedChar = () => {
    return this.state.selectedChar;
  }

  getCharacteristics = () => {
    return this.state.chars;
  }

  getDeleteCharGuids = () => {
    return this.state.deleteCharGuids;
  }

  clearDeleteCharGuids = () => {
    this.setState({ deleteCharGuids: new Set() })
  }

  extractDefaultValue = (descriptionExtension) => {
    if (!descriptionExtension) return "";
    let defaultValue = "";
    let regex = descriptionExtension.match(/^@skayl-default_value=\[\$dst\..* = (.*)\]/);
    if (regex?.[1]) defaultValue = regex[1];
    return defaultValue;
  }

  formatDefaultValueForSave = (char) => {
    const { all_platformTypes } = this.props;
    const ptGuid = char.platformType;
    const platformType = all_platformTypes[ptGuid];
    const ptXmiType = platformType?.xmiType;

    let text = this.extractDefaultValue(char.descriptionExtension)
    if (["platform:Char", "platform:WChar"].includes(ptXmiType)) {
      text = `'${text}'`;
    }
    if (["platform:String", "platform:WString"].includes(ptXmiType)) {
      text = `"${text}"`;
    }
    return text;
  }

  formatDefaultValue = (char, text) => {
    if (!text) return "";
    return `@skayl-default_value=[$dst.${char.rolename} = ${text}]`;
  }

  generateTransformCodes = (isSource) => {
    const transformCodes = [];

    this.state.chars.forEach(char => {
        if (!char.path || !char.descriptionExtension) return;
        let lhs = isSource ? `$src.${char.rolename}` : `$dst.${char.rolename}`;
        let rhs = this.formatDefaultValueForSave(char);
        transformCodes.push(`${lhs} = ${rhs}`);
    })

    return transformCodes;
  }

  addNewChar = (char) => {
    if (!char?.guid) {
      char = this.createNewChar();
    }

    this.setState((prevState) => {
      const chars = [...prevState.chars, char];
      return { chars }
    })
  }

  handleMSAChange = (char, msa) => {
    let unit;

    if (Array.isArray(msa?.defaultValueTypeUnit)) {
      const vtu = msa.defaultValueTypeUnit[0];
      unit = vtu?.unit;
    }

    char["selectedMSA"] = msa;
    char["selectedUnit"] = unit;
    this.changeCharDetails(char);
  }

  changeCharDetails = (char) => {
    const { all_measurements, all_platformTypes } = this.props;
    const measGuid = char.measurement;
    const ptGuid = char.platformType;
    const currMeas = all_measurements[measGuid];
    const currPT = all_platformTypes[ptGuid];
    let { selectedMSA, selectedUnit, selectedPrimitiveType } = char;
    let measAxis, measSysAxis, obsGuid, valueType, newMeas;

    if (currMeas) {
      measAxis = currMeas.measurementAxis[0];
      obsGuid = currMeas.realizes;
    }

    if (measAxis) {
      measSysAxis = measAxis.measurementSystemAxis;
    }

    if (!selectedMSA?.guid && measAxis) {
      selectedMSA = measAxis.measurementSystemAxis;
    }

    if (measAxis && measSysAxis) {
      const vtu = measAxis.valueTypeUnit[0] || measSysAxis.defaultValueTypeUnit[0];
      valueType = vtu.valueType;

      if (!selectedUnit?.guid) {
        selectedUnit = vtu.unit || {};
      }
    }

    // demo specific char
    // -> User clicked the "add new field" button
    //    -> Show all MSAs
    //    -> When searching for a matching measurement, the obserable is allowed to change.
    if (char.demoSpecificChar) {
      newMeas = this.findMatchingMeasurement(selectedMSA?.guid, selectedUnit?.guid);
    } else {
      // Measurement limited by obsGuid
      newMeas = this.findMatchingMeasurement(selectedMSA?.guid, selectedUnit?.guid, obsGuid);
    }

    // match not found - create new Measurement
    if (!newMeas) {
      newMeas = this.createNewMeasurement(obsGuid, selectedMSA, valueType, selectedUnit);
    }

    // find or create PlatformType
    let primitiveType = selectedPrimitiveType || currPT.xmiType;
    let newPT = this.findMatchingPlatformType(primitiveType, newMeas.guid);
    if (!newPT) {
      newPT = this.createNewPlatformType(newMeas.name, primitiveType, newMeas.guid);
    }

    // Observable was changed
    if (obsGuid !== newMeas?.realizes) {
      const obsLeaf = NavTree.getLeafNode(obsGuid);
      const realizeLeaf = NavTree.getLeafNode(newMeas?.realizes);

      if (obsLeaf && realizeLeaf) {
        receiveWarnings(`Changing observable from '${obsLeaf.getName()}' to '${realizeLeaf.getName()}'`);
      }
    }
    
    char.measurement = newMeas.guid;
    char.platformType = newPT.guid;

    this.forceUpdate();
  }

  /**
   * 
   * @param {string} obsGuid
   * @param {string} msaGuid 
   * @param {string} unitGuid 
   * @returns 
   */
  findMatchingMeasurement = (msaGuid, unitGuid, obsGuid) => {
    const { all_measurements } = this.props;

    const find_matching_vtu = (vtu) => {
      return unitGuid === vtu.unit?.guid;
    }

    const find_matching_measAxis = (ma) => {
      const msa = ma.measurementSystemAxis;
      const main_vtu = ma.valueTypeUnit;
      const default_vtu = msa.defaultValueTypeUnit;
      const vtus = main_vtu.length ? main_vtu : default_vtu;

      return msaGuid === msa.guid &&
             vtus.length === 1 &&
             vtus.find( find_matching_vtu );
    }

    const find_matching_measurement = (meas) => {
      // if obsGuid is provided, limit the search by obs
      if (obsGuid) {
        return obsGuid === meas.realizes && 
               meas.measurementAxis.length === 1 &&
               meas.measurementAxis.find( find_matching_measAxis );
      }

      // demo specific char can change observables
      return meas.measurementAxis.length === 1 &&
             meas.measurementAxis.find( find_matching_measAxis );
    }

    return Object.values(all_measurements).find( find_matching_measurement );
  }

  /**
   * 
   * @param {string} primitiveType 
   * @param {string} measGuid 
   */
  findMatchingPlatformType = (primitiveType, measGuid) => {
    const { all_platformTypes } = this.props;

    if (!primitiveType) {
      return;
    }

    return Object.values(all_platformTypes).find(pt => {
      const realizeGuid = pt.realizes;
      return pt.xmiType === primitiveType && realizeGuid === measGuid;
    })
  }

  /**
   * 
   * @param {object} observable 
   * @param {object} selectedMSA 
   * @param {object} valueType 
   * @param {object} selectedUnit 
   */
  createNewMeasurement = (obsGuid, selectedMSA, valueType, selectedUnit) => {
    const { all_measSys, all_coordSys, all_valuetypeunits, addNewMeasurement } = this.props;
    const obsLeaf = NavTree.getLeafNode(obsGuid);
    const obsName = obsLeaf ? obsLeaf.getName() : "Obs"
    const baseName = `${obsName}_${valueType.name}_${selectedUnit.name}`;

    const find_matching_cs = (csaGuid) => {
      return Object.values(all_coordSys).find(cs => cs.axis === csaGuid);
    }

    const find_matching_ms = (msaGuid) => {
      return Object.values(all_measSys).find(ms => {
        const msa = ms.measurementSystemAxis[0];
        
        return ms.measurementSystemAxis.length === 1 &&
               msaGuid === msa.guid;
      })
    }

    const find_matching_vtu = (vtGuid, uGuid) => {
      // finds a vtu with matching vtGuid and uGuid - ignores the constraints for the demo
      return Object.values(all_valuetypeunits).find(vtu => {
        return vtGuid === vtu.valueType?.guid && uGuid === vtu.unit?.guid;
      })
    }

    // search for existing MS or create new
    let measurementSystem = find_matching_ms(selectedMSA.guid);
    if (!measurementSystem) {
      let coordSys = find_matching_cs(selectedMSA.axis);
      measurementSystem = this.createNewMeasurementSystem(selectedMSA.name, coordSys?.guid, selectedMSA);
    }

    // search for existing VTU or create new
    let vtu = find_matching_vtu(valueType.guid, selectedUnit.guid);
    if (!vtu) {
      vtu = this.createNewValueTypeUnit(baseName, valueType, selectedUnit);
    }

    const newMa = this.createNewMeasurementAxis(baseName, obsGuid, selectedMSA, vtu);

    const measurement = {
      guid: createPhenomGuid(),
      name: `${baseName}_Meas`,
      xmiType: "logical:Measurement",
      description: "",
      measurementSystem: isPhenomGuid(measurementSystem.guid) ? measurementSystem : measurementSystem.guid,
      measurementAxis: [ newMa ],
      realizes: obsGuid,
      deconflictName: true,
    }

    addNewMeasurement(measurement);
    return measurement;
  }

  /**
   * 
   * @param {string} baseName 
   * @param {string} primitiveType 
   * @param {string} measGuid 
   */
  createNewPlatformType = (baseName, primitiveType, measGuid) => {
    const { addNewPlatformType } = this.props;
    const pt = {
      guid: createPhenomGuid(),
      name: `${baseName}_PT`,
      xmiType: primitiveType,
      realizes: measGuid,
      deconflictName: true,
    }

    addNewPlatformType(pt);
    return pt;
  }

  /**
   * 
   * @param {string} baseName 
   * @param {object} valueType 
   * @param {object} unit 
   */
  createNewValueTypeUnit = (baseName, valueType, unit) => {
    const { addNewValueTypeUnit } = this.props;

    const vtu = {
      guid: createPhenomGuid(),
      name: `${baseName}_VTU`,
      xmiType: "logical:ValueTypeUnit",
      valueType,
      unit,
      deconflictName: true,
    }

    addNewValueTypeUnit(vtu);
    return vtu;
  }

  /**
   * 
   * @param {string} baseName 
   * @param {string} obsGuid 
   * @param {object} msa 
   * @param {object} vtu 
   */
  createNewMeasurementAxis = (baseName, obsGuid, msa, vtu) => {
    return {
      guid: createPhenomGuid(),
      name: `${baseName}__MeasAxis`,
      xmiType: "logical:MeasurementAxis",
      realizes: obsGuid,
      measurementSystemAxis: msa,
      valueTypeUnit: [ vtu ],
      deconflictName: true,
    }
  }

  /**
   * 
   * @param {string} baseName 
   * @param {string} csGuid 
   * @param {object} msa 
   */
  createNewMeasurementSystem = (baseName, csGuid, msa) => {
    const { addNewMeasurementSystem } = this.props;

    const newMs = {
      guid: createPhenomGuid(),
      name: `${baseName}_MeasSys`,
      xmiType: "logical:MeasurementSystem",
      coordinateSystem: csGuid,
      measurementSystemAxis: [ msa ],
      deconflictName: true,
    }

    addNewMeasurementSystem(newMs);
    return newMs;
  }

  createNewChar = () => {
    const { all_measurements, all_platformTypes } = this.props;

    // Identifier
    const obsGuid = "EAID_B14C698C_13F8_434c_9D67_54CF514CA74E";
    
    // Find a non-IdlStruct measurement
    const measurement = Object.values(all_measurements).find(meas => {
      let measAxis = meas.measurementAxis[0];
      let measSysAxis, vtus = [];

      if (measAxis) {
        measSysAxis = measAxis.measurementSystemAxis;
        vtus = measAxis.valueTypeUnit;
      }

      if (!vtus.length && measSysAxis) {
        vtus = measSysAxis.defaultValueTypeUnit;
      }

      return meas.realizes === obsGuid && meas.measurementAxis.length === 1 && vtus.length === 1; 
    })

    // Find a PT
    const platformType = Object.values(all_platformTypes).find(pt => {
      return pt.realizes === measurement?.guid;
    })

    // Create path
    const { projChar, composition } = this.createNewPathAttributes(measurement?.guid);

    const char = {
      guid: createPhenomGuid(),
      rolename: "",
      xmiType: "platform:CharacteristicProjection",
      descriptionExtension: "",
      platformType: platformType?.guid,
      measurement: measurement?.guid || "",
      projectedCharacteristic: projChar,
      path: composition.guid,
      pathPairs: [ composition ],
      children: [],
      attributeKind: "privatelyScoped",
      optional: "false",
      deconflictName: true,
      demoSpecificChar: true,         // show all MSA only when user clicks "add new field". when searching for measurement, allow observable change
    }

    return char;
  }

  createNewPathAttributes = (measGuid) => {
    const { all_measurements } = this.props;
    const measurement = all_measurements[measGuid];
    const obsGuid = measurement?.realizes;
    const baseName = this.generateBaseName(measGuid);
    const projChar = this.createNewEntity(baseName);
    const composition = this.createNewComposition(baseName, obsGuid, projChar.guid);

    return {
      projChar,
      composition,
    }
  }

  /**
   * 
   * @param {string} baseName 
   */
  createNewEntity = (baseName) => {
    return {
      guid: createPhenomGuid(),
      name: `${baseName}_Ent`,
      xmiType: "conceptual:Entity",
      deconflictName: true,
    }
  }

  /**
   * 
   * @param {string} baseName 
   * @param {string} obsGuid 
   * @param {string} entGuid 
   */
  createNewComposition = (baseName, obsGuid, entGuid) => {
    return {
      guid: createPhenomGuid(),
      xmiType: "conceptual:Composition",
      rolename: `${baseName}_Comp`,
      type: obsGuid,
      parent: entGuid,
      deconflictName: true,
    }
  }

  generateBaseName = (measGuid) => {
    const { all_measurements } = this.props;
    const measurement = all_measurements[measGuid];

    if (!measurement) {
      return "Generated";
    }

    const obsGuid = measurement.realizes;
    const measAxis = measurement.measurementAxis[0];
    const measSysAxis = measAxis.measurementSystemAxis;
    const vtu = measAxis.valueTypeUnit[0] || measSysAxis.defaultValueTypeUnit[0];
    const valueType = vtu.valueType;
    const unit = vtu.unit;
    const obsLeaf = NavTree.getLeafNode(obsGuid);
    const obsName = obsLeaf ? obsLeaf.getName() : "Obs";
    return `${obsName}_${valueType.name}_${unit.name}`;
  }

  /**
   * 
   * @param {string} charGuid 
   */
  removeChar = (charGuid) => {
    const deleteCharGuids = new Set(this.state.deleteCharGuids);
    if (!isPhenomGuid(charGuid)) {
      deleteCharGuids.add(charGuid);
    }

    const chars = [ ...this.state.chars ];
    const removeIdx = chars.findIndex(char => char.guid === charGuid);
    if (removeIdx > -1) {
      chars.splice(removeIdx, 1);
      this.setState({ chars, deleteCharGuids })
    }
  }

  replaceAllChars = (chars) => {
    const deleteCharGuids = new Set(this.state.deleteCharGuids);
    this.state.chars.forEach(char => {
      if (!isPhenomGuid(char.guid)) {
        deleteCharGuids.add(char.guid);
      }
    })

    this.setState({ chars, deleteCharGuids })
  }

  checkCharStatus = (char) => {
    const { all_measurements, all_platformTypes } = this.props;
    const measGuid = char.measurement;
    const ptGuid = char.platformType;
    const measurement = all_measurements[measGuid];
    const platformType = all_platformTypes[ptGuid];
    let measAxis, measSysAxis, vtus, error;

    if (measurement) {
      measAxis = measurement.measurementAxis[0];
    }

    if (measAxis) {
      measSysAxis = measAxis.measurementSystemAxis;
    }

    if ( measAxis && measSysAxis) {
      vtus = measAxis.valueTypeUnit || measSysAxis.defaultValueTypeUnit;
    }

    // ignore Nested Views for demo
    if (char.viewType) {
      error = "The characteristic is a referencing a Nested View. This is temporarily disabled.";
    }

    // ignore IDLStructs for demo
    if (platformType?.xmiType === "platform:IDLStruct") {
      error = "The characteristic is a referencing an IDLStruct. This is temporarily disabled.";
    }

    // ignore IDLStructs for demo
    if (platformType?.xmiType === "platform:Enumeration") {
      error = "The characteristic is a referencing an Enumeration. This is temporarily disabled.";
    }

    // realizes can be a Measurement, MA, or VTU
    const ptRealizes = all_measurements[platformType?.realizes];
    if (ptRealizes && ptRealizes.xmiType !== "logical:Measurement") {
      error = "The platform type is referencing an Measurement Axis / Value Type Unit. This is temporarily disabled.";
    }
    
    // ignore IDLStructs for demo
    if (measurement?.measurementAxis.length > 1) {
      error = "The characteristic is a referencing an IDLStruct. This is temporarily disabled.";
    }

    // ignore IDLStructs for demo
    if (vtus && vtus.length > 1) {
      error = "The characteristic is a referencing an IDLStruct. This is temporarily disabled.";
    }

    char.error = error;
  }

  reorder = (dataItem) => {
    const { draggingChar, chars } = this.state;
    if (draggingChar === dataItem) {
      return;
    }

    let reorderedChars = chars.slice();
    let prevIndex = reorderedChars.findIndex((p) => p === draggingChar);
    let nextIndex = reorderedChars.findIndex((p) => p === dataItem);
    let goingUp = nextIndex < prevIndex;

    reorderedChars.splice(prevIndex, 1);
    reorderedChars.splice(
      Math.max(nextIndex + (goingUp ? -1 : 0), 0),
      0,
      draggingChar || reorderedChars[0]
    )

    this.setState({ chars: reorderedChars });
  };

  renderHandleBarCell = (cellProps) => {
    const { isRightSide } = this.props;
    const char = cellProps.dataItem;

    if (!isRightSide) {
      return null;
    }

    return <td>
      <span className='fas fa-grip-dots-vertical'
            draggable
            style={{ cursor: "grab", fontSize: "15px" }}
            onDragStart={(e) => {
              const row = document.getElementById(char.guid);
              if (row) e.dataTransfer.setDragImage(row, 0, 0);
              this.setState({ draggingChar: char });
            }} />
    </td>
  }

  renderNameCell = (cellProps) => {
    const { isRightSide } = this.props;
    const char = cellProps.dataItem;

    return <td>
        <PhenomInput value={char.rolename}
                     disabled={!isRightSide}
                     spellCheck={false}
                     onChange={(e) => {
                       char.rolename = e.target.value;
                       this.forceUpdate();
                     }} />
      </td>
  }

  renderMSACell = (cellProps) => {
    const { all_measurements, list_measSysAxis, msa_conversions, isRightSide } = this.props;
    const char = cellProps.dataItem;
    const measGuid = char.measurement;
    const measurement = all_measurements[measGuid];
    const isNewMeas = isPhenomGuid(measGuid);
    let measAxis, conversionList = [];
    let selectedMSA = char.selectedMSA || {};

    if (measurement) {
      measAxis = measurement.measurementAxis[0];
    }

    if (!selectedMSA?.guid && measAxis) {
      selectedMSA = measAxis.measurementSystemAxis;
    }

    // demo specific char
    // -> User clicked the "add new field" button
    //    -> Show all MSAs
    //    -> When searching for a matching measurement, the obserable is allowed to change.
    if (char.demoSpecificChar) {
      conversionList = list_measSysAxis;

    } else if (selectedMSA?.guid) {
      conversionList = msa_conversions[selectedMSA.guid] || [];
    }

    return <td>
              <PhenomComboBox value={selectedMSA}
                              data={conversionList}
                              dataItemKey="guid"
                              textField="name"
                              disabled={!isRightSide}
                              onChange={(msa) => {
                                this.handleMSAChange(char, msa);
                              }}>
                  {isNewMeas &&
                    <span class="fas fa-exclamation-circle"
                          title="Match not found. New Measurement will be created"
                          style={{ fontSize: 16, alignSelf: "center", color: "hsl(var(--skayl-purple-hs) 65%)" }} />}
              </PhenomComboBox>
    </td>
  }

  renderUnitCell = (cellProps) => {
    const { all_measurements, unit_conversions, isRightSide } = this.props;
    const char = cellProps.dataItem;
    const measGuid = char.measurement;
    const measurement = all_measurements[measGuid];
    let measAxis, measSysAxis, vtu, conversionList = [];
    let selectedUnit = char.selectedUnit || {};

    if (measurement) {
      measAxis = measurement.measurementAxis[0];
    }

    if (measAxis) {
      measSysAxis = measAxis.measurementSystemAxis;
    }

    if (measAxis && measSysAxis) {
      vtu = measAxis.valueTypeUnit[0] || measSysAxis.defaultValueTypeUnit[0];
    }

    if (!selectedUnit?.guid && vtu) {
      selectedUnit = vtu.unit || {};
    }

    if (selectedUnit?.guid) {
      conversionList = unit_conversions[selectedUnit.guid] || [];
    }

    let isNewVtu = isPhenomGuid(vtu?.guid);

    return <td key={char.guid}>
              <PhenomComboBox value={selectedUnit}
                              data={conversionList}
                              dataItemKey="guid"
                              textField="name"
                              disabled={!isRightSide}
                              onChange={(unit) => {
                                char["selectedUnit"] = unit;
                                this.changeCharDetails(char);
                              }}>
                  {isNewVtu &&
                    <span class="fas fa-exclamation-circle"
                          title="Match not found. New ValueTypeUnit will be created"
                          style={{ fontSize: 16, alignSelf: "center", color: "hsl(var(--skayl-purple-hs) 65%)" }} />}
              </PhenomComboBox>
    </td>
  }

  renderPrimitiveCell = (cellProps) => {
    const { all_platformTypes, primitiveTypeList, isRightSide } = this.props;
    const char = cellProps.dataItem;
    const ptGuid = char.platformType;
    const platformType = all_platformTypes[ptGuid];
    const isNewPT = isPhenomGuid(ptGuid);
    let primitiveText = "";

    if (char.selectedPrimitiveType) {
      primitiveText = splitXmiType(char.selectedPrimitiveType);

    } else if (platformType) {
      primitiveText = splitXmiType(platformType.xmiType);
    }

    return <td>
              <PhenomComboBox value={primitiveText}
                        data={primitiveTypeList}
                        dataDisabled={["IDLArray", "IDLSequence", "BoundedString", "BoundedWString", "CharArray", "Fixed", "WCharArray", "BoundedString", "Enumeration", "IDLStruct"]}
                        disabled={!isRightSide}
                        onChange={(primitive) => {
                          char["selectedPrimitiveType"] = `platform:${primitive}`;
                          this.changeCharDetails(char);
                        }}>
                  {isNewPT &&
                    <span class="fas fa-exclamation-circle"
                          title="Match not found. New PlatformType will be created"
                          style={{ fontSize: 16, alignSelf: "center", color: "hsl(var(--skayl-purple-hs) 65%)" }} />}
              </PhenomComboBox>
    </td>
  }

  renderDefaultValueCell = (cellProps) => {
    const { all_platformTypes } = this.props;
    const char = cellProps.dataItem;
    const ptGuid = char.platformType;
    const platformType = all_platformTypes[ptGuid];
    let primitiveType = char?.selectedPrimitiveType || platformType?.xmiType || "";

    let defaultValueInputType = "text";
    if (["platform:Short", "platform:UShort", "platform:Long", "platform:ULong", "platform:LongLong", "platform:ULongLong", "platform:Float", "platform:Double", "platform:LongDouble"].includes(primitiveType)) {
      defaultValueInputType = "number";
    }

    return <td>
      <PhenomInput value={this.extractDefaultValue(char.descriptionExtension)}
                  type={defaultValueInputType}
                  spellCheck={false}
                  onChange={(e) => {
                    char.descriptionExtension = this.formatDefaultValue(char, e.target.value);
                    this.forceUpdate();
                  }} />
    </td>
  }

  renderTrashCell = (cellProps) => {
    const { isRightSide } = this.props;
    const char = cellProps.dataItem;

    if (!isRightSide) {
      return null;
    }

    return <td>
      <Button icon="trash"
              onClick={(e) => {
                e.stopPropagation();
                this.removeChar(char.guid)
              }} />
    </td>
  }

  rowRender = (_, props) => {
    const { isRightSide, selectedSemantic, setActiveSemantic } = this.props;
    const { selectedChar } = this.state;
    const char = props.dataItem;

    const classes = [];
    if (char === selectedChar) {
      classes.push("selected");
    }

    if (char.path === selectedSemantic) {
      classes.push("matched");
    }

    if (char.error) {
      return <tr>
        <td colSpan={5}>{ char.error }</td>
      </tr>
    }

    return <tr id={char.guid}
               className={classes.join(" ")}
               onDragOver={(e) => {
                isRightSide && this.reorder(char);
               }}
               onClick={() => {
                this.setState({ selectedChar: char },
                  () => !isRightSide && setActiveSemantic(char.path))
               }}>
      { this.renderHandleBarCell(props) }
      { this.renderNameCell(props) }
      { this.renderMSACell(props) }
      { this.renderUnitCell(props) }
      { this.renderPrimitiveCell(props) }
      { this.renderDefaultValueCell(props) }
      { this.renderTrashCell(props) }
    </tr>
  }

  render() {
    const { isRightSide } = this.props;
    const { chars } = this.state;

    return (
      <Grid className='cadet-k-grid view-mod-grid'
            data={chars}
            rowRender={this.rowRender}
            reorderable>
        <GridNoRecords>No Data Is Available</GridNoRecords>
        {isRightSide &&
          <GridColumn title="" width="30" />}
        <GridColumn title="Field Name" field="rolename" />
        <GridColumn title="Measurement System Axis" />
        <GridColumn title="Unit" field="unit" width="120" />
        <GridColumn title="Primitive" field="primitive" width="120" />
        <GridColumn title="Default Value" width="220" />
        {isRightSide &&
          <GridColumn title="" width="45" />}
      </Grid>
    )
  }
}


export default ViewModification;
