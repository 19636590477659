import React from 'react';
import { isStormData } from '../../../util';


class SideMessagePortDetail extends React.Component {

  render() {
    const { $app, msgPortData, phenomId } = this.props;

    if (!isStormData(msgPortData)) {
      return null;
    }

    const messageTypeData = $app.getDiagramStormData(msgPortData.getAttr("messageType"));
    const requestTypeData = $app.getDiagramStormData(msgPortData.getAttr("requestType"));
    const responseTypeData = $app.getDiagramStormData(msgPortData.getAttr("responseType"));

    return <div className="storm-side-port-detail">
      <div>
        <label>Message Port</label>
        <div id={phenomId.genPageId(msgPortData.getGuid(), "name")}>
          { msgPortData.getName() }
        </div>
      </div>

      <div>
        <label>Messaging Pattern</label>
        <div id={phenomId.genPageId(msgPortData.getGuid(), "messagingPattern")}>
          { msgPortData.getAttr("messagingPattern") }
        </div>
      </div>

      {messageTypeData && <div>
        <label>Message Type</label>
        <div id={phenomId.genPageId(messageTypeData.getGuid(), "name")}>
          { messageTypeData.getName() }
        </div>
      </div>}

      {requestTypeData && <div>
        <label>Request Type</label>
        <div id={phenomId.genPageId(requestTypeData.getGuid(), "name")}>
          { requestTypeData.getName() }
        </div>
      </div>}

      {responseTypeData && <div>
        <label>Response Type</label>
        <div id={phenomId.genPageId(responseTypeData.getGuid(), "name")}>
          { responseTypeData.getName() }
        </div>
      </div>}
    </div>
  }
}


export default SideMessagePortDetail;