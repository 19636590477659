import React from 'react'
import { BranchLeaf, ProjectLeaf, FeauxLeaf } from '../../../tree/leaf/ModelLeaf';
import imgModel from "../../../../images/icons/model_manager.png"
import imgPubModel from "../../../../images/icons/published_model.png"
import imgProject from "../../../../images/icons/project_manager_gray.png"
import { Checkbox } from '@progress/kendo-react-inputs';
import { createLeafDomId } from '../model_manage';
import { Button } from '@progress/kendo-react-buttons';
import PhenomdId from '../../../../requests/phenom-id';
import { cloneDeep } from 'lodash';
import loadingIcon from "../../../../images/Palette Ring-1s-200px.gif";


class ModelTree extends React.Component {

  highlightedLeaves = new Set();

  defaulPrevSearch = {
    type: "",
    text: "",
    leaf: null,
  }

  prevSearch = cloneDeep(this.defaulPrevSearch);

  state = {
    leaves: [],
    isExpanded: true,
    isSortByName: null,
    isSortByDate: true,
  }
  
  componentDidMount() {
    this.initLeaves();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.index !== this.props.index) {
      this.initLeaves();
    }
    if (prevState.isSortByDate !== this.state.isSortByDate){
      if(this.state.isSortByDate === null){
        return;
      }
      this.sortByDate();
    }
    if (prevState.isSortByName !== this.state.isSortByName){
      if(this.state.isSortByName === null){
        return;
      }
      this.sortByName();
    }
  }

  initLeaves = () => {
    const leaves = [];

    for (let id in this.props.index) {
      // find leaf nodes without a parent (root nodes)
      const leaf = this.props.index[id];
      if (leaf.getParentLeaf()) continue;
      leaves.push(leaf);
    }

    if(this.state.isSortByDate) {
      leaves.sort((a, b) => 
        (a.getDateCreated() < b.getDateCreated()) ? 1 : -1);
    }

    this.setState({ leaves });
  }

  handleCollapse = () => {
    this.setState({ isExpanded: !this.state.isExpanded }, () => {
      this.props.onCollapse && this.props.onCollapse(this.state.isExpanded)
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.searchLeafByName(e.target.search.value)
    this.props.onSearch && this.props.onSearch();   // this will trigger an update on sibling tree
  }

  resetPrevSearch = () => {
    this.prevSearch = cloneDeep(this.defaulPrevSearch);
  }

  // onExpandLeaf = (leaf) => {
  //   if (leaf instanceof RootLeaf === false) return;
  //   if (leaf.isExpanded()) {
  //     leaf.setExpanded(false);

  //     // on collapse - remove children from selection list
  //     const stack = [...leaf.getModelLeaves()];
  //     while (stack.length) {
  //       const modelLeaf = stack.pop();
  //       modelLeaf.getChildrenLeaves().forEach(c => stack.push(c));
  //       this.deselectLeaf(modelLeaf);
  //     }

  //   } else {
  //     leaf.setExpanded(true);
  //   }
  // }

  // ==========================================================================================================
  // HIGHLIGHT LEAF
  // ==========================================================================================================
  clearHighlightedLeaves = () => {
    // this.highlightedLeaves.forEach(leaf => leaf.setHighlighted(false));
    this.highlightedLeaves = new Set();
  }

  highlightLeaf = (leaf) => {
    this.highlightedLeaves.add(leaf);

    if (this.props.treeType === "projects") {
      leaf.getProjectLeaf() && leaf.getProjectLeaf().setExpanded(true);
    } else {
      leaf.getBranchLeaf() && leaf.getBranchLeaf().setExpanded(true);
    }
  }

  dehighlightLeaf = (leaf) => {
    this.highlightedLeaves.delete(leaf);
  }

  // ==========================================================================================================
  // SEARCH
  // ==========================================================================================================
  scrollToLeaf = (leaf) => {
    if (!leaf) return;
    const domID = `${this.props.id}-${createLeafDomId(leaf)}`;
    const domLeaf = document.getElementById(domID);
    domLeaf && domLeaf.scrollIntoView();
  }

  searchLeafByName = (text="") => {
    if (typeof text !== 'string') return;
    text = text.trim();

    // 0) No input or user cleared the input - clear data
    if (!text) {
      this.clearHighlightedLeaves();
      this.prevSearch.text = "";
      this.prevSearch.leaf = null;
      return this.forceUpdate();
    }

    // 1) User entered the same search parameters - scroll to next leaf
    if (text === this.prevSearch.text) {
      const highlighted = [...this.highlightedLeaves];
      const currIndex = highlighted.findIndex(leaf => leaf === this.prevSearch.leaf);
      const nextLeaf = highlighted[(currIndex + 1) % highlighted.length];
      if (nextLeaf) {
        this.prevSearch.leaf = nextLeaf;
        this.scrollToLeaf(nextLeaf);
      }
      return this.forceUpdate();
    }

    // 2) Search for a match
    //    searches for a matching substring in the leaf's name
    const regex = new RegExp(`.*(${text}).*`, 'i');
    this.clearHighlightedLeaves();

    for (let id in this.props.index) {
      const leaf = this.props.index[id];
      leaf && leaf.getName().match(regex) && this.highlightLeaf(leaf);

      leaf.getModelLeaves().forEach(modelLeaf => {
        modelLeaf.getName().match(regex) && this.highlightLeaf(modelLeaf);
      })
    }

    // assign values for step 1
    let [firstLeaf] = this.highlightedLeaves;
    if (firstLeaf) {
      this.prevSearch.leaf = firstLeaf;
      this.scrollToLeaf(firstLeaf);
    }
    
    this.prevSearch.text = text;
    this.forceUpdate();
  }

  // ==========================================================================================================
  // SORT
  // ==========================================================================================================
  sortByDate = () => {
    const leaves = [...this.state.leaves];

    //reset icon postition for filter By Name
    this.setState({isSortByName: null})

    // Sort date ascending
    if(this.state.isSortByDate){
      leaves.sort((a, b) => 
        (a.getDateCreated() < b.getDateCreated()) ? 1 : -1);
    }

    // Sort date descending
    else{
      leaves.sort((a, b) => 
        (a.getDateCreated() > b.getDateCreated()) ? 1 : -1);
    }

    this.setState({leaves: leaves});
  }

  sortByName = () => {
    const leaves = [...this.state.leaves];

    //reset icon position for filter By Date
    this.setState({isSortByDate: null})

    // Sort A-Z
    if(this.state.isSortByName){
      leaves.sort((a, b) => 
        (a.getName().toLowerCase() > b.getName().toLowerCase()) ? 1 : -1);
    }
    // Sort Z-A
    else if (this.state.isSortByName === false){
      leaves.sort((a, b) => 
        (a.getName().toLowerCase() < b.getName().toLowerCase()) ? 1 : -1);
    }

    this.setState({leaves: leaves});
  }

  changeNameClass = () => {
    if(this.state.isSortByName === true){
      return "fas fa-sort-alpha-down sort-button-activated"
    }
    else if (this.state.isSortByName === false){
      return "fas fa-sort-alpha-up sort-button-activated"
    }
    else {
      return "fas fa-sort-alpha-down sort-button"
    }
  }

  changeDateClass = () => {
    if(this.state.isSortByDate === true){
      return "fa-regular fa-calendar-arrow-up sort-button2-activated"
    }
    else if (this.state.isSortByDate === false){
      return "fa-regular fa-calendar-arrow-down sort-button2-activated"
    }
    else {
      return "fa-regular fa-calendar-arrow-up sort-button2"
    }
  }
  render() {
    const phenomId = new PhenomdId(this.props.id);
    const { isExpanded } = this.state;
    const title = this.props.title || "";
    return <div id={this.props.id} className="tree-content vertical">
            <header>
              <div className="tree-left-header">
                
              <span className={"fas " + (isExpanded ? "fa-caret-down" : "fa-caret-right")} 
                    id={phenomId.gen("", "caret")}
                    onClick={this.handleCollapse} />

                <h2>{ title }</h2>

                {isExpanded && this.props.onAddNew &&
                  <button className="fa fa-plus" onClick={this.props.onAddNew} title={`Create new ${title}`}
                          id={phenomId.gen("new-project", "button")}
                          style={{ width: 23, height: 23 }} /> }
                
                {isExpanded && this.props.onCreateFilteredProject && false &&
                  <button title="Create new filtered project"
                          onClick={this.props.onCreateFilteredProject}>
                    <span className="custom-modeltree-button-icon img-icon icon-create-filtered-project"
                          style={{ width: 15, height: 15 }} />
                  </button> }
              </div>

              {isExpanded &&
              <form className="tree-search" onSubmit={this.handleSubmit}>
                  <input name="search"
                         id={(this.props.id + "-search-bar")}
                         autocomplete="off"
                         placeholder="Search..." />
                         
                  <button className="fas fa-search"
                          id={(this.props.id + "-submit-btn")}
                          style={{ background: "transparent", border: "none" }} 
                          />

                  <div className="model-tree-sort-btns">
                    <button className={this.changeNameClass()}
                            title="Sort By Name"
                            id={(this.props.id + "-sort-alphabetical")}
                            onClick={() => this.setState((prevState) => ({ isSortByName: !prevState.isSortByName }))}
                         />

                    <button className={this.changeDateClass()}
                            title="Sort By Creation Date"
                            id={(this.props.id + "-sort-date")}
                            onClick={() => this.setState((prevState) => ({ isSortByDate: !prevState.isSortByDate }))}
                         />
                  </div>

              </form> }

            </header>

            <div className="tree-content-wrapper">
              {this.props.loading && !this.state.leaves.length ? 
                    <div>
                        <img id="loading-spinner"
                            style={{ width: 70, height: 70 }}
                            src={loadingIcon} /> 
                    </div>
                      
                : isExpanded &&
                    <ul>
                      {this.state.leaves.map(leaf => {
                        return <ModelTreeLeaf id={this.props.id}
                                              key={ leaf.getId() }
                                              leaf={ leaf }
                                              config={ leaf.getConfig() }     // when config "changes" it will trigger a rerender with React.memo
                                              activeProjectId={ this.props.activeProjectId }
                                              useCheckbox={ this.props.useCheckbox }
                                              highlightedLeaves={ this.highlightedLeaves}
                                              onSelect={ this.props.onSelect }
                                              onContextMenu={ this.props.onContextMenu }
                                              isSortByDate={ this.state.isSortByDate }
                                              isSortByName={ this.state.isSortByName } />
                      })}
                    </ul> }
            </div>
          </div>
  }
}





class ModelTreeLeaf extends React.Component {
  // toggleExpanded = () => {
  //   const { leaf } = this.props;

  //   leaf.toggleExpanded()
  // }

  isHighlighted = () => {
    const { highlightedLeaves, leaf } = this.props;
    return highlightedLeaves.has(leaf);
  }

  handleSelect = (e) => {
    e.preventDefault();
    const { leaf, onSelect } = this.props;
    onSelect(leaf);
  }

  /*
   * @leaves - An array of model/project/feaux leaves
   */
  sortLeavesByName = ( leaves ) => {
    let sortedLeaves = [...leaves];

    // a-z
    if(this.props.isSortByName){
      sortedLeaves = sortedLeaves.sort((a, b) => 
      a.getName().toLowerCase().localeCompare(b.getName(), "en", {sensitivity: "base"}));
    }
    // z-a
    else if (this.props.isSortByName === false){
      sortedLeaves = sortedLeaves.sort((a, b) => 
      b.getName().toLowerCase().localeCompare(a.getName(), "en", {sensitivity: "base"}));
    }

    return sortedLeaves;
  }

  /*
   * @leaves - An array of model/project/feaux leaves
   */
  sortLeavesByDate = ( leaves ) => {
    let sortedLeaves = [...leaves];

    // check if leaf is published, set branchCreated to publishedDate for sort
    sortedLeaves.forEach( (leaf) => {
      if(leaf.isPublished()){
        leaf.data.dateCreated = leaf.data.publishedDate;
      }})

    // newest-oldest
    if(this.props.isSortByDate){
      sortedLeaves = sortedLeaves.sort((a, b) => 
      (a.getDateCreated() > b.getDateCreated()) ? 1 : -1);
    }

    // oldest-newest
    if(this.props.isSortByDate === false){
      sortedLeaves = sortedLeaves.sort((a, b) => 
      (a.getDateCreated() < b.getDateCreated()) ? 1 : -1);
    }

    return sortedLeaves;
  }

  sortLeavesByDateReverse = ( leaves ) => {
    let sortedLeaves = [...leaves];

    // check if leaf is published, set branchCreated to publishedDate for sort
    sortedLeaves.forEach( (leaf) => {
      if(leaf.isPublished()){
        leaf.data.dateCreated = leaf.data.publishedDate;
      }})

    // newest-oldest
    if(this.props.isSortByDate){
      sortedLeaves = sortedLeaves.sort((a, b) => 
      (a.getDateCreated() < b.getDateCreated()) ? 1 : -1);
    }

    // oldest-newest
    if(this.props.isSortByDate === false){
      sortedLeaves = sortedLeaves.sort((a, b) => 
      (a.getDateCreated() > b.getDateCreated()) ? 1 : -1);
    }

    return sortedLeaves;
  }

  renderCaret() {
    const phenomId = new PhenomdId(this.props.id);
    const { leaf } = this.props;
    // if every child is "hidden" then return null (an empty array will return a result of true)
    if (leaf.getModelLeaves().every(c => c.isFilteredOut()) && (leaf instanceof FeauxLeaf && leaf.getChildrenLeaves().length == 0)) return null;
    const classes = ["tree-node-caret", "fas"];
    if (leaf.isExpanded()) {
      classes.push("fa-caret-down");
    } else {
      classes.push("fa-caret-right");
    }

    return <span className={classes.join(" ")}
                 id={phenomId.gen(this.props.leaf.getName(), "caret")}
                 onClick={() => {
                  leaf.toggleExpanded();
                  this.forceUpdate();
                 }} />
  }

  renderProjectIcon() {
    const { leaf, activeProjectId } = this.props;

    if (leaf.getId() === activeProjectId) {
      return <img className="img-icon" src={imgProject} />
    } else {
      return <span className="fa-icon far fa-folder" />
    }
  }

  renderChildren() {
    const { leaf, isSortByDate, isSortByName } = this.props;
    const childrenLeaves = leaf.getChildrenLeaves();
    let sortedModelLeaves = [... childrenLeaves];

    if (!leaf.isExpanded() || childrenLeaves.length < 1) return null;

    // sort state handling
    if(this.props.isSortByName || this.props.isSortByName === false){
      sortedModelLeaves = this.sortLeavesByName(childrenLeaves);
    }
    if(this.props.isSortByDate || this.props.isSortByDate === false){
      sortedModelLeaves = this.sortLeavesByDate(childrenLeaves);
    }

    return <ul>
      {sortedModelLeaves.map(childLeaf => {
                  return <ModelTreeLeaf { ...this.props }
                              key={ childLeaf.getId() }
                              leaf={ childLeaf }
                              config={ childLeaf.getConfig() } />
      })}
    </ul>
  }

  renderModelLeaves() {
    const { leaf, isSortByDate, isSortByName } = this.props;
    const modelLeaves = leaf.getModelLeaves();
    let sortedModelLeaves = [... modelLeaves];

    if (!leaf.isExpanded() || modelLeaves.length < 1) return null;

    // sort state handling
    if(this.props.isSortByName || this.props.isSortByName === false){
      sortedModelLeaves = this.sortLeavesByName(modelLeaves);
    }
    if(this.props.isSortByDate || this.props.isSortByDate === false){
      if (this.props.id === "project-files"){
        sortedModelLeaves = this.sortLeavesByDateReverse(modelLeaves);
      } else {
        sortedModelLeaves = this.sortLeavesByDate(modelLeaves);
      }
    }

    return <ul>
              {sortedModelLeaves.map(childLeaf => {
                return <ModelTreeLeaf { ...this.props }
                                      key={ childLeaf.getId() }
                                      leaf={ childLeaf }
                                      config={ childLeaf.getConfig() } />
              })}
            </ul>
  }

  renderBranchLeaves() {
    const { leaf, isSortByDate, isSortByName } = this.props;
    const childrenLeaves = leaf.getChildrenLeaves();
    let sortedModelLeaves = [... childrenLeaves];

    if (!leaf.isExpanded() || childrenLeaves.length < 1) return null;

    // sort state handling
    if(this.props.isSortByName || this.props.isSortByName === false){
      sortedModelLeaves = this.sortLeavesByName(childrenLeaves);
    }
    if(this.props.isSortByDate || this.props.isSortByDate === false){
      sortedModelLeaves = this.sortLeavesByDateReverse(childrenLeaves);
    }

    return <ul>
      {sortedModelLeaves.map(childLeaf => {
        return <ModelTreeLeaf { ...this.props }
                              key={ childLeaf.getId() }
                              leaf={ childLeaf }
                              config={ childLeaf.getConfig() } />
      })}
    </ul>

  }

  render() {
    const { id, leaf, activeProjectId, useCheckbox, onSelect, onContextMenu } = this.props;

    const classes = ["tree-node"];
    if (leaf.isSelected() && !useCheckbox) {
      classes.push("selected");
    }

    if (this.isHighlighted()) {
      classes.push("highlighted");
    }

    // -----------
    // BRANCH LEAF
    // -----------
    if (leaf instanceof BranchLeaf) {
      return <li>
              <span id={ `${id}-${createLeafDomId(leaf)}` } 
                    className="tree-node"
                    onContextMenu={(e) => onContextMenu && onContextMenu(e, leaf)}>
                { this.renderCaret() }
                <span className="fa-icon fas fa-code-branch" />
                { leaf.getName() }
              </span>
              { this.renderModelLeaves() }
              { this.renderBranchLeaves() }
            </li>

    // ------------
    // PROJECT LEAF
    // ------------
    } else if (leaf instanceof ProjectLeaf) {      
      return <li>
              <span id={ `${id}-${createLeafDomId(leaf)}` }
                    className={classes.join(" ")}
                    onClick={this.handleSelect}
                    onContextMenu={(e) => onContextMenu && onContextMenu(e, leaf)}>
                { this.renderCaret() }
                { this.renderProjectIcon() }
                { activeProjectId === leaf.getId() ? <b><i>{ leaf.getName() }</i></b> : leaf.getName() }
                { leaf.isPublished() &&
                  <i>{`[pub. ${leaf.getPublishedDateShort()}]`}</i> }
              </span>
              { this.renderModelLeaves() }
              { this.renderChildren() }
            </li>

    // ----------
    // FEAUX LEAF
    // ----------
    } else if (leaf instanceof FeauxLeaf) {
      return <li>
              <span id={ `${id}-${createLeafDomId(leaf)}` }
                    className={classes.join(" ")}
                    onContextMenu={(e) => onContextMenu && onContextMenu(e, leaf)}>
                { this.renderCaret() }
                <span className="fa-icon fas fa-folder" />
                { <i>{ leaf.getName() }</i> }
              </span>
              { this.renderChildren() }
            </li>

    // ----------
    // MODEL LEAF
    // ----------
    } else {
      let style;
      if (!leaf.isSelectable()) {
        style = {
          textDecoration: "line-through"
        }
      }

      // instanceof ModelLeaf
      return <li>
              <span id={ `${id}-${createLeafDomId(leaf)}` }
                    className={classes.join(" ")}
                    style={style}
                    onClick={this.handleSelect}
                    onContextMenu={(e) => onContextMenu && onContextMenu(e, leaf)}>

                {useCheckbox &&
                <Checkbox checked={leaf.isSelected()}
                          onClick={this.handleSelect}
                          label="" />  }

                <img className="img-icon" src={leaf.isPublished() ? imgPubModel : imgModel} />
                { leaf.getName() }
                { leaf.isPublished() &&
                  <i>{`[pub. ${leaf.getPublishedDateShort()}]`}</i> }
              </span>
            </li>
    }
  }
}



export default ModelTree;
