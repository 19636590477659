import userReducer from './userReducer'
import appReducer from './appReducer'
import notificationReducer from './notificationReducer'
import navTreeReducer from './navTreeReducer'


export default (state = {}, action) => {
  return {
    app: appReducer(state.app, action),
    navTree: navTreeReducer(state.navTree, action),
    notification: notificationReducer(state.notification, action),
    user: userReducer(state.user, action),
  }
}



export const updateState = (prevState, data) => ({
  ...prevState,
  ...data,
})