import React, {Component} from "react";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import PhenomId from "../../../requests/phenom-id";
import NavTree from "../../tree/NavTree";

export class MergeErrorConfirm extends Component{
  constructor(props){
    super(props);
    MergeErrorConfirm.__singleton = this;

    this.state = {
      visible: false,
      selectAllFunc: null,
      selectSingleFunc: null,
      neededNodes: null,
    }
    this.invariantErrorCodes = {
        "UNREACHABLE_ELEMENT": "has been merged without its parent. ",
        "unreachable": "is missing a parent after the merge. ",
        "CHILD_ABSENT": "has been merged without its parent. ",
        "DANGLING_REALIZATION": "has been merged without the thing it realizes. ",
        "DANGLING_TYPE": "has been merged without the thing it is typed as. ",
        "DANGLING_VIEW_TYPE": "has been merged without the thing it is typed as. ",
        "MISSING_PATH_HOP": "has been merged without a piece of its path. ",
        "references": "is pointing to something that has not been merged. ",
    };
  }

  static show(neededNodes, selectAllFunc, selectSingleFunc){
    MergeErrorConfirm.__singleton._show()
    MergeErrorConfirm.__singleton.setState({
      neededNodes: neededNodes,
      selectAllFunc: selectAllFunc,
      selectSingleFunc: selectSingleFunc
    })
  }

  static hide(){

  }

  setStateFromResponse(response){

  }

  _show(){
    this.setState({visible: true})
  }

  _hide(){
    this.setState({visible: false})
  }

  confirmAction(){
    this.state.selectAllFunc()
  }

  render(){
    const phenomId = new PhenomId("merge-error-confirm",this.props.idCtx);
    return (<div id={phenomId.gen("","wrapper")}> {this.state.visible && <Dialog title={"Merge Assistance"} className="dialog-80vh" onClose={() => {this._hide()}}>
      <table>
        <tbody>
        {this.state.neededNodes != null && this.state.neededNodes.map((node, i) => {
          return<tr style={node.failure ? {backgroundColor: "#ffff99"} : null} id={phenomId.gen(["needed-nodes",i],"wrapper")}>
            <td>'{node.error_name}' {this.invariantErrorCodes[node.error_type]}</td>
            {node.failure
                ? <td>The needed content missing from merge request.</td>
                : node.deleted ? <td> This can be fixed by pulling deletions as deprecations. </td>
                : <td>
                    This can be fixed by adding&nbsp;
                    <button
                        id={phenomId.gen(i,"select-single-func-button")}
                        className="cadet-anchor normal-anchor"
                        onClick={() => this.state.selectSingleFunc(node.guid)}>
                        '{node.name}'
                    </button>
                    &nbsp;to the merge.
            </td>}
          </tr>
        })}
        </tbody>
      </table>
      <DialogActionsBar>
        <button className="k-button"
          id={phenomId.gen("init","cancel-button")}
          onClick={() => {
            this._hide()}}>Cancel
        </button>

        <button className="k-button k-primary"
          id={phenomId.gen("init","select-all-button")}
          onClick={() => {
            this.confirmAction();
            this._hide();}}>Select All Required Nodes
          </button>
      </DialogActionsBar>
      </Dialog>} </div>)
  }

}
