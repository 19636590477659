import React, {useState, useEffect} from "react";
import Draggable from "react-draggable";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import blankDiagram from "../../../../../images/blank_diagram.png";
import { PackageComboBox, PhenomInput, NodeComboBox } from "../../../../util/stateless";
import { useSelector } from "react-redux";



export default function SkratchpadSavePrompt({ selectedFile={}, data=[], dataGuidKey, dataNameKey, disabled, phenomId, redirectToUrl, onSelect, onSave, onBackSelectDiagram, loading, onPackageSelect, selectedPackage }) {
  const [inputValue, setInputValue] = useState("");
  const [packageValue, setPackageValue] = useState("");
  const diagramPackages = useSelector(state => state.navTree.nodesOfType["skayl:DiagramModel"] || {});

  useEffect(() => {
    if (selectedFile && typeof selectedFile[dataNameKey] === "string") {
      setInputValue(selectedFile[dataNameKey]);
    }    
  }, [selectedFile])

  const handleInputChange = (e) => {
    const ele = data.find(item => item[dataNameKey] === e.target.value);
    
    if (ele) {
      onSelect(ele);
    } else {
      onSelect({
        fileId:  null,
        fileName: e.target.value,
      })
    }

    setInputValue(e.target.value);
  }

  useEffect(() => {
    if (selectedPackage) {
      setPackageValue(selectedPackage["obj"]);
    }    
  }, [selectedPackage])

  const handlePackageChange = (e) => {
    const ele = diagramPackages.find(item => item?.guid === e.guid);

    onPackageSelect(ele.guid);
    setPackageValue(e.gud);
  }
  
  return <div className="diagram-save-as-prompt-content" id={phenomId.gen("save-prompt")}> 
            <div className="p-row row-no-gutter-row">
              <div className="p-col p-col-12" style={{paddingBottom: 4}}>Select a diagram:</div>

              <ul className="p-col p-col-6 p-no-gutter storm-prompt-box-200 storm-prompt-selection" id={phenomId.genPageId("selection-list")} style={{ maxWidth: 280 }}>
                {data.map((item) => {
                  return <li key={item[dataGuidKey]}
                            className="storm-prompt-selection-item"
                            style={{ backgroundColor: selectedFile && (item[dataGuidKey] === selectedFile[dataGuidKey]) ? "rgba(3, 15, 222, 0.5)" : null }}
                            onClick={() => onSelect(item)}>
                          {item[dataNameKey]}
                        </li>
                })}
              </ul>

              <div className="p-col p-col-6 p-col-fixed storm-prompt-box-200">
                <img className="storm-prompt-image"
                      src={( selectedFile && selectedFile.image) || blankDiagram} />
              </div>
            </div>

            <div className="p-row">
              <div className="p-col p-col-6 p-col-fixed">
                <div>
                  <label className="storm-prompt-label" style={{paddingBottom: 4}} htmlFor="diagram-name">Diagram name:</label>
                  <PhenomInput value={inputValue}
                               disabled={loading}
                               autoFocus={true}
                               id={phenomId.genPageId("name")}
                               onChange={handleInputChange}
                               onClickResetIcon={() => this.handleInputChange("")} />
                </div>
                <div className="storm-prompt-buttons">
                  <Button iconClass="fas fa-save fa-fw" 
                          title="Save"
                          disabled={disabled}
                          onClick={onSave}>Save</Button>
                </div>
              </div>

              <div className="p-col p-col-6 p-col-fixed">
                <div>
                  <label className="storm-prompt-label" style={{paddingBottom: 4}} htmlFor="diagram-name">Diagram name:</label>
                  <NodeComboBox id={phenomId.genPageId("parent")}
                                    xmiType="skayl:DiagramModel"
                                    placeholder="<Default>"
                                    selectedGuid={selectedPackage}
                                    autoComplete="off"
                                    onChange={handlePackageChange}
                                    />
                </div>
              </div>
            </div>
          </div>
}