import React from 'react'

const LinkIcon = (props) => {
  return <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
              width="475.000000pt" height="475.000000pt" viewBox="0 0 475.000000 475.000000"
              preserveAspectRatio="xMidYMid meet" {...props}>
          <g transform="translate(0.000000,475.000000) scale(0.100000,-0.100000)" fill="#aa91ab" stroke="none">
            <path d="M3315 4721 c-33 -9 -98 -37 -145 -61 -82 -42 -100 -58 -555 -514
                    -510 -510 -520 -521 -572 -681 -37 -114 -43 -280 -15 -391 31 -124 68 -192
                    121 -225 105 -67 240 -15 272 105 12 45 7 76 -31 181 -18 48 -21 74 -17 131
                    10 140 9 139 491 620 461 462 473 472 592 493 72 13 156 1 224 -34 29 -15 146
                    -123 336 -312 344 -341 349 -349 349 -503 0 -156 12 -140 -488 -643 -490 -492
                    -483 -487 -631 -495 -70 -3 -91 0 -151 23 -114 43 -191 33 -248 -34 -56 -66
                    -59 -152 -9 -222 53 -75 216 -129 391 -129 167 0 310 47 436 143 81 61 904
                    889 944 950 82 122 121 253 121 409 0 131 -24 230 -82 342 -39 74 -69 107
                    -343 384 -165 167 -326 323 -358 347 -69 52 -163 98 -245 119 -85 22 -306 21
                    -387 -3z"/>
            <path d="M3065 3321 c-57 -27 -1618 -1592 -1640 -1643 -8 -21 -15 -51 -15 -68
                    0 -36 23 -96 48 -123 48 -54 149 -73 209 -39 43 23 1605 1578 1633 1624 14 24
                    21 54 22 87 1 134 -134 219 -257 162z"/>
            <path d="M1322 2720 c-40 -11 -109 -38 -155 -61 -79 -41 -101 -62 -557 -518
                    -515 -515 -526 -528 -575 -684 -34 -107 -36 -318 -4 -423 30 -100 83 -193 154
                    -276 101 -119 565 -570 628 -611 134 -89 231 -117 407 -117 181 0 303 38 430
                    132 30 23 253 240 495 482 315 316 450 457 474 498 128 221 143 466 42 684
                    -46 97 -118 138 -216 120 -43 -8 -103 -59 -121 -103 -20 -46 -17 -111 7 -163
                    26 -57 38 -148 28 -212 -18 -110 -31 -126 -501 -594 -417 -416 -446 -443 -503
                    -464 -82 -31 -188 -31 -270 0 -55 21 -83 45 -358 319 -318 316 -336 339 -357
                    454 -13 67 -1 147 31 216 16 34 136 161 453 479 504 506 492 497 656 497 78 0
                    104 -4 145 -23 158 -74 302 34 271 202 -14 75 -92 131 -234 167 -101 26 -269
                    25 -370 -1z"/>
          </g>
        </svg>
}


export default LinkIcon;