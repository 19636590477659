import * as actionTypes from '../requests/actionTypes'

const initialState = {}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RECEIVE_CRITICAL_DETAILS:
      return { ...action.details };

    case actionTypes.SESSION_TOKEN_EXPIRED:
      return { sessionTokenExpired: true }

    case actionTypes.CLEAR_CRITICAL_DETAILS:
      return initialState;

    case actionTypes.UPDATE_USER_SETTINGS:
      // user was probably logged out - cannot update
      if (!state.userIdentity) return state;
      
      // do not mutate the state
      const settings = { ...state.userIdentity.settings, ...action.settings  };
      return { 
        ...state,
        userIdentity: { ...state.userIdentity, settings }
      }

    case actionTypes.UPDATE_BRANCH_NAME:
      if (!action.branchName) return state;
      return { 
        ...state,
        userIdentity: { ...state.userIdentity, branchName: action.branchName }
      }

    default:
      return state;
  }
}

export default userReducer;