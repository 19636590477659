import React, {useState, useEffect} from "react";
import Draggable from "react-draggable";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import blankDiagram from "../../../../../images/blank_diagram.png";




export default function SavePrompt({ selectedFile={}, data=[], dataGuidKey, dataNameKey, disabled, phenomId, redirectToUrl, onSelect, onSave, onBackSelectDiagram }) {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (typeof selectedFile[dataNameKey] === "string") {
      setInputValue(selectedFile[dataNameKey]);
    }    
  }, [selectedFile])

  const handleInputChange = (e) => {
    const ele = data.find(item => item[dataNameKey] === e.target.value);
    
    if (ele) {
      onSelect(ele);
    } else {
      onSelect({
        fileId:  null,
        fileName: e.target.value,
        Image: blankDiagram,
      })
    }

    setInputValue(e.target.value);
  }

  return <div className="storm-prompt-content" id={phenomId.gen("save-prompt")}> 
            <div className="p-row row-no-gutter-row">
              <div className="p-col p-col-12">Select a diagram:</div>

              <ul className="p-col p-col-6 p-no-gutter storm-prompt-box-200 storm-prompt-selection" id={phenomId.genPageId("selection-list")} style={{ maxWidth: 280 }}>
                {data.map((item) => {
                  return <li key={item[dataGuidKey]}
                            className="storm-prompt-selection-item"
                            style={{ backgroundColor: item[dataGuidKey] === selectedFile[dataGuidKey] ? "rgba(3, 15, 222, 0.5)" : null }}
                            onClick={() => onSelect(item)}>
                          {item[dataNameKey]}
                        </li>
                })}
              </ul>

              <div className="p-col p-col-6 p-col-fixed storm-prompt-box-200">
                {redirectToUrl && onBackSelectDiagram &&
                <button className="storm-prompt-unsaved-button"
                        onClick={onBackSelectDiagram}>
                  <i className="fas fa-arrow-alt-circle-left" />
                  <div>
                    <h3>Go back</h3>
                    <p>Select a different diagram</p>
                  </div>
                </button> }

                <img className="storm-prompt-image"
                      src={selectedFile.Image || blankDiagram} />
              </div>
            </div>

            <div className="p-row">
              <div className="p-col p-col-6 p-col-fixed">
                <div>
                  <label className="storm-prompt-label" htmlFor="diagram-name">Diagram name:</label>
                  <Input className="storm-prompt-input" 
                        id="diagram-name" 
                        type="text" 
                        autocomplete="off"
                        value={inputValue}
                        disabled={disabled}
                        onChange={handleInputChange} />
                </div>
                <div className="storm-prompt-buttons">
                  <Button iconClass="fas fa-save fa-fw" 
                          title="Save"
                          disabled={disabled}
                          onClick={onSave}>Save</Button>
                </div>
              </div>
            </div>
          </div>
}