import React from "react";
import $ from "jquery";
import { connect } from "react-redux";
import PhenomId from "../../../requests/phenom-id"
import { PhenomLabel } from "../../util/stateless";


class ModelPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modelId: undefined,
            optionsArrived: false,
            userIdentity: window["userIdentity"] || {}
        };
        this.options = {};
        this.defaultModel = null;
    }

    componentDidMount() {
        const promise = window["cachedRequest"]
                ? window["cachedRequest"]("subModels")
                : $.ajax("/index.php?r=/sub-model/current-model-ref-index");

        promise.then(this.updateOptionsFromServer);
    }

    updateOptionsFromServer = (res) => {
        const response = JSON.parse(res);
        
        Object.values(response).forEach(ref => {
            const userPerm = ref.permissions[this.props.userIdentity.username];

            if ((/.*w.*/.test(userPerm)) && !ref.created) {
                ref.disabled = false;
                if (!this.defaultModel) this.defaultModel = ref.id;
            } else {
                ref.disabled = true;
            }
        });
        this.options = response;
        if (!this.defaultModel) this.defaultModel = Object.values(response)[0].id;
        if (!this.state.modelId && !this.props.disabled) {
            this.setState({
                modelId: sessionStorage.getItem("selectedSubmodel") || this.defaultModel,
                editable: true,
                optionsArrived: true
            });
        } else {
            this.setState({optionsArrived: true});
        }
    }

    isWritableModel(modelId) {
        const subModelData = this.options[modelId];

        if(subModelData) {
            const userPerm = subModelData.permissions[this.props.userIdentity.username];

            return (/.*w.*/.test(userPerm)) && !subModelData.created;
        } else {
            throw `Attempted to write to model with ID ${modelId} which does not exist in this workspace.`;
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.modelId !== prevProps.modelId) {
            this.setState({
                modelId: this.props.modelId,
                editable: !this.props.modelId
            }, () => {this.determineNodeEditability();
                      if (!this.options[this.state.modelId]) {
                        $.ajax("/index.php?r=/sub-model/current-model-ref-index").then(this.updateOptionsFromServer);
                      }});
        }
        if (!this.state.modelId && Object.keys(this.options).length) {
            this.setState({
                modelId: this.defaultModel,
                editable: !this.props.disabled
            }, this.determineNodeEditability);
        }
        if (this.state.optionsArrived !== prevState.optionsArrived) {
            this.determineNodeEditability();
        }
    }

    determineNodeEditability = () => {
        if (this.props.disableEditing) {
            if (!this.state.editable && this.options[this.state.modelId] && this.options[this.state.modelId].disabled) {
                this.props.disableEditing(true);
            } else {
                this.props.disableEditing(false);
            }
        }
    };

    selectModel = (e) => {
        const newId = e.target.value;
        this.setState({modelId: newId});
        sessionStorage.setItem("selectedSubmodel", newId);
    };

    getModelId = () => {
      return this.state.modelId;
    }

    render() {
        const phenomId = new PhenomId("model-picker",this.props.idCtx);
        let isPublished = false;
        let displayDate = "";
        if (this.options[this.state.modelId]) {
          isPublished = this.options[this.state.modelId].created;
          displayDate = isPublished ? new Date(this.options[this.state.modelId].created) : new Date(this.options[this.state.modelId].branchCreated);
        }

        if (this.props.selectOnly) {
            return (
                <React.Fragment>
                    {this.state.editable ?
                        <select className="cadet-select" style={{width: "100%", ...this.props.style}} value={this.state.modelId}
                                onChange={this.selectModel} id={phenomId.gen("select")}>
                            {Object.entries(this.options).map(([id, ref]) => <option key={id} value={id} id={phenomId.gen("select", `${id}`)}
                                                                                     disabled={ref.disabled}>{ref.name}</option>)}
                        </select> :
                        <p style={{marginBottom: 0}}>{this.options[this.state.modelId] ? this.options[this.state.modelId].name : "Loading..."}</p>}
                </React.Fragment>
            );
        } else {
            return (
                <div className="node-history-wrapper" style={this.props.style}>
                    <PhenomLabel text="Model" id={phenomId.gen(["init","node-history"])} />
                    <span>
                        <p style={{marginBottom: 0, marginRight: 5}}>Name:</p>
                        {this.state.editable ?
                            <select className="cadet-select cs-thin" value={this.state.modelId} id={phenomId.gen("node-history","select")}
                                    onChange={this.selectModel}
                                    autoComplete={this.props.autoComplete}>
                                {Object.entries(this.options)
                                    .filter(([_, ref]) => !/.*i.*/.test(ref.permissions[this.props.userIdentity.username]))
                                    .map(([id, ref]) =>
                                        <option
                                            id={phenomId.gen(["node-history",`${id}`],"option")}
                                            key={id}
                                            value={id}
                                            disabled={ref.disabled}>
                                            {ref.name}
                                        </option>)}
                            </select> :
                            <p style={{
                                marginBottom: 0,
                                textOverflow: "ellipsis",
                                overflow: "hidden"
                            }}>{this.options[this.state.modelId] ? this.options[this.state.modelId].name : "Loading..."}</p>}
                    </span>
                    {this.state.modelId && this.state.optionsArrived && this.options[this.state.modelId] && <span><p style={{
                        marginBottom: 0,
                        marginRight: 15
                    }}>Description:</p><p>{this.options[this.state.modelId].description}</p></span>}
                    {this.state.modelId && this.state.optionsArrived && <span><p style={{
                        marginBottom: 0,
                        marginRight: 15
                    }}>{isPublished ? "Version Date:" : "Created Date:"}</p><p>{displayDate ? displayDate.toLocaleString() : "Tail Version"}</p></span>}
                </div>
            );
        }
    }
}


const msp = (state) => {
  return {
    userIdentity: state.user.userIdentity || {},
  }
}

export default connect(msp, null, null, { forwardRef: true })(ModelPicker)

