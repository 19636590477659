import React from "react";
import { Helmet } from "react-helmet";
import loadingIcon from "../../images/Palette Ring-1s-200px.gif";
import { getCriticalDetails, logout } from "../../requests/actionThunks";
import { Button } from "@progress/kendo-react-buttons";
import { withRouter } from "react-router-dom";

class LoginError extends React.Component {
  _isMounted = true;
  state = {
    msg: "Your project failed to load in PHENOM - please contact PHENOM Support.",
    projectIsLoading: false,
  }
  
  componentDidMount() {
    const locationState = this.props.location?.state;
    if (locationState) {
      const { errors } = locationState;
      const reg = new RegExp(/.* loading .*/);
      let msg = "";

      if (Array.isArray(errors) && errors.every(er => typeof er === 'string')) {
        msg = errors.join(" ");
      }

      this.setState({
        msg: Array.isArray(errors) && errors.every(er => typeof er === 'string') ? errors.join(" ") : "",
        projectIsLoading: reg.test(msg),
      }, () => {
        this.autoLogin();
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleLogout = () => {
    logout().always(() => {
      this.props.history.push("/")
    })
  }

  autoLogin = () => {
    if (!this.state.projectIsLoading) {
      return;
    }

    setTimeout(() => {
      if (!this._isMounted) return;

      // if login is successful then you will be redirected
      // if login fails then try again after X seconds
      getCriticalDetails().catch(() => {
        this.autoLogin();
      })
    }, 20000);
  }

  renderLogout = () => {
    return <div style={{ marginTop: 20 }}>
        <Button onClick={this.handleLogout}>
          Log out
        </Button>
      </div> 
  }

  render() {
    let { msg, projectIsLoading } = this.state;
    let title = "Project failed to load";

    if (projectIsLoading) {
      title = "Project loading";
    } else if (/.* permissions .*/.test(msg)) {
      title = "No project available";
      msg = "You can not log into Phenom at this time - this may be because your project failed to load or you do not have sufficient permissions to see any of your account's model content. \nIf you think this is a mistake, please contact your Phenom account administrator or the Skayl technical support team."
    }
    
    return (
      <section id="login-fail">
        <Helmet>
          <title>{title}</title>
        </Helmet>

        <div className="phenom-content-wrapper" style={{ padding: 20 }}>
          <div className="phenom-content-scrollable">
            <div className="logintitle">
              <h1>{title}</h1>
            </div>

            <div style={{ display: "flex", gap: 10, flexDirection: "column" }}>
              <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                {projectIsLoading && 
                <img id="loading-spinner"
                      style={{ width: 60 }}
                      src={loadingIcon} /> }
                  <p style={{ whiteSpace: "pre-wrap"}}>{msg}</p>
              </div>

              { this.renderLogout() }
            </div>
          </div>
        </div>
      </section>
    )
  }
}


export default withRouter(LoginError);