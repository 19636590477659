import React, { Component } from "react";
import {DialogActionsBar} from "@progress/kendo-react-dialogs";
import styled from "@emotion/styled";
import {CadetInput, CadetTextArea, Toggle} from "../../../util/stateless";
import { ComboBox, DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from "@progress/kendo-react-buttons";
import {cloneDeep} from "lodash";
import {Modal2} from "../../../util/Modal";
import { defaultCharacteristic } from "../util";
import { primitiveTypes, sortNodesByName, filterDataList, createPhenomGuid } from "../../../util/util";
import PhenomId from "../../../../requests/phenom-id";
import { _ajax } from "../../../../requests/sml-requests";

function noop() {
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    max-height: 650px;
    overflow: auto;
`

const Label = styled.label`
    font-size: 14px;
    text-transform: uppercase;
`

const Row = styled.div`
    margin-bottom: 10px;
`

const buttonStyle = { marginLeft: 5, }
const gridStyle = { width:500, background:"#ccc", border:"1px solid black" }
const inputStyle = { margin:0, minHeight: 30, background:"#fff" }
const inputErr = "1px solid red";

const blacklistPrimitiveTypes = new Set([
  "platform:Enumeration",
  "platform:IDLStruct",
  "platform:Fixed",
  "platform:CharArray",
  "platform:WCharArray",
  "platform:BoundedString",
  "platform:BoundedWString"
]);

export class CharacteristicModal extends Component {
    constructor(props) {
      super(props);

      this.phenomId = new PhenomId("char-modal");
      this.editNameOnShow = true;
      this.defaultState = {
        visible: false,
        ...cloneDeep(defaultCharacteristic),
        createNewPlatformType: false,
        newPlatformType: {},
        measurement: null,
        measurements: [],
        platformTypes: [],
        errMsgs: [],
        typeDifference: null,
      }

      this.state = {
        ...cloneDeep(this.defaultState),
      }
    }

    componentDidUpdate(prevProps, prevState) {
      if(this.editNameOnShow && this.nameRef) {
          this.nameRef.focus();
          this.nameRef.select();
          this.editNameOnShow = false;
      }
    }

    // Checks for type differences for ONLY path builder (not editing chars) upon modal show()
    checkWarnings = (char={}, observable) => {
      const lastHop = char?.pathPairs[char.pathPairs.length-1];
      if (!lastHop) {
        return { typeDifference: null };
      }

      let typeDifference = observable?.guid !== char?.pathPairs[char.pathPairs.length-1].type;

      // check for different path observable types
      if (typeDifference) {
        typeDifference = {char : char?.pathPairs[char.pathPairs.length-1], observable};
      }

      return {
        typeDifference,
      }
    }

    show = async (char={}, observable, editChar=false, confirmFunc, cancelFunc) => {
        // get warnings
        let typeDifference = false;
        if (!editChar) {
          typeDifference = this.checkWarnings(char, observable).typeDifference;
        }

        let measurements = [], newPlatformType = await this.generateNewPlatformType(), createNewPlatformType = false;
        let measurement = !typeDifference ? this.props.measurementMap[char.measurement] || null : null;

        // observable is passed in when using the PathBuilder
        // observable is not passed in when clicking the Char Edit Button
        if(!observable) {
            observable = measurement ? this.props.getOptionNode(measurement.realizes) : null;
        }

        // get measurement list based on observable
        if(observable?.realizations) {
          measurements = observable.realizations.split(" ").map(guid => this.props.getOptionNode(guid)).sort(sortNodesByName);
        }

        // if !measurement then select the first measurement from measurements list
        if(!measurement && measurements.length && !typeDifference) {
          measurement = measurements[0];
        }

        // Check for Enumeration and IDL Struct (to limit create new PlatformType)
        const isEnum = measurement?.measurementAxis[0]?.valueTypeUnit[0]?.valueType[0]?.xmiType === "logical:Enumerated";
        const isIdlStructMas = measurement?.measurementAxis?.length > 1;                     // note: undefined > 1 => false
        const isIdlStructVtus = measurement?.measurementAxis[0]?.valueTypeUnit.length > 1;
        
        // user wants the focus to be on name input when dialog appears
        const platformTypes = this.props.getRealizedPlatformTypeList(measurement?.guid).sort(sortNodesByName);
        const platformType = this.props.platformTypeMap[char.platformType] || null;

        // user wants the focus to be on name input when dialog appears
        this.editNameOnShow = true;

        if(measurement) {
          // user added a new platform type to the char
          // note: checking to see if measurement exist (in case: user reloads the diagram after deleting the measurement)
          if(char.platformType?.xmiType) {
            newPlatformType = cloneDeep(char.platformType);
            createNewPlatformType = true;
          } else {
            if(isEnum) newPlatformType = await this.generateNewPlatformType(measurement, "platform:Enumeration");
            if(isIdlStructMas || isIdlStructVtus) newPlatformType = await this.generateNewPlatformType(measurement, "platform:IDLStruct", isIdlStructVtus);
          }
        }

        // if platformTypes list is empty - start out in "create new platformType"
        if(!platformTypes.length || typeDifference) {
          createNewPlatformType = true;
        }

        this.setState({
            visible: true,
            rolename: char.rolename || "",
            descriptionExtension: char.descriptionExtension || "",
            createNewPlatformType,
            newPlatformType,
            platformType,
            platformTypes,
            platformValues: char.platformValues || [],
            measurement,
            measurements,
            optional: char.optional,
            isEnum,
            isIdlStructMas,
            isIdlStructVtus,
            confirmFunc: confirmFunc || noop,
            cancelFunc: cancelFunc || noop,
            typeDifference
        });
    }

    hide = () => {
        this.setState({visible: false});
    }

    generateNode = () => {
        const { rolename, descriptionExtension, measurement, platformType, newPlatformType, createNewPlatformType, platformValues, optional } = this.state;
        let pt;

        if(createNewPlatformType) {
          pt = cloneDeep(newPlatformType);
          pt.realizes = measurement?.guid || null;
          this.props.addPlatformTypeMap(pt);

        } else {
          pt = platformType ? platformType.guid : null;
        }

        return {
            rolename,
            descriptionExtension,
            measurement: measurement ? measurement.guid : null,
            platformType: pt,
            platformValues,
            optional,
        }
    }

    generateNewPlatformType = async (measurement, xmiType="platform:Boolean", realizeVtus=false) => {
      const platformType = {
        guid: createPhenomGuid(),
        name: "",
        xmiType,
        description: "",
        realizes: measurement ? measurement.guid : "",
        children: [],
        deconflictName: true,         // used by smm-save-nodes
      }

      switch(xmiType) {
        case "platform:IDLStruct":
          if(!measurement) break;
          const mas_or_vtus = realizeVtus ? measurement.measurementAxis[0].valueTypeUnit : measurement.measurementAxis;

          platformType.children = mas_or_vtus.map((ma_or_vtu) => {
            const obsGuid = ma_or_vtu.realizes || measurement.realizes;
            const observable = this.props.getOptionNode(obsGuid) || {name: "Placeholder"};

            return {
              guid: undefined,
              rolename: `${observable.name}_${ma_or_vtu.name}_IDLComposition`,
              xmiType: "platform:IDLComposition",
              deconflictName: true,
              type: {
                guid: undefined,
                name: `${observable.name}_${ma_or_vtu.name}_Boolean`,
                xmiType: "platform:Boolean",
                realizes: ma_or_vtu.guid,
                deconflictName: true,
              },
            }
          })
          break;
        case "platform:Enumeration":
          if(!measurement) break;
          const vtu = measurement.measurementAxis[0]?.valueTypeUnit[0];
          const vt = vtu?.valueType[0];
          const allowedValues = vtu?.children[0]?.allowedValue?.split(" ");
          const childsRes = await _ajax({
              url: "/index.php?r=/node/model-get-node",
              data: {
                  guid: vt.guid,
                  coreAddenda: ["childrenMULTI"],
              }
          });
          const all_labels = childsRes.children;

          // pdm_labels:
          platformType.children = (allowedValues || []).map(ldm_label_guid => {
            const ldm_label = all_labels.find(label => label.guid === ldm_label_guid);
            if (!ldm_label) return;
            return {
              guid: undefined,
              name: ldm_label.name,
              realizes: ldm_label.guid,
              xmiType: "platform:EnumerationLiteral",
            }
          });
          break;

          default:
      }
      return platformType;
    }

    getObservableForIdlStruct = (ma={}) => {
      const { measurement, isIdlStructMas, isIdlStructVtus } = this.state;
      const realize = isIdlStructVtus && measurement.measurementAxis[0]?.realizes ? measurement.measurementAxis[0]?.realizes :
                      isIdlStructMas && ma.realizes ? ma.realizes : measurement.realizes;
      return this.props.getOptionNode(realize);
    }

    confirm = () => {
      const { nameError, measError, platError } = this.validateForm();

      this.setState({ nameError, measError, platError }, () => {
        if(!nameError && !measError && !platError) {
          this.state.confirmFunc(this.generateNode());
          this.hide();
        }
      });
    }

    close = () => {
        this.hide();
        this.state.cancelFunc();
    }

    validateForm = () => {
        const { rolename, measurement, platformType, newPlatformType, createNewPlatformType } = this.state;
        const nameError = !rolename;
        const measError = !measurement || !measurement.guid;
        const platError = createNewPlatformType ? !newPlatformType.name : !platformType || !platformType.guid;

        return {
          nameError,
          measError,
          platError,
        };
    }

    clearErrors = () => {
      this.setState({
        nameError: false,
        measError: false,
        platError: false,
      })
    }

    toggleCreatePlatformType = () => {
      this.setState({
        createNewPlatformType: !this.state.createNewPlatformType,
      })
    }

    handleMeasurementChange = async (e) => {
      const measurement = e.target.value || null;
      const platformTypes = (measurement?.realizations || []).sort(sortNodesByName);
      const platformType = platformTypes[0] || null;

      const isEnum = measurement?.measurementAxis[0]?.valueTypeUnit[0]?.valueType[0]?.xmiType === "logical:Enumerated";
      const isIdlStructMas = measurement?.measurementAxis?.length > 1;                     // note: undefined > 1 => false
      const isIdlStructVtus = measurement?.measurementAxis[0]?.valueTypeUnit.length > 1;

      let newPlatformType = await this.generateNewPlatformType();
      if(isEnum) newPlatformType = await this.generateNewPlatformType(measurement, "platform:Enumeration");
      if(isIdlStructMas || isIdlStructVtus) newPlatformType = await this.generateNewPlatformType(measurement, "platform:IDLStruct", isIdlStructVtus);

      this.setState({
          measurement,
          platformType,
          platformTypes,
          isEnum,
          isIdlStructMas,
          isIdlStructVtus,
          newPlatformType,
      }, this.clearErrors)
    }

    handlePlatformTypeChange = (e) => {
        this.setState({
            platformType: e.target.value,
        }, this.clearErrors)
    }

    handlePrimitiveTypeChange = (e) => {
      const primitiveType = e.target.value[0];
      this.setState({
        newPlatformType: {...this.state.newPlatformType, xmiType: primitiveType}
      }, this.clearErrors)
    }

    handleIDLStructPrimitiveChange = (idlComp, primitive, ma_or_vtu) => {
      const { measurement } = this.state;
      const obsGuid = ma_or_vtu.realizes || measurement.realizes;
      const observable = this.props.getOptionNode(obsGuid) || {name: "Placeholder"};

      const primitiveType = primitive[0];
      // const primitiveText = primitive[1];

      idlComp.type = {
        ...idlComp.type,
        name: `${observable.name}_${ma_or_vtu.name}_${primitiveType.split(":")[1]}`,
        xmiType: primitiveType,
      }
      this.forceUpdate();
    }

    ptItemRender = (li, itemProps) => {
        let pt = itemProps.dataItem;
        const item = <span>{`${pt.name} [${pt.xmiType}]`}</span>
        return React.cloneElement(li, li.props, item);
    }

    primitiveItemRender = (li, itemProps) => {
      const { isEnum, isIdlStruct } = this.state;
      const primitiveType = itemProps.dataItem[0];
      const primitiveText = itemProps.dataItem[1];

      // limit the dropdown list
      if(isEnum && primitiveType !== "platform:Enumeration") return null;
      if(isIdlStruct && primitiveType !== "platform:IDLStruct") return null;
      if(!isEnum && !isIdlStruct && blacklistPrimitiveTypes.has(primitiveType)) return null;

      const item = <span>{primitiveText}</span>
      return React.cloneElement(li, li.props, item);
    }

    sortPrimitiveTypes = (ele1, ele2) => {
      const eleType1 = ele1[0];
      const eleType2 = ele2[0];
      return eleType1 > eleType2 ? 1 :
             eleType1 < eleType2 ? -1 : 0;
    }

    filterMeasurements = (e) => {
        const { value } = e.filter;
        this.setState({
            measurements: Object.values(this.props.measurementMap).sort((node1, node2) => filterDataList(node1, node2, value))
        })
    }

    filterPlatformTypes = (e) => {
        const { value } = e.filter;
        this.setState({
            platformTypes: this.state.platformTypes.sort((node1, node2) => filterDataList(node1, node2, value))
        })
    }

    renderPlatformTypeSection = () => {
      const { createNewPlatformType, platformType, newPlatformType, measurement, isEnum, isIdlStructMas, isIdlStructVtus } = this.state;
      const styleType = {display:"flex", alignItems:"center", padding:"5px 10px", fontSize:14, minHeight:30, background:"#f2f2f2"}
      const maLabel = {display:"block", fontSize:12, wordBreak:"break-word"}

      if(!createNewPlatformType) {
        return <Row>
                  <Label>Primitive Type</Label>
                  <div style={styleType}>
                    {platformType && platformType.xmiType.split(":")[1]}
                  </div>
               </Row>
      }

      return <Row>
                <Label>Primitive Type</Label>
                {isEnum ? <>
                  <div style={styleType}>Enumeration</div>
                  <p style={{fontSize:12, margin:10, textAlign:"center", fontStyle:"italic"}}>The new Platform Type will be created using the compositions from the Measurement.<br/>If you want to edit them, please go to the Details page.</p>
                </> :

                isIdlStructMas || isIdlStructVtus ? <>
                  <div style={styleType}>IDL Struct</div>
                  <Label style={{marginTop:10}}>IDL Compositions</Label>
                  {newPlatformType.children.map((idlComp, idx) => {
                    const ma_or_vtu = isIdlStructVtus ? measurement.measurementAxis[0].valueTypeUnit[idx] : measurement.measurementAxis[idx];

                    return <Row>
                              <span style={maLabel}>{ma_or_vtu.name}</span>
                              <DropDownList style={{width:"100%"}}
                                            data={Object.entries(primitiveTypes).sort(this.sortPrimitiveTypes)}
                                            value={primitiveTypes[idlComp.type.xmiType]}
                                            itemRender={(li, itemProps) => {
                                              const primitiveType = itemProps.dataItem[0];
                                              const primitiveText = itemProps.dataItem[1];

                                              // limit the dropdown list
                                              if(blacklistPrimitiveTypes.has(primitiveType)) return null;
                                              const item = <span>{primitiveText}</span>
                                              return React.cloneElement(li, li.props, item);
                                            }}
                                            onChange={(e) => {
                                              const primitive = e.target.value;
                                              this.handleIDLStructPrimitiveChange(idlComp, primitive, ma_or_vtu);
                                            }} />
                          </Row>
                  })}
                </> :

                <DropDownList data={Object.entries(primitiveTypes).sort(this.sortPrimitiveTypes)}
                              style={{width:"100%"}}
                              value={primitiveTypes[this.state.newPlatformType.xmiType]}
                              itemRender={this.primitiveItemRender}
                              onChange={this.handlePrimitiveTypeChange} />
                }
             </Row>
    }

    render() {
        const { optional, visible, createNewPlatformType, nameError, measError, platError, typeDifference } = this.state;
        const warnings =[];
        const errors = [];

        if(typeDifference?.char && typeDifference?.observable) warnings.push(<span>
          The selected path terminates on Observable <strong>{typeDifference.observable.name}</strong> 
          {' '}which differs from the existing path's Observable <strong>{typeDifference.char.rolename}</strong>. It will be overwritten.
        </span>)

        if(nameError) errors.push("Rolename cannot be blank.");
        if(measError) errors.push("Measurement is required.");
        if(platError) errors.push("PlatformType is required.");
        
        const isOptional = optional === "true";

        return (
            <Modal2 show={visible}
                    onExitedCallback={() => {
                        this.setState({...this.defaultState},
                          this.clearErrors);
                    }}>
                <div className="association-drag" style={gridStyle}>
                    <Container>
                        <Row>
                            <Label id={this.phenomId.genPageId("rolename-label")}>ROLENAME</Label>
                            <CadetInput id={this.phenomId.genPageId("rolename-input")}
                                        text={this.state.rolename}
                                        style={inputStyle}
                                        error={nameError}
                                        inputRef={el => this.nameRef = el}
                                        onChange={(e) => {
                                              this.setState({rolename: e.target.value})
                                              if(nameError) this.clearErrors();
                                        }} />
                        </Row>

                        <Row>
                            <Label id={this.phenomId.genPageId("description-label")}>DESCRIPTION</Label>
                            <CadetTextArea id={this.phenomId.genPageId("description-input")} style={inputStyle} text={this.state.descriptionExtension} onChange={(e) => this.setState({descriptionExtension: e.target.value})} />
                        </Row>

                        <Row id={this.phenomId.genPageId("measurement-wrapper")}>
                            <Label id={this.phenomId.genPageId("measurement-input")}>MEASUREMENT</Label>
                            <ComboBox
                                data={this.state.measurements}
                                value={this.state.measurement}
                                textField="name"
                                filterable={true}
                                clearButton={false}
                                style={{
                                  width:"100%",
                                  border: measError ? inputErr : null
                                }}
                                onChange={this.handleMeasurementChange}
                                onFilterChange={this.filterMeasurements} />
                        </Row>

                        <Row id={this.phenomId.genPageId("platform-type")}>
                            <Label id={this.phenomId.genPageId("platform-type-label")}>Platform Type</Label>
                            <div style={{display:"flex"}}>
                              {createNewPlatformType
                                ? <CadetInput id={this.phenomId.genPageId("platform-type-input")}
                                              text={this.state.newPlatformType.name}
                                              style={inputStyle}
                                              placeholder="Enter new platform type name"
                                              error={platError}
                                              onChange={(e) => this.setState({newPlatformType: {...this.state.newPlatformType, name: e.target.value}})} />
                                : <ComboBox
                                      data={this.state.platformTypes}
                                      value={this.state.platformType}
                                      textField="name"
                                      filterable={true}
                                      clearButton={false}
                                      style={{
                                        width:"100%",
                                        flex: 1,
                                        border: platError ? inputErr : null
                                      }}
                                      onChange={this.handlePlatformTypeChange}
                                      onFilterChange={this.filterPlatformTypes}
                                      itemRender={this.ptItemRender} />}

                                  <Button id={this.phenomId.genPageId("platform-type", createNewPlatformType ? "select-existing-btn" : "create-new-btn")}
                                      style={buttonStyle}
                                      icon={createNewPlatformType ? "list-unordered" : "plus"}
                                      title={createNewPlatformType ? "Select existing platform type" : "Add new platformType"}
                                      onClick={this.toggleCreatePlatformType} />
                            </div>
                        </Row>

                        {this.renderPlatformTypeSection()}

                        <Row style={{display: "flex"}}>
                            <div>
                              <Label id={this.phenomId.genPageId("optional-label")}>Optional</Label>
                            </div>
                            <div style={{textAlign:"center"}}>
                              <Toggle idCtx={this.phenomId.genPageId("optional")}
                                      disabled={false}
                                      options={["No", "Yes"]}
                                      startingPosition={isOptional ? 1 : 0}
                                      style={{width:"60px"}}
                                      toggleFunction={() => this.setState({ optional: isOptional ? "false" : "true" })} />
                            </div>
                        </Row>
                    </Container>

                    <div id={this.phenomId.genPageId("warning-messages")} 
                        style={{fontSize:14, textAlign:"center", marginLeft:"auto", marginRight:"auto", display: 'flex', justifyContent: 'center', alignItems: 'center', width: "95%"}}>
                        {warnings.map(err => 
                          <div>
                            <span className="fa-solid fa-triangle-exclamation" style={{paddingRight: "5px"}}/>
                            {err}
                          </div>)}
                    </div>
                    <div id={this.phenomId.genPageId("error-messages")} style={{color:"red", fontSize:14, textAlign:"center", margin:"10px -10px"}}>
                        {errors.map(err => <div>{err}</div>)}
                    </div>
                    <div style={{background:"#f2f2f2"}}>
                        <DialogActionsBar>
                            <Button id={this.phenomId.genPageId("confirm-btn")} look="bare" onClick={this.confirm}>CONFIRM</Button>
                            <Button id={this.phenomId.genPageId("cancel-btn")} look="bare" onClick={this.close}>CANCEL</Button>
                        </DialogActionsBar>
                    </div>
                </div>
            </Modal2>
        );
    }
}
