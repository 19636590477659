import React from "react";
import {getNodesOfType, getNodeWithAddenda, verifyEquation} from "../../requests/sml-requests";
import {deGuidify} from "./util";
import PhenomId from "../../requests/phenom-id";
import deprecatedSrc from "../../images/deprecated_hover.png";
import {NavLink} from "react-router-dom";
import {PhenomLink} from "../widget/PhenomLink";
import {LineLabel, DeprecatedBanner, DeprecatedIcon, ColorCollapsable, CadetTextArea,} from "./stateless";



export class EquationDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          equationErrors: null,
          showErrors: false,
        }
    }

    componentDidMount() {
      if (this.props.equation != "") {
        this.verifyEquationField();
      }
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.equation != this.props.equation) {
        this.verifyEquationField();
      }
    }

    verifyEquationField = () => {
      if (this.props.equation == "" && this.state.equationErrors != null) {
        this.setState({equationErrors: null});
      } else {
        verifyEquation(this.props.equation, this.props.source, this.props.target).then(response => {
          this.setState({equationErrors: response.data.errors.length ? response.data.errors : null});
        });
      }
    }

    render() {
      const {editable, idCtx, equation, setEquation} = this.props;
      const yellow_warning = '\u26A0\uFE0F';
      const equationHasErrors = this.state.equationErrors && this.state.equationErrors.length;

      const phenomId = new PhenomId("equations-display",idCtx);
      return (
        <div className={"flex-v"} style={{flexGrow: 1, paddingTop: "15px"}}>
          <div className={"flex-h"} >
            {equationHasErrors &&
              <button
                onClick={() => this.setState({showErrors: !this.state.showErrors})}
                title={"Click to see errors."}
                style={{background: "none", border: "none"}}>
                {yellow_warning}
              </button>}
            <div className={"flex-v"} style={{flexGrow: 1}}>
              <LineLabel text={"Equation"} idCtx={phenomId.gen("","equation")} />
            </div>
          </div>
          <div>
          <CadetTextArea
              text={equation}
              onChange={setEquation}
              disabled={!editable}
              onBlur={this.verifyEquationField}
              idCtx={phenomId.gen("details","equation")}/>
          </div>
          {equationHasErrors && this.state.showErrors &&
            <div style={{paddingBottom: "15px"}}>
              <CadetTextArea
                text={"Errors with Skayl Format:\n\t- " + this.state.equationErrors.join("\n\t- ")}
                disabled
                />
            </div>}
        </div>
      );
    }
}
