  
import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { LabelModel } from '@projectstorm/react-diagrams-core';
import styled from '@emotion/styled';


export class DiagramLabelFactory extends AbstractReactFactory {
	constructor() {
		super('default');
	}

	generateModel(event) {
		return new DiagramLabelModel();
	}

	generateReactWidget(event) {
		return <DiagramLabelWidget diagramEngine={this.engine} model={event.model} />;
	}

}


export class DiagramLabelModel extends LabelModel {
	constructor(options = {}) {
		super({
			offsetY: options.offsetY == null ? -15 : options.offsetY,
			type: 'default',
			...options
		});
	}

	setLabel(label) {
		this.options.label = label;
	}

	deserialize(event) {
		super.deserialize(event);
		this.options.label = event.data.label;
	}

	serialize() {
		return {
			...super.serialize(),
			label: this.options.label
		};
	}
}


const Label = styled.div`
    // background: rgba(136, 136, 136, .8);
    border-radius: 5px;
    color: rgba(136, 136, 136, .8);
    font-size: 12px;
    padding: 4px 8px;
    font-family: sans-serif;
    user-select: none;
`;

export class DiagramLabelWidget extends React.Component {

	render() {
		return <Label className="diagram-label">{this.props.model.getOptions().label}</Label>;
	}
}