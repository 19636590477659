import React from 'react';
import ReactTooltip from "react-tooltip";
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { PortWidget } from "@projectstorm/react-diagrams-core";
import { isStormData } from "../../util";
import { BaseNodeModel, BaseNodeWidget } from '../../base/BaseNode';
import { StormAnchor } from '../../base/BasePort';
import { ImPortModel } from '../misc/ImPort';
import { isPhenomGuid } from '../../../../util/util';
import StormData from '../../base/StormData';




export class ImBlockNodeFactory extends AbstractReactFactory {
  constructor() {
    super("block-node");
  }

  generateModel(event) {
    return new ImBlockNodeModel();
  }

  generateReactWidget(event) {
    return <ImBlockNodeWidget engine={this.engine} node={event.model}/>;
  }
}



export class ImBlockNodeModel extends BaseNodeModel {
  constructor(options={}, $app, settings={}, ) {
    super(
      { type: "block-node", ...options },
      $app,
      settings,
    )

    this.settings = {
      ...this.settings,
      ...settings,
    };

    this.init();

    this.registerListener({
      dataTypeChanged: (e) => {
        this.updateDataTypes(e.view);
      },
      templateTypeChanged: (e) => {
        this.updateTemplateTypes(e.text);
      },
      imLinkColorChanged: (e) => {
        this.setLinkColorsBasedOnDataType(e.color, e.dataType);
      },
      entityRemoved: (e) => {
        if (isPhenomGuid( this.getGuid() )) return;
        const stormData = this.getStormData();
        const dependencies = [];

        switch (stormData.getXmiType()) {
          case "im:ViewTransporterNode":
            const associationData = this.$app.getDiagramStormData(stormData.getAttr("associationNode"));
            if (isStormData(associationData) && !isPhenomGuid(associationData.getGuid())) {
              dependencies.push(associationData.getGuid());

              this.$app.markNodeForDeletion({
                data: associationData.serializeData(),
                dependencies: [this.getGuid()],
              });
            }
            break;

          default:
        }

        this.$app.markNodeForDeletion({
          data: stormData.serializeData(),
          position: [this.getX(), this.getY()],
          dependencies,
        });
      },
    })
  }


  // @override
  init() {
    // create a general purpose port (used for drag and drop)
    // Parent uses "BasePortModel"
    const port = new ImPortModel({
      name: "draw-tool",
      in: false,
      attrData: new StormData(),
    });
    this.addPort(port);
    port.reportPosition();
  }



  // ------------------------------------------------------------
  // Getters
  // ------------------------------------------------------------
  /**
   * Currently Block Nodes have at most one Out Port
   *
   * @returns Out Port
   */
  getImOutPort(guid) {
    if (guid) {
      return this.getPort(guid);
    } else {
      return Object.values(this.getPorts()).find(p => !p.getOptions().in && p.getName() !== "draw-tool")
    }
  }

  /**
   * Currently Block Nodes have at most one In Port
   *
   * @returns In Port
   */
  getImInPort(guid = undefined) {
    if (guid) {
      return this.getPort(guid);
    } else {
      return Object.values(this.getPorts()).find(p => p.getOptions().in && p.getName() !== "draw-tool")
    }
  }

  /**
   * Check if Node can accept an incoming connection
   *    Source Node cannot be this node
   *    This node must have an available Inport (zero connections)
   *
   * @returns True if node can accept an incoming connection, False otherwise
   */
  isValidDropTarget() {
    const dragNodeModel = this.$app.getDraggingFor();
    if (!dragNodeModel || dragNodeModel === this) {
      return false;
    }

    switch (this.getXmiType()) {
      case "im:ComposedBlockInstance":
          const inPorts = Object.values(this.getPorts()).filter((p) => p.getOptions().in && p.getName() !== 'draw-tool' && p.countLinks() < 1);
          return !!inPorts.length;

      default:
        const inPort = this.getImInPort();
        const arrowDragCanCatch = inPort && inPort.validLinkCount();
    
        return arrowDragCanCatch || ["im:FanIn", "im:Generic"].includes(this.getXmiType());
    }
  }


  // ------------------------------------------------------------
  // Setters
  // ------------------------------------------------------------
  setInPort (attrData, options={}) {
    try {
      if (!isStormData(attrData)) throw "invalid data, failed to create in port";
      const portName = options.portName || attrData.getGuid();
      const port = new ImPortModel({
        name: portName,
        in: true,
        attrData,
      })

      this.addPort(port);
      port.reportPosition();
      return port;

    } catch(err) {
      console.error(err);
    }
  }

  setOutPort (attrData, options={}) {
    try {
      if (!isStormData(attrData)) throw "invalid data, failed to create in port";
      const portName = options.portName || attrData.getGuid();
      const port = new ImPortModel({
        name: portName,
        in: false,
        attrData,
      })

      this.addPort(port);
      port.reportPosition();
      return port;

    } catch(err) {
      console.error(err);
    }
  }

  setLinkColorsBasedOnDataType(color, dataType="") {
    Object.values(this.getPorts()).forEach(port => {
      Object.values(port.getLinks()).forEach(link => {
        link.fireEvent({
          color,
          dataType,
          fromInPort: port.getOptions().in
        }, 'imLinkColorChanged');
      })
    })
  }


  /**
   * Called by an Event Listener
   *    When triggered, this updates all Outport and Inport DataTypes because certain blocks can only have one
   *    - i.e. Filter, SimAdapter, and Data Pump
   *
   * @param {string} dataType View guid
   */
  updateDataTypes(view={}) {
    Object.values(this.getPorts()).forEach(port => {
      if (port.getName() === 'draw-tool') return;
      port.setDataType(view);
    })
  }

  updateTemplateTypes(text="") {
    Object.values(this.getPorts()).forEach(port => {
      if (port.getName() === 'draw-tool') return;
      port.setTemplateType(text);
    })
  }
}




class ImBlockNodeWidget extends BaseNodeWidget {
  constructor(props) {
    super(props);

    this.nodeModel.registerListener({
      selectionChanged: (e) => {
        if (!e.isSelected) {
          this.state.isEditing && this.setState({ isEditing: !this.state.isEditing });
        }
      }
    })
  }

  componentDidUpdate(_, prevState) {
    if (prevState.isEditing !== this.state.isEditing) {
      ReactTooltip.hide();
    }

    if (prevState.isEditing !== this.state.isEditing) {
      if (this.state.isEditing) {
        this.nodeModel.setSelected(true);
      } else {
        this.nodeModel.setLocked(false);
      }
    }
  }

  // ------------------------------------------------------------
  // Drag and Drop
  // ------------------------------------------------------------
  dragStartCreateConnection = (e) => {
    this.repositionDrawTool(e);
    this.$app.setDraggingFor(this.nodeModel);

    const handleMouseUp = (e) => {
      const element = this.$app.engine.getMouseElement(e);
      if (element instanceof BaseNodeModel && element.isValidDropTarget()) {
        this.$app.establishConnection(this.nodeModel, element, e);
      }

      this.$app.setDraggingFor(null);
      window.removeEventListener("mouseup", handleMouseUp);
    }

    window.addEventListener("mouseup", handleMouseUp);
  }



  // ------------------------------------------------------------
  // Render Methods
  // ------------------------------------------------------------
  renderResizeBars = () => {
    return <div>
              <div className="resize-top" onMouseDown={this.startResize} />
              <div className="resize-right" onMouseDown={this.startResize} />
              <div className="resize-bottom" onMouseDown={this.startResize} />
              <div className="resize-left" onMouseDown={this.startResize} />
              <div className="resize-top-left" onMouseDown={this.startResize} />
              <div className="resize-top-right" onMouseDown={this.startResize} />
              <div className="resize-bottom-left" onMouseDown={this.startResize} />
              <div className="resize-bottom-right" onMouseDown={this.startResize} />
           </div>
  }

  renderTopDrawIcons = () => {
    if (!this.nodeModel.isSelected() || this.state.isEditing) return null;
    let stormData = this.nodeModel.getStormData();
    if (stormData.getXmiType() !== "im:Generic" && !this.nodeModel.getImOutPort()) return null;

    return <div className="top-draw-icons">
            <span className="k-icon k-i-sort-asc-sm"
                  data-port-name="draw-tool"
                  data-tip="Drag to create Connection"
                  data-for="diagramTip"
                  id={this.phenomId.genPageId("draw-tool")}
                  onDragStart={(e) => e.preventDefault()}   // bug fix - if you highlight other dom elements before clicking this element, it causes unpredictable side effects
                  onMouseDown={this.dragStartCreateConnection} />
          </div>
  }

  renderHeader = () => {
    const { isEditing } = this.state;
    const stormData = this.nodeModel.getStormData();
    const isUncommitted = !isEditing && this.$app.isShowUncommitted() && stormData.isEdited();

    return <div id={this.phenomId.genPageId("header")}
                className="node-header node-font-color"
                style={{ "--nodeFontColor": isUncommitted ? "var(--skayl-orange)" : null}}
                data-tip={stormData.getDescription()}
                data-for="diagramTip">
                  {isEditing
                    ? <input id={this.phenomId.genPageId("name-input")}
                             className="node-input node-input-head"
                             type="text"
                             value={stormData.getName()}
                             placeholder="Name"
                             autoFocus={true}
                             autoComplete="off"
                             ref={el => this.nameRef = el}
                             onChange={((e) => this.nodeModel.updateProp("name", e.target.value) )}
                             onFocus={() => this.nodeModel.setLocked(true)} />
                    : <span>{ stormData.getName() }</span> }
          </div>
  }

  renderFilterNodeAttr = () => {
    const stormData = this.nodeModel.getStormData();
    if (!this.state.isEditing || stormData.getXmiType() !== "im:FilterNode") {
      return null;
    }

    let equationData = this.$app.getDiagramStormData(stormData.getAttr("test"));

    return <div className="attribute-row block-attribute">
              <label>Test:</label>
              <input className="node-input"
                     value={equationData?.getAttr("equation") || ""}
                     placeholder="enter equation"
                     id={this.phenomId.genPageId("test-input")}
                     autoComplete="off"
                     onChange={(e) => {
                      if (!equationData) {
                        equationData = this.$app.createStormData("im:Equation");
                        isStormData(equationData) && stormData.setAttr("test", equationData.getGuid())
                      }

                      if (isStormData(equationData)) {
                        equationData.setAttr("equation", e.target.value);
                        this.nodeModel.fireEvent({}, 'nodeDataChanged');
                      }
                     }} />
           </div>
  }

  renderSIMAdapterAttr = () => {
    const stormData = this.nodeModel.getStormData();
    if (!this.state.isEditing || stormData.getXmiType() !== "im:SIMAdapter") return null;

    return <div className="attribute-row block-attribute">
              <label>Stale Time:</label>
              <input className="node-input"
                     value={stormData.getAttr("staleTime") || ""}
                     placeholder="enter stale time"
                     id={this.phenomId.genPageId("staleTime-input")}
                     autoComplete="off"
                     onChange={(e) => {
                        stormData.setAttr("staleTime", e.target.value);
                        this.nodeModel.fireEvent({}, 'nodeDataChanged');
                     }} />
           </div>
  }

  renderQueuingAdapterAttr = () => {
    const stormData = this.nodeModel.getStormData();
    if (!this.state.isEditing || stormData.getXmiType() !== "im:QueuingAdapter") return null;

    return <div className="attribute-row block-attribute">
              <label>Queue Depth:</label>
              <input className="node-input"
                     value={stormData.getAttr("queueDepth") || ""}
                     placeholder="enter queue depth"
                     id={this.phenomId.genPageId("queueDepth-input")}
                     autoComplete="off"
                     onChange={(e) => {
                        stormData.setAttr("queueDepth", e.target.value);
                        this.nodeModel.fireEvent({}, 'nodeDataChanged');
                     }} />
           </div>
  }

  render() {
    const isSelected = this.nodeModel.isSelected();
    const isTargetable = this.nodeModel.isValidDropTarget();
    const classes = ["node-container-center", "node-color-background", "node-color-border", "block-container"];

    if (isTargetable) classes.push("selectable-node");
    return (
      <div id={this.phenomId.genPageId("block-container")}
           ref={el => this.widgetRef = el}
           onDoubleClick={() => this.setState({ isEditing: !this.state.isEditing })}>

        { this.renderHalo() }
        { this.renderTopDrawIcons() }
        { this.renderResizeBars() }

        <div className={classes.join(" ")}
             style={{ "--nodeColor": this.nodeModel.getNodeColor(),
                      width: this.nodeModel.width || null,
                      minHeight: this.nodeModel.height || null }}>
                        
          { this.renderHeader() }

          <div className="attribute-container">
            { this.renderSIMAdapterAttr() }
            { this.renderQueuingAdapterAttr() }
            { this.renderFilterNodeAttr() }
          </div>
        </div>

        {/* ANCHOR POINTS AND PORTS */}
        <div className="anchor-point-container with-border">
          {Object.values(this.nodeModel.getPorts()).map(port => {
            if (port.getName() === 'draw-tool') {
              return <PortWidget key={port.getOptions().id}
                                 port={port}
                                 engine={this.props.engine}
                                 style={{ position: "absolute",
                                          left: "50%",
                                          top: "50%", }} />
            }

            return <StormAnchor key={port.getOptions().id}
                                port={port}
                                $app={this.$app}
                                engine={this.props.engine}
                                visible={isSelected || isTargetable} />
          })}
        </div>
      </div>
    );
  }
}
