import React from 'react';
import {ReactComponent as AssociationIcon} from '../icons/association_letter.svg';
import {ReactComponent as EntityIcon} from '../icons/entity_letter.svg';
import {ReactComponent as ObservableIcon} from '../icons/observable_letter.svg';
import deprecated from "../../../../images/deprecated_hover.png"



export const Icon = (props) => {
    if(!props.node) return null;

    const style = {
        display:'block',
        width:14,
        marginRight:2,
    }

    const deprecatedStyle = {
        display: 'block',
        width: 19,
        marginRight: "1.1px",
        marginLeft: "-4.2px",
        paddingLeft: "3.4px"
    }

    if(props.node.deprecated === "true") {
        return <img style={deprecatedStyle} src={deprecated} />
    }

    switch(props.node.xmiType) {
        case "conceptual:Association":
            return <AssociationIcon style={style} />
        case "conceptual:Entity":
            return <EntityIcon style={style} />
        case "conceptual:Observable":
            return <ObservableIcon style={style} />
        default:
            return null;
    }
}