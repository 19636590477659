import * as actionTypes from '../requests/actionTypes'
import { isJSON } from '../components/util/util'
import { updateState } from './rootReducer'
import { cloneDeep } from 'lodash'

const initialState = {
  notice: createNotice(),
  isEventLogVisible: false,
}

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RECEIVE_LOGS:
      return updateState(state, { notice: parseLogs(action.logs) })

    case actionTypes.RECEIVE_ERRORS:
      return updateState(state, { notice: parseErrors(action.errors) })

    case actionTypes.RECEIVE_WARNINGS:
      return updateState(state, { notice: parseWarnings(action.warnings) })

    case actionTypes.RECEIVE_SAVED_NODE:
    case actionTypes.RECEIVE_SAVED_NODES:
    case actionTypes.RECEIVE_RESPONSE:
      return updateState(state, { notice: parseResponse(action.response) })

    case actionTypes.SHOW_EVENT_LOG:
      return updateState(state, { isEventLogVisible: true });

    case actionTypes.HIDE_EVENT_LOG:
      return updateState(state, { isEventLogVisible: false });

    case actionTypes.SESSION_TOKEN_EXPIRED:
      return updateState(state, { notice: parseWarnings('Session expired. Please log back in.')})

    case actionTypes.RECEIVE_CRITICAL_DETAILS:
      if (action.details?.expired) {
        return updateState(state, { notice: parseWarnings("Your license is invalid. You are still able to download your models but other actions are restricted. Please contact your account administrator to obtain a valid license.")})
      }
      return state;

    case actionTypes.CLEAR_NOTIFICATIONS:
      return cloneDeep(initialState);

    case actionTypes.RESET_APP:
      return initialState;

    default:
      return state;
  }
}

// =============================================================

const invariantErrorCodes = {
  "UNREACHABLE_ELEMENT": "has been merged without its parent. ",
  "unreachable": "is missing a parent after the merge. ",
  "CHILD_ABSENT": "has been merged without its parent. ",
  "DANGLING_REALIZATION": "has been merged without the thing it realizes. ",
  "DANGLING_TYPE": "has been merged without the thing it is typed as. ",
  "DANGLING_VIEW_TYPE": "has been merged without the thing it is typed as. ",
  "MISSING_PATH_HOP": "has been merged without a piece of its path. ",
  "references": "is pointing to something that has not been merged. ",
}

export function createNotice() {
  return {
    timestamp: Date.now(),
    data: null,
    errors: [],
    logs: [],
    warnings: [],
  }
}


function flattenData(data) {
  if(!data) return null;
  if(data.guid) {
      return {
          guid: data.guid,
          name: data.name || data.rolename,
          xmiType: data.xmiType,
      }
  }
  return data;
}

function flattenMessages(ele, result=[]) {
  if (Array.isArray(ele)) {
    for (let msg of ele) flattenMessages(msg, result);
  } else if (typeof ele === 'string') {
    result.push(ele);
  }
   return result;
}

// =================
// Handles all cases
// @response    -> JSON object
// =================
function parseResponse(response) {
  let notice = createNotice();

  if(!isJSON(response)) {
    try {
      response = JSON.parse(response);
    }
    catch (err) {
      console.error(err);
      notice.errors.push("The server responded with an unexpected status. Please try again.");
      return notice;
    }
  }

  // Old response
  if (response.guid) {
    notice.data = flattenData(response);
    notice.logs.push(`${response.name || response.rolename} successfully saved.`);
  }

  if (response.nodes) {
    response.nodes.filter(n => !!n?.guid)
                  .forEach(n => notice.logs.push(`${n.name || n.rolename} successfully saved.`));
  }

  if (response.mmIssues) {
    notice.errors = flattenMessages(response.mmIssues);
  }

  if (response.invariantErrors) {
    response.invariantErrors.forEach(error => {
      invariantErrorCodes[error.type] && notice.errors.push(
        (error.nodes[0].name || error.nodes[0].rolename || error.nodes[0]["xmi:type"]) + invariantErrorCodes[error.type]
      )
    });
  }

  // New response
  if (response.data) {
    notice.data = flattenData(response.data);
  }

  if (response.error || response.errors) {
    notice.errors = flattenMessages((response.error || response.errors));
  }

  if (response.warnings) {
    notice.warnings = flattenMessages(response.warnings);
  }

  if (response.logs) {
    notice.logs = flattenMessages(response.logs);
  }

  return notice;
}

function parseLogs(messages, data) {
  const notice = createNotice();
  notice.data = flattenData(data);
  notice.logs = flattenMessages(messages);
  return notice;
}

function parseErrors(messages, data) {
  const notice = createNotice();
  notice.data = flattenData(data);
  notice.errors = flattenMessages(messages);
  return notice;
}

function parseWarnings(messages, data) {
  const notice = createNotice();
  notice.data = flattenData(data);
  notice.warnings = flattenMessages(messages);
  return notice;
}


export default notificationReducer;