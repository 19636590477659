import { createStore, applyMiddleware } from 'redux'
// import thunk from 'redux-thunk'
import rootReducer from '../reducers/rootReducer'


const middleware = [];
const store = createStore(
  rootReducer,
  applyMiddleware(...middleware),
)

export default store;