import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DefaultLabelModel, DefaultLabelWidget } from '@projectstorm/react-diagrams-defaults'



export class ImLabelFactory extends AbstractReactFactory {
	constructor() {
		super('default');
	}

	generateModel(event) {
		return new ImLabelModel();
	}

	generateReactWidget(event) {
		return <ImLabelWidget diagramEngine={this.engine} model={event.model} />;
	}
}



export class ImLabelModel extends DefaultLabelModel {
	constructor(options = {}) {
		super({
			type: 'default',
			...options
		});

    this.registerListener({
      textChanged: (e) => {
        if (this.getLabel() === e.text) return;
        this.setLabel(e.text || "");
      }
    })
	}

  getLabel() {
    return this.options.label;
  }

	setLabel(label="") {
		this.options.label = label;
	}

  getWidget() {
    return this.$widget;
  }

  forceWidgetToUpdate() {
    this.getWidget() && this.getWidget().forceUpdate();
  }
}



export class ImLabelWidget extends DefaultLabelWidget {
  constructor(props) {
    super(props);
    this.props.model.$widget = this;
  }

  render() {
		return <div className="link-label">
              {this.props.model.getOptions().label}
            </div>
	}
}