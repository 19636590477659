import React, {Component} from "react";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {Grid, GridColumn as Column} from "@progress/kendo-react-grid";
import {Button} from "@progress/kendo-react-buttons";
import {TagInput} from "../util/stateless";
import {BasicConfirm} from "./BasicConfirm";
import {cloneDeep} from "lodash";
import { _ajax } from "../../requests/sml-requests";
import PhenomId from "../../requests/phenom-id"
import NavTree from "../tree/NavTree";
import { receiveLogs, receiveErrors } from "../../requests/actionCreators";

class TagsEditor extends Component {
    state = {
        visible: false,
        allTags: [],
        newTag: "",
        originalNodes: [],
        nodes: [],
        idCtx: "",
    };

    constructor(props) {
        super(props);
        TagsEditor.__singleton = this;
    }

    static show(originalNodes) {
        if (originalNodes) {
            TagsEditor.__singleton.setState({
                originalNodes,
                nodes: cloneDeep(originalNodes),
                allTags: NavTree.getAllTags(),
            });
        }
        TagsEditor.__singleton.__show();
    }

    static hide() {
        TagsEditor.__singleton.__hide();
    }

    // static linkToTagSection(el) {
    //     TagsEditor.__singleton.setState({tagsSectionEl: el});
    // }

    __show() {
        TagsEditor.__singleton.setState({visible: true});
    }

    __hide() {
        TagsEditor.__singleton.setState({visible: false});
    }

    closeConfirm = () => {
        this.__hide();

        if (this.diffTags().length) {
            BasicConfirm.show("You have made unsaved changes to these tags. Are you sure that you want to exit this editor?", () => {
                this.__hide();
            }, () => {
                this.__show();
            });
        }
    };

    addTag = tagVal => {
        let nodes = [...this.state.nodes];
        if (tagVal.trim() !== "") {
            nodes = nodes.map(e => {
                if (!e.tags.includes(tagVal)) {
                    e.tags.push(tagVal);
                }
                return e;
            });
            this.setState({
                nodes
            });
        }
    };

    removeTag = (nodeGuid, tagIdx) => {
        let nodes = [...this.state.nodes];
        const nodeIdx = nodes.findIndex(e => e.guid === nodeGuid);
        nodes[nodeIdx].tags.splice(tagIdx, 1);
        this.setState({
            nodes
        });
    };

    diffTags = () => {
        let edits = [];

        this.state.nodes.forEach((node, idx) => {
            this.state.nodes[idx].tags.forEach(el => {
                if (!this.state.originalNodes[idx].tags.includes(el)) {
                    edits.push(`add,${node.guid},${el}`);
                }
            });

            this.state.originalNodes[idx].tags.forEach(el => {
                if (!this.state.nodes[idx].tags.includes(el)) {
                    edits.push(`remove,${node.guid},${el}`);
                }
            });
        });

        return edits;
    };

    saveTags = () => {
        const edits = this.diffTags();

        _ajax({
            method: "post",
            url: "/index.php?r=/edit/change-tag",
            data: {diffs: edits.join(";") + ";"}
        }).then((resp) => {
            receiveLogs("Successfully updated tags");
            window.dispatchEvent(new CustomEvent('UPDATE_NODE_TAGS', { detail: this.state.nodes }));
            this.__hide();
        });
    };

    render() {
      const phenomId = new PhenomId("tags-editer",this.props.idCtx);
        return (<div id={phenomId.gen("","wrapper")}>
            <BasicConfirm idCtx={phenomId.gen("")}/>
            {this.state.visible && <Dialog title="Edit tags" onClose={this.closeConfirm}>
                <div style={{margin: "5px"}}>
                    <div className="flex-v">
                        <Grid
                            id={phenomId.gen("grid","")}
                            data={this.state.nodes}
                            style={{maxHeight: "250px", width: "420px", marginBottom: 15}}
                        >
                            <Column title="NODENAME" field="name"/>
                            <Column title="TAGS" cell={(props) => {
                                return (
                                    <td style={{margin: 0}} id={phenomId.gen("tags")}>
                                        {!props.dataItem.tags.length && "n/a"}
                                        {props.dataItem.tags.map((tag, idx) => {
                                            return (
                                                <Button className="tag-editor-tag icon-text-btn" iconClass="fa fa-times"
                                                        onClick={() => {
                                                            this.removeTag(props.dataItem.guid, idx);
                                                        }} key={idx}
                                                        id={phenomId.gen(["tags",idx],"remove-button")}>
                                                    {tag}
                                                </Button>);
                                        })}
                                    </td>
                                );
                            }}/>
                        </Grid>
                        Add New Tags:
                        <TagInput style={{width: "420px"}} data={this.state.allTags}
                                  value={this.state.newTag}
                                  onAdd={(val) => {
                                      this.addTag(val);
                                  }}
                                  idCtx={phenomId.gen("init")}/>
                    </div>
                </div>
                <DialogActionsBar>
                    <button className="k-button k-primary" onClick={this.closeConfirm} id={phenomId.gen("init","cancel-button")}>Cancel</button>
                    <button className="k-button" onClick={() => {
                        this.saveTags();
                    }}
                    id={phenomId.gen("init","save-button")}>Save
                    </button>
                </DialogActionsBar>
            </Dialog>}
        </div>);
    }
}




export default TagsEditor;
