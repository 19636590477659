import { cloneDeep } from 'lodash';
import * as actionTypes from '../requests/actionTypes'
import { updateState } from './rootReducer'

const initialState = {
  activeChangeSetId: undefined,
  changeSets: [],
  subModels: {},
  health_report: {},
  project_settings: {},
}



const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RECEIVE_CRITICAL_DETAILS:
      return updateState(state, { project_settings: action.details?.projectSettings || {} });

    case actionTypes.RECEIVE_HEALTH_REPORT:
      return updateState(state, { health_report: action.health_report });

    case actionTypes.RECEIVE_GENERATE_SETTINGS:
      return updateState(state, { project_settings: action.project_settings });

    case actionTypes.REMOVE_DEPRECATION_ISSUES:
      const guids = [...action.guids];
      if (!guids.length) return;
      const healthReportCopy = cloneDeep(state.health_report);
      const { deprecation_issues_paths } = healthReportCopy;

      guids.forEach(guid => {
        // iterate through charProj and assocEnt path issues and remove node
        for (const nodeType of Object.values(deprecation_issues_paths)) {
          if (nodeType) {
            for (const [compGuid, pathData] of Object.entries(nodeType)) {
              pathData.chars.forEach(char => {
                if (char.guid === guid) {
                  // remove char, and remove node if all chars are removed
                  nodeType[compGuid].chars = nodeType[compGuid].chars.filter(char => char.guid !== guid);
                  if (!nodeType[compGuid].chars.length) delete nodeType[compGuid];
                }
              });
            }
          }
        }
      });

      return updateState(state, { health_report: healthReportCopy });

    case actionTypes.RECEIVE_CHANGE_SETS:
      const { changeSets=[] } = action;
      changeSets.unshift({
        id: "",
        name: "None",
        description: "",
        date: "",
        parent: "",
      })
      return updateState(state, { changeSets });

    case actionTypes.RECEIVE_CHANGE_SET_ID:
      const activeChangeSetId = action.activeChangeSetId || undefined;    // "None" will return empty string - handler expects undefined
      return updateState(state, { activeChangeSetId });

    case actionTypes.RECEIVE_SUBMODELS:
      return updateState(state, { subModels: action.subModels });

    case actionTypes.RESET_APP:
      return initialState;

    case actionTypes.CLEAR_CRITICAL_DETAILS:
          return updateState(state, {projectSettings: {}});
      
    default:
      return state;
  }
}




export default appReducer;