import React, {Component} from "react";
import { connect } from "react-redux";
import {fetchModelTree} from "./branch";
import {Grid, GridColumn as Column, GridNoRecords} from "@progress/kendo-react-grid";
import { LineLabel } from "../util/stateless";
import {Notifications, Notifications2} from "../edit/notifications";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import PhenomId from "../../requests/phenom-id";
import {cloneDeep} from "lodash";
import { _ajax } from "../../requests/sml-requests";
import loadingIcon from "../../images/Palette Ring-1s-200px.gif";

import $ from "jquery";


class UserPModelManager extends Component {
  constructor(props) {
      super(props);

      this.state = {
          user:[],
          selected: null,
          usersTable: [],
          pTree: [],
          mTree: [],
          errorMessage: false,
          processingType: null,
          processingIdx: -1,
      };

      this.mTreeOriginal = {};
      this.pTreeOriginal = {};
      this.noticeRef = undefined;
  }

// needs account name from accountInfo of subscription
  componentDidMount() {
      this.loadAccountData();
      this.refreshModels();
  }

  refreshModels() {
    this.fetchAvailableProjects();
    this.fetchAvailableModels();
  }

  loadAccountData = () => {
      const isSkaylPage = this.props.match.params.main_page === "skayl";
      const request = {
        url: "/index.php?r=/account/info"
      }
      if (isSkaylPage) {
        request.url = "/index.php?r=/skayl/account-manager-info";
        request.method = "post";
        request.data = {
          username: this.props.match.params.username
        }
      }

      _ajax(request).then(res => {
          let resp = res.data.accountInfo;
          let removalIndex;
          let usersTable = resp.usersTable.map(user => ({
              ...user,
              active: user.active,
              userIsAdmin: user.isAdmin,
              currentUser: user.name === this.props.userIdentity?.username,
          }))
          let user = usersTable.find((table, i) => {
            removalIndex = i
            return table.name === this.props.match.params.username})

          this.setState({
              ...resp,
              user: user,
              usersTable: usersTable,
              activeInTable: resp.usersTable.filter(user => user.active).length,
              loading: false,
          }, this.determineBaseProjects);
      });
  };

    deleteProjectAndModels = (projectId, modelIds, overide = false) => {
      const isSkaylPage = this.props.match.params.main_page === "skayl";

      return _ajax({
            url: "/index.php?r=/referencing-model/delete-models-sub-models-or-branch",
            method: "post",
            data: {
                modelIds: [projectId],
                subModelIds: modelIds,
                branchId: null,
                accountName: this.state.accountName,
                workspaceSwitchList: null,
                secondPass: overide,    //if true, will override the model/sub-model validation & force delete
                page: isSkaylPage ? "skayl_admin" : "account_admin",
            },
        }).then(resp => {
            if (resp.data?.deleted === true) {
                PModelDeleteManager.hide();
                this.setState({
                  deletable: false, 
                  processingType: null, 
                  processingIdx: -1,
                });
                this.noticeRef.info("Project and model/s were successfully deleted.");
                this.refreshModels();
            }
            else if (resp.data?.warnings != null || resp.data?.errors != null || resp.data?.error != null) {
                this.setState({
                    deletable: true,
                    warningMessage: resp.data.warnings,
                    errorMessage: resp.data.errors || resp.data.error,
                    processingType: null,
                    processingIdx: -1,
                });
            }
            else {
                PModelDeleteManager.hide();
                this.setState({
                  deletable: false, 
                  processingType: null,
                  processingIdx: -1,
                });
                this.noticeRef.error("An unexpected error occurred. Please try again or contact Skayl Support for help.");
            }
        });
    };

    modelManage = (rowIdx, treeType, action) => {
      let selected = this.state[treeType][rowIdx];

      this.setState({ 
        processingType: treeType, 
        processingIdx: rowIdx 
      });

      switch(action){
        case "assign":
            PermissionsManager.show(selected, treeType);
        break;
        case "delete":
            PModelDeleteManager.show(selected, treeType);
        break;
      }
      treeType === "pTree" ? this.fetchAvailableProjects() : this.fetchAvailableModels();
    }

    fetchAvailableProjects = () => {
        return _ajax({
            url: "/index.php?r=/referencing-model/users-index",
            data: {passedUser: this.props.match.params.username}
        }).then((res) => {
            const data = res.data.usersModels;
            const pTree = [];

            data.filter(el => !/^$|.*i.*/.test(el.permissions[this.props.match.params.username])).forEach(el => {
               const {id, name, description, permissions, subModels} = el;
               // doing this to help project/model submodel/model conversion
               let models = subModels

               pTree.push({
                    text: name,
                    highlighted: false,
                    additionalData: {
                        id,
                        description,
                        permissions
                    },
                    type: "project",
                    children: models.filter(model => !/^$|.*i.*/.test(model.permissions[this.props.match.params.username])).map(e => ({
                        text: e.name,
                        type: "model",
                        additionalData: {
                            description: e.description,
                            id: e.id,
                            permissions: e.permissions,
                            dependencies: e.dependencies,
                            family: e.family.filter(member => member.created || !member.model),
                        }
                    }))
                });
            });

            pTree.forEach(ele => {
              this.pTreeOriginal[ele.additionalData.id] = cloneDeep(ele);
            })
            this.setState({
               pTree: pTree,
               selected: null,
           }, this.determineBaseProjects);
        });
    };

    fetchAvailableModels = () => {
        return fetchModelTree(true, this.props.match.params.username).then((res) => {
            let mTree = [];
            let branches = Array.from(res);
            while (branches.length) {
                const branch = branches.shift();
                branch.children.forEach(branchRef => {
                    mTree.push(branchRef);
                    branches = branches.concat(branchRef.children);
                });
            }

            mTree = mTree.filter(leaf => !/^$|.*i.*/.test(leaf.additionalData.permissions[this.props.match.params.username]));
            mTree.forEach(ele => this.mTreeOriginal[ele.id] = cloneDeep(ele));

            this.setState({mTree, selected: null}, 
              this.determineBaseProjects)
        });
    };

    updatePModelPermissions = (selected, permissions) => {
      const isSkaylPage = this.props.match.params.main_page === "skayl";
      const path = selected.type === "project" ? "/index.php?r=/referencing-model/set-permissions-as-admin" : "/index.php?r=/sub-model/set-permissions-as-admin";
      const oldPerms = selected.additionalData.permissions;
      let newPerms = [];
      
      for (let user in oldPerms) {
        let role = oldPerms[user]
        let newData = permissions.find(e => e.username === user)
        if (newData) {
          role = newData.role;
        }
        newPerms.push(role)
      }

      if (newPerms.join("").replace("n", "") === "") {
        this.setState({
          processingType: null,
          processingIdx: -1,
        })
        this.noticeRef.error("Project/Model must be assigned to at least one user, otherwise consider deleting.");
        return ;
      }

      if(!permissions.length) {
        this.setState({
          processingType: null,
          processingIdx: -1,
        })
        return this.noticeRef.note("No change detected", "warning");
      };

      _ajax({
        url: path,
        method: "POST",
        data: {
          permissions,
          page: isSkaylPage ? "skayl_admin" : "account_admin",
        }
      }).then((response) => {
        Notifications2.parseResponse(response);

        if(response.status === 'success') {
          selected.type === "project" ? this.fetchAvailableProjects() : this.fetchAvailableModels();
        }

        this.clearLoading();
      }).catch((err) => {
        this.clearLoading();
      })

      // Promise.all(updateReqs).then((responses) => {
      //   const successCount = responses.reduce((acc, curr) => (curr === "#true" ? acc + 1 : acc), 0);
      //   const errorRes = responses.find(response => response !== "#true");

      //   if(errorRes) Notifications2.parseResponse(JSON.parse(errorRes));
      //   if(successCount > 0) {
      //     Notifications2.parseLogs([`${successCount} Permission(s) successfully updated.`]);
      //     selected.type === "project" ? this.fetchAvailableProjects() : this.fetchAvailableModels();
      //   }
      // })
    };

    clearLoading = () => {
      this.setState({ loading: false, processingType: null, processingIdx: -1 });
    }

    clearDeletable = () => {
      this.setState({deletable: false, errorMessage: false, warningMessage: false, processingType: null, processingIdx: -1});
    }


    render(){
        const phenomId = new PhenomId("user-pmodel-manager",this.props.idCtx);
        return (<div>
            <Notifications ref={ele => this.noticeRef = ele}/>
            <LineLabel text={`${this.state.user.name} Project/Model Management`} className="subview-wrapper" style={{margin: "20px 0px 20px 10px", fontSize:20}} id={phenomId.gen("init")}/>
            <Notifications ref={ele => this.noticeRef = ele} />
            <div className="flex-h" style={{margin: "0px 10px 0px 10px"}}>
              {this.state.pTree && <PModelTable tree={this.state.pTree} user={this.state.user} updatePModelSelection={this.updatePModelSelection}
                updateAssignee={this.updateAssignee} modelManage={this.modelManage}
                setPermissions={this.setPermissions} updateModelOrSub={this.updateModelOrSub} selected={this.state.selected}
                processingType={this.state.processingType} processingIdx={this.state.processingIdx}
                 treeType="pTree" id={phenomId.gen("init","ptree")}/>}

              {this.state.mTree && <PModelTable tree={this.state.mTree} user={this.state.user} updatePModelSelection={this.updatePModelSelection}
                updateAssignee={this.updateAssignee} modelManage={this.modelManage}
                setPermissions={this.setPermissions} updateModelOrSub={this.updateModelOrSub} selected={this.state.selected}
                processingType={this.state.processingType} processingIdx={this.state.processingIdx}
                 treeType="mTree" id={phenomId.gen("init","mtree")}/>}
            </div>
              <PermissionsManager updatePModelPermissions={this.updatePModelPermissions} id={phenomId.gen("init")} />
              <PModelDeleteManager deleteProjectAndModels={this.deleteProjectAndModels} deletable={this.state.deletable} clearDeletable={this.clearDeletable}
                                   id={phenomId.gen("init")} warningMessage={this.state.warningMessage} errorMessage={this.state.errorMessage}/>
        </div>);
    }
}

class PModelTable extends Component {
    render(){
      let currentIndex = -1;
      // let currentUser = this.props.user.name === window['userIdentity']['username'];
      let showGrid = this.props.tree && this.props.tree.length >= 1;
      let type = this.props.treeType === "pTree" ? "Project" : "Model"
      const phenomId = new PhenomId("pmodel-table",this.props.idCtx);
      let rowIndex = 0;
      return(
        <div style={{height:"100%", margin: 5}} id={phenomId.gen("init","wrapper")}>
          <LineLabel text={type+"s"} className="subview-wrapper" style={{marginBottom: 10}} idCtx={phenomId.gen("init","label")}/>
          <Grid
              id={phenomId.gen("init","grid")}
              data={this.props.tree || []}
              className="delete-user-table"
              style={{width: "fit-content", maxHeight: "500px"}}
              rowRender={(_, props) => {
                  const loading = this.props.treeType === this.props.processingType && currentIndex + 1 === this.props.processingIdx;
                  const disabled = !!this.props.processingType && this.props.processingIdx > -1;

                  return <ModelRow key={props.dataIndex} data={props.dataItem} rowIdx={++currentIndex} updatePModelSelection={this.props.updatePModelSelection} loading={loading} disabled={disabled}
                  updateAssignee={this.props.updateAssignee} modelManage={this.props.modelManage} finalModel={this.props.tree.length === 1} tree={this.props.tree}
                  setPermissions={this.props.setPermissions} updateModelOrSub={this.props.updateModelOrSub} treeType={this.props.treeType} idCtx={phenomId.gen(["init",rowIndex++])}/>
              }}>
              <GridNoRecords>
                <div>User has no more {type} assignments.</div>
              </GridNoRecords>
              {showGrid && <Column title="Model" width={200}/>}
              {showGrid && <Column title="Description"/>}
              {showGrid && <Column title="Action" width={170}/>}
              {!showGrid && <Column/>}
            </Grid>
        </div>
      )
    }
}


class ModelRow extends Component {
    render() {
        const leaf = this.props.data;
        const rowIdx = this.props.rowIdx;        const treeType = this.props.treeType;
        const phenomId = new PhenomId("model-row",this.props.idCtx);

        return (<tr style={{backgroundColor: leaf.highlighted ? "#4bc5bade" : null}}
                          id={phenomId.gen("","wrapper")}>
                <td style={{padding:'1'}}>
                    <span style={{marginLeft: 3}} id={phenomId.gen("","text")}>{leaf.text}</span>
                </td>
                <td style={{padding:'1'}}>
                    <span style={{marginLeft: 3}} id={phenomId.gen("","description")}>{leaf.additionalData.description}</span>
                </td>

                <td style={{padding:'1', margin: 0}}>
                  <div style={{display:"flex", justifyContent:"flex-center"}}>
                    {this.props.loading ? 
                      <img id="loading-spinner" style={{ width: 50 }} src={loadingIcon} alt="Loading..." /> 
                      : <>
                      <button className="k-button k-primary" style={{margin:5}}
                            onClick={() => {this.props.modelManage(rowIdx, treeType, "assign")}}
                            disabled={this.props.disabled}
                            id={phenomId.gen("","assign-button")}>Assign
                      </button>
                      <button className="k-button k-primary" style={{margin:5}}
                            onClick={() => {this.props.modelManage(rowIdx, treeType, "delete")}}
                            disabled={this.props.disabled}
                            id={phenomId.gen("","delete-button")}>Delete
                      </button>
                    </> }
                  </div>
                </td>
              </tr>
        )
    }
}

class PermissionsManager extends Component {
  state = {
     visible: false,
     allowClose: true,
     deletable: false,
     permissions: [],
     accountRoles: []
 };

   constructor(props) {
       super(props);
       PermissionsManager.__singleton = this;
   }

   static show(selected, treeType, allowClose = true) {
       if (!PermissionsManager.__singleton) new PermissionsManager();

       const permissions = Object.entries(selected.additionalData.permissions)
       .map((e) => ({
        username: e[0],
        role:     e[1] || "",
      }));
  
      const sortedPermissions = sortAlphabeticalPermissions(permissions);

       PermissionsManager.__singleton.setState({
           selected,
           treeType,
           allowClose,
           permissions,
           sortedPermissions,
           accountRoles: this.determineAccountRoles(selected)
       });

       PermissionsManager.__singleton.__show();
   }

   static hide() {
       PermissionsManager.__singleton.__hide();
   }

   __show() {
       PermissionsManager.__singleton.setState({visible: true});
   }

   __hide() {
       PermissionsManager.__singleton.setState({
           visible: false,
           allowClose: true
       });
   }

  static determineAccountRoles = (selected) => {
    const permissions = Object.entries(selected.additionalData.permissions).map(e => ({ username: e[0], role: e[1] || "" }))
    const roleStrings = permissions.map((user) => user.role);
    const roles = [...(new Set(roleStrings.join("").split("")))];

    return roles;
  }

  sortPerms = (perms) => {
    const ownerPerms = /.*o.*/.test(perms.role);
    const adminPerms = /.*a.*/.test(perms.role);
    const writePerms = /.*w.*/.test(perms.role);
    const readPerms = /.*r.*/.test(perms.role);

    let sorted = "";
    if (ownerPerms) sorted += "o";
    if (adminPerms) sorted += "a";
    if (writePerms) sorted += "w";
    if (readPerms) sorted += "r";
    return {
      username: perms.username,
      role: sorted
    };
  }

   serialize = () => {
    const { permissions, selected } = this.state;
    const request = [];

    for (let newPerm of permissions) {
      const prevRole = Object.entries(selected.additionalData.permissions).find(data => data[0] === newPerm.username)

      if (prevRole[1] !== newPerm.role) {
        if (this.state.selected.type === "project") {
          request.push({ modelId: selected.additionalData.id, ...this.sortPerms(newPerm) });
        } else {
          request.push({ subModelId: selected.additionalData.id, ...this.sortPerms(newPerm) });
        }
      }
    }

    return request;
  }

   handleEditPerms = (user, changedRole) => {
    const { permissions } = this.state;

    const permsData = permissions.find(data => data.username === user);
    permsData.role = permsData.role.includes(changedRole) ? permsData.role.replace(changedRole, "") : permsData.role + changedRole;

    if (!/.*r.*/.test(permsData.role) && /.*[oaw].*/.test(permsData.role)) {
      permsData.role += "r";
    }

    if (!/.*a.*/.test(permsData.role) && /.*o.*/.test(permsData.role)) {
      permsData.role += "a";
    }

    this.setState({ permissions  });
  }

   renderPermsRow = (_, props) => {
    const { sortedPermissions, accountRoles } = this.state;
    const data = props.dataItem;

    const permsData = sortedPermissions.find(perms => perms.username === data.username);
    const ownerPerms = /.*o.*/.test(permsData.role);
    const adminPerms = /.*a.*/.test(permsData.role);
    const writePerms = /.*w.*/.test(permsData.role);
    const readPerms = /.*r.*/.test(permsData.role);

    const isSkaylPage = /.*skayl.*/.test(window.location);
    const readDisabled = writePerms || adminPerms || ownerPerms || !accountRoles.find((e) => e === "r");
    const writeDisabled = !isSkaylPage && !accountRoles.find((e) => e === "w");
    const adminDisabled = !isSkaylPage && !accountRoles.find((e) => e === "a") || ownerPerms;
    const ownerDisabled = !isSkaylPage && !accountRoles.find((e) => e === "o");

    return <tr id={`perm-${data.username}`}>
      <td>{ data.username }</td>
      <td>
          <input type="checkbox"
                 checked={readPerms}
                 onChange={(e) => this.handleEditPerms(data.username, "r")}
                 disabled={readDisabled} />
      </td>
      <td>
          <input type="checkbox"
                 checked={writePerms}
                 onChange={(e) => this.handleEditPerms(data.username, "w")} 
                 disabled={writeDisabled} />
      </td>
      <td>
          <input type="checkbox"
                 checked={adminPerms}
                 onChange={(e) => this.handleEditPerms(data.username, "a")}
                 disabled={adminDisabled} />
      </td>
      <td>
          <input type="checkbox"
                 checked={ownerPerms}
                 onChange={(e) => this.handleEditPerms(data.username, "o")}
                 disabled={ownerDisabled} />
      </td>
    </tr>
  }

  render(){
    let selected = this.state.selected;
    const phenomId = new PhenomId("permissions-manager",this.props.idCtx);

    const score = (role) => {
      return (/.*o.*/.test(role) ? 4 : 0) + 
             (/.*a.*/.test(role) ? 2 : 0) + 
             (/.*w.*/.test(role) ? 1 : 0) + 
             (/.*r.*/.test(role) ? 1 : 0)
     }

    return(
      <div style={{width:'100%', height: "100%", marginTop: 10}} id={phenomId.gen("","wrapper")}>
          {this.state.visible && <Dialog title={`Change user permissions for ${selected.text}`} onClose={() => {
              this.__hide();
          }} className={this.state.allowClose ? "" : "dialog-no-exit_edit"}
          width={"50%"}>
          <Grid
            id={phenomId.gen("","grid")}
            style={{height:"100%"}}
            data={this.state.sortedPermissions}
            className="branch-manage-table"
            rowRender={this.renderPermsRow}>
            <Column title="USER" />
            <Column title='READ' width="75px" />
            <Column title='WRITE' width="75px" />
            <Column title='ADMIN' width="75px" />
            <Column title='OWNER' width="75px" />
          </Grid>        
        <DialogActionsBar>
            <button className="k-button" onClick={() => {
                this.__hide();
            }}
            id={phenomId.gen("","cancel-button")}>Cancel
            </button>

            <button disabled={false} className="k-button k-primary" onClick={() => {
                this.props.updatePModelPermissions(selected, this.serialize());
                this.__hide();
            }}
            id={phenomId.gen("","assign-button")}>Assign
            </button>
        </DialogActionsBar>
      </Dialog>}
    </div>)
  }
}

function sortAlphabeticalPermissions(arr) {
  // Separate users with and without read permissions
  const withReadPermissions = arr.filter((perm) => perm.role.includes('r'));
  const withoutReadPermissions = arr.filter((perm) => !perm.role.includes('r'));

  // Sort each group alphabetically by username
  const sortedWithRead = withReadPermissions.sort((a, b) => a.username.localeCompare(b.username));
  const sortedWithoutRead = withoutReadPermissions.sort((a, b) => a.username.localeCompare(b.username));

  // Concatenate sorted groups
  return [...sortedWithRead, ...sortedWithoutRead];
}

class PModelDeleteManager extends Component {
  state = {
     visible: false,
     allowClose: true,
     value: [],
 };

   constructor(props) {
       super(props);
       PModelDeleteManager.__singleton = this;
   }

   static show(selected, treeType, allowClose = true) {
       if (!PModelDeleteManager.__singleton) new PModelDeleteManager();

       PModelDeleteManager.__singleton.setState({
           selected,
           treeType,
           allowClose,
       });

       PModelDeleteManager.__singleton.__show();
   }

   static hide() {
       PModelDeleteManager.__singleton.__hide();
   }

   __show() {
       PModelDeleteManager.__singleton.setState({visible: true});
   }

   __hide() {
       PModelDeleteManager.__singleton.setState({
           visible: false,
           allowClose: true,
           value: [],
       });
       this.props.clearDeletable()
   }

   handleChange = (event) => {
        this.setState({
            value: event.target.value
        });
    }

  render(){
    let child = false;
    let selected = this.state.selected;
    let treeType = this.state.treeType;
    let pmodel = treeType === "pTree" ? "project" : "model";
    const phenomId = new PhenomId("pmodel-delete-manager",this.props.idCtx);

    return(
      <div id={phenomId.gen("","wrapper")}>
          {this.state.visible && <Dialog title={`Delete ${pmodel} ${selected.text}?`} onClose={() => {
              this.__hide();
          }} className={this.state.allowClose ? "" : "dialog-no-exit"}>
          {this.props.warningMessage && <div>Warning: {this.props.warningMessage}</div>}
          {this.props.errorMessage ? <div>Error: {this.props.errorMessage}</div> : <div>
          <div>You are attempting to permanently remove the {pmodel} {selected.text}{treeType === "mTree" && ", are you Sure?"}</div>
          {treeType === "pTree" && <div>Select any models to delete with the project.</div>}
          {treeType === "pTree" ?
          <MultiSelect
                    id={phenomId.gen("","ptree-multi-select")}
                    data={selected.children.map(child => child.text)}
                    value={this.state.value}
                    onChange={this.handleChange}
                />
        : null}</div>}

        <DialogActionsBar>
            <button className="k-button" onClick={() => {
                this.__hide();
            }}
            id={phenomId.gen("","cancel-button")}>Cancel
            </button>

            <button disabled={this.props.errorMessage} className="k-button k-primary" onClick={() => {
                if (treeType === "pTree") {
                  let modelIds = this.state.value.length
                                    ? selected.children.filter(child => this.state.value.includes(child.text)).map(child => child.additionalData.id.toString())
                                    : null;
                  this.props.deleteProjectAndModels(selected.additionalData.id.toString(), modelIds, this.props.deletable);
                }else{
                  this.props.deleteProjectAndModels(null, [selected.additionalData.id.toString()], this.props.deletable);
                }
            }}
            id={phenomId.gen("","confirm-or-delete-button")}>{this.props.deletable ? "Confirm" : "Delete"}
            </button>
        </DialogActionsBar>
      </Dialog>}
    </div>)
  }
}


const msp = (state) => ({
  userIdentity: state.user.userIdentity,
})


export default connect(msp)(UserPModelManager);