import React from "react";
import {Notifications, Notifications2} from "../notifications";
import {CadetInput, CadetTextArea, LineLabel, PackageComboBox} from "../../util/stateless";
import {NodeHistory2} from "../node-history";
import {Tags} from "../../util/tags";
import EntityChildsView from "./entity_childs_view";
import TyperView from "./typer_view";
import DeletionConfirm2 from "../../dialog/DeletionConfirm2";

import {
    getNodesOfType,
    runEntityUniquenessReport,
    smmDeleteNodes,
} from "../../../requests/sml-requests";
import PhenomId from "../../../requests/phenom-id";
import {NavLink} from "react-router-dom";
import $ from "jquery";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import { withPageLayout } from "../node-layout"
import { createNodeUrl } from "../../../requests/type-to-path";
import ChangeSetPicker from "../../widget/ChangeSetPicker";
import { getActiveChangeSetId, receiveResponse, removeNodes } from "../../../requests/actionCreators";
import {createPhenomGuid, isPhenomGuid, scrubNodeExport} from "../../util/util";
import NavTree from "../../tree/NavTree";
import { cloneDeep } from "lodash";
import { BasisEntityGrid } from "../edit-domain-manager";
import { _ajax } from "../../../requests/sml-requests";


export class EntityManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            guid: undefined,
            name: "loading...",
            xmiType: "",
            description: "loading...",
            children: [],
            typers: [],
            parent: "",
            parent_name: "",
            generalization: [],
            generalizationErrors: [],
            specializesOptions: [],
            specializes: "",
            changedSpecialization: false,
            specChangeDeets: {deletedCompositions: [], addedCompositions: [], descendents: [], deprecations: []},
            specializedBy: [],
            descendents: [],
            addUIDToNewEntities: ((localStorage.getItem("addUIDToNewEntities") === null) || localStorage.getItem("addUIDToNewEntities") === "true"),
            uniquenessErrors: undefined,
            typeOptions: [],
            compSort: [],
            assocInSort: [],
            availableRollups: [],
            basisEntity: [],
            editable: true,
            deprecated: false,
            changeSetId: "",
            childrenHaveChanged: false,
            changeSetId: "",
        };

        this.fieldRefs = {};
        this.original = {};
        this.childViews = undefined;
        this.noticeRef = undefined;
        this.basisEntRef = React.createRef();
    }

    componentDidMount() {
        this.loadEntityData(this.props.match.params.guid);
        window.addEventListener('MOVED_NODES', this.mutateOriginalParentListener);
    }

    componentDidUpdate(prevProps, prevState) {
        for (let id in this.fieldRefs) { //before each render, cleans out the field reference collection and allows a fresh rebuild at render time
            if (!this.fieldRefs[id]) {
                delete this.fieldRefs[id];
            }
        }
        const currGuid = this.props.match.params.guid;
        const prevGuid = prevProps.match.params.guid;
        if (currGuid !== prevGuid) {
            if (prevGuid) {
                this.loadEntityData(currGuid);
            }
        }

        if (prevState.subModelId !== this.state.subModelId ||
            prevProps.subModels !== this.props.subModels) {
                this.setEditingStatus();
        }
    }

    componentWillUnmount() {
      window.removeEventListener('MOVED_NODES', this.mutateOriginalParentListener);
    }

    sortAndMapTypeOptions = (optionsList) => {
        return optionsList.sort(function (x, y) {
            if (x.xmiType === y.xmiType) {
                if (x.name < y.name) {
                    return -1;
                }
                if (x.name > y.name) {
                    return 1;
                }
                return 0;
            } else {
                if (x.xmiType < y.xmiType) {
                    return -1;
                }
                if (x.xmiType > y.xmiType) {
                    return 1;
                }
                return 0;
            }
        }).map(e => ({name: e.name, guid: e.guid, xmiType: e.xmiType, deprecated: e.deprecated}));
    };

    loadEntityData(guid) {
        if (guid !== null && guid !== "" && guid !== "new") {
            $.ajax({
                url: "/index.php?r=/entity/page-data",
                method: "get",
                data: {guid: guid},
            }).then(res => {
                const response = JSON.parse(res);
                response.children.sort((a, b) => (b.xmiType === "conceptual:Composition") - (a.xmiType === "conceptual:Composition"));

                // convert parent to guid
                if (response.parent?.guid) {
                  response.parent = response.parent.guid;
                }

                if (this.props.updateTemplateNode) {
                    this.props.updateTemplateNode(response);
                }

                this.original = cloneDeep(response);
                this.setStateFromResponse(response);
                this.original.deletions = [];
                NavTree.addNodes(response);     // when deleting a child, loadEntityData is called again
            }).fail((err) => {
                err.status === 500 && this.props.renderPageNotFound && this.props.renderPageNotFound();
            });
        } else {
            this.original = {
                guid: "",
                name: "",
                xmiType: "conceptual:Entity",
                description: "",
                typers: [],
                parent: "",
                parent_name: "",
                generalization: [],
                generalizationErrors: [],
                specializes: "",
                specializedBy: [],
                changedSpecialization: false,
                descendents: [],
                children: [],
                availableRollups: [],
                basisEntity: [],
                deprecated: false,
                changeSetId: "",
                childrenHaveChanged: false,
            };
            this.setState(this.original);
            this.fieldRefs = {};
        }
        getNodesOfType(["conceptual:Entity", "conceptual:Association", "conceptual:Observable"]).then(res => {
            let optionsList = res.data.nodes;
            this.initialTypeOptions = optionsList;

            this.setState({
                typeOptions: this.sortAndMapTypeOptions(optionsList),
                specializesOptions: optionsList.filter(e => e.xmiType !== "conceptual:Observable" && e.guid !== this.props.match.params.guid).sort((a, b) => b.name > a.name)
            });
        });
    }

    setStateFromResponse(response) {
        const uidComps = response.children.filter(e => e.type.guid === this.props.model_uid);
        response.uidPresence = !!uidComps.length;
        response.typers.forEach(typer => {
            typer.parent_name = typer.parent.name;
            typer.parent_id = typer.parent.guid;
            typer.parent_description = typer.parent.description;
        });
        this.setState({changedSpecialization: false, childrenHaveChanged: false});
        this.setState({ ...response }, () => {
            this.historyRef.updateHisotry();
            this.setEditingStatus();
            runEntityUniquenessReport().then(res => {
                const {entity_issues} = res.data;
                const errorSet = entity_issues.find(e => this.state.guid in e);
                this.setState({
                    uniquenessErrors: errorSet ? Object.entries(errorSet).filter(([k]) => k !== this.state.guid).map(([k, v]) => v).join("\n") : undefined
                });
                //if (response.deprecated === "true"){this.setState({editable: false})};
            });
        });
    }

    isChanged() {
        return this.state.name !== this.original.name
            || this.state.description !== this.original.description
            || this.state.xmiType !== this.original.xmiType
            || this.state.specializes?.guid !== this.original.specializes?.guid
            || this.state.parent !== this.original.parent
            || this.state.children.length !== this.original.children.length
            || !this.state.children.every((child, index) => child.guid === this.original.children[index]?.guid)
            || (this.basisEntRef.current && this.basisEntRef.current.isEdited());   // isChanged is called in render method so we have to check if ref exist
    }

    getStateIfChanged() {
        if (this.isChanged()) {
            let res = {
                guid: this.state.guid || createPhenomGuid(),
                name: this.state.name,
                description: this.state.description,
                xmiType: this.state.xmiType,
                parent: this.state.parent || null,
                childOrder: this.state.children.map(child => child.guid),
            };

            // new Entity Page does not show BasisEntity
            if (this.basisEntRef.current) {
                res.basisEntity = this.basisEntRef.current.generateNode();
            }

            // smm rules for specializes/parent dont like an empty string
            if (this.state.specializes) {res.specializes = this.state.specializes.guid;}
            return res;
        } else {
            return false;
        }
    }

    refreshEntityParent() {
        window["treeRef"].fetchData();
    }

    handleDelete = () => {
        DeletionConfirm2.show(this.state.guid, this.state.name, this.refreshData);
        return null;
    };

    handleReset = () => {
        if (this.state.guid) {
            this.childViews.setState({deletions: []});
            this.setStateFromResponse({...cloneDeep(this.original), childrenHaveChanged: false});
        } else {
            this.setStateFromResponse({...this.original, childrenHaveChanged: false});
        }
    };

    determineOwnXmiType() {
        this.state.xmiType = this.childViews ? this.childViews.determineParentXmiType() : "conceptual:Entity";
    }

    saveChanges(ownChanges, changedChildren) {

        changedChildren.forEach(child => {
            child.parent = this.state.guid || null;
        });

        if (!ownChanges && !changedChildren.length) {
            this.noticeRef.error("No changes detected.");
        } else {
            let data;
            if (ownChanges) {
                if (this.state.changedSpecialization) {
                    changedChildren = [];
                    ownChanges.childOrder = [];
                }
                if (changedChildren.length) ownChanges.children = changedChildren;
                if (!this.state.guid && this.state.addUIDToNewEntities) {
                    ownChanges.children = [{
                        rolename: "elementID",
                        xmiType: "conceptual:Composition",
                        guid: createPhenomGuid(),
                        type: this.props.model_uid
                    }];
                }
                data = {
                    nodes: [ownChanges],
                    changeSetId: getActiveChangeSetId(),
                    returnTypes: ["conceptual:Entity", "conceptual:Association"],
                    returnAddenda: {
                        "conceptual:Entity": {
                            coreAddenda: ["childrenMULTI"],
                        },
                        "conceptual:Association": {
                            coreAddenda: ["childrenMULTI"],
                        },
                    }
                };
            } else {
                data = {
                    nodes: changedChildren,
                    changeSetId: getActiveChangeSetId()
                }
            }
            scrubNodeExport(data)

            return _ajax({
                url: "/index.php?r=/node/smm-save-nodes",
                method: "post",
                data: data,
            }).then(res => {
                const response = res.data;
                Notifications2.parseResponse(response);

                if (response.nodes) {
                    NavTree.addNodes(response.nodes);
                    const ent = response.nodes.find(node => node.xmiType === "conceptual:Entity" || node.xmiType === "conceptual:Association");

                    // new Entity was saved
                    if (ent && !this.state.guid) {
                        return this.props.history.push( createNodeUrl(ent) );
                    }
                    
                    // only attribute/participant was saved
                    return this.loadEntityData(ent?.guid || this.state.guid);
                } else if (this.state.changedSpecialization) {
                    this.handleReset();
                    this.setState({
                        specializationDialogVisible: false,
                        changedSpecialization: false,
                        specChangePermitted: false
                    });
                }
            });
        }
    }

    handleSave = () => {
        this.determineOwnXmiType();
        const ownChanges = this.getStateIfChanged();
        let {deletedChildren, changedChildren, errorChildren} = this.childViews ? this.childViews.gatherChangeData() : {
            deletedChildren: [],
            changedChildren: [],
            errorChildren: []
        };
        
        if (errorChildren.length) {
            return this.noticeRef.error(errorChildren);
        }
        if (deletedChildren.length) {
            return smmDeleteNodes(deletedChildren).then((response) => {
                receiveResponse(response);
                if (this.childViews) this.childViews.setState({deletions: []});
                this.saveChanges(ownChanges, changedChildren).then(() => {
                    removeNodes(deletedChildren);
                });
            });
        } else {
            return this.saveChanges(ownChanges, changedChildren);
        }
    };

    refreshData = () => {
        this.loadEntityData(this.state.guid);
    };

    setEditingStatus = () => {
        const { subModels={}, setParentEditingStatus } = this.props;
        const { subModelId } = this.state;
        const currSubModel = subModels[subModelId];
        this.setState({ editable: !currSubModel?.created }, () => {
            setParentEditingStatus && setParentEditingStatus(!currSubModel?.created)
        });
    };

    changeSpecialization = evt => {
        const newSpecializesGuid = evt.target.value;

        const {specializes: originalSpecializes} = cloneDeep(this.original);
        if (originalSpecializes && originalSpecializes.guid === newSpecializesGuid) {
            this.handleReset();
            return;
        }

        let deletedCompositions, addedCompositions, descendents, deprecations;

        if (newSpecializesGuid === "") {
            //clearing field
            return _ajax({
                url: "/index.php?r=/edit/change-spec",
                method: "get",
                data: {
                    guid: this.state.guid,
                    specializes: newSpecializesGuid
                }
            }).then(res => {
                deletedCompositions = this.state.children.filter(e => !!e.specializes);
                addedCompositions = [];
                let {deprecations, descendents} = res.data;

                this.childViews.setState({
                    deletions: [...this.childViews.state.deletions, ...deletedCompositions.map(e => e.guid)]
                });

                this.setState({
                    changedSpecialization: true,
                    specializes: "",
                    specChangeDeets: {deletedCompositions, addedCompositions: [], descendents, deprecations},
                    changeSetId: this.state.changeSetId,
                    specializationDialogVisible: true
                });
            });
        } else {
            //changing field
            return $.ajax({
                url: "/index.php?r=/entity/page-data",
                method: "get",
                data: {guid: newSpecializesGuid}
            }).then(res => {
                const newSpecializesNode = JSON.parse(res);

                _ajax({
                    url: "/index.php?r=/edit/change-spec",
                    method: "get",
                    data: {
                        guid: this.state.guid,
                        specializes: newSpecializesGuid
                    }
                }).then(res => {
                    this.setState({changedSpecialization: true});
                    let {deprecations, descendents} = res.data;
                    deletedCompositions = this.state.children.filter(e => !!e.specializes);
                    addedCompositions = newSpecializesNode.children;

                    this.childViews.setState({
                        deletions: [...this.childViews.state.deletions, ...deletedCompositions.map(e => e.guid)]
                    });

                    this.setState({
                        specializes: newSpecializesNode,
                        children: [
                            ...this.state.children.map(e => ({
                                ...e,
                                specializes: {}
                            })),
                            ...addedCompositions.map(e => ({
                                deleted: false,
                                description: e.description,
                                guid: createPhenomGuid(),
                                rolename: e.rolename,
                                parent_id: this.state.guid,
                                specializes: {guid: e.guid, rolename: e.rolename},
                                projectors: false,
                                showViews: false,
                                type: e.type,
                                xmiType: e.xmiType
                            }))
                        ],
                        changeSetId: this.state.changeSetId
                    }, () => {
                        if (document.getElementsByClassName("inherits-from")[0]) {
                            document.getElementsByClassName("inherits-from")[0].scrollIntoView({
                                block: "center",
                                behavior: "smooth"
                            });
                        }
                    });
                    this.setState({
                        specChangeDeets: {
                            deletedCompositions,
                            addedCompositions,
                            descendents,
                            deprecations
                        },
                        specializationDialogVisible: true
                    });
                });
            });
        }
    };

    specializationErrors = () => {
        const { childrenHaveChanged } = this.state;
        let errors = [];

        if (childrenHaveChanged || this.isChanged()) {
            errors.push("You have unsaved edits to this node");
        }

        return errors.length ? errors.join("\n") : null;
    };

    checkChildren = () => {
        if (!this.state.childrenHaveChanged && this.childViews) {
            const childrenChanges = this.childViews.gatherChangeData();

            if (childrenChanges.deletedChildren.length || childrenChanges.changedChildren.length) {
                this.setState({
                    childrenHaveChanged: true
                });
            }
        }
    };

    setChangeSetId = (e) => {
      this.setState({changeSetId: e.target.value});
    }

    mutateOriginalParentListener = (e) => {
      // invalid
      if (!this.state.guid) {
        return;
      }

      const leaf = NavTree.getLeafNode(this.state.guid);
      if (!leaf) return;

      const newParentGuid = leaf.getParentGuid();
      if (this.state.parent !== newParentGuid) {
        this.original["parent"] = newParentGuid;
      }
    }

    // updates the array of guids for childOrder
    updateChildren = (children) => {
        this.setState({ children })
    }

    render() {
        const { children } = this.state;
        const type = this.state.xmiType.substr(this.state.xmiType.indexOf(":") + 1).toUpperCase();
        const deprecated = this.state.deprecated === "true";
        const canEdit = this.props.editable;
        let deprecatedClassName = "subview-wrapper";
        deprecatedClassName += deprecated ? " deprecated" : "";
        const phenomId = new PhenomId("edit-entity",this.props.idCtx);

        return (
            <div className={deprecatedClassName} style={{position: "relative"}}>
                {this.state.specializationDialogVisible &&
                    <Dialog title="Review side effects" className="dialog-no-exit">
                        <div style={{margin: "5px"}}>
                            <div className="flex-v"
                            id={phenomId.gen("specialization-dialog")}
                            style={{
                                whiteSpace: "pre-line",
                                maxWidth: "calc(100vh - 300px)",
                                maxHeight: "calc(80vh - 340px)",
                                overflowY: "auto"
                            }}>

                                <b>NEW INHERITED ATTRIBUTES WILL BE CREATED:</b>
                                <ul>
                                    {(this.state.specChangeDeets.addedCompositions.length > 0) || <li>None</li>}
                                    {this.state.specChangeDeets.addedCompositions.map((entry, idx) => {
                                        return <li>{entry.rolename}</li>;
                                    })}
                                </ul>

                                <br />
                                <b>DISINHERITED ATTRIBUTES WILL BE DELETED:</b>
                                <ul>
                                    {(this.state.specChangeDeets.deletedCompositions.length > 0) || <li>None</li>}
                                    {this.state.specChangeDeets.deletedCompositions.map((entry, idx) => {
                                        return <li>{entry.rolename}</li>;
                                    })}
                                </ul>

                                <br />
                                <b>THE SAME CREATIONS/DELETIONS WILL OCCUR IN EACH DESCENDENT:</b>
                                <ul>
                                    {(this.state.specChangeDeets.descendents.length > 0) || <li>None</li>}
                                    {this.state.specChangeDeets.descendents.map((entry, idx) => {
                                        return <li>{entry.name}</li>;
                                    })}
                                </ul>

                                <br />
                                <b>DEPRECATIONS<sup>1</sup>:</b>
                                <ul>
                                    {(this.state.specChangeDeets.deprecations.length > 0) || <li>None</li>}
                                    {this.state.specChangeDeets.deprecations.map((entry, idx) => {
                                        return <li>{entry.rolename}</li>;
                                    })}
                                </ul>

                                <br />
                                <ol style={{paddingLeft: 15}}>
                                    <li>If any affected compositions are used in projection paths, they will be deprecated
                                    instead of deleted. These paths can be changed in bulk in Health Check.
                                </li>
                                </ol>

                                <br />
                                <span style={{display: "flex", alignItems: "center"}}>
                                    <input type="checkbox" style={{marginRight: 5}}
                                        id={phenomId.gen("specialization-dialog","checkbox")}
                                        className="locked-checkbox"
                                        onClick={() => {
                                            this.setState({specChangePermitted: true});
                                        }} disabled={this.state.specChangePermitted} />
                            I understand that there are {
                                        (this.state.specChangeDeets.deletedCompositions.length + this.state.specChangeDeets.addedCompositions.length)
                                        * (1 + this.state.specChangeDeets.descendents.length)} side-effects to this action!
                            </span>
                            </div>
                        </div>
                        <DialogActionsBar>
                            <button
                              className="k-button"
                              id={phenomId.gen("specialization-dialog","cancel-btn")}
                              onClick={() => {
                                this.handleReset();
                                this.setState({
                                    specializationDialogVisible: false,
                                    changedSpecialization: false,
                                    specChangePermitted: false
                                });
                            }}>Cancel
                        </button>
                            <button className="k-button k-primary" disabled={!this.state.specChangePermitted || !canEdit}
                                id={phenomId.gen("specialization-dialog","proceed-btn")}
                                onClick={() => {
                                    this.handleSave();
                                    this.setState({specializationDialogVisible: false, specChangePermitted: false});
                                }}>Proceed
                        </button>
                        </DialogActionsBar>
                    </Dialog>}

                {deprecated ? <strong>
                    <div className="deprecated-banner">WARNING: This node has been DEPRECATED</div>
                </strong> : null}

                <Notifications ref={el => this.noticeRef = el} idCtx={phenomId.genPageId()}/>
                <div className="flex-h">
                    <div className="flex-v" style={{flexGrow: 1}} id={phenomId.clear().gen("details")}>
                        <LineLabel text={type} idCtx={phenomId.genPageId("name")}
                            errorMsg={this.state.guid !== "" && this.state.uidPresence !== undefined && !this.state.uidPresence && `There are no Identifiers present on this ${type.toLowerCase()}`} />
                        <CadetInput text={this.state.name} onChange={(e) => this.setState({name: e.target.value})}
                            style={{marginBottom: 0}} disabled={!this.state.editable || deprecated}
                            idCtx={phenomId.genPageId("name")}/>
                        <LineLabel text="Description" style={{marginTop: 15}} idCtx={phenomId.genPageId("description")}/>
                        <CadetTextArea
                            text={this.state.description}
                            title={this.state.description}
                            onChange={(e) => this.setState({description: e.target.value})}
                            style={{marginBottom: 0}}
                            disabled={!this.state.editable || deprecated}
                            idCtx={phenomId.genPageId("description")}/>
                        <div className={"flex-h"} style={{justifyContent: "space-between"}}>
                          {!this.state.guid || <div style={{width: "48%"}}>
                              <LineLabel text="Specializes" style={{marginTop: 15}}
                                  errorMsg={this.specializationErrors()}
                                  idCtx={phenomId.genPageId("specializes")}/>
                              <select className="cadet-select" style={{margin: "5px 0 0 0", width: "100%", height: 30}}
                                  value={this.state.specializes?.guid || ""}
                                  disabled={this.state.changedSpecialization || this.state.childrenHaveChanged || this.isChanged()}
                                  onChange={this.changeSpecialization}
                                  id={phenomId.genPageId("specializes","select")}>
                                  <option value="">--- No Specialization ---</option>
                                  {this.state.specializesOptions.filter(e => !this.state.descendents.includes(e.guid)).map(e => {
                                      return <option value={e.guid} key={e.guid}>{e.name}</option>;
                                  })}
                              </select>
                          </div>}
                          <div style={{marginTop: 5, width: "48%"}}>
                            <PackageComboBox id={phenomId.genPageId("parent")}
                                              label="Package"
                                              xmiType="face:ConceptualDataModel"
                                              placeholder="<Default>"
                                              nodeGuid={this.state.guid}
                                              selectedGuid={this.state.parent}
                                              disabled={!this.state.editable || deprecated}
                                              onChange={(parent) => this.setState({ parent: parent.guid })}
                                              onClickCancelIcon={() => this.setState({ parent: undefined })} />
                          </div>
                    </div>
                    {this.state.guid === "" && <div className="flex-h" style={{marginTop: 15}}>
                        <label id={phenomId.genPageId("add-unique-identifier")} htmlFor={phenomId.genPageId("add-unique-identifier", "checkbox")}>Add Identifier attribute: </label>
                        <input type="checkbox" id={phenomId.genPageId("add-unique-identifier", "checkbox")}
                            checked={this.state.addUIDToNewEntities} style={{marginLeft: 5}} onChange={(e) => {
                                this.setState({addUIDToNewEntities: e.target.checked});
                                localStorage.setItem("addUIDToNewEntities", e.target.checked);
                            }} />
                    </div>}
                    </div>
                    <div className="edit-side-bar">
                        <NodeHistory2 ref={ele => this.historyRef = ele} guid={this.props.match.params.guid} idCtx={phenomId.genPageId()}/>
                        {/* <ChangeSetPicker id={phenomId.genPageId()}
                                         disabled={!this.state.editable}
                                         label="Change Set" /> */}
                    </div>
                </div>
                {!this.state.guid || <React.Fragment>
                    {!this.state.specializedBy.length ||
                        <div className="flex-v" style={{width: "calc(100% - 70px)"}}>
                            <LineLabel text="Specialized By" style={{marginTop: 15}} idCtx={phenomId.gen("specialized-by","")}/>
                            <div>
                                {this.state.specializedBy.map((entry, idx, arr) => {
                                    const connector = (idx === 0) ? "" : (idx === arr.length - 1) ? (arr.length > 2 ? ", and " : " and ") : ", ";
                                    return <React.Fragment key={idx} id={phenomId.gen("specialized-by",idx)}>
                                        <span className="cadet-text">{connector}</span>
                                        <NavLink className="cadet-anchor"
                                            to={`/edit/details/entity/${entry.guid}/`}>{entry.name}</NavLink>
                                    </React.Fragment>;
                                })}
                            </div>
                        </div>}
                    <EntityChildsView
                        ref={el => this.childViews = el}
                        scrollToGuid={this.props.scrollToGuid}
                        guid={this.state.guid}
                        children={children}
                        originalChildren={this.original.children}
                        typeOptions={this.state.typeOptions}
                        canEdit={this.state.editable}
                        parentName={this.state.name}
                        specializes={this.state.specializes}
                        refreshData={this.refreshData}
                        deprecated={this.state.deprecated}
                        onType={this.checkChildren}
                        changeSetID={this.state.changeSetId}
                        idCtx={phenomId.genPageId()}
                        updateChildren={this.updateChildren}
                    />

                    <div style={{ marginTop: 10 }}>
                      <BasisEntityGrid basisEntity={this.state.basisEntity}
                                       editable={this.state.editable}
                                       ref={this.basisEntRef} />
                    </div>

                    <div style={{width: "calc(100% - 70px)"}}>
                        <TyperView typers={this.state.typers} idCtx={phenomId.genPageId("typer-view")}/>
                        <Tags guid={this.state.guid} name={this.state.name} disabled={!this.state.editable} idCtx={phenomId.genPageId("tags")}/>
                    </div>
                </React.Fragment>}
            </div>
        );
    }
}



export const EditEntityManager = withPageLayout(EntityManager);
