import React from 'react';
import { DefaultPortModel } from '@projectstorm/react-diagrams-defaults';
import { AbstractModelFactory } from '@projectstorm/react-canvas-core';
import { DiagramPortModel } from './DiagramPort'

import { ShapeLinkModel } from '../link/ShapeLink';



export class ShapePortFactory extends AbstractModelFactory {
	constructor() {
		super('shape-port');
	}

	generateModel() {
		return new ShapePortModel();
	}
}

export class ShapePortModel extends DiagramPortModel {
	constructor(options={}) {
		super({
			...options,
			type: 'shape-port'
		});
	}

	createLinkModel() {
		return new ShapeLinkModel();
	}
}