import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { acceptLicense, getLicense } from '../../requests/sml-requests';
import { updateUserSettings } from '../../requests/actionCreators';
import { StyledLicenseContent } from '../../views/License';

class Splash extends React.Component {
  state = {
    license_confirmed: false,
    license_version: "0",
    license_text: "",
  }

  componentDidMount() {
    this.fetchLicense();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.fetchLicense();
    }
  }

  // =========================================
  // LICENSE
  // =========================================
  fetchLicense() {
    const { user } = this.props;
    if (!user?.authenticated) return;

    getLicense().then(response => {
      if (response.data === "#void") return;
      const { settings } = user.userIdentity;

      // show license popup
      if (!settings.license_version || settings.license_version < response.license_version) {
        this.setState({
          license_confirmed: false,
          license_version: response.data.license_version,
          license_text: response.data.license_text
        })
      } else {
        // hide license popup
        this.setState({ license_confirmed: true });
      }
    })
  }

  acceptLicense = () => {
    acceptLicense(this.state.license_version).then((res) => {
      if (res.status === "success") {
        updateUserSettings({license_version: this.state.license_version});
        this.setState({ license_confirmed: true });
      } 
    })
  }

  render() {
    const { user } = this.props;
    if (!user?.authenticated) return null;

    const { settings } = user.userIdentity;


    // Must render License Agreement first
    if (!this.state.license_confirmed) {
      return <LicenseConfirm text={this.state.license_text}
                             onConfirm={this.acceptLicense} />
    }

    return null;
  }
}





const LicenseConfirm = (props) => {
  const [confirm, setConfirm] = useState(false);

  if (!props.text) return null;

  return <Dialog title="Terms of Use" className="dialog-80vh dialog-80vw dialog-no-exit">
          <StyledLicenseContent>
            <div dangerouslySetInnerHTML={{ __html: props.text }} />
          </StyledLicenseContent>
          <div>
              <input type="checkbox" 
                     id="licence-checkbox" 
                     checked={confirm}
                     style={{ marginRight: 5 }}
                     onChange={() => setConfirm(!confirm)} />
              <label htmlFor="checkbox">{ "I have read and agree to the Terms of Use" }</label>
          </div>
          <DialogActionsBar>
                <button className='k-button'
                        id="licence-accept" 
                        disabled={!confirm}
                        onClick={props.onConfirm}>
                          Accept
                </button>
          </DialogActionsBar>
  </Dialog>
}

const msp = (state) => ({
  user: state.user,
})

export default connect(msp)(Splash);
