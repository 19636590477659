import { NodeModel } from "@projectstorm/react-diagrams";


export class RootNodeModel extends NodeModel {
  constructor(options = {}) {
      super({
          ...options,
      });
  }

  serialize() {
    return {
        ...super.serialize(),
        nodeData: this.options.nodeData || {},
        width: this.width,
        height: this.height,
    };
  }

  deserialize(event) {
      super.deserialize(event);
      this.options.nodeData = event.data.nodeData || {};
      this.width = event.data.width;
      this.height = event.data.height;
  }

  noop() {
  }

  getNodeData = this.noop;
  getOriginalData = this.noop;
  getOutPorts = this.noop;
  getOutPort = this.noop;
  getInPorts = this.noop;
  getInPort = this.noop;
  setNodeData = this.noop;
  setOutPort = this.noop;
  setInPort = this.noop;
  removeOutPort = this.noop;
  removeInPort = this.noop;
  updateProp = this.noop;

  rebuild = this.noop;
  forceNodeToUpdate = this.noop;
  forceNodeToUpdateByMoving = this.noop;
  forceWidgetToUpdate = this.noop;
}
