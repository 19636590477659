import React from 'react'


const LoadingPage = () => {
  return <div className="edit-form-container">
    <div className="p-row">
      <div className="p-col">
        <div className="placeholder-content placeholder-input-field" />
        <div className="placeholder-content placeholder-input-field" />
        <div className="placeholder-content placeholder-input-field" />
        <div className="placeholder-content placeholder-input-field" />
      </div>
      <div>
        <div className="placeholder-content placeholder-square" />
      </div>
    </div>
  </div>
}

export default LoadingPage;