import styled from "@emotion/styled";
import React, {Component} from "react";
import StencilEntity from "../../../images/diagram_images/stencil_entity.jpg";
import StencilAssociation from "../../../images/diagram_images/stencil_association.jpg";
import StencilView from "../../../images/diagram_images/stencil_view.jpg";
import StencilLine from "../../../images/diagram_images/stencil_line.jpg";
import StencilArrow from "../../../images/diagram_images/stencil_arrow.jpg";
import StencilBox from "../../../images/diagram_images/stencil_box.jpg";
import StencilTextBox from "../../../images/diagram_images/stencil_textbox.jpg";
import { createGhostElement } from "../../util/util";

const Item = styled.div`
    color: white;
    font-size: 14px;
    background-color: ${p => p.type === "blank" ? "transparent" : p.color};
    font-family: Helvetica, Arial;
    padding: 5px;
    border: solid 1px ${p => p.type === "blank" ? "transparent" : p.color};
    border-radius: 5px;
    margin-bottom: 5px;
    cursor: ${p => p.type === "blank" ? "default" : "pointer"};
`;

export default class Stencil extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const model = this.props.model || {};
        const img = new Image();
        const offset = [150, 20]
        
        switch(model.type) {
            case "conceptual:Entity":
                img.src = StencilEntity;
                break;
            case "conceptual:Association":
                img.src = StencilAssociation;
                break;
            case "platform:View":
                img.src = StencilView;
                break;
            case "arrow":
                img.src = StencilArrow;
                offset[0] = 101;
                offset[1] = 7;
                break;
            case "line":
                img.src = StencilLine;
                offset[0] = 100;
                offset[1] = -2;
                break;
            case "box":
                img.src = StencilBox;
                offset[0] = 100;
                offset[1] = 100;
                break;
            case "textbox":
                img.src = StencilTextBox;
                offset[0] = 100;
                offset[1] = 100;
                break;
        }
            
        return (<Item id={this.props.id} type={this.props.type || "normal"} color={this.props.color} draggable={this.props.type !== "blank"} onDragStart={event => {
            event.dataTransfer.setData("storm-diagram-node", JSON.stringify(this.props.model));
            event.dataTransfer.setDragImage(img, offset[0], offset[1]);
        }}>
            {this.props.name}
        </Item>);
    }

}



export class StormStencil extends React.Component {

  render() {
    const { items=[] } = this.props;

    if (!items.length) {
      return null;
    }

    return <div className="storm-stencilbox">
              {items.map((item) => {
                switch(item.type) {
                  case "conceptual:Entity":
                  case "conceptual:Association":
                  case "platform:View":
                  case "im:SourceNode":
                  case "im:SinkNode":
                  case "im:TransformNode":
                  case "im:FilterNode":
                  case "im:ViewTransporterNode":
                  case "im:SIMAdapter":
                  case "im:QueuingAdapter":
                  case "im:DataPump":
                    return <div id={item.id}
                                className="stencil-item"
                                draggable
                                onDragStart={(e) => {
                                  const { htmlElement, offset } = createGhostElement(item);
                                  e.dataTransfer.setDragImage(htmlElement, offset[0], offset[1]);
                                  e.dataTransfer.setData("stencil-item", item.type)
                                  setTimeout(() => htmlElement.remove(), 0);
                                }}
                                style={{
                                  ...item.bgColor,
                                }}>
                              {item.name}
                           </div>
                  case "blank":
                    return <div id={item.id} className="stencil-blank" />
                  default: 
                    return null;
                }
              })}
    </div>
  }
}

