import React from 'react';
import { cloneDeep } from "lodash"
import {Button} from "@progress/kendo-react-buttons";
import ReactTooltip from "react-tooltip";
import {BasicConfirm} from "../dialog/BasicConfirm";
import {BasicAlert} from "../dialog/BasicAlert";
import {deGuidify, colors, createPhenomGuid, isPhenomGuid} from "../util/util";
import {VtuPicker2} from "../util/vtu_picker";
import {NodeHistory2} from "./node-history";
import {Notifications2} from "./notifications";
import PhenomId from "../../requests/phenom-id";

import {
    getNodeWithAddenda,
    getNodesOfType,
    smmSaveNodes,
    modelRemoveNode,
    getDeletableStatus,
} from "../../requests/sml-requests";

import {
    CadetInput,
    CadetTextArea,
    LineLabel,
    SingleSelect,
    DropdownSelect2,
    Card,
    ConstraintTable,
} from "../util/stateless";
import NodeLayout, { withPageLayout, StyledGrid, StyledInput, StyledSidebar, StyledContent, StyledCardGroup } from './node-layout';
import { createNodeUrl } from '../../requests/type-to-path';
import { getActiveChangeSetId } from '../../requests/actionCreators';
import ChangeSetPicker from '../widget/ChangeSetPicker';
import { Link } from 'react-router-dom';
import NavTree from '../tree/NavTree';
import DeletionConfirm2 from '../dialog/DeletionConfirm2';


const labelStyle = {margin:"0 0 7px", lineHeight:"normal"};
const inputStyle = {margin:"0 0 15px"}

export class ReferencePointManager extends React.Component {
    original = {};
    changeSetRef = React.createRef();
    topBtnRef = React.createRef();
    landmarkRef = React.createRef();
    rppRef = [];

    loadingText = "Loading...";
    landmarkTip = ["Select an existing Landmark"];

    defaultReferencePoint = {
        guid: null,
        name: "",
        xmiType: "logical:ReferencePoint",
        description: "",
        landmark: "",
        parent: "",
        children: [],
        new_constraint: null,
        newConstraints: [],
        openConfirmRemovalRPP: false,
        dummyConstraintGuid: 1,
        editable: true,
    }

    state = {
        ...cloneDeep(this.defaultReferencePoint)
    };

    containerStyle = {
        position: "relative",
        border: `3px solid ${colors["logical:ReferencePoint"]}`,
        boxShadow: "3px 3px 6px #121212"
    }

    removeStyle = {
        position:"absolute",
        top:0,
        right:0,
        zIndex:1
    }

    componentDidMount() {
        if (this.props.match.params.guid === "new") {
            this.setupNewNode();
        } else {
            this.setupExistingNode();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.match.params.guid !== prevProps.match.params.guid) {
            if (this.props.match.params.guid === "new") {
                this.setupNewNode();
            } else {
                this.setupExistingNode();
            }
        }

        if (prevState.subModelId !== this.state.subModelId ||
            prevProps.subModels !== this.props.subModels) {
                this.setEditingStatus();
        }
    }

    setupNewNode = async () => {
        await this.setState({ ...this.defaultReferencePoint }, () => {
            this.setEditingStatus();
        });
        this.original = cloneDeep(this.defaultReferencePoint);

        const res = await Promise.all([
            getNodesOfType("logical:Landmark"),
            getNodeWithAddenda(this.props.match.params.parentGuid, { coreAddenda: ["measurementSystemAxisMULTI"] })
        ]);

        const landmarks = deGuidify(res[0].data.nodes);
        const measurementSystemAxes = res[1].measurementSystemAxis;

        this.setState({ landmarks, measurementSystemAxes });
    }

    setupExistingNode = async () => {
        const res = await Promise.all([
            getNodesOfType("logical:Landmark"),
            this.getReferencePoint(),
        ]);

        const landmarks = deGuidify(res[0].data.nodes);
        const rp = res[1];
        const measurementSystemAxes = deGuidify(rp.parent.measurementSystemAxis);

        if (this.props.updateTemplateNode) {
            this.props.updateTemplateNode(rp);
        }

        this.original = cloneDeep(rp);
        this.setState({ ...rp, landmarks, measurementSystemAxes}, () => {
            this.setEditingStatus();
        })
    }

    getReferencePoint = () => {
        return getNodeWithAddenda(this.props.match.params.guid, {
            coreAddenda: ["childrenMULTI", "parent"],
            coreAddendaChildren: ["valueTypeUnit"],
            parentAddenda: ["measurementSystemAxisMULTI"],
            valueTypeUnitAddenda: ["valueType", "unit", "childrenMULTI"]
        })
    }

    handleSave = async () => {
        let errors = [];
        let children = [];

        this.rppRef.forEach(ref => {
          if (!ref.current) return;
          let rpp = ref.current.generateNode();
          if (rpp.errors) return errors.push(...rpp.errors);
          // if (isPhenomGuid(rpp.data.guid)) rpp.data.guid = null;
          children.push(rpp.data);
        })

        if(!children.length) {
          errors.push("At least one Reference Point Part is required.");
        }

        if (errors && errors.length) {
          return Notifications2.parseErrors(errors);
        }

        let requestData = {
            guid: this.state.guid || createPhenomGuid(),
            name: this.state.name,
            description: this.state.description,
            xmiType: "logical:ReferencePoint",
            landmark: this.state.landmark,
            children: children,
            parent: this.state.parent || this.props.match.params.parentGuid,
            changeSetId: getActiveChangeSetId(),
        }

        const res = await smmSaveNodes({
            nodes: [requestData],
            returnTypes: ["logical:ReferencePoint"],
            returnAddenda: {
                "logical:ReferencePoint": {
                    coreAddenda: ["childrenMULTI", "parent"],
                    coreAddendaChildren: ["valueTypeUnit"],
                    parentAddenda: ["measurementSystemAxisMULTI"],
                    valueTypeUnitAddenda: ["valueType", "unit", "childrenMULTI"]
                }
            }
        });
        const response = res.data;
        Notifications2.parseResponse(response);

        if (response.nodes?.length) {
            NavTree.addNodes(response.nodes);
            const node = response.nodes[0];
    
            if (node?.guid) {
                window["resetCache"]();
                if (this.props.match.params.guid === "new") {
                    return this.props.history.push( createNodeUrl(node) );
                }
                this.setupExistingNode();
            }
        }
    }

    addNewRpp = () => {
        const newRpp = {
          guid: createPhenomGuid(),
        }

        this.setState((prevState) => ({ children: [...prevState.children, newRpp] }));
    }

    removeRpp = (idx) => {
        const removeMe = this.state.children[idx];
        const errorMsgs = [
          "This reference point part has been merged into another model and cannot be deleted at this time.",
          "The server responded with an unexpected status. Please try again."
        ]

        if(this.state.children.length < 2) {
            return BasicAlert.show("At least one Reference Point Part is required.", "Cannot remove");
        }

        // TODO: change set dropdown need to be moved
        // const changeSetId = this.topBtnRef.current.state.changeSetId !== "0" ?
        //                     this.topBtnRef.current.state.changeSetId : null;

        BasicConfirm.show("Are you sure you want to remove this Reference Point Part?", () => {
          if (isPhenomGuid(removeMe.guid)) {
            const newChildren = [...this.state.children];
                  newChildren.splice(idx, 1);
            this.setState({ children: newChildren });

          } else {
            getDeletableStatus(removeMe.guid).then(res => {
              const response = res.data;
              if (response.deletable) {
                  modelRemoveNode(removeMe.guid).then(result => {
                    if (result) {
                      const newChildren = [...this.state.children];
                            newChildren.splice(idx, 1);
                      this.setState({ children: newChildren });
                    }
                  })
              } else {
                  Notifications2.parseErrors(errorMsgs[0]);
              }
            })
          }
        });
    }

    resetReferencePoint = () => {
        this.setState({
            guid: this.original.guid,
            name: this.original.name,
            description: this.original.description,
            landmark: this.original.landmark,
            rpps: cloneDeep(this.original.originalRpps),
            parent: this.original.parent,
            children: cloneDeep(this.original.children),
            new_constraint: null,
            new_constraints: this.original.new_constraints,
            editable: this.original.editable,
            dummyGuid: this.original.dummyGuid,
        })
    }

    createNewConstraint = (constraint) => {
        if (constraint) {
            this.setState((prevState) => ({
              newConstraint: constraint,
              newConstraints: [...prevState.newConstraints, constraint],
              dummyConstraintGuid: prevState.dummyConstraintGuid + 1
            }))
        }
    }

    setEditingStatus = () => {
        const { subModels={}, setParentEditingStatus } = this.props;
        const { subModelId } = this.state;
        const currSubModel = subModels[subModelId];
        this.setState({ editable: !currSubModel?.created }, () => {
            setParentEditingStatus && setParentEditingStatus(!currSubModel?.created)
        });
    };

    createMSUrl = () => {
        if (this.state.parent?.guid) {
            return createNodeUrl(this.state.parent);
        } else if (this.props.match.params.parentGuid) {
            return createNodeUrl({ guid: this.props.match.params.parentGuid, xmiType: "logical:MeasurementSystem" })
        }

        return null;
    }

    handleDelete = () => {
        DeletionConfirm2.show(this.state.guid, this.state.name);
    }

    render() {
        const { guid, name, editable, deprecated } = this.state;
        const phenomId = new PhenomId("edit-reference-point",this.props.idCtx);
        const msURL = this.createMSUrl();
        return(
          <NodeLayout guid={guid}
                      name={name}
                      editable={editable}
                      deprecated={deprecated}
                      idCtx={phenomId.genPageId()}
                      onSave={this.save}
                      topBtnRef={this.topBtnRef}>
                <StyledGrid>
                    <StyledInput>
                        <LineLabel text="REFERENCE POINT" style={labelStyle} idCtx={phenomId.gen("details","name")}/>
                        <CadetInput text={this.state.name} style={inputStyle}
                            disabled={!this.state.editable}
                            idCtx={phenomId.gen("details","name")}
                            onChange={(e) => this.setState({name: e.target.value})} />

                        <LineLabel text="DESCRIPTION" style={labelStyle} idCtx={phenomId.gen("details","description")}/>
                        <CadetTextArea text={this.state.description} style={inputStyle}
                            disabled={!this.state.editable}
                            idCtx={phenomId.gen("details","description-input")}
                            onChange={(e) => this.setState({description: e.target.value})} />
                    </StyledInput>
                    <StyledSidebar>
                        <NodeHistory2 guid={this.props.match.params.guid} idCtx={phenomId.genPageId()}/>
                        {/* <ChangeSetPicker id={phenomId.genPageId()}
                                         disabled={!this.state.editable}
                                         label="Change Set" /> */}
                    </StyledSidebar>
                    <StyledContent>
                        <div>
                            <LineLabel text="Landmark" style={labelStyle} idCtx={phenomId.gen("landmark","")}/>
                            <div style={{marginBottom:10}}>
                                <Button icon="select-box" onClick={() => this.landmarkRef.current.toggleCollapse()} id={phenomId.gen("landmark","select-box-button")}>Select Landmark</Button>
                                <Button icon="info" look="bare" data-for="react-tip" data-tip={this.landmarkTip} id={phenomId.gen("landmark","info-button")}/>
                            </div>
                            <SingleSelect data={this.state.landmarks}
                                        id={phenomId.gen("landmark","single-select")}
                                        selectedGuid={this.state.landmark}
                                        required={true}
                                        collapse={this.props.match.params.guid !== "new"}
                                        onChange={(e) => this.setState({landmark: e.target.value})}
                                        editable={this.state.editable}
                                        ref={this.landmarkRef} />

                            {this.state.landmarks &&
                            <StyledCardGroup columns={3}>
                                <Card node={this.state.landmarks[this.state.landmark]} id={phenomId.gen("landmark","card")}/></StyledCardGroup>}
                        </div>

                        <div>
                            <LineLabel text="Reference Point Parts" style={labelStyle} idCtx={phenomId.gen(["details","reference-point"],"")}/>

                            <div style={{marginBottom:10}}>
                                <Button icon="plus"
                                        id={phenomId.gen("reference-point","add-new-button")}
                                        onClick={this.addNewRpp}
                                        disabled={!this.state.editable}>
                                    Add new Reference Point Part
                                </Button>
                            </div>

                            <StyledCardGroup columns={2} id={phenomId.gen("reference-point","card-grid")}>
                                {this.state.children.map((rpp, idx) => {
                                    this.rppRef[idx] = React.createRef();

                                    return(
                                        <div style={this.containerStyle} key={rpp.guid}>
                                            {this.state.editable && <>
                                                <Button icon="close" look="bare" style={this.removeStyle} data-for="removeRPP" data-tip="Remove Reference Point Part"
                                                        onClick={() => this.removeRpp(idx)}
                                                        id={phenomId.gen(["reference-point",`${idx}`],"remove-button")}/>
                                                <ReactTooltip id='removeRPP' type='error' place='left' getContent={(el) => el} />
                                            </>}

                                            <ReferencePointPart key={guid}
                                                                id={phenomId.gen(["reference-point",`${idx}`])}
                                                                referencePointPart={rpp}
                                                                measurementSystemAxes={this.state.measurementSystemAxes}
                                                                look="component"
                                                                newConstraints={this.state.newConstraints}
                                                                dummyConstraintGuid={this.state.dummyConstraintGuid}
                                                                createNewConstraint={this.createNewConstraint}
                                                                editable={this.state.editable}
                                                                match={{params: {guid: guid}}}
                                                                ref={this.rppRef[idx]} />
                                        </div>
                                    )
                                })}
                            </StyledCardGroup>

                            {this.state.newConstraint &&
                                <div style={{marginTop:20}}>
                                    <LineLabel text="Added Constraint" style={labelStyle} idCtx={phenomId.gen("landmark","constrint")}/>
                                    <ConstraintTable constraint={this.state.newConstraint} idCtx={phenomId.gen("landmark")}/>
                                </div>
                            }
                        </div>

                        {msURL && <div style={{margin: "10px 0 20px"}}>
                            <Link to={msURL} className="bordered-button" style={{margin: 0}} id={phenomId.gen("details","back-to-parent-view-button")}>BACK TO PARENT MEASUREMENT SYSTEM</Link>
                        </div> }
                    </StyledContent>
                </StyledGrid>

                <ReactTooltip id='react-tip' type="info" getContent={(str) => <div>
                    You can:
                    <ul style={{paddingLeft:20, margin:0}}>
                        {(str || "").split(",").map((txt, i) =>
                            <li key={i}> {txt} </li>)}
                    </ul></div>} />
          </NodeLayout>
        )
    }
}








class ReferencePointPart extends React.Component {

    vtuRef = React.createRef();

    defaultState = {
        value: "",
        axis: "",
        editable: true,
        valueTypeUnit: null,
    }

    state = {
        ...cloneDeep(this.defaultState),
    }

    componentDidMount() {
        this.setState({
            ...this.props.referencePointPart,
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.referencePointPart !== this.props.referencePointPart) {
          this.setState({ ...this.props.referencePointPart });
        }

        if(prevProps.editable !== this.props.editable) {
            this.setState({ editable: this.props.editable });
        }
    }

    componentWillUnmount() {
        if (this.props.saveState) {
            this.props.saveState(this.state)
        }
    }

    generateNode = () => {
        let vtu = this.vtuRef.current?.generateNode();

        if (vtu) {
          if (vtu.errors) return { errors: vtu.errors };
          // vtu.data can be null
          if (vtu.data) var valueTypeUnit = vtu.data.valueType && vtu.data.unit ? vtu.data : null;
        }

        return {
            data: {
                guid: this.state.guid || createPhenomGuid(),
                xmiType: "logical:ReferencePointPart",
                axis: this.state.axis,
                value: this.state.value,
                valueTypeUnit,
            }
        }
    }

    addNewVtu = () => {
      this.setState({
        valueTypeUnit: {
          guid: null,
          xmiType: "logical:ValueTypeUnit",
          name: "",
          unit: {},
          valueType: {},
          children: [],
          collapse: true,
        }
      })
    }

    removeVtu = () => {
      this.setState({ valueTypeUnit: null },
        () => ReactTooltip.hide());
    }

    render() {
        const labelStyle = {display:"block", fontSize:"80%", fontWeight:"bold", marginBottom:10};
        const inputStyle = {margin:"0 0 10px"}
        const spaceStyle = {marginBottom:10}

        return (
            <div className="subview-wrapper">
                <div style={{marginBottom:20}}>
                    <LineLabel text="Reference Point Part" />
                </div>

                <div style={spaceStyle}>
                    <span style={labelStyle}>Value</span>
                    <CadetInput text={this.state.value} style={inputStyle}
                                disabled={!this.state.editable}
                                onChange={(e) => this.setState({value: e.target.value})} />
                </div>
                <div style={spaceStyle}>
                    <span style={labelStyle}>Measurement System Axis</span>
                    <DropdownSelect2 data={this.props.measurementSystemAxes}
                                    selectedGuid={this.state.axis}
                                    onChange={(e) => this.setState({axis: e.target.value})}
                                    editable={this.state.editable} />
                </div>
                {this.state.valueTypeUnit &&
                <div style={spaceStyle}>
                    <span style={labelStyle}>Value Type Unit</span>
                    <VtuPicker2 valueTypeUnit={this.state.valueTypeUnit}
                                look={this.props.look}
                                newConstraints={this.props.newConstraints}
                                dummyConstraintGuid={this.props.dummyConstraintGuid}
                                createNewConstraint={this.props.createNewConstraint}
                                editable={this.props.editable}
                                removeNode={this.removeVtu}
                                ref={this.vtuRef} />
                </div>
                }
                <Button icon="plus"
                        disabled={this.state.valueTypeUnit}
                        onClick={this.addNewVtu}>Add Value Type Unit</Button>
            </div>
        )
    }
}


export const EditReferencePointManager = withPageLayout(ReferencePointManager, { renderResetBtn:false });
