import React from "react";
import { PasswordStrengthIndicator, PhenomInput, PhenomLabel } from "../util/stateless";
import $ from "jquery";
import PhenomId from "../../requests/phenom-id"
import { connect } from "react-redux";
import { receiveLogs, receiveResponse } from "../../requests/actionCreators";
import { _ajax } from "../../requests/sml-requests";

class ChangeUserPassword extends React.Component {
  constructor(props) {
    super(props);

    this.phenomId = new PhenomId("change-user-password"); 
  }

  state = {
    old_password: "",
    new_password: "",
    confirm_password: "",
    passesChecks: false,
  }

  changeState = e => {
    const target = e.target.id.match("old_password|new_password|confirm_password")[0]
    this.setState({[target]: e.target.value});
  }

  updateCheckStatus = (newStatus) => {
    if (this.state.passesChecks === newStatus) return;
    this.setState({ passesChecks: newStatus });
  }

  checkForErrors = () => {
    const { new_password, confirm_password, passesChecks } = this.state;

    if (new_password !== confirm_password) {
      return "Password confirmation does not match the new password.";
    }

    if (!passesChecks) {
      return "A password must pass the remaining conditions shown below.";
    }
  }

  generateRequest = () => {
    const { new_password } = this.state;
    const { subUsername } = this.props;

    return {
      username: subUsername,
      new_password
    }
  }

  handleSave = () => {
    const { userIdentity, subUsername } = this.props;
    const { new_password, confirm_password, passesChecks } = this.state;

    if (new_password !== confirm_password) {
      return Promise.reject("Password confirmation does not match the new password.");
    }

    if (!passesChecks) {
      return Promise.reject("A password must pass the remaining conditions shown below.");
    }

    return _ajax({
      method: "post",
      url: "/index.php?r=/site/request-password-reset",
      data: {
          admin: userIdentity?.username,
          user: subUsername,
          new_password: new_password
      }
    }).then((response) => {

      // result looks like {response: true}
      if (!response?.data) {
        return Promise.reject("An unexpected error occurred, please try again.")
      }

      receiveLogs(`${subUsername}'s password has been successfully updated.`);
      return response;
    })
  }

  render() {
      const { subUsername } = this.props;
      const { new_password, confirm_password } = this.state;
      const hasPasswordError = !!new_password.length && !!confirm_password.length && new_password !== confirm_password;

      return <div className="edit-form-container">
                <div className="edit-form">
                  <label>{`Change password for '${subUsername}'`}</label>
                  <PhenomInput label="New Password"
                                id={this.phenomId.genPageId('new_password')}
                                type="password"
                                value={new_password}
                                onChange={this.changeState} />
                  <PasswordStrengthIndicator pswd={this.state.new_password}
                                            updateCheckStatus={this.updateCheckStatus} 
                                            idCtx={this.phenomId.gen()} />
                  <PhenomInput label="Confirm Password"
                                id={this.phenomId.genPageId('confirm_password')}
                                type="password"
                                value={confirm_password}
                                customErrorMsg={ hasPasswordError ? "Passwords do not match" : ""}
                                onChange={this.changeState} />
                </div>
              </div>
  }
}



export class ChangeMyPassword extends React.Component {
  constructor(props) {
    super(props);

    this.phenomId = new PhenomId("change-my-password"); 
  }

  state = {
    old_password: "",
    new_password: "",
    confirm_password: "",
    passesChecks: false,
  }

  changeState = e => {
    const target = e.target.id.match("old_password|new_password|confirm_password")[0]
    this.setState({[target]: e.target.value});
  }

  updateCheckStatus = (newStatus) => {
    if (this.state.passesChecks === newStatus) return;
    this.setState({ passesChecks: newStatus });
  }

  handleSave = () => {
    const { new_password, confirm_password, old_password, passesChecks } = this.state;

    if (new_password !== confirm_password) {
      return Promise.reject("Password confirmation does not match the new password.");
    }

    if (!passesChecks) {
      return Promise.reject("A password must pass the remaining conditions shown below.");
    }

    return $.ajax({
      method: "post",
      url: "/index.php?r=/site/change-password",
      data: {
          old_password,
          new_password,
          confirm_password,
      }
    }).then((res) => {
      if (res === "true") {
        receiveLogs("Your password has been successfully updated.");

      } else {
        const response = JSON.parse(res);
        receiveResponse(response);
        return Promise.reject();
      }
    })
  }

  render() {
    const { new_password, confirm_password, old_password } = this.state;
    const hasPasswordError = !!new_password.length && !!confirm_password.length && new_password !== confirm_password;

    return <div className="edit-form-container">
             <div className="edit-form">
               <PhenomInput label="Current Password"
                            id={this.phenomId.genPageId('old_password')}
                            type="password"
                            value={old_password}
                            onChange={this.changeState} />
               <PhenomInput label="New Password"
                            id={this.phenomId.genPageId('new_password')}
                            type="password"
                            value={new_password}
                            onChange={this.changeState} />
               <PasswordStrengthIndicator pswd={this.state.new_password} 
                                         updateCheckStatus={this.updateCheckStatus} 
                                         idCtx={this.phenomId.gen()} />
               <PhenomInput label="Confirm Password"
                            id={this.phenomId.genPageId('confirm_password')}
                            type="password"
                            value={confirm_password}
                            customErrorMsg={ hasPasswordError ? "Passwords do not match" : ""}
                            onChange={this.changeState} />
             </div>
           </div>
  }
}


const mspSubUser = (state) => ({
  userIdentity: state.user.userIdentity,
})
  
export const ChangeSubUserPassword = connect(mspSubUser, null, null, { forwardRef: true })(ChangeUserPassword);