import {EntityUsageFrequency} from "./entity_usage_frequency";
import {AttributeUsageFrequency} from "./attribute_usage_frequency";
import {SemanticEquivalencyByGroup} from "./semantic_equivalency_by_group";
import React from "react";
import {NavLink, Redirect, Route, Switch} from "react-router-dom";
import PhenomId from "../../requests/phenom-id";
import { connect } from "react-redux";
import NavTree from "../tree/NavTree";
import { routeUrlBuilder } from "../../routes";
import { SubMenuRight } from "../edit/edit-top-buttons";
import { KbButton } from "../util/stateless";

class ModelStats extends React.Component {
    componentDidMount() {
      NavTree.collapseNavTree(true);
      NavTree.assignPresetPageFilters("data");
    }

    render() {
        const expired = this.props.expired;
        const phenomId = new PhenomId("model-stats",this.props.idCtx);

        return (
            <div className="phenom-content-wrapper" style={{backgroundColor: "#e8e9ea"}}>
                <nav className="sub-menu-actions" aria-label='form actions'>
                  <NavLink id="entity-usage-link"
                            to={expired ? "/edit/model_stats" : "/edit/model_stats/entity_usage"}>
                      ENTITY USAGE</NavLink>
                  <NavLink id="attribute-usage-link"
                            to={expired ? "/edit/model_stats" : "/edit/model_stats/attribute_usage"}>
                      ATTRIBUTE USAGE</NavLink>
                  <NavLink id="semantic-equivalencies-link"
                            to={expired ? "/edit/model_stats" : "/edit/model_stats/semantic_equivalency"}>
                      SEMANTIC EQUIVALENCIES</NavLink>
                  <SubMenuRight> 
                    <KbButton /> 
                  </SubMenuRight> 
                </nav>

                <Switch>
                  {/* <Route exact path="/generate/model_stats" id={phenomId.gen("","model-stats-route")} render={() => <div
                      style={{padding: 15, color: "#565656", height: 455, backgroundColor: "#fff"}}>Click one of the
                      buttons above to get statistics about your model.</div>}/> */}
                  <Route path={routeUrlBuilder("edit", "model_stats", "entity_usage")} id={phenomId.gen("","entity-usage-route")}
                         render={(props) => <EntityUsageFrequency disabled={expired} {...props}/>}/>
                  <Route path={routeUrlBuilder("edit", "model_stats", "attribute_usage")} id={phenomId.gen("","attribute-usage-route")}
                         render={(props) => <AttributeUsageFrequency disabled={expired} {...props}/>}/>
                  <Route path={routeUrlBuilder("edit", "model_stats", "semantic_equivalency")} id={phenomId.gen("","semantic-equivalencies-route")}
                         render={(props) => <SemanticEquivalencyByGroup disabled={expired} {...props}/>}/>
                  <Redirect to="/edit/model_stats/entity_usage" />
                </Switch>
            </div>
        );
    }
}



const msp = (state) => ({
  expired: state.user.expired,
})

export default connect(msp)(ModelStats)