import React from 'react';
import ReactTooltip from "react-tooltip";
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { PortWidget } from "@projectstorm/react-diagrams-core";
import { BaseNodeModel, BaseNodeWidget } from '../../base/BaseNode';
import { ImBlockNodeModel } from './ImBlock';




export class ImComposedPortBlockFactory extends AbstractReactFactory {
  constructor() {
    super("im-composed-port");
  }

  generateModel(event) {
    return new ImComposedPortBlockModel();
  }

  generateReactWidget(event) {
    return <ImComposedPortBlockWidget engine={this.engine} node={event.model}/>;
  }
}



export class ImComposedPortBlockModel extends ImBlockNodeModel {
  constructor(options={}, $app, settings={}, ) {
    super(
      { type: "im-composed-port", ...options },
      $app,
      settings,
    )
  }


  /**
   * Check if Node can accept an incoming connection
   *
   * @returns True if node can accept an incoming connection, False otherwise
   */
   isValidDropTarget() {
    const dragNodeModel = this.$app.getDraggingFor();
    
    // Source Node cannot be this node
    if (!dragNodeModel) {
      return false;
    }

    // Cannot connect a ComposedPort to another ComposedPort
    if (["im:UoPInstance", "im:ComposedInPort", "im:ComposedOutPort"].includes(dragNodeModel.getXmiType())) {
      return false;
    }

    // Only a ComposedOutPort can accept an incoming connection
    // ComposedInPort does not accept an incoming connection
    if (this.getXmiType() !== "im:ComposedOutPort") {
      return false;
    }

    const in_ports = Object.values(this.getPorts()).filter(p => p.getOptions().in && p.getName() !== "draw-tool");
    return in_ports.length === 0;
  }

  hasAvailableOutputEndPoints() {
    const out_ports = Object.values(this.getPorts()).filter(p => !p.getOptions().in && p.getName() !== "draw-tool");
    return out_ports.length === 0;
  }
}




class ImComposedPortBlockWidget extends BaseNodeWidget {
  constructor(props) {
    super(props);
    this.domHeight = 0;

    this.nodeModel.registerListener({
      selectionChanged: (e) => {
        if (!e.isSelected) {
          this.state.isEditing && this.setState({ isEditing: !this.state.isEditing });
        }
      }
    })
  }

  componentDidMount() {
    ReactTooltip.rebuild();

    this.refreshDomHeight();
  }

  componentDidUpdate(_, prevState) {
    if (prevState.isEditing !== this.state.isEditing) {
      ReactTooltip.hide();
    }

    if (prevState.isEditing !== this.state.isEditing) {
      if (this.state.isEditing) {
        this.nodeModel.setSelected(true);
      } else {
        this.nodeModel.setLocked(false);
      }
    }
    this.refreshDomHeight();
  }

  refreshDomHeight = () => {
    if (!this.widgetRef) {
      return;
    }

    const rect = this.widgetRef.getBoundingClientRect();
    if (this.domHeight !== rect.height) {
      this.domHeight = rect.height;
      this.forceUpdate();
    }
  }


  // ------------------------------------------------------------
  // Drag and Drop
  // ------------------------------------------------------------
  dragStartCreateConnection = (e) => {
    this.repositionDrawTool(e);
    this.$app.setDraggingFor(this.nodeModel);

    const handleMouseUp = (e) => {
      const element = this.$app.engine.getMouseElement(e);
      if (element instanceof BaseNodeModel && element.isValidDropTarget()) {
        this.$app.establishConnection(this.nodeModel, element, e);
      }

      this.$app.setDraggingFor(null);
      window.removeEventListener("mouseup", handleMouseUp);
    }

    window.addEventListener("mouseup", handleMouseUp);
  }



  // ------------------------------------------------------------
  // Render Methods
  // ------------------------------------------------------------

  renderTopDrawIcons = () => {
    if (!this.nodeModel.isSelected() || this.state.isEditing) return null;
    let stormData = this.nodeModel.getStormData();
    if (stormData.getXmiType() === "im:ComposedOutPort" || !this.nodeModel.hasAvailableOutputEndPoints()) return null;

    return <div className="top-draw-icons">
            <span className="k-icon k-i-sort-asc-sm"
                  data-port-name="draw-tool"
                  data-tip="Drag to create Connection"
                  data-for="diagramTip"
                  id={this.phenomId.genPageId("draw-tool")}
                  onDragStart={(e) => e.preventDefault()}   // bug fix - if you highlight other dom elements before clicking this element, it causes unpredictable side effects
                  onMouseDown={this.dragStartCreateConnection} />
          </div>
  }

  renderShape = () => {
    const stormData = this.nodeModel.getStormData();
    const flowTrigger = stormData.getAttr("flowTrigger");

    if (flowTrigger === "PUSH") {
      return <div className="shape" style={{ "--shape-height": `${this.domHeight}px` }}>
        <div className="shape-rhombus-right" />
        <div className="shape-rhombus-left" />
      </div>
      
    } else if (flowTrigger === "PULL") {
      return null;    // default is Square

    } else {
      return <div className="shape" style={{ "--shape-height": `${this.domHeight}px` }}>
        <div className="shape-circle" />
      </div>
    }
  }


  render() {
    const isTargetable = this.nodeModel.isValidDropTarget();
    const classes = ["node-container-center", "node-color-background", "node-color-border", "composed-block-container"];

    if (isTargetable) classes.push("selectable-node");
    return <div id={this.phenomId.genPageId("composed-block-container")}
                className={classes.join(" ")}
                ref={el => this.widgetRef = el}
                style={{ "--nodeColor": this.nodeModel.getNodeColor() }}
                onDoubleClick={() => this.setState({ isEditing: !this.state.isEditing })}>

        { this.renderHalo() }
        { this.renderTopDrawIcons() }

        {/* ANCHOR POINTS AND PORTS */}
        <div className="anchor-point-container with-border">
          {Object.values(this.nodeModel.getPorts()).map(port => {
            return <PortWidget key={port.getOptions().id}
                                port={port}
                                engine={this.props.engine}
                                style={{ position: "absolute",
                                        left: "50%",
                                        top: "50%", }} />
          })}
        </div>

        { this.renderShape() }
      </div>
  }
}
