import React from "react";
import {PhenomLink} from "../widget/PhenomLink";
import PhenomLoadButton from "../widget/LoaderButton";
import {BasicAlert} from "../dialog/BasicAlert";
import $ from "jquery";
import PhenomId from "../../requests/phenom-id";
import { receiveErrors } from "../../requests/actionCreators";
import { _ajax } from "../../requests/sml-requests";

const TextBox = (title, text, idCtx) => {
    return <div className="migratewindow" id={idCtx+"-text-box"}>
        <div className="minititle">
            <p>{title}</p>
        </div>
        <div className="minicontent">
            <p>{text}</p>
        </div>
    </div>;
};

export class Migrate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            migrating: false,
            migrated: false,
            entities: [],
            unmatchables: [],
            errors: [],
            loading: false,
        };
        this.actionMap = {};
    }

    componentDidMount() {
        this.setState({loading: true});
        _ajax({
            url: "/index.php?r=/edit/check-generalization-migration",
            method: "GET"
        }).then((res) => {
            this.setState({loading: false});
            let resp = res.data;
            let indexedEntities = {};
            resp.entities.forEach(x => {
                indexedEntities[x.guid] = x;
            });
            resp.unmatchables.forEach((attr, idx) => {
                if (idx % 2 === 1) {
                    let specializing = resp.unmatchables[idx - 1];
                    let uniqueId = attr.guid + " " + specializing.guid;
                    this.actionMap[uniqueId] = 0;
                }
            });

            resp.entities = indexedEntities;
            this.setState(resp);
        });
    }

    performMigration = () => {
        let actions = Object.values(this.actionMap).filter(x => x !== "no");
        if (actions.some(x => x === 0)) {
            BasicAlert.show("Please select an action for each of the unmatched attributes above.", "But first...");
            return;
        }
        this.setState({migrating: true});
        return $.ajax({
            url: "/index.php?r=/edit/perform-generalization-migration",
            method: "POST",
            data: {
                guids: actions
            }
        }).then((res) => {
            const resp = JSON.parse(res);
            if (resp.errors) {
                receiveErrors(resp.errors);
            } 
            else {
                this.setState({migrating: false, migrated: true});
            }
        });
    };

    render() {
        switch (true) {
            case this.state.migrated:
                return TextBox("Success!", "Successfully reconciled generalization-specialization relationships.");
            case this.state.unmatchables.length > 0:
                return <div className="phenom-content-scrollable">
                    {TextBox("Generalization-Specialization Inconsistencies",
                             `There ${this.state.unmatchables.length / 2 === 1 ? "is" : "are"} ${this.state.unmatchables.length / 2}
                             potentially mismatched attribute${this.state.unmatchables.length / 2 === 1 ? "" : "s"}
                             to review.`)}
                    <table id={"issues"}>
                        <tbody> {
                            this.state.unmatchables.map((attr, idx) => {
                                if (idx % 2 === 1) {
                                    const specialized = this.state.entities[attr.parent];
                                    const specializing = this.state.unmatchables[idx - 1];
                                    const uniqueId = attr.guid + " " + specializing.guid;
                                    const options = [{
                                        id: uniqueId,
                                        value: specializing.guid + "->" + attr.guid,
                                        text: `Create ${specializing.name}.${attr.rolename}`
                                    }];
                                    specializing.children.forEach(x => {
                                        if (x.type === attr.type && !x.specializes)
                                            options.push({
                                                id: uniqueId,
                                                value: x.guid + "->" + attr.guid,
                                                text: `${specializing.name}.${x.rolename} specializes ${specialized.name}.${attr.rolename}`
                                            });
                                    });
                                    if(options.length === 1) {
                                        this.actionMap[uniqueId] = specializing.guid + "->" + attr.guid;
                                    }
                                    return <tr>
                                        <td>{(idx + 1) / 2}</td>
                                        <td>
                                            {<PhenomLink node={specialized}/>} has attribute "{attr.rolename}", but it is missing
                                            from {<PhenomLink node={specializing}/>}
                                        </td>
                                        <td>
                                            <select
                                                className="cadet-select"
                                                style={{width: "100%"}}
                                                id={uniqueId}
                                                disabled={this.state.migrating}
                                                defaultValue={options.length > 1 ? "dummy" : undefined}
                                                onChange={(e) => {
                                                    this.actionMap[e.target.id] = e.target.value;
                                                }}>
                                                <option disabled={true} value={"dummy"}>-SELECT RESOLUTION-</option>
                                                {options.map(x => <option value={x.value}>{x.text}</option>)}
                                            </select>
                                        </td>
                                    </tr>;
                                }
                            })}
                        </tbody>
                    </table>
                    <PhenomLoadButton divStyle={{margin: 15}} text="RESOLVE" onClick={this.performMigration}/>
                </div>
            case this.state.loading:
                return TextBox("Loading...", "Analyzing generalization-specialization relationships.");
            default:
                return TextBox("All Clear", "No generalization-specialization issues detected.");
        }
    }
}
