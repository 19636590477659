import React, { Component } from "react";
import Helmet from "react-helmet";
import releaseNotes from "../static/updates"
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const StyledBox = styled.div`
  padding: 0.5em;
  border: 1px solid #ddd;

  h2 {
    font-weight: bold;
  }

  h1, h2, p {
    margin: 0 0 1em;
  }
`

class About extends Component {
    constructor(props) {
        super(props);
        const currDate = new Date(Date.now());

        this.state = {
            updates: [],
            openedUpdateIdx: 0,
            copyright: `©${currDate.getFullYear()}`
        };

        this.changeOpenedUpdateIdx = this.changeOpenedUpdateIdx.bind(this);
    }

    changeOpenedUpdateIdx(idx) {
      this.setState({ openedUpdateIdx: idx });
    }

    licenseMap = {
      "Babylon": "https://github.com/BabylonJS/Babylon.js/blob/master/license.md",
      "Backbone": "https://github.com/jashkenas/backbone/blob/master/LICENSE",
      "Circos": "https://github.com/nicgirault/circosJS/blob/master/LICENSE.txt",
      "Codemirror": "https://github.com/codemirror/CodeMirror/blob/master/LICENSE",
      "Dagre": "https://github.com/dagrejs/dagre/blob/master/LICENSE",
      "D3": "https://github.com/d3/d3/blob/master/LICENSE",
      "FileSaver": "https://github.com/eligrey/FileSaver.js/blob/master/LICENSE.md",
      "Font Awesome": "https://fontawesome.com/license/free",
      "FoxScott's Airplane Model": "https://sketchfab.com/3d-models/caproni-980e73a43dc749f583686b9b23066347",
      "JQuery": "https://jquery.org/license/",
      "JSZip": "https://github.com/Stuk/jszip/blob/master/LICENSE.markdown",
      "Kendo React": "https://github.com/telerik/kendo-react/blob/master/LICENSE.md",
      "Plotly": "https://github.com/plotly/plotly.js/blob/master/LICENSE",
      "QrCode": "https://github.com/kazuhikoarase/qrcode-generator/blob/master/LICENSE",
      "React": "https://github.com/facebook/react/blob/master/LICENSE",
      "React Helmet": "https://github.com/nfl/react-helmet/blob/master/LICENSE",
      "React Draggable": "https://github.com/STRML/react-draggable/blob/master/LICENSE",
      "Redux": "https://github.com/reduxjs/redux/blob/master/LICENSE.md",
      "Storm": "https://github.com/projectstorm",
    }

    render() {
        return (<section id="phenom-content" aria-label='content'>
            <Helmet>
                <title>About</title>
            </Helmet>

            <div className="phenom-content-wrapper" style={{ padding: "1em" }}>
              <div className="phenom-content-scrollable">

                <div className="flex-container">
                  <div className="p-row">
                    <div className="p-col p-col-8">
                      <StyledBox>
                        <h2>PHENOM™</h2>
                        <p>PHENOM™ is a product of <a href="https://www.skayl.com" target="_blank">Skayl, LLC</a> {this.state.copyright}</p>
                        <p>For any questions, email <a href="mailto:inquiries@skayl.com">inquiries&#64;skayl.com</a></p>
                        <p>By using our website, you agree to our <Link className="cadet-anchor" to="/license">Terms of Use</Link> and <a className="cadet-anchor" href="https://www.skayl.com/privacy-policy-1" target="_blank">Privacy Policy</a></p>
                      </StyledBox>

                      <div className="accordionWrapper dashboard-notes">
                            {releaseNotes.updates.map((update, idx) => {
                                return (<div className={"accordionItem " + (this.state.openedUpdateIdx === idx ? "active": "")}
                                             key={idx} onClick={() => this.setState({ openedUpdateIdx: idx }) }>
                                          <h3 className="accordionItemHeading">{update.title}</h3>
                                          <div className="accordionItemContent"
                                              dangerouslySetInnerHTML={{__html: update.content}}/>
                                      </div>);
                            })}
                        </div>
                    </div>
                    <div className="p-col p-col-4">
                      <div>
                        <StyledBox>
                          <h1 className="page-title">PHENOM PLUGIN DOWNLOAD</h1>
                          <p>This is an experimental plugin for Cameo that lets you access PHENOM from Cameo. It is still in alpha.</p>
                          <a href='/com.skayl.phenomplugin.zip' download>Click Here to Download</a>
                        </StyledBox>
                      </div>
                      <StyledBox>
                        <h1 className="page-title">Attributions</h1>
                        {Object.entries(this.licenseMap).map(([library, license]) => {
                            return <p>
                                    <a className="cadet-anchor" href={license} target="_blank">{library}</a>
                                   </p>
                        })}
                      </StyledBox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </section>);
    }
}




export default About;