// PROPS: a guid of the coordinate system to display - systemGuid (string)

import React from "react";
import {Tags} from "../util/tags";
import { modelRemoveNode, getNodeWithAddenda } from "../../requests/sml-requests";
import {NavLink} from "react-router-dom";
import $ from 'jquery';
import {Grid, GridColumn as Column, GridNoRecords} from "@progress/kendo-react-grid";
import {CadetInput, CadetTextArea, FormatMenuHeaderCell, LineLabel, TypeAutoFill, PhenomComboBox, PackageComboBox, PhenomLabel} from "../util/stateless";
import {Button, Toolbar, ToolbarItem} from "@progress/kendo-react-buttons";
import {Notifications} from "./notifications";
import {NodeHistory2} from "./node-history";
import {PlatformLiteral} from "./platform-literal";
import {IdlComposition} from "./idl-composition";
import {BasicConfirm} from "../dialog/BasicConfirm";
import {BasicAlert} from "../dialog/BasicAlert";
import {deGuidify, constraintsToPrimitives, createPhenomGuid, splitXmiType} from "../util/util";
import PhenomId from "../../requests/phenom-id";
import DeletionConfirm2 from "../../components/dialog/DeletionConfirm2";
import { withPageLayout } from "./node-layout";
import {ConstraintManager} from "./edit-constraint";
import {Modal} from "../util/Modal";
import NavTree from "../tree/NavTree";
import { primitiveTypeList } from "../../global-constants";
import { receiveResponse } from "../../requests/actionCreators";
import ReactTooltip from 'react-tooltip';
import { flatten } from "lodash";
import { _ajax } from "../../requests/sml-requests";


function splitCamelCaseString(str) {
  return str.replace(/([a-z])([A-Z])/g, '$1 $2');
}

const boundedPrimitives = {
  "platform:Fixed": [ "digits", "scale" ],
  "platform:IDLArray": [ "size" ],
  "platform:IDLSequence": [ "maxSize" ],
  "platform:CharArray": [ "length" ],
  "platform:WCharArray": [ "length" ],
  "platform:BoundedString": [ "maxLength" ],
  "platform:BoundedWString": [ "maxLength" ],
}


export class PlatformTypeManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "loading...",
            xmiType: "loading...",
            parent: "",
            size: "",
            maxSize: "",
            description: "",
            measurement: {},
            axes:{},
            platform_children: [],
            all_ldm_labels: [],
            constrained_ldm_labels: [],
            realizeUsage: new Set(),
            deprecated: false,
            editable: true,
            platformTypeMeasurementStatus: false,
            constraintOptions: {},
            constraint: {},
            makingConstraint: false,
            currentBoundPrimitiveValues: null,
            childOrder: [
                "first",
            ],
            subModelId: undefined,
            realizationOptions: [],
        };

        this.newChildId = 0;
        this.historyRef = undefined;
        this.original = {};
        this.fieldRefs = {};
        this.createFieldRef = this.createFieldRef.bind(this);
        this.getUnusedElements = this.getUnusedElements.bind(this);
        this.measRef = undefined;
    }

    primitiveTypes = primitiveTypeList.map(xmiType => splitXmiType(xmiType));
    restrictedTypes = ["Enumeration", "IDLStruct"];
    idlPrimitives = this.primitiveTypes.filter(prim => !this.restrictedTypes.includes(prim));

    setupCachedData = async () => {
        const response = await window["cachedRequest"]("measurementData");
        const measurements = deGuidify(response.data.nodes.sort((a, b) => a.name.localeCompare(b.name)));

        return window["cachedRequest"]("newVTUS").then(res => {
            const valueTypeUnits = deGuidify(Object.values(res.value_type_units).sort((a, b) => a.name.localeCompare(b.name)));
            this.setState({
                measurements: measurements,
                valueTypeUnits: valueTypeUnits,
                measurement: measurements[this.props.measGuid] || {},
            });
        });
    };

    editXmiType(primitive) {
        const newXmiType = "platform:" + primitive;
        if(this.state.xmiType !== newXmiType) {
            this.setState({xmiType : newXmiType}, () => {
                this.validateConstraintWithPrimitive(newXmiType);
            });
        }
    }

    receiveNewMeasurementData(measurement) {
        const newAxisGuids = measurement.measurementAxis.map(axis => axis.guid);
        let new_platform_children = this.state.platform_children.filter(child => newAxisGuids.includes(child.realizes));
        const existingAxisGuids = new_platform_children.map(child => child.realizes);

        this.setState({
            measurement: measurement,
            axes: measurement.measurementAxis,
            platform_children: new_platform_children
        }, this.cleanFieldRefs);
    }

    setAbstractMeas = (measurement) => {
        //FIXME: Currently, the general measurements sent to setAbstractMeas do not have the default meas sys as an object.
        //Therefore it is currently not looked at from setAbstractMeas, while it's trivial in setStateFromResponse
        if (!measurement.xmiType) {
            this.setMeas(measurement);
        } else if (measurement.xmiType === "logical:Measurement") {
            const axes = measurement.measurementAxis || [];
            const vtu = axes[0]?.valueTypeUnit ? this.state.valueTypeUnits?.[axes[0].valueTypeUnit] : false;
            const attrs = measurement.children?.filter(measureChild => measureChild.xmiType === "logical:MeasurementAttribute");

            this.setMeas(measurement, axes, vtu, attrs)
        } else if (measurement.xmiType === "logical:MeasurementAxis") {
            const vtu = measurement?.valueTypeUnit ? this.state.valueTypeUnits?.[measurement.valueTypeUnit] : false;

            this.setMeas(measurement, [], vtu)
        } else if (measurement.xmiType === "logical:ValueTypeUnit") {
            this.setMeas(measurement, [], measurement)
        }
    }

    getAxisVTUs(axis) {
        let vtuNodes = (axis && (axis.valueTypeUnit || axis.measurementSystemAxis.defaultValueTypeUnit)) || [];

        if(typeof vtuNodes === 'string') {
            vtuNodes = this.swapCachedVTUS(vtuNodes.split(" "));
        }
        return vtuNodes;
    }

    getRealizationOptions = (abstractMeasurement) => {
        let realizationOptions = [];
        let axes = [];
        let attrs = [];
        let isMeasureBasedPT = false;
        let isAxisBasedPT = false;

        switch(abstractMeasurement.xmiType) {
            case "logical:Measurement":
                isMeasureBasedPT = true;
                axes = abstractMeasurement.measurementAxis || [];
                if (abstractMeasurement.children) {
                    attrs = abstractMeasurement.children.filter(measureChild => measureChild.xmiType === "logical:MeasurementAttribute")
                            .map(attr => {return {...attr, ...{name: attr.rolename}};});};
                break;
            case "logical:MeasurementAxis":
                isAxisBasedPT = true;
                axes = [abstractMeasurement];
                break;
        }

        const vtuNodes = this.getAxisVTUs(axes[0]);
        const isSinglAxis = isMeasureBasedPT && axes.length === 1;
        const isMultiAxis = axes.length > 1;
        const isMultiVTU = vtuNodes.length > 1;
        const isAttrMeasure = !attrs || (attrs.length && axes.length) || attrs.length > 1;
        const mustBeIDLStruct = isMultiAxis || isMultiVTU || isAttrMeasure;

        if(mustBeIDLStruct) {
            if (isMultiAxis || isAttrMeasure) {
                realizationOptions = axes.concat(attrs).map(axis => this.removeNonIDLStructRealizations(axis));
            } else if (isMultiVTU) {
                realizationOptions = vtuNodes;
            }
        }

        return realizationOptions;
    }

    setMeas = (abstractMeasurement, axes=[], vtu=false, attrs=[]) => {
        const realizationOptions = this.getRealizationOptions(abstractMeasurement);
        const mustBeIDLStruct = realizationOptions.length > 0;
        const mustBeEnum = !mustBeIDLStruct && (vtu && vtu.valueType.xmiType === "logical:Enumerated");

        const vt = vtu?.valueType;
        const constraintAllowedValue = vtu?.children?.[0]?.allowedValue?.map(av => av.guid);
        const all_ldm_labels = vt ? vt.children : [];
        const constrained_ldm_labels = constraintAllowedValue ? all_ldm_labels.filter(label => constraintAllowedValue.includes(label.guid)) : all_ldm_labels.slice();

        const make_plat_child = (rlzOpt) => { 
            const isAttrBased = rlzOpt.xmiType === "logical:MeasurementAttribute";

            return {
              'guid' : `newChild${this.newChildId++}`,
              'rolename' : '',
              'realizes' : isAttrBased ? rlzOpt.guid : null,
              'type' : {
                'guid' : null,
                'realizes' : isAttrBased ? rlzOpt.type.guid : rlzOpt.guid},
              'parent' : this.props.match.params.guid === "new" ? "" : this.props.match.params.guid,
            };
        }

        if (mustBeEnum) {
            this.editXmiType("Enumeration");
        } else if (mustBeIDLStruct) {
            this.editXmiType("IDLStruct");
        } else if (this.state.xmiType === "platform:IDLStruct" || this.state.xmiType === "platform:Enumeration") {
            this.editXmiType("Boolean");
        }

        this.setState({
            axes: axes,
            measurement: abstractMeasurement,
            platform_children: realizationOptions.map(make_plat_child),
            all_ldm_labels: all_ldm_labels,
            constrained_ldm_labels: constrained_ldm_labels,
            realizationOptions: realizationOptions
        }, this.cleanFieldRefs);
    };

    async loadComponent() {
        await this.setupCachedData();
        if (this.props.match.params.guid === "new") {
            this.original = {
                name: "",
                xmiType: "platform:Boolean",
                parent: "",
                description: "",
                measurement: {},
                axes:[],
                platform_children: [],
                all_ldm_labels: [],
                constrained_ldm_labels: [],
                editable: true,
                realizeUsage: new Set(),
                deprecated: false,
                platformTypeMeasurementStatus: false,
                digits: "",
                scale: "",
                size: "",
                length: "",
                maxSize: "",
                maxLength: "",
                childOrder: [],
                subModelId: undefined,
            };
            this.setState(this.original, () => {
                if (this.measRef) {
                    this.measRef.setState({measurement: {}});
                }
            });
            this.refreshConstraint();
            this.disableEditing(false);
        } else if (this.props.nestedInCharacteristic && this.props.platformType) {
//            let pt = {...this.props.platformType};
//            pt.realizes = this.props.measurement;
            this.disableEditing(true);
//            this.setStateFromResponse(this.props.platformType); FIXME: we should be able to pull from parent's cache.
            this.loadGuidBasedData(this.props.platformType.guid);
        } else {
            this.loadGuidBasedData(this.props.match.params.guid);
        }
    }

    componentDidMount() {
        this.loadComponent();
        window.addEventListener('MOVED_NODES', this.mutateOriginalParentListener);
    }

    componentWillUnmount() {
      window.removeEventListener('MOVED_NODES', this.mutateOriginalParentListener);
    }

    refreshConstraint() {
        this.fetchConstraintOptions(() => {
            if (this.state.constraint.guid) {
                this.setState({constraint: this.state.constraintOptions[this.state.constraint.guid]}, () => this.validateConstraintWithPrimitive(this.state.xmiType));
            }
        });
    }

    // used to remove non IDLStruct realizations from ambiguous measurement axis
    removeNonIDLStructRealizations(axisOrAttr) {
        let requiresStructs;
        let realizations;

        switch(axisOrAttr.xmiType) {
            case "logical:MeasurementAxis":
                requiresStructs = this.getAxisVTUs(axisOrAttr).length > 1;
                realizations = axisOrAttr.realizations;
                break;
            case "logical:MeasurementAttribute":
                const attributeAxes = axisOrAttr.type.measurementAxis;

                requiresStructs = attributeAxes.length > 1 || (attributeAxes.length > 0 && this.getAxisVTUs(attributeAxes[0]).length > 1);
                realizations = axisOrAttr.type.realizations;
                break;
            default:
                break;
        }

        if(requiresStructs){
            const cleanAxisOrAttr = {...axisOrAttr};
            const newRealizations = realizations.filter(realization => realization.xmiType === "platform:IDLStruct");

            cleanAxisOrAttr.realizations = newRealizations;
            cleanAxisOrAttr.ambiguous = true;
            return cleanAxisOrAttr;
        } else {
            return axisOrAttr;
        }
    }

    // used to swap out vtu guids for cached vtu objects
    swapCachedVTUS(vtuGuidArray) {
        const vtuGuids = [...vtuGuidArray];
        const valueTypeUnits = {...this.state.valueTypeUnits};
        const newArray = vtuGuidArray.map(guid => {
            if(valueTypeUnits.hasOwnProperty(guid)) {
                return valueTypeUnits[guid];
            }
        })

        return newArray;
    }

    validateConstraintWithPrimitive = primitive => {
        const constraintType = this.state.constraint?.xmiType?.replace('platform:', '');
        const primitiveGroup = constraintsToPrimitives[constraintType];
        if (primitiveGroup && !primitiveGroup.includes(primitive.replace('platform:', ''))) {
            this.setState({constraint: {}});
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.match.params.guid !== prevProps.match.params.guid) {
            this.loadComponent();
        } else if (this.props.nestedInCharacteristic && this.props.measurement.guid !== this.state.measurement.guid) {
            this.setAbstractMeas(this.props.measurement);
        }
        this.validateConstraintWithPrimitive(this.state.xmiType);

        if (prevState.subModelId !== this.state.subModelId ||
            prevProps.subModels !== this.props.subModels) {
                this.setEditingStatus();
        }
    }

    cleanFieldRefs() {
        for(let id in this.fieldRefs) {
            if(!this.fieldRefs[id] || (this.fieldRefs[id].getXmiType() === "platform:EnumerationLiteral" && this.state.xmiType !== "platform:Enumeration") ||
                                      (this.fieldRefs[id].getXmiType() === "platform:IDLComposition" && this.state.xmiType !== "platform:IDLStruct")) {
                delete this.fieldRefs[id];
            }
        }
    }

    createFieldRef(id, ele) {
        const duplicate = Object.keys(this.fieldRefs).find(key => this.fieldRefs[key] === ele);

        if (duplicate) {
            delete this.fieldRefs[duplicate];
        }
        this.fieldRefs[id] = ele;
    }

    getUnusedElements(elementType) {
        this.cleanFieldRefs();
        if (elementType === 'label') {
            const realizeUsages = new Set(Object.values(this.fieldRefs).map(fieldRef => fieldRef.getRealizes && fieldRef.getRealizes()));
            const remainingLabels = this.state.constrained_ldm_labels.filter(label => !realizeUsages.has(label.guid));
            return remainingLabels;
        } else if (elementType === 'axis') {
            const axisUsages = new Set(Object.values(this.fieldRefs).map(fieldRef => fieldRef.getAxis && fieldRef.getAxis()));
            const remainingAxes = this.state.axes.filter(axis => !axisUsages.has(axis.guid));
            return remainingAxes;
        }
    }

    confirmAddMatches() {
        const labels = this.getUnusedElements('label');
        if(labels.length) {
            const warning = `Do you want to match LDM Measurement Labels to existing Platform Literals, and/or add new Platform Literals?\n
                             The following LDM Measurement Labels are unmatched:\n\n${labels.map(label => label.name).join(', ')}`;
            BasicConfirm.show(warning, () => {
                this.matchUnusedLabels();
            }, () => {
                return;
            });
        } else {
            this.noticeRef.error("All LDM Measurement Labels are realized.");
        }
    }

    matchUnusedLabels() {
        let unusedLabelMap = this.getUnusedElements('label').reduce((unusedLabelMap, label) => (unusedLabelMap[label.name] = label, unusedLabelMap), {});
        Object.values(this.fieldRefs).forEach(fieldRef => {
            const literalName = fieldRef.getName();
            const match = unusedLabelMap[literalName];
            if(match) {
                if(!fieldRef.getRealizes()) fieldRef.setRealizes(match.guid);
                delete unusedLabelMap[literalName];
            }
        });
        const newLiterals = Object.values(unusedLabelMap).map((label) => {return {'name': label.name, 'realizes': label.guid}});
        this.addNewRows(newLiterals);
    }

    addNewRows(newChildren) {
        const newTable = Array.from(this.state.platform_children);
        newChildren.forEach(newChild => {
            newChild.guid = `newChild${this.newChildId++}`;
            newChild.parent = this.props.match.params.guid === "new" ? "" : this.props.match.params.guid;
            newTable.push(newChild);
        });
        this.setState({
            platform_children: newTable
        });
    }

    setStateFromResponse(response) {
        this.newChildId = 0;
        const measurement = response.realizes;
        const axes = measurement.measurementAxis || [];
        const vtus = axes.length ? this.getAxisVTUs(axes[0]) : [];
        const vtu = vtus.length ? vtus[0] : null;
        const vt = vtu?.valueType;
        const constraintAllowedValue = vtu?.children?.[0]?.allowedValue?.map(aV => aV.guid);
        const all_ldm_labels = vt ? vt.children : [];
        const constrained_ldm_labels = constraintAllowedValue ? all_ldm_labels.filter(label => constraintAllowedValue.includes(label.guid)) : all_ldm_labels.slice();
        const constraint = response.constraint ? response.constraint : {};
        const newChildOrder = response.children.map(child => child.guid);
        const realizationOptions = this.getRealizationOptions(measurement);
        
        this.original = {
            guid: response.guid,
            name: response.name,
            parent: response.parent,
            xmiType: response.xmiType,
            measurement: measurement,
            axes: axes,
            platform_children: response.children,
            all_ldm_labels: all_ldm_labels,
            constrained_ldm_labels: constrained_ldm_labels,
            description: response.description,
            platformTypeMeasurementStatus: response.platformTypeMeasurementStatus,
            deprecated: response.deprecated,
            constraint: constraint,
            digits: response.digits,
            scale: response.scale,
            size: response.size,
            length: response.length,
            maxSize: response.maxSize,
            maxLength: response.maxLength,
            childOrder: newChildOrder,
            subModelId: response.subModelId,
            realizationOptions: realizationOptions,
        };
        if (this.props.updateTemplateNode) {
            this.props.updateTemplateNode(this.original);
        }
        this.setState(this.original, () => {
            this.refreshConstraint();
            this.setEditingStatus();
            if(!this.props.nestedInCharacteristic) {
                this.historyRef.updateHisotry();
            }
            if (this.state.deprecated === "true") this.setState({editable: false});
        });
    }

    loadGuidBasedData(guid) {
        getNodeWithAddenda(guid, {
            coreAddenda: ["childrenMULTI", "realizes", "platformTypeMeasurementStatus", "constraint"],
            coreAddendaChildren: ["type"],
            realizesAddenda: ["measurementAxisMULTI", "measurementSystem", "realizationsMULTI", "childrenMULTI", "measurementSystemAxis"],
            realizesAddendaChildren: ["type"],
            typeAddenda: ["realizationsMULTI", "measurementAxisMULTI"],
            measurementAxisAddenda: ["realizationsMULTI", "measurementSystemAxis"],
            measurementSystemAddenda: ["measurementSystemAxisMULTI"],
        }).then((res) => {
            this.setStateFromResponse(res);
        });
    }


    handleSave = async (changeSetId = null) => {
        this.cleanFieldRefs();
        return await this.saveChanges(changeSetId);
    }

    handleReset = () => {
        this.loadComponent();
    }

    completeDeletions(deletedChildren) {
        const deletePromises = [];
        deletedChildren.forEach(delChildGuid => {
            const deletePromise = modelRemoveNode(delChildGuid).then(() => {
              this.setState((prevState) => {
                const childOrder = prevState.childOrder.filter(childGuid => childGuid !== delChildGuid);
                return { childOrder };
              })
            });
            deletePromises.push(deletePromise);
        });
        return Promise.all(deletePromises);
    }

    getChildrenEdits = () => { //children could be comps or literals! Component state is different for each!
        let childrenEdits = [];
        if (Object.keys(this.fieldRefs).length) {
            for (let fieldID in this.fieldRefs) {
                const fieldRef = this.fieldRefs[fieldID];
                const childState = fieldRef.getStateIfChanged();
                if (childState) {
                    if (childState.guid.startsWith("newChild")) {
                        childState.guid = createPhenomGuid();
                        if (childState.type && this.state.parent !== "") childState.type.parent = this.state.parent;
                    }
                    childrenEdits.push(childState);
                }
            }
        }
        //TODO figure out why field ref keys are in order but indices are shifting +1 regardless
        if(childrenEdits.length) {
            const frontChild = childrenEdits.shift();
            childrenEdits.push(frontChild);
        }
        return childrenEdits;
    }

    hasChanges = () => {
        const nodeEdited = ["guid", "name", "xmiType", "description", "parent", ...flatten(Object.values(boundedPrimitives))]
                                    .some((key) => this.original[key] !== this.state[key]);
        const constraintEdited = this.original.constraint?.guid !== this.state.constraint?.guid
        const orderEdited = this.state.childOrder.join(",") !== this.original.platform_children.map(char => char.guid).join(",");

        return nodeEdited || constraintEdited || orderEdited;
    }

    getChanges = () => {
        let data = {
            guid: this.props.match.params.guid === "new" ? undefined : this.props.match.params.guid,
            parent: this.state.parent || undefined,
            name: this.state.name,
            xmiType: this.state.xmiType,
            children: this.getChildrenEdits(),
            realizes: this.state.measurement.guid,
            description: this.state.description,
            constraint: this.state.constraint.guid,
            childOrder: this.state.childOrder.filter(c => !c.startsWith("newChild")),
            size: this.state.size,
            maxSize: this.state.maxSize,
        }

        // add bounded attr to request data
        if (boundedPrimitives[data.xmiType]) {
          boundedPrimitives[data.xmiType].forEach(attr => data[attr] = this.state[attr]);
        }

//        if (!data.name || !data.realizes) {
//            data.errors = ["Please select appropriate values for Platform Type"];
//        }
        //this.state.platform_children.length === this.state.axes.length
        return data;
    }

    saveChanges = (changeSetId = null, deletions) => {
        let url;
        let data;
        if (this.hasChanges()) {
            data = [this.getChanges()];
        } else {
            data = this.getChildrenEdits();
            data.forEach(child => child.parent = this.props.match.params.guid);
        }

//        data.changeSetId = changeSetId;
        if(data.length) {
            return _ajax({
                url: "/index.php?r=/node/smm-save-nodes",
                method: "post",
                data: {nodes: data,
                       changeSetId: changeSetId || undefined,
                       returnTypes: [this.state.xmiType, "platform:IDLComposition", "platform:EnumerationLiteral"]},
            }).then(res => {
                let response = res.data;
                if (response.nodes && response.nodes.length > 1) {
                    let children = response.nodes.filter(node => node.xmiType === "platform:IDLComposition" || node.xmiType === "platform:EnumerationLiteral");
                    let platformType = response.nodes.filter(node => node.xmiType === this.state.xmiType);
                    response.nodes = [...platformType, ...children];
                }
                if (response.errors) {
                    this.noticeRef.error(response.errors);
                } else if (response.guid || response.nodes) {
                    receiveResponse(response);
                    NavTree.addNodes(response.nodes);
                    // if response.nodes was returned, this assumes it has a single node.
                    if (this.props.match.params.guid === "new") {
                        let respNode = response;
                        if(response.nodes) respNode = response.nodes[0] || {};
                        return this.props.history.replace(`/edit/details/platform_type/${respNode.guid}`);
                    }
                    this.historyRef.updateHisotry();
                    this.loadGuidBasedData(response.guid || this.props.match.params.guid);
                } else {
                    this.noticeRef.error("Something went wrong when trying to save your changes.");
                }
            });
        } else {
            this.noticeRef.error("No changes detected.");
        }
    }

    disableEditing = disabled => {
        this.setState({editable: !disabled});
    };

    setEditingStatus = () => {
        const { subModels={}, setParentEditingStatus, nestedInCharacteristic, platformType } = this.props;
        const { subModelId } = this.state;

        if (nestedInCharacteristic && platformType) {
            return this.setState({ editable: false })
        }

        const currSubModel = subModels[subModelId];
        this.setState({ editable: !currSubModel?.created }, () => {
            setParentEditingStatus && setParentEditingStatus(!currSubModel?.created)
        });
    };

    getPrimitives = () => {
        if (this.state.platformTypeMeasurementStatus) {
            const restrictedPrims = new Set([this.state.xmiType.replace('platform:', '')]);
            if (this.state.constrained_ldm_labels.length > 0) {
                restrictedPrims.add("Enumeration");
            } else if (this.state.measurement?.measurementAxis && this.state.measurement.measurementAxis.length > 1) {
                restrictedPrims.add("IDLStruct");
            } else {
                this.primitiveTypes.forEach(primitive => {
                    if (!this.restrictedTypes.includes(primitive)) {
                        restrictedPrims.add(primitive);
                    }
                });
            }
            return [...restrictedPrims];
        }
        return this.primitiveTypes;
    }

    getWarning = () => {
        const status = this.state.platformTypeMeasurementStatus;
        const isEnumMeas = (this.state.constrained_ldm_labels.length > 0);
        const isStructMeas = (this.state.measurement.measurementAxis && this.state.measurement.measurementAxis.length > 1);
        let measurementType;
        let desiredPrimitiveType;
        let currentPrimitiveType;
        
        if (isEnumMeas) {
            measurementType = "Enumeration";
            desiredPrimitiveType = "Enumeration";
        } else if (isStructMeas) {
            measurementType = "Multi-Axis"
            desiredPrimitiveType = "IDLStruct";
        } else {
            measurementType = "regular"
            desiredPrimitiveType = "simple"
        }

        if (status == "badEnum") {
            currentPrimitiveType = "Enumeration";
        } else if (status == "badStruct") {
            currentPrimitiveType = "IDLStruct"
        } else {
            currentPrimitiveType = "simple"
        }
        return `This Platform Type has a ${currentPrimitiveType} primitive type, and it realizes a ${measurementType} measurement. The primitive type would likely need to be changed to a ${desiredPrimitiveType} primitive type.`;
    }

    handleDelete = (changeSetId = null) => {
        this.setState({
            changeSetId: changeSetId
        });
        DeletionConfirm2.show(this.props.match.params.guid, this.state.name, () => this.loadGuidBasedData(this.props.match.params.guid));
        return null;
    }

    closeModal = constraintGuid => {
        this.setState({makingConstraint: false});
        if (constraintGuid) {
            this.fetchConstraintOptions(() => this.setState({constraint: this.state.constraintOptions[constraintGuid]}));
        }
    };

    fetchConstraintOptions(cb) {
        _ajax({
            url: "/index.php?r=/constraint/model-nodes-of-type",
        }).then((res) => {
            const response = deGuidify(res.data.nodes);
            this.setState({constraintOptions: response}, cb);
        });
    }

    reorder = dataItem => {
        if (this.state.activeItem === dataItem) return;
        let prevIndex = this.state.childOrder.findIndex(p => (p === this.state.activeItem.guid));
        let nextIndex = this.state.childOrder.findIndex(p => (p === dataItem.guid));
        this.state.childOrder.splice(prevIndex, 1);
        this.state.childOrder.splice(nextIndex, 0, this.state.activeItem.guid);

        this.setState({
            active: this.state.activeItem
        });
    };

    dragStart = dataItem => {
        this.setState({
            activeItem: dataItem
        });
    };

    renderAbstractMeasurement(phenomId) {
        //If this render fn ever has performance issues from re-rendering too often, move to stateless component and pass in state
        const measurements = this.state.measurements ? Object.values(this.state.measurements) : [];
        const axes = measurements.reduce((axes, meas) => (axes = axes.concat(meas.measurementAxis.filter(axis => !axes.find(f => f.guid == axis.guid))), axes), []).sort((a, b) => a.name.localeCompare(b.name));
        const vtus = this.state.valueTypeUnits ? Object.values(this.state.valueTypeUnits) : [];
        const combinedDropdown = [...measurements, ...axes, ...vtus].map(abstractMeas => { return {...abstractMeas, 'name': `[${abstractMeas.xmiType.replace('logical:', '')}] ${abstractMeas.name}`}});

        return (<div className="flex-v" style={{flexGrow: 1}}>
            <LineLabel text="REALIZED ABSTRACT MEASUREMENT" idCtx={phenomId.gen("details","realized-measurement")}/>
            {this.props.match.params.guid !== 'new' && (this.state.measurement.xmiType == "logical:Measurement" || this.state.measurement.xmiType === "logical:ValueTypeUnit") && <NavLink
                id={phenomId.gen("details","realized-measurement-link")}
                style={{marginTop: 7, padding: 7}}
                to={`/edit/details/measurement/${this.state.measurement.guid}/`}
                className="cadet-anchor">
                {this.state.measurement.name}
            </NavLink>}
            {(this.props.match.params.guid === 'new' || this.state.measurement.xmiType !== "logical:Measurement") && <TypeAutoFill
                style={{width: "100%", fontSize: "12px", marginTop: 11, height: 30}}
                data={combinedDropdown}
                disabled={this.props.match.params.guid !== 'new'}
                textField="name"
                value={this.state.measurement}
                idCtx={phenomId.genPageId()}
                showAll={false}
                addTypeIdent={false}
                onChange={(evt) => {
                    const selectMatch = combinedDropdown.find(e => e.guid === evt.target.value?.guid);
                    this.setAbstractMeas(selectMatch ? selectMatch : {});
                }}/>}
        </div>)
    }

    renderConstraintSelection(phenomId, prim) {
        let allowedPrims = Object.values(constraintsToPrimitives).flat();
        if (!allowedPrims.includes(prim)) {
            if (this.state.constraint.guid) {
                this.setState({constraint: {}});
            }
            return <select className="cadet-select" disabled={true} id={phenomId.gen(["details","multi-constraint"],"select")}>
                <option id={phenomId.gen(["multi-constraint","solo"],"option")}>--- Constraints for selected primitive type not supported ---</option>
            </select>;
        } else {
            if (this.state.constraint.guid && this.state.constraintOptions[this.state.constraint.guid] && !this.state.constraint.name) {
                this.setState({constraint: this.state.constraintOptions[this.state.constraint.guid]});
            }
            return (<div className="flex-h" style={{justifyContent: "space-between"}}>
                <select className="cadet-select" value={this.state.constraint.guid}
                    onChange={e => this.setState({constraint: e.target.value ? this.state.constraintOptions[e.target.value] : ({})})}
                    disabled={!this.state.editable}
                    id={phenomId.gen(["details","constraint"],"select")}>
                    <option value="">--- No Constraint ---</option>
                    {Object.values(this.state.constraintOptions).map((constraint,cIdx) => {
                        const primitiveGroup = constraintsToPrimitives[constraint.xmiType.replace('platform:', '')] || [];
                        if (primitiveGroup.includes(prim))
                            return <option value={constraint.guid} key={constraint.guid} id={phenomId.gen(["constraint",`${cIdx}`],"option")}>{constraint.name}</option>;
                    })}
                </select>
                {this.state.editable && <button className="cadet-anchor" style={{height: 35}} id={phenomId.gen(["details","constraint"],"create-button")}
                    onClick={() => this.setState({makingConstraint: true})}>CREATE NEW
                    CONSTRAINT
                </button>}
            </div>);
        }
//        else {
//            if (this.state.constraint.guid && this.state.constraint.name) {
//                this.setState({constraint: {}});
//            }
//            return <select className="cadet-select" disabled={true} id={phenomId.gen(["details","constraint"],"select")}>
//                <option id={//phenomId.gen(["constraint","solo"],"option")}>--- Please Select Axis Primitive ---</option>
//            </select>//;
//        }
    }


    updateProp = (key, value="") => {
      switch (key) {
        // INTEGERS
        case "digits":
        case "scale":
        case "size":
        case "length":
        case "maxSize":
        case "maxLength":
          var reg = value.match(/\d*/);
          value = reg[0];
          break;
      }
      this.setState({ [key]: value });
    }

    mutateOriginalParentListener = (e) => {
      // invalid
      if (!this.state.guid) {
        return;
      }

      const leaf = NavTree.getLeafNode(this.state.guid);
      if (!leaf) {
        return;
      }

      const newParentGuid = leaf.getParentGuid();
      if (this.state.parent !== newParentGuid) {
        this.original["parent"] = newParentGuid;
      }
    }

    deleteLiteral = (litGuid) => {
        this.cleanFieldRefs();
        this.setState((prevState) => {
            const childOrder = prevState.childOrder.filter(childGuid => childGuid !== litGuid);
            const platform_children = prevState.platform_children.filter(child => child.guid !== litGuid);
            return { childOrder, platform_children };
        })
    }

    render() {
        let platRowIdx = 0;
        let ldmRowIdx = 0;
        let IDLCompRowIdx = 0;
        const deprecated = this.state.deprecated === "true";
        const phenomId = new PhenomId("edit-platform-types",this.props.idCtx);
        let prim = this.state.xmiType.replace('platform:', '');
        return (<div className="subview-wrapper flex-v">
            {deprecated ? <strong>
                <div className="deprecated-banner">WARNING: This node has been DEPRECATED</div>
            </strong> : null}
            <Notifications ref={ele => this.noticeRef = ele}/>
            <div className="flex-h">
                <div className="flex-v" style={{flexGrow: 1, gap:15}}>
                    <div>
                      <LineLabel text="PLATFORM TYPE" idCtx={phenomId.gen("details","name")}/>
                      {this.state.editable ?
                          <CadetInput text={this.state.name} style={{marginBottom: 0}} disabled={!this.state.editable} onChange={(e) => this.setState({name: e.target.value})}  id={phenomId.gen("details","name") }/> :
                          <NavLink style={{marginTop: 10, marginBottom: 10}} className="cadet-anchor" to={`/edit/details/platform_type/${this.props.match.params.guid}`} id={phenomId.gen("details","name-link")}>{this.state.name}</NavLink>}
                    </div>
                    {this.state.platformTypeMeasurementStatus &&
                        <div style={{
                            color: "crimson",
                            fontSize: "90%",
                            marginBottom: 15
                        }}>{"\n\n\n ⚠ PHENOM has detected a possible error. " + this.getWarning()}</div>}
                    <div className="flex-h">
                      <div className="flex-v" style={{flexGrow: 1, paddingRight: 15}}>
                        <PhenomComboBox
                          label={"Primitive Type"}
                          id={phenomId.gen("primitive-type","")}
                          disabled={!this.state.editable || !this.state.platformTypeMeasurementStatus && (this.props.match.params.guid !== 'new' || this.state.xmiType === "platform:Enumeration" || this.state.xmiType === "platform:IDLStruct")}
                          value={prim || "Boolean"}
                          data={this.getPrimitives()}
                          dataDisabled={this.restrictedTypes}
                          onChange={prim => this.editXmiType(prim)} />
                      </div>
                        {this.props.nestedInCharacteristic || this.renderAbstractMeasurement(phenomId)}
                    </div>

                    {boundedPrimitives[this.state.xmiType] &&
                    <div className="flex-h" style={{gap:10}}>
                      {boundedPrimitives[this.state.xmiType].map((attr, idx) => (
                        <div key={attr + "-" + idx} style={{width: "100%"}}>
                          <LineLabel text={splitCamelCaseString(attr).toUpperCase()} idCtx={phenomId.gen("details", attr)}/>
                          <CadetInput
                            id={phenomId.gen("details", attr) } 
                            value={this.state[attr] || ""} 
                            type="number" 
                            disabled={!this.state.editable} 
                            onChange={(e) => this.updateProp(attr, e.target.value)} 
                            text={this.state[attr]}/>
                        </div>
                      ))}
                    </div>}
                </div>
                {this.props.nestedInCharacteristic || <div className="edit-side-bar">
                    <NodeHistory2 ref={ele => this.historyRef = ele} guid={this.props.match.params.guid === "new" ? undefined : this.props.match.params.guid} idCtx={phenomId.genPageId()}/>
                </div>}
            </div>

            {this.props.nestedInCharacteristic || <div>
                <LineLabel text="Description" style={{marginTop: 15}} idCtx={phenomId.gen("details","description")}/>
                <CadetTextArea
                    idCtx={phenomId.gen("details","description")}
                    text={this.state.description}
                    title={this.state.description}
                    onChange={(e) => this.setState({description: e.target.value})}
                    style={{marginBottom: 0}}
                    disabled={!this.state.editable} />
                <div style={{marginTop:15}}>
                    <PackageComboBox id={phenomId.genPageId("parent")}
                                      label="Package"
                                      xmiType="face:PlatformDataModel"
                                      placeholder="<Default>"
                                      nodeGuid={this.state.guid}
                                      selectedGuid={this.state.parent}
                                      disabled={!this.state.editable}
                                      onChange={(parent) => this.setState({ parent: parent.guid })}
                                      onClickCancelIcon={() => this.setState({ parent: undefined })} />
                    </div>
            </div>}


            {(this.props.nestedInCharacteristic && this.props.match.params.guid !== "new" && !this.state.constraint.guid) || <div>
                <LineLabel text="Constraint" style={{marginTop: 30}} idCtx={phenomId.gen("constraint","label")}/>
                {this.renderConstraintSelection(phenomId, prim)}
            </div>}
            {!this.state.constraint.guid ||
            <table className="input-table" style={{marginTop: -5}} id={phenomId.gen(["constraint","table"])}>
                <tr>
                    <td id={phenomId.gen("table","description-label")}>Description</td>
                    <td style={{fontSize: "90%"}} id={phenomId.gen("table","description-text")}>{this.state.constraint.description}</td>
                </tr>
                {!this.state.constraint.expression || <tr>
                    <td id={phenomId.gen("table","expression-label")}>RegEx Expression</td>
                    <td style={{fontSize: "90%"}} id={phenomId.gen("table","expression-text")}>{this.state.constraint.expression}</td>
                </tr>}
                {!this.state.constraint.upperBound || <tr>
                    <td id={phenomId.gen("table","upper-bound-label")}>Upper Bound</td>
                    <td style={{fontSize: "90%"}} id={phenomId.gen("table","upper-bound-text")}>{this.state.constraint.upperBound}</td>
                </tr>}
                {!this.state.constraint.lowerBound || <tr>
                    <td id={phenomId.gen("table","lower-bound-label")}>Lower Bound</td>
                    <td style={{fontSize: "90%"}} id={phenomId.gen("table","lower-bound-text")}>{this.state.constraint.lowerBound}</td>
                </tr>}
                {!this.state.constraint.upperBoundInclusive || <tr>
                    <td id={phenomId.gen("table","upper-bound-inclusive-label")}>Upper Bound Inclusive</td>
                    <td style={{fontSize: "90%"}} id={phenomId.gen("table","upper-bound-inclusive-text")}>{this.state.constraint.upperBoundInclusive}</td>
                </tr>}
                {!this.state.constraint.lowerBoundInclusive || <tr>
                    <td id={phenomId.gen("table","lower-bound-inclusive-label")}>Lower Bound Inclusive</td>
                    <td style={{fontSize: "90%"}} id={phenomId.gen("table","lower-bound-inclusive-text")}>{this.state.constraint.lowerBoundInclusive}</td>
                </tr>}
            </table>}
            {this.state.xmiType === 'platform:IDLStruct' && <div className="flex-h" style={{marginTop: 15}}>
                <div className="flex-v" style={{flexGrow: 2, paddingRight: 15}}>
                    <PhenomLabel text="IDL Compositions" style={{ marginBottom: 15, width: "100%"}}>
                        {this.props.destructureReference && <div style={{display: "flex"}}>
                            <span className="fas fa-info-circle"
                                style={{margin: "2px 5px 0 0"}}
                                data-tip
                                data-for="compInfo"
                                data-place="right"/>
                            <ReactTooltip id='compInfo'>
                                    <span>In the parent View's template, this Characteristic can be represented either by the whole IDLStruct or by addressing individually an IDLStruct's member using the member reference notation (->).<br/><br/>
                                    In the table below, the members referenced in the template (either all of them in the former case or the selected one in the latter) will appear plain while the excluded members will be marked with a gray background.<br/><br/>
                                    Please refer to the "Query and Template Preview" section from the parent View for more details.
    </span>
                            </ReactTooltip>
                        </div> }
                    </PhenomLabel>
                    <Grid
                        id={phenomId.gen("idl-compositions","grid")}
                        data={[...this.state.platform_children].sort((a,b) => this.state.childOrder.indexOf(a.guid) - this.state.childOrder.indexOf(b.guid))}
                        className="editorTable"
                        rowRender={(_, props) => {
                            const child = props.dataItem;
                            return (
                                <IdlComposition
                                    idCtx={phenomId.gen(["idl-compositions", IDLCompRowIdx++])}
                                    ref={ele => this.createFieldRef(child.guid, ele)}
                                    key={child.guid}
                                    destructureReference={this.props.destructureReference?.guid}
                                    canEdit={this.state.editable}
                                    element={child}
                                    new={child.guid.startsWith("newChild")}
                                    realizationOptions={this.state.realizationOptions}
                                    primitives={this.idlPrimitives}
                                    getUnusedElements={() => this.getUnusedElements('axis')}
                                    isDraggable={!child.guid.startsWith("newChild")}
                                    onDragOver={() => {
                                        if(Object.keys(this.fieldRefs).filter(c => c.startsWith("newChild")).length) {
                                            return BasicAlert.show("Please finish creating the characteristic before rearranging it.", "Action Incomplete");
                                        }
                                        this.reorder(child);
                                    }}
                                    onDragStart={(e) => {
                                        this.dragStart(child);
                                        e.dataTransfer.setData("dragging", "");
                                    }}
                                />);
                        }}>
                        <GridNoRecords>
                            No Data Is Available For This Table
                        </GridNoRecords>
                        <Column title="NAME" field="name"
                                headerCell={(props) => {
                                    return <FormatMenuHeaderCell text={props.title} />;
                                }}/>
                        <Column title="AXIS / ATTRIBUTE / VTU" field="name"
                                headerCell={(props) => {
                                    return <FormatMenuHeaderCell text={props.title} />;
                                }}/>
                        <Column title="TYPE" field="realizes"
                                headerCell={(props) => {
                                    return <FormatMenuHeaderCell text={props.title} />;
                                }}/>
                        <Column title="PRIMITIVE" field="realizes" width="120px"
                                headerCell={(props) => {
                                    return <FormatMenuHeaderCell text={props.title} />;
                                }}/>
                    </Grid>
                    {!this.state.editable || this.state.platform_children.length >= this.state.axes.length || <Toolbar>
                        <ToolbarItem>
                            <Button
                                iconClass="fa fa-plus fa-fw"
                                onClick={() => {
                                    this.addNewRows([{rolename:'', type:null}]);
                                }}>
                                Create
                            </Button>
                        </ToolbarItem>
                    </Toolbar>}
                </div>
            </div>}
            {this.state.xmiType === 'platform:Enumeration' && <div className="flex-h" style={{marginTop: 15}}>
                <div className="flex-v" style={{flexGrow: 2, paddingRight: 15}}>
                    <LineLabel text="Platform Literals" style={{marginBottom: 15}} idCtx={phenomId.gen(["details","platform-literals"],"")}/>
                    <Grid
                        id={phenomId.gen("platform-literals","grid")}
                        data={[...this.state.platform_children].sort((a,b) => this.state.childOrder.indexOf(a.guid) - this.state.childOrder.indexOf(b.guid))}
                        className="editorTable"
                        rowRender={(_, props) => {
                            const child = props.dataItem;
                            return (
                                <PlatformLiteral
                                    idCtx={phenomId.gen(["platform-literals",platRowIdx++])}
                                    ref={ele => this.createFieldRef(child.guid, ele)}
                                    key={child.guid}
                                    canEdit={this.state.editable}
                                    element={child}
                                    new={child.guid.startsWith("newChild")}
                                    labels={this.state.constrained_ldm_labels}
                                    getUnusedElements={() => this.getUnusedElements('label')}
                                    isDraggable={!child.guid.startsWith("newChild")}
                                    onDragOver={() => {
                                        if(Object.keys(this.fieldRefs).filter(c => c.startsWith("newChild")).length) {
                                            return BasicAlert.show("Please finish creating the characteristic before rearranging it.", "Action Incomplete");
                                        }
                                        this.reorder(child);
                                    }}
                                    onDragStart={(e) => {
                                        this.dragStart(child);
                                        e.dataTransfer.setData("dragging", "");
                                    }}
                                    deleteLiteral={this.deleteLiteral}
                                />);
                        }}>
                        <GridNoRecords>
                            No Data Is Available For This Table
                        </GridNoRecords>
                        <Column title="NAME" field="name"
                                headerCell={(props) => {
                                    return <FormatMenuHeaderCell text={props.title} />;
                                }}/>
                        <Column title="REALIZES" field="realizes"
                                headerCell={(props) => {
                                    return <FormatMenuHeaderCell text={props.title} />;
                                }}/>
                        <Column title="DELETE" field="delete" width="70px"
                                headerCell={(props) => {
                                    return <FormatMenuHeaderCell text={props.title} />;
                                }}/>
                    </Grid>
                    {!this.state.editable || <Toolbar>
                        <ToolbarItem>
                            <Button
                                id={phenomId.gen("platform-literals","create-new-rows-button")}
                                iconClass="fa fa-plus fa-fw"
                                onClick={() => {
                                    this.addNewRows([{name:'', realizes:null}]);
                                }}>
                                Create
                            </Button>
                            <Button
                                id={phenomId.gen("platform-literals","confirm-add-matches-button")}
                                iconClass="fa fa-plus fa-fw"
                                style={{marginLeft: 15}}
                                onClick={() => {
                                    this.confirmAddMatches();
                                }}>
                                Add Matches
                            </Button>
                        </ToolbarItem>
                    </Toolbar>}
                </div>
                <div className="flex-v" style={{flexGrow: 2}}>
                    <LineLabel text="LDM Measurement Labels" style={{marginBottom: 15}} idCtx={phenomId.gen(["platform-literals","ldm-measurement-labels"],"")}/>
                    <Grid
                        id={phenomId.gen("ldm-measurement-labels","grid")}
                        rowHeight={50}
                        data={this.state.constrained_ldm_labels}
                        className="editorTable">
                        <GridNoRecords>
                            No Data Is Available For This Table
                        </GridNoRecords>
                        <Column title="NAME" field="name"
                                headerCell={(props) => {
                                    return <FormatMenuHeaderCell text={props.title} />;
                                }}
                                cell={(props) => {
                                    const {guid, name} = props.dataItem;
                                    return (<td>
                                                <CadetInput
                                                    idCtx={phenomId.gen(["ldm-measurement-labels",ldmRowIdx++],"grid")}
                                                    text={name}
                                                    // id={guid}
                                                    style={{margin: 0, padding: 0, height: 28, fontWeight: 'bold'}}
                                                />
                                            </td>);
                                            }}/>
                    </Grid>
                </div>
            </div>}
            {(this.props.nestedInCharacteristic || this.props.match.params.guid === "new") || <Tags guid={this.props.match.params.guid} name={this.state.name} disabled={!this.state.editable} idCtx={phenomId.genPageId()}/>}
            {!this.state.makingConstraint ||
                <Modal closeModal={this.closeModal}
                    idCtx={phenomId.gen("details")}
                    componentToShow={<ConstraintManager match={{params: {guid: "new"}}} closeModal={this.closeModal}
                        primitive={prim} />} />}
        </div>);
    }
}



export const EditPlatformTypeManager = withPageLayout(PlatformTypeManager, { renderResetBtn: false });
