import React from "react";
import ReactTooltip from "react-tooltip";
import {
    DefaultLinkWidget,
} from "@projectstorm/react-diagrams-defaults";

import styled from "@emotion/styled";
import { nodeProps } from "../design/nodeDesign";




export class DiagramLinkWidget extends DefaultLinkWidget {
    constructor(props) {
        super(props);

        this.linkModel = this.props.link;
        this.linkModel.$widget = this;
        this.$app = this.props.diagramEngine.$app;
    }

    componentDidMount() {
        ReactTooltip.rebuild();
    }

    // Written by children
    deleteLink = () => {
    }

    generateArrow(point, previousPoint, arrowHead, uncommitted) {
        return (<CustomLinkArrowWidget key={point.getID()} arrowHead={arrowHead} point={point} previousPoint={previousPoint}
                                       colorSelected={this.props.link.getOptions().selectedColor}
                                       color={uncommitted ? nodeProps["uncommittedColor"] : this.props.link.getOptions().color} />);
    }

    generateHandle(point, port) {
        return (<DragHandle key={point.getID()} point={point}
                                        port={port} link={this} engine={this.props.diagramEngine}
                                       color={this.props.link.getOptions().selectedColor} />);
    }

    generatePoint(point) {
		return (
			<DiagramLinkPointWidget
				key={point.getID()}
                point={point}
				colorSelected={this.props.link.getOptions().selectedColor}
				color={this.props.link.getOptions().color}
			/>
		);
	}

    hasAeConnection = (srcPort, dstPort) => {
        if(!srcPort || !dstPort) return false;
        return srcPort.options.name === dstPort.options.name;
    }

    // overriding Right Click
    generateLink(path, extraProps, id) {
      const ref = React.createRef();
      this.refPaths.push(ref);
      return (
        <DiagramLinkSegmentWidget
          key={`link-${id}`}
          path={path}
          selected={this.state.selected}
          diagramEngine={this.props.diagramEngine}
          factory={this.props.diagramEngine.getFactoryForLink(this.props.link)}
                  link={this.props.link}
                  linkWidget={this}
                  forwardRef={ref}
          onSelection={(selected) => {
            this.setState({ selected: selected });
          }}
          extras={extraProps}
        />
      );
    }
    
    createPointAtIdx = -1;
    pointCreated = false;
    mouseEvent = null;

    moveMouseToAddPoint = (e) => {
        e.preventDefault();
        e.persist = this.mouseEvent.persist

        let points = this.props.link.getPoints();

        if(!this.pointCreated) {
            this.addPointToLink(e, this.createPointAtIdx);
            this.pointCreated = true;
        };

        let point = points[this.createPointAtIdx];
        point.extras = {isBendPoint: true}
        let coord = this.$app.engine.getRelativeMousePoint(e);
        point.setPosition(coord);
    }

    cleanUpAfterMouseMove = (e) => {
        window.removeEventListener("mousemove", this.moveMouseToAddPoint);
        this.createPointAtIdx = -1;
        this.pointCreated = false;
        this.mouseEvent = null;
    }

	render() {
		return <g>
            <text>do not render me</text>
        </g>;
	}
}






class DiagramLinkSegmentWidget extends React.Component {

	render() {
		const Bottom = React.cloneElement(
			this.props.factory.generateLinkSegment(
				this.props.link,
				this.props.selected || this.props.link.isSelected(),
				this.props.path
			),
			{
				ref: this.props.forwardRef
			}
		);

		const Top = React.cloneElement(Bottom, {
			strokeLinecap: 'round',
			// onMouseLeave: () => {
			// 	this.props.onSelection(false);
			// },
			// onMouseEnter: () => {
			// 	this.props.onSelection(true);
      // },
			...this.props.extras,
			ref: null,
			'data-linkid': this.props.link.getID(),
			strokeOpacity: this.props.selected ? 0.1 : 0,
      strokeWidth: 20,
      class:"diagram-link",
      fill: 'none',
			// onContextMenu: (event) => {
			// 	event.preventDefault();
			// 	this.props.link.remove();
			// },
		});

		return (
			<g>
				{Bottom}
				{Top}
			</g>
		);
	}
}




const PointTop = styled.circle`
    pointer-events: all;
    cursor: pointer;
`;

export class DiagramLinkPointWidget extends React.Component {
	constructor(props) {
        super(props);
        
		this.state = {
			selected: false
		};
	}

	render() {
		const { point } = this.props;
		return (
			<g className="diagram-point">
				<circle
					cx={point.getPosition().x}
					cy={point.getPosition().y}
					r={5}
					fill={this.props.colorSelected}
				/>
				<PointTop
					className="point"
					onMouseLeave={() => {
						this.setState({ selected: false });
					}}
					onMouseEnter={() => {
						this.setState({ selected: true });
                    }}
                    onMouseDown={(e) => {
                        e.preventDefault();
                        if(e.ctrlKey && this.props.point?.extras?.isBendPoint === true) {
                            this.props.point.remove();
                        }
                    }}
					data-id={point.getID()}
					data-linkid={point.getLink().getID()}
					cx={point.getPosition().x}
					cy={point.getPosition().y}
					r={15}
					opacity={0.0}
				/>
			</g>
		);
	}
}











const STATIC = {
    background: "#f6f6f6",
}

export const CustomLinkArrowWidget = props => {
    const {point, previousPoint, arrowHead} = props;
    const angle = 90 +
        (Math.atan2(point.getPosition().y - previousPoint.getPosition().y, point.getPosition().x - previousPoint.getPosition().x) *
            180) /
        Math.PI;

    let arrow = null;
    let translate = "translate(0, 0)";
    switch(arrowHead) {
        // Association
        // Dependency
        case "thinArrow":
            arrow = <path d="M5,20 L0,0 L-5,20" stroke={props.color} strokeWidth={2} fill="none" 
                            data-id={point.getID()} data-linkid={point.getLink().getID()} />
            break;
        // ShapeArrow (Stencilbox)
        case "mediumArrow":
            arrow = <path d="M10,20 L0,0 L-10,20" stroke={props.color} strokeWidth={3} fill="none" 
                          data-id={point.getID()} data-linkid={point.getLink().getID()} />
            break;
        // Inheritance
        // Realization / Implementation
        case "thinEmptyArrow":
            arrow = <path d="M0,0 L5,20 L-5,20 L0,0" stroke={props.color} strokeWidth={2} fill={STATIC.background} 
                            data-id={point.getID()} data-linkid={point.getLink().getID()} />
            break;
        // Aggregation
        case "diamondEmptyArrow":
            arrow = <path d="M0,0 L5,12 L0,24 L-5,12 L0,0 L5,12" stroke={props.color} strokeWidth={2} fill={STATIC.background} 
                            data-id={point.getID()} data-linkid={point.getLink().getID()} />
            break;
        // Composition
        case "diamondFilledArrow":
            arrow = <path d="M0,0 L5,12 L0,24 L-5,12 L0,0 L5,12" stroke={props.color} strokeWidth={2} fill={props.color} 
                            data-id={point.getID()} data-linkid={point.getLink().getID()} />
            break;
        case "renameMe":
            arrow = <polygon points="0,10 8,30 -8,30" fill={props.color} 
                            onMouseLeave={() => this.setState({selected: false})} 
                            onMouseEnter={() => this.setState({selected: true})} 
                            data-id={point.getID()} data-linkid={point.getLink().getID()} />
            translate = "translate(0, -10)";
        default: 
            return null;
    }

    //translate(50, -10),
    return (<g className="arrow" 
                transform={"translate(" + point.getPosition().x + ", " + point.getPosition().y + ")"}>
        <g style={{transform: "rotate(" + angle + "deg)"}}>
            <g transform={translate}>
                {arrow}
            </g>
        </g>
    </g>);
};


export const startMoveArrow = (movingPoint, port, engine, link) => {
    engine.$app.domModelLayerOnTop();
    // const managerId = engine.$app.props.managerId;
    // const svgLayer = document.querySelector(`div[data-scratchpad=${managerId}] .srd-demo-canvas > svg:first-of-type`);
    // svgLayer.style.zIndex = "0";

    let nodeModel = port.parent;
    let original_pos = port.getPosition();

    if(port.options.type === "path-port") {
        var arrowClass = "arrow-path-drag";
    } else {
        var arrowClass = "arrow-comp-drag";
    }

    const node = document.querySelector(`div[data-nodeid="${nodeModel.options.id}"] div:first-child`);

    const moveArrow = (e) => {
        if(e.stopPropagation) e.stopPropagation();
        if(e.preventDefault) e.preventDefault();

        const mouse_pos = engine.getRelativeMousePoint(e);
        movingPoint.setPosition(mouse_pos);
        link.forceUpdate();
    }

    const repositionArrow = (e) => {
        const placement = e.target.dataset.placement;
        // return arrow back to port
        movingPoint.setPosition(original_pos);
        if(placement) nodeModel.setPortPosition(port, placement);
  
        cleanUp();
        link.forceUpdate();
        engine.repaintCanvas();
    }

    const cleanUp = () => {
        engine.$app.domSvgLayerOnTop();
        node.classList.remove(arrowClass);
        window.removeEventListener("mousemove", moveArrow);
        window.removeEventListener("mouseup", repositionArrow);
    }

    node.classList.add(arrowClass);
    window.addEventListener("mousemove", moveArrow);
    window.addEventListener("mouseup", repositionArrow);
}



const DragHandle = (props) => {
    const {point, port, link, engine} = props;

    return (
        <g transform={"translate(" + point.getPosition().x + ", " + point.getPosition().y + ")"}>
                    <circle r="5" fill={props.color} />
                    <PointTop
                        r={15}
                        opacity={0}
                        onMouseDown={(e) => {
                            e.stopPropagation();
                            startMoveArrow(point, port, engine, link)
                        }}
                    />
        </g>
    )
}