import React, { Component } from "react";
import { connect } from "react-redux";
import { Prompt } from "react-router-dom";
import ViewTrace from "../view_trace/ViewTrace";
// import Scratchpad from "../scratchpad/Scratchpad";
import IdmEditor from "../idm_editor/IdmEditor";
import $ from "jquery";
import domToImg from "dom-to-image";
import { Button } from "@progress/kendo-react-buttons";
import { _ajax, getNodeWithAddenda } from "../../../../requests/sml-requests";
import { createPhenomGuid, defaultBounds, deGuidify, isPhenomGuid } from "../../../util/util";
import { StormPrompt } from "../modal/StormPrompt";
import { BasicAlert } from "../../../dialog/BasicAlert";
import PhenomId from "../../../../requests/phenom-id";
import { Notifications2 } from "../../../edit/notifications";
import { Semantics } from "../../../dialog/semantics";
import Portal from "../../../dialog/Portal";
import { createImPort, isStormData } from "../util";
import { invert } from "lodash";
import DeletionConfirm2 from "../../../dialog/DeletionConfirm2";
import imageCompression from 'browser-image-compression';

import NavTree from "../../../tree/NavTree";
import { withRouterAndRef, KbButton } from "../../../util/stateless";
import { saveAs } from "file-saver";
import StormData from "../base/StormData";
import { receiveErrors } from "../../../../requests/actionCreators";
import { SubMenuRight } from "../../../edit/edit-top-buttons";


/**
 * INDEX
 * ------------------------------------------------------------
 * 00. Addenda
 * 01. Life Cycle Methods
 * 02. Tabs
 * 03. Diagram Actions
 * 04. Nodes Getters/Setters
 * 05. Fetch Api
 * 20. Scratchpad
 * 30. IDM Editor
 * 40. View Trace
 * 50. Nav Tree
 * 70. Helper
 * ------------------------------------------------------------
 */


// ------------------------------------------------------------
// 00. Addenda
// ------------------------------------------------------------
const assocEntiyAddenda = {
  coreAddenda: ["childrenMULTI"],
  coreAddendaChildren: ["pathPairsMULTI"],
}

const viewAddenda = {
  coreAddenda: ["childrenMULTI"],
  coreAddendaChildren: ["pathPairsMULTI"],
}

const viewTraceAddenda = {
  coreAddenda: ["semanticFields"],
}

const imContextAddenda = {
  coreAddenda: ["im_context_add_nodes"]
}

const uopiAddenda = {
  coreAddenda: ["childrenMULTI", "realizes"],
  // coreAddendaChildren: ["connection"],
  realizesAddenda: ["childrenMULTI"],
}

const composedBlockAddenda = {
  coreAddenda: ["childrenMULTI", "realizes"],
}



class StormManager extends Component {
  constructor(props) {
    super(props);
    this.phenomId = new PhenomId("storm");
  }

  state = {
    activeIdx: 0,
    diagrams: [],
    showRemovalWarning: true,

    // for unsaved prompt
    showUnsavedPrompt: true,
    promptLocation: null,
    promptAnswer: false,
  }

  // Indices
  nodeMap = {}

  imPackageList = []
  transportChannelList = []
  viewList = []

  // Scratchpad
  associationEntityMap = {}        // with Associations
  measurementMap = {}
  observableMap = {}
  platformTypeMap = {}
  viewMap = {}



  // ------------------------------------------------------------
  // 01. Life Cycle Methods
  // ------------------------------------------------------------
  componentDidMount() {
    this.init();
    window.addEventListener('LOAD_CONTEXT', this.loadContextFromNavTree);
    window.addEventListener('DELETED_NODES', this.removeNodesListener);
  }

  componentDidUpdate(prevProps) {
    const { stormType, location, nodesOfType } = this.props;

    if (stormType !== prevProps.stormType) {
      this.init();
    }

    if (nodesOfType !== prevProps.nodesOfType) {
      switch (stormType) {
        case "idm_editor":
          break;

        default:
      }
    }
  }

  componentWillUnmount() {
    if (window["sessionExpired"]) {
      const recoverFiles = this.state.diagrams.map((diagram, idx) => {
        return {
          fileId: diagram.fileId,
          fileName: diagram.fileName,
          content: this.serializeDiagram(idx),
        }
      });
      sessionStorage.setItem(this.props.stormType + " recoverFile", JSON.stringify(recoverFiles));
    }

    window.removeEventListener('LOAD_CONTEXT', this.loadContextFromNavTree);
    window.removeEventListener('DELETED_NODES', this.removeNodesListener);
  }

  init = async () => {
    const { stormType } = this.props;
    NavTree.collapseNavTree(false);

    switch (stormType) {
      case "scratchpad":
        // this.addDiagramPackageToNavTree();
        this.initMeasurementNodes();
        break;

      case "view_trace":
        NavTree.assignPresetPageFilters("views");
        break;

      case "idm_editor":
        NavTree.assignPresetPageFilters("integration");
        this.initViewNodes();
        break;
    }

    const recover = sessionStorage.getItem(this.props.stormType + "Recover");
    if (recover) {
      this.recoverDiagrams(JSON.parse(recover));
      sessionStorage.removeItem(this.props.stormType + " recoverFile");
    } else {
      await this.createNewTab();
    }

    this.loadContext();
  }

  loadContext = () => {
    const { stormType, location } = this.props;

    // load context node (double clicked in Nav Tree)
    if (!location.state?.guid) {
      return;
    }

    switch (stormType) {
      case "idm_editor":
        this.loadImContext(location.state.guid);
        break;

      default:
    }
  }

  loadContextFromNavTree = (e) => {
    const { stormType } = this.props;
    const { detail } = e;

    // invalid
    if (!detail) {
      return;
    }

    switch (stormType) {
      case "idm_editor":
        if (["im:IntegrationContext", "im:ComposedBlock"].includes(detail.xmiType)) {
          this.loadImContext(detail.guid);
        }
        break;

      default:
    }
  }

  removeNodesListener = (e) => {
    let guids = e?.detail?.guids;

    // invalid
    if (!Array.isArray(guids)) {
      return;
    }

    // remove deleted context tabs
    for (let contextGuid of guids) {
      const removeIdx = this.state.diagrams.findIndex(d => d.ref.getContextGuid() === contextGuid);
      if (removeIdx > -1) {
        this.deleteTab(removeIdx);
      }
    }

    // remove deleted context nodes
    this.removeConnectionsFromAllTabs(guids);
  }

  initViewNodes = () => {
    _ajax({
      url: "/index.php?r=/node/model-nodes-of-type",
      method: "get",
      data: {
        type: "platform:View",
        coreAddenda: ["childrenMULTI"],
        Filter: {
          firstPass: {
            event: "topLevelViewsWithComposite",
          }
        }
      },
    }).then((res) => {
      const response = res.data;
      this.viewList = response.nodes || [];
      this.forceUpdate();
    })
  }

  initMeasurementNodes = () => {
    _ajax({
      url: "/index.php?r=/node/model-nodes-of-type",
      method: "get",
      data: {
        type: ["logical:Measurement"],
      },
    }).then(res => {
      const response = res.data;
      response.nodes.forEach(node => this.setDiagramStormData(node));
    })
  }

  // ------------------------------------------------------------
  // 02. Tabs
  // ------------------------------------------------------------
  getActiveTab = () => {
    return this.state.diagrams[this.state.activeIdx];
  }

  createNewTab = (fileData={}) => {
    const diagram = {
      diagramId: createPhenomGuid(),
      fileId: fileData.fileId || createPhenomGuid(),
      fileName: fileData.fileName || `New_${this.state.diagrams.length + 1}`,
      saved: true,
      ref: null,
    }

    this.setState((prevState) => ({
      activeIdx: prevState.diagrams.length,
      diagrams: [...prevState.diagrams, diagram],
    }))
  }

  /**
   * Changes the active diagram
   *
   * @param {number} idx
   */
  switchTab = (idx) => {
    if (typeof idx !== 'number') {
      return;
    }

    this.setState({ activeIdx: idx }, () => {
      const activeTab = this.state.diagrams[idx];
      activeTab.ref?.refresh && activeTab.ref.refresh();
    });
  }

  /**
   * Deletes a Tab and sets a new Active Index value
   *
   * Case 1: Delete current tab
   *    [X] [1] [2] [3]
   *    After deleting the current active index, the latest diagram becomes the new active tab
   *
   * Case 2: Deleting a tab with a smaller index
   *    [X] [1] [A] [3]
   *    The new active index needs to decrease by one
   *
   * Case 3: Deleting a tab with a larger index
   *    [A] [1] [X] [3]
   *    The current active index does not need an adjustment
   *
   * Case 4: Deleting a tab with only one diagram present
   *    [X]
   *    Create a new tab
   *
   * @param {number} idx
   */
  deleteTab = (idx) => {
    let { activeIdx, diagrams } = this.state;

    let newDiagrams = [...diagrams]; // 3
        newDiagrams.splice(idx, 1); // 2

    // Case 1:
    if (activeIdx === idx) {
      activeIdx = newDiagrams.length - 1;

    // Case 2:
    } else if (activeIdx > idx) {
      activeIdx = activeIdx - 1;
    }

    // Case 3:
    // Jacob loves pizza and beer

    this.setState({
      activeIdx,
      diagrams: newDiagrams,
    }, () => {
      // Case 4:
      if (this.state.diagrams.length < 1) {
        this.createNewTab();
      }
    });
  }

  // TODO: review
  updateTabProps = (key, value, idx) => {
    if (typeof idx !== 'number' || idx === -1) {
      idx = this.state.activeIdx;
    }

    const diagrams = [...this.state.diagrams];
    const tab = diagrams[idx];
    tab[key] = value;
    this.setState({ diagrams });
  }

  // ------------------------------------------------------------
  // 03. Diagram Actions
  // ------------------------------------------------------------

  /**
   * Loads a diagram for ViewTrace only
   *
   * If diagram was previously loaded then switch tabs.
   * If the current diagram contain nodes then create a new tab
   * If the current diagram is empty then fetch and load the diagram
   *
   * @param {number} fileId
   * @param {string} fileName
   */
  loadDiagram = async (fileId, fileName) => {
    // Switch tab if diagram was previously loaded
    const loadedIdx = this.state.diagrams.findIndex(d => d.fileId === fileId);
    if (loadedIdx > -1) {
      return this.switchTab(loadedIdx);
    }

    let activeTab = this.state.diagrams[this.state.activeIdx];
    let activeRef = activeTab.ref;

    // Create new tab if current diagram is being used
    if (activeRef.hasContent()) {
      await this.createNewTab({ fileId, fileName });
      activeTab = this.state.diagrams[this.state.activeIdx];
      activeRef = activeTab.ref;
    }

    activeTab.fileId = fileId;
    activeTab.fileName = fileName;

    const res = await $.ajax({
      method: "get",
      url: "/index.php?r=/diagram/_diagram-load",
      data: { id: fileId }
    })

    const response = JSON.parse(res);
    const presentationData = JSON.parse(response.content);
    await this.fetchMissingNodesFromPresentationData(presentationData);
    this.readdNodesFromPresentationData(presentationData);
    this.forceUpdate();

    await activeRef.loadDiagram(presentationData);
  }


  /**
   * Saves a diagram for ViewTrace only
   *
   * @param {number} fileId
   * @param {string} fileName
   * @param {number} tabIdx
   */
  saveDiagram = async (fileId, fileName, tabIdx) => {
    if (typeof tabIdx !== 'number' || tabIdx === -1) {
      tabIdx = this.state.activeIdx;
    }

    BasicAlert.show("Saving diagram...", "Saving");
    const diagrams = [...this.state.diagrams];
    const tab = diagrams[tabIdx];
    const { engine } = tab.ref;

    const contents = JSON.stringify(tab.ref.serializeModel());

    switch (this.props.stormType) {
      case "view_trace":
        var category = "t";
        break;

      default:
    }

    try {
      const imageData = await domToImg.toPng(engine.getCanvas());
      
      const res = await $.ajax({
        method: "post",
        url: "/index.php?r=/diagram/_diagram-save",
        data: {
            category,
            index: fileId,
            filename: fileName,
            contents,
            imageData,
        },
      });

      const response = JSON.parse(res);
      tab.saved = true;
      tab.fileName = response.Name;
      tab.fileId = response.Diagram_ID;

      if (tab.ref?.setDiagramSavedStatus) {
        tab.ref.setDiagramSavedStatus(true);
      }

      this.forceUpdate();

      Notifications2.parseLogs(`${fileName} successfully saved.`);
      BasicAlert.hide();

    } catch (err) {
      Notifications2.parseErrors("Unable to save diagram. Server responded with an unexpected status code.");
      BasicAlert.hide();
    }
  }

  /**
   * Deletes a diagram for ViewTrace
   *    The delete request is handled by StormPrompt
   *
   * @param {number} fileId
   */
  deleteDiagram = (fileId) => {
    const tabIdx = this.state.diagrams.findIndex(d => d.fileId === fileId);
    if (tabIdx < 0) return;

    this.deleteTab(tabIdx);
  }

  /**
   * TODO: REMOVE
   * Serializes a diagram's data to be stringified
   *
   * @param {number} idx
   * @returns json
   */
  serializeDiagram = (idx) => {
    const tabIdx = idx || idx === 0 ? idx : this.state.activeIdx;
    const tab = this.state.diagrams[tabIdx];
    const { model } = tab.ref;

    const serializedData = model.serialize();
    return serializedData;
  }

  /**
   * TODO: REMOVE
   * Restores a user session
   *
   * @param {array} recoverData
   */
  recoverDiagrams = async (recoverData) => {
    BasicAlert.show("Recovering session", "Recovering...", true);

    for (let idx in recoverData) {
      await this.createNewTab(recoverData[idx]);
    }

    for (let idx in recoverData) {
      const recover = recoverData[idx];
      const diagram = this.state.diagrams[idx];

      if (diagram) {
        switch (this.props.stormType) {
          case "idm_editor":
            await diagram.ref.loadImContext({}, recover.content);
            break;
          default:
            await diagram.ref.loadDiagram(recover.content);
        }

        Notifications2.parseLogs(`Recovered '${recover.fileName}' diagram.`);
      }
    }

    BasicAlert.hide();
  }

  /**
   * Opens the dialog box
   *
   * @param {string} dialogType
   */
  showDialog = (dialogType, redirectToUrl) => {
    this.dialogRef.show(dialogType, redirectToUrl);
  }

  /**
   * Converts the diagram into a downloadable png image
   *
   * @param {number} tabIdx
   */
  exportDiagramWithCropping = (tabIdx) => {
    if (typeof tabIdx !== 'number' || tabIdx === -1) {
      tabIdx = this.state.activeIdx;
    }
    
    const tab = this.state.diagrams[tabIdx || this.state.activeIdx];
    BasicAlert.show(`Generating ${tab.fileName}`, "One moment please", true);

    this.createScreenshotWithCropping(tabIdx).then(image => {
      saveAs(image, `${tab.fileName || "my-diagram"}.png`);
      BasicAlert.hide();
    })
  }

  exportDiagramWithoutCropping = (tabIdx) => {
    if (typeof tabIdx !== 'number' || tabIdx === -1) {
      tabIdx = this.state.activeIdx;
    }

    const tab = this.state.diagrams[tabIdx];
    BasicAlert.show(`Generating ${tab.fileName}`, "One moment please", true);

    this.createScreenshotWithoutCropping(tabIdx).then(image => {
      saveAs(image, `${tab.fileName || "my-diagram"}.png`);
      BasicAlert.hide();
    });
  }

  createScreenshotWithCropping = async (tabIdx) => {
    try {
      if (typeof tabIdx !== 'number' || tabIdx === -1) {
        tabIdx = this.state.activeIdx;
      }

      const tab = this.state.diagrams[tabIdx];
      const { engine, model } = tab.ref;
      const { min_x, min_y, max_x, max_y } = this.getCroppedCanvasCoords(tabIdx);
      const prevZoom = model.getZoomLevel();
      const canvas = engine.getCanvas();
      const buffer = 50;

      // DomToImg does not have a way to crop
      // have to manipulate the current canvas and then reset it afterwards
      model.setZoomLevel(100);
      model.setOffsetX(Math.round(min_x) * -1 + buffer);
      model.setOffsetY(Math.round(min_y) * -1 + buffer);
      engine.repaintCanvas();

      const rawImage = await domToImg.toBlob(canvas, { 
        bgcolor: "transparent", 
        height: max_y - min_y + (buffer * 2), 
        width: max_x - min_x + (buffer * 2), 
      })

      const compressedFile = await imageCompression(rawImage);
      const compressedBase64 = await imageCompression.getDataUrlFromFile(compressedFile);

      model.setZoomLevel(prevZoom);
      model.setOffsetX(0);
      model.setOffsetY(0);
      engine.repaintCanvas();
      return compressedBase64;

    } catch (error) {
      console.error(error);
    }
  }

  createScreenshotWithoutCropping = (tabIdx) => {
    try {
      if (typeof tabIdx !== 'number' || tabIdx === -1) {
        tabIdx = this.state.activeIdx;
      }

      const tab = this.state.diagrams[tabIdx];
      const { engine } = tab.ref;

      return domToImg.toPng(engine.getCanvas()).then((imgData) => {
        return imgData;
      })

    } catch (error) {
      console.error(error);
    }
  }


  hasUnsavedDiagram = () => {
    return !this.state.diagrams.every(tab => tab.ref?.getDiagramSaveStatus && tab.ref.getDiagramSaveStatus());
  }





  // ------------------------------------------------------------
  // 04. Nodes Getters/Setters
  // ------------------------------------------------------------

  /**
   * Nodes are shared across diagrams. When a node is edited, the changes will appear in the other tabs/diagrams
   * The nodes are stored in a hash table and fetched using its guid. This is to ensure the reference pointer do not change.
   *
   * @param {string} guid
   * @returns node if found, otherwise undefined
   */
  getDiagramStormData = (guid) => {
    return this.nodeMap[guid];
  }

  // deprecated
  getDiagramNodeData = (guid) => {
    return this.getDiagramStormData(guid);
  }

  /**
   * Assign node (and all nested nodes) to index
   *    -> creates new StormData if node is not in index
   *    -> updates the StormData if node already exist in index
   *
   * @param {object} node
   * @param {hash} hashRealToPhenom key-value pair in the form of { <real_guid>: <phenom_guid> }
   */
  setDiagramStormData = (node, hashRealToPhenom={}, overrideOriginal=false) => {
    if (!node?.guid) return;

    // The IM Context Addenda dereferences parents - UoPi nodes are not children of context. This is the best way to fetch them when the JSON data is not present. 
    // Previously this was using "Depth First Search" to collect all nodess but it ran into issues with the new associationNode
    //    -> basically there can be multiple instances of the attribute "associationNode". depending on which one it finds first, it will determine whether it is null or not.
    // Now this is using "Breadth First Search" to look through the nodes and ignores duplicate nodes.
    const nodeHash = {};
    const queue = [ node ];
    while (queue.length) {
      const data = queue.shift();

      // invalid
      if (!data?.guid || !data?.xmiType) {
        continue;
      }

      // gather nested nodes
      for (let key in data) {
        const attr = data[key];

        // ignore
        if (["guid", "xmiType"].includes(key)) {
          continue;
        }

        if (Array.isArray(attr)) {
          attr.forEach(ele => ele?.guid && !nodeHash[ele.guid] && queue.push(ele));

        } else if (attr?.guid && !nodeHash[attr.guid]) {
          queue.push(attr);
        }
      }

      nodeHash[data.guid] = data;
    }

    for (let data of Object.values(nodeHash)) {
      if (!data?.guid) continue;
      let stormData = this.nodeMap[data.guid];

      // special case for skayl:DiagramContext
      if (data.image) data.image = "";
      if (data.content) data.content = "";

      if (isStormData(stormData)) {
        stormData.updateStormData(data);
        if (overrideOriginal) stormData.updateOriginalStormData(data);

      } else {
        stormData = new StormData(data);
        this.nodeMap[data.guid] = stormData;
      }

      // phenom guid was converted to real guid, redirect pointer
      let phenomGuid = hashRealToPhenom[stormData.getGuid()];
      if (phenomGuid) {
        this.nodeMap[phenomGuid] = stormData;
      }
    }

    // return top level node
    return this.getDiagramStormData(node.guid);
  }

  readdPhenomAttrs = (node) => {
    if (!node?.guid) return;

    const nodeHash = {};
    const queue = [ node ];

    // gather all nested nodes
    while (queue.length) {
      const data = queue.shift();

      // invalid
      if (!data?.guid) {
        continue;
      }

      for (let key in data) {
        const attr = data[key];

        // ignore
        if (["guid", "xmiType"].includes(key)) {
          continue;
        }

        if (Array.isArray(attr)) {
          attr.forEach(ele => ele?.guid && !nodeHash[ele.guid] && queue.push(ele));

        } else if (attr?.guid && !nodeHash[attr.guid]) {
          queue.push(attr);
        }
      }

      nodeHash[data.guid] = data;
    }

    for (let data of Object.values(nodeHash)) {
      // invalid node
      if (!data?.guid || !data.xmiType) {
        continue;
      }

      // add phenom nodes to index
      if (isPhenomGuid(data.guid)) {
        this.setDiagramStormData(data);

      } else {
        // readd phenom attrs to real node
        const stormData = this.getDiagramStormData(node.guid);
        isStormData(stormData) && stormData.readdPhenomAttrs(node);
      }
    }
  }

  // deprecated
  setDiagramNodeData = (node, hashRealToPhenom={}, overrideOriginal) => {
    return this.setDiagramStormData(node, hashRealToPhenom, overrideOriginal);
  }

  /**
   * Retrieves a hash table of nodes.
   *
   * @param {string} type
   * @returns hash table
   */
  getDiagramStormDataMap = () => {
    return this.nodeMap;
  }


  /**
   * Retrieve the node from the index; or fetch the node if not found.
   * 
   * @param {object} node object with guid and xmiType attributes
   * @returns StormData or undefined
   */
  getOrFetchDiagramStormData = async (node) => {
    if (!node?.guid) return;

    let stormData = this.getDiagramStormData(node.guid);
    if (!stormData && !isPhenomGuid(node.guid)) {
      stormData = await this.fetchStormData(node);
    }

    return stormData;
  }


  /**
   * Creates Node Data
   *
   * @param {xmiType} type string in the form of a node's xmiType
   * @returns StormData
   */
   createStormData = (xmiType) => {
    const nodeList = Object.values(this.nodeMap).filter(nd => nd.getXmiType() === xmiType);
    const nameList = new Set(nodeList.map(nd => nd.getName()));

    const node = {
      guid: createPhenomGuid(),
      xmiType,
      children: [],
    }

    let prefix = "";
    switch (xmiType) {
      case "conceptual:Entity":
        prefix = "Ent";
        break;

      case "conceptual:Association":
        prefix = "Assoc";
        break;

      case "platform:View":
        prefix = "View";
        node.structureKind = "nesting";
        break;

      case "im:SourceNode":
        prefix = "Source";
        node.nodeKind = "source";
        node.children.push(createImPort(true, node.guid));
        break;

      case "im:SinkNode":
        prefix = "Sink";
        node.nodeKind = "sink";
        node.children.push(createImPort(false, node.guid));
        break;

      case "im:FanIn":
        prefix = "FanIn";
        node.nodeKind = "fanin";
        node.children.push(createImPort(true, node.guid));
        break;

      case "im:Generic":
        prefix = "Generic";
        node.nodeKind = "generic";
        break;

      case "im:TransformNode":
        prefix = "Transform";
        node.nodeKind = "transform";
        node.transformType = "auto";
        node.children.push(createImPort(true, node.guid));
        node.children.push(createImPort(false, node.guid));
        break;

      case "im:FilterNode":
        prefix = "Filter";
        node.nodeKind = "filter";
        node.test = undefined;       // optional
        node.children.push(createImPort(true, node.guid));
        node.children.push(createImPort(false, node.guid));
        break;

      case "im:ViewTransporterNode":
        prefix = "Transporter";
        node.nodeKind = "transporter";
        node.channel = "";
        node.children.push(createImPort(true, node.guid));
        node.children.push(createImPort(false, node.guid));
        break;

      case "im:SIMAdapter":
        prefix = "SIM_Adapter";
        node.nodeKind = "simadapter";
        node.staleTime = "";
        node.children.push(createImPort(true, node.guid));
        node.children.push(createImPort(false, node.guid));
        break;

      case "im:QueuingAdapter":
        prefix = "Queuing_Adapter";
        node.nodeKind = "queuingadapter";
        node.queueDepth = "";
        node.children.push(createImPort(true, node.guid));
        node.children.push(createImPort(false, node.guid));
        break;

      case "im:DataPump":
        prefix = "Data_Pump";
        node.nodeKind = "datapump";
        node.children.push(createImPort(true, node.guid));
        node.children.push(createImPort(false, node.guid));
        break;

      case "im:ManualTransform":
        node.update = undefined;   // optional
        break;

      case "im:Equation":
        prefix = "Equation";
        node.equation = "";
        node.deconflictName = true;
        break;

      case "im:TransporterNodeToTransportChannel":
        node.Transporter_Guid = "";
        node.Channel_Guid = "";
        break;
    }

    // a few nodes do not need a name
    if (prefix) {
      let count = 1;
      node.name = `${prefix}_${count}`;

      while(nameList.has(node.name)) {
        count++;
        node.name = `${prefix}_${count}`
      }
    }

    return this.setDiagramStormData(node);
  }


  createComposedStormData = (xmiType, contextData) => {
    let node = {
      guid: createPhenomGuid(),
      rolename: "",
      xmiType,
      type: "Default",
      flowTrigger: "NONE",
      dataType: "",
      templateType: "",
      parent: contextData.getGuid(),
      children: [],
    }

    contextData.addChild(node.guid);
    return this.setDiagramStormData(node);
  }

  createComposedBlockInstanceData_from_ComposedBlock = (block) => {
    const nodeList = Object.values(this.nodeMap).filter(nd => nd.getXmiType() === "im:ComposedBlockInstance");
    const nameList = new Set(nodeList.map(nd => nd.getName()));

    const node = {
      guid: createPhenomGuid(),
      xmiType: "im:ComposedBlockInstance",
      realizes: block?.guid,
      nodeKind: "composed_instance",
      children: [],
    }

    if (Array.isArray(block.children)) {
      for (let child of block.children) {
        if (!["im:ComposedInPort", "im:ComposedOutPort"].includes(child.xmiType)) {
          continue;
        }

        let newChild;
        if (child.xmiType === "im:ComposedInPort") {
          newChild = createImPort(false, node.guid);
        } else {
          newChild = createImPort(true, node.guid);
        }

        for (let key of ["rolename", "flowTrigger", "dataType"]) {
          newChild[key] = child[key] || "";
        }

        newChild.realizes = child.guid;
        newChild.realized_type = child.type;
        newChild.realized_dataType = child.dataType || "";
        newChild.realized_templateType = child.templateType || "";
        node.children.push(newChild);
      }
    }

    let count = 1;
    let prefix = `${block.name}_Instance`;
    node.name = `${prefix}_${count}`;

    while(nameList.has(node.name)) {
      count++;
      node.name = `${prefix}_${count}`
    }

    this.setDiagramStormData(node);
    return this.getDiagramStormData(node.guid);
  }

  /**
   * 
   * @param {xmiType} xmiType 
   * @param {StormData} parentData 
   * @param {object} info 
   * @returns StormData
   */
  createChildStormData = (xmiType, parentData, info={}) => {
    if (!isStormData(parentData)) return;

    const child = {
      guid: createPhenomGuid(),
      xmiType: xmiType,
      children: [],
    }

    switch (xmiType) {
      case "conceptual:Composition":
        child.rolename = info.rolename || "";
        child.description = "";
        child.type = info.type || "";
        child.lowerBound = defaultBounds["lowerBound"];
        child.upperBound = defaultBounds["upperBound"];
        child.tag = "composition";
        break;
      
      case "conceptual:AssociatedEntity":
        child.rolename = info.rolename || "";
        child.description = "";
        child.type = info.type || "";
        child.sourceLowerBound = defaultBounds["sourceLowerBound"];
        child.sourceUpperBound = defaultBounds["sourceUpperBound"];
        child.lowerBound = defaultBounds["lowerBound"];
        child.upperBound = defaultBounds["upperBound"];
        child.path = "";
        child.tag = "associatedEntity";
        break;

      case "platform:CharacteristicProjection":
        child.rolename = info.rolename || "";
        child.descriptionExtension = "";
        child.measurement = "";
        child.path = "";
        child.platformType = null;
    // platformValues: [],
        child.projectedCharacteristic = "";
    // pathPairs: [],
        child.optional = "false";
        child.attributeKind = "privatelyScoped";
        break;

      case "im:UoPOutputEndPoint":
      case "im:UoPInputEndPoint":
        child.connection = info.connection;
        break;

      case "im:OutPort":
      case "im:InPort":
        child.dataType = "";
        child.rolename = "";
        child.flowTrigger = "NONE";
        break;

      case "im:ManualTransform":
        child.update = undefined;   // optional
    }

    parentData.addChild(child.guid);
    return this.setDiagramStormData(child);
  }

  /**
   * Merges the json data with model data because they are out of sync.
   * 
   * @param {object} presentationData 
   * @returns 
   */
  readdNodesFromPresentationData = async (presentationData) => {
    // Parse React Storm data only
    if (!presentationData?.id) return;

    const { version=0, nodeHash, layers } = presentationData;
    let queue = [];

    // version 2 contains flat nodes
    if (version >= 2 && nodeHash) {
      queue = Object.values(nodeHash);

    } else {
      // version 1 contains nested nodes
      const linkModels = layers.find(e => e.type === "diagram-links").models;
      const nodeModels = layers.find(e => e.type === "diagram-nodes").models;

      let nodeList = Object.values(nodeModels).map(nm => nm.nodeData);
      let linkList = Object.values(linkModels).map(lm => lm.attrData);
      queue = nodeList.concat(linkList);
    }


    for (let node of queue) {
      // invalid node
      if (!node?.guid || !node.xmiType) {
        continue;
      }

      this.readdPhenomAttrs(node);
    }
  }



  // ------------------------------------------------------------
  // 05. Fetch Api
  // ------------------------------------------------------------

  /**
   * Fetches an individual node based on its xmiType
   *    -> Instead of listing every Block Node, it is handled by the default case.
   *
   * @param {node} node with a guid and xmiType
   * @returns StormData
   */
  fetchStormData = async (node) => {
    let response;

    try {
      switch (node.xmiType) {
        case "conceptual:Entity":
        case "conceptual:Association":
          response = await getNodeWithAddenda(node.guid, assocEntiyAddenda);
          break;

        case "platform:View":
          if (this.props.stormType === "view_trace") {
            response = await getNodeWithAddenda(node.guid, viewTraceAddenda);
          } else {
            response = await getNodeWithAddenda(node.guid, viewAddenda);
          }
          break;

        case "im:UoPInstance":
          response = await getNodeWithAddenda(node.guid, uopiAddenda);
          break;

        // note: fetch ComposedBlock data and create a new ComposedBlockInstance!
        case "im:ComposedBlock":
          response = await getNodeWithAddenda(node.guid, composedBlockAddenda);
          break;

        // Block Node (IDM Editor) - silence code:500 errors from getting nodes removed from model with 
        default:
          response = await getNodeWithAddenda(node.guid, { coreAddenda: ["childrenMULTI"]}, {500: {}});
      }

      // invalid response/node
      if (!response?.guid) {
        return;
      }

      // SPECIAL CASE:
      // use ComposedBlock data and create a new ComposedBlockInstance node
      if (response.xmiType === "im:ComposedBlock") {
        return this.createComposedBlockInstanceData_from_ComposedBlock(response);
      }

      // // fetch any dependent/additional nodes
      // let dependentNodes = [];
      // switch (response.xmiType) {
      //   case "platform:View":
      //     for (let char of response.children) {
      //       if (!char.pathPairs) {
      //         continue;
      //       }
            
      //       for (let pair of char.pathPairs) {
      //         dependentNodes.push(pair.parent);
      //         dependentNodes.push(pair.type);
      //       }

      //       // remove pathPairs to prevent "setDiagramStormData" from triggering
      //       delete char["pathPairs"]
      //     }
      //     break;

      //   default:
      // }

      // // remove duplicate guids/nodes
      // let uniqueGuids = new Set();
      // dependentNodes = dependentNodes.filter((node) => {
      //   if (uniqueGuids.has(node.guid)) return false;
      //   uniqueGuids.add(node.guid);
      //   return true;
      // })

      // // fetch dependent/additional nodes in the background
      // if (dependentNodes.length) {
      //   for (let dependent of dependentNodes) {
      //     setTimeout(() =>  this.fetchStormData(dependent), 0);
      //   }
      // }

      this.setDiagramStormData(response);
      return this.getDiagramStormData(response.guid);

    } catch (err) {
      console.error(err);
    }
  }





  /**
   * Fetches a list of Measurements
   *    Scratchpad uses this data to dereference attributes
   */
  fetchMeasurements = () => {
    return _ajax({
      url: "/index.php?r=/node/model-nodes-of-type",
      method: "get",
      data: {
        type: ["logical:Measurement"],
      },
    }).then(res => {
      this.measurementMap = deGuidify(res.data.nodes);
    })
  }

  fetchImContext = (contextGuid) => {
    return getNodeWithAddenda(contextGuid, imContextAddenda).then(response => {
      response?.guid && this.setDiagramStormData(response);
      return response;
    })
  }

  fetchPresentationContext = (contextGuid) => {
    return _ajax({
      url: "/index.php?r=/integration-diagram/diagram-load",
      data: {
        guid: contextGuid
      },
    }).then(res => {
      return JSON.parse(res.data);
    })
  }

  /**
   * This method loops through the presentation data and fetches all missing nodes.  
   * 
   * Nodes retrieved from the context can have missing nodes
   *    -> i.e. if a UoPi is not connected to a block node, it will not appear within the context fetch request
   *    -> i.e. Scratchpad Context does not come with any nodes
   * 
   * @param {object} presentationData 
   * @returns void
   */
  fetchMissingNodesFromPresentationData = async (presentationData) => {
    // Parse React Storm data only
    if (!presentationData?.id) return;

    const { version=0, nodeHash, layers } = presentationData;
    let queue = [];

    // version 2 consist of flat nodes
    if (version >= 2 && nodeHash) {
      queue = Object.values(nodeHash);

    } else {
      // version 1 consist of nested nodes
      const nodeModels = layers.find(e => e.type === "diagram-nodes").models;
      queue = Object.values(nodeModels).map(nm => nm.nodeData);
    }

    for (let node of queue) {
      // invalid node
      if (!node?.guid || !node.xmiType || isPhenomGuid(node.guid)) {
        continue;
      }

      // skip - real node was already fetched
      if (isStormData(this.getDiagramStormData(node.guid))) {
        continue;
      }

      // fetch real node
      await this.fetchStormData(node);
    }
  }





  // ------------------------------------------------------------
  // 20. Scratchpad
  // ------------------------------------------------------------
  showRemovalWarning = () => {
    return this.state.showRemovalWarning;
  }


  /**
   * Given an Entity/Association/View node, search its children to find type attributes with phenom guids so they can be added to the save request
   *
   * @param {string} guid Guid of Entity/Association/View
   * @param {set} dependencies
   * @returns set of guids
   */
  findNodeDependencies = (guid, dependencies = new Set()) => {
    const node = this.getDiagramNodeData(guid);

    if (!node) {
      return dependencies;
    }

    for (let child of node.children) {
      let phenomTypeNode;

      switch (child.xmiType) {
        case "conceptual:Composition":
        case "conceptual:AssociatedEntity":
          if (isPhenomGuid(child.type)) {
            phenomTypeNode = this.getDiagramNodeData(child.type);
          }
          break;

        case "platform:CharacteristicProjection":
          if (isPhenomGuid(child.viewType?.guid)) {
            phenomTypeNode = this.getDiagramNodeData(child.viewType?.guid);
          }
          break;
      }

      if (phenomTypeNode && !dependencies.has(phenomTypeNode.guid)) {
        dependencies.add(phenomTypeNode.guid);
        this.findNodeDependencies(phenomTypeNode.guid, dependencies);
      }
    }

    return dependencies;
  }



  // ------------------------------------------------------------
  // 30. IDM Editor
  // ------------------------------------------------------------
  /**
   * Fetches Context Node and Presentational data
   */
  loadImContext = async (contextGuid) => {
    BasicAlert.show("Loading Context...", "Loading", true);

    // // Remove state from window history
    // this.props.location.state?.guid && window.history.replaceState(null, document.title);

    // Switch tab if diagram was previously loaded
    const loadedDiagram = this.state.diagrams.findIndex(d => d.ref.getContextGuid() === contextGuid);
    if (loadedDiagram > -1) {
      BasicAlert.hide();
      return this.switchTab(loadedDiagram);
    }

    let activeTab = this.state.diagrams[this.state.activeIdx];

    // Create new tab if no diagram exist yet (i.e. user double clicked nav tree)
    // Create new tab if current diagram is being used
    if (!activeTab?.ref || activeTab.ref.hasContent()) {
      await this.createNewTab({ fileId: null, fileName: `Context_${this.state.diagrams.length + 1}` });
      activeTab = this.state.diagrams[this.state.activeIdx];
    }

    // Fetch Context
    let context = await this.fetchImContext(contextGuid);
    let presentationData = await this.fetchPresentationContext(contextGuid);
    await this.fetchMissingNodesFromPresentationData(presentationData);
    this.readdNodesFromPresentationData(presentationData);

    // Context exist in in-memory model
    // PresentationData does not exist yet.
    if (presentationData.errors) {
      presentationData = undefined;
    }

    await activeTab?.ref.loadImContext(context, presentationData);
    BasicAlert.hide();

    // Loading context from NavTree
    if (this.props.location.state?.guid) {
      // Remove state from window history
      this.props.location.state?.guid && this.props.history.replace();
    }
  }

  deleteContext = (context, callback) => {
    if (!context?.guid) return;

    DeletionConfirm2.show(context.guid, context.name, (status) => {
      if (!status.deleted) return;

      _ajax({
        url: "/index.php?r=/integration-diagram/diagram-delete",
        method: "post",
        data: { guid: context.guid },
      });

      // Delete tab if diagram was loaded
      const diagramIndex = this.state.diagrams.findIndex(d => d.ref.getContextGuid() === context.guid);
      if (diagramIndex > -1) {
        this.deleteTab(diagramIndex);
      }

      callback && callback();
    }, true);
  }

  /**
   * 
   * @param {array} requestNodes 
   * @param {xmiType} contextXmiType "im:IntegrationContext" or "im:ComposedBlock"
   * @returns 
   */
  saveContextNode = (requestNodes, contextXmiType) => {
    const customErrors = {
      408: "The Save is taking longer than expected. You can check back later to see if the nodes were fully committed.",   // status time
    }

    return _ajax({
      url: "/index.php?r=/node/smm-save-nodes",
      method: "post",
      data: {
        nodes: requestNodes,
        returnTypes: [contextXmiType],
        returnAddenda: {
          [contextXmiType]: imContextAddenda,
        },
      },
    }, customErrors).then((res) => {
      const response = res.data;
      // note: if a UoPInstance is standalone (not connected to a block node) then it does not come back with the context
      //          UoPI was added to returnTypes, specifically for this edge case, to update the NavTree
      if (!response?.nodes) {
        BasicAlert.hide();
        Notifications2.parseErrors("Failed to save context. Server responded with an unexpected status code.");
        return;
      }

      const respContext = response.nodes.find(node => node.xmiType === contextXmiType);
      const hashPhenomToReal = response.referencees || {};
      const hashRealToPhenom = invert(response.referencees);
      NavTree.addNodes(response.nodes);

      // update index
      for (let node of response.nodes) {
        this.setDiagramStormData(node, hashRealToPhenom, true);
      }

      // reassign storm data accross all tabs
      for (let diagram of this.state.diagrams) {
        diagram.ref?.reassignAllStormData && diagram.ref.reassignAllStormData(hashPhenomToReal);
      }

      const contextData = this.getDiagramStormData(respContext.guid);

      return {
        contextData,
        hashPhenomToReal: response.referencees || {},
        hashRealToPhenom: hashRealToPhenom || {},
      }

    }).catch(err => {
      BasicAlert.hide();
    })
  }

  saveContextNodeOnly = async (requestNodes, contextXmiType) => {
    return _ajax({
      url: "/index.php?r=/node/smm-save-nodes",
      method: "post",
      data: {
        nodes: requestNodes,
        returnTypes: [contextXmiType, "im:IntegrationContext"],
      },
    }).then(res => {
      const response = res.data;
      
      if (!response?.nodes) {
        BasicAlert.hide();
        Notifications2.parseErrors("Failed to save context. Server responded with an unexpected status code.");
        return;
      }

      const resContext = response.nodes[0];
      const hashPhenomToReal = response.referencees || {};
      const hashRealToPhenom = invert(response.referencees);
      NavTree.addNodes([ resContext ]);

      const contextData = this.setDiagramStormData(resContext, hashRealToPhenom, true);

      // reassign storm data accross all tabs
      for (let diagram of this.state.diagrams) {
        diagram.ref?.reassignAllStormData && diagram.ref.reassignAllStormData(hashPhenomToReal);
      }
      
      return contextData;
    }).catch(err => {
      BasicAlert.hide();
    })
  }


  /**
   * Saves the Context Node and Presentational data
   *    updates the hash tables without
   */
  saveImDiagram = async (serializedDiagram, contextData) => {
    try {
      const response = await _ajax({
        method: "post",
        url: "/index.php?r=/integration-diagram/diagram-save",
        data: {
          guid: contextData.getGuid(),
          xmiType: contextData.getXmiType(),
          contents: JSON.stringify(serializedDiagram),
        }
      })
      
      return response;
      
    } catch (err) {
      BasicAlert.hide();
      console.error(err);
    }
  }


  /**
   * 
   * @param {guid} source 
   * @param {guid} destination 
   * @returns StormData
   */
  createNodeConnectionData = (source, destination) => {
    const link = {
      guid: createPhenomGuid(),
      xmiType: "im:NodeConnection",
      source: source || "",
      destination: destination || "",
      children: [],
    }
    return this.setDiagramStormData(link);
  }

  /**
   * 
   * @returns StormData
   */
  createImContextData = () => {
    const context = {
      guid: createPhenomGuid(),
      name: "",
      description: "",
      xmiType: "im:IntegrationContext",
      children: [],
    }

    return this.setDiagramStormData(context);
  }

  /*
    removeConnectionsFromAllTabs

    Inputs: guids - An array of connection guids to delete from nav tree, either uop endpoints or im node connections
    Outputs: n/a

    CASE 1 - Guid is an endpoint, check if endpoint is present , then remove endpoint and connection data from all diagrams
    CASE 2 - Guid is an node connection, check if endpoint is present, then remove endpoint and connection data from current diagram
    EXTRA HANDLING - removes diagram endpoints from diagram uop instance if no connection is present
  */
  removeConnectionsFromAllTabs = async (guids=[]) => { 
    guids.forEach((g) => {
      const nodeStormData = this.getDiagramStormData(g);

      if (!nodeStormData) return;

      // CASE 1 - Deletion of endpoints
      //deletion of UoP endpoints and node connections from all tabs
      if (nodeStormData.getXmiType() === "im:UoPOutputEndPoint" || nodeStormData.getXmiType() === "im:UoPInputEndPoint") {
        for (let diagram of this.state.diagrams) {
          const idmEditor = diagram.ref;  
          const links = idmEditor.model.getLinks();
          const nodeParent = nodeStormData.getParentGuid();
          const parentData = this.getDiagramStormData(nodeParent);

          parentData.removeChild(g);

          links.forEach(link => {
            const linkStormData = link.getAttrData();
            const sourcePort = link.getSourcePort();
            const sourcePortStormData = sourcePort.getAttrData();
            const destinationPort = link.getTargetPort();
            const destinationPortStormData = destinationPort.getAttrData();

            // if connection source port is found then delete
            if (nodeStormData.getXmiType() === "im:UoPOutputEndPoint" && linkStormData.getAttr("source") === g) {          
              linkStormData.setAttr("source", null);
              sourcePortStormData.setAttr("connection", null);
              link.remove();
              sourcePort.remove();

              // check connection destination port in case of uop to uop connection
              if (destinationPortStormData.getXmiType() === "im:UoPInputEndPoint") {
                destinationPort.remove();
                linkStormData.setAttr("destination", null);
              }
            } 
            
            // if connection destination port is found then delete
            if (nodeStormData.getXmiType() === "im:UoPInputEndPoint" && linkStormData.getAttr("destination") === g) {
              linkStormData.setAttr("destination", null);
              destinationPortStormData.setAttr("connection", null)
              link.remove();
              destinationPort.remove();

              // check connection source port in case of uop to uop connection
              if (sourcePortStormData.getXmiType() === "im:UoPOutputEndPoint") {
                sourcePort.remove();
                linkStormData.setAttr("source", null);
              }
            }
          });
        }
      }

      // CASE 1 - Deletion of node connections
      // deletion of node connection from current tab
      if (nodeStormData.getXmiType() === "im:NodeConnection") {
        const diagram = this.getActiveTab();
        const idmEditor = diagram.ref;
        const links = idmEditor.model.getLinks();

        links.forEach(link => {
          const linkStormData = link.getAttrData();

          if (linkStormData.getAttr("guid") === g) {
            const sourcePort = link.getSourcePort();
            const sourcePortStormData = sourcePort.getAttrData();
            const destinationPort = link.getTargetPort();
            const destinationPortStormData = destinationPort.getAttrData();
            
            if (sourcePortStormData.getXmiType() === "im:UoPOutputEndPoint") {
              link.remove();
              sourcePort.remove();
            } 
            
            if (destinationPortStormData.getXmiType() === "im:UoPInputEndPoint") {
              link.remove();
              destinationPort.remove();
            }
          }
        });
      }
    });

    // cleanup so backend doesn't throw errors
    guids.forEach(g => {
      for(let diagram of this.state.diagrams) {
        const idmEditor = diagram.ref;
        idmEditor.unmarkNodeForDeletion(g);
        idmEditor.refresh();
      }
    })

    this.forceUpdate();
  }


  // ------------------------------------------------------------
  // 40. View Trace
  // ------------------------------------------------------------
  viewTraceOverrideTable = {}              // { [source char.guid]: target char.guid }

  getViewTraceOverrideTable = () => {
    return this.viewTraceOverrideTable;
  }

  setViewTraceOverrideTable = (mappingData) => {
    if (!mappingData) return;

    for (let data of mappingData) {
      this.setViewTraceOverrideData(data);
    }
  }

  getTargetViewTraceOverrideGuid = (srcGuid) => {
    return this.viewTraceOverrideTable[srcGuid];
  }

  setViewTraceOverrideData = (data) => {
    this.viewTraceOverrideTable = {
      ...this.viewTraceOverrideTable,
      ...data,
    }
  }

  removeViewTraceOverrideData = (srcCharGuid) => {
    delete this.viewTraceOverrideTable[srcCharGuid];
  }

  fetchViewTraceOverride = async (viewGuids) => {
    const response = await _ajax({
      method: "get",
      url: "/index.php?r=/mapping/_load-default-map",
      data: {views: viewGuids},
    });

    if (response.data.mappings) {
      this.setViewTraceOverrideTable(response.data.mappings);
    }
  };

  saveViewTraceOverride = (fromCharGuid, toCharGuid) => {
    _ajax({
        method: "post",
        url: "/index.php?r=/mapping/_mapping-save",
        data: {name: "default", contents: [fromCharGuid, toCharGuid]},
    }).then((res) => {

      if (res.data === true) {
        Notifications2.parseLogs("Sucessfully saved mapping.");
        this.setViewTraceOverrideData([{[fromCharGuid]: toCharGuid}]);
        this.buildTraceOverrideForAllDiagrams(fromCharGuid, toCharGuid);

      } else {
        Notifications2.parseErrors("Something went wrong. Mapping was not saved to the database.")
      }
    });
  };

  removeViewTraceOverride = (fromCharGuid, toCharGuid) => {
    _ajax({
        method: "post",
        url: "/index.php?r=/mapping/_mapping-remove",
        data: {name: "default", contents: [fromCharGuid, toCharGuid]},
    }).then((res) => {

      if (res.data === true) {
        Notifications2.parseLogs("Sucessfully removed mapping.");
        this.removeViewTraceOverrideData(fromCharGuid);
        this.removeTraceOverrideFromAllDiagrams(fromCharGuid, toCharGuid);

      } else {
        Notifications2.parseErrors("Something went wrong. Mapping was not removed from database.")
      }
    });
  };

  buildTraceOverrideForAllDiagrams = (fromCharGuid, toCharGuid) => {
    for (let diagram of this.state.diagrams) {
      if (!diagram.ref) continue;
          diagram.ref.buildTraceOverride(fromCharGuid, toCharGuid);
    }
  }

  removeTraceOverrideFromAllDiagrams = (fromCharGuid, toCharGuid) => {
    for (let diagram of this.state.diagrams) {
      if (!diagram.ref) continue;
          diagram.ref.removeTraceOverride(fromCharGuid, toCharGuid);
    }
  }

  // nested views are flatten and has multiple guids inside
  showSemanticsFor = (guid_str="") => {
    if (typeof guid_str !== 'string') return;

    const guids = guid_str.split("__");
    this.setState({ semanticsFor: guids[0] });
  }

  handleSemanticClick = async (newViewGuid, semanticCharGuid) => {
    const activeDiagram = this.state.diagrams[this.state.activeIdx];
    const viewData = await this.getOrFetchDiagramStormData({ guid: newViewGuid, xmiType: "platform:View" });
    isStormData(viewData) && activeDiagram.ref.addViewFromSemanticClick(viewData.getData(), semanticCharGuid);
  }

  createVertexStormData = (path="") => {
    const data = {
      guid: createPhenomGuid(),
      xmiType: "skayl:Vertex",
      tracing: path,
      children: [],
    }

    return this.setDiagramStormData(data);
  }



  // ------------------------------------------------------------
  // 50. Nav Tree
  // ------------------------------------------------------------
  // injest diagram nodes into NavTree
  diagramPackage = {
    "diagramPackage": { p: "root", n:"UncommittedEntities", t:"diagram:Package", x:[], children: {} }
  }

  addDiagramPackageToNavTree = () => {
    window["treeRef"].injestLeaves(this.diagramPackage);
    window["treeRef"].forceUpdate();
  }

  revealInTree = (guid) => {
    window["treeRef"].revealAndScrollTo(guid);
  }





  // ------------------------------------------------------------
  // 70. Helper
  // ------------------------------------------------------------
  handleContinue = () => {
    this.setState({ showUnsavedPrompt: false });
  }

  /**
   * Loops through all diagram tabs and remove Block nodes (Entity, View, UoPI, Source, etc)
   * 
   * @param {array} guids array of guids
   */
  removeNodesFromAllTabs = (guids=[]) => {
    for (let diagram of this.state.diagrams) {
      if (!diagram.ref?.removeNodesFromDiagram) {
        continue;
      }

      diagram.ref.removeNodesFromDiagram(guids);
    }
  }

  /**
   * Loops through all diagram tabs and remove all ports/connections that matches the childGuid
   * 
   * @param {guid} parentGuid 
   * @param {guid} childGuid 
   */
  removeAttributePortsFromAllTabs = (parentGuid, childGuid) => {
    for (let diagram of this.state.diagrams) {
      if (!diagram.ref?.removeNodesFromDiagram) {
        continue;
      }

      diagram.ref.removeAttributePorts(parentGuid, childGuid);
    }
  }

  getCroppedCanvasCoords = (tabIdx) => {
    try {
        if (typeof tabIdx !== 'number') {
          tabIdx = this.state.activeIdx;
        }

        const tab = this.state.diagrams[tabIdx];
        const { model } = tab.ref;

        if (!model.getNodes().length) {
            return { min_x: 0, min_y: 0, max_x: 0, max_y: 0 };
        }

        let min_x = Infinity;
        let min_y = Infinity; 
        let max_x = 0;
        let max_y = 0;

        model.getNodes().forEach((nodeModel) => {
            const { points } = nodeModel.getBoundingBox();
            min_x = Math.min(min_x, points[0].x);
            min_y = Math.min(min_y, points[0].y);
            max_x = Math.max(max_x, points[2].x);
            max_y = Math.max(max_y, points[2].y);
        })
        
        return { min_x, min_y, max_x, max_y }

    } catch (err) {
        console.error(err);
    }
  }


  render() {
    const { activeIdx, diagrams, showUnsavedPrompt } = this.state;
    const { stormType, location } = this.props;
    const { params } = this.props.match;

    let StormComponent;
    switch (stormType) {
      case "view_trace":
        StormComponent = ViewTrace;
        break;
      case "idm_editor":
        StormComponent = IdmEditor;
        break;
      case "scratchpad":
        // StormComponent = Scratchpad;
        break;
    }

    const isLoadingContextFromNavTree = !!location.state?.guid;

    return <div id={this.phenomId.genPageId("manager")}
                className="fill-vertical"
                style={{ overflow: "hidden" }}>

            <Prompt when={showUnsavedPrompt && !isLoadingContextFromNavTree}
                    message={(location) => {
                      // allow user to navigate away
                      if (!this.hasUnsavedDiagram()) {
                        return true;
                      }

                      // prevent user from navigating away - renders the prompt
                      this.showDialog("unsaved_prompt", location);
                      this.forceUpdate();
                      return false;
                    }} />

              {/* TABS */}
              <div id={this.phenomId.genPageId("tab-container")}
                   className="tab-container">
                {diagrams.map((diagram, idx, arr) => {
                  const domId = `tabitem-${idx}`;
                  const classes = ["tab-item"];
                  const isActive = activeIdx === idx;
                  isActive && classes.push("active");

                  return <div id={this.phenomId.genPageId(domId)}
                              className={classes.join(" ")}
                              key={diagram.fileId}
                              onClick={(e) => {
                                e.stopPropagation();
                                this.switchTab(idx)
                              }}>
                            {diagram.fileName}
                            {arr.length > 1 &&
                              <Button icon="close"
                                      look="bare"
                                      id={this.phenomId.genPageId(domId, "close-btn")}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        this.deleteTab(idx);
                                      }} /> }
                         </div>
                })}
                <Button icon="add"
                        id={this.phenomId.genPageId("tab-add-new-btn")}
                        onClick={this.createNewTab} />
                <div style={{position: "absolute", right: 0, paddingBottom: 2}}> 
                    <KbButton /> 
                </div>
              </div>

              {/* DIAGRAM AREA */}
              {StormComponent && diagrams.map((diagram, idx) => {
                const isActive = activeIdx === idx;

                return <div key={diagram.diagramId}
                            className="fill-vertical"
                            style={{
                              display: isActive ? "flex" : "none",
                              width: "100%",
                              overflow: "hidden" }}>
                        <StormComponent $manager={this}
                                        id={this.phenomId.genPageId("diagram", idx)}
                                        tabIdx={idx}
                                        fileId={diagram.fileId}
                                        fileName={diagram.fileName}
                                        imPackageList={this.imPackageList}
                                        transportChannelList={this.transportChannelList}
                                        viewList={this.viewList}
                                        ref={(el) => diagram.ref = el}
                                        params={params} />
                      </div>
              })}

              <StormPrompt stormType={stormType}
                           $manager={this}
                           diagrams={diagrams}
                           activeIdx={activeIdx}
                           editable={this.props.canEdit}
                           routerHistory={this.props.history}
                           onClickContinue={this.handleContinue}
                           ref={el => this.dialogRef = el} />

              {stormType === "view_trace" && this.state.semanticsFor && <Portal>
              <Semantics guid={this.state.semanticsFor}
                         click={this.handleSemanticClick}
                         close={() => this.setState({ semanticsFor: undefined })} /></Portal> }
           </div>
  }
}



const msp = (state) => ({
  canEdit: state.user.canEdit,
  nodesOfType: state.navTree.nodesOfType,
})

export default connect(msp, null, null, { forwardRef: true })(withRouterAndRef(StormManager));
