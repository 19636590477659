import React from 'react';
import { DefaultPortModel } from '@projectstorm/react-diagrams-defaults';
import { AbstractModelFactory } from '@projectstorm/react-canvas-core';



export class DiagramPortFactory extends AbstractModelFactory {
	constructor() {
		super('diagram-port');
	}

	generateModel() {
		return new DiagramPortModel();
	}
}

export class DiagramPortModel extends DefaultPortModel {
	constructor(options = {}) {
		super({
			...options,
		});

    if(!this.options.attrData) this.options.attrData = {};
		if(!this.options.position) this.options.position = 0;
	}

	// need to skip "create-asso" and "create-comp" from EntityNode
	// data of outPortData and inPortData should match bc of naming conventions
	serialize() {
    const attrData = this.getAttrData();

		if(attrData?.guid && attrData.parent !== undefined) {
      var portData = {
        parent: attrData.parent,
        guid: attrData.guid
      }
		}

		if(this.options.pathData) {
			var pathData = {...this.options.pathData}

			if(pathData.viewChild) pathData.viewChild = {guid: pathData.viewChild.guid};
			if(pathData.viewNodeData) pathData.viewNodeData = {guid: pathData.viewNodeData.guid};
		}

		return {
			...super.serialize(),
			name: this.options.name,
			portData,
			pathData,
			arrowHead: this.options.arrowHead,
			arrowTail: this.options.arrowTail,
			position: this.options.position,
			targetAttrGuid: this.options.targetAttrGuid,
			specializes: this.options.specializes,
			specializedBy: this.options.specializedBy
		};
	}

	deserialize(event) {

		// if(event.data.type === "path-port") {
		// 	return super.deserialize(event);
		// }

		let attrData = {};
		let portData = event.data.portData;
		let pathData = event.data.pathData;

		if(portData) {
			let parentData = event.engine.$app.getOptionNode(portData.parent);
			if(parentData) {
				attrData = parentData.guid === portData.guid ? parentData : 
						   parentData.children.find(child => child.guid === portData.guid) || {};
			}
		}

		if(pathData && pathData.viewNodeData) {
			let viewParent = event.engine.$app.getOptionNode(pathData.viewNodeData.guid);

			if(!viewParent) {
				delete pathData.viewNodeData;
				delete pathData.viewChild;
			} else {
				pathData.viewNodeData = viewParent;
				pathData.viewChild = viewParent.children.find(vc => vc.guid === pathData.viewChild.guid) || {};
			}
		}
		
		super.deserialize(event);
		this.options.name = event.data.name;
		this.options.attrData = attrData;
		this.options.pathData = pathData;
		this.options.arrowHead = event.data.arrowHead;
		this.options.arrowTail = event.data.arrowTail;
		this.options.position = event.data.position;
		this.options.targetAttrGuid = event.data.targetAttrGuid;
		this.options.specializes = event.data.specializes;
		this.options.specializedBy = event.data.specializedBy;
	}


  getAttrData = () => {
    return this.options.attrData;
  }

  setAttrData = (data) => {
    this.options.attrData = data;
  }

	addTargetAttrGuid = () => {}
  removeTargetAttrGuid = ()=> {}
}