import React, { Component } from "react";

import { Dialog } from "@progress/kendo-react-dialogs";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import { ColorCollapsable } from "../../util/stateless";
import { getShortenedStringRepresentationOfXmiType } from "../../util/util";
import { PhenomLink } from "../../widget/PhenomLink";
import loadingIcon from "../../../images/Palette Ring-1s-200px.gif";
import { receiveErrors } from "../../../requests/actionCreators";
import { _ajax } from "../../../requests/sml-requests";
// import PhenomId from "../../requests/phenom-id";


class InterModelDependencies extends Component {
    constructor(props) {
        super(props);
        InterModelDependencies.__instance = this;

        this.state = {
            visible: false,
            loading: true,
            dependencies: {},

        }

        this.__hide = this.__hide.bind(this);

    }

    static show(project) {
        InterModelDependencies.__instance.setState({
            visible: true,
            loading: true,
        }, InterModelDependencies.__instance.__show);
    }

    __show() {
        this.fetchDependencies();
    }

    __hide() {
        this.setState({
            visible: false,
            loading: false,
        })
    }

    fetchDependencies = () => {
        _ajax({
            url: "/index.php?r=/sub-model/get-inter-model-dependencies",
            method: "POST",
        }).then((res) => {

            this.setState({dependencies: res.data, loading: false});
        })
    }

    renderDependencyInterModels() {
        return (
            <>
                {Object.entries(this.state.dependencies).map(([key, nodes], i) => {
                    return <ColorCollapsable color="#2596be" 
                                      content={this.renderDependencyNodes(nodes, key)} 
                                      heading={`Model ${key.split(":")[0]} > Model ${key.split(":")[1]}`} 
                                      contentId={`${key}`} 
                                      vMargin={5} 
                                      collapsableStyle={{paddingRight: 0, marginBottom: 15}}/>
                })}
            </>
        );
    }

    renderDependencyNodes (nodes, id) {
        return <div id={id}>
            {nodes && Object.values(nodes).map(node => 
                <div>
                    {`${getShortenedStringRepresentationOfXmiType(node["xmiType"])} `} <PhenomLink node={node} newTab={true}/>
                    <ul>
                        {node["referencees"].map(ref => 
                            <li>
                                {`${getShortenedStringRepresentationOfXmiType(ref["xmiType"])} `} <PhenomLink node={ref} newTab={true}/>
                            </li>)}
                    </ul>
                </div>)}
        </div>
    }

    cleanupDependencies () {
        _ajax({
            url: "/index.php?r=/sub-model/cleanup-dependencies",
            method: "POST",
        }).then((res) => {
            this.setState({loading: true});
            this.fetchDependencies();
        })
    }

    render() {
        const outerStyle = {maxHeight: "70vh"}
        if (!this.state.visible) return null;
        return (
            <Dialog
                title={"Inter Model Dependencies"}
                onClose={this.__hide}
                width={900}>
                {!this.state.loading &&
                    <div className={"flex-h"}>
                        <div style={{flex:"5"}}></div>
                        <button className="btn-main"
                            style={{flex:"1", margin:"5px"}}
                            onClick={() => this.cleanupDependencies()}>
                        Cleanup Dependencies
                        </button>
                    </div>}
                {!this.state.loading && this.state.dependencies && Object.keys(this.state.dependencies).length > 0 && this.renderDependencyInterModels()}
                {!this.state.loading && (!this.state.dependencies || Object.keys(this.state.dependencies).length === 0) &&
                    <div style={outerStyle}>
                        <span> {`This project has no dependencies`}</span>
                    </div>}
                {this.state.loading && 
                    <div style={outerStyle}>
                        <img src={loadingIcon} style={{width: 75}}/>
                        <span> {`Fetching inter model dependencies`}</span>
                    </div>}
            </Dialog>
        )    
        
    }
}

export default withRouter(InterModelDependencies);